import { MetroChip } from "hakobio-react-ui";
import { GENERIC_STATUS_EXPLANATION } from "../../../constants/statusExplanations/GENERIC_STATUS_EXPLANATION";
import { REFERENCE_STATUS_EXPLANATION } from "../../../constants/statusExplanations/REFERENCE_STATUS_EXPLANATION";
import { UNIT_OPERATION_STATUS_EXPLANATION } from "../../../constants/statusExplanations/UNIT_OPERATION_STATUS_EXPLANATION";
import {
  GenericStatus,
  genericStatusBackgroundColors,
  genericStatusColors,
} from "../../../utilities/statuses/genericStatus";
import {
  ReferenceStatus,
  referenceStatusBackgroundColors,
  referenceStatusColors,
} from "../../../utilities/statuses/referenceStatus";
import {
  operationStatusBackgroundColors,
  operationStatusColors,
} from "../../../utilities/statuses/operationStatus";

interface StatusTooltipProps {
  status: string;
  assemblyType: "generic" | "reference" | "unit-operation";
}

const StatusTooltip: React.FC<StatusTooltipProps> = ({
  status,
  assemblyType,
}) => {
  const getStatusList = () => {
    switch (assemblyType) {
      case "generic":
        return GENERIC_STATUS_EXPLANATION;
      case "reference":
        return REFERENCE_STATUS_EXPLANATION;
      case "unit-operation":
        return UNIT_OPERATION_STATUS_EXPLANATION;
      default:
        return {};
    }
  };
  const statusList = getStatusList();

  const getStatusColor = () => {
    switch (assemblyType) {
      case "generic":
        return genericStatusColors(status);
      case "reference":
        return referenceStatusColors(status);
      case "unit-operation":
        return operationStatusColors(status);
      default:
        return undefined;
    }
  };
  const statusColor = getStatusColor();

  const getStatusBackgroundColor = () => {
    switch (assemblyType) {
      case "generic":
        return genericStatusBackgroundColors(status);
      case "reference":
        return referenceStatusBackgroundColors(status);
      case "unit-operation":
        return operationStatusBackgroundColors(status);
      default:
        return undefined;
    }
  };
  const statusBackgroundColor = getStatusBackgroundColor();

  return (
    <div className="p-2" style={{ backgroundColor: "rgba(255,255,255,.5)" }}>
      {Object.entries(statusList)?.map(
        ([key, value]: [
          key: string,
          value: { name: string; explanations: string[] },
        ]) => {
          const assemblyStatus =
            assemblyType === "reference" &&
            status ===
              "onboarding/qualification" /* || status === 'qualification' */
              ? "onboardingQualification"
              : status;

          return (
            <div key={`${key}-${value}`}>
              <MetroChip
                auto
                fontSize="smallText"
                key={value.name}
                label={value.name}
                color={
                  assemblyStatus === key ? statusColor : "var(--anthracite)"
                }
                backgroundColor={
                  assemblyStatus === key ? statusBackgroundColor : "var(--grey)"
                }
              />
              {assemblyStatus === key && (
                <ul
                  className="py-2"
                  style={{
                    borderLeft:
                      key === GenericStatus.Freeze ||
                      key === GenericStatus.Obsolete ||
                      key === ReferenceStatus.Effective
                        ? "none"
                        : "1px solid var(--grey)",
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 12,
                    paddingLeft: 9,
                  }}
                >
                  {value.explanations.map((exp, index) => (
                    <li key={`${exp}-${index}`}>
                      <span style={{ color: "black" }}>{exp}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        },
      )}
    </div>
  );
};

export default StatusTooltip;
