import { ThinChip, Tooltip } from 'hakobio-react-ui';
import React, { useEffect, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../../store/features/assemblyEditor/useAssemblyEditorAction';
import _ from 'lodash';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';

export const GenericAnchor = (props: any) => {
  const { setHoveredAnchor, unsetHoveredAnchor, onAnchorChanged, onAnchorDeleted } =
    useAssemblyEditorAction();
  const { anchor, sizeX, sizeY, component, color } = props;
  const [anchorPosition, setAnchorPosition] = useState(anchor.data.position);
  const [anchorType, setAnchorType] = useState(anchor.type);
  const [hoveredWarn, setHoveredWarn] = useState(false);
  const dispatch = useAppDispatch();

  const customComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentEdition
  );
  const hoveredWarning = useAppSelector((state) => state.assemblyEditorSlice.hoveredWarning);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const selectedAnchors = useAppSelector((state) => state.assemblyEditorSlice.selectedAnchors);
  const isSavingScreenshotSUAAnnotated = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUAAnnotated
  );
  const isSavingScreenshotUOAnnotatedPFD = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotUOAnnotatedPFD
  );
  const isSavingScreenshotUOAnnotatedPID = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotUOAnnotatedPID
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClick(event: any): void {
    setAnchorEl(event.currentTarget);
  }

  const handleChangeType = (value: string, event: any) => {
    event.stopPropagation();
    // onAnchorChanged(anchor, 'type', value, props.component);
    handleClose();
  };
  const colorAnchors = () => {
    if (
      isSavingScreenshotSUAAnnotated ||
      isSavingScreenshotUOAnnotatedPID ||
      isSavingScreenshotUOAnnotatedPFD
    ) {
      return 'rgba(255,255,255,0)';
    }
    switch (anchor.type) {
      case 'genderless':
        return 'var(--blue)';
      case 'neutral':
        return 'var(--dark-grey)';
      case 'source':
        return 'var(--red)';
      case 'target':
        return 'var(--green)';
    }
  };

  const anchorColor = colorAnchors();

  const selectedAnchor = selectedAnchors && selectedAnchors[0];

  const isSelected =
    anchor?.id === selectedAnchor?.id &&
    (component?.id || customComponent?.id) === selectedAnchor?.component;

  const errors = useAppSelector((state) => state.assemblyEditorSlice.errors);

  const errorAnchor = errors.filter((error) =>
    error.componentAnchors.find(
      (a: any) => a.anchorId === anchor.id && a.componentId === component.id
    )
  );

  const errorOrphan = errors.filter((error) =>
    error.componentAnchors.find(
      (a: any) => a.anchorId === anchor.id && a.componentId === component.id && a.orphan === true
    )
  );

  const connectedEdge = components
    .filter((e: any) => e.type === 'functionedge' || e.type === 'genericedge')
    .find((edge: any) => {
      return (
        (edge.source === component.id && edge.sourceHandle === anchor.id) ||
        (edge.target === component.id && edge.targetHandle === anchor.id)
      );
    });

  const isConnectable = () => {
    if(editorMode === EditorMode.UnitOperation && !connectedEdge){
      if(layerMode === UnitOperationLayer.PnID){
        return (props.component.type === "generic" || props.component.type === "ghost") && !!props.component.data.ghost;
      }
      if(layerMode === UnitOperationLayer.Reference){
        return (props.component.type === "generic" || props.component.type === "ghost") && !!props.component.data.ghostReference;
      }
    }

    return connectedEdge === undefined || connectedEdge === null;
  };

  const isValidConnection = (connection: any) => {
    const source = components.find((c) => c.id === connection.source);
    let sourceAnchor;
    sourceAnchor = source.data.anchors?.find((a: any) => a.id === connection.sourceHandle);
    if (!sourceAnchor)
      sourceAnchor = source.data.instrumentationPorts?.find(
        (a: any) => a.id === connection.sourceHandle
      );
    if (!sourceAnchor)
      sourceAnchor = source.data.samplingPorts?.find((a: any) => a.id === connection.sourceHandle);
    const target = components.find((c) => c.id === connection.target);
    let targetAnchor;
    targetAnchor = target.data.anchors?.find((a: any) => a.id === connection.targetHandle);
    if (!sourceAnchor)
      targetAnchor = target.data.instrumentationPorts?.find(
        (a: any) => a.id === connection.targetHandle
      );
    if (!sourceAnchor)
      targetAnchor = target.data.samplingPorts?.find((a: any) => a.id === connection.targetHandle);
    if (connection.source === connection.target) {
      return false;
    }
    //Cas source anchor === source(rouge) => target anchor === target(vert) || genderless(blue)
    if (sourceAnchor.type === 'source' && targetAnchor.type === 'source') {
      return false;
    }
    //Cas source anchor === target(vert) =>
    //il faut que target anchor === source(rouge) || genderless(blue)
    //si correct => échanger source et target
    if (sourceAnchor.type === 'target' && targetAnchor.type === 'target') {
      return false;
    }
    //Cas source anchor  = Genderless
    //Si target est genderless => ok
    //Si target est vert => ok
    //Si target est rouge => échanger position
    return true;
  };

  useEffect(() => {
    const errorWarningHover = errorAnchor.find((co: any) => co.id === hoveredWarning);
    if (errorWarningHover) {
      setHoveredWarn(true);
    } else {
      setHoveredWarn(false);
    }
  }, [hoveredWarning]);

  useEffect(() => {
    if (anchor.type === 'genderless' || anchor.type === 'neutral') {
      if (
        connectedEdge &&
        connectedEdge.source === component.id &&
        connectedEdge.sourceHandle === anchor.id
      ) {
        if (anchorType !== 'source') {
          setAnchorType('source');
          const componentCopy = _.cloneDeep(component);
          if (componentCopy.viewer2D.pfd && componentCopy.viewer2D.pid) {
            componentCopy.viewer2D.pfd.x += 0.00000001;
            componentCopy.viewer2D.pid.x += 0.00000001;
          } else {
            componentCopy.viewer2D.x += 0.00000001;
          }
          const promise = { id: componentCopy.id, component: componentCopy };
          dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          //dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
        }
        return;
      }
      if (
        connectedEdge &&
        connectedEdge.target === component.id &&
        connectedEdge.targetHandle === anchor.id
      ) {
        if (anchorType !== 'target') {
          setAnchorType('target');
          const componentCopy = _.cloneDeep(component);
          if (componentCopy.viewer2D.pfd && componentCopy.viewer2D.pid) {
            componentCopy.viewer2D.pfd.x += 0.00000001;
            componentCopy.viewer2D.pid.x += 0.00000001;
          } else {
            componentCopy.viewer2D.x += 0.00000001;
          }
          const promise = { id: componentCopy.id, component: componentCopy };
          dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          //dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
        }

        return;
      }

      if (anchorType !== 'genderless' && anchor.type === 'genderless') {
        setAnchorType('genderless');
        const componentCopy = _.cloneDeep(component);
        if (componentCopy.viewer2D.pfd && componentCopy.viewer2D.pid) {
          componentCopy.viewer2D.pfd.x += 0.00000001;
          componentCopy.viewer2D.pid.x += 0.00000001;
        } else {
          componentCopy.viewer2D.x += 0.00000001;
        }
        const promise = { id: componentCopy.id, component: componentCopy };
        dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
        //dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
      }

      if (anchorType !== 'neutral' && anchor.type === 'neutral') {
        setAnchorType('neutral');
        const componentCopy = _.cloneDeep(component);
        if (componentCopy.viewer2D.pfd && componentCopy.viewer2D.pid) {
          componentCopy.viewer2D.pfd.x += 0.00000001;
          componentCopy.viewer2D.pid.x += 0.00000001;
        } else {
          componentCopy.viewer2D.x += 0.00000001;
        }
        const promise = { id: componentCopy.id, component: componentCopy };
        dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
        //dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
      }
    }
  }, [anchor, component, components]);

  useEffect(() => {
    if (editorMode !== EditorMode.Component) {
      const position = ['top', 'right', 'bottom', 'left'] as any;
      const rotation = [0, 90, 180, 270] as any;
      if (component.viewer2D.rotate) {
        let rotate = rotation.findIndex((i: number) => {
          return i === component.viewer2D.rotate;
        });
        let pos = position.findIndex((p: any) => {
          return p === anchor.data.position;
        });
        if (position[(pos + rotate) % 4] !== anchorPosition) {
          setAnchorPosition(position[(pos + rotate) % 4]);
          const componentCopy = _.cloneDeep(component);
          if (componentCopy.viewer2D.pfd && componentCopy.viewer2D.pid) {
            componentCopy.viewer2D.pfd.x += 0.00000001;
            componentCopy.viewer2D.pid.x += 0.00000001;
          } else {
            componentCopy.viewer2D.x += 0.00000001;
          }
          const promise = { id: componentCopy.id, component: componentCopy };
          dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          //dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
        }
      } else if (anchor.data.position !== anchorPosition) {
        setAnchorPosition(anchor.data.position);
        const componentCopy = _.cloneDeep(component);
        if (componentCopy.viewer2D.pfd && componentCopy.viewer2D.pid) {
          componentCopy.viewer2D.pfd.x += 0.00000001;
          componentCopy.viewer2D.pid.x += 0.00000001;
        } else {
          componentCopy.viewer2D.x += 0.00000001;
        }
        const promise = { id: componentCopy.id, component: componentCopy };
        dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
        //dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
      }
    }
  }, [anchor, component, components]);

  const renderErrorTooltip = () => {
    const title = errorAnchor.map((e: any) => {
      return (
        <div key={e.id}>
          <div style={{ width: 300 }} className="p-1">
            <ThinChip
              auto
              fontSize="smallText"
              label={e.name}
              color="var(--orange)"
              backgroundColor="var(--light-orange)"
            />
            <div>{e.errors}</div>
          </div>
        </div>
      );
    });

    return (
      <div
        style={{
          position: 'absolute',
          width: '8px',
          height: '8px',
          border: 'none',
          top: anchor.viewer2D.top * sizeY - 3,
          left: anchor.viewer2D.left * sizeX - 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <div style={{ position: 'relative' }}>
          <Tooltip title={title}>
            <div
              //onMouseEnter={warningMouseEnter}
              //onMouseLeave={warningMouseLeave}
              className="f-center"
              style={{
                borderRadius: 50,
                width: '8px',
                height: '8px',
                top: anchor.viewer2D.top * sizeY - 3,
                left: anchor.viewer2D.left * sizeY - 3
              }}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderError = () => {
    let radarClassic = null as JSX.Element;
    if (isSelected || errorOrphan.length > 0) {
      radarClassic = renderAnchorRadarClassic(true);
    }

    const backgroundColor =
      isSavingScreenshotSUAAnnotated ||
        isSavingScreenshotUOAnnotatedPFD ||
        isSavingScreenshotUOAnnotatedPID
        ? 'rgba(255,255,255,0)'
        : hoveredWarn
          ? 'var(--light-orange)'
          : 'var(--orange)';

    const border =
      isSavingScreenshotSUAAnnotated ||
        isSavingScreenshotUOAnnotatedPFD ||
        isSavingScreenshotUOAnnotatedPID
        ? 'rgba(255,255,255,0)'
        : hoveredWarn
          ? '1px solid var(--orange)'
          : '1px solid var(--orange)';

    return (
      <>
        {/* @ts-ignore */}
        <Handle
          key={anchor.id}
          type={anchorType}
          position={anchorPosition}
          /* title="Click to set type" */
          onClick={handleClick}
          id={anchor.id}
          isConnectable={isConnectable()}
          isValidConnection={isValidConnection}
          style={{
            position: 'absolute',
            width: '6px',
            height: '6px',
            border: border,
            backgroundColor: backgroundColor,
            top: anchor.viewer2D.top * sizeY - 3,
            left: anchor.viewer2D.left * sizeX - 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
        {radarClassic}
        {renderErrorTooltip()}
      </>
    );
  };

  const renderAnchorRadarGhost = () => {
    return (
      <div
        className="radar f-center"
        style={{
          backgroundColor: 'none',
          border: `1px solid ${color}`,
          position: 'absolute',
          top: anchor.viewer2D.top * sizeY - 3,
          left: anchor.viewer2D.left * sizeX - 3
        }}
      />
    );
  };

  const renderAnchorRadarClassic = (isWarning: boolean) => {
    return (
      <div
        className="radar f-center"
        style={{
          backgroundColor: 'none',
          border: isWarning ? `1px solid var(--orange)` : `1px solid ${color}`,
          position: 'absolute',
          top: anchor.viewer2D.top * sizeY - 3,
          left: anchor.viewer2D.left * sizeX - 3
        }}
      />
    );
  };

  const renderAnchor = () => {
    let anchorStyle = {} as React.CSSProperties;
    let radarGhost = null as JSX.Element;
    let radarClassic = null as JSX.Element;
    switch (editorMode) {
      case EditorMode.Component:
        anchorStyle.backgroundColor = color;
        break;
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PnID:
            if (!connectedEdge && component.data.ghost) {
              radarGhost = renderAnchorRadarGhost();
              anchorStyle.backgroundColor = color;
            }
            break;
          case UnitOperationLayer.Reference:
            if (!connectedEdge && component.data.ghostReference) {
              radarGhost = renderAnchorRadarGhost();
              anchorStyle.backgroundColor = color;
            }
        }
        break;
    }
    if (isSelected) {
      radarClassic = renderAnchorRadarClassic(false);
      anchorStyle.backgroundColor = color;
    }
    return (
      <>
        {radarGhost}
        {radarClassic}
        {/* @ts-ignore */}
        <Handle
          onMouseEnter={() => setHoveredAnchor(anchor.id)}
          onMouseLeave={() => unsetHoveredAnchor()}
          onClick={handleClick}
          key={anchor.id}
          type={anchorType}
          position={anchorPosition}
          id={anchor.id}
          isConnectable={isConnectable()}
          isValidConnection={isValidConnection}
          style={{
            transition: 'color 0.1s linear',
            position: 'absolute',
            width: '8px',
            height: '8px',
            backgroundColor: component?.type === 'function' ? '#565656' : color,
            border: 'none',
            top: anchor.viewer2D.top * sizeY - 3,
            left: anchor.viewer2D.left * sizeX - 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...anchorStyle
          }}
        />
      </>
    );
  };

  return errorAnchor.length === 0 ? renderAnchor() : renderError();
};
