import { RuleComponentEdgeComponent } from '../RuleComponentEdgeComponent';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';

export class RuleComponentDiameterTubingDiameter extends RuleComponentEdgeComponent {
  schemaComponentSourceList = allComponents;
  schemaComponentTargetList = allComponents;
  schemaTubeList = ['tubing'];
  editorList = ['SUE', 'UOE_PID'];

  checkPrerequisite = () => {
    const isTube = this.schemaTubeList.includes(this.edge.data.type);
    const isSourceValid = this.componentSource ? this.schemaComponentSourceList.includes(this.componentSource.data.type) : false;
    const isTargetValid = this.componentTarget ? this.schemaComponentTargetList.includes(this.componentTarget.data.type) : false;
    if (isTube && isSourceValid) {
      return true;
    }
    if (isTube && isTargetValid) {
      return true;
    }
    return false;
  };

  checkRule = () => {
    if ( 
      this.componentTargetAnchor.data['anchorOuterDiameter'] !==
      this.edge.data['componentInnerDiameter']
    ) {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible diameter',
        errors:
          'The diameter on the' +
          ` ${CUSTOM_NODES[this.componentTarget.data.type].name} ` +
          '(target) is ' +
          (this.componentTargetAnchor.data['anchorOuterDiameter']
            ? this.componentTargetAnchor.data['anchorOuterDiameter']
            : 'unspecified') +
          ' and the diameter of the tube is ' +
          (this.edge.data['componentInnerDiameter']
            ? this.edge.data['componentInnerDiameter']
            : 'unspecified'),
        componentAnchors: [
          // @ts-ignore
          { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
        ]
      });
    }

    if (  
      this.componentSourceAnchor.data['anchorOuterDiameter'] !==
      this.edge.data['componentInnerDiameter']
    ) {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible diameter',
        errors:
          'The diameter on the' +
          ` ${CUSTOM_NODES[this.componentSource.data.type].name} ` +
          '(source) is ' +
          (this.componentSourceAnchor.data['anchorOuterDiameter']
            ? this.componentSourceAnchor.data['anchorOuterDiameter']
            : 'unspecified') +
          ' and the diameter of the tube is ' +
          (this.edge.data['componentInnerDiameter']
            ? this.edge.data['componentInnerDiameter']
            : ' unspecified '),
        componentAnchors: [
          // @ts-ignore
          { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id }
        ]
      });
    }
  };
}

const allComponents = [
  'suPumpHead',
  'asepticDisconnector',
  'mechanicDisconnector',
  'quickCoupler',
  'triclampConnector',
  'bioreactorBag',
  'twoDimensionalBag',
  'threeDimensionalBag',
  'mixerBag',
  'bottle',
  'plug',
  'couplerReducer',
  'lConnector',
  'tConnector',
  'yConnector',
  'xConnector',
  'asepticConnector',
  'sipConnector',
  'hydrophobicFilter',
  'hydrophilicFilter'
];
