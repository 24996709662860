import { SUPPLIERS } from '../../../../constants/SUPPLIERS';

export const advancedSearch = (assemblyList, searchValue) => {
  let searchAssemblyList = assemblyList;

  if (searchValue) {
    // Name
    if (searchValue.name.length) {
      searchAssemblyList = searchAssemblyList.filter((a: any) =>
        a.general.name.toLowerCase().includes(searchValue.name.toLowerCase())
      );
    }
    // Created by
    if (searchValue.createdBy.length) {
      searchAssemblyList = searchAssemblyList.filter((a: any) =>
        a.general.createdByName?.toLowerCase().includes(searchValue.createdBy.toLowerCase())
      );
    }
    // Modified by
    if (searchValue.modifiedBy.length) {
      searchAssemblyList = searchAssemblyList.filter((a: any) =>
        a.general.modifiedByName?.toLowerCase().includes(searchValue.modifiedBy.toLowerCase())
      );
    }

    // parent sua
    if (searchValue.parentSUA?.length) {
      searchAssemblyList = searchAssemblyList.filter((a: any) => {
        return a.general.assemblyGenericList.some((ag) =>
          searchValue.parentSUA.some((p) => ag.parameters[0].value === p?.general.id)
        );
      });
    }

    // Folders
    if(searchValue.folders?.length && searchValue.folders[0].children && searchValue.folders[0].children?.length > 0) { 
      const folderChildrenIds = searchValue.folders.flatMap(folder => folder.children);
      searchAssemblyList = searchAssemblyList.filter((sua: any) => {
        return folderChildrenIds.includes(sua.general.id);
      });
    }

    // AssemblyType
    if (searchValue.assemblyType) {
      searchValue.assemblyType === 'Functional assembly'
        ? (searchAssemblyList = searchAssemblyList.filter(
            (a: any) => a.general.type === 'SingleUseAssembly'
          ))
        : (searchAssemblyList = searchAssemblyList.filter(
            (a: any) => a.general.type === 'SingleUseAssemblyReference'
          ));
    }

    // suaStatus
    if (searchValue.suaStatus?.length) {
      let assemblyList = [];
      searchValue.suaStatus.forEach((s, index) => {
        searchAssemblyList.forEach((a: any) => {
          if (
            a.general?.status === s.toLowerCase() ||
            ((a.general?.status === undefined || a.general?.status === null) &&
              s.toLowerCase() === 'no status')
          ) {
            assemblyList.push(a);
          }
        });
      });
      searchAssemblyList = assemblyList;
    }

    //refernceStatus
    if (searchValue.referenceStatus?.length) {
      let assemblyList = [];
      searchValue.referenceStatus.forEach((s, index) => {
        searchAssemblyList.forEach((a: any) => {
          if (a.general?.status === s.toLowerCase()) {
            assemblyList.push(a);
          }
        });
      });
      searchAssemblyList = assemblyList;
    }
    // Functions
    if (searchValue.functions.length && !searchValue.ancillaryFunctions.length) {
      searchAssemblyList = searchAssemblyList.filter((a: any) =>
        a.general.functions.some((c) => searchValue.functions.includes(c))
      );
    }
    // Ancillary
    if (searchValue.ancillaryFunctions.length && !searchValue.functions.length) {
      searchAssemblyList = searchAssemblyList.filter((a: any) =>
        a.general.functions.some((c) => searchValue.ancillaryFunctions.includes(c))
      );
    }
    // AncillaryAndFunctions
    if (searchValue.ancillaryFunctions.length && searchValue.functions.length) {
      searchValue.ancillaryFunctions.map((ancillaryFunction) => {
        searchAssemblyList.map((f) => {
          if (f.general.functions.includes(ancillaryFunction)) {
            searchAssemblyList = searchAssemblyList.filter((a: any) =>
              a.general.functions.includes(ancillaryFunction)
            );
          }
        });
      });

      searchAssemblyList = searchAssemblyList.filter((a: any) =>
        a.general.functions.some((c) => searchValue.functions.includes(c))
      );
    }

    //suppliers
    if (searchValue?.suppliers?.length) {
      let assemblyList = [];
      searchValue.suppliers.forEach((s, index) => {
        const supplier = SUPPLIERS.find((s) => s.name === searchValue.suppliers[index]);
        searchAssemblyList.forEach((a: any) => {
          if (a.general?.supplier === supplier.id) {
            assemblyList.push(a);
          }
        });
      });
      searchAssemblyList = assemblyList;
    }

    // Containers
    if (searchValue.containers.length) {
      searchAssemblyList = filterComponents(searchAssemblyList, searchValue.containers);
    }
    // Connectors
    if (searchValue.connectors.length) {
      searchAssemblyList = filterComponents(searchAssemblyList, searchValue.connectors);
    }
    // Volume
    if (searchValue.volumes.length) {
      searchAssemblyList = searchAssemblyList.filter((a: any) => {
        let volumesList = [];
        Object.values(a.components).forEach((comp: any) => {
          const componentVolume = comp.data.componentVolume?.toString();
          if (componentVolume) volumesList.push(componentVolume);
        });
        const isVolInList = volumesList.some((c) => searchValue.volumes.includes(c));
        return isVolInList;
      });
    }
    // Lines
    if (searchValue.lines[1] > 0) {
      searchAssemblyList = searchAssemblyList.filter((a: any) => {
        const tubingsList = a.components.filter((c: any) => c.data.type === 'tubing');
        return (
          tubingsList.length >= searchValue.lines[0] && tubingsList.length <= searchValue.lines[1]
        );
      });
    }
    // 2D Bags
    if (searchValue.bags2D[1] > 0) {
      searchAssemblyList = searchAssemblyList.filter((a) => {
        const bags2DList = a.components.filter((c: any) => c.data.type === 'threeDimensionalBag');
        return (
          bags2DList.length >= searchValue.bags2D[0] && bags2DList.length <= searchValue.bags2D[1]
        );
      });
    }
    // Pore Size Mum
    if (searchValue.poreSizeMum) {
      let res = [];
      searchAssemblyList = searchAssemblyList.forEach((element) => {
        element.components.forEach((component) => {
          if (component.data.componentPortSize === searchValue.poreSizeMum.toString())
            res.push(element);
        });
      });
      return res;
    }

    // Pore Size Mwco
    if (searchValue.poreSizeMwco) {
      let res = [];
      searchAssemblyList = searchAssemblyList.forEach((element) => {
        element.components.forEach((component) => {
          if (component.data.componentPortSizeMwco === searchValue.poreSizeMwco.toString())
            res.push(element);
        });
      });
      return res;
    }

    // Combined Porosity
    if (searchValue.combinedPorosity?.length) {
      let res = [];
      searchAssemblyList = searchAssemblyList.forEach((element) => {
        element.components.forEach((component) => {
          if (searchValue.combinedPorosity.includes(component.data.componentCombinedPorosity))
            res.push(element);
        });
      });
      return res;
    }

    //Tubing Material
    if (searchValue.tubingMaterials?.length) {
      searchAssemblyList = filterDatas(
        searchAssemblyList,
        searchValue.tubingMaterials,
        'componentMaterial'
      );
    }

    //Tubing Inner Diameters
    if (searchValue.tubingInnerDiameters?.length) {
      searchAssemblyList = filterDatas(
        searchAssemblyList,
        searchValue.tubingInnerDiameters,
        'componentInnerDiameter'
      );
    }

    //Tubing Length
    if (searchValue.tubingLength) {
      let res = [];
      searchAssemblyList = searchAssemblyList.forEach((element) => {
        element.components.forEach((component) => {
          if (component.data.componentLength === searchValue.tubingLength.toString())
            res.push(element);
        });
      });
      return res;
    }

    //Pump Type
    if (searchValue.pumpingTypes?.length) {
      if (searchValue.pumpingTypes?.length) {
        searchAssemblyList = filterDatas(
          searchAssemblyList,
          searchValue.pumpingTypes,
          'componentType'
        );
      }
    }
    // Filtration surface
    if (searchValue.filtrationSurface) {
      let res = [];
      searchAssemblyList = searchAssemblyList.forEach((element) => {
        element.components.forEach((component) => {
          if (component.data.componentSurface === searchValue.filtrationSurface.toString())
            res.push(element);
        });
      });
      return res;
    }
    // Sensor types
    if (searchValue.sensorTypes.length) {
      searchAssemblyList = filterDatas(
        searchAssemblyList,
        searchValue.sensorTypes,
        'componentType'
      );
    }
    return searchAssemblyList;
  }
};

const filterComponents = (list: any[], searchComponentsArray: string[]) => {
  list = list.filter((a: any) => {
    let componentsList = Object.values(a.components).map((comp: any) => comp.data.type);
    const isCompInList = componentsList.some((c) => searchComponentsArray.includes(c));
    return isCompInList;
  });
  return list;
};

const filterDatas = (list: any[], searchParamsArray: string[], paramName: string) => {
  const lowerCaseParams = searchParamsArray.map((p) => p.toLowerCase());
  list = list.filter((a: any) => {
    let componentTypeList = [];
    Object.values(a.components).forEach((comp: any) => {
      if (comp.data[paramName]) componentTypeList.push(comp.data[paramName]);
    });
    const isTypeInList = componentTypeList.some((c) => lowerCaseParams.includes(c.toLowerCase()));
    return isTypeInList;
  });
  return list;
};

export const hasFiltersSet = (filters: any) => {
  if (!filters) {
    return false;
  } else {
    const currentFilters = [];
    Object.values(filters).forEach((f) => {
      if (typeof f === 'string' && f !== '') currentFilters.push(f);
      if (typeof f === 'number' && f > 0) currentFilters.push(f);
      if (Array.isArray(f) && f[1] !== 0) currentFilters.push(...f);
    });
    return currentFilters.length ? true : false;
  }
};
