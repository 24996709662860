import {
  Icon,
  IconTooltip,
  Label,
  SmallButton,
  ThinChip,
  UserBadge,
  Title,
  Tooltip
} from 'hakobio-react-ui';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SUPPLIERS, Supplier } from '../../../../../constants/SUPPLIERS';
import { configMessages, versioningMessages } from '../../../../../lang/messages';
import { useAppSelector } from '../../../../../store';
import { useImplementEditorAction } from '../../../../../store/features/assemblyEditor/useImplementEditorAction';
import {
  ReferenceStatus,
  referenceStatusBackgroundColors,
  referenceStatusColors
} from '../../../../../utilities/statuses/referenceStatus';
import { DirectoryFolder } from '../../../../models/DirectoryFolder';
import { config } from '../../../../../services/config';
import {
  genericStatusBackgroundColors,
  genericStatusColors
} from '../../../../../utilities/statuses/genericStatus';
import { CUSTOM_FUNCTIONS } from '../../../../../constants/CUSTOM_FUNCTIONS';
import { orderBy } from 'lodash';
import SingleComponent from './SingleComponent';

const SingleReference = ({ sua, selectedComponent, onClickSua, isReferenceAccessible }) => {
  const [_referenceHovered, setReferenceHovered] = useState(null);
  const [_designOwners, setDesignOwners] = useState([]);
  const componentList = orderBy(sua.components, (comp) => comp.data.type, ['asc']);

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const folder = directoriesList.find((f: DirectoryFolder) => f.id === sua.general.parentFolderId);
  const userList = useAppSelector((state) => state.assemblyEditorSlice.userList);
  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary
  );

  const intl = useIntl();
  const { implementComponent } = useImplementEditorAction();

  useEffect(() => {
    if (folder) {
      let designOwners = [];
      folder.designOwners.map((designOwner) => {
        designOwners.push(userList.find((user) => user.userId === designOwner));
      });
      setDesignOwners(designOwners);
    }
  }, []);

  const designOwners = () => {
    return (
      <>
        <div className="pb-1 small-text font-regular">
          {intl.formatMessage({
            id: 'SUConfigView.EditFolderModal.DesignOwners',
            defaultMessage: 'Design Owners'
          })}
        </div>
        <div className="f-row f2-center gap-2 f-wrap">
          {_designOwners.map((designOwner) => {
            return (
              <div>
                <UserBadge
                  firstName={designOwner.firstname}
                  lastName={designOwner.lastname}
                  size={'small'}
                  title={
                    <div className="f-col">
                      <div className="font-regular">
                        {designOwner.firstname} {designOwner.lastname}
                      </div>
                      {designOwner.email}
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };
  const renderFolderInfo = () => {
    return (
      <>
        <div className="f-row py-2 ">
          <div className={`f-row f2-center gap-2`}>
            <Tooltip title={'Directory'}>
              <Icon name="folder" />
            </Tooltip>
            {folder?.name}
          </div>
        </div>

        <div className="w-10 border-bottom mb-2" />
      </>
    );
  };
  const referenceIconTitle = (sua) => {
    return (
      <div className="f-row f-full">
        <img
          alt="reference"
          style={{
            objectFit: 'contain',
            borderRadius: 4,
            width: 300,
            height: 300,
            backgroundColor: '#f7faff'
          }}
          src={
            config.BlobURL +
            '/single-use-configurator/assemblies/thumbnail/' +
            sua.general.id +
            '.png?cache=' +
            sua.general.modifiedOn
          }
        />
        <div
          className="f-col f1-start f-full px-2"
          style={{
            width: 300,
            height: 300,
            overflowY: 'auto',
            alignItems: 'stretch'
          }}>
          <div className="f-row f1-between f2-center f-wrap gap-1 mt-1">
            <div title={sua.general.name} className="font-regular max-lines-1">
              {sua.general.name}
            </div>
            <ThinChip
              className="f-shrink-0"
              label={
                sua.general.status ? (
                  <div className="capitalize">{sua.general.status}</div>
                ) : (
                  'No status'
                )
              }
              color={genericStatusColors(sua.general.status)}
              backgroundColor={genericStatusBackgroundColors(sua.general.status)}
            />
          </div>
          <div>{folder && renderFolderInfo()}</div>
          <div className="mb-2">{folder && _designOwners && designOwners()}</div>

          <div className="font-regular py-1">{intl.formatMessage(configMessages.functionsPFD)}</div>
          {sua.general.functions.map((fun) => {
            return (
              <div key={fun} className="f-row f2-center gap-2 mb-2">
                {CUSTOM_FUNCTIONS[fun]?.form(16)}
                <ThinChip
                  fontSize="smallText"
                  className="capitalize f-full"
                  style={{ cursor: 'pointer' }}
                  label={fun}
                  color={'var(--secondaryColor)'}
                  backgroundColor={'var(--secondaryColorBcg)'}
                />
              </div>
            );
          })}
          <div className="font-regular py-1">SUA components</div>
          {componentList
            .filter((comp: any) => comp.type === 'generic')
            .map((comp: any) => {
              return <SingleComponent key={comp.id} component={comp} />;
            })}
          <div className="font-regular py-1">Tubings</div>
          {componentList
            .filter((comp: any) => comp.type === 'genericedge')
            .map((comp: any) => {
              return <SingleComponent component={comp} />;
            })}
        </div>
      </div>
    );
  };

  function getRefParams(reference: any, value: any) {
    const supplier = Object.values(SUPPLIERS).find(
      (supplier: Supplier) => supplier.id === reference
    );
    return supplier?.name || 'N/C';
  }

  function changeToReference(sua: any): void {
    const realSelectedComponent = components.find((c: any) => c.id === selectedComponent.id);
    implementComponent(
      realSelectedComponent,
      sua,
      components,
      false,
      layerMode,
      editorAssemblyLibrary
    );
  }

  const isObsolete = sua.general.status === ReferenceStatus.Obsolete;

  return (
    <Label
      className={`container-action f-col`}
      style={{
        position: 'relative',
        cursor: isObsolete || isReferenceAccessible ? 'pointer' : 'default'
      }}
      shadow={false}>
      <IconTooltip
        color="var(--primaryColor)"
        // @ts-ignore
        tooltipWidth={600}
        style={{ position: 'absolute', top: '.2rem', right: '.2rem' }}
        title={<>{referenceIconTitle(sua)}</>}
      />
      <div
        className="f-col gap-2"
        onClick={
          sua.general.status === ReferenceStatus.Obsolete || !isReferenceAccessible
            ? undefined
            : () => changeToReference(sua)
        }
        onMouseEnter={() => setReferenceHovered(sua.general.id)}
        onMouseLeave={() => setReferenceHovered(null)}>
        <div className="f-row f2-center gap-2 w-100">
          {!isReferenceAccessible && (
            <div
              className="f-row f1-center"
              style={{
                zIndex: 100,
                backgroundColor: 'var(--light-orange)',
                borderRadius: 50,
                width: 22,
                height: 22
                // border: '1px solid var(--orange)'
              }}>
              <IconTooltip
                name="alert"
                color="var(--orange)"
                title={'You do not have access to this reference'}
              />
            </div>
          )}
          <ThinChip
            color={
              isObsolete || !isReferenceAccessible ? 'var(--frozen-grey)' : 'var(--primaryColor)'
            }
            backgroundColor={
              isObsolete || !isReferenceAccessible ? 'var(--light-grey)' : 'var(--primaryColorBcg)'
            }
            label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
              sua.general.version + 1 || '1'
            }`}
          />
          <ThinChip
            color={
              isObsolete || !isReferenceAccessible
                ? 'var(--frozen-grey)'
                : referenceStatusColors(sua.general.status || null)
            }
            backgroundColor={
              isObsolete || !isReferenceAccessible
                ? 'var(--light-grey)'
                : referenceStatusBackgroundColors(sua.general.status || null)
            }
            label={<span className="capitalize">{sua.general.status || 'No status'}</span>}
          />
        </div>
        <div className="f-row f1-between f2-center w-100">
          <div
            className="font-regular max-lines-1"
            style={{
              color: isObsolete || !isReferenceAccessible ? 'var(--frozen-grey)' : undefined
            }}>
            {sua.general.name}
          </div>
        </div>
        <ThinChip
          color={'var(--anthracite)'}
          className="max-lines-1"
          label={getRefParams(sua.general.supplier, 'supplier') || null}
        />
      </div>
    </Label>
  );
};

export default SingleReference;
