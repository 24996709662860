import FunctionNode from '../node/FunctionNode';
import GenericNode from '../node/GenericNode';
import GenericOnEdge from '../node/GenericOnEdgeNode';
import SelectionNode from '../node/SelectionNode';
import GhostNode from '../node/GhostNode';
import UpdateNode from '../node/UpdateNode';
import AssemblyNode from '../node/AssemblyNode';

export const nodeTypes = {
  generic: GenericNode,
  genericonedge: GenericOnEdge,
  function: FunctionNode,
  selection: SelectionNode,
  ghost: GhostNode,
  update: UpdateNode,
  assembly: AssemblyNode
};
