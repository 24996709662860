export type Supplier = {
  id: string;
  name: string;
};

export const SUPPLIERS: Supplier[] = [
  {
    id: '7720dcf8-63fd-4105-bbc5-3145f182be83',
    name: 'ABEC'
  },
  {
    id: 'e0a2a2c2-ed4e-4698-b275-6a21f992dd39',
    name: 'CYTIVA - Danaher Group'
  },
  {
    id: '86db024d-0dc9-4194-ada8-7c5a94d1eba2',
    name: 'MEISSNER FILTRATION'
  },
  {
    id: 'e2c40703-775c-4dc9-b31a-96bbfb3f8236',
    name: 'PALL-Danaher Group'
  },
  {
    id: '4790c527-77b4-4399-86e6-5aa6919e9d62',
    name: 'REPLIGEN'
  },
  {
    id: '1850b398-b27c-4f64-976e-58468633ab19',
    name: 'SARTORIUS AG'
  },
  {
    id: '9a5f632f-fc86-467b-b9f4-5896fe42a09f',
    name: 'THERMO FISHER SCIENTIFIC'
  }
];
