import { FC, memo, useEffect, useRef, useState } from 'react';

import { NodeProps } from 'react-flow-renderer';
import { NodeData } from '../utility/NodeData';
import { useAppSelector } from '../../../store';
import { CUSTOM_FUNCTIONS } from '../../../constants/CUSTOM_FUNCTIONS';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { Icon } from 'hakobio-react-ui';
import { groupBySuaNamePnid } from '../../ReportPanel/SchemaPDF/utils';

const AssemblyNode: FC<NodeProps<NodeData>> = (props) => {
  const { id } = props;

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const suaCount = useAppSelector((state) => state.assemblyEditorSlice.suaCount);
  const isSavingScreenshotAnnotatedPID = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotUOAnnotatedPID
  );
  const [_isFrameHovered, setFrameHovered] = useState<boolean>(false);
  const componentRef = useRef(null);
  const componentsRef = useRef<any>(null);
  const layerModeRef = useRef<any>(null);
  const editorModeRef = useRef<any>(null);
  const theme = useAppSelector((state) => state.assemblyEditorSlice.theme);

  layerModeRef.current = layerMode;
  editorModeRef.current = editorMode;
  componentsRef.current = components;

  const component = components.find((c) => c.id === id);
  componentRef.current = component;

  const functionComponent = components.find((c: any) => {
    return component?.data.component === c.id;
  });

  // retourne un tableau d'objet avec le nom de l'assembly et le nombre de fois qu'il apparait
  let countByAssembly = groupBySuaNamePnid(components, 'assembly');
  let indexAssembly = countByAssembly.findIndex(
    (c) =>
      c.name ===
      functionComponent?.data?.assembly?.general
        ?.name /* || c.name === functionComponent?.data?.assemblyReference?.general?.name */
  );

  let alphabetAssembly = null;
  if (indexAssembly !== -1) {
    let idToFind = functionComponent?.id;
    alphabetAssembly = countByAssembly[indexAssembly].letters.find(
      (c) => c.id === idToFind
    )?.letter;
  }

  const onFrameEnter = () => {
    setFrameHovered(true);
  };

  const onFrameLeave = () => {
    setFrameHovered(false);
  };
  let componentField;
  let viewField;

  switch (layerMode) {
    case UnitOperationLayer.PnID:
      componentField = 'assembly';
      viewField = 'pid';
      break;
    case UnitOperationLayer.Reference:
      if (functionComponent?.data.assembly && !functionComponent?.data.assemblyReference) {
        componentField = 'assembly';
        viewField = 'pid';
      } else {
        componentField = 'assemblyReference';
        viewField = 'reference';
      }
  }

  const renderNode = () => {
    let assemblyName;
    switch (layerMode) {
      case UnitOperationLayer.PnID:
        assemblyName = functionComponent.data[componentField]?.general.name;
        break;
      case UnitOperationLayer.Reference:
        switch (componentField) {
          case 'assembly':
            assemblyName = functionComponent.data.assembly?.general.name;
            break;
          case 'assemblyReference':
            assemblyName = (
              <div className={'f-row f1-center f2-center gap-1'}>
                <Icon
                  title={functionComponent.data.assemblyReference?.general.name}
                  name="label"
                  size={20}
                />
                {functionComponent.data.assemblyReference?.general.name}
              </div>
            );
            break;
        }
        break;
    }

    let center = { x: component?.viewer2D?.size?.x / 2, y: component?.viewer2D?.size?.y / 2 };

    if (isSavingScreenshotAnnotatedPID) {
      return (
        <div
          onMouseEnter={onFrameEnter}
          onMouseLeave={onFrameLeave}
          style={{
            height: component?.viewer2D?.size?.y,
            width: component?.viewer2D?.size?.x,
            position: 'relative',
            cursor: 'pointer'
          }}>
          <div
            style={{
              height: component?.viewer2D?.size?.y / 2 - 40,
              width: '1px',
              position: 'absolute',
              backgroundColor: 'black',
              top: 0,
              left:
                indexAssembly + 1 < 10
                  ? component?.viewer2D?.size?.x / 2 - 14
                  : component?.viewer2D?.size?.x / 2 + 10
            }}></div>
          <div
            className="f-row absolute f1-center f2-center gap-1"
            style={{
              color: 'black',
              top: -80,
              left: component?.viewer2D?.size?.x / 2 - 30,
              fontSize: suaCount < 7 ? 26 : 56,
              overflowY: 'visible'
              /* opacity: '18%' */
            }}>
            <div
              className="f-row f1-center f2-center gap-1 max-1-lines-visible"
              title={functionComponent.data.assembly?.general.name}>
              <span className="bold">{indexAssembly + 1}</span>
              {theme.team === 'sp' ? (
                <span className="font-regular"> {`[${alphabetAssembly}]`}</span>
              ) : (
                <span> {`${alphabetAssembly.toLowerCase()}`}</span>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          onMouseEnter={onFrameEnter}
          onMouseLeave={onFrameLeave}
          style={{
            height: component?.viewer2D?.size?.y,
            width: component?.viewer2D?.size?.x,
            border: '3px solid',
            borderColor: _isFrameHovered ? 'var(--primaryColor)' : 'var(--dark-grey)',
            position: 'relative',
            cursor: 'pointer',
            color: _isFrameHovered ? undefined : 'var(--frozen-grey)'
          }}>
          <div
            className="f-row absolute f1-center f2-center gap-1"
            style={{
              color: 'black',
              top: -100,
              fontSize: suaCount < 7 ? 26 : 76,
              overflowY: 'visible'
            }}>
            {CUSTOM_FUNCTIONS[functionComponent.data.type].form(26)}
            <div
              className="max-1-lines-visible"
              title={functionComponent.data.assembly?.general.name}>
              {assemblyName}
            </div>
          </div>
        </div>
      );
    }
  };

  if (component === undefined) {
    return null;
  }
  return (
    <div style={{ position: 'relative', pointerEvents: 'all' }}>
      <div>{renderNode()}</div>
    </div>
  );
};

export default memo(AssemblyNode);
