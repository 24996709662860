export enum DocumentTitle {
  SUD = 'Single-Use Designer',
  ComponentEditor = 'Component Editor',
  ComponentViewer = 'Component Viewer',
  SUAEditor = 'Functional Assembly Editor',
  SUAHistory = 'Functional Assembly Viewer',
  ReferenceEditor = 'Reference Assembly Editor',
  ReferenceHistory = 'Reference Assembly Viewer',
  UnitOperation = 'Unit Operation'
}
