import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import CameraManager from "../../../components/Shared/CameraManager";

export enum cameraModes { TWO, THREE }

export interface modeViewState {
    mode: cameraModes;
    isCameraInRotate: boolean;
}

const initialState: modeViewState = {
    mode: cameraModes.TWO,
    isCameraInRotate: false,
}

export const modeViewSlice = createSlice({
  name: "modeView",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === cameraModes.TWO ? cameraModes.THREE : cameraModes.TWO;
    },
    setCameraRotateOrTranslation: (state) => {
      let newState = _.assign({}, state);
      CameraManager.setRotate3d(!newState.isCameraInRotate);
      newState.isCameraInRotate = !newState.isCameraInRotate;  
      state.isCameraInRotate = newState.isCameraInRotate;    
    }
  },
});

export const { setMode } = modeViewSlice.actions;
export const { setCameraRotateOrTranslation } = modeViewSlice.actions;

export default modeViewSlice.reducer;
