import { Modal, SmallButton } from 'hakobio-react-ui';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { buttonSaveStyle } from '../components/EditorPanel/style/styles';
import { useAppDispatch, useAppSelector } from '../store';
import { assemblyEditorSliceActions } from '../store/features/assemblyEditor/assemblyEditorSlice';
import { EntityToDeleteTypes } from './enum/EntityToDeleteType';
import { useAssemblyEditorAction } from '../store/features/assemblyEditor/useAssemblyEditorAction';

interface Props {
  removeAssembly: Function;
  onRemoveComponents: Function;
}

const DeleteModal = ({ removeAssembly, onRemoveComponents }: Props) => {
  const [_isDelete, setIsDelete] = useState(false);
  const dispatch = useAppDispatch();
  const assemblyId = useAppSelector((state) => state.assemblyEditorSlice.showDeleteModal);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const showConfirmDeleteModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showConfirmDeleteModal
  );

  const { onGeneralEdited } = useAssemblyEditorAction();

  const intl = useIntl();

  const onCloseModal = () => {
    if (removeAssembly) dispatch(assemblyEditorSliceActions.setShowDeleteModal(null));
    else dispatch(assemblyEditorSliceActions.setShowDeleteConfirmModal(null));
  };

  const onDelete = async () => {
    if (showConfirmDeleteModal.type === EntityToDeleteTypes.URL) {
      let linkToDelete = general.medias.find((l) => l.id === showConfirmDeleteModal.entityId);
      let newArr = general.medias.filter((l) => !l.id.includes(linkToDelete.id));
      onGeneralEdited('medias', newArr);
    } else {
      if (onRemoveComponents) onRemoveComponents();
      else removeAssembly(assemblyId);
    }
    setIsDelete(true);
    if (!removeAssembly) {
      dispatch(assemblyEditorSliceActions.setShowDeleteModal(null));
      dispatch(assemblyEditorSliceActions.setShowDeleteConfirmModal(null));
    }
  };

  const renderFooter = () => {
    return (
      <SmallButton
        width={'150px'}
        color={'var(--red)'}
        style={buttonSaveStyle}
        onClick={onDelete}
        loading={_isDelete}>
        <FormattedMessage id="SUConfigView.DeleteModal.Delete" defaultMessage="Delete" />
      </SmallButton>
    );
  };

  const renderModalBody = () => {
    let body: any = '';
    let title: any = '';
    if (showConfirmDeleteModal) {
      switch (showConfirmDeleteModal.type) {
        case EntityToDeleteTypes.Assembly:
          body = intl.formatMessage(
            {
              id: 'SUConfigView.DeleteModal.Assembly',
              defaultMessage: 'Are you sure you want to delete <strong>{name}</strong>?'
            },
            {
              name: showConfirmDeleteModal.entityName,
              strong: (str) => <span className="font-regular">{str}</span>
            }
          );
          title = (
            <FormattedMessage
              id="DeleteModal.ConfirmationAssembly"
              defaultMessage="Delete Assembly"
            />
          );
          break;
        case EntityToDeleteTypes.CustomComponent:
          body = intl.formatMessage(
            {
              id: 'SUConfigView.DeleteModal.CustomComponent',
              defaultMessage:
                'Are you sure you want to delete the custom component <strong>{name}</strong>?'
            },
            {
              name: showConfirmDeleteModal.entityName,
              strong: (str) => <span className="font-regular">{str}</span>
            }
          );
          title = (
            <FormattedMessage
              id="DeleteModal.ConfirmationCustomComponent"
              defaultMessage="Delete Custom Component"
            />
          );
          break;
        case EntityToDeleteTypes.Component:
          body = intl.formatMessage(
            {
              id: 'SUConfigView.DeleteModal.Component',
              defaultMessage: 'Are you sure you want to delete <strong>{name}</strong>?'
            },
            {
              name: showConfirmDeleteModal.entityName,
              strong: (str) => <span className="font-regular">{str}</span>
            }
          );
          title = (
            <FormattedMessage
              id="DeleteModal.ConfirmationComponent"
              defaultMessage="Delete Component"
            />
          );
          break;
        case EntityToDeleteTypes.Function:
          body = intl.formatMessage(
            {
              id: 'SUConfigView.DeleteModal.Function',
              defaultMessage:
                'Are you sure you want to delete the function <strong>{name}</strong>?'
            },
            {
              name: showConfirmDeleteModal.entityName,
              strong: (str) => <span className="font-regular">{str}</span>
            }
          );
          title = (
            <FormattedMessage
              id="DeleteModal.ConfirmationFunction"
              defaultMessage="Delete Function"
            />
          );
          break;
        case EntityToDeleteTypes.Sua:
          body = intl.formatMessage(
            {
              id: 'SUConfigView.DeleteModal.SUAANDREF',
              defaultMessage:
                'Are you sure you want to delete this <strong>assembly</strong>?  If you delete this assembly, the reference associated to it will be removed from the unit operation.'
            },
            {
              strong: (str) => <span className="font-regular">{str}</span>
            }
          );
          title = <FormattedMessage id="DeleteModal.ConfirmationSUA" defaultMessage="Delete SUA" />;
          break;
        case EntityToDeleteTypes.Reference:
          body = intl.formatMessage(
            {
              id: 'SUConfigView.DeleteModal.REF',
              defaultMessage: 'Are you sure you want to delete this <strong>reference</strong>?'
            },
            {
              strong: (str) => <span className="font-regular">{str}</span>
            }
          );
          title = (
            <FormattedMessage
              id="DeleteModal.ConfirmationReference"
              defaultMessage="Delete Reference"
            />
          );
          break;
        case EntityToDeleteTypes.URL:
          body = intl.formatMessage(
            {
              id: 'SUConfigView.DeleteModal.URL',
              defaultMessage: 'Are you sure you want to delete <strong>{name}</strong>?'
            },
            {
              name: showConfirmDeleteModal.entityName,
              strong: (str) => <span className="font-regular">{str}</span>
            }
          );
          title = intl.formatMessage(
            {
              id: 'SUConfigView.DeleteModal.URL',
              defaultMessage: 'Delete <strong>{name}</strong>?'
            },
            {
              name: showConfirmDeleteModal.entityName,
              strong: (str) => <span className="font-regular">{str}</span>
            }
          );
          break;
        default:
          break;
      }
    } else {
      body = (
        <FormattedMessage
          id="SUConfigView.DeleteModal.Message"
          defaultMessage="Are you sure you want to delete this item?"
        />
      );
      title = <FormattedMessage id="DeleteModal.Confirmation" defaultMessage="Confirmation" />;
    }
    return { body, title };
  };

  const { body, title } = renderModalBody();
  return (
    <Modal title={title} cancel={onCloseModal} footer={renderFooter()}>
      {body}
    </Modal>
  );
};

export default DeleteModal;
