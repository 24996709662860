import { MenuItem } from '@mui/material';
import { DoubleChip, Select, TextInput } from 'hakobio-react-ui';
import { useAssemblyEditorAction } from '../../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { cloneDeep } from 'lodash';
import { useIntl } from 'react-intl';
import { generalMessages } from '../../../../../lang/messages';
import { getTubeColor } from '../../../../../services/tubing/getTubeColor';
import Color from 'color';

interface Props {
  displayEdition: any;
  keyProperty: string;
  property: any;
  selectedComponent: any;
}

export function NodePropertyString({
  displayEdition,
  keyProperty,
  property,
  selectedComponent
}: Props) {
  const { onComponentPropertyChanged } = useAssemblyEditorAction();

  const intl = useIntl();

  const renderTextField = () => {
    return (
      <div className="f-row gap-2 f2-center w-100">
        <TextInput
          focused={
            selectedComponent?.data?.[keyProperty] && selectedComponent?.data?.[keyProperty] !== ''
          }
          className="w-100"
          label={<span className="capitalize">{property.name}</span>}
          value={selectedComponent?.data?.[keyProperty]}
          onChange={(event: any) =>
            onPropertyChanged(selectedComponent, keyProperty, event.target.value)
          }
          endAdornment={property.units}
        />
      </div>
    );
  };

  const onPropertyChanged = (selectComponent, keyProperty, inputValue) => {
    let selectComponentClone = cloneDeep(selectComponent);
    if (inputValue === 'Weldable' || inputValue === 'High pressure') {
      if (
        selectComponent.data.componentMaterial !== 'Reinforced Silicone' &&
        selectComponent.data.contentMaterial !== 'Thermoplastic elastomer'
      ) {
        selectComponentClone.data.componentMaterial = null;
      }
    }

    onComponentPropertyChanged(selectComponentClone, keyProperty, inputValue);
  };

  const filterEnum = (property) => {
    let propertyCopy = cloneDeep(property);
    if (selectedComponent.data.componentType === 'Weldable') {
      if (property.description === 'Material of tubes') {
        propertyCopy = {
          ...propertyCopy,
          enum: ['Thermoplastic elastomer']
        };
      }
    } else if (selectedComponent.data.componentType === 'High pressure') {
      if (property.description === 'Material of tubes') {
        propertyCopy = {
          ...propertyCopy,
          enum: ['Reinforced Silicone']
        };
      }
    }
    return propertyCopy;
  };

  const renderSelect = () => {
    let propertyCopy = filterEnum(property);

    const menuItemList = propertyCopy.enum.map((e: any) => {
      let icon = null;
      if (selectedComponent.data.type === 'tubing' && keyProperty === 'componentType') {
        icon = (
          <div
            style={{
              width: 24,
              height: 12,
              borderRadius: 50,
              backgroundColor: getTubeColor(e)
            }}
          />
        );
      }
      if (selectedComponent.data.type === 'tubing' && keyProperty === 'componentMaterial') {
      }
      return (
        <MenuItem key={e} value={e} className="capitalize">
          <div className="f-row f2-center gap-2">
            {icon}
            {`${e} ${property.units || ''}`}
          </div>
        </MenuItem>
      );
    });

    menuItemList.push(
      <MenuItem key={'No value'} value={''} className="capitalize">
        <div className="f-row f2-center gap-2" style={{ color: 'var(--anthracite)' }}>
          {intl.formatMessage(generalMessages.noValue)}
        </div>
      </MenuItem>
    );

    return (
      <Select
        className={`capitalize`}
        inputSize="extraSmall"
        label={property.name}
        value={selectedComponent?.data?.[keyProperty] || property['default'] || ''}
        onChange={(event: any) => {
          onPropertyChanged(selectedComponent, keyProperty, event.target.value);
        }}>
        {menuItemList}
      </Select>
    );
  };

  const renderChip = () => {
    const value = selectedComponent?.data?.[keyProperty];

    return (
      <DoubleChip
        className="max-1-lines-visible"
        color={
          selectedComponent.data.type === 'tubing' && keyProperty === 'componentType' && value
            ? getTubeColor(value)
            : undefined
        }
        backgroundColor={
          selectedComponent.data.type === 'tubing' && keyProperty === 'componentType' && value
            ? Color(getTubeColor(value)).alpha(0.1).toString()
            : undefined
        }
        key={selectedComponent?.data?.[keyProperty]}
        label1={<span className="capitalize">{property.name}</span>}
        label2={
          value ? (
            selectedComponent.data.type === 'tubing' && keyProperty === 'componentType' ? (
              value
            ) : (
              value + ' ' + (property.units || '')
            )
          ) : (
            <span className="frozen-grey">{intl.formatMessage(generalMessages.noValue)}</span>
          )
        }
      />
    );
  };

  const render = () => {
    if (!displayEdition) {
      return renderChip();
    }
    if (property.readOnly) {
      return renderChip();
    }
    if (property.enum) {
      return renderSelect();
    }
    return renderTextField();
  };

  return render();
}
