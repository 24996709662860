import { assemblyEditorSliceActions } from '../store/features/assemblyEditor/assemblyEditorSlice';
import { Modal, Button } from 'hakobio-react-ui';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { useHistory } from 'react-router';
import { buttonSaveStyle } from '../components/EditorPanel/style/styles';

interface Props {
  closeModal: Function;
  showSaveModal: Function;
  saveButtonsDisabled: boolean;
}

const ExitModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);

  const onCloseEditor = () => {
    window.parent.postMessage({ message: 'deleteCross', value: 'show' }, '*');
    dispatch(assemblyEditorSliceActions.resetEditor(null));
    history.push('/list');
  };

  const onCloseModal = () => {
    props.closeModal();
  };

  const renderFooter = () => {
    return (
      <div
        className="gap-2"
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Button
          width={'auto'}
          color="var(--secondaryColor)"
          inverse={true}
          style={buttonSaveStyle}
          onClick={onCloseEditor}>
          <FormattedMessage
            id="SUConfigView.ExitWithoutSaving"
            defaultMessage="Exit without saving"
          />
        </Button>
        <Button
          disabled={props.saveButtonsDisabled}
          width={'auto'}
          color={'var(--primaryColor)'}
          style={buttonSaveStyle}
          onClick={props.showSaveModal}>
          <div className="f-center gap-2">
            <span style={{ fontSize: '1rem' }}>
              {' '}
              <FormattedMessage id="SUConfigView.SaveAndExit" defaultMessage="Save and exit" />
            </span>
          </div>
        </Button>
      </div>
    );
  };

  const render = () => {
    const title = (
      <FormattedMessage
        id="SUConfigView.WantToSaveYourChanges"
        defaultMessage="Want to save your changes?"
      />
    );

    return (
      <Modal title={title} cancel={onCloseModal} footer={renderFooter()}>
        {
          <FormattedMessage
            id="SUConfigView.AnyUnsavedChangesWillBeLost"
            defaultMessage="Any unsaved changes will be lost."
          />
        }
      </Modal>
    );
  };

  return render();
};

export default ExitModal;
