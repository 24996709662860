import { RuleComponentEdgeComponent } from '../RuleComponentEdgeComponent';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';

export class RulePlugFunctionPlug extends RuleComponentEdgeComponent {
  schemaComponentSourceList = ['plug'];
  schemaComponentTargetList = ['plug'];
  schemaTubeList = ['transferring'];
  editorList = ['UOE_PID'];

  sourceConnector =
    this.componentSource?.data.assembly?.components?.find(
      (comp) => comp.id === this.componentSourceAnchor.data.componentLink?.component
    ) || null;

  targetConnector =
    this.componentTarget?.data.assembly?.components?.find(
      (comp) => comp.id === this.componentTargetAnchor.data.componentLink?.component
    ) || null;

  checkPrerequisite = () => {
    const isTube = this.schemaTubeList.includes(this.edge.data.type);
    const isSourcePlug = this.sourceConnector
      ? this.schemaComponentSourceList.includes(this.sourceConnector.data.type)
      : false;
    const isTargetPlug = this.targetConnector
      ? this.schemaComponentTargetList.includes(this.targetConnector.data.type)
      : false;

    if (isTube && (isSourcePlug || isTargetPlug)) {
      return true;
    }
    return false;
  };

  checkRule = () => {
    if (
      (this.targetConnector?.data.type === 'plug' &&
        this.sourceConnector?.data.type !== 'plug' &&
        this.sourceConnector?.type === 'generic') ||
      (this.sourceConnector?.data.type === 'plug' &&
        this.targetConnector?.data.type !== 'plug' &&
        this.targetConnector?.type === 'generic')
    ) {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible component',
        errors: `${CUSTOM_NODES[this.sourceConnector.data.type].name} can't be connected to ${
          CUSTOM_NODES[this.targetConnector.data.type].name
        }`,
        componentAnchors: [
          //@ts-ignore
          { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id },
          //@ts-ignore
          { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
        ]
      });
    }
  };
}
