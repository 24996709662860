import { useState } from 'react';
import * as htmlToImage from 'html-to-image';

/**
 * @module Main_Hook
 * Hook return
 * @typedef {Array} HookReturn
 * @property {string} HookReturn[0] - image string
 * @property {string} HookReturn[1] - take screen shot string
 * @property {object} HookReturn[2] - errors
 */

/**
 * hook for creating screenshot from html node
 * @returns {HookReturn}
 */
const useScreenshot = (quality) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  /**
   * convert html node to image
   * @param {HTMLElement} node
   */
  const takeScreenshot = (node) => {
    if (!node) {
      throw new Error('You should provide correct html node.');
    }

    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        setImage(dataUrl);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });

    // htmlToImage.toPng(node, { pixelRatio: quality || 3 }).then((dataUrl) => {
    //   setImage(dataUrl);
    // });
  };

  const clearScreenshot = () => {
    setImage(null);
  };

  return [
    image,
    takeScreenshot,
    clearScreenshot,
    {
      error
    }
  ];
};

/**
 * creates name of file
 * @param {string} extension
 * @param  {string[]} parts of file name
 */
const createFileName = (extension = '', ...names) => {
  if (!extension) {
    return '';
  }

  return `${names.join('')}.${extension}`;
};

export { useScreenshot, createFileName };
