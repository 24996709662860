import React, { DragEventHandler, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Connection,
  ConnectionMode,
  Edge,
  Elements,
  OnEdgeUpdateFunc,
  OnLoadFunc,
  OnLoadParams,
  ReactFlowProps,
  useStoreActions,
  useStoreState
} from 'react-flow-renderer';

import { getAllAssembliesComplete, savePNGAssembly } from '../../services/editor/editorService';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../store/features/assemblyEditor/useAssemblyEditorAction';
import { Uuidv4 } from '../../utilities';

import { SplashScreen } from 'hakobio-react-ui';
import { cloneDeep } from 'lodash';
import { UnitOperationLayer } from '../../constants/PFD_EquipmentTabs';
import { convertToRead } from '../../services/editor/editorServiceUtilities';
import { useAppDispatch, useAppSelector } from '../../store';
import { useDeletionEditorAction } from '../../store/features/assemblyEditor/useDeletionEditorAction';
import { useImplementEditorAction } from '../../store/features/assemblyEditor/useImplementEditorAction';
import { useScreenshot } from '../../utilities/useScreenshot';
import { createSelectionNode } from './utils/createSelectionNode';
import { createSingleSUAFrame, createSUAFrames, updateSingleSUAFrame } from './utils/editSUAFrames';
import DeleteModal from '../../views/DeleteModal';
import { edgeTypes } from './enum/EdgeTypes';
import { nodeTypes } from './enum/NodeTypes';
import './Flow.css';
import { getElementsPFD } from './utils/getElementsPFD';
import { getElementsPID } from './utils/getElementsPID';
import { createUpdateNode, updateUpdateNode } from './utils/createUpdateNode';
import { EntityToDeleteTypes } from '../../views/enum/EntityToDeleteType';
import { DocumentTitle } from '../../constants/DocumentTitle';
import { getElementsReference } from './utils/getElementsReference';

const FlowEditorUO = (props) => {
  const componentsRef = useRef<any>(null);
  const layerModeRef = useRef<any>(null);
  const isLayerLockedRef = useRef<any>(null);
  const promiseUpdateComponentRef = useRef<any>(null);
  const { changeSelectionUO, setHoverEdge, unsetHoverEdge, setHoverOnEdge } = useAssemblyEditorAction();
  const { reactiveGhost } = useImplementEditorAction();

  const { removeComponents } = useDeletionEditorAction();
  const { clearAssembly } = useImplementEditorAction();
  const dispatch = useAppDispatch();

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const preShotLayerMode = useAppSelector((state) => state.assemblyEditorSlice.preShotLayerMode);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);

  const tubingData = useAppSelector((state) => state.assemblyEditorSlice.lastTubingDetails);
  const promiseUpdateComponent = useAppSelector((state) => state.assemblyEditorSlice.promiseUpdateComponent);
  const isSavingScreenshot = useAppSelector((state) => state.assemblyEditorSlice.isSavingScreenshotUO);
  const isSavingScreenshotAnnotatedPFD = useAppSelector((state) => state.assemblyEditorSlice.isSavingScreenshotUOAnnotatedPFD);
  const isSavingScreenshotAnnotatedPID = useAppSelector((state) => state.assemblyEditorSlice.isSavingScreenshotUOAnnotatedPID);

  const isSavingAssembly = useAppSelector((state) => state.assemblyEditorSlice.isSavingAssembly);
  const isLayerLocked = useAppSelector((state) => state.assemblyEditorSlice.isLayerLocked);
  const showConfirmDeleteModal = useAppSelector((state) => state.assemblyEditorSlice.showConfirmDeleteModal);

  const [loadingElements, setLoadingElements] = useState(false);
  const loadingElementsTimer = 1500 + 12 * components.length
  const selectedElements = useStoreState((store) => store.selectedElements);
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  const selectedComponents = useAppSelector((state) => state.assemblyEditorSlice.selectedComponents);

  const areSUAFramesDisplayed = useAppSelector((state) => state.assemblyEditorSlice.areSUAFramesDisplayed);

  const outdatedAssemblies = useAppSelector((state) => state.assemblyEditorSlice.outdatedAssemblies);

  const hoveredComponent = useAppSelector((state) => state.assemblyEditorSlice.hoveredComponent);

  componentsRef.current = components;
  layerModeRef.current = layerMode;
  isLayerLockedRef.current = isLayerLocked;
  promiseUpdateComponentRef.current = promiseUpdateComponent;

  const dragComponentRef = useRef<any | null>(null);

  const dragPositionRef = useRef<any | null>(null);

  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
  const [elements, setElements] = useState<any>([]);
  const [_suaCount, setSuaCont] = useState(null);
  const [isDragging, setDragging] = useState<boolean>(false);
  const [_isMoving, setIsMoving] = useState<boolean>(false);
  const [image, takeScreenshot, clearScreenshot] = useScreenshot(1);
  const [imageAnnotatedPFD, takeScreenshotAnnotatedPFD, clearScreenshotAnnotatedPFD] = useScreenshot(4);
  const [imageAnnotatedPID, takeScreenshotAnnotatedPID, clearScreenshotAnnotatedPID] = useScreenshot(4);
  const [_ghostList, setGhostNodeList] = useState<any>([]);
  const [_nodeDragStop, setNodeDragStop] = useState(null);

  useEffect(() => {
    const onKeyDown = (event) => {
      switch (layerMode) {
        case UnitOperationLayer.PFD:
          if (event.key === 'Delete' && selectedComponents?.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            dispatch(assemblyEditorSliceActions.setShowDeleteConfirmModal({
              entityId: selectedComponents?.[0].id, entityName: selectedComponents?.[0].name, type: EntityToDeleteTypes.Function
            }));
          }
          break;
        default:
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [selectedComponents, layerMode]);

  useEffect(() => {
    window.parent.postMessage({ message: 'message', value: DocumentTitle.UnitOperation }, '*');
  }, []);

  useEffect(() => {
    let newElements = [];
    switch (layerMode) {
      case UnitOperationLayer.PFD:
        newElements = getElementsPFD(componentsRef.current, null);
        break;
      case UnitOperationLayer.PnID:
        newElements = getElementsPID(componentsRef.current, null);
        break;
      case UnitOperationLayer.Reference:
        newElements = getElementsReference(componentsRef.current, null);
        break;
    }
    setElements(newElements);
    if (layerMode === UnitOperationLayer.PnID) {
      setSuaCount();
    }
  }, [components]);

  const setSuaCount = () => {
    let functionCount = 0;
    components.forEach((el) => {
      if (el.type === 'function') {
        ++functionCount;
      }
    });
    dispatch(assemblyEditorSliceActions.setSuaCount(functionCount));
  };

  const setElementsAsync = () => {
    let newElements = [];
    switch (layerMode) {
      case UnitOperationLayer.PFD:
        newElements = getElementsPFD(componentsRef.current, null);
        break;
      case UnitOperationLayer.PnID:
        newElements = getElementsPID(componentsRef.current, null);
        break;
      case UnitOperationLayer.Reference:
        newElements = getElementsReference(componentsRef.current, null);
        break;
    }
    setLoadingElements(true);
    setElements(newElements);
    setTimeout(() => {
      setLoadingElements(false);
    }, loadingElementsTimer * 2);
  };

  useEffect(() => {
    const ghostNodeList = components.filter((c) => c.type === 'ghost');
    setGhostNodeList(ghostNodeList);
    ghostNodeList?.forEach((g) => {
      const frameToRemove = components.find((c) => c.type === 'assembly' && c.data.component === g.data.function);
      if (frameToRemove) dispatch(assemblyEditorSliceActions.removeComponents([frameToRemove]));
    });
  }, [components]);

  useEffect(() => {
    setElementsAsync();
  }, []);

  useEffect(() => {
    setElementsAsync();
  }, [layerMode]);

  useEffect(() => {
    setSelectedElements([]);
    dispatch(assemblyEditorSliceActions.selectComponents([]));
    const assemblyToRemove = components.filter((c) => c.type === 'selection');
    if (assemblyToRemove) dispatch(assemblyEditorSliceActions.removeComponents([...assemblyToRemove]));
    setTimeout(() => {
      reactFlowInstance?.fitView({ padding: 0.3, includeHiddenNodes: false });
    }, loadingElementsTimer * 2);
  }, [layerMode]);

  useEffect(() => {
    const promiseUpdateComponentLength = promiseUpdateComponentRef.current.length;
    const componentsCopy = cloneDeep(componentsRef.current);
    if (promiseUpdateComponentRef.current.length !== 0 && !isDragging) {
      promiseUpdateComponentRef.current.forEach((promise: any) => {
        if (promiseUpdateComponentLength === promiseUpdateComponentRef.current.length) {
          //dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
          const index = componentsCopy.findIndex((component) => component.id === promise.component.id);
          if (index !== -1) {
            componentsCopy[index] = cloneDeep(promise.component);
          }
        }
      });
      if (promiseUpdateComponentLength === promiseUpdateComponentRef.current.length) {
        dispatch(assemblyEditorSliceActions.setComponents(componentsCopy));
        dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
      }
    }
  }, [promiseUpdateComponent]);

  useEffect(() => {
    // Vérification si l'on se trouve en pnid ou reference pour aller chercher dans la bonne liste (const suaFunctionId = _nodeDragStop.data?.[assemblyKey]?.component;)
    let assemblyKey = '';
    switch (layerMode) {
      case UnitOperationLayer.PnID:
        assemblyKey = 'assembly';
        break;
      case UnitOperationLayer.Reference:
        assemblyKey = 'assemblyReference';
        break;
    }

    if (_nodeDragStop && _nodeDragStop.type !== 'selection') {
      const suaFunctionId = _nodeDragStop.data?.[assemblyKey]?.component;
      const updateNode = components.find((c) => c.type === 'update' && c.data.component === suaFunctionId);
      if (updateNode) {
        const updateNodeNew = cloneDeep(updateUpdateNode(updateNode, suaFunctionId, components, layerMode));
        dispatch(assemblyEditorSliceActions.updateComponent(updateNodeNew));
      } else if (areSUAFramesDisplayed) {
        const assemblyFrame = components.find((c) => c.type === 'assembly' && c.data.component === suaFunctionId);
        const selectionFrame = components.find((c) => c.type === 'selection' && c.data.component === suaFunctionId);
        if (!selectionFrame && assemblyFrame) {
          const assemblyNode = updateSingleSUAFrame(assemblyFrame, components, suaFunctionId, layerMode);
          dispatch(assemblyEditorSliceActions.updateComponent(assemblyNode));
        }
      }
    }
  }, [_nodeDragStop]);

  useEffect(() => {
    const setupUpdateNodes = async () => {
      let componentsCopy = cloneDeep(componentsRef.current);

      const updateAndDispatch = async (components) => {
        const { updateNodeList, nodeList } = await setUpdateNodesInList(componentsCopy, layerMode);
        updateNodeList.forEach((updateNode) => {
          dispatch(assemblyEditorSliceActions.addComponent(updateNode));
        });
        const outdatedAssembliesList = updateNodeList.map((n) => n.data.component);
        dispatch(assemblyEditorSliceActions.setOutdatedAssemblies(outdatedAssembliesList));
      };

      const nodeList = cleanUpdateNodesFromList(componentsCopy);
      dispatch(assemblyEditorSliceActions.removeComponents(nodeList));
      if (layerMode === UnitOperationLayer.PFD) {
      } else if (!isSavingAssembly && (layerMode === UnitOperationLayer.PnID || layerMode === UnitOperationLayer.Reference)) {
        await updateAndDispatch(cleanUpdateNodesFromList(componentsCopy));
      }
    };
    setupUpdateNodes();
  }, [layerMode, isSavingAssembly]);

  useEffect(() => {
    if (areSUAFramesDisplayed) {
      const suaFrames = createSUAFrames(components, layerMode);
      suaFrames.forEach((frame) => {
        const isUpdateNode = outdatedAssemblies?.find((oa) => oa === frame.data.component);
        const hasGhostNode = _ghostList?.find((ghost) => ghost.data.function === frame.data.component);
        const hasAssemblyNode = components?.find((c) => c.type === 'assembly' && c.data.component === frame.data.component);
        if (!isUpdateNode && !hasGhostNode && !hasAssemblyNode) {
          dispatch(assemblyEditorSliceActions.addComponent(frame));
        }
      });
    } else {
      if (_ghostList.length) {
        _ghostList.forEach((ghost) => {
          const updateNodeToRemove = components.find((c) => c.type === 'update' && c.data.component === ghost.data.function);
          if (updateNodeToRemove) {
            dispatch(assemblyEditorSliceActions.removeComponents([updateNodeToRemove]));
          }
        });
      }
      const assemblyComponents = elements.filter((el) => el.type === 'assembly');
      dispatch(assemblyEditorSliceActions.removeComponents([...assemblyComponents]));
    }
  }, [areSUAFramesDisplayed, _ghostList.length]);

  useEffect(() => {
    const takeScreenshotAsync = () => {
      reactFlowInstance.fitView({ padding: 0.3, includeHiddenNodes: false });
      setTimeout(() => {
        takeScreenshot(reactFlowWrapper.current);
      }, loadingElementsTimer);
    };
    if (isSavingScreenshot && reactFlowInstance && reactFlowWrapper.current) {
      takeScreenshotAsync();
    }
  }, [isSavingScreenshot]);

  useEffect(() => {
    const takeScreenshotAsync = () => {
      setTimeout(() => {
        reactFlowInstance.fitView({ padding: 0.3, includeHiddenNodes: false });
        setTimeout(() => {
          takeScreenshotAnnotatedPFD(reactFlowWrapper.current);
        }, loadingElementsTimer);
      }, loadingElementsTimer * 2);
    };
    if (isSavingScreenshotAnnotatedPFD && reactFlowInstance && reactFlowWrapper.current) {
      takeScreenshotAsync();
    }
  }, [isSavingScreenshotAnnotatedPFD]);

  useEffect(() => {
    let paddingResize = components.length < 8 ? 1.1 : 0.3;
    dispatch(assemblyEditorSliceActions.setOutdatedAssemblies([]));
    const takeScreenshotAsync = () => {
      dispatch(assemblyEditorSliceActions.setSUAFramesDisplayed(true));
      setTimeout(() => {
        reactFlowInstance.fitView({ padding: paddingResize, includeHiddenNodes: false });
        setTimeout(() => {
          takeScreenshotAnnotatedPID(reactFlowWrapper.current);
        }, loadingElementsTimer);
      }, loadingElementsTimer * 2);
    };
    if (isSavingScreenshotAnnotatedPID && reactFlowInstance && reactFlowWrapper.current) {
      takeScreenshotAsync();
    }
  }, [isSavingScreenshotAnnotatedPID]);

  useEffect(() => {
    const handleScreenshot = async () => {
      if (image) {
        // Example call:
        //var file = dataURLtoFile(image,'screenshot.svg');
        await savePNGAssembly(general.id, image);
        clearScreenshot();
        dispatch(assemblyEditorSliceActions.setSavingScreenshotUO(false));
        dispatch(assemblyEditorSliceActions.setLayerMode(UnitOperationLayer.PFD));
        dispatch(assemblyEditorSliceActions.setSavingScreenshotUOAnnotatedPFD(true));
      }
    };
    handleScreenshot();
  }, [image]);

  useEffect(() => {
    const handleScreenshot = async () => {
      if (imageAnnotatedPFD) {
        // Example call:
        //var file = dataURLtoFile(imageAnnotatedPFD,'screenshot.svg');
        await savePNGAssembly(general.id + '_UO_Annotated_PFD', imageAnnotatedPFD);
        clearScreenshotAnnotatedPFD();
        dispatch(assemblyEditorSliceActions.setSavingScreenshotUOAnnotatedPFD(false));
        dispatch(assemblyEditorSliceActions.setLayerMode(UnitOperationLayer.PnID));
        dispatch(assemblyEditorSliceActions.setSavingScreenshotUOAnnotatedPID(true));
      }
    };
    handleScreenshot();
  }, [imageAnnotatedPFD]);

  useEffect(() => {
    const handleScreenshot = async () => {
      if (imageAnnotatedPID) {
        // Example call:
        //var file = dataURLtoFile(imageAnnotatedPID,'screenshot.svg');
        await savePNGAssembly(general.id + '_UO_Annotated_PID', imageAnnotatedPID);
        clearScreenshotAnnotatedPID();
        dispatch(assemblyEditorSliceActions.setSavingScreenshotUOAnnotatedPID(false));
        dispatch(assemblyEditorSliceActions.setLayerMode(preShotLayerMode));
        setTimeout(() => {
          dispatch(assemblyEditorSliceActions.setEditingAssembly(false));
        }, 1000);
      }
    };
    handleScreenshot();
  }, [imageAnnotatedPID]);

  useEffect(() => {
    window.parent.postMessage({ message: 'deleteCross', value: 'hide' }, '*');
    dispatch(assemblyEditorSliceActions.setEditingAssembly(false));
  }, []);

  useEffect(() => {
    switch (layerMode) {
      case UnitOperationLayer.PFD:
        //No need to do anything
        break;
      case UnitOperationLayer.PnID:
      case UnitOperationLayer.Reference:
        if (hoveredComponent) {
          const suaFunction = components.find((c) => hoveredComponent === c.id);
          if (suaFunction.data.assembly || suaFunction.data.assemblyReference) {
            const newSelectionNode = createSelectionNode(suaFunction, componentsRef.current, false, layerMode);
            dispatch(assemblyEditorSliceActions.addComponent(newSelectionNode));
          }
        } else {
          const selectionNode = components.find((c) => c.type === 'selection');
          if (selectionNode) dispatch(assemblyEditorSliceActions.removeComponents([selectionNode]));
        }
        break;
    }
  }, [hoveredComponent]);

  const cleanUpdateNodesFromList = (nodeList: any[]) => {
    return components.filter((el) => el.type === 'update');
  };

  const setUpdateNodesInList = async (nodeList: any[], layer: UnitOperationLayer) => {
    const suaComponents = nodeList.filter((el) => el.type !== 'update' && el.type !== 'function' && el.type !== 'functionedge');

    let assemblyKey = 'assembly';
    switch (layer) {
      case UnitOperationLayer.PnID:
        assemblyKey = 'assembly';
        break;
      case UnitOperationLayer.Reference:
        assemblyKey = 'assemblyReference';
        break;
    }

    const suaFunctions = nodeList.filter((el) => el.type === 'function' && el.data[assemblyKey]);

    const componentToUpdateList = [];
    let readableAssemblyList;

    let updateNodeList = [];

    let assemblyList = await getAllAssembliesComplete();
    readableAssemblyList = assemblyList.map((assembly) => {
      return convertToRead(assembly);
    });
    if (readableAssemblyList) {
      suaFunctions.forEach((functionAssembly) => {
        const options = {
          year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
        };
        // @ts-ignore
        const functionDate = new Date(functionAssembly.data[assemblyKey].general.modifiedOn
          // @ts-ignore
        ).toLocaleDateString(undefined, options);
        const assembly = readableAssemblyList.find((a) => functionAssembly.data[assemblyKey].general.id === a.general.id);
        if (!assembly) {
          return;
        }
        // @ts-ignore
        const assemblyDate = new Date(assembly.general.modifiedOn).toLocaleDateString(undefined, // @ts-ignore
          options);
        if (functionDate !== assemblyDate) {
          componentToUpdateList.push({ function: functionAssembly, updatedSua: assembly });
        }
      });
    }
    componentToUpdateList.forEach((f) => {
      const updateNode = createUpdateNode(f.function.id, suaComponents, layerMode);
      updateNodeList.push(updateNode);
      nodeList.unshift(updateNode);
      //dispatch(assemblyEditorSliceActions.unshiftComponent(updateNode));
    });
    return { updateNodeList, nodeList };
  };

  const resetFramesSelection = () => {
    const selectionNode = components.find((c) => c.type === 'selection');
    const suaFunctionId = selectionNode?.data.component;

    if (selectionNode) {
      const hasOutdatedAssembly: any = outdatedAssemblies.find((a: any) => a === suaFunctionId);
      if (hasOutdatedAssembly) {
        const newUpdateNode = createUpdateNode(suaFunctionId, components, layerMode);
        dispatch(assemblyEditorSliceActions.addComponent(newUpdateNode));
      }
      if (areSUAFramesDisplayed && !hasOutdatedAssembly) {
        const assemblyFrame = createSingleSUAFrame(suaFunctionId, components, layerMode);
        dispatch(assemblyEditorSliceActions.addComponent(assemblyFrame));
      }
      dispatch(assemblyEditorSliceActions.removeComponents([selectionNode]));
    }
  };

  const unselectAll = () => {
    resetFramesSelection();
    setSelectedElements([]);
    dispatch(assemblyEditorSliceActions.selectComponents([]));
    dispatch(assemblyEditorSliceActions.selectedEdges(null));
  };

  const onLoad: OnLoadFunc = (_reactFlowInstance) => {
    setTimeout(() => {
      _reactFlowInstance?.fitView({ padding: 0.25, includeHiddenNodes: false });
    }, loadingElementsTimer);
    setReactFlowInstance(_reactFlowInstance);
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    dispatch(assemblyEditorSliceActions.setEditingAssembly(true));
    if (event.dataTransfer && reactFlowInstance && reactFlowWrapper.current) {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const constant = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      if (constant.node.snappable /* || constant.key === 'sensor' */) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left, y: event.clientY - reactFlowBounds.top
      });

      let newNode;
      if (constant.customComponent) {
        newNode = {
          id: Uuidv4(), type: constant.type, viewer2D: {
            pfd: { ...position }, pid: { ...position }, reference: { ...position }, size: constant.node.size
          }, data: constant.customComponent.data
        };
      } else {
        newNode = {
          id: Uuidv4(), type: constant.type, viewer2D: {
            pfd: { ...position }, pid: { ...position }, reference: { ...position }, size: constant.node.size
          }, data: {
            type: constant.key, anchors: constant.node.anchors ? constant.node.anchors.map((a: any) => {
              return { ...a, id: Uuidv4() };
            }) : []
          }
        };
      }

      dispatch(assemblyEditorSliceActions.addComponent(newNode));
    }
  };

  const multiSelect = () => {
    //@ts-ignore
    if (navigator.userAgentData?.platform.toLowerCase() === 'macOS') {
      return 224;
    } else {
      return 17;
    }
  };

  const changeDeleteKeyCode = () => {
    return 46;
  };

  const onElementsRemove = (elementsToRemove: any) => {
    removeComponents(elementsToRemove, editorMode, layerMode, components);
  };

  const exchangeSourceTarget = (params: Connection | Edge) => {
    const realTarget = params.source;
    const realAnchorTarget = params.sourceHandle;
    params.source = params.target;
    params.sourceHandle = params.targetHandle;
    params.target = realTarget;
    params.targetHandle = realAnchorTarget;
    return params;
  };

  const onConnect = (params: any) => {
    let source = componentsRef.current.find((c: any) => c.id === params.source);
    let sourceAnchor;
    sourceAnchor = source.data.anchors.find((a: any) => a.id === params.sourceHandle);
    if (!sourceAnchor) sourceAnchor = source.data.instrumentationPorts.find((a: any) => a.id === params.sourceHandle);
    if (!sourceAnchor) sourceAnchor = source.data.samplingPorts.find((a: any) => a.id === params.sourceHandle);
    let target = componentsRef.current.find((c: any) => c.id === params.target);
    let targetAnchor;
    targetAnchor = target.data.anchors.find((a: any) => a.id === params.targetHandle);
    if (!targetAnchor) targetAnchor = target.data.instrumentationPorts.find((a: any) => a.id === params.targetHandle);
    if (!targetAnchor) targetAnchor = target.data.samplingPorts.find((a: any) => a.id === params.targetHandle);
    //Cas source anchor === target(vert) => échanger source et target
    if (sourceAnchor.type === 'target') {
      exchangeSourceTarget(params);
    }

    //Cas source anchor  = Genderless(bleu)
    //Si target est genderless(bleu) => rien à faire
    //Si target est target(vert) => rien à faire
    //Si target est source(rouge) => échanger position
    if (sourceAnchor.type === 'genderless' && targetAnchor.type === 'source') {
      exchangeSourceTarget(params);
    }

    if (sourceAnchor.type === 'neutral' && targetAnchor.type === 'source') {
      exchangeSourceTarget(params);
    }

    let sourceGhost: any;
    let sourceGhostReference: any;

    if (source.type === 'function' && source.data.assembly) {
      const result = reactiveGhost(source, componentsRef.current);
      sourceGhost = result.ghost;
      sourceGhostReference = result.ghostReference;
    }


    let targetGhost: any;
    let targetGhostReference: any;

    if (target.type === 'function' && target.data.assembly) {
      const result = reactiveGhost(target, componentsRef.current);
      targetGhost = result.ghost;
      targetGhostReference = result.ghostReference;
    }

    if (source.data.assembly) {
      source = componentsRef.current.find((c: any) => c.id === params.source);
    }

    if (target.data.assembly) {
      target = componentsRef.current.find((c: any) => c.id === params.target);
    }

    switch (layerMode) {
      case UnitOperationLayer.PFD:
        const newEdge = {
          id: Uuidv4(), type: 'functionedge', data: {
            type: 'transferring', implementable: true, implementableReference: true
          }, ...params
        };

        if (sourceGhost) {
          newEdge.sourceGhost = sourceGhost.id;
        }
        if (sourceGhostReference) {
          newEdge.sourceGhostReference = sourceGhostReference.id;
        }
        if (targetGhost) {
          newEdge.targetGhost = targetGhost.id;
        }
        if (targetGhostReference) {
          newEdge.targetGhostReference = targetGhostReference.id;
        }

        dispatch(assemblyEditorSliceActions.addComponent(newEdge));
        break;
      case UnitOperationLayer.PnID:
        if (source.data.ghost) {
          params.sourceGhost = source.data.ghostId;
        }

        if (target.data.ghost) {
          params.targetGhost = target.data.ghostId;
        }

        if (source.data.assembly?.component && target.data.assembly?.component && source.data.assembly?.component === target.data.assembly?.component) {
          return null;
        }

        if (source.data.ghost || target.data.ghost) {
          if (source.type === 'ghost' && target.type !== 'ghost' && source.data.function !== target.data.assembly?.component) {
            return null;
          }
          if (target.type === 'ghost' && source.type !== 'ghost' && target.data.function !== source.data.assembly?.component) {
            return null;
          }
          if (source.type !== 'ghost' && target.type !== 'ghost' && source.data.assembly?.component !== target.data.assembly?.component) {
            return null;
          }
          if (source.type === 'ghost' && target.type === 'ghost') {
            return null;
          }
        }
        dispatch(assemblyEditorSliceActions.addComponent({
          id: Uuidv4(), type: 'functionedge', data: {
            type: 'transferring', implementable: true, implementableReference: true, ghost: source.data.ghost && target.data.ghost
          }, ...params
        }));
        break;
      case UnitOperationLayer.Reference:
        if (source.data.ghostReference) {
          params.sourceGhostReference = source.data.ghostIdReference;
        }

        if (target.data.ghostReference) {
          params.targetGhostReference = target.data.ghostIdReference;
        }

        if (source.data.assemblyReference?.component && target.data.assemblyReference?.component && source.data.assemblyReference?.component === target.data.assemblyReference?.component) {
          return null;
        }

        if (source.data.ghostReference || target.data.ghostReference) {
          if (source.type === 'ghost' && target.type !== 'ghost' && source.data.function !== target.data.assemblyReference?.component) {
            return null;
          }
          if (target.type === 'ghost' && source.type !== 'ghost' && target.data.function !== source.data.assemblyReference?.component) {
            return null;
          }
          if (source.type !== 'ghost' && target.type !== 'ghost' && source.data.assemblyReference?.component !== target.data.assemblyReference?.component) {
            return null;
          }
          if (source.type === 'ghost' && target.type === 'ghost') {
            return null;
          }
        }
        dispatch(assemblyEditorSliceActions.addComponent({
          id: Uuidv4(), type: 'functionedge', data: {
            type: 'transferring', implementable: true, implementableReference: true, ghostReference: source.data.ghostReference && target.data.ghostReference
          }, ...params
        }));
        break;
    }
  };

  const onEdgeUpdate: OnEdgeUpdateFunc = (oldEdge, newConnection) => {
    if (oldEdge.data.ghost) {
      return;
    }
    const source = componentsRef.current.find((c: any) => c.id === newConnection.source);
    const sourceAnchor = source.data.anchors.find((a: any) => a.id === newConnection.sourceHandle);
    const target = componentsRef.current.find((c: any) => c.id === newConnection.target);
    const targetAnchor = target.data.anchors.find((a: any) => a.id === newConnection.targetHandle);

    if (newConnection.source === newConnection.target) {
      return false;
    }
    //Cas source anchor === source(rouge) => target anchor === target(vert) || genderless(blue)
    if (sourceAnchor.type === 'source' && targetAnchor.type === 'source') {
      return false;
    }
    //Cas source anchor === target(vert) =>
    //il faut que target anchor === source(rouge) || genderless(blue)
    //si correct => échanger source et target
    if (sourceAnchor.type === 'target' && targetAnchor.type === 'target') {
      return false;
    }

    //Cas source anchor === target(vert) => échanger source et target
    if (sourceAnchor.type === 'target') {
      exchangeSourceTarget(newConnection);
    }

    //Cas source anchor  = Genderless(bleu)
    //Si target est genderless(bleu) => rien à faire
    //Si target est target(vert) => rien à faire
    //Si target est source(rouge) => échanger position
    if (sourceAnchor.type === 'genderless' && targetAnchor.type === 'source') {
      exchangeSourceTarget(newConnection);
    }

    if (sourceAnchor.type === 'neutral' && targetAnchor.type === 'source') {
      exchangeSourceTarget(newConnection);
    }

    const oldEdgeCopy = cloneDeep(componentsRef.current.find((component: any) => component.id === oldEdge.id));

    if (oldEdge.type === 'functionedge') {
      if (oldEdgeCopy.source !== newConnection.source) {
        const oldSource = cloneDeep(componentsRef.current.find((component: any) => component.id === oldEdgeCopy.source));
        const source = cloneDeep(componentsRef.current.find((component: any) => component.id === newConnection.source));
        const oldSourceAnchor = oldSource.data.anchors.find((anchor: any) => anchor.id === oldEdgeCopy.sourceHandle);
        const sourceAnchor = source.data.anchors.find((anchor: any) => anchor.id === newConnection.sourceHandle);
        sourceAnchor.data.componentLink = {
          anchor: oldEdgeCopy.sourceHandle, component: oldEdgeCopy.source
        };
        oldSourceAnchor.data.componentLink = {
          anchor: newConnection.sourceHandle, component: newConnection.source
        };
        dispatch(assemblyEditorSliceActions.updateComponent(source));
        dispatch(assemblyEditorSliceActions.updateComponent(oldSource));
      }

      if (oldEdgeCopy.target !== newConnection.target) {
        const oldTarget = cloneDeep(componentsRef.current.find((component: any) => component.id === oldEdgeCopy.target));
        const target = cloneDeep(componentsRef.current.find((component: any) => component.id === newConnection.target));
        const oldTargetAnchor = oldTarget.data.anchors.find((anchor: any) => anchor.id === oldEdgeCopy.targetHandle);
        const targetAnchor = target.data.anchors.find((anchor: any) => anchor.id === newConnection.targetHandle);
        targetAnchor.data.componentLink = {
          anchor: oldEdgeCopy.targetHandle, component: oldEdgeCopy.target
        };
        oldTargetAnchor.data.componentLink = {
          anchor: newConnection.targetHandle, component: newConnection.target
        };
        dispatch(assemblyEditorSliceActions.updateComponent(target));
        dispatch(assemblyEditorSliceActions.updateComponent(oldTarget));
      }
      dispatch(assemblyEditorSliceActions.updateComponent({
        ...oldEdgeCopy, ...newConnection
      }));
    } else {
      dispatch(assemblyEditorSliceActions.updateComponent({
        ...oldEdgeCopy, ...newConnection
      }));
    }
  };

  const onNodeDragStop: ReactFlowProps['onNodeDragStop'] = (event, node) => {
    dispatch(assemblyEditorSliceActions.setEditingAssembly(true));
    switch (layerModeRef.current) {
      case UnitOperationLayer.PFD:
        if (!isLayerLockedRef.current) {
          let originalFunction = componentsRef.current.find((c: any) => {
            return c.id === node.id;
          });
          if (originalFunction.data.assembly) {
            let deltaViewer2D = {
              x: node.position.x - originalFunction.viewer2D.pid.x, y: node.position.y - originalFunction.viewer2D.pid.y
            };
            let componentToMoveList = componentsRef.current.filter((c: any) => {
              return ((c.type === 'generic' || c.type === 'genericonedge') && c.data.assembly && c.data.assembly.id === originalFunction.data.assembly.general.id);
            });
            componentToMoveList.forEach((componentToMove) => {
              const componentToMoveCopy = cloneDeep(componentToMove);
              componentToMoveCopy.viewer2D.pid.x += deltaViewer2D.x;
              componentToMoveCopy.viewer2D.pid.y += deltaViewer2D.y;
              componentToMoveCopy.viewer2D.reference.x += deltaViewer2D.x;
              componentToMoveCopy.viewer2D.reference.y += deltaViewer2D.y;
              dispatch(assemblyEditorSliceActions.updateComponent(componentToMoveCopy));
            });
          }
          promiseUpdateComponentRef.current.forEach((promise: any) => {
            const promiseCopy = cloneDeep(promise);
            promiseCopy.component.viewer2D.pid = cloneDeep(promiseCopy.component.viewer2D.pfd);
            promiseCopy.component.viewer2D.reference = cloneDeep(promiseCopy.component.viewer2D.pfd);
            dispatch(assemblyEditorSliceActions.updateComponent(promiseCopy.component));
          });
        } else {
          promiseUpdateComponentRef.current.forEach((promise: any) => {
            dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
          });
        }
        break;
      case UnitOperationLayer.PnID:
        if (!isLayerLockedRef.current) {
          promiseUpdateComponentRef.current.forEach((promise: any) => {
            const promiseCopy = cloneDeep(promise);
            promiseCopy.component.viewer2D.pfd = cloneDeep(promiseCopy.component.viewer2D.pid);
            promiseCopy.component.viewer2D.reference = cloneDeep(promiseCopy.component.viewer2D.pid);
            dispatch(assemblyEditorSliceActions.updateComponent(promiseCopy.component));
          });
        } else {
          promiseUpdateComponentRef.current.forEach((promise: any) => {
            dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
          });
        }
        setNodeDragStop(node);
        break;
      case UnitOperationLayer.Reference:
        if (!isLayerLockedRef.current) {
          promiseUpdateComponentRef.current.forEach((promise: any) => {
            const promiseCopy = cloneDeep(promise);
            promiseCopy.component.viewer2D.pfd = cloneDeep(promiseCopy.component.viewer2D.reference);
            promiseCopy.component.viewer2D.pid = cloneDeep(promiseCopy.component.viewer2D.reference);
            dispatch(assemblyEditorSliceActions.updateComponent(promiseCopy.component));
          });
        } else {
          promiseUpdateComponentRef.current.forEach((promise: any) => {
            dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
          });
        }
        setNodeDragStop(node);
        break;
    }
    dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
    setDragging(false);
  };

  const onNodeDragStart: ReactFlowProps['onNodeDragStart'] = (event, node) => {
    let componentListDrag = [];
    switch (layerModeRef.current) {
      case UnitOperationLayer.PnID:
        componentListDrag = componentsRef.current.filter((component) => component.data.idDrag && node.data.idDrag && component.data.idDrag.id === node.data.idDrag.id && component.id !== node.id);
        break;
      case UnitOperationLayer.Reference:
        componentListDrag = componentsRef.current.filter((component) => component.data.idDragReference && node.data.idDragReference && component.data.idDragReference.id === node.data.idDragReference.id && component.id !== node.id);
        break;
    }

    dragPositionRef.current = node.position;
    dragComponentRef.current = cloneDeep(componentListDrag);
  };

  const onNodeDrag: ReactFlowProps['onNodeDrag'] = (event, node) => {
    setDragging(true);
  };

  const onSelectionDragStart: ReactFlowProps['onSelectionDragStart'] = () => {
    setDragging(true);
  };

  const onSelectionDragStop: ReactFlowProps['onSelectionDragStop'] = () => {
    promiseUpdateComponentRef.current.forEach((promise: any) => {
      dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
    });
    dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
    setDragging(false);
  };

  const onSelectionDrag: ReactFlowProps['onSelectionDragStart'] = (event, node) => {
    setDragging(true);
  };

  const renderLoadingSpinner = () => {
    return (<div
      style={{
        height: '100%', width: '100%', backgroundColor: 'rgba(255,255,255,0.9)', position: 'absolute', zIndex: 1000
      }}>
      <SplashScreen text={'Loading'} color={'var(--primaryColor)'} />
    </div>);
  };

  const onNodeMouseEnter = (event: React.MouseEvent, node: any) => {
    let assemblyKey = '';

    switch (layerMode) {
      case UnitOperationLayer.PnID:
        if (node.type === 'genericonedge') {
          setHoverOnEdge(node.id);
        }
        assemblyKey = 'assembly';
        break;
      case UnitOperationLayer.Reference:
        if (node.type === 'genericonedge') {
          setHoverOnEdge(node.id);
        }
        if (node.data?.assembly?.component && !node.data?.assemblyReference?.component) {
          assemblyKey = 'assembly';
        } else {
          assemblyKey = 'assemblyReference';
        }
        break;
    }

    const allUpdateFrames = components.filter((c) => c.type === 'update');
    const hasUpdateFrame = allUpdateFrames.find((c) => c.data.component === node.data?.[assemblyKey]?.component);
    const allGhostNodes = components.filter((c) => c.type === 'ghost');
    const hasGhostNode = allGhostNodes.find((g) => g.data.function === node.data?.[assemblyKey]?.component);
    const isLinking = selectedElements?.length === 2 && selectedElements[0].type === 'generic' && selectedElements[1].type === 'generic';

    if (node.type === 'generic' && !hasUpdateFrame && !hasGhostNode && !isLinking) {
      const existingSelectionNode = components.find((c) => c.type === 'selection');
      if (!areSUAFramesDisplayed && existingSelectionNode?.data.component !== node.data[assemblyKey]?.component) {
        const suaFunction = components.find((c) => node.data[assemblyKey]?.component === c.id);
        const newSelectionNode = createSelectionNode(suaFunction, componentsRef.current, false, layerMode);
        dispatch(assemblyEditorSliceActions.addComponent(newSelectionNode));
      }
    }
  };

  const onNodeMouseLeave = (event: React.MouseEvent, node: any) => {
    if (node.type === 'genericonedge' && (layerMode === UnitOperationLayer.PnID || layerMode === UnitOperationLayer.Reference)) {
      setHoverOnEdge(null);
    }
    if (!areSUAFramesDisplayed && (layerMode === UnitOperationLayer.PnID || layerMode === UnitOperationLayer.Reference)) {
      const selectionNodeToRemove = components.filter((c) => c.data.isHoverFrame);
      if (selectionNodeToRemove) {
        dispatch(assemblyEditorSliceActions.removeComponents([...selectionNodeToRemove]));
      }
    }
  };

  const onEdgeMouseEnter = (event: React.MouseEvent, edge: any) => {
    setHoverEdge(edge.id);
  };

  const onEdgeMouseLeave = (event: React.MouseEvent, edge: any) => {
    unsetHoverEdge(null);
  };

  const onSelectionChange = (selectedElements: Elements<any> | null) => {
    if (layerModeRef.current !== UnitOperationLayer.PFD && selectedElements?.length === 1 && selectedElements?.[0].type === 'functionedge') {
      unselectAll();
      return;
    }

    if (selectedElements?.length === 1 && selectedElements?.[0].type === 'assembly') {
      unselectAll();
    } else {
      if (selectedElements?.length === 1 && (selectedElements?.[0].type === 'generic' || selectedElements?.[0].type === 'function')) {
        resetFramesSelection();
      }
      changeSelectionUO(selectedElements, setSelectedElements);
    }
  };

  const onNodeDoubleClick = (event: React.MouseEvent, node: any) => {
    event.preventDefault();
    event.stopPropagation();

    let assemblyKey = '';

    switch (layerMode) {
      case UnitOperationLayer.PnID:
        if (node.type === 'genericonedge') {
          setHoverOnEdge(node.id);
        }
        assemblyKey = 'assembly';
        break;
      case UnitOperationLayer.Reference:
        if (node.type === 'genericonedge') {
          setHoverOnEdge(node.id);
        }
        if (node.data?.assembly?.component && !node.data?.assemblyReference?.component) {
          assemblyKey = 'assembly';
        } else {
          assemblyKey = 'assemblyReference';
        }
        break;
    }

    if (!_ghostList.length) {
      let suaFunction: any = components.find((c) => node.data.component === c.id);
      const removeUpdateFrame = (functionId) => {
        const updateFramesToRemove = components.find((c) => c.type === 'update' && c.data.component === functionId);
        if (updateFramesToRemove) dispatch(assemblyEditorSliceActions.removeComponents([updateFramesToRemove]));
      };
      const removeAssemblyFrame = (functionId) => {
        const assemblyToRemove = components.find((c) => c.type === 'assembly' && c.data.component === functionId);
        if (assemblyToRemove) dispatch(assemblyEditorSliceActions.removeComponents([assemblyToRemove]));
      };

      if (node?.type === assemblyKey) {
        removeAssemblyFrame(suaFunction?.id);
      } else if (node?.type === 'update') {
        removeUpdateFrame(suaFunction?.id);
      } else {
        suaFunction = components.find((c) => node.data?.[assemblyKey]?.component === c.id);
        removeUpdateFrame(suaFunction?.id);
        if (areSUAFramesDisplayed) {
          removeAssemblyFrame(suaFunction?.id);
        }
      }
      selectEntireSUA(suaFunction, node, assemblyKey);
    }
  };

  const selectEntireSUA = (selectedComponent, selectedNode, assemblyKey) => {
    if (selectedComponent) {
      const selectionNode = createSelectionNode(selectedComponent, componentsRef.current, true, layerMode);
      const assemblyComponents = cloneDeep(components.filter((component: any) => {
        return component.data?.[assemblyKey]?.component === selectedComponent.id;
      }));
      const listDragId = assemblyComponents
        .filter((component: any) => {
          return component.data.idDrag?.id;
        })
        .map((component: any) => {
          return component.data.idDrag?.id;
        });
      const listComponentsDraggable = components.filter((component: any) => component.data?.[assemblyKey]?.component !== selectedComponent.id && listDragId.includes(component.data.idDrag?.id));

      dispatch(assemblyEditorSliceActions.addComponent(selectionNode));
      setSelectedElements([selectedNode, selectedComponent, selectionNode, ...assemblyComponents, ...listComponentsDraggable].map((component) => cloneDeep(component)));
    }
  };

  const isRenderingSpinner = (isSavingScreenshot || isSavingScreenshotAnnotatedPID || isSavingScreenshotAnnotatedPFD || isSavingAssembly) && !props.showExitModal;
  const isRenderingLoadingElements = !isRenderingSpinner && loadingElements;

  const onMoveStart = () => {
    setIsMoving(true);
  };

  const onMoveEnd = () => {
    setIsMoving(false);
  };

  const onRemoveComponents = () => {
    const selectedComponent = components.find((c) => c.id === selectedComponents[0]);
    let componentsToRemove = [selectedComponent];
    removeComponents(componentsToRemove, editorMode, layerMode, components);
  };

  const onSUAClear = (layer: UnitOperationLayer = null) => {
    const component = components.find((el) => el.id === selectedComponents[0]);
    clearAssembly(component, components, layer || layerMode);
  };

  const renderDeleteModal = () => {
    const toDelete = components.find((el) => el.id === selectedComponents[0]);
    let functionRemoveComponents;
    switch (layerMode) {
      case UnitOperationLayer.PFD:
        functionRemoveComponents = onRemoveComponents;
        break;
      case UnitOperationLayer.PnID:
        if (toDelete.data.assembly) {
          functionRemoveComponents = onSUAClear;
          break;
        }
        functionRemoveComponents = onRemoveComponents;
        break;
      case UnitOperationLayer.Reference:
        if (toDelete.data.assemblyReference) {
          functionRemoveComponents = onSUAClear;
          break;
        }
        if (toDelete.data.assembly) {
          functionRemoveComponents = () => onSUAClear(UnitOperationLayer.PnID);
          break;
        }
        functionRemoveComponents = onRemoveComponents;
    }
    return <DeleteModal removeAssembly={null} onRemoveComponents={functionRemoveComponents} />;
  };

  const onPaneClick = () => {
    unselectAll();
  };

  return (<>
    <div className='f-full' ref={reactFlowWrapper}>
      {/* @ts-ignore */}
      <ReactFlow
        onPaneClick={onPaneClick}
        minZoom={0.05}
        maxZoom={5}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        elements={elements}
        onLoad={onLoad}
        onDragOver={onDragOver}
        onDrop={onDrop}
        //onElementsRemove={onElementsRemove}
        onSelectionChange={onSelectionChange}
        onConnect={onConnect}
        onEdgeUpdate={onEdgeUpdate}
        onNodeDragStop={onNodeDragStop}
        onNodeDragStart={onNodeDragStart}
        onNodeDoubleClick={onNodeDoubleClick}
        onNodeMouseEnter={onNodeMouseEnter}
        onNodeMouseLeave={onNodeMouseLeave}
        onNodeDrag={onNodeDrag}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        onSelectionDragStart={onSelectionDragStart}
        onSelectionDragStop={onSelectionDragStop}
        onSelectionDrag={onSelectionDrag}
        connectionMode={ConnectionMode.Loose}
        multiSelectionKeyCode={multiSelect()}
        //deleteKeyCode={changeDeleteKeyCode()}
        onMoveStart={onMoveStart}
        onMoveEnd={onMoveEnd}
        style={{
          backgroundColor: isSavingScreenshotAnnotatedPID || isSavingScreenshotAnnotatedPFD ? 'white' : '#f7faff', cursor: _isMoving ? 'grabbing' : 'grab'
        }}
      />
    </div>
    {isRenderingLoadingElements && renderLoadingSpinner()}
    {showConfirmDeleteModal && renderDeleteModal()}
  </>);
};

export default FlowEditorUO;
