import { RuleComponentEdgeComponent } from '../RuleComponentEdgeComponent';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';
import { CUSTOM_FUNCTIONS } from '../../../../constants/CUSTOM_FUNCTIONS';

export class RuleConnectorFunctionEdgeUtilityWaste extends RuleComponentEdgeComponent {
  schemaComponentSourceList = [...allComponents, ...allFunctions];
  schemaComponentTargetList = [...allComponents, ...allFunctions];
  schemaFunctionList = allFunctions;
  schemaComponentList = allComponents;
  schemaTubeList = ['transferring'];
  editorList = ['UOE_PID'];

  checkPrerequisite = () => {
    const isFunction = this.schemaTubeList.includes(this.edge.data.type);

    if (!isFunction) {
      return false;
    }

    if (this.componentSource && this.componentTarget) {
      if (this.componentSource.type === 'generic' && this.componentTarget.type === 'function') {
        return true;
      }

      if (this.componentTarget.type === 'generic' && this.componentSource.type === 'function') {
        return true;
      }
    }

    return false;
  };

  checkRule = () => {
    if (this.componentSource.type === 'generic' && this.componentTarget.type === 'function') {
      if (
        !this.schemaComponentList.includes(this.componentSource.data.type) &&
        this.schemaFunctionList.includes(this.componentTarget.data.type)
      ) {
        this.errorList.push({
          id: uuidv4(),
          name: 'Incompatible function',
          errors: `A(n) ${
            CUSTOM_FUNCTIONS[this.componentTarget.data.type].name
          } can't be connected with a(n) ${CUSTOM_NODES[this.componentSource.data.type].name} `,
          componentAnchors: [
            //@ts-ignore
            { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id },
            //@ts-ignore
            { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
          ]
        });
      }
    }

    if (this.componentTarget.type === 'generic' && this.componentSource.type === 'function') {
      if (
        !this.schemaComponentList.includes(this.componentTarget.data.type) &&
        this.schemaFunctionList.includes(this.componentSource.data.type)
      ) {
        this.errorList.push({
          id: uuidv4(),
          name: 'Incompatible function',
          errors: `A(n) ${
            CUSTOM_FUNCTIONS[this.componentSource.data.type].name
          } can't be connected with a(n) ${CUSTOM_NODES[this.componentTarget.data.type].name} `,
          componentAnchors: [
            //@ts-ignore
            { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id },
            //@ts-ignore
            { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
          ]
        });
      }
    }
  };
}

const allComponents = ['triclampConnector', 'sipConnector'];

const allFunctions = ['waste', 'utilities'];
