import { Icon, IconTooltip, ThinChip, Tooltip } from 'hakobio-react-ui';
import { indexOf } from 'lodash';
import { FC, memo, useEffect, useState } from 'react';
import { NodeProps, useUpdateNodeInternals } from 'react-flow-renderer';
import { CUSTOM_NODES } from '../../../constants/CUSTOM_NODES';
import { EditorMode } from '../../../constants/EditorMode';
import { useAppSelector } from '../../../store';
import { GenericAnchor } from '../anchor/GenericAnchor';
import ButtonTooltip from '../utility/GenericTooltip';
import { NodeData } from '../utility/NodeData';
import SelectionToolbar from '../utility/SelectionToolbar';

const GenericOnEdgeNode: FC<NodeProps<NodeData>> = (props) => {
  const { id, selected } = props;
  const [isHover, setIsHover] = useState(false);
  const [_showTooltip, setShowToolTip] = useState(false) as any;
  const [timeOutTooltip, setTimeOutTooltip] = useState(false) as any;
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const hoveredComponent = useAppSelector((state) => state.assemblyEditorSlice.hoveredComponent);
  const hoverOnEdge = useAppSelector((state) => state.assemblyEditorSlice.hoverOnEdge);

  const updateNodeInternals = useUpdateNodeInternals();

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const isSavingScreenshotSUAAnnotated = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUAAnnotated
  );

  const component = components.find((c) => c.id === id);
  const SIZE = component?.viewer2D.size ? component.viewer2D.size : 80;

  const listComparisonVersions = useAppSelector(
    (state) => state.assemblyEditorSlice.listComparionVersions
  );
  const [_componentDifference, setComponentDifference] = useState() as any;
  const [_schema, setSchema] = useState<any | null>(null);
  const componentDifference = listComparisonVersions.find((c: any) => c.id === component?.id);

  const getSchemaAsync = async () => {
    try {
      const schema = await CUSTOM_NODES[component.data.type].schema();
      setSchema(schema);
    } catch (error) {
      setSchema(null);
    }
  };

  useEffect(() => {
    getSchemaAsync();
  }, []);

  useEffect(() => {
    if (componentDifference) {
      setComponentDifference(componentDifference);
    }
  }, [componentDifference]);

  useEffect(() => {
    updateNodeInternals(id);
  }, [id, component?.data, component?.viewer2D, updateNodeInternals]);

  const renderAnnotation = () => {
    let top = -12;
    let left = 0;
    if (component.viewer2D.size === 80 || component.viewer2D.size === 120) {
      top = component.viewer2D.size * 0.5 - 11;
      left = component.viewer2D.size * 0.5 - 11;
    }
    switch (editorMode) {
      case EditorMode.SUA:
      case EditorMode.Reference:
        if (isSavingScreenshotSUAAnnotated) {
          const element = general.legend?.find((l) => l?.ids?.includes(id));
          let indexNumber = indexOf(general.legend, element);
          return (
            <div
              style={{
                position: 'absolute',
                top,
                left,
                borderRadius: '30px',
                border: '1px solid white',
                backgroundColor: 'black',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
                fontSize: 16,
                fontFamily: 'aileron-regular',
                height: 24,
                width: 24
              }}>
              {indexNumber + 1}
            </div>
          );
        } else {
          return null;
        }
      case EditorMode.UnitOperation:
        return null;
    }
  };

  const renderSelected = () => {
    return (
      <SelectionToolbar
        hoverOnEdge={hoverOnEdge}
        component={component}
        rotate={rotate}
        noButton={true}
        isSelected={selected}
      />
    );
  };

  const onMouseEnter = () => {
    setIsHover(true);
    setShowToolTip(true);
    timeOutTooltipFunction();
  };

  const onMouseLeave = () => {
    setIsHover(false);
    setShowToolTip(false);
    setTimeOutTooltip(false);
  };

  const timeOutTooltipFunction = () => {
    const timeOutId = setTimeout(() => {
      setTimeOutTooltip(true);
    }, 1000);
    return clearTimeout(timeOutId);
  };

  const contentTooltip = () => {
    if (component?.data && Object.entries(component?.data).length > 0) {
      // @ts-ignore
      return <ButtonTooltip component={component} />;
    } else {
      return null;
    }
  };

  const contentVersionTooltip = () => {
    return (
      <div className="p-2 overflow-y-auto" style={{ maxHeight: 400 }}>
        <>
          <div key={_componentDifference?.id} className="font-regular">
            {CUSTOM_NODES[_componentDifference?.type]?.name}
          </div>
          <div className="pl-2 f-col gap-2">
            {_componentDifference?.changes.map((change) => {
              if (change.key !== 'anchors') {
                return (
                  <div key={change.key}>
                    <span className=" capitalize">
                      {_schema?.properties[change.key]?.name || change.key}
                    </span>
                    <div className="f-row f2-center gap-2">
                      <ThinChip
                        color={'var(--primaryColor)'}
                        backgroundColor={'var(--primaryColorBcg)'}
                        label={
                          (
                            <span>{`${change.viewingValue}${
                              _schema?.properties[change.key]?.units &&
                              change.viewingValue !== 'N/C'
                                ? ' ' + _schema?.properties[change.key].units
                                : ''
                            }`}</span>
                          ) || 'N/C'
                        }
                      />
                      <Icon name={'transfer'} />
                      <ThinChip
                        label={
                          (
                            <span>{`${change.comparingValue}${
                              _schema?.properties[change.key]?.units &&
                              change.comparingValue !== 'N/C'
                                ? ' ' + _schema?.properties[change.key].units
                                : ''
                            }`}</span>
                          ) || 'N/C'
                        }
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </>
      </div>
    );
  };

  const renderTooltip = () => {
    return (
      <div
        style={{ position: 'absolute', overflowY: 'visible' }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <Tooltip open={_showTooltip} title={contentTooltip()}></Tooltip>
      </div>
    );
  };

  function highlightComponent() {
    //TODO GV highlight tube
    return (
      <div
        style={{
          border:
            hoveredComponent === id ? 'solid 1px var(--orange)' : 'solid 1px var(--frozen-grey)',
          position: 'absolute',
          top: -8,
          bottom: -8,
          left: -8,
          right: -8,
          pointerEvents: 'none'
        }}
      />
    );
  }

  const renderNode = () => {
    switch (editorMode) {
      case EditorMode.SUA:
        return (
          <div
            key={'SUA'}
            style={{ height: SIZE, width: SIZE, cursor: 'pointer' }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {CUSTOM_NODES[component.data.type].form(SIZE)}
            {componentDifference && renderCustomIconVersion()}
          </div>
        );
      case EditorMode.Reference:
        return (
          <div
            key={'SUA'}
            style={{ height: SIZE, width: SIZE, cursor: 'pointer' }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {CUSTOM_NODES[component.data.type].form(SIZE)}
            {componentDifference && renderCustomIconVersion()}
          </div>
        );
      case EditorMode.UnitOperation:
        return (
          <div
            key={'PFD'}
            style={{ height: SIZE, width: SIZE, cursor: 'pointer' }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {CUSTOM_NODES[component.data.type].form(SIZE)}
          </div>
        );
    }
  };

  const renderAnchors = () => {
    return component.data.anchors.map((anchor: any) => {
      return <GenericAnchor key={anchor.id} anchor={anchor} sizeX={SIZE} sizeY={SIZE} />;
    });
  };

  if (component === undefined) {
    return null;
  }
  const rotate = component.viewer2D?.rotate ?? 0;
  const renderCustomIconVersion = () => {
    return (
      <div
        style={{
          position: 'absolute',
          overflowY: 'visible',
          bottom: 54,
          left: 30
        }}>
        <IconTooltip
          title={contentVersionTooltip()}
          placement="top"
          name="history"
          color="orange"
        />
      </div>
    );
  };

  return (
    <>
      <div style={{ position: 'relative' }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {renderAnnotation()}
        {isHover && timeOutTooltip && renderTooltip()}
        <div
          style={{
            position: 'relative',
            transform:
              rotate < -45 && rotate > -225
                ? `rotate(${rotate + 90}deg) translateY(-10px)`
                : `rotate(${rotate + 90}deg)`
          }}>
          {renderNode()}
          {renderAnchors()}
        </div>
        {(selected || hoverOnEdge === id) && renderSelected()}
        {componentDifference && highlightComponent()}
      </div>
    </>
  );
};

export default memo(GenericOnEdgeNode);
