import { UnitOperationLayer } from '../../../../constants/PFD_EquipmentTabs';
import { DetailPanel } from '../../Shared/DetailPanel/DetailPanel';
import { UnitOperationGeneralPanel } from './UnitOperationGeneralPanel';
import GenericSuaDetails from './Details/GenericSuaDetails';
import FunctionEdgeDetails from './Details/FunctionEdgeDetails';
import { PanelHeader } from 'hakobio-react-ui';
import ReferenceSuaDetails from './Details/ReferenceSuaDetails';

interface Props {
  onCloseLeftPanel: Function;
  components: Array<any>;
  selectedComponent: any;
  layerMode: UnitOperationLayer;
}

const UnitOperationEditorLeftPanel = ({
  onCloseLeftPanel,
  components,
  selectedComponent,
  layerMode
}: Props) => {
  const renderContentPFD = () => {
    if (selectedComponent) {
      return (
        <DetailPanel
          key={selectedComponent.id}
          components={components}
          selectedComponent={selectedComponent}
        />
      );
    }
    return <UnitOperationGeneralPanel />;
  };

  const renderContentPID = () => {
    if (selectedComponent && selectedComponent?.data.assembly) {
      return (
        <GenericSuaDetails
          sua={selectedComponent.data.assembly}
          anchors={selectedComponent.data.anchors}
          component={selectedComponent}
        />
      );
    }
    if (selectedComponent && selectedComponent?.type === 'functionedge') {
      const source = components.find((c) => c.id === selectedComponent.source);
      const target = components.find((c) => c.id === selectedComponent.target);
      if (source && target && source.type === 'generic' && target.type === 'generic') {
        return <FunctionEdgeDetails component={selectedComponent} />;
      }
    }
    if (selectedComponent) {
      return (
        <DetailPanel
          key={selectedComponent.id}
          components={components}
          selectedComponent={selectedComponent}
        />
      );
    }
    return <UnitOperationGeneralPanel />;
  };

  const renderContentReference = () => {
    if (selectedComponent && selectedComponent?.data.assemblyReference) {
      return <ReferenceSuaDetails component={selectedComponent} />;
    }
    if (selectedComponent && selectedComponent?.data.assembly) {
      return (
        <GenericSuaDetails
          sua={selectedComponent.data.assembly}
          anchors={selectedComponent.data.anchors}
          component={selectedComponent}
        />
      );
    }
    if (selectedComponent && selectedComponent?.type === 'functionedge') {
      const source = components.find((c) => c.id === selectedComponent.source);
      const target = components.find((c) => c.id === selectedComponent.target);
      if (source && target && source.type === 'generic' && target.type === 'generic') {
        return <FunctionEdgeDetails component={selectedComponent} />;
      }
    }
    if (selectedComponent) {
      return (
        <DetailPanel
          key={selectedComponent.id}
          components={components}
          selectedComponent={selectedComponent}
        />
      );
    }
    return <UnitOperationGeneralPanel />;
  };

  const renderContent = () => {
    switch (layerMode) {
      case UnitOperationLayer.PFD:
        return renderContentPFD();
      case UnitOperationLayer.PnID:
        return renderContentPID();
      case UnitOperationLayer.Reference:
        return renderContentReference();
    }
  };

  const render = () => {
    return (
      <>
        <PanelHeader arrowBack onClose={onCloseLeftPanel}>
          Unit Operation Designer
        </PanelHeader>
        {renderContent()}
      </>
    );
  };

  return render();
};

export default UnitOperationEditorLeftPanel;
