import { ThinChip } from "hakobio-react-ui";

import { useAppDispatch } from "../store";
import { assemblyEditorSliceActions } from "../store/features/assemblyEditor/assemblyEditorSlice";

export function NotificationPanel(props: any) {
  const dispatch = useAppDispatch();

  const onMouseEnter = (e: any) => {
    dispatch(assemblyEditorSliceActions.setHoveredWarning(e.id));
  };

  const onMouseLeave = (e: any) => {
    dispatch(assemblyEditorSliceActions.setHoveredWarning(null));
  };

  return (
    <div>
      {props.errors.length ? (
        <div
          style={{
            width: "250px",
            maxHeight: "200px",
            overflow: "auto",
            backgroundColor: "rgba(255,255,255,0.9)",
          }}
        >
          {props.errors.map((e: any, index: number) => {
            return (
              <div
                className="p-1"
                style={{ borderTop: index !== 0 ? "1px solid lightgrey" : "0" }}
                onMouseEnter={() => onMouseEnter(e)}
                onMouseLeave={() => onMouseLeave(e)}
              >
                <ThinChip
                  auto
                  fontSize="extraSmall"
                  color={"var(--dark-orange)"}
                  backgroundColor={"var(--light-orange)"}
                  label={e.name}
                  style={{ marginBottom: 0 }}
                />
                <div className="px-1" style={{ fontSize: ".875rem" }}>
                  {e.errors}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="frozen-grey p-2">No error reported</div>
      )}
    </div>
  );
}
