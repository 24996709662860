import { tubingColors } from '../../utilities/tubingColors';

export const getTubeColor = (type) => {
  switch (type) {
    case 'Standard':
      return tubingColors.pink;
    case 'High pressure':
      return tubingColors.blue;
    case 'Pump dedicated':
      return tubingColors.green;
    case 'Weldable':
      return tubingColors.purple;
    default:
      return 'black';
  }
};
