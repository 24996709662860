export enum tubingColors {
  blue = '#113DA6',
  green = '#29A677',
  purple = '#8719A6',
  pink = '#A75F22',
  lightBlue = '#9fb1db',
  lightGreen = '#a9dbc8',
  lightPurple = '#cfa3db',
  lightPink = '#dbbfa6',
}
