import Color from 'color';
import { IconChip, ThinChip } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import { configMessages } from '../../../../lang/messages';
import { portColors } from '../../../../utilities/portsColors';

interface Props {
  list: any[];
  type: 'transfer' | 'instrumentation' | 'sampling';
}

export const NodeAnchors = ({ list, type }: Props) => {
  const intl = useIntl();

  const getSubTitle = () => {
    switch (type) {
      case 'transfer':
        return intl.formatMessage(configMessages.transferPort);
      case 'instrumentation':
        return intl.formatMessage(configMessages.instrumentationPort);
      case 'sampling':
        return intl.formatMessage(configMessages.samplingPort);
    }
  };

  const getColor = () => {
    switch (type) {
      case 'transfer':
        return portColors.Transfer;
      case 'instrumentation':
        return portColors.Instrumentation;
      case 'sampling':
        return portColors.Sampling;
    }
  };

  const getBcgColor = () => {
    switch (type) {
      case 'transfer':
        return Color(portColors.Transfer).alpha(0.1).toString();
      case 'instrumentation':
        return Color(portColors.Instrumentation).alpha(0.1).toString();
      case 'sampling':
        return Color(portColors.Sampling).alpha(0.1).toString();
    }
  };

  return (
    <div className="mb-2">
      {list.length > 0 && (
        <div className="f-col f1-start f2-start gap-2">
          {list.map((anchor: any, index: number) => {
            return (
              <div key={anchor.id} style={{}}>
                <IconChip
                  title={getSubTitle()}
                  name={type === 'transfer' ? 'blood' : type}
                  hako3={type !== 'transfer'}
                  auto
                  color={getColor()}
                  backgroundColor={getBcgColor()}
                  label={anchor.data.name ? anchor.data.name : `${getSubTitle()} (${index})`}
                />
                <div className="f-row gap-2" style={{ flexWrap: 'wrap' }}>
                  {Object.entries(anchor.data).map(([key, value]: any, index: number) => {
                    if (value && key !== 'name') {
                      return (
                        <ThinChip
                          key={key + index}
                          className="capitalize"
                          auto
                          title={key}
                          label={value}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
