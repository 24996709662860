import Zone from './Zone';
/* import AirUnit from './AirUnit';
import ColdUnit from './ColdUnit';
import WarmUnit from './WarmUnit'; */
import Element from './Element';
import Uuidv4 from '../Uuidv4';
//import SupplyNetwork from './SupplyNetwork';

export default class Project {
  constructor(arg) {
    var data = {
      owner: null,
      ownerId: null,
      name: '',
      description: '',
      zones: [],
      elements: [],
      stockElements: [],
      rejectElements: [],
      walls: [],
      globalHeight: 3,
      keysValues: [],
      id: Uuidv4(),
      createdOn: null,
      modifiedOn: null,
      rights: null,
      frames: [],
      processUnits: [],
      processUnitLinks: [],
      tour: null,
      airUnits: [],
      coldUnits: [],
      warmUnits: [],
      supplyNetworks: [],
      parentBuildingId: null,
      parentFloor: null
    };
    try {
      Object.assign(data, JSON.parse(arg));
    } catch (err) {
      try {
        Object.assign(data, arg);
      } catch (err) {
        console.log('Warning: ' + err);
      }
    }

    this.id = data.id;
    this.organizationId = data.organizationId;

    this.owner = data.owner;
    this.ownerId = data.ownerId;

    this.name = data.name;
    this.description = data.description;

    this.globalHeight = data.globalHeight;

    // children
    this.zones = [];
    for (let i = 0; i < data.zones.length; i++) this.zones.push(new Zone(data.zones[i]));

    this.elements = [];
    this.stockElements = data.stockElements || [];
    this.rejectElements = data.rejectElements || [];
    for (let i = 0; i < data.elements.length; i++) {
      switch (data.elements[i].stockType) {
        case 0:
          this.elements.push(new Element(data.elements[i]));
          break;
        case 1:
          this.stockElements.push(new Element(data.elements[i]));
          break;
        case 2:
          this.rejectElements.push(new Element(data.elements[i]));
          break;
        default:
          this.elements.push(new Element({ ...data.elements[i], stockType: 0 }));
      }
    }
    this.airUnits = [];
/*     for (let i = 0; i < data.airUnits.length; i++)
      this.airUnits.push(new AirUnit(data.airUnits[i]));
    this.coldUnits = [];
    for (let i = 0; i < data.coldUnits.length; i++)
      this.coldUnits.push(new ColdUnit(data.coldUnits[i]));
    this.warmUnits = [];
    for (let i = 0; i < data.warmUnits.length; i++)
      this.warmUnits.push(new WarmUnit(data.warmUnits[i]));
    this.supplyNetworks = [];
    for (let i = 0; i < data.supplyNetworks.length; i++)
      this.supplyNetworks.push(new SupplyNetwork(data.supplyNetworks[i])); */

    this.walls = []; // now representes WallSelection items
    // for (let i = 0; i < data.walls.length; i++)
    //     this.walls.push(new Wall(data.walls[i]))
    this.keysValues = data.keysValues;

    // Date information
    this.createdOn = data.createdOn;
    this.modifiedOn = data.modifiedOn;

    // Additional information
    this.parentBuildingId = data.parentBuildingId;
    this.parentFloor = data.parentFloor;
    this.isTemplate = data.isTemplate;
    this.rights = data.rights;
    this.backgroundUrl = data.backgroundUrl;
    this.backgroundRatio = data.backgroundRatio;
    this.backgroundX = data.backgroundX;
    this.backgroundY = data.backgroundY;
    this.fromTemplate = data.fromTemplate;
    this.processUnits = data.processUnits || [];
    this.processUnitLinks = data.processUnitLinks || [];
    this.frames = data.frames || [];
    this.tour = data.tour || {
      projectId: this.id,
      checkpoints: []
    };
  }

  static uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
}
