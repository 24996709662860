import {
  Icon,
  IconTooltip,
  MetroChip,
  Select,
  TextInput,
  ThinChip,
  Title,
  Tooltip
} from 'hakobio-react-ui';
import { configMessages, generalMessages, statusMessages } from '../../../../lang/messages';
import { MenuItem } from '@mui/material';
import {
  ReferenceStatus,
  referenceStatusBackgroundColors,
  referenceStatusColors
} from '../../../../utilities/statuses/referenceStatus';
import { useIntl } from 'react-intl';
import { Supplier, SUPPLIERS } from '../../../../constants/SUPPLIERS';
import { REFERENCE_STATUS_EXPLANATION } from '../../../../constants/statusExplanations/REFERENCE_STATUS_EXPLANATION';
import { useAppSelector } from '../../../../store';

const GeneralInformationEdition = ({
  setPrivacyContent,
  setStatusToChange,
  setName,
  setDescription,
  setSupplier,
  setStatus,
  name,
  description,
  supplier,
  status,
  nameNotValid
}) => {
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const intl = useIntl();

  const handleEditName = (event) => {
    setName(event.target.value);
  };

  const handleEditSupplier = (event) => {
    setSupplier(event.target.value);
  };

  const handleEditDescription = (event) => {
    setDescription(event.target.value);
  };

  let message = null;

  if (
    !general.permissions.changeStatus &&
    general.permissions.update &&
    general.status === ReferenceStatus.PreOnboarding &&
    general.newAssembly
  ) {
    message = intl.formatMessage(statusMessages.saveBeforeChange);
  } else if (
    !general.permissions.changeStatus &&
    general.permissions.update &&
    general.status === ReferenceStatus.PreOnboarding
  ) {
    message = intl.formatMessage(statusMessages.contactOwner);
  } else if (
    !general.permissions.changeStatus &&
    !general.permissions.update &&
    general.status === ReferenceStatus.Obsolete
  ) {
    message = intl.formatMessage(statusMessages.changeStatusNotAllowed);
  } else if (!general.permissions.changeStatus && !general.permissions.update) {
    message = intl.formatMessage(statusMessages.contactOwner);
  } else {
    //message if assembly is in an invalid state
    message = intl.formatMessage(statusMessages.contactOwner);
  }

  let tooltip = null;
  if (!general.permissions.changeStatus) {
    tooltip = (
      <div className="ml-1">
        <Tooltip title={message}>
          <Icon name={'about'} />
        </Tooltip>
      </div>
    );
  }

  const handleChangeStatus = (e) => {
    if (
      (!general.permissions.changeStatus &&
        general.permissions.update &&
        general.status === ReferenceStatus.PreOnboarding) ||
      (general.isPrivate &&
        general.permissions.changeStatus &&
        general.status === ReferenceStatus.PreOnboarding)
    ) {
      setPrivacyContent(true);
      setStatusToChange(e.target.value);
    } else {
      setStatus(e.target.value);
    }
  };

  const renderStatusesExplanation = () => {
    return (
      <div className="px-2" style={{ backgroundColor: 'rgba(255,255,255,.6)' }}>
        <Title style={{ marginTop: -4 }}>Reference SUA Statuses</Title>
        <div style={{ marginLeft: '20px', paddingBottom: '5px' }}>
          {intl.formatMessage(statusMessages.statusChangedSUAS)}
        </div>
        {Object.entries(REFERENCE_STATUS_EXPLANATION)?.map(
          ([key, value]: [
            key: string,
            value: {
              name: string;
              explanations: string[];
            }
          ]) => {
            return (
              <div>
                <MetroChip
                  key={value.name}
                  label={value.name}
                  color={referenceStatusColors(key)}
                  backgroundColor={referenceStatusBackgroundColors(key)}
                />
                <ul
                  className="pl-4 py-2"
                  style={{
                    borderLeft:
                      key === ReferenceStatus.Effective || key === ReferenceStatus.Obsolete
                        ? 'none'
                        : '1px solid var(--grey)',
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 12
                  }}>
                  {value.explanations.map((exp) => (
                    <li>{exp}</li>
                  ))}
                </ul>
              </div>
            );
          }
        )}
      </div>
    );
  };

  return (
    <div className="pr-2 border-right w-40">
      <Title>
        <div style={{ height: 28 }}>{intl.formatMessage(generalMessages.generalInformation)}</div>
      </Title>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
          id="reference-status"
          InputLabelProps={{
            focused: true
          }}
          defaultValue={status}
          className="mb-1"
          inputSize="small"
          value={status}
          disabled={
            (!general.permissions.changeStatus &&
              general.status !== ReferenceStatus.PreOnboarding) ||
            general.newAssembly ||
            (!general.permissions.changeStatus && !general.isPrivate)
          }
          renderValue={(value: string) => (
            <ThinChip
              center
              fontSize="smallText"
              label={<span className="capitalize">{value}</span>}
              color={referenceStatusColors(value)}
              backgroundColor={referenceStatusBackgroundColors(value)}
            />
          )}
          onChange={handleChangeStatus}>
          {Object.entries(ReferenceStatus).map((status: [string, string]) => {
            return (
              <MenuItem
                className="capitalize"
                value={status[1]}
                style={{ color: referenceStatusColors(status[1]) }}>
                {status[1]}
              </MenuItem>
            );
          })}
        </Select>
        {tooltip}
        <IconTooltip
          className="ml-1"
          name="question-mark"
          title={renderStatusesExplanation()}
          placement="right-start"
        />
      </div>

      <TextInput
        id="name"
        label={'Name'}
        inputSize={'small'}
        value={name}
        error={nameNotValid}
        disabled={!general.permissions.update}
        helperText={nameNotValid ? `'${name}' already exists. Please enter another name.` : null}
        onChange={(event: any) => handleEditName(event)}
      />

      <Select
        id="reference-supplier"
        InputLabelProps={{
          focused: true
        }}
        disabled={!general.permissions.update}
        defaultValue={supplier}
        className="mb-1"
        inputSize="small"
        label={intl.formatMessage(configMessages.supplier)}
        value={supplier}
        onChange={(event) => {
          handleEditSupplier(event);
        }}>
        {SUPPLIERS.map((supplier: Supplier) => {
          return (
            <MenuItem className="capitalize" value={supplier.id}>
              {supplier.name}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default GeneralInformationEdition;
