import React, { useRef } from 'react';
import { CustomIcon, SocketBadge, DraggingBadge } from 'hakobio-react-ui';
import { useDrag } from 'react-dnd';

export const ItemTypes = { DRAGGING_BADGE: 'DRAGGING_BADGE' };

const DraggableSocketBadge = ({
  isDragging,
  title,
  size,
  label,
  type,
  onClick,
  anchorType,
  backgroundColor,
  content,
  orientation
}: {
  isDragging?: boolean;
  title: string;
  size: any;
  label: string;
  type: string;
  onClick: any;
  anchorType: any;
  backgroundColor: string;
  content: string | JSX.Element;
  orientation?: "top" | "top-right" | "right" | "bottom-right" | "bottom" | "bottom-left" | "left"
}) => {
  const position = useRef(null);
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: ItemTypes.DRAGGING_BADGE,
      //@ts-ignore
      item: {
        isDragging,
        title,
        size,
        label,
        sourcePosition: position,
        type: type,
        anchorType,
        backgroundColor,
        content
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  );

  return (
    <div className="f-col f-center gap-2" ref={position}>
      <div ref={dragRef} style={{ opacity }}>
        <DraggingBadge
          onClick={onClick}
          title={title}
          size={size}
          label={label}
          backgroundColor={backgroundColor}
          content={content}
          orientation={orientation}
        />
      </div>
    </div>
  );
};

export default DraggableSocketBadge;
