import { FC, memo, useEffect, useRef, useState } from 'react';

import { DoubleChip, Icon, SmallButton, Tooltip } from 'hakobio-react-ui';
import { cloneDeep } from 'lodash';
import { NodeProps } from 'react-flow-renderer';
import { useIntl } from 'react-intl';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { actionMessages, versioningMessages } from '../../../lang/messages';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useImplementEditorAction } from '../../../store/features/assemblyEditor/useImplementEditorAction';
import { NodeData } from '../utility/NodeData';
import { GetUpdatedSUA } from '../utils/GetUpdatedSUA';
import { CUSTOM_FUNCTIONS } from '../../../constants/CUSTOM_FUNCTIONS';

const UpdateNode: FC<NodeProps<NodeData>> = (props) => {
  const { id } = props;

  const dispatch = useAppDispatch();
  const { implementComponent } = useImplementEditorAction();

  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary
  );
  const outdatedAssemblies = useAppSelector(
    (state) => state.assemblyEditorSlice.outdatedAssemblies
  );
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const isLayerLocked = useAppSelector((state) => state.assemblyEditorSlice.isLayerLocked);

  const [_isHover, setIsHover] = useState(false);

  const intl = useIntl();

  const componentRef = useRef(null);
  const componentsRef = useRef<any>(null);
  const layerModeRef = useRef<any>(null);
  const editorModeRef = useRef<any>(null);

  layerModeRef.current = layerMode;
  editorModeRef.current = editorMode;
  componentsRef.current = components;

  const component = components.find((c) => c.id === id);
  componentRef.current = component;
  const rotate = component?.viewer2D?.rotate ?? 0;

  const functionComponent = components.find((c: any) => {
    return component?.data?.component === c.id;
  });

  useEffect(() => {
    if (!componentRef.current) {
      return;
    }
    const componentCopy = cloneDeep(componentRef.current);
    switch (editorModeRef.current) {
      case EditorMode.SUA:
        if (component.viewer2D.x !== props.xPos || component.viewer2D.y !== props.yPos) {
          // @ts-ignore
          const deltaX = Math.abs(component.viewer2D.x - props.xPos);
          // @ts-ignore
          const deltaY = Math.abs(component.viewer2D.y - props.yPos);
          if (deltaX > 0.01 || deltaY > 0.01) {
            const promise = { id: id, component: componentCopy };
            promise.component.viewer2D.x = props.xPos;
            promise.component.viewer2D.y = props.yPos;
            dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          }
        }
        break;
      case EditorMode.Reference:
        if (component.viewer2D.x !== props.xPos || component.viewer2D.y !== props.yPos) {
          // @ts-ignore
          const deltaX = Math.abs(component.viewer2D.x - props.xPos);
          // @ts-ignore
          const deltaY = Math.abs(component.viewer2D.y - props.yPos);
          if (deltaX > 0.01 || deltaY > 0.01) {
            const promise = { id: id, component: componentCopy };
            promise.component.viewer2D.x = props.xPos;
            promise.component.viewer2D.y = props.yPos;
            dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          }
        }
        break;
      case EditorMode.UnitOperation:
        switch (layerModeRef.current) {
          case UnitOperationLayer.PFD:
            if (
              componentRef.current.viewer2D.pfd.x !== props.xPos ||
              componentRef.current.viewer2D.pfd.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pfd.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pfd.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pfd = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.PnID:
            if (
              componentRef.current.viewer2D.pid.x !== props.xPos ||
              componentRef.current.viewer2D.pid.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pid.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pid.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pid = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.Reference:
            if (
              componentRef.current.viewer2D.reference.x !== props.xPos ||
              componentRef.current.viewer2D.reference.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.reference.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.reference.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.reference = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
        }
        break;
    }
  }, [props.xPos, props.yPos]);

  const clearAndUpdateSUA = async () => {
    dispatch(assemblyEditorSliceActions.setIsSUAUpdating(false));
    dispatch(assemblyEditorSliceActions.removeComponents([component]));
    const newOutdatedList = outdatedAssemblies.filter((a) => a !== component.data.component);
    dispatch(assemblyEditorSliceActions.setOutdatedAssemblies(newOutdatedList));
    let promiseImplement = new Promise((resolve, reject) => {
      setTimeout(() => {
        let updatedSuas = GetUpdatedSUA(components, editorAssemblyLibrary);
        let newComponent = cloneDeep(functionComponent);
        let updateSUAToImplement = updatedSuas?.find((c: any) => c.function.id === newComponent.id);
        implementComponent(
          newComponent,
          updateSUAToImplement.updatedSua,
          componentsRef.current,
          isLayerLocked,
          layerMode,
          editorAssemblyLibrary
        );
        resolve(true);
      }, 500);
    });
    await promiseImplement;
  };

  const onFrameEnter = () => {
    setIsHover(true);
  };

  const onFrameLeave = () => {
    setIsHover(false);
  };

  const renderNode = () => {
    if (editorMode !== EditorMode.UnitOperation || layerMode !== UnitOperationLayer.PnID) {
      return null;
    } else {
      return (
        <div
          className="pointer relative"
          onMouseEnter={onFrameEnter}
          onMouseLeave={onFrameLeave}
          style={{
            height: component?.viewer2D?.size?.y,
            width: component?.viewer2D?.size?.x,
            border: '1px solid',
            borderColor: _isHover ? 'var(--primaryColor)' : 'var(--red)',
            backgroundColor: 'rgba(232,32,52, .1)',
            borderRadius: '0 4px 4px 4px'
          }}>
          <div
            className="f-row f2-between absolute gap-1 w-100"
            style={{
              top: -32,
              right: 0,
              left: -1,
              fontSize: 'var(--title)',
              maxHeight: 32
            }}>
            <div
              className="py-2 px-2 f-center"
              style={{
                backgroundColor: 'rgba(232,32,52, .1)',
                borderRadius: '4px 4px 0 0',
                border: '1px solid',
                borderLeftColor: _isHover ? 'var(--primaryColor)' : 'var(--red)',
                borderRightColor: _isHover ? 'var(--primaryColor)' : 'var(--red)',
                borderTopColor: _isHover ? 'var(--primaryColor)' : 'var(--red)',
                borderBottomColor: 'var(--light-red)'
              }}>
              <Icon
                size={'1rem'}
                name="history"
                color="var(--dark-red)"
                style={{
                  lineHeight: 0.5
                }}
              />
            </div>
            <div
              className="f-row f2-center gap-1 w-100"
              style={{
                color: 'var(--frozen-grey)',
                minWidth: 180
              }}>
              {CUSTOM_FUNCTIONS[functionComponent.data.type].form(26)}
              <div className="max-lines-1" style={{ fontSize: 11 }}>
                {layerMode === UnitOperationLayer.PnID
                  ? functionComponent?.data.assembly.general.name
                  : functionComponent?.data.assemblyReference.general.name}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const contentTooltip = () => {
    return (
      <div className="f-row f-center gap-2" onMouseEnter={onFrameEnter} onMouseLeave={onFrameLeave}>
        <DoubleChip
          center
          label1={
            <div style={{ maxWidth: 160 }}>{functionComponent.data.assembly?.general.name}</div>
          }
          auto
          label2={
            <div style={{ minWidth: 32 }}>{`${intl.formatMessage(versioningMessages.versionAbr)} ${
              functionComponent?.data.assembly?.general?.version + 1
            }`}</div>
          }
          title1={functionComponent.data.assembly?.general.name}
          title2={intl.formatMessage(versioningMessages.currentVersion)}
          backgroundColor="var(--light-red)"
          color="var(--dark-red)"
        />
        <SmallButton
          width={'auto'}
          color="var(--primaryColor)"
          inverse
          title={intl.formatMessage(versioningMessages.updateToLastVersion)}
          onClick={clearAndUpdateSUA}>
          {intl.formatMessage(actionMessages.update)}
        </SmallButton>
      </div>
    );
  };

  if (component === undefined) {
    return null;
  }
  return (
    <Tooltip style={{}} placement="top" title={contentTooltip()} tooltipWidth={450}>
      <div
        style={{
          position: 'relative',
          transform: `rotate(${rotate}deg)`
        }}>
        {renderNode()}
      </div>
    </Tooltip>
  );
};

export default memo(UpdateNode);
