export const GENERIC_STATUS_EXPLANATION = {
  draft: {
    name: 'Draft',
    explanations: [
      //"SUA must be set as 'Public' to be able to change its status",
      "Only SUAs with 'draft' status can be deleted",
      //"SUA can still be 'Private' with this status",
      'Design Owners and Design Editors can edit'
    ]
  },
  proposal: {
    name: 'Proposal',
    explanations: [
      'Design Owners and Design Editors can edit' /* ,
      "Status can only be changed by Design Owner and SUA's Creator" */
    ]
  },
  detailed: {
    name: 'Detailed',
    explanations: [
      'Allows creation of Reference SUAs',
      'Design Owners and Design Editors can edit' /* ,
      "Status can only be changed by Design Owner and SUA's Creator" */
    ]
  },
  updateOngoing: {
    name: 'Update Ongoing',
    explanations: [
      'Design Owners and Design Editors can edit',
      'Used if a freeze design need to be updated',
      'Go back to freeze once the design is updated' /*
      "Status can only be changed by Design Owner and SUA's Creator" */
    ]
  },
  freeze: {
    name: 'Freeze',
    explanations: [
      'Allows creation of Reference SUAs',
      'SUA cannot be edited anymore' /* ,
      "Status can only be changed by Design Owner and SUA's Creator" */
    ]
  },

  obsolete: {
    name: 'Obsolete',
    explanations: [
      'Viewing and duplicating only',
      'Once the status is obsolete, it can only be changed by Design Owners'
    ]
  }
};
