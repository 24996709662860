import React from 'react';
import { config } from '../../../services/config';
import { useIntl } from 'react-intl';
import { configMessages } from '../../../lang/messages';
import { groupBySuaNamePnid } from './utils';

interface Props {
  assemblyGeneral: any;
  assemblyComponents: any;
  assemblyList: any;
  exportDate: string;
  pageNumber: number;
  totalPageNumber: number;
  time?: number;
}

const SchemaPID = ({
  assemblyGeneral,
  assemblyComponents,
  assemblyList,
  exportDate,
  pageNumber,
  totalPageNumber,
  time
}: Props) => {
  const intl = useIntl();

  // retourne un tableau d'objet avec le nom de l'assembly et le nombre de fois qu'il apparait
  let countByAssembly = groupBySuaNamePnid(assemblyComponents, 'assembly');

  return (
    <div
      className="pb-3 pt-1 px-4 f-col h-100 w-100 relative"
      style={{
        breakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always',
        pageBreakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always'
      }}>
      <div className="border-bottom py-2">
        <div className="f-row f1-between f2-center">
          <div>
            <span>{intl.formatMessage(configMessages.PID)}</span>
            <span className="px-2">&#124;</span>
            <span className="font-regular">{assemblyGeneral.name}</span>
          </div>
          <div>{exportDate}</div>
        </div>
      </div>
      <div className="w-100 f-full f-center mt-1" style={{ maxHeight: 600 }}>
        <img
          alt="PID view"
          className="w-100"
          style={{
            objectFit: 'contain',
            height: 600
          }}
          src={
            config.BlobURL +
            '/single-use-configurator/assemblies/thumbnail/' +
            assemblyGeneral.id +
            '_UO_Annotated_PID' +
            '.png?cache=' +
            assemblyGeneral.modifiedOn
          }
        />
      </div>
      <div className="h-100 col-count-5 px-2" style={{ height: 90 }}>
        {
          countByAssembly.map((assembly: any, index: any) => {
            return (<div key={assembly.name + index} className="h-100 f-row f2-center gap-2" style={{ height: 30 }}>
              <span className='font-regular'>{`${time > 0 ? index + 1 + 20 * time : index + 1}.`}</span>
              <span className='font-regular max-1-lines-visible'> {assembly.name}</span>
              <span className='font-regular'> {`(${assembly.count})`}</span>
            </div>);
          })
        }
      </div>

      {/* <div style={{ width: '100%', height: 100, display: 'flex', flexFlow: 'column wrap', marginTop: 20 }}>
        {assemblies
          .map((assembly, index) => {
            return (<div key={assembly.general.id + index}>
              <span>{time > 0 ? index + 1 + 20 * time : index + 1}</span>
              <span className='bold'> {assembly.general.name}</span>
            </div>);
          })}
      </div> */}
      <div className="f-row f1-end gap-2 mt-2" style={{ fontSize: 13 }}>
        {/* {time > 0 && (
          <div className="dark-orange f-row f2-center gap-1">
            <CustomIcon name="alert-2" />
            Continued from previous page
          </div>
        )} */}
        <div>{`Page ${pageNumber}/${totalPageNumber}`}</div>
      </div>
    </div>
  );
};

export default SchemaPID;
