import { isNode } from 'react-flow-renderer';
import { store, useAppDispatch, useAppSelector } from '../..';
import { EditorMode } from '../../../constants/EditorMode';
import { assemblyEditorSliceActions } from './assemblyEditorSlice';
import { cloneDeep } from 'lodash';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';

export function useAssemblyEditorAction() {
  const dispatch = useAppDispatch();
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const allHeadersLibrary = useAppSelector((state) => state.assemblyEditorSlice.allHeadersLibrary);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);

  const setAssembly = (general: any, components: any) => {
    dispatch(
      assemblyEditorSliceActions.setAssembly({
        general,
        components
      })
    );
  };

  const onGeneralEdited = (key: string, value: any) => {
    dispatch(
      assemblyEditorSliceActions.editGeneral({
        key,
        value
      })
    );
  };

  const onComponentPropertyChanged = (component: any, keyProperty: any, value: any) => {
    const componentCopy = cloneDeep(component);
    componentCopy.data[keyProperty] = value;
    if (editorMode === EditorMode.Component) {
      dispatch(assemblyEditorSliceActions.setCustomComponentEdition(componentCopy));
    } else {
      dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
    }
    if (component.type === 'genericedge') {
      const edgeDataCopy = { ...component.data, [keyProperty]: value };
      dispatch(assemblyEditorSliceActions.editTubingDetails({ ...edgeDataCopy }));
    }
  };

  const onComponentRotated = (component: any) => {
    const ROTATE_BY = 90;
    const newRotate = ((component.viewer2D.rotate ?? 0) + ROTATE_BY) % 360;
    dispatch(
      assemblyEditorSliceActions.updateComponent({
        ...component,
        viewer2D: {
          ...component.viewer2D,
          rotate: newRotate
        }
      })
    );
  };

  const onComponentHorizontalFlip = (component: any) => {
    dispatch(
      assemblyEditorSliceActions.updateComponent({
        ...component,
        viewer2D: {
          ...component.viewer2D,
          flipX: component?.viewer2D.flipX ? !component?.viewer2D.flipX : true
        }
      })
    );
  };

  const onComponentVerticalFlip = (component: any) => {
    dispatch(
      assemblyEditorSliceActions.updateComponent({
        ...component,
        viewer2D: {
          ...component.viewer2D,
          flipY: component?.viewer2D.flipY ? !component?.viewer2D.flipY : true
        }
      })
    );
  };

  const onEdgeCenterMoved = (component: any, value: any) => {
    dispatch(
      assemblyEditorSliceActions.addPromiseUpdateComponent({
        id: component.id,
        component: {
          ...component,
          viewer2D: value
        }
      })
    );
  };

  const onAnchorChanged = (
    item: any,
    key: string,
    anchorType: string,
    value: any,
    component: any
  ) => {
    const componentCopy = cloneDeep(component);
    let anchor = null;
    switch (anchorType) {
      case 'FluidTransfert':
        anchor = componentCopy.data.anchors.find((a) => a.id === item.id);
        anchor[key] = value;
        break;
      case 'InstrumentationPort':
        anchor = componentCopy.data.instrumentationPorts.find((a) => a.id === item.id);
        anchor[key] = value;
        break;
      case 'SamplingPort':
        anchor = componentCopy.data.samplingPorts.find((a) => a.id === item.id);
        anchor[key] = value;
        break;
    }

    if (editorMode === EditorMode.Component) {
      dispatch(assemblyEditorSliceActions.setCustomComponentEdition(componentCopy));
    } else {
      dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
    }
  };

  const onAnchorPropertyChanged = (
    item: any,
    key: string,
    anchorType: string,
    value: any,
    component: any
  ) => {
    const componentCopy = cloneDeep(component);
    let anchor = null;
    switch (anchorType) {
      case 'anchors':
        anchor = componentCopy.data.anchors.find((a) => a.id === item.id);
        anchor.data[key] = value;
        break;
      case 'instrumentationPorts':
        anchor = componentCopy.data.instrumentationPorts.find((a) => a.id === item.id);
        anchor.data[key] = value;
        break;
      case 'samplingPorts':
        anchor = componentCopy.data.samplingPorts.find((a) => a.id === item.id);
        anchor.data[key] = value;
        break;
    }
    if (editorMode === EditorMode.Component) {
      dispatch(assemblyEditorSliceActions.setCustomComponentEdition(componentCopy));
    } else {
      dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
    }
  };

  const onAnchorDeletedCustomComponent = (keyProperty: any, item: any, component: any) => {
    const newProperty = component.data?.[keyProperty].filter((i: any) => i.id !== item.id);
    const updateComponent = assemblyEditorSliceActions.setCustomComponentEdition({
      ...component,
      data: {
        ...component.data,
        [keyProperty]: newProperty
      }
    });
    dispatch(updateComponent);
  };

  const onAnchorDeleted = (keyProperty: any, item: any, components: any, component: any) => {
    const newEdges = components
      .filter((c: any) => !isNode(c))
      .map((e: any) => {
        const f =
          (e.source === component.id && e.sourceHandle === item.id) ||
          (e.target === component.id && e.targetHandle === item.id);

        if (f) {
          return null;
        } else {
          return e;
        }
      })
      .filter((e: any) => !!e);

    const updateEdges = assemblyEditorSliceActions.setComponents([
      ...newEdges,
      ...components.filter((c: any) => isNode(c))
    ]);
    dispatch(updateEdges);

    const newProperty = component.data?.[keyProperty].filter((i: any) => i.id !== item.id);
    const updateComponent = assemblyEditorSliceActions.updateComponent({
      ...component,
      data: {
        ...component.data,
        [keyProperty]: newProperty
      }
    });
    dispatch(updateComponent);
  };

  const setEditorMode = (mode: EditorMode) => {
    dispatch(assemblyEditorSliceActions.setEditorMode(mode));
  };

  const changeSelectionUO = (
    selectedElements: any,
    setSelectedElements: any
  ) => {
    const state = store.getState();
    const layerMode = state.assemblyEditorSlice.layerMode;
    const components = state.assemblyEditorSlice.components;
    let assemblyKey = '';

    switch (layerMode) {
      case UnitOperationLayer.PnID:
        assemblyKey = 'assembly';
        break;
      case UnitOperationLayer.Reference:
        assemblyKey = 'assemblyReference';
        break;
    }
    const selectedElementsCopy = cloneDeep(selectedElements);
    const componentListCopy = cloneDeep(components);
    if (
      selectedElementsCopy &&
      selectedElementsCopy[0] &&
      selectedElementsCopy[0].type === 'ghost'
    ) {
      return;
    }

    if (selectedElementsCopy && selectedElementsCopy[0]) {
      const selectedElementIds = selectedElementsCopy.map((e: any) => e.id);

      const selectionComponent = componentListCopy.find((c: any) => c.type === 'selection');
      if (
        selectedElementsCopy[0].data.assembly?.component ||
        selectedElementsCopy[0].data.assemblyReference?.component
      ) {
        let componentsToAdd = [] as any;
        selectedElementsCopy.forEach((sec: any) => {
          switch (layerMode){
            case UnitOperationLayer.PnID:
              if (sec.data.idDrag) {
                let componentListDrag = componentListCopy.filter(
                  (component: any) =>
                    !selectedElementsCopy.find((secc: any) => secc.id === component.id) &&
                    component.data.idDrag &&
                    component.data.idDrag.id === sec.data.idDrag.id &&
                    component.id !== sec.id
                );
                componentsToAdd.push(...componentListDrag);
              }
              break;
            case UnitOperationLayer.Reference:
              if (sec.data.idDragReference) {
                let componentListDrag = componentListCopy.filter(
                  (component: any) =>
                    !selectedElementsCopy.find((secc: any) => secc.id === component.id) &&
                    component.data.idDragReference &&
                    component.data.idDragReference.id === sec.data.idDragReference.id &&
                    component.id !== sec.id
                );
                componentsToAdd.push(...componentListDrag);
              }
              break;
          }
        });
        if (componentsToAdd.length > 0) {
          setSelectedElements(cloneDeep([...selectedElementsCopy, ...componentsToAdd]));
        } else {
          const component = componentListCopy.find(
            (e: any) =>
              e.id === selectedElementsCopy[0].data.assembly?.component ||
              e.id === selectedElementsCopy[0].data.assemblyReference?.component
          );
          dispatch(
            assemblyEditorSliceActions.selectComponents([
              component.id,
              ...selectedElementsCopy.map((e: any) => e.id)
            ])
          );
        }
      } else if (selectionComponent && selectedElementIds.includes(selectionComponent.id)) {
        dispatch(assemblyEditorSliceActions.selectComponents(selectedElementIds));
      } else {
        dispatch(assemblyEditorSliceActions.selectComponents(selectedElementIds));
        if (selectionComponent) {
          dispatch(assemblyEditorSliceActions.removeComponents([selectionComponent]));
        }
      }
    }
  };

  const changeSelectionSUA = (
    selectedElements: any,
    componentList: any,
    setSelectedElements: any
  ) => {
    const selectedElementsCopy = cloneDeep(selectedElements);
    const componentListCopy = cloneDeep(componentList);

    if (selectedElementsCopy && selectedElementsCopy[0]) {
      const selectedElementIds = selectedElementsCopy.map((e: any) => e.id);

      if (selectedElementsCopy[0].data?.idDrag) {
        let componentsToAdd = [] as any;
        selectedElementsCopy.forEach((sec: any) => {
          if (sec.data.idDrag) {
            let componentListDrag = componentListCopy.filter(
              (component: any) =>
                !selectedElementsCopy.find((secc: any) => secc.id === component.id) &&
                component.data.idDrag &&
                component.data.idDrag.id === sec.data.idDrag.id &&
                component.id !== sec.id
            );
            componentsToAdd.push(...componentListDrag);
          }
        });

        if (componentsToAdd.length > 0) {
          const elements = cloneDeep([...selectedElementsCopy, ...componentsToAdd]);
          setSelectedElements(elements);
          dispatch(assemblyEditorSliceActions.selectComponents(elements.map((el) => el.id)));
        } else {
        }
      } else {
        dispatch(assemblyEditorSliceActions.selectComponents(selectedElementIds));
      }
    }
  };

  const changeAnchorsSelection = (selectedAnchors: any[]) => {
    dispatch(assemblyEditorSliceActions.selectedAnchors(selectedAnchors));
  };

  const changeEdgesSelection = (selectedEdges: any[]) => {
    const selectedEdgesIds = selectedEdges.map((edge) => {
      return edge.id;
    });
    dispatch(assemblyEditorSliceActions.selectedEdges(selectedEdgesIds));
  };

  const setHoveredComponent = (id: String) => {
    dispatch(assemblyEditorSliceActions.hoveredComponent(id));
  };
  const setHoveredAnchor = (id: String) => {
    dispatch(assemblyEditorSliceActions.hoveredAnchor(id));
  };

  const setHoverEdge = (id: String) => {
    dispatch(assemblyEditorSliceActions.setHoverEdge(id));
  };

  const unsetHoverEdge = (id: String) => {
    dispatch(assemblyEditorSliceActions.setHoverEdge(null));
  };
  const setHoverOnEdge = (id: String) => {
    dispatch(assemblyEditorSliceActions.setHoverOnEdge(id));
  };

  const setHoverComponentGenericNode = (id: String) => {
    dispatch(assemblyEditorSliceActions.setHoverComponentGenericNode(id));
  };

  const unsetHoverComponentGenericNode = (id: String) => {
    dispatch(assemblyEditorSliceActions.setHoverComponentGenericNode(null));
  };

  const setSUAFramesDisplayed = (areFramesDisplayed: boolean) => {
    dispatch(assemblyEditorSliceActions.setSUAFramesDisplayed(areFramesDisplayed));
  };

  const setIsLayerLocked = (isPotsitionSwitched: boolean) => {
    dispatch(assemblyEditorSliceActions.setIsLayerLocked(isPotsitionSwitched));
  };

  const unsetHoveredComponent = (id: String) => {
    dispatch(assemblyEditorSliceActions.hoveredComponent(null));
  };

  const unsetHoveredAnchor = () => {
    dispatch(assemblyEditorSliceActions.hoveredAnchor(null));
  };

  const validateSuaName = (name: string) => {
    const sameName = allHeadersLibrary
      .filter((el) => el.name !== 'New assembly')
      .find((el) => el.name.toLowerCase().trim() === name.toLowerCase().trim());
    return sameName === undefined || name === general.name ? true : false;
  };

  return {
    setAssembly,
    onAnchorChanged,
    onAnchorPropertyChanged,
    onAnchorDeleted,
    onAnchorDeletedCustomComponent,
    onComponentRotated,
    onComponentHorizontalFlip,
    onComponentVerticalFlip,
    onComponentPropertyChanged,
    onGeneralEdited,
    onEdgeCenterMoved,
    setEditorMode,
    changeSelectionUO,
    changeSelectionSUA,
    changeAnchorsSelection,
    changeEdgesSelection,
    setHoveredComponent,
    unsetHoveredComponent,
    setHoveredAnchor,
    unsetHoveredAnchor,
    setSUAFramesDisplayed,
    setIsLayerLocked,
    setHoverEdge,
    unsetHoverEdge,
    setHoverOnEdge,
    setHoverComponentGenericNode,
    unsetHoverComponentGenericNode,
    validateSuaName
  };
}
