// @ts-ignore
import {v4 as uuidv4} from 'uuid';

export const getTags = (assembly: any) => {
    if (assembly.general.type !== "SingleUseAssembly") {
        throw "Assembly not supported";
    }

    let tags = [];
    //VOLUME
    if (assembly.general.functions.includes("culture")) {
        //bioreactorBag
        let totalCultureVolume = 0;
        assembly.components
            .filter((component) => {
                return component.data.type === "bioreactorBag";
            })
            .forEach((component) => {
                totalCultureVolume += (component.data.componentVolume || 0);
            });
        tags.push({
            id: uuidv4(),
            name: "volume",
            function: "culture",
            value: totalCultureVolume + "L"
        });
    }
    if (assembly.general.functions.includes("mixing")) {
        //mixerBag
        let totalMixingVolume = 0;
        assembly.components
            .filter((component) => {
                return component.data.type === "mixerBag";
            })
            .forEach((component) => {
                totalMixingVolume += (component.data.componentVolume || 0);
            });
        tags.push({
            id: uuidv4(),
            name: "volume",
            function: "mixing",
            value: totalMixingVolume + "L"
        });
    }
    if (assembly.general.functions.includes("storage")) {
        //small2DBag
        //large2DBag
        //3DBag
        let totalStorageVolume = 0;
        assembly.components
            .filter((component) => {
                return component.data.type === "twoDimensionalBag" ||
                    component.data.type === "threeDimensional";
            })
            .forEach((component) => {
                totalStorageVolume += (component.data.componentVolume || 0);
            });
        tags.push({
            id: uuidv4(),
            name: "volume",
            function: "storage",
            value: totalStorageVolume + "L"
        });
    }
    if (assembly.general.functions.includes("waste")) {
        //?
    }
    if (assembly.general.functions.includes("sampling")) {
        //bottle
        let totalSamplingVolume = 0;
        assembly.components
            .filter((component) => {
                return component.data.type === "bottle";
            })
            .forEach((component) => {
                totalSamplingVolume += (component.data.componentVolume || 0);
            });
        tags.push({
            id: uuidv4(),
            name: "volume",
            function: "sampling",
            value: totalSamplingVolume + "L"
        });
    }

    //SURFACE
    if (assembly.general.functions.includes("filtering")) {
        //hydrophobicFilter
        //hydrophilicFilter
        let totalFilteringSurface = 0;
        assembly.components
            .filter((component) => {
                return component.data.type === "hydrophobicFilter" ||
                    component.data.type === "hydrophilicFilter";
            })
            .forEach((component) => {
                totalFilteringSurface += (component.data.componentSurface || 0);
            });
        tags.push({
            id: uuidv4(),
            name: "surface",
            function: "filtering",
            value: totalFilteringSurface + "m²"
        });
    }
    if (assembly.general.functions.includes("venting")) {
        //?
    }

    //TYPE OF MEASURE
    if (assembly.general.functions.includes("measuring")) {
        //sensor
        let totalMeasuringType = [];
        assembly.components
            .filter((component) => {
                return component.data.type === "sensor";
            })
            .forEach((component) => {
                if (component.data.componentType && !totalMeasuringType.includes(component.data.componentType)) {
                    totalMeasuringType.push(component.data.componentType)
                    tags.push({
                        id: uuidv4(),
                        name: "type",
                        function: "measuring",
                        value: component.data.componentType
                    });
                }
            });
    }

    //LENGTH
    if (assembly.general.functions.includes("connecting")) {
        //?
    }
    if (assembly.general.functions.includes("disconnecting")) {
        //?
    }

    return tags;
}