import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { Uuidv4 } from '../../../utilities';

export const createSUAFrames = (components, layer) => {

  const suaComponents = components.filter(
    (el) => el.type !== 'selection' && el.type !== 'function' && el.type !== 'functionedge'
  );
  const suaFunctions = components.filter((el) => el.type === 'function');

  const frames = [];
  suaFunctions.forEach((f) => {
    let componentField;
    let viewField;

    switch (layer) {
      case UnitOperationLayer.PnID:
        componentField = 'assembly';
        viewField = 'pid';
        break;
      case UnitOperationLayer.Reference:
        if (f.data.assembly && !f.data.assemblyReference) {
          componentField = 'assembly';
          viewField = 'pid';
        } else {
          componentField = 'assemblyReference';
          viewField = 'reference';
        }
    }
    const assemblyComponents = suaComponents.filter((component: any) => {
      return component.data[componentField]?.component === f.id;
    });
    const componentGeneric = assemblyComponents.filter((c: any) => c.type === 'generic');

    if (componentGeneric.length) {
      var componentMaxX = componentGeneric[0]?.viewer2D[viewField].x as number;
      var componentMaxY = componentGeneric[0]?.viewer2D[viewField].y as number;
      var componentMinX = componentGeneric[0]?.viewer2D[viewField].x as number;
      var componentMinY = componentGeneric[0]?.viewer2D[viewField].y as number;
      componentGeneric.forEach((c: any) => {
        if (componentMaxX < c.viewer2D[viewField].x) {
          componentMaxX = c.viewer2D[viewField].x;
        }
        if (componentMaxY < c.viewer2D[viewField].y) {
          componentMaxY = c.viewer2D[viewField].y;
        }
        if (componentMinX > c.viewer2D[viewField].x) {
          componentMinX = c.viewer2D[viewField].x;
        }
        if (componentMinY > c.viewer2D[viewField].y) {
          componentMinY = c.viewer2D[viewField].y;
        }
      });

      const positionTopLeft = {
        pfd: {
          x: componentMinX - 10,
          y: componentMinY - 10
        },
        pid: {
          x: componentMinX - 10,
          y: componentMinY - 10
        },
        reference: {
          x: componentMinX - 10,
          y: componentMinY - 10
        },
      };
      const positionBottomRight = {
        x: componentMaxX + 90,
        y: componentMaxY + 90
      };
      const nodeSize = {
        x: positionBottomRight.x - positionTopLeft[viewField].x + 35,
        y: positionBottomRight.y - positionTopLeft[viewField].y + 35
      };
      const assemblyNode = {
        id: Uuidv4(),
        type: 'assembly',
        viewer2D: { ...positionTopLeft, size: nodeSize },
        data: {
          type: 'assembly',
          component: f.id
        },
        draggable: false,
        selectable: false,
        style: { zIndex: 10 }
      };
      frames.push(assemblyNode);
    }
  });
  return frames;
};

export const createSingleSUAFrame = (suaFunctionId, components, layer) => {
  const suaComponents = components.filter(
    (el) => el.type !== 'selection' && el.type !== 'function' && el.type !== 'functionedge'
  );

  const suaFunction = components.filter((el) => el.type === 'function' && el.id === suaFunctionId);

  let componentField;
  let viewField;
  switch (layer) {
    case UnitOperationLayer.PnID:
      componentField = 'assembly';
      viewField = 'pid';
      break;
    case UnitOperationLayer.Reference:
      if (suaFunction?.data?.assembly && !suaFunction?.data?.assemblyReference) {
        componentField = 'assembly';
        viewField = 'pid';
      } else {
        componentField = 'assemblyReference';
        viewField = 'reference';
      }
  }

  const assemblyComponents = suaComponents.filter((component: any) => {
    return component.data[componentField]?.component === suaFunctionId;
  });

  const componentGeneric = assemblyComponents.filter((c: any) => c.type === 'generic');

  var componentMaxX = componentGeneric[0]?.viewer2D[viewField].x as number;
  var componentMaxY = componentGeneric[0]?.viewer2D[viewField].y as number;
  var componentMinX = componentGeneric[0]?.viewer2D[viewField].x as number;
  var componentMinY = componentGeneric[0]?.viewer2D[viewField].y as number;
  componentGeneric.forEach((c: any) => {
    if (componentMaxX < c.viewer2D[viewField].x) {
      componentMaxX = c.viewer2D[viewField].x;
    }
    if (componentMaxY < c.viewer2D[viewField].y) {
      componentMaxY = c.viewer2D[viewField].y;
    }
    if (componentMinX > c.viewer2D[viewField].x) {
      componentMinX = c.viewer2D[viewField].x;
    }
    if (componentMinY > c.viewer2D[viewField].y) {
      componentMinY = c.viewer2D[viewField].y;
    }
  });

  const positionTopLeft = {
    pfd: {
      x: componentMinX - 10,
      y: componentMinY - 10
    },
    pid: {
      x: componentMinX - 10,
      y: componentMinY - 10
    },
    reference: {
      x: componentMinX - 10,
      y: componentMinY - 10
    },
  };
  const positionBottomRight = {
    x: componentMaxX + 90,
    y: componentMaxY + 90
  };
  const nodeSize = {
    x: positionBottomRight.x - positionTopLeft[viewField].x + 35,
    y: positionBottomRight.y - positionTopLeft[viewField].y + 35
  };
  const assemblyNode = {
    id: Uuidv4(),
    type: 'assembly',
    viewer2D: { ...positionTopLeft, size: nodeSize },
    data: {
      type: 'assembly',
      component: suaFunctionId
    },
    draggable: false,
    selectable: false,
    style: { zIndex: 0 }
  };
  return assemblyNode;
};

export const updateSingleSUAFrame = (node, components, suaFunctionId, layer) => {
  const suaComponents = components.filter(
    (el) => el.type !== 'selection' && el.type !== 'function' && el.type !== 'functionedge'
  );

  const suaFunction = components.filter((el) => el.type === 'function' && el.id === suaFunctionId);

  let componentField;
  let viewField;
  switch (layer) {
    case UnitOperationLayer.PnID:
      componentField = 'assembly';
      viewField = 'pid';
      break;
    case UnitOperationLayer.Reference:
      if (suaFunction.data.assembly && !suaFunction.data.assemblyReference) {
        componentField = 'assembly';
        viewField = 'pid';
      } else {
        componentField = 'assemblyReference';
        viewField = 'reference';
      }
  }

  const assemblyComponents = suaComponents.filter((component: any) => {
    return component.data[componentField]?.component === suaFunctionId;
  });

  const componentGeneric = assemblyComponents.filter((c: any) => c.type === 'generic');

  var componentMaxX = componentGeneric[0]?.viewer2D[viewField].x as number;
  var componentMaxY = componentGeneric[0]?.viewer2D[viewField].y as number;
  var componentMinX = componentGeneric[0]?.viewer2D[viewField].x as number;
  var componentMinY = componentGeneric[0]?.viewer2D[viewField].y as number;
  componentGeneric.forEach((c: any) => {
    if (componentMaxX < c.viewer2D[viewField].x) {
      componentMaxX = c.viewer2D[viewField].x;
    }
    if (componentMaxY < c.viewer2D[viewField].y) {
      componentMaxY = c.viewer2D[viewField].y;
    }
    if (componentMinX > c.viewer2D[viewField].x) {
      componentMinX = c.viewer2D[viewField].x;
    }
    if (componentMinY > c.viewer2D[viewField].y) {
      componentMinY = c.viewer2D[viewField].y;
    }
  });

  const positionTopLeft = {
    pfd: {
      x: componentMinX - 10,
      y: componentMinY - 10
    },
    pid: {
      x: componentMinX - 10,
      y: componentMinY - 10
    },
    reference: {
      x: componentMinX - 10,
      y: componentMinY - 10
    },
  };
  const positionBottomRight = {
    x: componentMaxX + 90,
    y: componentMaxY + 90
  };
  const nodeSize = {
    x: positionBottomRight.x - positionTopLeft[viewField].x + 35,
    y: positionBottomRight.y - positionTopLeft[viewField].y + 35
  };
  const assemblyNode = {
    id: node.id,
    type: 'assembly',
    viewer2D: { ...positionTopLeft, size: nodeSize },
    data: {
      type: 'assembly',
      component: suaFunctionId
    },
    draggable: false,
    selectable: false,
    style: { zIndex: 0 }
  };
  return assemblyNode;
};
