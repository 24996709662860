import { Modal, SmallButton } from "hakobio-react-ui";
import { FormattedMessage, useIntl } from "react-intl";
import { buttonSaveStyle } from "../components/EditorPanel/style/styles";
import { useAppDispatch, useAppSelector } from "../store";
import { assemblyEditorSliceActions } from "../store/features/assemblyEditor/assemblyEditorSlice";
import {
  deleteShortcut
} from "../services/editor/editorService";
import _ from "lodash";
import { toast } from "react-toastify";

const DeleteShortcutModal = () => {
  const dispatch = useAppDispatch();
  const selectedFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedFolder,
  );

  const directoriesList = useAppSelector(
    (state) => state.assemblyEditorSlice.allDirectories,
  );

  const shortcutToDelete = useAppSelector(
    (state) => state.assemblyEditorSlice.showConfirmDeleteShortcutModal,
  );

  const intl = useIntl();

  const onCloseModal = () => {
    dispatch(
      assemblyEditorSliceActions.setShowDeleteShortcutConfirmModal(null),
    );
  };
  console.log("shortcutToDelete", shortcutToDelete);
  const onDelete = async () => {
    let editedDirectory = {
      ...selectedFolder,
      shortCuts: selectedFolder.shortCuts.filter(
        (shortcut) => shortcut !== shortcutToDelete.shortcutId,
      ),
    };
    const saveResult = await deleteShortcut(
      editedDirectory.id,
      shortcutToDelete.shortcutId
    ); 

    if (saveResult) {
      toast.success(`Shortcut ${editedDirectory.name} has been removed`);
      let directoriesListCopy = _.cloneDeep(directoriesList);
      let newDirList = directoriesListCopy.map((directory) => {
        if (directory.id === editedDirectory.id) {
          let modifiedFolder = { ...saveResult };
          modifiedFolder.permissions = directory.permissions;
          modifiedFolder.designEditors = directory.designEditors;
          modifiedFolder.designOwners = directory.designOwners;
          dispatch(assemblyEditorSliceActions.selectFolder(modifiedFolder));
          return { ...modifiedFolder };
        }
        return directory;
      });
      dispatch(assemblyEditorSliceActions.setAllDirectories(newDirList));
    } else {
      toast.error(`Shortcut couldn't be removed`);
    }
    dispatch(
      assemblyEditorSliceActions.setShowDeleteShortcutConfirmModal(null),
    );
  };

  const renderFooter = () => {
    return (
      <SmallButton
        width={"150px"}
        color={"var(--red)"}
        style={buttonSaveStyle}
        onClick={onDelete}
      >
        <FormattedMessage
          id="SUConfigView.DeleteModal.Remove"
          defaultMessage="Remove"
        />
      </SmallButton>
    );
  };

  const renderModalBody = () => {
    const title = (
      <FormattedMessage
        id="DeleteModal.ConfirmationShortcut"
        defaultMessage="Remove Shortcut"
      />
    );
    const body = intl.formatMessage(
      {
        id: "SUConfigView.DeleteModal.Shortcut",
        defaultMessage:
          "Are you sure you want to remove <strong>{name}</strong>?",
      },
      {
        name: shortcutToDelete.shortcutName,
        strong: (str) => <span className="font-regular">{str}</span>,
      },
    );

    return { body, title };
  };

  const { body, title } = renderModalBody();
  return (
    <Modal title={title} cancel={onCloseModal} footer={renderFooter()}>
      {body}
    </Modal>
  );
};

export default DeleteShortcutModal;
