import { useEffect, useState } from 'react';
import { PanelHeader } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import AdvancedSearchComponent from '../../../../Shared/AdvancedSearch/AdvancedSearchComponent';
import ReferenceLibrary from './ReferenceLibrary';
import { useAppSelector } from '../../../../../store';

interface Props {
  selectedComponent: any;
}

const UnitOperationEquipmentReferencePanel = ({ selectedComponent }: Props) => {
  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary
  );

  const [_search, setSearch] = useState<any>('');
  const [_SUAList, setSUAList] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (selectedComponent) {
      const genericSua = editorAssemblyLibrary.find(
        (a) => a.general.id === selectedComponent.data.assembly?.general.id
      );
      setSUAList(true);

      const search = {
        name: '',
        createdBy: '',
        modifiedBy: '',
        functions: [],
        ancillaryFunctions: [],
        parentSUA: [genericSua],
        containers: [],
        connectors: [],
        tubingMaterials: [],
        tubingInnerDiameters: [],
        tubingLength: null,
        poreSize: null,
        filtrationSurface: null,
        volumes: [],
        lines: [0, 0],
        bags2D: [0, 0],
        sensorTypes: [],
        pumpingTypes: []
      };
      setSearch(search);
    } else {
      setSUAList(false);
    }
  }, [selectedComponent]);

  const renderSearch = () => {
    return (
      <div className="mx-2 my-1">
        <AdvancedSearchComponent
          setSearch={(s) => setSearch(s)}
          selectedComponent={selectedComponent}
        />
      </div>
    );
  };
  const render = () => {
    return (
      <>
        <PanelHeader>
          {intl.formatMessage({
            id: 'activeTab.referencesLibrary',
            defaultMessage: 'References Library'
          })}
        </PanelHeader>
        {_SUAList && renderSearch()}
        <ReferenceLibrary
          components={selectedComponent?.data?.assembly?.components}
          selectedComponent={selectedComponent}
          search={_search}
          referenceList={_search}
          setSUAList={setSUAList}
          />
      </>
    );
  };
  return render();
};

export default UnitOperationEquipmentReferencePanel;
