export  function GetUpdatedSUA(components: any, editorAssemblyLibrary: any) {
  const functionAssemblyList = components.filter((c) => c.type === 'function' && c.data.assembly);
  const componentToUpdateList = [];

  functionAssemblyList.forEach((functionAssembly) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };
    // @ts-ignore
    const functionDate = new Date(
      functionAssembly.data.assembly.general.modifiedOn
      // @ts-ignore
    ).toLocaleDateString(undefined, options);
    const assembly = editorAssemblyLibrary.find(
      (a) => functionAssembly.data.assembly.general.id === a.general.id
    );
    if (!assembly) {
      return;
    }
    // @ts-ignore
    const assemblyDate = new Date(assembly.general.modifiedOn).toLocaleDateString(
      undefined,
      // @ts-ignore
      options
    );

    if (functionDate !== assemblyDate) {
      componentToUpdateList.push({ function: functionAssembly, updatedSua: assembly });
    }
  });

  return componentToUpdateList;
}
