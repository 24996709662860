export const REFERENCE_STATUS_EXPLANATION = {
  preOnboarding: {
    name: 'PreOnboarding',
    explanations: [
      //"SUA can still be 'Private' with this status",
      //"SUA must be set as 'Public' to be able to change its status",
      "Only SUAs with 'Pre-Onboarding' status can be deleted",
      /* "Status can only be changed by Design Owner and SUA's Creator", */
      "Design Owners and Design Editors can edit"
    ]
  },
  onboardingQualification: {
    name: 'Onboarding or Qualification',
    explanations: [
      "Design Owners and Design Editors can edit" /* ,
      "Status can only be changed by Design Owner and SUA's Creator" */
    ]
  },
  effective: {
    name: 'Effective',
    explanations: [
      'SUA cannot be edited anymore' /* ,
      "Status can only be changed by Design Owner and SUA's Creator" */
    ]
  },
  obsolete: {
    name: 'Obsolete',
    explanations: [
      'Viewing and duplicating only',
      'Once the status is obsolete, it can only be changed by Design Owners'
    ]
  }
};
