export enum ReferenceStatus {
  PreOnboarding = 'pre-onboarding',
  OnboardingQualification = 'onboarding/qualification',
/*   Qualification = 'qualification', */
  Effective = 'effective',
  Obsolete = 'obsolete'
}

export const referenceStatusColors = (ref: string) => {
  switch (ref) {
    case ReferenceStatus.PreOnboarding:
      return 'var(--anthracite)';
    case ReferenceStatus.OnboardingQualification:
      return 'var(--dark-orange)';
/*     case ReferenceStatus.Qualification:
      return 'var(--dark-orange)'; */
    case ReferenceStatus.Effective:
      return 'var(--dark-green)';
    case ReferenceStatus.Obsolete:
      return 'var(--dark-red)';
    default:
      return 'var(--primaryColor)';
  }
};

export const referenceStatusBackgroundColors = (ref: string) => {
  switch (ref) {
    case ReferenceStatus.PreOnboarding:
      return 'var(--light-grey)';
    case ReferenceStatus.OnboardingQualification:
      return 'var(--light-orange)';
/*     case ReferenceStatus.Qualification:
      return 'var(--light-orange)'; */
    case ReferenceStatus.Effective:
      return 'var(--light-green)';
    case ReferenceStatus.Obsolete:
      return 'var(--light-red)';
    default:
      return 'var(--primaryColorBcg)';
  }
};
