
export enum OperationStatus {
  Draft = "draft",
  Effective = "effective",
  Obsolete = "obsolete"
}

export const operationStatusColors = (ref: string) => {
  switch (ref) {
    case OperationStatus.Draft:
      return 'var(--anthracite)';
    case OperationStatus.Effective:
      return 'var(--dark-green)';
    case OperationStatus.Obsolete:
      return 'var(--dark-red)';
    default:
      return 'var(--primaryColor)';
  }
};

export const operationStatusBackgroundColors = (ref: string) => {
  switch (ref) {
    case OperationStatus.Draft:
      return 'var(--light-grey)';
    case OperationStatus.Effective:
      return 'var(--light-green)';
    case OperationStatus.Obsolete:
      return 'var(--light-red)';
    default:
      return 'var(--primaryColorBcg)';
  }
};