import { Empty } from 'hakobio-react-ui';
import { CSSProperties, useEffect } from 'react';
import { CUSTOM_NODES } from '../../constants/CUSTOM_NODES';
import { useEditorLibraryAction } from '../../store/features/assemblyEditor/useEditorLibraryAction';
import ComponentItem from './ComponentItem';
import { useAppSelector } from '../../store';

const nodeListStyle: CSSProperties = {
  height: 1,
  display: 'grid',
  // gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gridAutoRows: 200,
  columnGap: 16,
  rowGap: 16
};

const ComponentsList = ({ searchedValue }: { searchedValue: string }) => {
  const { getEditorCustomComponentLibrary } = useEditorLibraryAction();
  const theme = useAppSelector((state) => state.assemblyEditorSlice.theme);

  useEffect(() => {
    getEditorCustomComponentLibrary();
  }, []);

  const constants = Object.entries(CUSTOM_NODES)
    .filter(([key, node]: [string, any]) => {
      if(!!theme && (theme.team === 'marketing' || theme.team === 'cytiva')){
        return true;
      }else{
        const exceptedKeys = ['adapterRmToMtc', 'airTrap', 'needlessClaveSamplePort']
        return !exceptedKeys.includes(key);
      }
    })
    .sort((a: any, b: any) => {
      if (a[1].name > b[1].name) {
        return 1;
      } else if (a[1].name === b[1].name) {
        return 0;
      } else {
        return -1;
      }
    })
    .map((nodeObject: any) => {
      const [key, node] = nodeObject;
      return {
        key,
        type: 'generic',
        node
      };
    })
    .filter((constant: any) => {
      if (constant.node.name === 'Transferring' || constant.node.name === 'Purification') {
        return false;
      } else {
        return constant.node.name.toLowerCase().includes(searchedValue.toLowerCase());
      }
    });

  return (
    <>
      {constants.length ? (
        <div
          className="p-2 f-full overflow-y-auto"
          style={{
            gridTemplateColumns:
              constants.length < 6
                ? 'repeat(auto-fit, minmax(200px, 220px))'
                : 'repeat(auto-fit, minmax(200px, 1fr))',
            ...nodeListStyle
          }}>
          {constants.map((constant: any) => {
            const { key, node } = constant;
            return (
              <ComponentItem
                key={key}
                keyName={key}
                node={node}
                constant={constant}
                component={null}
                isCustomComponent={false}
                isShowingCustomLibrary={false}
                onDeleteCustomComponent={null}
              />
            );
          })}
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default ComponentsList;
