import {
  Chip,
  DoubleChip,
  Empty,
  Icon,
  IconTooltip,
  MetroChip,
  Select,
  TextArea,
  TextInput,
  ThinChip,
  Title,
  Tooltip,
  UserBadge,
  UserBadgeSelect
} from 'hakobio-react-ui';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useStoreActions } from 'react-flow-renderer';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { CUSTOM_FUNCTIONS } from '../../../../constants/CUSTOM_FUNCTIONS';
import { UnitOperationLayer } from '../../../../constants/PFD_EquipmentTabs';
import { configMessages, generalMessages, statusMessages } from '../../../../lang/messages';
import { validateAssemblyName } from '../../../../services/editor/editorService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { MenuItem } from '@mui/material';
import { UoStatus, uoStatusBackgroundColors, uoStatusColors } from '../../../../utilities/uostatus';
import PrivacyModal from '../../SingleUseEditor/LeftPanel/PrivacyModal';

import {
  OperationStatus,
  operationStatusBackgroundColors,
  operationStatusColors
} from '../../../../utilities/statuses/operationStatus';
import { UNIT_OPERATION_STATUS_EXPLANATION } from '../../../../constants/statusExplanations/UNIT_OPERATION_STATUS_EXPLANATION';
import ReferencesInvolved from './ReferencesInvolved';
import { useStatusAction } from '../../../../store/features/assemblyEditor/useStatusAction';
import { GenericStatus } from '../../../../utilities/statuses/genericStatus';
import { DirectoryFolder } from '../../../models/DirectoryFolder';

export const UnitOperationGeneralPanel = (props: any) => {
  const dispatch = useAppDispatch();
  const { onGeneralEdited, setHoveredComponent } = useAssemblyEditorAction();
  const { changeStatus } = useStatusAction();
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const folder = directoriesList.find((f: DirectoryFolder) => f.id === general.parentFolderId);
  const userList = useAppSelector((state) => state.assemblyEditorSlice.userList);

  const [_infoEditing, setInfoEditing] = useState(false);
  const [_hoveredFunction, setHoveredFunction] = useState(null);
  const [_uoName, setUoName] = useState(general.name);
  const [_uoDescription, setUoDescription] = useState(general.description);
  const [_uoStatus, setUoStatus] = useState(general.status);
  const [_nameNotValid, setNameNotValid] = useState(false);
  const [_statusToChange, setStatusToChange] = useState(null);
  const [_privacyModal, setPrivacyModal] = useState(false);
  const [_designOwners, setDesignOwners] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    if (folder) {
      let designOwners = [];
      folder.designOwners.map((designOwner) => {
        designOwners.push(userList.find((user) => user.userId === designOwner));
      });
      setDesignOwners(designOwners);
    }
  }, [directoriesList, userList, folder]);

  const functions = orderBy(
    components.filter((c: any) => c.type === 'function'),
    (f) => f.data.type,
    ['asc']
  );

  const handleMouseEnterFunction = (id) => {
    setHoveredFunction(id);
    setHoveredComponent(id);
  };

  const handleMouseLeaveFunction = (id) => {
    setHoveredFunction(null);
    setHoveredComponent(null);
  };

  const handleSelectFunction = (id) => {
    dispatch(assemblyEditorSliceActions.selectComponents([id]));
    const component = components.find((c: any) => c.id === id);
    setSelectedElements([component]);
  };

  const handleEditName = (event) => {
    setUoName(event.target.value);
    setNameNotValid(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') closeNameEdition();
  };

  const closeNameEdition = async () => {
    const isValid = await validateAssemblyName(general.id, general.type, _uoName);
    if (isValid) {
      setNameNotValid(false);
      dispatch(assemblyEditorSliceActions.setIsSaveDisabled(false));
      setInfoEditing(false);
      onGeneralEdited('name', _uoName);
      onGeneralEdited('description', _uoDescription);
      onGeneralEdited('status', _uoStatus);
    } else {
      setNameNotValid(true);
      dispatch(assemblyEditorSliceActions.setIsSaveDisabled(true));
      toast.error(`'${_uoName}' already exists. Please enter another name.`);
    }
  };

  const editName = (
    <>
      {_infoEditing && general?.permissions?.update ? (
        <div className="f-row gap-3 f1-between f2-start py-2 w-100">
          <TextInput
            id="name"
            label={'Name'}
            inputSize={'small'}
            value={_uoName}
            disabled={!general.permissions.update}
            error={_nameNotValid}
            helperText={
              _nameNotValid ? `'${_uoName}' already exists. Please enter another name.` : null
            }
            onChange={(event: any) => handleEditName(event)}
            onKeyDown={(e: any) => handleKeyPress(e)}
          />
        </div>
      ) : (
        <div className="capitalize py-2">{general.name}</div>
      )}
    </>
  );

  const editDescription = (
    <>
      {_infoEditing && general?.permissions?.update ? (
        <TextArea
          //@ts-ignore
          id="description"
          inputSize="medium"
          label="Description"
          rows={3}
          value={_uoDescription}
          disabled={!general.permissions.update}
          onChange={(event: any) => setUoDescription(event.target.value)}
        />
      ) : (
        <div className="mt-2">
          {general.description === '' ? (
            <span className="frozen-grey">{intl.formatMessage(generalMessages.noDescription)}</span>
          ) : (
            <div style={{ maxHeight: 144, overflow: 'auto' }}>{general.description}</div>
          )}
        </div>
      )}
    </>
  );

  const renderAncillaryFunctionsTitle = (f: any) => {
    return f?.data?.ancillaryFunctions?.[0].complexParameters?.length ? (
      <div>
        {f?.data?.ancillaryFunctions?.[0].complexParameters?.map((fct, index) => {
          return (
            <div className="f-row gap-2 f2-center" key={`function-${fct}-${index}`}>
              <div>{CUSTOM_FUNCTIONS[fct]?.form(20)}</div>
              <div>{CUSTOM_FUNCTIONS[fct]?.name}</div>
            </div>
          );
        })}
      </div>
    ) : (
      ''
    );
  };

  const renderStatusesExplanation = () => {
    return (
      <div className="px-2" style={{ backgroundColor: 'rgba(255,255,255,.6)' }}>
        <Title style={{ marginTop: -4 }}>Unit Operation Statuses</Title>
        <div style={{ marginLeft: '20px', paddingBottom: '5px' }}>
          {intl.formatMessage(statusMessages.statusChangedUO)}
        </div>
        {Object.entries(UNIT_OPERATION_STATUS_EXPLANATION)?.map(
          ([key, value]: [
            key: string,
            value: {
              name: string;
              explanations: string[];
            }
          ]) => {
            return (
              <div>
                <MetroChip
                  key={value.name}
                  label={value.name}
                  color={operationStatusColors(key)}
                  backgroundColor={operationStatusBackgroundColors(key)}
                />
                <ul
                  className="pl-4 py-2"
                  style={{
                    borderLeft:
                      key === OperationStatus.Effective || key === OperationStatus.Obsolete
                        ? 'none'
                        : '1px solid var(--grey)',
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 12
                  }}>
                  {value.explanations.map((exp, index) => {
                    return <li key={`status-explanation-${index}`}>{exp}</li>;
                  })}
                </ul>
              </div>
            );
          }
        )}
      </div>
    );
  };

  const editStatus = () => {
    let message = null;
    if (
      !general.permissions.changeStatus &&
      general.permissions.update &&
      general.status === UoStatus.Draft &&
      general.newAssembly
    ) {
      message = intl.formatMessage(statusMessages.saveBeforeChange);
    } else if (
      !general.permissions.changeStatus &&
      general.permissions.update &&
      general.status === UoStatus.Draft
    ) {
      message = intl.formatMessage(statusMessages.contactOwner);
    } else if (
      !general.permissions.changeStatus &&
      !general.permissions.update &&
      general.status === UoStatus.Obsolete
    ) {
      message = intl.formatMessage(statusMessages.changeStatusNotAllowed);
    } else if (!general.permissions.changeStatus && !general.permissions.update) {
      message = intl.formatMessage(statusMessages.contactOwner);
    } else {
      //message if assembly is in an invalid state
      message = intl.formatMessage(statusMessages.contactOwner);
    }

    let tooltip = null;
    if (!general.permissions.changeStatus) {
      tooltip = (
        <div className="ml-2 f-shrink-0">
          <Tooltip title={message}>
            <Icon name={'about'} />
          </Tooltip>
        </div>
      );
    }

    const handleChangeStatus = (e) => {
      if (
        (!general.permissions.changeStatus &&
          general.permissions.update &&
          general.status === GenericStatus.Draft) ||
        (general.isPrivate && general.permissions.changeStatus && !general.status) ||
        (general.isPrivate &&
          general.permissions.changeStatus &&
          general.status === GenericStatus.Draft)
      ) {
        setPrivacyModal(true);
        setStatusToChange(e.target.value);
      } else {
        setUoStatus(e.target.value);
      }
    };

    return (
      <>
        {_infoEditing ? (
          <div className="f-row f2-center f-shrink-0">
            <Select
              defaultValue={_uoStatus || 'No Status'}
              className="mb-1"
              inputSize="small"
              label={intl.formatMessage(statusMessages.selectStatus)}
              value={_uoStatus || 'No Status'}
              disabled={
                (!general.permissions.changeStatus && general.status !== UoStatus.Draft) ||
                general.newAssembly ||
                (!general.permissions.changeStatus && !general.isPrivate)
              }
              renderValue={(value: string) => (
                <ThinChip
                  center
                  fontSize="smallText"
                  label={<span className="capitalize">{value}</span>}
                  color={uoStatusColors(value)}
                  backgroundColor={uoStatusBackgroundColors(value)}
                />
              )}
              onChange={handleChangeStatus}>
              <MenuItem key={'no-status'} className="capitalize frozen-grey" value={'No Status'}>
                No Status
              </MenuItem>
              {Object.entries(UoStatus).map((status: [string, string]) => {
                return (
                  <MenuItem
                    key={status[0]}
                    className="capitalize"
                    value={status[1]}
                    style={{ color: uoStatusColors(status[1]) }}>
                    {status[1]}
                  </MenuItem>
                );
              })}
            </Select>
            {tooltip}
            <IconTooltip
              className="ml-1"
              placement="right-start"
              name="question-mark"
              title={renderStatusesExplanation()}
            />
          </div>
        ) : (
          <ThinChip
            fontSize="smallText"
            label={<span className="capitalize">{general.status || 'No status'}</span>}
            color={general.status ? uoStatusColors(general.status) : uoStatusColors('No status')}
            backgroundColor={
              general.status
                ? uoStatusBackgroundColors(general.status)
                : uoStatusBackgroundColors('No status')
            }
          />
        )}
      </>
    );
  };

  const renderFunctionInvolved = (
    <div className="f-col f-full pb-2" style={{ height: 1 }}>
      <Title className="mb-0">{intl.formatMessage(configMessages.functionsInvolved)}</Title>
      <div className=" f-col gap-2 f-full" style={{ overflowY: 'auto', height: 1 }}>
        {functions.length ? (
          functions.map((f: any, index: number) => {
            const inverse = _hoveredFunction === f.id;
            return (
              <Tooltip key={f.id + index * 10} title={renderAncillaryFunctionsTitle(f)}>
                <div
                  className="f-row gap-2"
                  onMouseEnter={() => handleMouseEnterFunction(f.id)}
                  onMouseLeave={() => handleMouseLeaveFunction(f.id)}
                  onClick={() => handleSelectFunction(f.id)}>
                  {CUSTOM_FUNCTIONS[f?.data?.type].form(20)}
                  {f?.data?.ancillaryFunctions?.[0] ? (
                    <DoubleChip
                      className="max-1-lines-visible w-100"
                      label1Ratio={'25%'}
                      key={f?.data?.id}
                      color={inverse ? 'white' : 'var(--secondaryColor)'}
                      backgroundColor={
                        inverse ? 'var(--secondaryColor)' : 'var(--secondaryColorBcg)'
                      }
                      label1={
                        <span className="capitalize">
                          {CUSTOM_FUNCTIONS[f?.data?.type].name || 'No name'}
                        </span>
                      }
                      label2={
                        f?.data?.ancillaryFunctions?.[0].complexParameters?.length ? (
                          f?.data?.ancillaryFunctions?.[0].complexParameters?.map((name, index) => {
                            return (
                              <span key={name + index}>
                                {index ===
                                f?.data?.ancillaryFunctions?.[0].complexParameters?.length - 1
                                  ? CUSTOM_FUNCTIONS[name]?.name
                                  : CUSTOM_FUNCTIONS[name]?.name + ', '}
                              </span>
                            );
                          })
                        ) : (
                          <span className="frozen-grey">
                            {intl.formatMessage(generalMessages.noValue)}
                          </span>
                        )
                      }
                    />
                  ) : (
                    <ThinChip
                      className="w-100"
                      key={f?.data?.id}
                      color={inverse ? 'white' : 'var(--secondaryColor)'}
                      backgroundColor={
                        inverse ? 'var(--secondaryColor)' : 'var(--secondaryColorBcg)'
                      }
                      label={CUSTOM_FUNCTIONS[f?.data?.type].name || 'No name'}
                    />
                  )}
                </div>
              </Tooltip>
            );
          })
        ) : (
          <Empty text="No function has been added yet" />
        )}
      </div>
    </div>
  );

  const renderSuaInvolved = (
    <div className="f-col f-full pb-2" style={{ height: 1 }}>
      <Title className="mb-0">SUAs involved</Title>
      <div className=" f-col gap-2 f-full pr-1" style={{ overflowY: 'auto' }}>
        {functions.length ? (
          functions.map((f: any, index: number) => {
            const inverse = _hoveredFunction === f.id;
            return (
              <div
                key={f.id}
                onMouseEnter={() => handleMouseEnterFunction(f.id)}
                onMouseLeave={() => handleMouseLeaveFunction(f.id)}
                onClick={() => handleSelectFunction(f.id)}>
                {f.data?.hasOwnProperty('assembly') ? (
                  <div className="f-row f2-center gap-2 ">
                    <Icon name="manifold" className="pl-1" />
                    <ThinChip
                      className="f-full"
                      label={f.data.assembly.general.name}
                      color={inverse ? 'white' : 'var(--primaryColor)'}
                      backgroundColor={inverse ? 'var(--primaryColor)' : 'var(--primaryColorBcg)'}
                    />
                  </div>
                ) : (
                  <Tooltip title={renderAncillaryFunctionsTitle(f)}>
                    <div
                      key={f.id + index * 10}
                      className="f-row gap-2"
                      onClick={() => handleSelectFunction(f.id)}>
                      {CUSTOM_FUNCTIONS[f?.data?.type].form(20)}
                      {f?.data?.ancillaryFunctions?.[0] ? (
                        <DoubleChip
                          className="max-1-lines-visible w-100"
                          label1Ratio={'25%'}
                          key={f?.data?.id}
                          color={inverse ? 'white' : 'var(--secondaryColor)'}
                          backgroundColor={
                            inverse ? 'var(--secondaryColor)' : 'var(--secondaryColorBcg)'
                          }
                          label1={CUSTOM_FUNCTIONS[f?.data?.type].name || 'No name'}
                          label2={
                            f?.data?.ancillaryFunctions?.[0].complexParameters?.length ? (
                              f?.data?.ancillaryFunctions?.[0].complexParameters?.map(
                                (name, index) => {
                                  return (
                                    <span key={name + index}>
                                      {index ===
                                      f?.data?.ancillaryFunctions?.[0].complexParameters?.length - 1
                                        ? CUSTOM_FUNCTIONS[name]?.name
                                        : CUSTOM_FUNCTIONS[name]?.name + ', '}
                                    </span>
                                  );
                                }
                              )
                            ) : (
                              <span className="frozen-grey">
                                {intl.formatMessage(generalMessages.noValue)}
                              </span>
                            )
                          }
                        />
                      ) : (
                        <ThinChip
                          className="w-100"
                          key={f?.data?.id}
                          color={inverse ? 'white' : 'var(--secondaryColor)'}
                          backgroundColor={
                            inverse ? 'var(--secondaryColor)' : 'var(--secondaryColorBcg)'
                          }
                          label={CUSTOM_FUNCTIONS[f?.data?.type].name || 'No name'}
                        />
                      )}
                    </div>
                  </Tooltip>
                )}
              </div>
            );
          })
        ) : (
          <Empty text="No function has been added yet" />
        )}
      </div>
    </div>
  );

  const designOwners = () => {
    return (
      <>
        <Title
          className="mb-0"
          children={intl.formatMessage({
            id: 'SUConfigView.EditFolderModal.DesignOwners',
            defaultMessage: 'Design Owners'
          })}></Title>
        <div className="pt-1 f-row f2-center gap-2 f-wrap">
          {_designOwners.map((designOwner) => {
            return (
              <div>
                <UserBadge
                  firstName={designOwner.firstname}
                  lastName={designOwner.lastname}
                  size={_designOwners.length > 1 ? 'small' : 'medium'}
                  title={
                    <div className="f-col">
                      <div className="font-regular">
                        {designOwner.firstname} {designOwner.lastname}
                      </div>
                      {designOwner.email}
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const handleConfirm = async () => {
    await changeStatus(general.id, _statusToChange);
    setUoStatus(_statusToChange);
    setPrivacyModal(false);
  };

  const closeModal = () => {
    setPrivacyModal(false);
  };

  return (
    <div className="f-full f-col">
      {_privacyModal && (
        <PrivacyModal
          handleConfirm={handleConfirm}
          closeModal={closeModal}
          statusToChange={_statusToChange}
        />
      )}
      <div className=" f-full f-col px-2" style={{ height: 1 }}>
        <div className="f-col mb-3">
          {/* {folder && renderFolderInfo()} */}
          {_infoEditing ? (
            <div className="f-col f-full">
              <div className="f-row f2-center w-100">
                {editName}
                <Icon
                  name={'chat'}
                  onClick={closeNameEdition}
                  className="ml-2"
                  color="var(--dark-green)"
                />
              </div>
              {editStatus()}
            </div>
          ) : (
            <div className="w-100">
              <div className={`f-row f1-between f2-center mt-2 gap-2 container-action`}>
                <div className="capitalize ">{general.name}</div>
                <div className="f-row f2-center">
                  {editStatus()}
                  {(general?.permissions?.update || general?.permissions?.changeStatus) && (
                    <Icon
                      className="items-action-none ml-2"
                      name="pencil"
                      onClick={() => setInfoEditing(true)}
                    />
                  )}
                </div>
              </div>
              {folder && (
                <div className="f-row py-2 ">
                  <div className={`f-row f2-center gap-2`}>
                    <Tooltip title={'Directory'}>
                      <Icon name="folder" />
                    </Tooltip>
                    {folder?.name}
                  </div>
                </div>
              )}
              <div className="w-10 border-bottom mb-2" />
            </div>
          )}
          {editDescription}
        </div>
        {folder && designOwners()}
        {layerMode === UnitOperationLayer.PFD ? (
          renderFunctionInvolved
        ) : layerMode === UnitOperationLayer.PnID ? (
          renderSuaInvolved
        ) : (
          <ReferencesInvolved functions={functions} />
        )}
      </div>
    </div>
  );
};
