import { cloneDeep } from 'lodash';
import { assemblyEditorSliceActions } from './assemblyEditorSlice';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { useAppDispatch } from '../../index';

export const useDeletionEditorAction = () => {
  const dispatch = useAppDispatch();

  const onUoFunctionRemove = (elementsToRemove: Array<any>, componentList: Array<any>) => {
        const componentsCopy = cloneDeep(componentList);
    
    const selectedComponent = componentsCopy.find((c) => c.id === elementsToRemove[0].id);
    let componentsToRemove = [selectedComponent];
    if (selectedComponent.type === 'function' && !selectedComponent.data.assembly) {
      const results = componentsCopy
        .filter((c) => c.type === 'functionedge')
        .filter((c) => c.source === selectedComponent.id || c.target === selectedComponent.id);
      componentsToRemove = [...componentsToRemove, ...results];
    } else if (selectedComponent.type === 'function' && selectedComponent.data.assembly) {
            const results1 = componentsCopy
        .filter((c) => c.id !== selectedComponent.id && (c.type === 'generic' || c.type === 'genericonedge' || c.type === 'genericedge') && (c.data.assembly?.component === selectedComponent.id || c.data.assemblyReference?.component === selectedComponent.id));
      const results2 = componentsCopy.filter((c) => c.type === 'ghost' && c.data.function === selectedComponent.id);
      const results3 = componentsCopy
        .filter((c) => c.type === 'functionedge')
        .filter((c) => c.source === selectedComponent.id || c.target === selectedComponent.id);
      componentsToRemove = [...componentsToRemove, ...results1, ...results2, ...results3];
    }

    componentsToRemove.forEach((element) => {
      switch (element.type) {
        case 'functionedge':
          removeComponentLink(element.source, element.sourceHandle, componentsCopy);
          removeComponentLink(element.target, element.targetHandle, componentsCopy);
          break;
      }
    });
    dispatch(assemblyEditorSliceActions.removeComponents([...elementsToRemove, ...componentsToRemove]));
  };

  const onSUAElementsRemove = (elementsToRemove: Array<any>, componentList: Array<any>) => {
    dispatch(assemblyEditorSliceActions.removeComponents(elementsToRemove));
  };

  const removeComponents = (elementsToRemove: Array<any>, editorMode: string, layerMode: UnitOperationLayer, componentList: Array<any>) => {
    const componentsCopy = cloneDeep(componentList);
    const componentsToRemove = elementsToRemove.map((element) => {
      return componentsCopy.find((component) => component.id === element.id);
    });
    switch (editorMode) {
      case EditorMode.SUA:
      case EditorMode.Reference:
        onSUAElementsRemove(componentsToRemove, componentsCopy);
        break;
      case EditorMode.UnitOperation:
        onUoFunctionRemove(componentsToRemove, componentsCopy);
        break;
    }
  };

  const removeComponentLink = (componentId: string, handleId: string, componentList: Array<any>) => {
    const component = cloneDeep(componentList.find((c) => c.id === componentId));
    let allAnchors = [...component.data.anchors];
    if (component.data.instrumentationPorts) {
      allAnchors = [...allAnchors, ...component.data.instrumentationPorts];
    }
    if (component.data.samplingPorts) {
      allAnchors = [...allAnchors, ...component.data.samplingPorts];
    }
    const anchor = allAnchors.find((a) => a.id === handleId);
    const linkedComponent = cloneDeep(componentList.find((c) => c.id === anchor.data.componentLink?.component));
    const linkedReferenceComponent = cloneDeep(componentList.find((c) => c.id === anchor.data.componentLinkReference?.component));
    if (linkedComponent) {
      let allAnchorsLinkedComponent = [...linkedComponent.data.anchors];
      if (linkedComponent.data.instrumentationPorts) {
        allAnchorsLinkedComponent = [...allAnchorsLinkedComponent, ...linkedComponent.data.instrumentationPorts];
      }
      if (linkedComponent.data.samplingPorts) {
        allAnchorsLinkedComponent = [...allAnchorsLinkedComponent, ...linkedComponent.data.samplingPorts];
      }
      const linkedComponentAnchor = allAnchorsLinkedComponent.find((a) => a.id === anchor.data.componentLink.anchor);
      if (linkedComponentAnchor) {
        delete linkedComponentAnchor.data.componentLink;
        delete anchor.data.componentLink;
        dispatch(assemblyEditorSliceActions.updateComponent(linkedComponent));
      }
    }
    if (linkedReferenceComponent) {
      let allAnchorsLinkedReferenceComponent = [...linkedReferenceComponent.data.anchors];
      if (linkedReferenceComponent.data.instrumentationPorts) {
        allAnchorsLinkedReferenceComponent = [...allAnchorsLinkedReferenceComponent, ...linkedReferenceComponent.data.instrumentationPorts];
      }
      if (linkedReferenceComponent.data.samplingPorts) {
        allAnchorsLinkedReferenceComponent = [...allAnchorsLinkedReferenceComponent, ...linkedReferenceComponent.data.samplingPorts];
      }
      const linkedComponentReferenceAnchor = allAnchorsLinkedReferenceComponent.find((a) => a.id === anchor.data.componentLinkReference?.anchor);
      if (linkedComponentReferenceAnchor) {
        delete linkedComponentReferenceAnchor.data.componentLinkReference;
        delete anchor.data.componentLinkReference;
        dispatch(assemblyEditorSliceActions.updateComponent(linkedReferenceComponent));
      }
    }
    dispatch(assemblyEditorSliceActions.updateComponent(component));
  };

  return {
    removeComponents
  };
};
