import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../../store';
import { useAssemblyEditorAction } from '../../../../../store/features/assemblyEditor/useAssemblyEditorAction';

import { DoubleChip, Switch } from 'hakobio-react-ui';
import { EditorMode } from '../../../../../constants/EditorMode';
import { useIntl } from 'react-intl';
import { generalMessages } from '../../../../../lang/messages';

interface Props {
  displayParameters: any;
  keyProperty: any;
  selectedComponent: any;
}

export function NodePropertyBoolean({ displayParameters, keyProperty, selectedComponent }: Props) {
  const { onComponentPropertyChanged } = useAssemblyEditorAction();

  const [_isChecked, setIsChecked] = useState(false);

  const intl = useIntl()

  useEffect(() => {
    if (selectedComponent?.data?.hasOwnProperty(keyProperty)) {
      if (selectedComponent?.data?.[keyProperty]) setIsChecked(true);
    } else {
      onComponentPropertyChanged(selectedComponent, keyProperty.replace(/ /g, ''), false);
    }
  }, [selectedComponent]);

  if (displayParameters) {
    return (
      <div className="f-row gap-3 f2-center w-100">
        <div className="capitalize">{keyProperty}</div>
        <Switch
          firstColor="var(--red)"
          secondColor="var(--green)"
          checked={_isChecked}
          onChange={() => {
            onComponentPropertyChanged(
              selectedComponent,
              keyProperty.replace(/ /g, ''),
              !_isChecked
            );
            setIsChecked(!_isChecked);
          }}
        />
      </div>
    );
  } else {
    return (
      <DoubleChip
        className="max-1-lines-visible"
        key={selectedComponent?.data?.[keyProperty]}
        label1={<span className="capitalize">{keyProperty}</span>}
        label2={
          selectedComponent?.data?.hasOwnProperty(keyProperty) ? (
            selectedComponent?.data?.[keyProperty] ? (
              'On'
            ) : (
              'Off'
            )
          ) : (
            <span className="frozen-grey">{intl.formatMessage(generalMessages.noValue)}</span>
          )
        }
      />
    );
  }
}
