import { CustomIcon } from 'hakobio-react-ui';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { CUSTOM_NODES } from '../../../constants/CUSTOM_NODES';
import { configMessages, versioningMessages } from '../../../lang/messages';
import { config } from '../../../services/config';

interface Props {
  assemblyId: string;
  assemblyFunction: any;
  hashPartitionKey: string;
  exportDate: string;
  time?: number;
  pageNumber: number;
  totalPageNumber: number;
  suaLoaded: Function;
  suaUnloaded: Function;
  legend: any;
  entriesAmount: number;
  setDeletedAssemblies?: any;
}

//TODO A voir si on le met dans le dossier utils(général) ou si on créer un dossier utils par dossier (ex: ReportPanel) car il ne sera utilisé que dans ce dossier
export const connectionComponents = [
  'plug',
  'asepticDisconnector',
  'mechanicDisconnector',
  'quickCoupler',
  'triclampConnector',
  'couplerReducer',
  'lConnector',
  'tConnector',
  'yConnector',
  'xConnector',
  'asepticConnector',
  'sipConnector'
];
const imgHeight = 665;
const SchemaSUA = ({
  assemblyId,
  assemblyFunction,
  hashPartitionKey,
  exportDate,
  time,
  pageNumber,
  totalPageNumber,
  suaLoaded,
  suaUnloaded,
  legend,
  entriesAmount
}: Props) => {
  const [_assemblyGeneral, setAssemblyGeneral] = useState(null);
  const [_assemblyComponents, setAssemblyComponents] = useState(null);
  // const [_imageDisplay, setImageDisplay] = useState(true);
  const [_src, setSrc] = useState(null);
  const _isSuaLoadedRef = useRef(false);
  const [_isArchived, setIsArchived] = useState(false);
  const [_errorCount, setErrorCount] = useState(0);

  const intl = useIntl();

  useEffect(() => {
    if (!_isSuaLoadedRef.current) {
      getCurrentAssembly(assemblyId, hashPartitionKey);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (_isSuaLoadedRef.current) {
        suaUnloaded(assemblyId);
      }
    };
  }, []);

  useEffect(() => {
    if (_assemblyGeneral) {
      setSrc(
        config.BlobURL +
          '/single-use-configurator/assemblies/thumbnail/' +
          _assemblyGeneral.id +
          '/' +
          _assemblyGeneral.hashPartitionKey +
          '_SUA_Annotated' +
          '.png?cache=' +
          _assemblyGeneral.modifiedOn
      );
    }
  }, [_assemblyGeneral]);

  const getCurrentAssembly = async (id: string, hashPartitionKey: string) => {
    setAssemblyGeneral(assemblyFunction.general);
    setAssemblyComponents(assemblyFunction.components);
    if (assemblyFunction.isArchived) {
      setIsArchived(true);
    }

    if (!time || time === 0) {
      suaLoaded(id);
      _isSuaLoadedRef.current = true;
    }
  };

  if (!_assemblyGeneral) {
    return null;
  }

  const onError = () => {
    if (_errorCount === 0) {
      setSrc(
        config.BlobURL +
          '/single-use-configurator/assemblies/thumbnail/' +
          _assemblyGeneral.id +
          '_SUA_Annotated' +
          '.png?nocache=' +
          _assemblyGeneral.modifiedOn
      );
      setErrorCount(_errorCount + 1);
    } else {
      setSrc(null);
    }
  };

  // dividing legend in groups to display on separated pages
  let amount = 0;
  let legendDivided = [];
  legend.forEach((leg, index) => {
    amount = amount + leg.elementCount;
    const divided = legendDivided.flat(2);
    
    if (amount >= 33) {
      const part = legend.slice(divided?.length, index);
      legendDivided.push([part]);
      amount = leg.elementCount;
      const newDevided = [...legendDivided].flat(2);
      if (index === legend.length - 1 && legend.length > newDevided.length) {
        const part = legend.slice(newDevided.length);
        legendDivided.push([part]);
        amount = leg.elementCount;
      }
    } else {
      if (index === legend.length - 1 && legend.length > divided.length) {
        const part = legend.slice(divided.length);
        legendDivided.push([part]);
        amount = leg.elementCount;
      }
    }
  });

  const components = entriesAmount < 34 ? legend : legendDivided[time]?.flat();

  const render = () => {
    return (
      <div
        key={`schemaSUA-${_assemblyGeneral.id}-${pageNumber}`}
        className="mt-3 w-100 h-100 relative"
        style={{
          breakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always',
          pageBreakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always',
          padding: '0 2rem 0.5rem 2rem',
          display: 'block'
        }}>
        <div className="border-bottom py-2">
          <div className="f-row f1-between f2-center">
            <div>
              <span>{_assemblyGeneral.type === 'SingleUseAssembly' ? intl.formatMessage(configMessages.sua) :  intl.formatMessage(configMessages.reference)}</span>
              <span className="px-2">&#124;</span>
              <span className="font-regular">{_assemblyGeneral.name}</span>
              <span className="px-2">&#124;</span>
              <span>
                {`${intl.formatMessage(versioningMessages.version)} ${
                  Number(_assemblyGeneral.version) + 1
                }`}
                {_isArchived ? (
                  <span>{` (${intl.formatMessage(versioningMessages.archived)})`}</span>
                ) : null}
              </span>
            </div>
            <div>{exportDate}</div>
          </div>
        </div>

        <div className="f-row gap-2 pt-3">
          <div className="f-full" style={{ height: imgHeight }}>
            {/* {_imageDisplay ? ( */}
            <img
              alt={_assemblyGeneral.name}
              style={{
                objectFit: 'contain',
                height: imgHeight
              }}
              className="w-100"
              src={_src}
              onError={onError}
            />
            {/* ) : (
              <Empty
                size="md"
                text={intl.formatMessage({
                  id: 'SchemaSUA.EmptyText.NoSUAFound',
                  defaultMessage: 'Graphic not available'
                })}
              />
            )} */}
          </div>
          <div className="border-left px-2 f-col gap-2" style={{ width: '30%', flexShrink: 0 }}>
            {components &&
              components.map((component, index) => {
                return (
                  <SchemaSUAComponent
                    key={component.id + index}
                    component={component}
                    index={index}
                    time={time}
                  />
                );
              })}
          </div>
        </div>
        <div className="f-row f1-end gap-2 mt-2" style={{ fontSize: 13 }}>
          {time > 0 && (
            <div className="dark-orange f-row f2-center gap-1">
              <CustomIcon name="alert-2" />
              Continued from previous page
            </div>
          )}
          <div>{`Page ${pageNumber}/${totalPageNumber}`}</div>
        </div>
      </div>
    );
  };

  if (!_assemblyComponents) {
    return null;
  }

  return render();
};

export default SchemaSUA;

interface Props2 {
  component: any;
  index: number;
  time?: number;
}

const SchemaSUAComponent = ({ component, index, time }: Props2) => {
  const [_schemaHeader, setSchemaHeader] = useState(null);
  const [_schema, setSchema] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    const getSchema = async () => {
      const schema = await schemaHeader.schema();
      setSchema(schema);
    };
    const schemaHeader = CUSTOM_NODES[component.type];
    if (schemaHeader) {
      setSchemaHeader(schemaHeader);
      getSchema();
    }
  }, []);

  const getComponentParameters = () => {
    return Object.entries(_schema.properties).map(([key, property]: [any, any]) => {
      if (
        key === 'name' ||
        key === 'customName' ||
        key === 'anchors' ||
        key === 'instrumentationPorts' ||
        key === 'samplingPorts'
      ) {
        return null;
      }
      const value = component[key];
      if (value) {
        return (
          <div className="f-row f2-center" key={key} style={{ fontSize: 13 }}>
            <div className="f-row f2-end gap-2" style={{ flexWrap: 'wrap' }}>
              <span className="capitalize frozen-grey">{property.name}</span>
              <span> &#8226; </span>
              <span className="font-regular">
                {value +
                  (property.units
                    ? ' ' + property?.units === 'inch'
                      ? "'"
                      : ' ' + property?.units
                    : '')}
              </span>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderAnchorsByType = (componentType: any, shemasType: any) => {
    return componentType.map((a, i) => {
      return Object.entries(shemasType).map(([key, property]: [any, any]) => {
        if (key === 'position') {
          return null;
        }
        if (!a) return null;
        let value = a[key];
        if (!value) return null;
        return (
          <div className="f-row f2-center" key={`${key}-${i}`} style={{ fontSize: 13 }}>
            <div className="f-row f2-end gap-2" style={{ flexWrap: 'wrap' }}>
              <span className="capitalize frozen-grey">{property.name + (i + 1)}</span>
              <span> &#8226; </span>
              <span className="font-regular">{value}</span>
            </div>
          </div>
        );
      });
    });
  };

  function anchorsWithParams(anchors: any) {
    let hasParams = false;
    anchors.forEach((anchor) => {
      if (anchor !== null && anchor !== undefined ) {
        Object.keys(anchor).forEach((key) => {
          if (key !== 'position' && key !== 'componentLink' && key !== 'name') {
            hasParams = true;
          }
        });
      }
    });
    return hasParams;
  }

  const renderAnchors = (type: string) => {
    let anchorsList;
    let schemasType;
    if (type === 'anchors' && component?.anchors && anchorsWithParams(component?.anchors)) {
      anchorsList = component?.anchors;
      schemasType = _schema.$defs.anchor.properties;
      return (
        <div>
          <div className="f-row f2-center gap-2 mt-1 mb-0.5" style={{ fontSize: 13 }}>
            <CustomIcon name="blood" size="0.8rem" color="#187bde" />{' '}
            {intl.formatMessage(configMessages.fluidTransfer)}
          </div>
          {renderAnchorsByType(anchorsList, schemasType)}
        </div>
      );
    }
    if (component.type !== 'tubing') {
      if (
        type === 'instrumentationPorts' &&
        component?.instrumentationPorts &&
        anchorsWithParams(component?.instrumentationPorts)
      ) {
        anchorsList = component?.instrumentationPorts;
        schemasType = _schema.$defs.instrumentationPort.properties;
        return (
          <div>
            <div className="f-row f2-center gap-2 mt-1 mb-0.5" style={{ fontSize: 13 }}>
              <CustomIcon
                hako3={true}
                name="instrumentation"
                size="0.8rem"
                color="#ad5f2e"
                style={{ lineHeight: 0.5 }}
              />{' '}
              {intl.formatMessage(configMessages.instrumentationPorts)}
            </div>{' '}
            {renderAnchorsByType(anchorsList, schemasType)}
          </div>
        );
      }
      if (
        type === 'samplingPorts' &&
        component?.samplingPorts &&
        anchorsWithParams(component?.samplingPorts)
      ) {
        anchorsList = component?.samplingPorts;
        schemasType = _schema.$defs.samplingPort.properties;
        return (
          <div>
            <div className="f-row f2-center gap-2 mt-1 mb-0.5" style={{ fontSize: 13 }}>
              <CustomIcon
                hako3={true}
                name="sampling"
                size="0.8rem"
                color="#6a2fad"
                style={{ lineHeight: 0.5 }}
              />
              {intl.formatMessage(configMessages.samplingPorts)}
            </div>
            {renderAnchorsByType(anchorsList, schemasType)}
          </div>
        );
      }
    }
  };

  const render = () => {
    return (
      <div className="f-col" key={`schemaSUAComponent-${index}`}>
        <div>
          {component.legendNumber}
          <span className="bold"> {_schemaHeader.name} </span>
          <span style={{ fontSize: 13 }}> &times;{component.count}</span>
        </div>
        {getComponentParameters()}
        {renderAnchors('anchors')}
        {renderAnchors('instrumentationPorts')}
        {renderAnchors('samplingPorts')}
      </div>
    );
  };

  if (!_schema || !_schemaHeader) {
    return null;
  }

  return render();
};
