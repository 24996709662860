import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';

import { FormattedMessage } from 'react-intl';
import { ListTab } from '../../../constants/ListTab';
import { EntityDisplayed } from '../../../constants/EntityDisplayed';

const AllAssembliesLine = (props) => {
  const dispatch = useAppDispatch();
  const selectedFolder = useAppSelector((state) => state.assemblyEditorSlice.selectedFolder);
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);

  const selectAll = () => {
    dispatch(assemblyEditorSliceActions.setEntitiesDisplayed(EntityDisplayed.SuaReference));
    dispatch(assemblyEditorSliceActions.selectFolder(null));
  };

  return (
    <div
      style={{
        backgroundColor: selectedFolder ? undefined : '#F5F5F5',
        color: selectedFolder ? undefined : 'var(--primaryColor)'
      }}
      className={'f-row f2-center gap-2 p-2 hover-item'}
      onClick={selectAll}>
      {listTab === (ListTab.SUA as number) ? (
        <FormattedMessage id="AllProjectLine.AllAssemblies" defaultMessage="All Assemblies" />
      ) : (
        <FormattedMessage
          id="AllProjectLine.AllUnitOperation"
          defaultMessage="All unit operation"
        />
      )}
    </div>
  );
};

export default AllAssembliesLine;
