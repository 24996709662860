import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { cloneDeep } from 'lodash';
import { ListTab } from '../../../constants/ListTab';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { ViewMode } from '../../../constants/ViewMode';
import { DirectoryFolder } from '../../../components/models/DirectoryFolder';
import { EntityDisplayed } from '../../../constants/EntityDisplayed';
export interface assemblyEditorState {
  general: {
    createdOn: string;
    modifiedOn: string;
    name: string;
    id: string;
    hashPartitionKey: string;
    description: string;
    functions: string[];
    ancillaryFunctions: any[];
    medias: any[];
    assemblyGenericList: any[];
    assemblyReferenceList: any[];
    type: string;
    tags: any[];
    legend: any[];
    version: number;
    versionMessage: string;
    newAssembly: boolean;
    isPrivate: boolean;
    supplier: string;
    status: string;
    permissions: {
      changeStatus: boolean;
      createReference: boolean;
      delete: boolean;
      duplicate: boolean;
      read: boolean;
      update: boolean;
    };
    parentFolderId: string;
  };
  user: any;
  theme: any;
  lastTubingDetails: {};
  components: any[];
  selectedComponents: any[] | null;
  updatedSUAs: any[] | null;
  errors: any[];
  listTab: ListTab;
  suaCount: number;
  showShareModal: any;
  showShortcutModal: any;
  showSaveModal: any;
  showDeleteModal: any;
  showDeleteFolderModal: any;
  showConfirmDeleteModal: any;
  showConfirmDeleteShortcutModal: any;
  showReferenceEditionlModal: any;
  showHistoryModal: any;
  showEditReferenceEdition: any;
  editorMode: EditorMode | null;
  layerMode: UnitOperationLayer;
  viewMode: ViewMode;
  entitiesDisplayed: EntityDisplayed;
  preShotLayerMode: UnitOperationLayer | null;
  selectedAnchors: any[] | null;
  selectedEdges: any[] | null;
  hoveredComponent: string | null;
  hoveredAnchor: string | null;
  hoveredWarning: string | null;
  hoverEdge: string | null;
  hoverTube: string | null;
  hoverOnEdge: string | null;
  hoverComponentGenericNode: string | null;
  promiseUpdateComponent: any[];
  assemblyListModified: boolean;
  editorAssemblyLibrary: any[];
  allHeadersLibrary: any[];
  allDirectories: any[];
  isComponentSnappable: boolean;
  isSavingAssembly: boolean;
  isSavingScreenshotSUA: boolean;
  isSavingScreenshotComponent: boolean;
  isSavingScreenshotSUAAnnotated: boolean;
  isSavingScreenshotUO: boolean;
  isSavingScreenshotUOAnnotatedPFD: boolean;
  isSavingScreenshotUOAnnotatedPID: boolean;
  isShowingGhostNode: boolean;
  isEditingAssembly: boolean;
  assemblyToDuplicate: any;
  isEditingFolder: boolean;
  isSaveDisabled: boolean;
  isLayerLocked: boolean;
  areSUAFramesDisplayed: boolean;
  isSUALibraryCharged: boolean;
  isSUAUpdating: boolean;
  listComparionVersions: [];
  outdatedAssemblies: [];
  customComponentEdition: any;
  customComponentViewOnly: boolean;
  editorCustomComponentLibrary: any[];
  libComponentName: string | null;
  selectedCustomTubing: any;
  jwt: string;
  jwtTime: Date;
  reportData: {
    frontPage: boolean;
    headerInformation: boolean;
    tableOfContent: boolean;
    PFDView: boolean;
    PIDView: boolean;
    allSUA: string[];
    allReference: string[];
    metrics: boolean;
  };
  selectedFolder: DirectoryFolder;
  uoHorisontalActiveTab: number;
  userList: any;
}

const initialState: assemblyEditorState = {
  components: [],
  listComparionVersions: [],
  selectedComponents: null,
  updatedSUAs: null,
  user: null,
  theme: null,
  general: {
    createdOn: new Date().toISOString(),
    modifiedOn: new Date().toISOString(),
    name: '',
    id: uuidv4(),
    hashPartitionKey: '',
    description: '',
    functions: [],
    ancillaryFunctions: [],
    medias: [],
    type: '',
    assemblyGenericList: [],
    assemblyReferenceList: [],
    tags: [],
    legend: [],
    version: 0,
    versionMessage: '',
    newAssembly: false,
    isPrivate: false,
    supplier: '',
    status: null,
    permissions: null,
    parentFolderId: null
  },
  lastTubingDetails: {},
  errors: [],
  listTab: 0,
  suaCount: 0,
  showDeleteModal: null,
  showDeleteFolderModal: null,
  showConfirmDeleteModal: null,
  showConfirmDeleteShortcutModal: null,
  showReferenceEditionlModal: null,
  showShareModal: null,
  showShortcutModal: null,
  showSaveModal: null,
  showHistoryModal: null,
  showEditReferenceEdition: null,
  editorMode: null,
  layerMode: UnitOperationLayer.PFD,
  viewMode: ViewMode.Editor, //EntityDisplayed
  entitiesDisplayed: EntityDisplayed.Component,
  preShotLayerMode: null,
  selectedAnchors: null,
  selectedEdges: null,
  hoveredComponent: null,
  hoveredAnchor: null,
  hoveredWarning: null,
  hoverEdge: null,
  hoverTube: null,
  hoverOnEdge: null,
  hoverComponentGenericNode: null,
  promiseUpdateComponent: [],
  assemblyListModified: false,
  editorAssemblyLibrary: [],
  allHeadersLibrary: [],
  allDirectories: [],
  isComponentSnappable: false,
  outdatedAssemblies: [],
  isSavingAssembly: false,
  isSavingScreenshotSUA: false,
  isSavingScreenshotComponent: false,
  isSavingScreenshotSUAAnnotated: false,
  isSavingScreenshotUO: false,
  isSavingScreenshotUOAnnotatedPFD: false,
  isSavingScreenshotUOAnnotatedPID: false,
  isShowingGhostNode: false,
  isEditingAssembly: false,
  assemblyToDuplicate: null,
  isEditingFolder: false,
  isSaveDisabled: false,
  isLayerLocked: true,
  areSUAFramesDisplayed: false,
  isSUALibraryCharged: false,
  isSUAUpdating: false,
  customComponentEdition: null,
  customComponentViewOnly: false,
  editorCustomComponentLibrary: [],
  libComponentName: null,
  selectedCustomTubing: null,
  jwt: null,
  jwtTime: null,
  reportData: {
    frontPage: false,
    headerInformation: false,
    tableOfContent: false,
    PFDView: false,
    PIDView: false,
    allSUA: [],
    allReference: [],
    metrics: false
  },
  selectedFolder: null,
  uoHorisontalActiveTab: 0,
  userList: null
};

export const assemblyEditorSlice = createSlice({
  name: 'assemblyEditor',
  initialState,
  reducers: {
    setJWT(state, action) {
      state.jwt = action.payload.jwt;
      state.jwtTime = action.payload.jwtTime;
    },
    setUser(state, action) {
      state.user = action.payload.user;
    },
    setTheme(state, action) {
      state.theme = action.payload.theme;
    },
    setUserList(state, action) {
      state.userList = action.payload.userList;
    },
    setSavingAssembly(state, action) {
      state.isSavingAssembly = action.payload;
    },
    setSavingScreenshotSUA(state, action) {
      state.isSavingScreenshotSUA = action.payload;
    },
    setSavingScreenshotComponent(state, action) {
      state.isSavingScreenshotComponent = action.payload;
    },
    setSavingScreenshotSUAAnnotated(state, action) {
      state.isSavingScreenshotSUAAnnotated = action.payload;
    },
    setSavingScreenshotUO(state, action) {
      state.isSavingScreenshotUO = action.payload;
    },
    setSavingScreenshotUOAnnotatedPFD(state, action) {
      state.isSavingScreenshotUOAnnotatedPFD = action.payload;
    },
    setSavingScreenshotUOAnnotatedPID(state, action) {
      state.isSavingScreenshotUOAnnotatedPID = action.payload;
    },
    setIsShowingGhostNode(state, action) {
      state.isShowingGhostNode = action.payload;
    },
    resetEditorAssemblyLibrary(state, action) {
      state.editorAssemblyLibrary = [];
    },
    setEditorAssemblyLibrary(state, action) {
      state.editorAssemblyLibrary = action.payload;
    },
    setCustomComponentEdition(state, action) {
      state.customComponentEdition = action.payload;
    },
    setCustomComponentViewOnly(state, action) {
      state.customComponentViewOnly = action.payload;
    },
    resetEditorCustomComponentLibrary(state, action) {
      state.editorCustomComponentLibrary = [];
    },
    addInEditorAssemblyLibrary(state, action) {
      const library = [...state.editorAssemblyLibrary];
      library.push(action.payload);
      state.editorAssemblyLibrary = library;
    },
    setAllHeadersLibrary(state, action) {
      state.allHeadersLibrary = action.payload;
    },
    setAllDirectories(state, action) {
      state.allDirectories = action.payload;
    },
    addInEditorCustomComponentLibrary(state, action) {
      const componentLibrary = [...state.editorCustomComponentLibrary];
      componentLibrary.push(action.payload);
      state.editorCustomComponentLibrary = componentLibrary;
    },
    setLibComponentName(state, action) {
      state.libComponentName = action.payload;
    },
    setSuaCount(state, action) {
      state.suaCount = action.payload;
    },
    setSelectedCustomTubing(state, action) {
      state.selectedCustomTubing = action.payload;
    },
    setAssemblyListModified(state, action) {
      state.assemblyListModified = action.payload;
    },
    setAssembly(state, action) {
      state.general = action.payload.general;
      state.components = action.payload.components;
    },
    setListTab(state, action) {
      state.listTab = action.payload;
    },
    setShowDeleteModal(state, action) {
      state.showDeleteModal = action.payload;
    },
    setShowReferenceEditionModal(state, action) {
      state.showReferenceEditionlModal = action.payload;
    },
    setShowDeleteFolderModal(state, action) {
      state.showDeleteFolderModal = action.payload;
    },
    setShowDeleteConfirmModal(state, action) {
      if (!state.isShowingGhostNode) state.showConfirmDeleteModal = action.payload;
    },
    setShowDeleteShortcutConfirmModal(state, action) {
      state.showConfirmDeleteShortcutModal = action.payload;
    },
    setShowShareModal(state, action) {
      state.showShortcutModal = action.payload;
    },
    setShowShortcutModal(state, action) {
      state.showShortcutModal = action.payload;
    },
    setShowSaveModal(state, action) {
      state.showSaveModal = action.payload;
    },
    setShowHistoryModal(state, action) {
      state.showHistoryModal = action.payload;
    },
    setShowEditReferenceEdition(state, action) {
      state.showEditReferenceEdition = action.payload;
    },
    setEditorMode(state, action) {
      state.editorMode = action.payload;
    },
    setLayerMode(state, action) {
      state.layerMode = action.payload;
    },
    setViewMode(state, action) {
      state.viewMode = action.payload;
    },
    setEntitiesDisplayed(state, action) {
      state.entitiesDisplayed = action.payload;
    },
    setPreShotLayerMode(state, action) {
      state.preShotLayerMode = action.payload;
    },
    setGeneral(state, action) {
      state.general = action.payload;
    },
    editGeneral(state, action) {
      const { key, value } = action.payload;
      const generalCopy = JSON.parse(JSON.stringify(state.general));
      //@ts-ignore
      generalCopy[key] = value;
      state.general = generalCopy;
      checkRules(state);
    },
    editTubingDetails(state, action) {
      const edgeDataCopy: any = action.payload;
      state.lastTubingDetails = cloneDeep(edgeDataCopy);
    },
    setComponents(state, action) {
      state.components = action.payload;
      checkRules(state);
    },
    setIsComponentSnappable(state, action) {
      state.isComponentSnappable = action.payload;
    },
    setListComparisonVersions(state, action) {
      state.listComparionVersions = action.payload;
    },
    setUpdatedSUAs(state, action) {
      state.updatedSUAs = action.payload;
      checkRules(state);
    },
    addComponent(state, action) {
      state.components.push(action.payload);
      checkRules(state);
    },
    unshiftComponent(state, action) {
      state.components.unshift(action.payload);
      checkRules(state);
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
    forceRender(state, action) {
      state.components = cloneDeep(state.components);
    },
    removeComponents(state, action) {
      const componentsToDelete: any[] = action.payload;
      componentsToDelete.forEach((componentToDelete) => {
        const index = state.components.findIndex(
          (component) => component.id === componentToDelete.id
        );
        if (index !== -1) {
          state.components.splice(index, 1);
        }
      });
      checkRules(state);
    },
    updateComponent(state, action) {
      const componentToUpdate: any = action.payload;
      const index = state.components.findIndex(
        (component) => component.id === componentToUpdate.id
      );
      if (index !== -1) {
        state.components[index] = cloneDeep(componentToUpdate);
      }
      checkRules(state);
    },
    addPromiseUpdateComponent(state, action) {
      const promise: any = action.payload;
      const index = state.promiseUpdateComponent.findIndex((p) => p.id === promise.id);
      const promises = state.promiseUpdateComponent;
      if (index !== -1) {
        promises[index].component = cloneDeep(promise.component);
      } else {
        promises.push(cloneDeep(promise));
      }
    },
    resetPromiseUpdateComponent(state, action) {
      state.promiseUpdateComponent = [];
    },
    updateComponentProperty(state, action) {
      const { property, component, componentProperty } = action.payload;
      const index = state.components.findIndex((c) => c.id === component.id);
      if (index !== -1) {
        state.components[index][componentProperty] = {
          ...state.components[index][componentProperty],
          ...component[property]
        };
      }
      checkRules(state);
    },
    selectComponents(state, action) {
      state.selectedComponents = action.payload;
    },
    selectedAnchors(state, action) {
      state.selectedAnchors = action.payload;
    },
    selectedEdges(state, action) {
      state.selectedEdges = action.payload;
    },
    resetEditor(state, action) {
      const newId = uuidv4();
      state.components = [];
      state.editorAssemblyLibrary = [];
      state.selectedComponents = null;
      state.general = {
        createdOn: new Date().toISOString(),
        modifiedOn: new Date().toISOString(),
        name: 'New assembly',
        id: newId,
        hashPartitionKey: '',
        description: '',
        functions: [],
        ancillaryFunctions: [],
        medias: [],
        type: '',
        tags: [],
        assemblyGenericList: [],
        assemblyReferenceList: [],
        legend: [],
        version: 0,
        versionMessage: '',
        newAssembly: false,
        isPrivate: false,
        status: null,
        supplier: '',
        permissions: null,
        parentFolderId: null
      };
      state.errors = [];
      state.layerMode = UnitOperationLayer.PFD;
      state.selectedAnchors = null;
      state.selectedEdges = null;
      state.hoveredComponent = null;
      state.hoveredAnchor = null;
      state.hoveredWarning = null;
      state.hoverEdge = null;
      state.hoverTube = null;
      state.hoverOnEdge = null;
      state.hoverComponentGenericNode = null;
      state.promiseUpdateComponent = [];
      state.listTab =
        state.editorMode === EditorMode.Component
          ? 0
          : state.editorMode === EditorMode.SUA || state.editorMode === EditorMode.Reference
          ? 1
          : 2;
      state.editorMode = null;
      state.libComponentName = EditorMode.Component ? state.libComponentName : null;
      state.isEditingAssembly = false;
      state.isLayerLocked = true;
      state.areSUAFramesDisplayed = false;
      state.reportData = {
        frontPage: true,
        headerInformation: false,
        tableOfContent: false,
        PFDView: false,
        PIDView: false,
        allSUA: [],
        allReference: [],
        metrics: false
      };
    },
    hoveredComponent(state, action) {
      state.hoveredComponent = action.payload;
    },
    hoveredAnchor(state, action) {
      state.hoveredAnchor = action.payload;
    },

    setHoveredWarning(state, action) {
      state.hoveredWarning = action.payload;
    },
    setHoverEdge(state, action) {
      state.hoverEdge = action.payload;
    },
    setHoverTube(state, action) {
      state.hoverTube = action.payload;
    },
    setHoverOnEdge(state, action) {
      state.hoverOnEdge = action.payload;
    },
    setHoverComponentGenericNode(state, action) {
      state.hoverComponentGenericNode = action.payload;
    },
    setEditingAssembly(state, action) {
      state.isEditingAssembly = action.payload;
    },
    setEditingFolder(state, action) {
      state.isEditingFolder = action.payload;
    },
    setAssemblyDuplicate(state, action) {
      state.assemblyToDuplicate = action.payload;
    },
    setIsSaveDisabled(state, action) {
      state.isSaveDisabled = action.payload;
    },
    setReportData(state, action) {
      state.reportData = action.payload;
    },
    setSUAFramesDisplayed(state, action) {
      state.areSUAFramesDisplayed = action.payload;
    },
    setIsLayerLocked(state, action) {
      state.isLayerLocked = action.payload;
    },
    setIsSUALibraryCharged(state, action) {
      state.isSUALibraryCharged = action.payload;
    },
    setIsSUAUpdating(state, action) {
      state.isSUAUpdating = action.payload;
    },
    setOutdatedAssemblies(state, action) {
      state.outdatedAssemblies = action.payload;
    },
    selectFolder(state, action) {
      state.selectedFolder = action.payload;
    },
    setUoHorisontalActiveTab(state, action) {
      state.uoHorisontalActiveTab = action.payload;
    }
  }
});

export const assemblyEditorSliceActions = assemblyEditorSlice.actions;

export const assemblyEditorSliceReducer = assemblyEditorSlice.reducer;

function checkRules(state: WritableDraft<assemblyEditorState>) {
  state.general.modifiedOn = new Date().toISOString();
}
