import { Autocomplete, Icon, Modal, SmallButton } from "hakobio-react-ui";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { buttonSaveStyle } from "../components/EditorPanel/style/styles";
import { DirectoryFolder } from "../components/models/DirectoryFolder";
import { ListTab } from "../constants/ListTab";

import { useAppDispatch, useAppSelector } from "../store";
import { assemblyEditorSliceActions } from "../store/features/assemblyEditor/assemblyEditorSlice";
import { saveShortcut } from "../services/editor/editorService";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { actionMessages } from "../lang/messages";

const CreateShortcutModal = () => {
  const dispatch = useAppDispatch();
  const assemblyToCreateShortcut = useAppSelector(
    (state) => state.assemblyEditorSlice.showShortcutModal,
  );

  const [_directory, setDirectory] = useState(null);

  const intl = useIntl();

  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const directoriesList = useAppSelector(
    (state) => state.assemblyEditorSlice.allDirectories,
  );

  const tabFolderList =
    listTab === ListTab.SUA
      ? directoriesList.filter(
          (f: DirectoryFolder) => f.directoryType === (ListTab.SUA as number),
        )
      : directoriesList.filter(
          (f: DirectoryFolder) =>
            f.directoryType === (ListTab.UnitOperation as number),
        );

  const onCloseModal = () => {
    dispatch(assemblyEditorSliceActions.setShowShortcutModal(null));
  };

  const onCreateShortCut = async () => {
    if (_directory.shortCuts.includes(assemblyToCreateShortcut.assemblyId)) {
      toast.warn(`Shortcut already exists in ${_directory.name}`);
      return;
    }
    const newFolder = {
      ..._directory,
      shortCuts: [
        ..._directory?.shortCuts,
        assemblyToCreateShortcut.assemblyId,
      ],
    };

    const saveResult = await saveShortcut(
      newFolder.id,
      assemblyToCreateShortcut.assemblyId,
    );

    if (saveResult) {
      toast.success(
        `Shortcut ${assemblyToCreateShortcut.assemblyName} has been created`,
      );
      let directoriesListCopy = cloneDeep(directoriesList);
      let newDirList = directoriesListCopy.map((directory) => {
        if (directory.id === _directory.id) {
          saveResult.permissions = _directory.permissions;
          saveResult.designEditors = _directory.designEditors;
          saveResult.designOwners = _directory.designOwners;
          return { ...saveResult };
        }
        return directory;
      });
      dispatch(assemblyEditorSliceActions.setAllDirectories(newDirList));
    } else {
      toast.error(`Shortcut couldn't be created`);
    }
    dispatch(assemblyEditorSliceActions.setShowShortcutModal(null));
  };

  const renderFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "5px",
          position: "relative",
        }}
      >
        <SmallButton
          disabled={
            _directory === null || _directory === undefined || _directory === ""
          }
          width={"150px"}
          color={"var(--primaryColor)"}
          style={buttonSaveStyle}
          onClick={async () => {
            onCreateShortCut();
          }}
        >
          <FormattedMessage id="Global.Action.Save" defaultMessage="Save" />
        </SmallButton>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="w-100 h-100 f-col f1-center gap-2">
        <div className="f-col gap-2 f1-start f2-start f-full pb-2">
          <div className="f-row f2-center w-100 gap-2 ">
            <span>
              {intl.formatMessage(
                {
                  id: "SUConfigView.ShortcutModal",
                  defaultMessage:
                    "Select a folder to create a shortcut for <b>{name}</b>",
                },
                {
                  name: assemblyToCreateShortcut.assemblyName,
                  b: (str) => <span className="font-regular">{str}</span>,
                },
              )}
            </span>
          </div>

          <div className="f-col w-100 f2-center gap-2 pt-2">
            <div className="f-col w-100 gap-2">
              <Autocomplete
                variant="outlined"
                placeholder={intl.formatMessage(
                  actionMessages.selectFolderToSave,
                )}
                label={intl.formatMessage(actionMessages.selectFolderToSave)}
                // @ts-ignore
                getOptionLabel={(option) => option.name}
                options={tabFolderList.map((option) => {
                  return option;
                })}
                value={_directory}
                onChange={(event, newValue) => {
                  if (newValue && newValue.id) {
                    setDirectory(newValue);
                  } else {
                    setDirectory(null);
                  }
                }}
                renderOption={(props: any, option: any) => {
                  if (!option.permissions.assemblyCreate) {
                    return;
                  }
                  return (
                    <li
                      {...props}
                      key={`option-${option.id}`}
                      className={`f-row f2-center gap-2 px-2 py-1 li-autocomplete`}
                    >
                      <div className="max-lines-1">{option.name}</div>
                    </li>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    let type = null;
    switch (assemblyToCreateShortcut.assemblyType) {
      case "SingleUseAssembly":
        type = "SUA";
        break;
      case "UnitOperation":
        type = "UO";
        break;
      case "SingleUseAssemblyReference":
        type = "Reference";
        break;
      default:
        type = "SUA";
        break;
    }
    return (
      <div className="f-row f1-center f2-center gap-2">
        <Icon name={"external-link"} size={18} />

        {intl.formatMessage(
          {
            id: "SUConfigView.CreateShortcut.message",
            defaultMessage: "Create a shortcut for {entity}",
          },
          {
            entity: type,
          },
        )}
      </div>
    );
  };

  const render = () => {
    return (
      <Modal
        size={"modal-md"}
        title={renderTitle()}
        cancel={onCloseModal}
        footer={renderFooter()}
      >
        {renderBody()}
      </Modal>
    );
  };

  return render();
};

export default CreateShortcutModal;
