// import "./wdyr"; // <--- first import

import React from 'react';
import ReactDOM from 'react-dom';
import 'hakobio-react-ui/dist/index.css';
import './index.css';
import App from './components/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.min.css';

import { store } from './store';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';

class CalmResizeObserver extends ResizeObserver {
  constructor(callback: ResizeObserverCallback) {
    super((entries, observer) => {
      requestAnimationFrame(() => {
        callback(entries, observer);
      });
    });
  }
}
global.ResizeObserver = CalmResizeObserver;

if (process.env.REACT_APP_ENVIRONMENT !== 'localhost') {
  Sentry.init({
    dsn: 'https://475c1cdadf1144cdb63a5a3523150df0@o192009.ingest.sentry.io/4505521540628480',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          /^https:\/\/hakobio-singleuseconfigurator-staging\.azurewebsites\.net/,
          /^https:\/\/hakobio-singleuseconfigurator-preview\.azurewebsites\.net/,
          /^https:\/\/hakobio-singleuseconfigurator-api\.azurewebsites\.net/
        ]
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    autoSessionTracking: true,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport)
  });
}

//TODO : move to hakobio-react-ui
const primaryColor = getComputedStyle(document.documentElement)
  .getPropertyValue('--primaryColor')
  .trim();
const redColor = getComputedStyle(document.documentElement).getPropertyValue('--red').trim();

const theme = createTheme({
  typography: {
    fontFamily: [
      'var(--light)',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      main: primaryColor || '#C0C0C0'
    },
    error: {
      main: redColor || 'var(--red)'
    }
  }
});
//-------------

ReactDOM.render(
  // @ts-ignore
  <React.StrictMode>
    <Provider store={store}>
      {/* @ts-ignore */}
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
