import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import _ from 'lodash';
import { ListTab } from '../../../constants/ListTab';
import { useAppSelector } from '../../../store';
/**
 * @component
 * @param {Object} props
 * @param {string} props.type string of window, grid or list,
 * @param {number} props.rowHeight height of one row (for grid type),
 * @param {array} props.itemData  array of item
 * @param {number} props.itemSize  size of an item (for list type)
 * @param {{XL: 3,L:2,M:2,S:1, XS: 2}} props.responsiveColumns  responsive number of columm
 * @param {number} props.adjustColumnWidth number to adjust width of a column to perfectly fit the parent container
 * @param {number} props.innerElementType
 * @param {Object} props.children JSX element of the grid or list
 *
 */
const InfiniteScrollWindow = (props) => {
  const [cols, setCols] = useState({ XL: 3, L: 2, M: 2, S: 1, XS: 1 });
  const [isList, setIsList] = useState(false);
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);

  const XS = useMediaQuery('(max-width:480px)');
  const S = useMediaQuery('(min-width:576px)');
  const M = useMediaQuery('(min-width:768px)');
  const L = useMediaQuery('(min-width:992px)');
  const XL = useMediaQuery('(min-width:1200px)');

  useEffect(() => {
    const isList = props.type === 'list';
    setIsList(isList);
    if (props.responsiveColumns) {
      setCols(props.responsiveColumns);
    }
  }, []);

  useEffect(() => {
    if (listTab === ListTab.UnitOperation) {
      setCols({ XL: 2, L: 2, M: 1, S: 1, XS: 1 });
    } else {
      setCols({ XL: 3, L: 2, M: 1, S: 1, XS: 1 });
    }
  }, [listTab]);

  const renderType = ({ height, width }) => {
    let array2D;
    let columnWidth = 0;
    let columnNumber = 3;
    if (isList) {
    } else {
      if (XL) {
        columnNumber = cols.XL;
      } else if (L) {
        columnNumber = cols.L;
      } else if (M) {
        columnNumber = cols.M;
      } else if (S) {
        columnNumber = cols.S;
      } else if (XS) {
        columnNumber = cols.XS;
      } else {
        columnNumber = cols.S;
      }
      array2D = _.chunk(props.itemData, columnNumber);
      columnWidth = width / columnNumber;
    }
    return isList ? (
      <List
        id="testid"
        itemSize={60}
        height={height}
        innerElementType={props.innerElementType ? props.innerElementType : null}
        width={width}
        itemCount={props.itemData.length}
        /* itemCount={props.itemCount} */
      >
        {props.children}
      </List>
    ) : (
      <Grid
        id="testid"
        innerElementType={props.innerElementType ? props.innerElementType : null}
        columnCount={columnNumber}
        columnWidth={columnWidth - props.adjustColumnWidth}
        rowCount={array2D.length}
        rowHeight={props.rowHeight}
        height={height}
        width={width}
        itemData={array2D}>
        {props.children}
      </Grid>
    );
  };
  return (
    <>
      <AutoSizer>{renderType}</AutoSizer>
    </>
  );
};
export default InfiniteScrollWindow;
