export enum GenericStatus {
  Draft = 'draft',
  Proposal = 'proposal',
  Detailed = 'detailed',
  UpdateOngoing = 'updateOngoing',
  Freeze = 'freeze',
  Obsolete = 'obsolete'
}

export const genericStatusColors = (ref: string) => {
  switch (ref) {
    case GenericStatus.Draft:
      return 'var(--anthracite)';
    case GenericStatus.Proposal:
      return 'var(--dark-orange)';
    case GenericStatus.Detailed:
      return 'var(--dark-orange)';
    case GenericStatus.Freeze:
      return 'var(--dark-green)';
    case GenericStatus.Obsolete:
      return 'var(--dark-red)';
    case GenericStatus.UpdateOngoing:
      return 'var(--dark-blue)';
    default:
      return 'var(--primaryColor)';
  }
};

export const genericStatusBackgroundColors = (ref: string) => {
  switch (ref) {
    case GenericStatus.Draft:
      return 'var(--light-grey)';
    case GenericStatus.Proposal:
      return 'var(--light-orange)';
    case GenericStatus.Detailed:
      return 'var(--light-orange)';
    case GenericStatus.Freeze:
      return 'var(--light-green)';
    case GenericStatus.Obsolete:
      return 'var(--light-red)';
    case GenericStatus.UpdateOngoing:
      return 'var(--light-blue)';
    default:
      return 'var(--primaryColorBcg)';
  }
};
