import PIDLibrary from './Library/PIDLibrary';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PanelHeader } from 'hakobio-react-ui';
import AdvancedSearchComponent from '../../../Shared/AdvancedSearch/AdvancedSearchComponent';

interface Props {
  selectedComponent: any;
}

const UnitOperationEquipmentPIDPanel = ({ selectedComponent }: Props) => {
  const [_search, setSearch] = useState<any>('');
  const [_SUAList, setSUAList] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (selectedComponent) {
    const ancillaryFunctions = selectedComponent?.data?.ancillaryFunctions?.[0]
    ?.complexParameters
    ? selectedComponent?.data?.ancillaryFunctions?.[0]?.complexParameters
    : [];
    
      setSUAList(true);
      //@ts-ignore
      setSearch({
        name: '',
        createdBy: '',
        modifiedBy: '',
        functions: [selectedComponent.data.type],
        ancillaryFunctions: selectedComponent.data.ancillaryFunctions ? ancillaryFunctions:[],
        containers: [],
        connectors: [],
        tubingMaterials: [],
        tubingInnerDiameters: [],
        tubingLength: null,
        poreSize: null,
        filtrationSurface: selectedComponent.data.componentSurface
          ? selectedComponent.data.componentSurface
          : 0,
        volumes: selectedComponent.data.componentVolume
          ? [selectedComponent.data.componentVolume]
          : [],
        lines: [0, 0],
        bags2D: [0, 0],
        sensorTypes: selectedComponent.data.componentType
          ? [selectedComponent.data.componentType]
          : [],
        pumpingTypes: []
      });
    } else {
      setSUAList(false);
    }
  }, [selectedComponent]);

  const renderSearch = () => {
    return (
      <div className="mx-2 my-1">
        <AdvancedSearchComponent
          setSearch={(s) => setSearch(s)}
          selectedComponent={selectedComponent}
        />
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <PanelHeader>SUA Library</PanelHeader>
        {_SUAList && renderSearch()}
        <PIDLibrary
          search={_search}
          selectedComponent={selectedComponent}
          setSUAList={setSUAList}
          resetSearch={() => setSearch('')}
          setSearch={(s) => setSearch(s)}
        />
      </>
    );
  };

  return render();
};

export default UnitOperationEquipmentPIDPanel;
