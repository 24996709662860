import { DoubleChip, Select, TextInput } from 'hakobio-react-ui';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useAssemblyEditorAction } from '../../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { angle } from '../../../../../utilities';
import { useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import { assemblyEditorSliceActions } from '../../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { generalMessages } from '../../../../../lang/messages';
import Fraction from 'fraction.js';

interface Props {
  displayEdition: any;
  keyProperty: string;
  property: any;
  selectedComponent: any;
  setIsValid: any;
}

const NodePropertyNumber = ({
  displayEdition,
  keyProperty,
  property,
  selectedComponent,
  setIsValid
}: Props) => {
  // const Fraction = require('fraction.js');
  const { onComponentPropertyChanged } = useAssemblyEditorAction();
  const [_rangeMin, setRangeMin] = useState(null);
  const [_rangeMax, setRangeMax] = useState(null);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (property.range) {
      if (selectedComponent.data[keyProperty]) {
        setRangeMin(selectedComponent.data[keyProperty][0]);
        setRangeMax(selectedComponent.data[keyProperty][1]);
      }
    }
  }, []);

  useEffect(() => {
    setIsValid(true);
  }, [selectedComponent]);

  const onPropertyChanged = (selectComponent, keyProperty, inputValue) => {
    onComponentPropertyChanged(selectComponent, keyProperty, inputValue);
  };

  const onPositionPropertyChanged = (e: any, value: any) => {
    let foo = 0;
    if (Array.isArray(value)) {
      foo = value[0];
    } else {
      foo = value;
    }
    const pos = foo / 100;
    const edgeRef = document.getElementById(selectedComponent.viewer2D?.source);
    if (edgeRef !== null) {
      // @ts-ignore
      const edgeLength = edgeRef.getTotalLength();
      // @ts-ignore
      let edgeLoc = edgeRef.getPointAtLength(pos * edgeLength);
      // @ts-ignore
      let edgeLoc2 = edgeRef.getPointAtLength((pos + 0.02) * edgeLength);

      let rotation = angle(edgeLoc.x, edgeLoc.y, edgeLoc2.x, edgeLoc2.y);

      dispatch(
        assemblyEditorSliceActions.updateComponent({
          // @ts-ignore
          ...selectedComponent,
          viewer2D: {
            ...selectedComponent.viewer2D,
            x: edgeLoc.x - 15 + 0.00000001,
            y: edgeLoc.y - 15,
            position: pos,
            rotate: rotation - 90
          }
        })
      );
    }
  };

  const renderTextField = () => {
    return (
      <div className="f-row gap-2 f2-center w-100">
        <TextInput
          focused={
            selectedComponent?.data?.[keyProperty] && selectedComponent?.data?.[keyProperty] !== ''
          }
          className="w-100"
          label={property.name}
          value={selectedComponent?.data?.[keyProperty]}
          onChange={(event: any) =>
            onPropertyChanged(selectedComponent, keyProperty, event.target.value)
          }
          endAdornment={property.units}
        />
      </div>
    );
  };

  const toInch = (unit: any) => {
    return Number(unit) * 0.39370078740157;
  };

  const getWall = () => {
    if (
      selectedComponent?.data?.componentOuterDiameter &&
      selectedComponent?.data?.componentInnerDiameter
    ) {
      const outer = Number(new Fraction(selectedComponent?.data?.componentOuterDiameter));
      const inner = Number(new Fraction(selectedComponent?.data?.componentInnerDiameter));
      return new Fraction((outer - inner) / 2).toFraction();
    } else {
      return intl.formatMessage(generalMessages.noValue);
    }
  };

  const renderSelect = () => {
    const menuItemList = property.enum.map((e: any) => {
      return (
        <MenuItem key={e} value={e}>
          <div className="f-row f2-center gap-2">
            <span className="capitalize">{e}</span>
            {property.name === 'length' ? (
              `${property.units} ${toInch(e).toFixed(2)} Inch` || ''
            ) : property.name === 'combined porosity' ? (
              <span className="font-light">&mu;m</span>
            ) : (
              property.units || ''
            )}
          </div>
        </MenuItem>
      );
    });

    menuItemList.push(
      <MenuItem key={'No value'} value={''} className="capitalize">
        <div className="f-row f2-center gap-2" style={{ color: 'var(--anthracite)' }}>
          {intl.formatMessage(generalMessages.noValue)}
        </div>
      </MenuItem>
    );

    return (
      <Select
        inputSize="extraSmall"
        label={<span className="capitalize">{property.name}</span>}
        value={selectedComponent?.data?.[keyProperty] || property['default'] || ''}
        onChange={(event: any) => {
          onPropertyChanged(selectedComponent, keyProperty, event.target.value);
        }}>
        {menuItemList}
      </Select>
    );
  };

  const renderSlider = () => {
    if (keyProperty === 'position') {
      return (
        <TextInput
          className="w-100"
          label={property.name}
          value={Math.round(selectedComponent.viewer2D[keyProperty] * 100)}
          onChange={(event: any) => onPositionPropertyChanged(event, event.target.value)}
          endAdornment={property.units}
          min={Number(property.slider[0])}
          max={Number(property.slider[1])}
          step={Number(property.step) || 1}
          type={'number'}
        />
      );
    }
    return (
      <TextInput
        className="w-100"
        label={
          keyProperty === 'componentPortSize' ? (
            <span className="font-light">
              <span className="capitalize">{property.name}</span> &#40;&mu;m&#41;
            </span>
          ) : (
            <span className="capitalize">{property.name}</span>
          )
        }
        value={selectedComponent?.data?.[keyProperty]}
        onChange={(event: any) => {
          onPropertyChanged(selectedComponent, keyProperty, event.target.value);
        }}
        helperText={intl.formatMessage(
          {
            id: 'NodePropertyNumber.ThisValueNeedsToBeBetween',
            defaultMessage: `This value needs to be between {firstValue} and {secondValue}`
          },
          {
            firstValue: property.slider[0],
            secondValue: property.slider[1]
          }
        )}
        error={!isValidValue(selectedComponent?.data?.[keyProperty])}
        endAdornment={property.units}
        min={Number(property.slider[0])}
        max={Number(property.slider[1])}
        step={Number(property.step) || 1}
        type={'number'}
        disabled={
          (keyProperty === 'componentPortSize' &&
            selectedComponent?.data?.['componentPortSizeMwco']) ||
          (keyProperty === 'componentPortSizeMwco' &&
            selectedComponent?.data?.['componentPortSize'])
            ? true
            : false
        }
      />
    );
  };

  const isValidValue = (inputValue: number) => {
    switch (keyProperty) {
      case 'componentVolume':
        setIsValid(
          (inputValue >= property.slider[0] && inputValue <= property.slider[1]) ||
            !selectedComponent.data.componentVolume
        );
        break;
      case 'componentSurface':
        setIsValid(
          (inputValue >= property.slider[0] && inputValue <= property.slider[1]) ||
            !selectedComponent.data.componentSurface
        );
        break;
    }

    return (
      (inputValue >= property.slider[0] && inputValue <= property.slider[1]) ||
      // @ts-ignore
      inputValue === '' ||
      inputValue === undefined
    );
  };

  const isValidRangeValue = (edge: string, inputValue: number) => {
    let insideValidValues = inputValue >= property.range[0] && inputValue <= property.range[1];
    let okMinMax = edge === 'min' ? inputValue <= _rangeMax : inputValue >= _rangeMin;
    return insideValidValues && okMinMax;
  };

  const changeCommittedFlowRange = (e: any, edge: string, inputValue: number) => {
    if (!isNaN(inputValue)) {
      switch (edge) {
        case 'min':
          setRangeMin(inputValue);
          const newMin = [inputValue, _rangeMax];
          isValidRangeValue('min', inputValue) &&
            onComponentPropertyChanged(selectedComponent, keyProperty, newMin);
          break;
        case 'max':
          setRangeMax(inputValue);
          const newMax = [_rangeMin, inputValue];
          isValidRangeValue('max', inputValue) &&
            onComponentPropertyChanged(selectedComponent, keyProperty, newMax);
          break;
        default:
          break;
      }
    }
  };

  const renderRange = () => {
    return (
      <div className="w-100">
        <div className="w-100">
          <TextInput
            label={'Minimum ' + property.name}
            inputSize={'small'}
            value={_rangeMin}
            onChange={(e: any) => changeCommittedFlowRange(e, 'min', Number(e.target.value))}
            error={!isValidRangeValue('min', _rangeMin)}
            helperText={`This value must not be lower than ${property.range[0]}`}
            min={property.range[0]}
            max={property.range[1]}
            endAdornment={property.units}
            type={'number'}
          />
          <TextInput
            label={'Maximum ' + property.name}
            inputSize={'small'}
            value={_rangeMax}
            onChange={(e: any) => changeCommittedFlowRange(e, 'max', Number(e.target.value))}
            // step={5}
            error={!isValidRangeValue('max', _rangeMax)}
            helperText={`This value must not be higher than ${property.range[1]}`}
            min={property.range[0]}
            max={property.range[1]}
            endAdornment={property.units}
            type={'number'}
          />
        </div>
      </div>
    );
  };

  const renderChip = () => {
    if (keyProperty === 'componentWall') {
      const value = getWall();
      return (
        <DoubleChip
          className="max-1-lines-visible"
          key={selectedComponent?.data?.[keyProperty]}
          label1={<span className="capitalize">{property.name}</span>}
          label2={
            value && value !== intl.formatMessage(generalMessages.noValue) ? (
              value + ' ' + (property.units || '')
            ) : (
              <span className="frozen-grey">{intl.formatMessage(generalMessages.noValue)}</span>
            )
          }
        />
      );
    }

    if (keyProperty === 'position') {
      return (
        <DoubleChip
          className="max-1-lines-visible"
          key={selectedComponent?.viewer2D[keyProperty]}
          label1={<span className="capitalize">{property.name}</span>}
          label2={
            selectedComponent?.viewer2D[keyProperty] ? (
              selectedComponent?.viewer2D[keyProperty] * 100 + ' ' + (property.units || '')
            ) : (
              <span className="frozen-grey">{intl.formatMessage(generalMessages.noValue)}</span>
            )
          }
        />
      );
    }

    if (keyProperty === 'componentLength') {
      return (
        <DoubleChip
          className="max-1-lines-visible"
          key={selectedComponent?.data?.[keyProperty]}
          label1={<span className="capitalize">{property.name}</span>}
          label2={
            selectedComponent?.data?.[keyProperty] ? (
              selectedComponent?.data?.[keyProperty] +
              ' ' +
              (property.units +
                ' | ' +
                toInch(selectedComponent?.data?.[keyProperty]).toFixed(2) +
                ' ' +
                'inch' || '')
            ) : (
              <span className="frozen-grey">{intl.formatMessage(generalMessages.noValue)}</span>
            )
          }
        />
      );
    }

    const value = selectedComponent?.data?.[keyProperty];

    return (
      <DoubleChip
        key={selectedComponent?.data?.[keyProperty]}
        label1={
          <span
            className={`capitalize ${
              (keyProperty === 'componentPortSize' &&
                selectedComponent?.data?.['componentPortSizeMwco']) ||
              (keyProperty === 'componentPortSizeMwco' &&
                selectedComponent?.data?.['componentPortSize'])
                ? 'frozen-grey'
                : undefined
            }`}>
            {keyProperty === 'componentPortSize' ? (
              <span>
                {property.name} <span className="lowercase font-light">&#40;&mu;m&#41;</span>
              </span>
            ) : (
              property.name
            )}
          </span>
        }
        label2={
          value ? (
            <span>
              {value} <span className="lowercase font-light">{property.units}</span>
            </span>
          ) : (
            <span className="frozen-grey">{intl.formatMessage(generalMessages.noValue)}</span>
          )
        }
      />
    );
  };

  const render = () => {
    if (!displayEdition) {
      return renderChip();
    }
    if (property.readOnly) {
      return renderChip();
    }
    if (property.enum) {
      return property.name !== 'wall' ? renderSelect() : null;
    }
    if (property.slider) {
      return renderSlider();
    }
    if (property.range) {
      return renderRange();
    }
    return renderTextField();
  };

  return render();
};

export default NodePropertyNumber;
