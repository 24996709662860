import { useEffect, useState } from 'react';
import { MenuItem, Paper } from '@mui/material';
import { Icon, DoubleChip, Select, ShortHeader, SmallButton, ThinChip } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import { actionMessages, versioningMessages } from '../../lang/messages';
import { useAppDispatch, useAppSelector } from '../../store';
import { historySliceActions } from '../../store/features/history/historySlice';
import { useAssemblyEditorAction } from '../../store/features/assemblyEditor/useAssemblyEditorAction';

interface HistoryHeaderProps {}

const HistoryHeader: React.FC<HistoryHeaderProps> = () => {
  const dispatch = useAppDispatch();
  const { setAssembly } = useAssemblyEditorAction();
  const viewingAssembly = useAppSelector((state) => state.historySlice.viewingAssembly);
  const comparingAssembly = useAppSelector((state) => state.historySlice.comparingAssembly);
  const historyList = useAppSelector((state) => state.historySlice.historyList);

  const [_isShowingDropDownVersion, setIsShowingDropDownVersion] = useState<boolean>(false);
  const [_viewingVersion, setViewingVersion] = useState<string>('');
  const [_comparingVersion, setComparingVersion] = useState<string>('');
  const intl = useIntl();

  const assemblyName = viewingAssembly?.general?.name || '';

  useEffect(() => {
    if (viewingAssembly) {
      setViewingVersion((viewingAssembly?.general?.version + 1).toString());
    }
  }, [viewingAssembly]);

  useEffect(() => {
    if (comparingAssembly) {
      setComparingVersion((comparingAssembly?.general?.version + 1).toString());
    }
  }, [comparingAssembly]);

  useEffect(() => {
    if (historyList) {
      const lastVersion = historyList?.slice(-1);
      dispatch(historySliceActions.setComparingAssembly(lastVersion[0]));
    }
  }, [historyList]);

  const handleClickOK = () => {
    const newVersionToShow = historyList?.find(
      (a) => a.general.version === Number(_viewingVersion) - 1
    );
    dispatch(historySliceActions.setViewingAssembly(newVersionToShow));
    const newVersionToCompareWith = historyList?.find(
      (a) => a.general.version === Number(_comparingVersion) - 1
    );
    dispatch(historySliceActions.setComparingAssembly(newVersionToCompareWith));
    dispatch(historySliceActions.setDifferencesCount(0));
    setAssembly(newVersionToShow.general, newVersionToShow.components);
    setIsShowingDropDownVersion(false);
  };

  const renderVersionDropDown = () => {
    if (historyList && Object.entries(historyList).length > 0) {
      return (
        <Paper
          style={{
            minWidth: '300px',
            top: 42,
            left: 10,
            backgroundColor: 'rgba(255,255,255.9)'
          }}
          className="p-2 f-col f2-stretch absolute">
          <div className="f-row f2-center gap-2 px-2">
            <div className="mt-2" style={{ minWidth: '50%' }}>
              {intl.formatMessage(versioningMessages.view)}
            </div>
            <Select
              variant="standard"
              inputSize="medium"
              className=""
              value={_viewingVersion || 'N/C'}
              onChange={(e) => setViewingVersion(e.target.value)}>
              {historyList?.map((e: any, index: number) => {
                const version = (e.general.version + 1).toString();
                return (
                  <MenuItem
                    key={'viewing-' + e.general.id + index}
                    value={version}
                    className="pt-1">
                    <ThinChip
                      auto
                      fontSize="smallText"
                      color={'var(--primaryColor)'}
                      backgroundColor={'var(--primaryColorBcg)'}
                      label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
                        version === historyList?.length.toString()
                          ? version + ' ' + intl.formatMessage(versioningMessages.lastParentheses)
                          : version
                      }`}
                      className="mb-0 ml-2"
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="f-row f2-center gap-2 px-2">
            <div className="mt-2" style={{ minWidth: '50%' }}>
              {intl.formatMessage(versioningMessages.compareWith)}
            </div>
            <Select
              variant="standard"
              inputSize="medium"
              value={_comparingVersion || 'N/C'}
              onChange={(e) => setComparingVersion(e.target.value)}>
              {historyList?.map((e: any, index: number) => {
                const version = (e.general.version + 1).toString();
                return (
                  <MenuItem
                    key={'comparing-' + e.general.id + index}
                    value={version}
                    className="pt-1">
                    <ThinChip
                      auto
                      fontSize="smallText"
                      label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
                        version === historyList?.length.toString()
                          ? version + ' ' + intl.formatMessage(versioningMessages.lastParentheses)
                          : version
                      }`}
                      className="mb-0 ml-2"
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <SmallButton onClick={handleClickOK} className="mt-2" style={{ alignSelf: 'flex-end' }}>
            {intl.formatMessage(actionMessages.OK)}
          </SmallButton>
        </Paper>
      );
    } else {
      return null;
    }
  };

  return (
    <ShortHeader className="relative" style={{ zIndex: 101 }} noDeleteCross>
      <div className="f-row f2-center gap-2 px-2 border-right">
        <Icon name="history" color={'var(--primaryColor)'} />
        {intl.formatMessage(versioningMessages.history)}
        <Icon
          onClick={() => setIsShowingDropDownVersion(!_isShowingDropDownVersion)}
          pointer
          name={_isShowingDropDownVersion ? 'arrow-up' : 'arrow-down'}
        />
        {_isShowingDropDownVersion && renderVersionDropDown()}
      </div>

      <div className="f-row gap-2 px-2">
        <DoubleChip
          auto
          center
          fontSize="text"
          label1={intl.formatMessage(versioningMessages.viewing)}
          label2={`${intl.formatMessage(versioningMessages.versionAbr)} ${_viewingVersion}`}
          title1={intl.formatMessage(versioningMessages.viewing)}
          title2={intl.formatMessage(versioningMessages.version)}
          color={'var(--primaryColor)'}
          backgroundColor={'var(--primaryColorBcg)'}
        />

        <DoubleChip
          auto
          center
          fontSize="text"
          label1={intl.formatMessage(versioningMessages.comparingWith)}
          label2={`${intl.formatMessage(versioningMessages.versionAbr)} ${
            _comparingVersion === historyList?.length.toString()
              ? _comparingVersion + ' ' + intl.formatMessage(versioningMessages.lastParentheses)
              : _comparingVersion
          }`}
          title1={intl.formatMessage(versioningMessages.comparingWith)}
          title2={intl.formatMessage(versioningMessages.version)}
          backgroundColor="var(--light-grey)"
        />
      </div>
      <div className="px-2 font-regular border-left max-1-lines-visible capitalize">
        {assemblyName}
      </div>
    </ShortHeader>
  );
};

export default HistoryHeader;
