import { RuleEdgeComponentOnEdge } from '../RuleEdgeComponentOnEdge';
import Fraction from 'fraction.js';
import { v4 as uuidv4 } from 'uuid';

export class RuleTubingComponentOnEdgeDiameter extends RuleEdgeComponentOnEdge {
  schemaComponentOnEdgeList = ['pinchClamp', 'pinchValve'];
  schemaTubeList = ['tubing'];
  editorList = ['SUE', 'UOE_PID'];

  checkPrerequisite = () => {
    const isTube = this.schemaTubeList.includes(this.edge.data.type);
    const isOnEdge = this.componentOnEdgeList.filter((component) =>
      this.schemaComponentOnEdgeList.includes(component.data.type)
    ).length;
    if (isTube && isOnEdge) {
      return true;
    }
    return false;
  };

  checkRule = () => {
    const edgeOuterDiameter = new Fraction(this.edge.data.componentOuterDiameter || 0).valueOf();
    this.componentOnEdgeList
      .filter((component) => this.schemaComponentOnEdgeList.includes(component.data.type))
      .forEach((component) => {
        if (
          !component.data.componentMinOuterDiameter ||
          !component.data.componentMaxOuterDiameter
        ) {
          return;
        }
        const componentMinOuterDiameter = new Fraction(
          component.data.componentMinOuterDiameter
        ).valueOf();
        const componentMaxOuterDiameter = new Fraction(
          component.data.componentMaxOuterDiameter
        ).valueOf();
        if (
          !(
            edgeOuterDiameter >= componentMinOuterDiameter &&
            edgeOuterDiameter <= componentMaxOuterDiameter
          )
        ) {
          this.errorList.push({
            id: uuidv4(),
            name: 'Incompatible tube diameter',
            errors:
              'The diameter of tube is ' +
              this.edge.data['componentInnerDiameter'] +
              ' and the accepted diameter of the pinch clamp is between ' +
              component.data['componentOuterDiameterMin'] +
              ' and ' +
              component.data['componentOuterDiameterMax'],
            componentAnchors: [
              // @ts-ignore
              { anchorId: this.componentSourceAnchor, componentId: this.componentSource.id },
              // @ts-ignore
              { anchorId: this.componentTargetAnchor, componentId: this.componentTarget.id }
            ]
          });
        }
      });
  };
}
