import _ from 'lodash';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { RulePlugTubingWeldable } from './definition/RuleComponentEdgeComponent/RulePlugTubingWeldable';
import { RuleComponentDiameterTubingDiameter } from './definition/RuleComponentEdgeComponent/RuleComponentDiameterTubingDiameter';
import { RuleAsepticConnectorTubingWeldable } from './definition/RuleComponentEdgeComponent/RuleAsepticConnectorTubingWeldable';
import { RuleTubingComponentOnEdgeDiameter } from './definition/RuleEdgeComponentOnEdge/RuleTubingComponentOnEdgeDiameter';

export const checkSUAEditor = (components: any) => {
  const componentsCopy = _.cloneDeep(components);
  const failedRules: any[] = [];

  componentsCopy
    .filter((component: any) => component.type === 'genericedge')
    .forEach((component: any) => {
      Array.prototype.push.apply(failedRules, new RuleTubingComponentOnEdgeDiameter(component, components).handle());
      Array.prototype.push.apply(failedRules, new RulePlugTubingWeldable(component, components).handle());
      Array.prototype.push.apply(failedRules, new RuleComponentDiameterTubingDiameter(component, components).handle());
      Array.prototype.push.apply(failedRules, new RuleAsepticConnectorTubingWeldable(component, components).handle());
    });
  return failedRules;
};