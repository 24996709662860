import { Icon, Title } from "hakobio-react-ui";

import DraggableSocketBadge from "./DraggableSocketBadge";
import { AnchorTypes } from "../../../../../constants/AnchorTypes";
import { IconTooltip } from "hakobio-react-ui";
import { FormattedMessage, useIntl } from "react-intl";
import { configMessages } from "../../../../../lang/messages";
import _ from "lodash";
import { portColors } from "../../../../../utilities/portsColors";
import { ViewMode } from "../../../../../constants/ViewMode";
import { useAppSelector } from "../../../../../store";

export function NodePropertyAnchorAdd({
  keyProperty,
  onClick,
  selectedComponent,
}: {
  keyProperty: any;
  onClick: any;
  selectedComponent: any;
}) {
  const intl = useIntl();
  // **********************
  const viewMode = useAppSelector(
    (state) => state.assemblyEditorSlice.viewMode,
  );
  const components = useAppSelector(
    (state) => state.assemblyEditorSlice.components,
  );
  let count = [];
  // get the components of a SUA if any
  let assemblyFunction = components.filter(
    (c: any) =>
      c.data?.assembly && selectedComponent.id === c.data?.assembly?.component,
  );
  if (assemblyFunction.length > 0) {
    // push the anchors of the selected function in order to compare their number with the ones available on the linked SUA
    const availableAnchors = selectedComponent.data.anchors.map(
      (a) => a.data.componentLink === null,
    );
    count.push(availableAnchors.length);
    // get the types generic in one array and the edges in the other
    let [edgesAssembly, genericAssembly] = _.partition(
      assemblyFunction,
      function (c) {
        return c.type === "genericedge" || c.type === "functionedge";
      },
    );

    // let edgesAssembly = assemblyFunction.filter(
    //   (c: any) => c.type === 'genericedge' || c.type === 'functionedge'
    // );
    // let genericAssembly = assemblyFunction.filter((c: any) => c.type === 'generic');

    genericAssembly.forEach((c: any) => {
      checkAvailableAnchors(c.data.anchors, edgesAssembly);
      checkAvailableAnchors(c.data.instrumentationPorts, edgesAssembly);
      checkAvailableAnchors(c.data.samplingPorts, edgesAssembly);
    });
  }

  function checkAvailableAnchors(anchors: any[], edgesAssembly: any[]) {
    if (anchors) {
      anchors.forEach((anchor: any) => {
        // check if the anchor is used as a source, a target or is already linked to another function
        let isAnchorUsed = edgesAssembly.find(
          (e: any) =>
            e.sourceHandle === anchor.id || e.targetHandle === anchor.id,
          // ||
          // anchor.data.componentLink !== null
        );
        if (!isAnchorUsed) {
          count.push(anchor);
        }
      });
    }
  }
  // *****************
  // const dispatch = useAppDispatch();
  // const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  // const selectedComponents = useAppSelector(
  //   (state) => state.assemblyEditorSlice.selectedComponents
  // );
  // const selectedComponentId = selectedComponents && selectedComponents[0];
  // const selectedComponent = components.find((c: any) => c.id === selectedComponentId);
  /// const [_anchorType, setAnchorType] = useState(null);

  // const onAddAnchor = (type: string) => {
  //   if (!name) {
  //   }

  //   const el = components.find((e) => e.id === selectedComponent?.id);
  //   dispatch(
  //     assemblyEditorSliceActions.updateComponent({
  //       ...el,
  //       data: {
  //         ...el.data,
  //         [keyProperty]: [
  //           ...el.data?.[keyProperty],
  //           {
  //             type: type,
  //             position: 'left',
  //             id: Uuidv4(),
  //             style: {},
  //             data: { name }
  //           }
  //         ]
  //       }
  //     })
  //   );
  //   setName('');
  // };

  /// const [name, setName] = useState('');
  return (
    viewMode !== ViewMode.HistoryUO && (
      <>
        {selectedComponent.type === "generic" ? (
          <div
            className="f-row f1-between gap-2 mb-2"
            style={{ marginTop: "-.5rem" }}
          >
            <div className="f-col f-center gap-2 w-100">
              <Title className={"w-100"}>
                <div className={"f-row f2-center gap-1"}>
                  {intl.formatMessage(configMessages.connections)}
                  <IconTooltip
                    title="Drag and drop a point to connect components"
                    className="frozen-grey"
                    style={{ lineHeight: 0.5 }}
                  />
                </div>
              </Title>
              <div className="f-row f1-between w-100">
                <DraggableSocketBadge
                  title={intl.formatMessage(configMessages.addTransferPort)}
                  onClick={onClick}
                  size="sm"
                  orientation="bottom"
                  label={intl.formatMessage(configMessages.transferPort)}
                  content={<Icon name="liquid" color="white" size="1.25rem" />}
                  type="neutral"
                  anchorType={AnchorTypes.Transfer}
                  backgroundColor={portColors.Transfer}
                />
                <DraggableSocketBadge
                  title={intl.formatMessage(
                    configMessages.addInstrumentationPort,
                  )}
                  onClick={onclick}
                  size="sm"
                  orientation="bottom"
                  label={intl.formatMessage(configMessages.instrumentationPort)}
                  content={
                    <Icon name="instrumentation" size="1.25rem" color="white" />
                  }
                  type="neutral"
                  anchorType={AnchorTypes.InstrumentationPort}
                  backgroundColor={portColors.Instrumentation}
                />
                <DraggableSocketBadge
                  title={intl.formatMessage(configMessages.addSamplingPort)}
                  onClick={onClick}
                  size="sm"
                  orientation="bottom"
                  label={intl.formatMessage(configMessages.samplingPort)}
                  content={
                    <Icon name="sampling" size="1.25rem" color="white" />
                  }
                  type="neutral"
                  anchorType={AnchorTypes.SamplingPort}
                  backgroundColor={portColors.Sampling}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="f-row f1-between f2-center gap-2 mb-2 mt-2">
            <div className=" frozen-grey px-1">
              {assemblyFunction.length === 0 ||
              (count.length > 1 &&
                count.length - 1 > selectedComponent.data.anchors.length) ? (
                <FormattedMessage
                  id="NodePropertyAnchorAdd.DragDropTitle"
                  defaultMessage="Drag and drop a point to connect components"
                />
              ) : (
                <FormattedMessage
                  id="NodePropertyAnchorAdd.MaximumConnectionsReachedForTheLinkedSUA"
                  defaultMessage="Connections limit reached for the linked SUA"
                />
              )}
            </div>

            {assemblyFunction.length === 0 ||
            (count.length > 1 &&
              count.length - 1 > selectedComponent.data.anchors.length) ? (
              <DraggableSocketBadge
                title="Add an Anchor"
                onClick={onClick}
                size="sm"
                label={""}
                content={<Icon name="flows" color="white" size="1.25rem" />}
                type="neutral"
                anchorType={AnchorTypes.Transfer}
                backgroundColor={"#565656"}
                orientation="bottom-left"
              />
            ) : (
              <div
                style={{
                  backgroundColor: "#E9E9E9",
                  borderRadius: "30px",
                  padding: "10px",
                  height: "40px",
                }}
              >
                <Icon name="flows" color="white" size="1.25rem" />
              </div>
            )}
          </div>
        )}
      </>
    )
  );
}
