import { Icon } from 'hakobio-react-ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { actionMessages } from '../../../../lang/messages';

interface Props {
  libraryPanelState: string;
  buttonPanelState: string;
  isButtonExtended: boolean;
  setLibraryPanelState: Function;
  text: string;
  icon: string;
}

const VerticalTabsItem = ({
  libraryPanelState,
  buttonPanelState,
  isButtonExtended,
  setLibraryPanelState,
  text,
  icon
}: Props) => {
  const [_isHover, setHover] = useState(false);

  const intl = useIntl();

  return (
    <div
      className="plugin-button f-row f2-center gap-2 px-2"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        if (libraryPanelState && libraryPanelState === buttonPanelState) {
          setLibraryPanelState('');
        } else {
          setLibraryPanelState(buttonPanelState);
        }
      }}
      style={{
        width: isButtonExtended ? (libraryPanelState === '' ? 175 : 225) : 40,
        borderRight: 'solid 1px var(--dark-grey)',
        borderLeft:
          libraryPanelState === buttonPanelState ? '1px solid white' : 'solid 1px var(--dark-grey)',
        borderBottom:
          libraryPanelState === buttonPanelState ? 'solid 1px var(--dark-grey)' : 'none',
        borderTop: libraryPanelState === buttonPanelState ? 'solid 1px var(--dark-grey)' : 'none',
        color: libraryPanelState === buttonPanelState ? 'var(--primaryColor)' : null,
        backgroundColor: _isHover ? 'rgba(230,230,230,0.9)' : 'rgba(255,255,255,0.9)'
      }}>
      {libraryPanelState && libraryPanelState === buttonPanelState ? (
        <Icon name="delete" />
      ) : (
        <Icon name={icon} size={icon === 'label' || icon === 'file' ? 18 : 20} />
      )}

      {isButtonExtended && (
        <div>
          {libraryPanelState && libraryPanelState === buttonPanelState ? (
            <>
              {intl.formatMessage(actionMessages.close)}
              <span className="lowercase"> {text}</span>
            </>
          ) : (
            text
          )}
        </div>
      )}
    </div>
  );
};

export default VerticalTabsItem;
