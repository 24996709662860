import { DoubleButton, DoubleChip, Icon } from 'hakobio-react-ui';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { actionMessages } from '../../lang/messages';
import { config } from '../../services/config';
import { useAppDispatch, useAppSelector } from '../../store';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import { getTubeColor } from '../../services/tubing/getTubeColor';
import Color from 'color';
import { EditorMode } from '../../constants/EditorMode';
import { ViewMode } from '../../constants/ViewMode';

interface Props {
  keyName: string;
  node: any;
  constant: any;
  component: any;
  isShowingCustomLibrary: boolean;
  isCustomComponent: boolean;
  onDeleteCustomComponent: Function;
  selectTubing?: Function;
  selectedTubing?: string;
}

const ComponentItem = ({
  keyName,
  node,
  constant,
  component,
  isShowingCustomLibrary,
  isCustomComponent,
  onDeleteCustomComponent,
  selectTubing,
  selectedTubing
}: Props) => {
  const dispatch = useAppDispatch();

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);

  const [_isComponentNameHover, setComponentNameHover] = useState<boolean>(false);
  const [_isImage, setIsImage] = useState(null);
  const [_schema, setSchema] = useState(null);

  const history = useHistory();

  const intl = useIntl();

  useEffect(() => {
    // hasImageBlob();
  }, [component]);

  useEffect(() => {
    getSchema();
  }, []);

  const getSchema = async () => {
    const schema = await constant.node.schema();
    setSchema(schema);
  };

  const attributes =
    _schema && component
      ? Object.entries(component.data).filter(
          ([key, value]: any) =>
            key !== 'name' &&
            key !== 'anchors' &&
            key !== 'instrumentationPorts' &&
            key !== 'samplingPorts' &&
            key !== 'type' &&
            key !== 'customName'
        )
      : [];

  const renderAttributs = () => {
    const typeAttribute = attributes.find((a) => a[0] === 'componentType');
    const otherAttributes = attributes.filter((a) => a[0] !== 'componentType');
    return (
      <div className="f-row gap-3 f-wrap p-2">
        {attributes.length ? (
          <>
            {typeAttribute && (
              <DoubleChip
                className="capitalize"
                color={getTubeColor(typeAttribute[1])}
                backgroundColor={
                  _isComponentNameHover
                    ? 'white'
                    : Color(getTubeColor(typeAttribute[1])).alpha(0.1).toString()
                }
                center
                auto
                key={typeAttribute[0]}
                label1={_schema.properties[typeAttribute[0]]?.name}
                label2={<span className="font-regular ">{typeAttribute[1]}</span>}
              />
            )}
            {otherAttributes.length
              ? otherAttributes.map(([key, value]: any) => {
                  if (value !== '') {
                    return (
                      <DoubleChip
                        className="capitalize"
                        backgroundColor={_isComponentNameHover ? 'white' : undefined}
                        center
                        auto
                        key={key}
                        label1={_schema.properties[key]?.name}
                        label2={
                          <span className="font-regular ">
                            {typeof value === 'boolean' ? (value ? 'On' : 'Off') : value}
                          </span>
                        }
                      />
                    );
                  }
                })
              : null}
          </>
        ) : (
          <div className="frozen-grey">
            {intl.formatMessage({
              id: 'ComponentItem.NoAttribute',
              defaultMessage: 'No value defined for this component'
            })}
          </div>
        )}
      </div>
    );
  };

  const showCustomComponentLibrary = () => {
    dispatch(assemblyEditorSliceActions.setLibComponentName(keyName));
  };

  const onDeleteComponent = () => {
    onDeleteCustomComponent(component);
  };

  const openComponentEditor = () => {
    const path = '/editor/Component/' + component.name + '/' + component.id;
    history.push(path);
    dispatch(assemblyEditorSliceActions.setCustomComponentViewOnly(true));
    dispatch(assemblyEditorSliceActions.setViewMode(ViewMode.History));
  };

  function handleImageError() {
    setIsImage(false);
  }

  const selectCustomItem = () => {
    if (selectedTubing === component.id) {
      selectTubing(null);
      dispatch(assemblyEditorSliceActions.setSelectedCustomTubing(null));
    } else {
      selectTubing(component.id);
      dispatch(assemblyEditorSliceActions.setSelectedCustomTubing(component));
    }
  };

  const getColorClass = (type) => {
    switch (type) {
      case 'Standard':
        return 'pink';
      case 'High pressure':
        return 'blue';
      case 'Pump dedicated':
        return 'green';
      case 'Weldable':
        return 'purple';
      default:
        return '';
    }
  };
  const renderCustomComponentInLibrary = () => {
    return (
      <div
        key={node.name}
        className="p-2 hover-item f-row gap-2 f2-center "
        onClick={editorMode === EditorMode.SUA ? selectCustomItem : undefined}
        onMouseEnter={() => setComponentNameHover(true)}
        onMouseLeave={() => setComponentNameHover(false)}
        style={{
          backgroundColor: selectedTubing === component.id ? 'var(--light-grey)' : undefined
        }}>
        {editorMode === EditorMode.SUA &&
          (selectedTubing === component.id ? (
            <Icon name="chat" color="var(--primaryColor)" />
          ) : (
            <div
              className="border"
              style={{ height: 16, width: 16, borderRadius: 50, flexShrink: 0 }}
            />
          ))}
        <div className="f-full">
          <div className="max-1-lines-visible font-regular mb-2">
            {component.data.customName || component.data.name || node.name}
          </div>
          <div className="f-row f1-between gap-2">
            <div
              className={`mb-1 pl-2 pr-3 ${
                component.data.type === 'tubing'
                  ? 'tubing-' + getColorClass(component.data.componentType)
                  : ''
              }`}>
              {_isImage === false || component.data.type === 'tubing' ? (
                node.form(80)
              ) : (
                <img
                  alt="Component view"
                  style={{
                    objectFit: 'contain',
                    height: 80
                  }}
                  src={
                    config.BlobURL +
                    '/single-use-configurator/components/thumbnail/' +
                    component.id +
                    '.png'
                  }
                  onError={handleImageError}
                />
              )}
            </div>

            <div className="f-full">{renderAttributs()}</div>

            {editorMode !== EditorMode.SUA && (
              <div style={{ flexShrink: 0, minWidth: 200 }}>
                {_isComponentNameHover && (
                  <DoubleButton
                    width={180}
                    onClick={openComponentEditor}
                    popContent={
                      <>
                        <button onClick={onDeleteComponent} type="button">
                          <div className="f-row f2-center gap-2 red" style={{ minWidth: 100 }}>
                            <Icon pointer name="bin" />
                            {intl.formatMessage(actionMessages.delete)}
                          </div>
                        </button>
                      </>
                    }>
                    <div className="f-row f2-center gap-2">
                      <Icon pointer name="eye" />
                      {intl.formatMessage(actionMessages.seeComponent)}
                    </div>
                  </DoubleButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderNode = () => {
    return (
      <div
        key={node.name}
        className="f-col hover-item p-3 pointer relative"
        onClick={showCustomComponentLibrary}
        onMouseEnter={() => setComponentNameHover(true)}
        onMouseLeave={() => setComponentNameHover(false)}>
        <div
          className="f-center"
          style={{
            height: 120
          }}>
          {node.form(80)}
        </div>

        <div
          className="text-center font-regular f-row px-2 f1-center f2-center"
          style={{
            color: _isComponentNameHover ? 'var(--primaryColor)' : 'black'
          }}>
          <div className="max-1-lines-visible">{node.name}</div>
        </div>
      </div>
    );
  };

  const render = () => {
    if (isShowingCustomLibrary && isCustomComponent) {
      return renderCustomComponentInLibrary();
    } else {
      return renderNode();
    }
  };

  return render();
};

export default ComponentItem;
