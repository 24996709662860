import { ButtonFooter, Empty, Icon, TextInput, ThinChip } from 'hakobio-react-ui';
import { useEffect, useState } from 'react';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { EntityToDeleteTypes } from '../../../../views/enum/EntityToDeleteType';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { actionMessages, generalMessages } from '../../../../lang/messages';
import { useIntl } from 'react-intl';
import { useAssemblyEditorAction } from '../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { cloneDeep } from 'lodash';
import { ViewMode } from '../../../../constants/ViewMode';
import { Media } from '../../../../utilities/Media';
import { EditorMode } from '../../../../constants/EditorMode';

const LinksTab = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [_isEditingLink, setIsEditingLink] = useState(null);
  const [_isLinkHovered, setLinkHovered] = useState(null);
  const [_linkName, setLinkName] = useState('');
  const [_linkUrl, setLinkUrl] = useState('');
  const [_links, setLinks] = useState([]);
  const [_highLightFirst, setHighLightFirst] = useState(false);

  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);

  const { onGeneralEdited } = useAssemblyEditorAction();

  useEffect(() => {
    setLinks(general.medias);
  }, []);

  useEffect(() => {
    setLinks(general.medias);
  }, [general.medias]);

  const onDelete = (l: any) => {
    dispatch(
      assemblyEditorSliceActions.setShowDeleteConfirmModal({
        entityId: l.id,
        entityName: l.name,
        type: EntityToDeleteTypes.URL
      })
    );
  };

  const onLinkNameChange = (event: any, link: any) => {
    setLinkName(event.target.value);
    let links = cloneDeep(_links);
    let linkToChange = links.find((l) => l.id === link.id);
    linkToChange.name = event.target.value;
    setLinks(links);
  };

  const onUrlNameChange = (event: any, url: any) => {
    setLinkUrl(event.target.value);
    let links = cloneDeep(_links);
    let linkToChange = links.find((l) => l.id === url.id);
    linkToChange.url = event.target.value;
    setLinks(links);
  };

  const addUrl = () => {
    let newMedia = new Media();
    let mediaList = [..._links];
    mediaList.unshift(newMedia);
    setLinks(mediaList);
    setLinkName('');
    setLinkUrl('');
    setIsEditingLink(newMedia.id);
    setHighLightFirst(true);
  };

  const editIsFavorite = (event: any, index: number) => {
    const mediaList = cloneDeep(_links);
    let mediaSelected = mediaList[index];
    if (mediaSelected.isFavorite) {
      mediaSelected.isFavorite = false;
    } else {
      mediaList.forEach((media: any) => {
        media.isFavorite = false;
      });
      mediaSelected.isFavorite = true;
    }
    setLinks(mediaList);
  };

  const deleteLink = (media: any, index: number) => {
    if (media.name === '' && media.url === '') {
      const mediaList = cloneDeep(_links);
      mediaList.splice(index, 1);
      setLinks(mediaList);
    } else {
      let linkToDelete = general.medias[index];
      let newArr = general.medias.filter((l) => !l.id.includes(linkToDelete.id));
      onGeneralEdited('medias', newArr);
    }
    setHighLightFirst(false);
  };

  const isDisabled = _linkName === '' && _linkUrl === '';

  return (
    <>
      {_links.length ? (
        _links.map((l: any, index: number) => (
          <div
            className="pb-2"
            onMouseEnter={() => setLinkHovered(l.id)}
            onMouseLeave={() => setLinkHovered(null)}>
            {_isEditingLink !== l.id ? (
              <ThinChip
                className="container-action"
                backgroundColor={'var(--light-grey)'}
                label={
                  <div className="f-row f2-center gap-2 p-2">
                    <Icon
                      name="web-link"
                      color={_isLinkHovered === l.id ? 'var(--primaryColor)' : undefined}
                    />
                    <a
                      className="max-lines-1"
                      href={l.url}
                      rel="noreferrer"
                      target="_blank"
                      style={{
                        textDecoration: _isLinkHovered === l.id ? 'underline' : 'none',
                        color: _isLinkHovered === l.id ? 'var(--primaryColor)' : undefined
                      }}>
                      {l.name.length ? l.name : <span className="frozen-grey">N/C</span>}
                    </a>
                    {!_highLightFirst && (
                      <div className="f-row f-full f1-end gap-2 items-action-none">
                        <Icon
                          pointer
                          name="pencil"
                          onClick={() => {
                            setIsEditingLink(l.id);
                            setLinkName(l.name);
                            setLinkUrl(l.url);
                          }}
                        />
                        <Icon pointer name="bin" onClick={() => onDelete(l)} />
                      </div>
                    )}
                  </div>
                }
              />
            ) : (
              <div
                className="f-row f1-between f2-center gap-2 p-2 relative"
                style={{
                  backgroundColor: _highLightFirst && index === 0 ? 'var(--light-grey)' : undefined
                }}>
                {((_highLightFirst && index === 0) || isDisabled) && (
                  <Icon
                    title={intl.formatMessage(actionMessages.cancel)}
                    onClick={() => deleteLink(l, index)}
                    size={12}
                    name="delete"
                    className="absolute"
                    style={{ top: 8, right: 8 }}
                  />
                )}
                <div
                  className="f-shrink-0 f-center"
                  style={{ width: 20 }}
                  onClick={(e) => editIsFavorite(e, index)}>
                  {l.isFavorite ? (
                    <Icon name="star-full" color={'var(--primaryColor)'} size={18} />
                  ) : (
                    <Icon name="star" size={24} />
                  )}
                </div>
                <div className="f-full">
                  <TextInput
                    label="Link custom name"
                    onChange={(ev) => onLinkNameChange(ev, l)}
                    value={_linkName}
                  />
                  <TextInput
                    label="Url"
                    onChange={(ev) => onUrlNameChange(ev, l)}
                    value={_linkUrl}
                  />
                </div>

                <Icon
                  pointer
                  className="items-action-none"
                  color={isDisabled ? 'var(--frozen-grey)' : 'var(--dark-green)'}
                  hoverColor={isDisabled ? 'var(--frozen-grey)' : undefined}
                  name="chat"
                  onClick={
                    isDisabled
                      ? undefined
                      : () => {
                          onGeneralEdited('medias', _links);
                          setIsEditingLink(null);
                          setHighLightFirst(false);
                        }
                  }
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <Empty size="md" />
      )}
      {general?.permissions?.update &&
        viewMode === ViewMode.Editor &&
        editorMode !== EditorMode.UnitOperation && (
          <ButtonFooter arrow inverse onClick={addUrl}>
            <div className="f-row  f2-center gap-2">
              <Icon name="web-link" />
              {intl.formatMessage(generalMessages.addUrl)}
            </div>
          </ButtonFooter>
        )}
    </>
  );
};

export default LinksTab;
