import { Button, Icon, Modal, Switch } from 'hakobio-react-ui';
import { cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { buttonSaveStyle } from '../components/EditorPanel/style/styles';
import { ListTab } from '../constants/ListTab';
import { actionMessages, configMessages } from '../lang/messages';
import { useAppDispatch, useAppSelector } from '../store';
import { assemblyEditorSliceActions } from '../store/features/assemblyEditor/assemblyEditorSlice';
import { Uuidv4 } from '../utilities';

interface Props {
  shareAssembly: Function;
  assembly: any;
}

const ShareModal = (props: Props) => {
  const [_isPrivate, setIsPrivate] = useState(props.assembly.isPrivate || false);
  const [_inAssemblyList, setInAssemblyList] = useState(true);
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const allHeadersLibrary = useAppSelector((state) => state.assemblyEditorSlice.allHeadersLibrary);
  const dispatch = useAppDispatch();
  const [_assembly, setAssembly] = useState(null);

  const intl = useIntl();

  const inAssemblyList = () => {
    switch (listTab) {
      case ListTab.SUA:
        setInAssemblyList(true);
        break;
      case ListTab.UnitOperation:
        setInAssemblyList(false);
        break;
    }
  };

  useEffect(() => {
    inAssemblyList();
  }, []);

  const onCloseModal = () => {
    dispatch(assemblyEditorSliceActions.setShowShareModal(null));
  };

  const onSave = async () => {
    if (_isPrivate !== props.assembly.isPrivate) {
      const shareable = {
        id: Uuidv4(),
        shareableContentId: _assembly.id,
        ContentType: 'sud.assembly',
        isPrivate: _isPrivate,
        ShareWith: [],
        Except: []
      };
      props.shareAssembly(shareable);
      dispatch(assemblyEditorSliceActions.setShowShareModal(null));
    }
  };

  const renderFooter = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Button
          width={'150px'}
          color={'var(--primaryColor)'}
          style={buttonSaveStyle}
          onClick={onSave}
          // loading={_isPublic}
        >
          {intl.formatMessage(actionMessages.OK)}
        </Button>
      </div>
    );
  };

  const setPrivacy = () => {
    setIsPrivate(!_isPrivate);
    var cloneAssembly = cloneDeep(props.assembly);
    let statusAssembly = _assembly?.parameters?.find((p) => p.name === 'status')?.value;
    cloneAssembly = { ...cloneAssembly, isPrivate: !_isPrivate, status: statusAssembly };
    setAssembly(cloneAssembly);
  };

  const render = () => {
    const title = (
      <div className="f-row gap-2 f1-start f2-center">
        <Icon name="share" />
        <FormattedMessage id="ShareModal.Share" defaultMessage="Share" />
      </div>
    );

    const assemblyType = _inAssemblyList
      ? intl.formatMessage(configMessages.sua)
      : intl.formatMessage(configMessages.uo);

    return (
      <Modal title={title} cancel={onCloseModal} footer={renderFooter()}>
        <div>
          {props.assembly.name ? (
            <div className="font-regular">{props.assembly.name}</div>
          ) : _inAssemblyList ? (
            <div className="frozen-grey">
              {intl.formatMessage({
                id: 'ShareModal.NoNameAssembly',
                defaultMessage: 'No name (Single-Use Assembly)'
              })}
            </div>
          ) : (
            <div className="frozen-grey">
              {intl.formatMessage({
                id: 'ShareModal.NoNameUO',
                defaultMessage: 'No name (Unit Operation)'
              })}
            </div>
          )}
          <div className="mt-3">
            {intl.formatMessage(
              {
                id: 'ShareModal.WhoCanAccessThis',
                defaultMessage: 'Who can access this {assembly} ?'
              },
              {
                assembly: assemblyType
              }
            )}
          </div>
          <div className="mt-2 f-row gap-4">
            <Switch
              className="switch-fix"
              vertical
              checked={_isPrivate}
              onChange={setPrivacy}
              firstColor="var(--primaryColor)"
              secondColor="var(--secondaryColor)"
            />

            <div className="f-col gap-2 f-full">
              <div className="f-row gap-2 w-100">
                <div
                  style={{
                    minWidth: 80,
                    color: !_isPrivate ? 'var(--primaryColor)' : 'var(--frozen-grey)'
                  }}>
                  {intl.formatMessage({
                    id: 'SUConfigView.ShareModal.Public',
                    defaultMessage: `Public`
                  })}
                </div>
                {!_isPrivate && (
                  <div className="f-col gap-2 ">
                    {intl.formatMessage(
                      {
                        id: 'SUConfigView.ShareModal.EveryOneCan',
                        defaultMessage: `Everyone can access this {assembly}`
                      },
                      {
                        assembly: assemblyType
                      }
                    )}
                  </div>
                )}
              </div>
              <div className="f-row gap-2 w-100">
                <div
                  style={{
                    minWidth: 80,
                    color: _isPrivate ? 'var(--primaryColor)' : 'var(--frozen-grey)'
                  }}>
                  {intl.formatMessage({
                    id: 'SUConfigView.ShareModal.OnlyMe',
                    defaultMessage: `Only me`
                  })}
                </div>
                {_isPrivate && (
                  <div>
                    {intl.formatMessage(
                      {
                        id: 'SUConfigView.ShareModal.IamTheOnly',
                        defaultMessage: `I'm the only one who can access this {assembly}`
                      },
                      {
                        assembly: assemblyType
                      }
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return render();
};

export default ShareModal;
