import { Icon } from 'hakobio-react-ui';
import { useState } from 'react';

interface Props {
  onOpenLeftPanel: Function;
}

const MinimizedLeftPanel = ({ onOpenLeftPanel }: Props) => {
  const [_isHover, setHover] = useState(false);

  return (
    <div
      className="f-full f-col border-right"
      // @ts-ignore
      onClick={onOpenLeftPanel}>
      <div
        className="border-bottom f-center"
        style={{
          height: 40,
          backgroundColor: 'rgba(245, 245, 245, 0.9)'
        }}>
        <Icon pointer style={{ lineHeight: 0.5 }} size="20px" name="about" />
      </div>
      <div
        className="f-center f-full"
        style={{
          backgroundColor: _isHover ? 'var(--light-grey)' : 'rgba(255, 255, 255, 0.9)'
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        {_isHover && <Icon name="next" pointer />}
      </div>
    </div>
  );
};

export default MinimizedLeftPanel;
