import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  getAssembly,
  getOneAssemblyHistory,
} from "../../services/editor/editorService";
import { convertToRead } from "../../services/editor/editorServiceUtilities";
import {
  getAssembliesCountByType,
  getConnectionCountByType,
} from "../../services/metrics/metricsServiceUtilities";
import FrontPage from "./SchemaPDF/FrontPage";
import SchemaPFD from "./SchemaPDF/SchemaPFD";
import SchemaPID from "./SchemaPDF/SchemaPID";
import SchemaSUA from "./SchemaPDF/SchemaSUA";
import { useAppSelector } from "../../store";
import { useReactToPrint } from "react-to-print";
import { Button, ButtonFooter, Icon, IconTooltip } from "hakobio-react-ui";
import { cloneDeep, isEqual, orderBy, times, uniqWith } from "lodash";
import "./print.css";
import Metrics from "./SchemaPDF/Metrics";

import { useIntl } from "react-intl";
import { generalMessages } from "../../lang/messages";
import { v4 as uuidv4 } from "uuid";

const ReportPanel = (props: any) => {
  const [_assemblyGeneral, setAssemblyGeneral] = useState(null);
  const [_assemblyComponents, setAssemblyComponents] = useState(null);
  const [_assemblyList, setAssemblyList] = useState(null);
  const [_assemblyListReference, setAssemblyListReference] = useState(null);
  const [_assembliesLoaded, setAssembliesLoaded] = useState(0);
  const [_totalPage, setTotalPage] = useState(1);
  const [_isDeletedAssemblies, setDeletedAssemblies] = useState(false);
  const [_isRenderingPDF, setRenderingPDF] = useState(true);
  const _isRenderingPDFRef = useRef("42");
  const _assembliesLoadedRef = useRef(0);
  const _totalPageRef = useRef(1);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const reportData = useAppSelector(
    (state) => state.assemblyEditorSlice.reportData,
  );
  const components = useAppSelector(
    (state) => state.assemblyEditorSlice.components,
  );

  const exportDate = new Date().toLocaleDateString("en-EN", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const intl = useIntl();

  useEffect(() => {
    setRenderingPDF(true);
    const renderingId = uuidv4();
    _isRenderingPDFRef.current = renderingId;
    setTimeout(() => {
      if (renderingId === _isRenderingPDFRef.current) {
        setRenderingPDF(false);
      }
    }, 2000);
  }, [reportData, _assemblyList, _assemblyListReference]);

  useEffect(() => {
    getAssemblyAsync(props.id);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTotalPage(_totalPageRef.current);
    }, 20);
  });

  useEffect(() => {
    if (!_assemblyGeneral || !_assemblyComponents) {
      return;
    }
    const setAssemblyListAsync = async () => {
      const assemblies = await getAssemblies(_assemblyComponents, "assembly");
      const assembliesReference = await getAssemblies(
        _assemblyComponents,
        "assemblyReference",
      );
      setAssemblyList(assemblies);
      setAssemblyListReference(assembliesReference);
    };
    setAssemblyListAsync();
  }, [_assemblyGeneral, _assemblyComponents]);

  const getAssemblyAsync = async (id: string) => {
    const assembly = await getAssembly(id);
    const assemblyToRead = convertToRead(assembly);
    setAssemblyGeneral(assemblyToRead.general);
    setAssemblyComponents(assemblyToRead.components);
  };

  const getComponentAssemblyAsync = async (id, hashPartitionKey) => {
    const assembly = await getOneAssemblyHistory(id, hashPartitionKey);
    const assemblyToRead = convertToRead(assembly);
    return { ...assemblyToRead, isArchived: assembly.isArchived };
  };

  const getAssemblies = async (components: Array<any>, assemblyKey) => {
    const functions = components.filter(
      (c) => c.type === "function" && c.data[assemblyKey],
    );
    const functionCountList = [] as Array<any>;
    for (let i = 0; i < functions.length; i++) {
      const functionComponent = functions[i];
      const assemblyLinked = functionComponent.data[assemblyKey].general;
      const componentInList = functionCountList.find(
        (fc) => fc.hashPartitionKey === assemblyLinked.hashPartitionKey,
      );
      if (componentInList) {
        componentInList.count++;
        continue;
      }
      let assemblyLikedFromDB = null;
      try {
        assemblyLikedFromDB = await getComponentAssemblyAsync(
          assemblyLinked.id,
          assemblyLinked.hashPartitionKey,
        );
      } catch (error) {
        console.log(
          "Catch ERROR: assembly doesn't exist in AssemblyHistory table",
          error,
        );
        setDeletedAssemblies(true);
        return;
      }

      functionCountList.push({
        id: assemblyLinked.id,
        hashPartitionKey: assemblyLinked.hashPartitionKey,
        general: assemblyLikedFromDB.general,
        components: assemblyLikedFromDB.components,
        isArchived: assemblyLikedFromDB.isArchived,
      });
    }
    return functionCountList;
  };

  const backToUnitOperation = () => {
    props.history.push("/editor/UnitOperation/" + _assemblyGeneral.id);
  };

  const suaLoaded = () => {
    _assembliesLoadedRef.current = _assembliesLoadedRef.current + 1;
    setAssembliesLoaded(_assembliesLoadedRef.current);
  };

  const suaUnloaded = (id) => {
    _assembliesLoadedRef.current = _assembliesLoadedRef.current - 1;
    setAssembliesLoaded(_assembliesLoadedRef.current);
  };

  const renderSchemaSUA = (component, assemblyKey) => {
    const assemblyId = component.data[assemblyKey]?.general.id;
    const hashPartitionKey =
      component.data[assemblyKey].general.hashPartitionKey;
    let assembly;
    if (assemblyKey === "assembly") {
      assembly = _assemblyList.find(
        (a) => a.general.hashPartitionKey === hashPartitionKey,
      );
    } else {
      assembly = _assemblyListReference.find(
        (a) => a.general.hashPartitionKey === hashPartitionKey,
      );
    }
    const sortComponents = () => {
      let componentsCopy = cloneDeep(assembly.components);
      let tubesComponentsLinkedIds = [];
      for (let index = 0; index < componentsCopy.length; index++) {
        const element = componentsCopy[index];
        if (element.data.idDrag) {
          tubesComponentsLinkedIds.push(element.data.idDrag.edge);
        }
      }
      componentsCopy = componentsCopy.filter(
        (component: any) => !tubesComponentsLinkedIds.includes(component.id),
      );
      componentsCopy.sort((c1, c2) => {
        let c1x;
        let c2x;
        if (c1.type === "generic" || c1.type === "genericonedge") {
          c1x = c1.viewer2D.x;
        } else {
          const c1Src = assembly.components.find((c) => c.id === c1.source);
          const c1Trg = assembly.components.find((c) => c.id === c1.target);
          c1x =
            c1Src.viewer2D.x < c1Trg.viewer2D.x
              ? c1Src.viewer2D.x
              : c1Trg.viewer2D.x;
        }
        if (c2.type === "generic" || c2.type === "genericonedge") {
          c2x = c2.viewer2D.x;
        } else {
          const c2Src = assembly.components.find((c) => c.id === c2.source);
          const c2Trg = assembly.components.find((c) => c.id === c2.target);
          c2x =
            c2Src.viewer2D.x < c2Trg.viewer2D.x
              ? c2Src.viewer2D.x
              : c2Trg.viewer2D.x;
        }
        if (c1x > c2x) {
          return 1;
        }
        if (c1x < c2x) {
          return -1;
        }
        if (c1.type === c2.type) {
          return 0;
        }
        return c1.type > c2.type ? 1 : c1.type < c2.type ? -1 : 0;
      });

      //delete non essential attributes for the comparison
      const newComponentsCopy = cloneDeep(componentsCopy);
      const componentsData = newComponentsCopy.map((component) => {
        delete component.data.customName;
        delete component.data.assembly;
        if (component.data.type === "plug") {
          component.data.anchors = component.data.anchors.map((anchor) => {
            return anchor.data || null;
          });
        } else if (component.data.type !== "tubing") {
          if (component?.data?.anchors) {
            component.data.anchors = component.data.anchors.map((anchor) => {
              if (anchor?.data !== null && anchor?.data !== undefined) {
                return anchor.data;
              }
            });
          }
          if (component?.data?.instrumentationPorts) {
            component.data.instrumentationPorts =
              component.data.instrumentationPorts.map((anchor) => {
                return anchor.data;
              });
          }
          if (component?.data?.samplingPorts) {
            component.data.samplingPorts = component.data.samplingPorts.map(
              (anchor) => {
                return anchor.data;
              },
            );
          }
        } else {
          delete component.data.anchors;
          delete component.data.instrumentationPorts;
          delete component.data.samplingPorts;
        }
        const newComponent = { ...component.data, ...{ id: component.id } };
        return newComponent;
      });

      function customizer(objValue, othValue) {
        const newObjValue = { ...objValue, ...{ id: "" } };
        const newOthValue = { ...othValue, ...{ id: "" } };

        if (isEqual(newObjValue, newOthValue)) {
          return true;
        } else {
          return false;
        }
      }

      const groups = cloneDeep(uniqWith(componentsData, customizer));

      function getOccurrence(componentsData, value) {
        let count = 0;
        const idLessValue = { ...value, ...{ id: "" } };
        componentsData.forEach((c) => {
          const idlessComponent = { ...c, ...{ id: "" } };
          isEqual(idlessComponent, idLessValue) && count++;
        });
        return count;
      }

      groups.map((g) => (g.count = getOccurrence(componentsData, g)));
      const sortedList = orderBy(groups, ["type"], ["asc"]);

      return sortedList;
    };

    const legend = assembly ? sortComponents() : [];

    const getEntriesAmount = () => {
      let count = 0;
      legend.length &&
        legend.forEach((leg, index) => {
          let legCount = 0;
          let anchorType = "";
          Object.entries(leg).forEach(([key1, value1]) => {
            if (
              key1 === "anchors" ||
              key1 === "instrumentationPorts" ||
              key1 === "samplingPorts"
            ) {
              value1 &&
                Object.values(value1).forEach((anchor) => {
                  if (anchor) {
                    Object.entries(anchor).forEach(([key2, value2]) => {
                      // we only want to add a count if there are actual properties inside the anchor
                      if (
                        key2 !== "position" &&
                        key2 !== "componentLink" &&
                        key2 !== "name"
                      ) {
                        if (anchorType !== key1) {
                          anchorType = key1;
                          count = count + 2;
                          legCount = legCount + 2;
                        }
                        count++;
                        legCount++;
                      }
                    });
                  }
                });
            } else if (key1 === "type") {
              count = count + 2;
              legCount = legCount + 2;
            } else if (
              key1 !== "id" &&
              key1 !== "ghost" &&
              key1 !== "count" &&
              key1 !== "length of tube" &&
              key1 !== "wall" &&
              value1
            ) {
              count++;
              legCount++;
            }
          });
          leg.elementCount = legCount;
          leg.legendNumber = index + 1;
        });
      return count;
    };

    const entriesAmount = getEntriesAmount();

    if (legend) {
      if (entriesAmount < 34) {
        _totalPageRef.current = _totalPageRef.current + 1;
        return (
          <SchemaSUA
            key={assemblyId}
            hashPartitionKey={hashPartitionKey}
            exportDate={exportDate}
            assemblyId={assemblyId}
            assemblyFunction={assembly}
            pageNumber={_totalPageRef.current}
            totalPageNumber={_totalPage}
            suaLoaded={suaLoaded}
            suaUnloaded={suaUnloaded}
            legend={legend}
            entriesAmount={entriesAmount}
          />
        );
      } else {
        let amount = 0;
        let legendDivided = [];
        legend.forEach((leg, index) => {
          amount = amount + leg.elementCount;
          const divided = legendDivided.flat(2);

          if (amount >= 33) {
            const part = legend.slice(divided?.length, index);
            legendDivided.push([part]);
            amount = leg.elementCount;
            const newDevided = [...legendDivided].flat(2);
            if (
              index === legend.length - 1 &&
              legend.length > newDevided.length
            ) {
              const part = legend.slice(newDevided.length);
              legendDivided.push([part]);
              amount = leg.elementCount;
            }
          } else {
            if (index === legend.length - 1 && legend.length > divided.length) {
              const part = legend.slice(divided.length);
              legendDivided.push([part]);
              amount = leg.elementCount;
            }
          }
        });
        return (
          <>
            {times(legendDivided.length, (i) => {
              _totalPageRef.current = _totalPageRef.current + 1;
              return (
                <SchemaSUA
                  key={assemblyId + i}
                  hashPartitionKey={hashPartitionKey}
                  time={i}
                  exportDate={exportDate}
                  assemblyId={assemblyId}
                  assemblyFunction={assembly}
                  pageNumber={_totalPageRef.current}
                  totalPageNumber={_totalPage}
                  suaLoaded={suaLoaded}
                  suaUnloaded={suaUnloaded}
                  legend={legend}
                  entriesAmount={entriesAmount}
                />
              );
            })}
          </>
        );
      }
    }
  };

  const renderFrontPage = () => {
    _totalPageRef.current = _totalPageRef.current + 1;
    return (
      <FrontPage
        assemblyGeneral={_assemblyGeneral}
        exportDate={exportDate}
        pageNumber={_totalPageRef.current}
        totalPageNumber={_totalPage}
      />
    );
  };

  const renderSchemaPFD = () => {
    const assemblyComponentFunctions = _assemblyComponents.filter(
      (c) => c.type === "function",
    );
    // if (assemblyComponentFunctions.length <= 20) {
    _totalPageRef.current = _totalPageRef.current + 1;
    return (
      <SchemaPFD
        assemblyGeneral={_assemblyGeneral}
        exportDate={exportDate}
        assemblyComponents={_assemblyComponents}
        pageNumber={_totalPageRef.current}
        totalPageNumber={_totalPage}
      />
    );
    // } else {
    //   const restOfModulo = assemblyComponentFunctions.length % 20;
    //   const repeatAmount =
    //     Math.floor(assemblyComponentFunctions.length / 20) + (restOfModulo === 0 ? 0 : 1);
    //   return (
    //     <>
    //       {times(repeatAmount, (i) => {
    //         _totalPageRef.current = _totalPageRef.current + 1;
    //         return (
    //           <SchemaPFD
    //             time={i}
    //             assemblyGeneral={_assemblyGeneral}
    //             exportDate={exportDate}
    //             assemblyComponents={_assemblyComponents}
    //             pageNumber={_totalPageRef.current}
    //             totalPageNumber={_totalPage}
    //           />
    //         );
    //       })}
    //     </>
    //   );
    // }
  };

  const renderSchemaPID = () => {
    // if (_assemblyList.length <= 20) {
    _totalPageRef.current = _totalPageRef.current + 1;
    return (
      <SchemaPID
        assemblyGeneral={_assemblyGeneral}
        exportDate={exportDate}
        assemblyComponents={_assemblyComponents}
        assemblyList={_assemblyList}
        pageNumber={_totalPageRef.current}
        totalPageNumber={_totalPage}
      />
    );
    // } else {
    //   const restOfModulo = _assemblyList.length % 20;
    //   const repeatAmount = Math.floor(_assemblyList.length / 20) + (restOfModulo === 0 ? 0 : 1);
    //   return (
    //     <>
    //       {times(repeatAmount, (i) => {
    //         _totalPageRef.current = _totalPageRef.current + 1;
    //         return (
    //           <SchemaPID
    //             assemblyGeneral={_assemblyGeneral}
    //             exportDate={exportDate}
    //             assemblyComponents={_assemblyComponents}
    //             assemblyList={_assemblyList}
    //             pageNumber={_totalPageRef.current}
    //             totalPageNumber={_totalPage}
    //             time={i}
    //           />
    //         );
    //       })}
    //     </>
    //   );
    // }
  };

  const renderMetrics = () => {
    const count =
      3 +
      getAssembliesCountByType(_assemblyComponents).length / 2 +
      getConnectionCountByType(_assemblyComponents).length / 2;
    if (count <= 20) {
      _totalPageRef.current = _totalPageRef.current + 1;
      return (
        <Metrics
          assemblyGeneral={_assemblyGeneral}
          assemblyComponents={_assemblyComponents}
          exportDate={exportDate}
          pageNumber={_totalPageRef.current}
          totalPageNumber={_totalPage}
        />
      );
    } else {
      const restOfModulo = count % 18;
      const repeatAmount =
        Math.floor(count / 18) + (restOfModulo === 0 ? 0 : 1);
      return (
        <>
          {times(repeatAmount, (i) => {
            _totalPageRef.current = _totalPageRef.current + 1;
            return (
              <Metrics
                assemblyGeneral={_assemblyGeneral}
                assemblyComponents={_assemblyComponents}
                exportDate={exportDate}
                pageNumber={_totalPageRef.current}
                totalPageNumber={_totalPage}
                time={i}
              />
            );
          })}
        </>
      );
    }
  };

  const getFunctionComponents = () => {
    let functionsComponents = components
      .filter((c: any) => c.type === "function")
      .filter((c: any) => c.data?.hasOwnProperty("assembly"));

    functionsComponents.sort((c1: any, c2: any) => {
      if (c1.data.assembly.general.name > c2.data.assembly.general.name) {
        return 1;
      } else if (
        c1.data.assembly.general.name === c2.data.assembly.general.name
      ) {
        return 0;
      } else {
        return -1;
      }
    });

    functionsComponents = functionsComponents
      .filter(function (component) {
        var key = `${component.data.assembly.general.id}`;
        return !this.has(key) && this.add(key);
      }, new Set())
      .filter((component) =>
        reportData.allSUA.includes(component.data.assembly.general.id),
      );

    let functionReferenceComponents = components
      .filter((c: any) => c.type === "function")
      .filter((c: any) => c.data?.hasOwnProperty("assemblyReference"));

    functionReferenceComponents.sort((c1: any, c2: any) => {
      if (
        c1.data.assemblyReference.general.name >
        c2.data.assemblyReference.general.name
      ) {
        return 1;
      } else if (
        c1.data.assemblyReference.general.name ===
        c2.data.assemblyReference.general.name
      ) {
        return 0;
      } else {
        return -1;
      }
    });

    functionReferenceComponents = functionReferenceComponents
      .filter(function (component) {
        var key = `${component.data.assemblyReference.general.id}`;
        return !this.has(key) && this.add(key);
      }, new Set())
      .filter((component) =>
        reportData.allReference.includes(
          component.data.assemblyReference.general.id,
        ),
      );

    return { functionsComponents, functionReferenceComponents };
  };

  const { functionsComponents, functionReferenceComponents } =
    getFunctionComponents();

  const isButtonDisabled =
    (!reportData.frontPage && !reportData.PFDView && !reportData.PIDView) ||
    _assembliesLoaded !==
      functionsComponents?.length + functionReferenceComponents?.length;

  const renderActiveButton = () => {
    _totalPageRef.current = 0;
    return (
      <div style={{ overflowY: "auto", counterReset: "page" }}>
        <ButtonFooter
          disabled={isButtonDisabled || _isRenderingPDF}
          onClick={handlePrint}
        >
          {intl.formatMessage(generalMessages.exportReport)}
        </ButtonFooter>
        <div style={{ height: 0, visibility: "hidden", overflow: "hidden" }}>
          <div
            className="border-top f-row f1-between f2-center px-3 py-2 border-bottom"
            style={{ backgroundColor: "var(--grey)" }}
          >
            <div className="f-row f2-center gap-2">
              <Icon name="previous" onClick={backToUnitOperation} />
              {intl.formatMessage(generalMessages.report)}
            </div>
            <Button onClick={handlePrint}>
              {intl.formatMessage(generalMessages.exportReport)}
            </Button>
          </div>

          <div ref={componentRef} className="pb-3" style={{}}>
            {reportData.frontPage && renderFrontPage()}
            {reportData.PFDView && renderSchemaPFD()}
            {reportData.PIDView && renderSchemaPID()}
            {reportData.metrics && renderMetrics()}
            {functionsComponents.map((component) => {
              return renderSchemaSUA(component, "assembly");
            })}
            {functionReferenceComponents.map((component) => {
              return renderSchemaSUA(component, "assemblyReference");
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderErrorButton = () => {
    return (
      <>
        <ButtonFooter
          disabled={true}
          style={{
            zIndex: 1,
            position: "relative",
            minWidth: 125,
            paddingTop: 16,
          }}
        >
          {intl.formatMessage(generalMessages.exportReport)}
        </ButtonFooter>
        <div
          className="f-center"
          style={{
            position: "absolute",
            top: 6,
            right: 8,
            zIndex: 100,
            backgroundColor: "var(--light-orange)",
            borderRadius: 50,
            width: 22,
            height: 22,
            border: "1px solid var(--orange)",
          }}
        >
          <IconTooltip
            name="alert-2"
            title={
              !_assemblyList && !_assemblyComponents && !_assemblyGeneral
                ? "The Unit Operation has not been created."
                : "One or several Single-Use Assemblies in this Unit Operation have been deleted."
            }
            color="var(--orange)"
            hoverColor="var(--orange)"
            placement="top"
          />
        </div>
      </>
    );
  };

  const renderLoadingButton = () => {
    return (
      <>
        <ButtonFooter disabled={true}>
          {intl.formatMessage(generalMessages.exportReport)}
        </ButtonFooter>
      </>
    );
  };

  const render = () => {
    if (_isDeletedAssemblies) {
      return renderErrorButton();
    }
    if (!_assemblyList || !_assemblyListReference) {
      return renderLoadingButton();
    }
    return renderActiveButton();
  };

  return render();
};

export default withRouter(ReportPanel);
