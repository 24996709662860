import { useEffect, useRef, useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';

import { matchPath, withRouter } from 'react-router';
import FlowEditorUO from '../components/Flow/FlowEditorUO';
import LeftPanel from '../components/EditorPanel/LeftPanel';
import { EditorMode } from '../constants/EditorMode';
import { useAssemblyEditorAction } from '../store/features/assemblyEditor/useAssemblyEditorAction';
import ControlButtons from '../components/Shared/ControlButtons';
import { NotificationPanel } from './NotificationPanel';
import { UnitOperationLayer } from '../constants/PFD_EquipmentTabs';
import {
  duplicateGenericAssemblyToReferenceAssembly,
  getAllAssemblies,
  getAssembly,
  getAssemblyHistory
} from '../services/editor/editorService';
import { convertToRead } from '../services/editor/editorServiceUtilities';
//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { Icon, ShortHeader, SmallButton, SplashScreen, ThinChip, Tooltip } from 'hakobio-react-ui';
import { assemblyEditorSliceActions } from '../store/features/assemblyEditor/assemblyEditorSlice';
import { Save } from '../components/EditorPanel/Shared/Utility/Save';

import { checkPFDEditor } from '../services/rules/PFDEditorRuleService';
import { checkPIDEditor } from '../services/rules/PIDEditorRuleService';
import { checkSUAEditor } from '../services/rules/SUAEditorRuleService';
import { useAppDispatch, useAppSelector } from '../store/index';
import ExitModal from './ExitModal';
import { useHistory } from 'react-router-dom';
import { useEditorLibraryAction } from '../store/features/assemblyEditor/useEditorLibraryAction';
import FlowEditorSUA from '../components/Flow/FlowEditorSUA';
import { useImplementEditorAction } from '../store/features/assemblyEditor/useImplementEditorAction';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import SaveModal from './SaveModal';
import { configMessages, generalMessages, versioningMessages } from '../lang/messages';
import HistoryModal from '../components/History/HistoryModal';
import { toast } from 'react-toastify';
import { GetUpdatedSUA } from '../components/Flow/utils/GetUpdatedSUA';
import FlowEditorComponents from '../components/Flow/FlowEditorComponents';
import { CUSTOM_NODES } from './../constants/CUSTOM_NODES';
import FlowEditorReference from '../components/Flow/FlowEditorReference';
import { GenericStatus } from '../utilities/statuses/genericStatus';
import { OperationStatus } from '../utilities/statuses/operationStatus';
import { ViewMode } from '../constants/ViewMode';

const SUConfigView = (props: any) => {
  const dispatch = useAppDispatch();
  const { setEditorMode, setAssembly } = useAssemblyEditorAction();
  const { getEditorCustomComponentLibrary, getEditorAssemblyLibrary } = useEditorLibraryAction();
  const { implementComponent } = useImplementEditorAction();

  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const isSaveDisabled = useAppSelector((state) => state.assemblyEditorSlice.isSaveDisabled);
  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary
  );
  const isEditingAssembly = useAppSelector((state) => state.assemblyEditorSlice.isEditingAssembly);
  const isSUAUpdating = useAppSelector((state) => state.assemblyEditorSlice.isSUAUpdating);

  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const showHistoryModal = useAppSelector((state) => state.assemblyEditorSlice.showHistoryModal);

  const editorCustomComponentLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorCustomComponentLibrary
  );
  const customComponentEdition = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentEdition
  );
  const libComponentName = useAppSelector((state) => state.assemblyEditorSlice.libComponentName);
  const customComponentViewOnly = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentViewOnly
  );
  const allHeadersLibrary = useAppSelector((state) => state.assemblyEditorSlice.allHeadersLibrary);

  const intl = useIntl();

  const componentsRef = useRef<any>(null);
  const [_errors, setErrors] = useState<any[]>([]);
  const [_activeTab, setActiveTab] = useState(0);
  const [showExitModal, setShowExitModal] = useState(false);
  const [_showSaveModal, setShowSaveModal] = useState(false);
  const [isExitingOnSave, setExitingOnSave] = useState(false);
  const [_isLoading, setLoading] = useState(true);
  const [_assemblyOriginal, setAssemblyOriginal] = useState(null);
  const [_historyButton, setHistoryButton] = useState(false);
  const history = useHistory();

  componentsRef.current = components;

  const getAssemblyAsync = async (id: string) => {
    const assembly = await getAssembly(id);
    const assemblyToRead = convertToRead(assembly);
    setAssemblyOriginal(assemblyToRead.general);
    setAssembly(assemblyToRead.general, assemblyToRead.components);
    setLoading(false);
  };

  // getReferenceAsync creer un assemblage de reference sinon un duplicate temporaire
  const getReferenceAsync = async (id: string) => {
    const assembly = await duplicateGenericAssemblyToReferenceAssembly(id);

    if (assembly.status !== 500) {
      const assemblyToRead = convertToRead(assembly);
      // @ts-ignore
      assemblyToRead.general.newAssembly = true;
      setAssembly(assemblyToRead.general, assemblyToRead.components);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (_assemblyOriginal) {
      const getHistoryAsync = async () => {
        let historyList = await getAssemblyHistory(_assemblyOriginal.id);
        if (historyList.title === 'Unauthorized') {
          dispatch(assemblyEditorSliceActions.setShowHistoryModal(null));
          setHistoryButton(false);
          return;
        } else {
          setHistoryButton(true);
        }
      };
      getHistoryAsync();
    }
  }, [_assemblyOriginal]);

  useEffect(() => {//
    const matchComp = matchPath(props.location.pathname, {
      path: '/editor/Component'
    });
    const matchSua = matchPath(props.location.pathname, {
      path: '/editor/SingleUseAssembly'
    });
    const matchRef = matchPath(props.location.pathname, {
      path: '/editor/Reference'
    });

    if (!matchComp) dispatch(assemblyEditorSliceActions.setViewMode(ViewMode.Editor));

    if (matchComp) {
      const matchComponent = matchPath(props.location.pathname, {
        path: '/editor/Component/:componentName/:componentId',
        exact: true
      });
      if (matchComponent) {
        // @ts-ignore
        const id = matchComponent.params.componentId;
        const customComponent = editorCustomComponentLibrary.find((c) => c.id === id);
        dispatch(assemblyEditorSliceActions.setCustomComponentEdition(customComponent));
      } else {
        const newId = uuidv4();
        const newCustomComponent = {
          type: 'generic',
          id: newId,
          data: {
            anchors: [],
            instrumentationPorts: [],
            samplingPorts: [],
            type: libComponentName,
            customName: `${intl.formatMessage(generalMessages.new)} ${
              CUSTOM_NODES[libComponentName].name
            }`
          },
          viewer2D: {}
        };
        dispatch(assemblyEditorSliceActions.setCustomComponentEdition(newCustomComponent));
      }
      setLoading(false);
      setEditorMode(EditorMode.Component);
    } else if (matchSua) {
      const matchGenericAssembly = matchPath(props.location.pathname, {
        path: '/editor/SingleUseAssembly/:assemblyId',
        exact: true
      });
      if (matchGenericAssembly) {
        // @ts-ignore
        const id = matchGenericAssembly.params.assemblyId;
        getAssemblyAsync(id);
      } else {
        const newId = uuidv4();
        setAssembly(
          {
            createdOn: new Date().toISOString(),
            modifiedOn: new Date().toISOString(),
            name: 'New assembly',
            id: newId,
            description: '',
            functions: [],
            type: 'SingleUseAssembly',
            tags: [],
            permissions: {
              changeStatus: false,
              createReference: false,
              delete: true,
              duplicate: false,
              read: true,
              update: true
            },
            version: 0,
            versionMessage: '',
            newAssembly: true,
            hashPartitionKey: newId + '-0',
            status: GenericStatus.Draft
          },
          []
        );
        setLoading(false);
      }
      setEditorMode(EditorMode.SUA);
      getEditorCustomComponentLibrary();
    } else if (matchRef) {
      const matchReferenceAssembly = matchPath(props.location.pathname, {
        path: '/editor/Reference/:assemblyId',
        exact: true
      });
      if (matchReferenceAssembly) {
        setLoading(false);
        let duplicateGenericToReference = localStorage.getItem('duplicateGenericToReference');
        if (duplicateGenericToReference) {
          localStorage.removeItem('duplicateGenericToReference');
          // @ts-ignore
          const idReference = matchReferenceAssembly.params.assemblyId;
          getReferenceAsync(idReference);
        } else {
          // @ts-ignore
          const idReference = matchReferenceAssembly.params.assemblyId;
          getAssemblyAsync(idReference);
        }
      }
      setEditorMode(EditorMode.Reference);
      getEditorCustomComponentLibrary();
    } else {
      const matchUnitOperation = matchPath(props.location.pathname, {
        path: '/editor/UnitOperation/:assemblyId',
        exact: true
      });
      if (matchUnitOperation) {
        // @ts-ignore
        const id = matchUnitOperation.params.assemblyId;
        getAssemblyAsync(id);
      } else {
        const newId = uuidv4();
        setAssembly(
          {
            createdOn: new Date().toISOString(),
            modifiedOn: new Date().toISOString(),
            name: 'New unit operation',
            id: newId,
            description: '',
            functions: [],
            type: 'UnitOperation',
            tags: [],
            permissions: {
              changeStatus: false,
              createReference: false,
              delete: true,
              duplicate: false,
              read: true,
              update: true
            },
            version: 0,
            versionMessage: '',
            newAssembly: true,
            status: OperationStatus.Draft
          },
          []
        );
        setLoading(false);
      }
      setEditorMode(EditorMode.UnitOperation);
      getEditorAssemblyLibrary();
    }
  }, [props.location.pathname]);

  // useEffect(() => {
  //   const activeTab = () => {
  //     switch (layerMode) {
  //       case UnitOperationLayer.PFD:
  //         return 0;
  //       case UnitOperationLayer.PnID:
  //         return 1;
  //       case UnitOperationLayer.Reference:
  //         return 2;
  //       default:
  //         return 0;
  //     }
  //   };
  //   setActiveTab(activeTab());
  // }, [layerMode]);

  useEffect(() => {
    const failedRules = check();
    dispatch(assemblyEditorSliceActions.setErrors(failedRules));
    setErrors(failedRules);
  }, [components, editorAssemblyLibrary, editorMode, layerMode]);

  useEffect(() => {
    if (isSUAUpdating) {
      const componentToUpdateList = GetUpdatedSUA(components, editorAssemblyLibrary);
      clearAndUpdateSUAs(componentToUpdateList);
    }
  }, [isSUAUpdating]);

  useEffect(() => {
    if (!allHeadersLibrary.length) {
      const allAssemblies = async () => {
        const result = await getAllAssemblies();
        result
          .filter(
            (a: any) => a.type === 'SingleUseAssembly' || a.type === 'SingleUseAssemblyReference'
          )
          .sort(sortByName);

        dispatch(assemblyEditorSliceActions.setAllHeadersLibrary(result));
      };
      allAssemblies();
    }
  }, [allHeadersLibrary]);

  const sortByName = (a: any, b: any) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
      return 0;
    } else {
      return -1;
    }
  };

  const clearAndUpdateSUAs = async (componentToUpdateList) => {
    for (let i = 0; i < componentToUpdateList.length; i++) {
      const promiseImplement = new Promise((resolve, reject) => {
        setTimeout(() => {
          const component = componentToUpdateList[i];
          const realSelectedComponent = componentsRef.current.find(
            (c: any) => c.id === component.function.id
          );
          const newComponent = _.cloneDeep(realSelectedComponent);
          implementComponent(
            newComponent,
            component.updatedSua,
            componentsRef.current,
            false,
            layerMode,
            editorAssemblyLibrary
          );
          resolve(true);
        }, 1000);
      });
      await promiseImplement;
    }
    dispatch(assemblyEditorSliceActions.setIsSUAUpdating(false));
  };

  const check = () => {
    switch (editorMode) {
      case EditorMode.SUA:
        return checkSUAEditor(components);
      case EditorMode.Reference:
        return checkSUAEditor(components);
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PFD:
            return checkPFDEditor(components);
          case UnitOperationLayer.PnID:
            const setIsSUAUpdating = () => {
              dispatch(assemblyEditorSliceActions.setIsSUAUpdating(true));
              toast.success(
                <FormattedMessage
                  id="PIDEditorRuleService.AllSUASAreUpdated"
                  defaultMessage="All SUA's are updated, please connect your components"
                />
              );
            };
            return checkPIDEditor(components, editorAssemblyLibrary, { setIsSUAUpdating });

          case UnitOperationLayer.Reference:
            let setIsSUAUpdatingReference = () => {
              dispatch(assemblyEditorSliceActions.setIsSUAUpdating(true));
              toast.success(
                <FormattedMessage
                  id="PIDEditorRuleService.AllSUASAreUpdated"
                  defaultMessage="All SUA's are updated, please connect your components"
                />
              );
            };
            return checkPIDEditor(components, editorAssemblyLibrary, { setIsSUAUpdatingReference });
        }
    }
    return [];
  };

  const handleChangeTab = (index: number) => {
    setActiveTab(index);
    const tabName = ['PFD', 'P&ID', 'Reference'];
    dispatch(assemblyEditorSliceActions.setLayerMode(tabName[index]));
  };

  const onClose = () => {
    if (isEditingAssembly || _assemblyOriginal?.status !== general.status) {
      setShowExitModal(true);
    } else {
      closeEditor();
    }
  };

  const closeEditor = () => {
    window.parent.postMessage({ message: 'deleteCross', value: 'show' }, '*');
    dispatch(assemblyEditorSliceActions.resetEditor(null));
    history.push('/list');
  };

  const closeExitModal = () => {
    setShowExitModal(false);
  };

  const onSave = () => {
    setShowSaveModal(true);
  };

  const closeSaveModal = () => {
    setShowSaveModal(false);
  };

  const renderExitModal = () => {
    return (
      <ExitModal
        saveButtonsDisabled={saveButtonsDisabled()}
        showSaveModal={() => {
          setShowExitModal(false);
          setExitingOnSave(true);
          setShowSaveModal(true);
        }}
        closeModal={closeExitModal}
      />
    );
  };

  function canChangeStatusOnly() {
    return general?.permissions?.changeStatus && !general.permissions.update;
  }

  function saveButtonsDisabled() {
    if (editorMode === EditorMode.Component) {
      return false;
    }
    if (canChangeStatusOnly()) {
      return _assemblyOriginal?.status === general.status;
    } else {
      return !general?.permissions?.update || isSaveDisabled;
    }
  }

  const renderSaveModal = () => {
    return (
      <SaveModal
        saveButtonsDisabled={saveButtonsDisabled()}
        closeModal={closeSaveModal}
        closeEditor={closeEditor}
        isExitingOnSave={isExitingOnSave}
      />
    );
  };

  if (_isLoading) {
    return <SplashScreen text={'Loading'} />;
  }

  const renderFlow = () => {
    switch (editorMode) {
      case EditorMode.Component:
        return <FlowEditorComponents showExitModal={showExitModal} />;
      case EditorMode.SUA:
        return <FlowEditorSUA showExitModal={showExitModal} />;
      case EditorMode.UnitOperation:
        dispatch(assemblyEditorSliceActions.setViewMode(ViewMode.Editor));
        return <FlowEditorUO showExitModal={showExitModal} />;
      case EditorMode.Reference:
        return <FlowEditorReference showExitModal={showExitModal} />;
    }
  };

  const renderHistoryModal = () => {
    return <HistoryModal />;
  };

  const showModalHistory = () => {
    dispatch(assemblyEditorSliceActions.setShowHistoryModal({ assemblyId: general?.id }));
  };

  const UOTabs = [
    <div className="f-row f2-center gap-2">
      <Icon name="layer-1" />
      {intl.formatMessage(configMessages.PFD)}
    </div>,
    <div className="f-row f2-center gap-2">
      <Icon name="layer-2" />
      {intl.formatMessage(configMessages.PID)}
    </div>,
    <div className="f-row f2-center gap-2">
      <Icon name="floors" />
      {intl.formatMessage(configMessages.references)}
    </div>
  ];

  return (
    <>
      <ReactFlowProvider>
        {showExitModal && renderExitModal()}
        {_showSaveModal && renderSaveModal()}
        {showHistoryModal && renderHistoryModal()}
        <ShortHeader
          style={{ zIndex: 9, position: 'relative' }}
          tabs={editorMode === EditorMode.UnitOperation ? UOTabs : null}
          activeTab={_activeTab}
          changeActiveTab={handleChangeTab}
          onClose={onClose}
          notifications={
            editorMode === EditorMode.Component ? undefined : <NotificationPanel errors={_errors} />
          }
          errorNotifs={_errors.length}>
          {!customComponentViewOnly && (
            <Save onSave={onSave} saveButtonsDisabled={saveButtonsDisabled()} />
          )}
          <>
            {(editorMode === EditorMode.SUA || editorMode === EditorMode.Reference) && (
              <div className="ml-2 pl-2 gap-2 f-center border-left">
                <Tooltip
                  className="tooltip-p-0"
                  placement="right"
                  title={
                    !_historyButton
                      ? intl.formatMessage({
                          id: 'SUConfigView.Assembly.SaveToSeeHistory',
                          defaultMessage: `Save to see history`
                        })
                      : intl.formatMessage({
                          id: 'SUConfigView.Assembly.SeeHistory',
                          defaultMessage: `See history`
                        })
                  }>
                  <SmallButton
                    disabled={!_historyButton}
                    width="auto"
                    inverse
                    onClick={showModalHistory}>
                    <Icon name="history" />
                  </SmallButton>
                </Tooltip>

                <ThinChip
                  auto
                  className="mb-0"
                  fontSize="text"
                  label={
                    <span>
                      {intl.formatMessage(versioningMessages.version)}
                      <span className="font-regular"> {general.version + 1}</span>
                    </span>
                  }
                  color={'var(--primaryColor)'}
                  backgroundColor={'var(--primaryColorBcg)'}
                />
              </div>
            )}

            <div
              className={`${
                customComponentViewOnly ? '' : 'pl-3 ml-2 border-left'
              } font-regular max-1-lines-visible capitalize`}>
              {editorMode === EditorMode.Component
                ? customComponentEdition.data.customName || customComponentEdition.name
                : general.name}
            </div>
          </>
        </ShortHeader>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            height: 45,
            backgroundColor: '#f7faff'
          }}
        />

        <div className="f-row f-full" style={{ height: 1 }}>
          <LeftPanel />
          {editorMode !== EditorMode.Component && <ControlButtons />}
          {renderFlow()}
        </div>
      </ReactFlowProvider>
    </>
  );
};

export default withRouter(SUConfigView);
