import { Chip, Icon, UserCard, SmallButton } from 'hakobio-react-ui';
import React, { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generalMessages, versioningMessages } from '../../lang/messages';
import { useCustomBehavior } from '../../utils';

const HistoryModalElement = (props: any) => {
  const [_isHovering, setIsHovering] = useState(false);
  const currentSize = React.useRef(null);
  const [_marginWidth, setMarginWidth] = useState(34);

  useLayoutEffect(() => {
    if (currentSize.current) {
      setMarginWidth(currentSize.current.offsetWidth);
    }
  }, []);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  const { openReactAppHistoryInNewTab } = useCustomBehavior();

  const intl = useIntl();

  const modifiedOn = new Date(
    props.assembly.modifiedOn || props.assembly.createdOn
  ).toLocaleDateString(
    undefined,
    // @ts-ignore
    options
  );
  const createdOn = new Date(props.assembly.createdOn).toLocaleDateString(
    undefined,
    // @ts-ignore
    options
  );

  const renderAssemblyName = () => {
    if (props.index === 0) {
      return <div className="font-regular">{props.assembly.name}</div>;
    }
    if (props.historyList.length - 1 === props.index) {
      return (
        <div style={{ marginLeft: _marginWidth }} className="font-regular 2">
          {props.assembly.name}
        </div>
      );
    }

    let prevName = props.historyList[props.index - 1].name;
    if (props.assembly.name !== prevName) {
      return <div className="font-regular">{props.assembly.name}</div>;
    }

    return (
      <div style={{ marginLeft: _marginWidth }} className="font-regular">
        {props.assembly.name}
      </div>
    );
  };

  const renderVersionComment = () => {
    return (
      <div className="small-text">
        <div className="f-row f2-center gap-2">
          <Icon name="bubble-comment" color="frozen-grey" />
          <div className={`${!props.assembly.versioningMessages && 'frozen-grey'}`}>
            {props.assembly.versionMessage
              ? intl.formatMessage(versioningMessages.comment)
              : intl.formatMessage({
                  id: 'noComment',
                  defaultMessage: 'No Comment'
                })}
          </div>
        </div>

        <div className="max-3-lines-visible">
          <div style={{ maxWidth: 380 }}>{props.assembly.versionMessage}</div>
        </div>
      </div>
    );
  };

  const openAssemblyVersion = () => {
    localStorage.setItem('action', 'assemblyVersion');
    localStorage.setItem('actionData', JSON.stringify(props.assembly));
    openReactAppHistoryInNewTab();
  };

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        transition: '0.1s all'
      }}>
      <div className="f-center gap-2">
        {props.index === 0 ? (
          <>
            <div className="f-row f2-center gap-2 mr-1">
              <div
                style={{
                  backgroundColor: 'var(--primaryColor)',
                  width: 20,
                  height: 20,
                  borderRadius: 50,
                  minWidth: 20,
                  minHeight: 20
                }}></div>
              <div className="color-primary" ref={currentSize}>
                {intl.formatMessage(versioningMessages.current)}
              </div>
            </div>

            <Chip
              label={
                <div className="small-text">
                  {intl.formatMessage(versioningMessages.versionAbr)}{' '}
                  <span className="font-regular">{props.assembly.version + 1}</span>
                </div>
              }
              color={'var(--primaryColor)'}
              backgroundColor={'var(--primaryColorBcg)'}
            />
          </>
        ) : (
          <div className="f-row f2-center gap-2 mr-1">
            <div
              style={{
                backgroundColor: 'var(--primaryColorBcg)',
                width: 20,
                height: 20,
                borderRadius: 50,
                minWidth: 20,
                minHeight: 20
              }}></div>
            <div className="color-primary">
              {intl.formatMessage(versioningMessages.versionAbr)}{' '}
              <span className="font-regular">{props.assembly.version + 1}</span>
            </div>
          </div>
        )}

        <div>{renderAssemblyName()}</div>

        <div style={{ flex: 1 }}></div>
      </div>
      <div
        className="f-row pr-2 pb-2 w-100 hover-item"
        style={{
          borderLeft:
            props.index === props.historyList.length - 1
              ? 'none'
              : '1px solid var(--primaryColorBcg)',
          marginTop: -26,
          marginLeft: '.65rem',
          paddingTop: '2.5rem',
          paddingLeft: props.index === 0 ? 90 : 56
        }}>
        <div
          className="w-100 pb-2"
          style={{ marginTop: -10, marginLeft: props.index !== 0 && _marginWidth - 4 }}>
          <div className="f-row f1-between w-100 f2-center">
            <div className="f-row f2-start gap-5 w-100">
              <div className="f-row f2-center" style={{ minWidth: 280, maxWidth: 280 }}>
                <div className="f-row f2-center gap-2 pr-3 ">
                  <UserCard
                    title={
                      <div>
                        <div className="border-bottom mb-1">
                          {intl.formatMessage(generalMessages.createdBy)}
                        </div>
                        <div className="font-regular">{props.assembly?.createdByName}</div>
                        <div className="font-regular">
                          {props.assembly?.createdByEmail || 'N/C'}
                        </div>
                      </div>
                    }
                    firstName={props.assembly?.createdByName}
                    minimized
                  />
                  <div className="small-text">
                    <div>
                      {intl.formatMessage({
                        id: 'SUConfigView.HistoryModal.Creation',
                        defaultMessage: 'Creation'
                      })}
                    </div>
                    <div>{createdOn}</div>
                  </div>
                </div>
                <div style={{ width: 1, height: 30, backgroundColor: 'var(--grey)' }}></div>
                <div className="f-row f2-center gap-2 pl-3 ">
                  <UserCard
                    title={
                      <div>
                        <div className="border-bottom mb-1">
                          {intl.formatMessage(generalMessages.modifiedBy)}
                        </div>
                        <div className="font-regular">
                          {props.assembly?.modifiedByName || props.assembly?.createdByName}
                        </div>
                        <div className="font-regular">
                          {props.assembly?.modifiedByEmail ||
                            props.assembly?.createdByEmail ||
                            'N/C'}
                        </div>
                      </div>
                    }
                    firstName={props.assembly?.modifiedByName || props.assembly?.createdByName}
                    minimized
                  />
                  <div className="small-text">
                    <div>Last modified</div> <div>{modifiedOn}</div>
                  </div>
                </div>
              </div>
              <div>{renderVersionComment()}</div>
            </div>

            <div
              style={{
                opacity: _isHovering ? 1 : 0,
                transition: '0.1s all'
              }}>
              <SmallButton onClick={openAssemblyVersion}>
                {intl.formatMessage(versioningMessages.seeVersion)}
              </SmallButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryModalElement;
