import React from 'react';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckBox, PanelHeader, Title } from 'hakobio-react-ui';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { withRouter } from 'react-router-dom';
import { ReportData } from '../../../../constants/ReportData';
import ReportPanelSUA from '../../../ReportPanel/ReportPanelSUA';
import { generalMessages } from '../../../../lang/messages';

const SingleUseEditorReportPanel = (props) => {
  const dispatch = useAppDispatch();
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const reportData = useAppSelector((state) => state.assemblyEditorSlice.reportData);

  const intl = useIntl();

  let suaList = components
    .filter((c: any) => c.type === 'function')
    .filter((c: any) => c.data?.hasOwnProperty('assembly'));

  suaList.sort((c1: any, c2: any) => {
    if (c1.data.assembly.general.name > c2.data.assembly.general.name) {
      return 1;
    } else if (c1.data.assembly.general.name === c2.data.assembly.general.name) {
      return 0;
    } else {
      return -1;
    }
  });
  suaList = suaList.filter(function (component) {
    var key = `${component.data.assembly.general.id}`;
    return !this.has(key) && this.add(key);
  }, new Set());

  const handleChange = (event, key) => {
    dispatch(
      assemblyEditorSliceActions.setReportData({ ...reportData, [key]: event.target.checked })
    );
  };

  const handleChangeAllSUA = (event) => {
    const suaIdList = suaList.map((sua: any) => sua.data.assembly.general.id);
    if (event.target.checked) {
      dispatch(
        assemblyEditorSliceActions.setReportData({
          ...reportData,
          [ReportData.AllSUA]: [...suaIdList]
        })
      );
    } else {
      dispatch(
        assemblyEditorSliceActions.setReportData({ ...reportData, [ReportData.AllSUA]: [] })
      );
    }
  };

  const handleChangeSUA = (event, id) => {
    let selectedSuas = _.cloneDeep(reportData.allSUA);
    if (event.target.checked) {
      selectedSuas.push(id);
    } else {
      selectedSuas = _.remove(selectedSuas, function (n) {
        return n !== id;
      });
    }
    dispatch(
      assemblyEditorSliceActions.setReportData({
        ...reportData,
        [ReportData.AllSUA]: [...selectedSuas]
      })
    );
  };

  return (
    <div className="f-col f-full h-100  relative h-100">
      <PanelHeader>{intl.formatMessage(generalMessages.report)}</PanelHeader>
      <div className="f-full" style={{ overflowY: 'auto', marginBottom: '4rem' }}>
        <div className="f-col f1-start gap-2 f-full px-2">
          <Title underline="short">
            <FormattedMessage
              id="SUAEditorReportPanel.SeenInYourReport"
              defaultMessage="What would you like to be seen in your report?"
            />
          </Title>
          <CheckBox
            onChange={(e: any) => handleChange(e, ReportData.FrontPage)}
            label={'Front page'}
            checked={reportData.frontPage}
          />

          <div className="border-bottom" style={{ width: 35, borderBottomStyle: 'dotted' }} />
          <CheckBox
            key={general.id}
            onChange={(event) => handleChangeSUA(event, general.id)}
            label={general.name}
            checked={reportData.allSUA.includes(general.id)}
          />

          {/* <CheckBox
            onChange={(e: any) => handleChangeAllSUA(e)}
            label="All Single-Use Assemblies"
            checked={reportData.allSUA.length === suaList.length}
            // @ts-ignore
            indeterminate={
              reportData.allSUA.length > 0 && reportData.allSUA.length !== suaList.length
            }
          /> */}

          {/* {suaList.length ? (
            suaList.map((component, index) => {
              return (
                <CheckBox
                  key={component.id}
                  className="pl-4"
                  onChange={(event) => handleChangeSUA(event, component.data.assembly.general.id)}
                  label={component.data.assembly.general.name}
                  checked={reportData.allSUA.includes(component.data.assembly.general.id)}
                />
              );
            })
          ) : (
            <div className="frozen-grey">There is no SUA implemented yet.</div>
          )} */}
        </div>

        <div style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
          <ReportPanelSUA general={general} components={components} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(SingleUseEditorReportPanel);
