import { Empty, Icon } from "hakobio-react-ui";
import { CUSTOM_NODES } from "../../constants/CUSTOM_NODES";
import { useAppDispatch, useAppSelector } from "../../store";
import { assemblyEditorSliceActions } from "../../store/features/assemblyEditor/assemblyEditorSlice";
import { useEditorLibraryAction } from "../../store/features/assemblyEditor/useEditorLibraryAction";
import DeleteModal from "../../views/DeleteModal";
import { EntityToDeleteTypes } from "../../views/enum/EntityToDeleteType";
import ComponentItem from "./ComponentItem";
import { deleteCustomComponent } from "../../services/editor/editorService";
import { useState } from "react";
import { EditorMode } from "../../constants/EditorMode";

const CustomComponentList = ({ searchedValue }: { searchedValue?: string }) => {
  const dispatch = useAppDispatch();
  const libComponentName = useAppSelector(
    (state) => state.assemblyEditorSlice.libComponentName,
  );
  const editorCustomComponentLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorCustomComponentLibrary,
  );
  const editorMode = useAppSelector(
    (state) => state.assemblyEditorSlice.editorMode,
  );
  const showDeleteConfirmModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showConfirmDeleteModal,
  );
  const { getEditorCustomComponentLibrary } = useEditorLibraryAction();
  const [_componentToDelete, setComponentToDelete] = useState<any>(null);
  const [_selectedTubing, setSelectedTubing] = useState<string | null>(null);

  let constants = Object.entries(CUSTOM_NODES).map((nodeObject: any) => {
    const [key, node] = nodeObject;
    return {
      key,
      type: "generic",
      node,
    };
  });

  const constant = constants?.find((constant: any) => {
    return constant.key === libComponentName;
  });

  let customComponentList = editorCustomComponentLibrary
    .filter((customComponent) => customComponent.data.type === libComponentName)
    .sort((a, b) => {
      if (a.data.customName < b.data.customName) {
        return -1;
      } else if (a.data.customName === b.data.customName) {
        return 0;
      } else {
        return 1;
      }
    });

  if (searchedValue !== "") {
    customComponentList.filter((customComponent) => {
      const name =
        customComponent.data.customName || customComponent.name || "";
      return name.toLowerCase().includes(searchedValue?.toLowerCase());
    });
  }

  const handleBack = () => {
    dispatch(assemblyEditorSliceActions.setLibComponentName(null));
  };

  const onDeleteCustomComponent = (component) => {
    setComponentToDelete(component);
    dispatch(
      assemblyEditorSliceActions.setShowDeleteConfirmModal({
        entityId: component.id,
        entityName: CUSTOM_NODES[component.data.type]?.name,
        type: EntityToDeleteTypes.CustomComponent,
      }),
    );
  };

  const removeComponent = async () => {
    await deleteCustomComponent(_componentToDelete.id);
    getEditorCustomComponentLibrary();
  };

  return (
    <>
      {editorMode !== EditorMode.SUA && showDeleteConfirmModal && (
        <DeleteModal
          removeAssembly={null}
          onRemoveComponents={removeComponent}
        />
      )}
      <div
        className={
          editorMode === EditorMode.SUA ? "f-full f-col" : "p-2 f-full f-col"
        }
      >
        {editorMode !== EditorMode.SUA && (
          <div className="f-row f2-center gap-2 p-2">
            <Icon name="previous" onClick={handleBack} />
            <div style={{ maxHeight: 40 }}>
              {CUSTOM_NODES[libComponentName]?.form(40)}
            </div>
            <div>{CUSTOM_NODES[libComponentName]?.name}</div>
          </div>
        )}

        <div
          className="p-2 f-full overflow-y-auto"
          style={{ height: editorMode === EditorMode.SUA ? undefined : 1 }}
        >
          {customComponentList.length ? (
            customComponentList.map((cc: any) => {
              return (
                <ComponentItem
                  key={cc.id}
                  keyName={constant.key}
                  node={constant.node}
                  constant={constant}
                  component={cc}
                  isShowingCustomLibrary={true}
                  isCustomComponent={true}
                  onDeleteCustomComponent={onDeleteCustomComponent}
                  selectTubing={(id) => setSelectedTubing(id)}
                  selectedTubing={_selectedTubing}
                />
              );
            })
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </>
  );
};

export default CustomComponentList;
