import { FC, memo, useEffect, useRef } from 'react';

import { NodeProps } from 'react-flow-renderer';
import { useAssemblyEditorAction } from '../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { NodeData } from '../utility/NodeData';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { CUSTOM_FUNCTIONS } from '../../../constants/CUSTOM_FUNCTIONS';
import { cloneDeep } from 'lodash';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { Icon } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import { Uuidv4 } from '../../../utilities';
import { actionMessages } from '../../../lang/messages';

const SelectionNode: FC<NodeProps<NodeData>> = (props) => {
  const dispatch = useAppDispatch();
  const { onComponentVerticalFlip, onComponentHorizontalFlip } = useAssemblyEditorAction();

  const { id, selected } = props;

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);

  const componentRef = useRef(null);
  const componentsRef = useRef<any>(null);
  const layerModeRef = useRef<any>(null);
  const editorModeRef = useRef<any>(null);

  layerModeRef.current = layerMode;
  editorModeRef.current = editorMode;
  componentsRef.current = components;

  const intl = useIntl();

  const component = components.find((c) => c.id === id);
  componentRef.current = component;
  const rotate = component?.viewer2D?.rotate ?? 0;

  const functionComponent = components.find((c: any) => {
    return component?.data.component === c.id;
  });

  let componentField;
  let viewField;

  switch (layerMode) {
    case UnitOperationLayer.PnID:
      componentField = 'assembly';
      viewField = 'pid';
      break;
    case UnitOperationLayer.Reference:
      if (functionComponent?.data.assembly && !functionComponent?.data.assemblyReference) {
        componentField = 'assembly';
        viewField = 'pid';
      } else {
        componentField = 'assemblyReference';
        viewField = 'reference';
      }
  }

  useEffect(() => {
    if (!componentRef.current) {
      return;
    }
    const componentCopy = cloneDeep(componentRef.current);
    switch (editorModeRef.current) {
      case EditorMode.SUA:
        if (component.viewer2D.x !== props.xPos || component.viewer2D.y !== props.yPos) {
          // @ts-ignore
          const deltaX = Math.abs(component.viewer2D.x - props.xPos);
          // @ts-ignore
          const deltaY = Math.abs(component.viewer2D.y - props.yPos);
          if (deltaX > 0.01 || deltaY > 0.01) {
            const promise = { id: id, component: componentCopy };
            promise.component.viewer2D.x = props.xPos;
            promise.component.viewer2D.y = props.yPos;
            dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          }
        }
        break;
      case EditorMode.Reference:
        if (
          component.viewer2D[viewField].x !== props.xPos ||
          component.viewer2D[viewField].y !== props.yPos
        ) {
          // @ts-ignore
          const deltaX = Math.abs(component.viewer2D[viewField].x - props.xPos);
          // @ts-ignore
          const deltaY = Math.abs(component.viewer2D[viewField].y - props.yPos);
          if (deltaX > 0.01 || deltaY > 0.01) {
            const promise = { id: id, component: componentCopy };
            promise.component.viewer2D[viewField].x = props.xPos;
            promise.component.viewer2D[viewField].y = props.yPos;
            dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          }
        }
        break;
      case EditorMode.UnitOperation:
        switch (layerModeRef.current) {
          case UnitOperationLayer.PFD:
            if (
              componentRef.current.viewer2D.pfd.x !== props.xPos ||
              componentRef.current.viewer2D.pfd.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pfd.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pfd.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pfd = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.PnID:
            if (
              componentRef.current.viewer2D[viewField].x !== props.xPos ||
              componentRef.current.viewer2D[viewField].y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D[viewField].x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D[viewField].y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D[viewField] = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.Reference:
            if (
              componentRef.current.viewer2D[viewField].x !== props.xPos ||
              componentRef.current.viewer2D[viewField].y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D[viewField].x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D[viewField].y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D[viewField] = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
        }
        break;
    }
  }, [props.xPos, props.yPos]);

  const handleSelectionClick = () => {};

  const renderNode = () => {
    let assemblyName;
    let colorByAssembly;
    switch (layerMode) {
      case UnitOperationLayer.PnID:
        assemblyName = (
          <div
            className={'f-row  f2-center gap-1 max-1-lines-visible'}
            style={{ flexWrap: 'nowrap', minWidth: 200 }}>
            <Icon
              title={functionComponent.data[componentField]?.general.name}
              name="manifold"
              size={16}
            />
            <div style={{ fontSize: 12 }} className="max-1-lines-visible">
              <span style={{ fontSize: 11 }}>Func. SUA </span>
              <span>{functionComponent.data[componentField]?.general.name}</span>
            </div>
          </div>
        );
        colorByAssembly = 'var(--blue)';
        break;
      case UnitOperationLayer.Reference:
        switch (componentField) {
          case 'assembly':
            assemblyName = (
              <div
                className={'f-row f2-center gap-1 max-1-lines-visible'}
                style={{ minWidth: 180 }}>
                <Icon
                  title={functionComponent.data.assembly?.general.name}
                  name="manifold"
                  size={16}
                />
                <div style={{ fontSize: 12 }} className="max-1-lines-visible">
                  <span style={{ fontSize: 11 }}>Func. SUA </span>
                  <span>{functionComponent.data.assembly?.general.name}</span>
                </div>
              </div>
            );
            colorByAssembly = 'var(--blue)';
            break;
          case 'assemblyReference':
            assemblyName = (
              <div
                className={'f-row f2-center gap-1 w-100 max-1-lines-visible '}
                style={{
                  fontSize: 14,
                  minWidth: 200
                }}>
                <Icon
                  title={functionComponent.data.assemblyReference?.general.name}
                  name="label"
                  size={14}
                />
                <div
                  className="max-1-lines-visible"
                  style={{
                    fontSize: 12
                  }}>
                  <span
                    style={{
                      fontSize: 10
                    }}>
                    Ref. {''}
                  </span>
                  <span>{functionComponent.data.assemblyReference?.general.name}</span>
                </div>
              </div>
            );
            colorByAssembly = 'var(--blue)';
            break;
        }
        break;
    }
    return (
      <div
        onClick={handleSelectionClick}
        style={{
          height: component?.viewer2D?.size.y,
          width: component?.viewer2D?.size.x,
          border: '3px solid',
          borderColor: colorByAssembly,
          position: 'relative',
          cursor: 'pointer'
        }}>
        <div
          className="f-row absolute f1-center f2-center gap-1"
          style={{
            top: -30,
            fontSize: 24,
            overflowY: 'visible'
          }}>
          {/*  {layerMode === UnitOperationLayer.PnID && CUSTOM_FUNCTIONS[functionComponent.data.type].form(26)} */}
          <div
            className="max-1-lines-visible"
            title={functionComponent.data[componentField]?.general.name}>
            {assemblyName}
          </div>
        </div>
      </div>
    );
  };

  function rotateComponent(componentToMove, centerSUA, angle) {
    var xM, yM, x, y;
    angle *= Math.PI / 180;
    xM = componentToMove.x - centerSUA.x;
    yM = componentToMove.y - centerSUA.y;
    x = xM * Math.cos(angle) + yM * Math.sin(angle) + centerSUA.x;
    y = -xM * Math.sin(angle) + yM * Math.cos(angle) + centerSUA.y;
    return { x: Math.round(x), y: Math.round(y) };
  }

  const onComponentRotated = (component: any) => {
    const ROTATE_BY = 90;
    const newRotate = ((component.viewer2D.rotate ?? 0) + ROTATE_BY) % 360;
    component.viewer2D = { ...component.viewer2D, rotate: newRotate };
    dispatch(assemblyEditorSliceActions.updateComponent(component));
  };

  const onComponentSUARotated = () => {
    const assemblyComponents = components.filter((component: any) => {
      return component.data[componentField]?.component === functionComponent.id;
    });

    const componentGeneric = assemblyComponents.filter((c: any) => c.type === 'generic');
    var componentMaxX = componentGeneric[0].viewer2D[viewField].x as number;
    var componentMaxY = componentGeneric[0].viewer2D[viewField].y as number;
    var componentMinX = componentGeneric[0].viewer2D[viewField].x as number;
    var componentMinY = componentGeneric[0].viewer2D[viewField].y as number;
    componentGeneric.map((c: any) => {
      if (componentMaxX < c.viewer2D[viewField].x) {
        componentMaxX = c.viewer2D[viewField].x;
      }
      if (componentMaxY < c.viewer2D[viewField].y) {
        componentMaxY = c.viewer2D[viewField].y;
      }
      if (componentMinX > c.viewer2D[viewField].x) {
        componentMinX = c.viewer2D[viewField].x;
      }
      if (componentMinY > c.viewer2D[viewField].y) {
        componentMinY = c.viewer2D[viewField].y;
      }
    });

    const centre = {
      x: (componentMaxX + componentMinX) / 2,
      y: (componentMaxY + componentMinY) / 2
    };

    let componentsRefCurrentCopy = cloneDeep(componentGeneric);
    componentsRefCurrentCopy
      .filter((c: any) => c.type === 'generic')
      .forEach((component) => {
        const pidCoordinates = rotateComponent(component.viewer2D[viewField], centre, -90);
        component.viewer2D[viewField].x = pidCoordinates.x;
        component.viewer2D[viewField].y = pidCoordinates.y;
        onComponentRotated(component);
        component.viewer2D[viewField] = { ...pidCoordinates };
      });

    frameSUARotate(componentsRefCurrentCopy);
  };

  const frameSUARotate = (componentsRefCurrentCopy: any) => {
    const assemblyComponents = componentsRefCurrentCopy.filter((component: any) => {
      return component.data[componentField]?.component === functionComponent.id;
    });

    const componentGeneric = assemblyComponents.filter((c: any) => c.type === 'generic');
    const componentGenericAfterRotation = assemblyComponents.filter(
      (c: any) => c.type === 'generic'
    );

    var componentMaxXAfterRotation = componentGeneric[0].viewer2D[viewField].x as number;
    var componentMaxYAfterRotation = componentGeneric[0].viewer2D[viewField].y as number;
    var componentMinXAfterRotation = componentGeneric[0].viewer2D[viewField].x as number;
    var componentMinYAfterRotation = componentGeneric[0].viewer2D[viewField].y as number;
    componentGenericAfterRotation.map((c: any) => {
      if (componentMaxXAfterRotation < c.viewer2D[viewField].x) {
        componentMaxXAfterRotation = c.viewer2D[viewField].x;
      }
      if (componentMaxYAfterRotation < c.viewer2D[viewField].y) {
        componentMaxYAfterRotation = c.viewer2D[viewField].y;
      }
      if (componentMinXAfterRotation > c.viewer2D[viewField].x) {
        componentMinXAfterRotation = c.viewer2D[viewField].x;
      }
      if (componentMinYAfterRotation > c.viewer2D[viewField].y) {
        componentMinYAfterRotation = c.viewer2D[viewField].y;
      }
    });

    const positionTopLeft = {
      pfd: {
        x: componentMinXAfterRotation - 10,
        y: componentMinYAfterRotation - 10
      },
      pid: {
        x: componentMinXAfterRotation - 10,
        y: componentMinYAfterRotation - 10
      },
      reference: {
        x: componentMinXAfterRotation - 10,
        y: componentMinYAfterRotation - 10
      }
    };
    const positionBottomRight = {
      x: componentMaxXAfterRotation + 90,
      y: componentMaxYAfterRotation + 90
    };
    const nodeSize = {
      x: positionBottomRight.x - positionTopLeft[viewField].x,
      y: positionBottomRight.y - positionTopLeft[viewField].y
    };

    var selectionNode = components.find(
      (c) => c.type === 'selection' && c.data.component === functionComponent.id
    );

    const newSelectionNode = {
      id: Uuidv4(),
      type: 'selection',
      viewer2D: { ...positionTopLeft, size: nodeSize },
      data: {
        type: 'selection',
        component: functionComponent.id
      }
    };
    componentRef.current = newSelectionNode;
    dispatch(assemblyEditorSliceActions.addComponent(newSelectionNode));
    dispatch(assemblyEditorSliceActions.removeComponents([selectionNode]));
  };

  function flipHorizontaleComponent(component, centre) {
    let x1, x2, distance, result;
    x1 = component.viewer2D[viewField].x;
    x2 = component.viewer2D[viewField].x + component.viewer2D.size;
    distance = centre.x - x2;
    let result1 = centre.x - component.viewer2D[viewField].x;
    result = centre.x + result1;
    return { x: result, y: component.viewer2D[viewField].y };
  }

  function flipVerticalComponent(component, centre) {
    let y1, y2, result, differenceY1, differenceY2;
    y1 = component.viewer2D[viewField].y; // point zéro du composant en Y
    y2 = component.viewer2D[viewField].y + component.viewer2D.size; // le bout de la ligne en Y
    differenceY1 = centre.y - y1;
    differenceY2 = y2 - centre.y;
    result = centre.y + differenceY1;
    return { x: component.viewer2D[viewField].x, y: result };
  }

  const onComponentSuaHorizontaleFlip = () => {
    const assemblyComponents = components.filter((component: any) => {
      return component.data[componentField]?.component === functionComponent.id;
    });

    const componentsGeneric = assemblyComponents.filter((c: any) => c.type === 'generic');
    var componentMaxX = componentsGeneric[0].viewer2D[viewField].x as number;
    var componentMaxY = componentsGeneric[0].viewer2D[viewField].y as number;
    var componentMinX = componentsGeneric[0].viewer2D[viewField].x as number;
    var componentMinY = componentsGeneric[0].viewer2D[viewField].y as number;
    componentsGeneric.map((c: any) => {
      if (componentMaxX < c.viewer2D[viewField].x) {
        componentMaxX = c.viewer2D[viewField].x;
      }
      if (componentMaxY < c.viewer2D[viewField].y) {
        componentMaxY = c.viewer2D[viewField].y;
      }
      if (componentMinX > c.viewer2D[viewField].x) {
        componentMinX = c.viewer2D[viewField].x;
      }
      if (componentMinY > c.viewer2D[viewField].y) {
        componentMinY = c.viewer2D[viewField].y;
      }
    });

    const centre = {
      x: (componentMaxX + componentMinX) / 2,
      y: (componentMaxY + componentMinY) / 2
    };

    let componentsGenericCopy = cloneDeep(componentsGeneric);
    componentsGenericCopy
      .filter((c: any) => c.type === 'generic')
      .forEach((component) => {
        const pidCoordonates = flipHorizontaleComponent(component, centre); //*
        //const pidCoordinates = rotateComponent(component.viewer2D.pid, centre, -90);
        component.viewer2D[viewField].x = pidCoordonates.x;
        component.viewer2D[viewField].y = pidCoordonates.y;
        onComponentHorizontalFlip(component);
        component.viewer2D[viewField] = { ...pidCoordonates };
      });
  };

  const onComponentSuaVerticaleFlip = () => {
    const assemblyComponents = components.filter((component: any) => {
      return component.data[componentField]?.component === functionComponent.id;
    });

    const componentsGeneric = assemblyComponents.filter((c: any) => c.type === 'generic');
    var componentMaxX = componentsGeneric[0].viewer2D[viewField].x as number;
    var componentMaxY = componentsGeneric[0].viewer2D[viewField].y as number;
    var componentMinX = componentsGeneric[0].viewer2D[viewField].x as number;
    var componentMinY = componentsGeneric[0].viewer2D[viewField].y as number;
    componentsGeneric.map((c: any) => {
      if (componentMaxX < c.viewer2D[viewField].x) {
        componentMaxX = c.viewer2D[viewField].x;
      }
      if (componentMaxY < c.viewer2D[viewField].y) {
        componentMaxY = c.viewer2D[viewField].y;
      }
      if (componentMinX > c.viewer2D[viewField].x) {
        componentMinX = c.viewer2D[viewField].x;
      }
      if (componentMinY > c.viewer2D[viewField].y) {
        componentMinY = c.viewer2D[viewField].y;
      }
    });

    const centre = {
      x: (componentMaxX + componentMinX) / 2,
      y: (componentMaxY + componentMinY) / 2
    };

    let componentsGenericCopy = cloneDeep(componentsGeneric);
    componentsGenericCopy
      .filter((c: any) => c.type === 'generic')
      .forEach((component) => {
        const pidCoordonates = flipVerticalComponent(component, centre);
        component.viewer2D[viewField].x = pidCoordonates.x;
        component.viewer2D[viewField].y = pidCoordonates.y;
        onComponentVerticalFlip(component);
        component.viewer2D[viewField] = { ...pidCoordonates };
      });
  };

  const renderActionButtons = () => {
    return (
      <div className="f-row f2-center p-1 gap-2">
        <Icon
          className="p-1"
          onClick={onComponentSUARotated}
          name="rotate"
          title={intl.formatMessage(actionMessages.rotate)}
          pointer
          style={{
            lineHeight: 0.5,
            transform: 'scaleX(-1)'
          }}
        />

        <Icon
          className="p-1"
          onClick={onComponentSuaHorizontaleFlip}
          name="flip-horizontal"
          title={intl.formatMessage(actionMessages.flipHorizontal)}
          pointer
          size={13}
          style={{
            lineHeight: 0.5
          }}
        />

        <Icon
          className="p-1"
          onClick={onComponentSuaVerticaleFlip}
          name="flip-vertical"
          title={intl.formatMessage(actionMessages.flipVertical)}
          pointer
          size={13}
          style={{
            lineHeight: 0.5
          }}
        />
      </div>
    );
  };

  const renderSelectedSUA = () => {
    return (
      <div
        className="f-row"
        style={{
          borderRadius: 2,
          boxShadow: '0px 1px 5px 1px rgba(192,192,192, .5)',
          position: 'absolute',
          backgroundColor: 'white',
          bottom: -40,
          left: '50%',
          transform: 'translateX(-50%)'
        }}>
        {renderActionButtons()}
      </div>
    );
  };

  const renderAncillaryFunctions = () => {
    if (functionComponent?.data?.ancillaryFunctions?.[0].complexParameters) {
      return (
        <div
          className="f-col absolute gap-1"
          style={{
            left: -26,
            top: 6,
            fontSize: 14,
            width: 50
          }}>
          {functionComponent?.data?.ancillaryFunctions?.[0].complexParameters?.map((fct) => {
            return (
              <div className="f-row gap-2 f2-center">
                <div>{CUSTOM_FUNCTIONS[fct]?.form(20)}</div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  if (component === undefined) {
    return null;
  }
  return (
    <div style={{ position: 'relative', pointerEvents: 'all' }}>
      <div
        style={{
          position: 'relative',
          transform: `rotate(${rotate}deg)`
        }}>
        {renderAncillaryFunctions()}
        {renderNode()}
        {renderSelectedSUA()}
      </div>
    </div>
  );
};

export default memo(SelectionNode);
