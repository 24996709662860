import { NodePropertyAnchorAdd } from '../AnchorPropertyDisplay/NodePropertyAnchorAdd';
import { NodePropertyAnchor } from '../AnchorPropertyDisplay/NodePropertyAnchor';
import { AnchorDragEditor } from '../AnchorPropertyDisplay/AnchorDragEditor';
import { useAppSelector } from '../../../../../store';
import { useEffect, useRef, useState } from 'react';
import { ViewMode } from '../../../../../constants/ViewMode';
import { EditorMode } from '../../../../../constants/EditorMode';
import { CubeLoader } from 'hakobio-react-ui';

export function NodeArrayComponent({
  //   displayParameters,
  keyProperty,
  property,
  schema,
  selectedComponent
}: {
  //   displayParameters: any;
  keyProperty: string;
  property: any;
  schema: any;
  selectedComponent: any;
}) {
  const customComponentViewOnly = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentViewOnly
  );
  const isSavingScreenshotComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotComponent
  );
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [_selectedComponent, setSelectedComponent] = useState(null) as any;
  const [_reloadSelected, setReloadSelected] = useState<boolean>(false);
  const typeItemFull = property.items.$ref.split('/');
  const typeItem = typeItemFull[typeItemFull.length - 1];
  const subSchema = schema.$defs?.[typeItem];

  useEffect(() => {
    if (selectedComponent) {
      setSelectedComponent(JSON.parse(JSON.stringify(selectedComponent)));
    }
  }, [selectedComponent]);

  useEffect(() => {
    if (_reloadSelected) {
      setSelectedComponent(JSON.parse(JSON.stringify(selectedComponent)));
      setReloadSelected(false);
    }
  }, [_reloadSelected]);

  const updateComponent = () => {
    setReloadSelected(true);
  };

  if (_selectedComponent) {
    return (
      <div className="f-full f-col" style={{ overflowY: 'auto', alignItems: 'stretch' }}>
        {typeItem === 'anchor' && (
          <>
            {!customComponentViewOnly && (
              <>
                <NodePropertyAnchorAdd
                  keyProperty={keyProperty}
                  onClick={null}
                  selectedComponent={_selectedComponent}
                />
              </>
            )}

            <div className="py-1">
              {_selectedComponent.data?.anchors?.map((item: any, index: number) => {
                return (
                  <NodePropertyAnchor
                    key={item.id}
                    subSchema={subSchema}
                    item={item}
                    keyProperty={keyProperty}
                    index={index}
                    property={property}
                  />
                );
              })}
            </div>
          </>
        )}
        {typeItem === 'instrumentationPort' && (
          <div className="py-1">
            {_selectedComponent.data?.instrumentationPorts?.map((item: any, index: number) => {
              return (
                <NodePropertyAnchor
                  key={item.id}
                  subSchema={subSchema}
                  item={item}
                  keyProperty={keyProperty}
                  index={index}
                  property={property}
                />
              );
            })}
          </div>
        )}
        {typeItem === 'samplingPort' && (
          <div className="py-1">
            {_selectedComponent?.data?.samplingPorts?.map((item: any, index: number) => {
              return (
                <NodePropertyAnchor
                  key={item.id}
                  subSchema={subSchema}
                  item={item}
                  keyProperty={keyProperty}
                  index={index}
                  property={property}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  } else {
    return <div>Schema incomplete</div>;
  }
}
