import { useEffect, useState } from 'react';
import { CUSTOM_NODES } from '../../constants/CUSTOM_NODES';
import { useAssemblyEditorAction } from '../../store/features/assemblyEditor/useAssemblyEditorAction';
import ItemChange from './ItemChange';

const ComponentAttributs = ({ difference }) => {
  const { setHoveredComponent, unsetHoveredComponent } = useAssemblyEditorAction();
  const [_schema, setSchema] = useState<any | null>(null);
  const getSchemaAsync = async () => {
    try {
      const schema = await CUSTOM_NODES[difference.type].schema();
      setSchema(schema);
    } catch (error) {
      setSchema(null);
    }
  };
  useEffect(() => {
    getSchemaAsync();
  }, []);

  return (
    <div className="hover-item ">
      <div key={difference.id} className="font-regular pl-3">
        {CUSTOM_NODES[difference.type]?.name}
      </div>
      {difference.changes.map((change) => {
        return (
          <div
            className="py-1 pl-3"
            key={change.key}
            onMouseEnter={() => {
              setHoveredComponent(difference.id);
            }}
            onMouseLeave={() => {
              unsetHoveredComponent('');
            }}>
            <ItemChange
              name={_schema?.properties[change.key]?.name}
              viewingValue={`${change.viewingValue}${
                _schema?.properties[change.key]?.units && change.viewingValue !== 'N/C'
                  ? ' ' + _schema?.properties[change.key].units
                  : ''
              }`}
              comparingValue={`${change.comparingValue}${
                _schema?.properties[change.key]?.units && change.comparingValue !== 'N/C'
                  ? ' ' + _schema?.properties[change.key].units
                  : ''
              }`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ComponentAttributs;
