import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';

import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import ComponentAttributs from './ComponentAttributs';

const ComponentsChangeArray = ({
  title,
  viewingComponents,
  comparingComponents,
  setHasDifferences
}) => {
  const [_differences, setDifferences] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  const compareLists = (viewingAComponents, comparingAComponents) => {
    let result = [];
    viewingAComponents.forEach((viewingComponent) => {
      comparingAComponents.forEach((comparingComponent) => {
        if (
          viewingComponent.id === comparingComponent.id &&
          !isEqual(viewingComponent, comparingComponent)
        ) {
          let changes = [];
          for (let key in viewingComponent.data) {
            if (
              key !== 'anchors' &&
              key !== 'instrumentationPorts' &&
              key !== 'samplingPorts' &&
              key !== 'idDrag'
            ) {
              if (viewingComponent.data[key] !== comparingComponent.data[key]) {
                changes.push({
                  key: key,
                  viewingValue: viewingComponent.data[key] || 'N/C',
                  comparingValue: comparingComponent.data[key] || 'N/C'
                });
              }
            }
          }

          // check for missing keys in the viewingComponent array
          for (let key in comparingComponent.data) {
            if (
              !viewingComponent.data.hasOwnProperty(key) &&
              comparingComponent.data[key] !== '' &&
              key !== 'idDrag'
            ) {
              changes.push({
                key: key,
                viewingValue: 'N/C',
                comparingValue: comparingComponent.data[key] || 'N/C'
              });
            }
          }

          // compare anchors array
          // if (viewingComponent?.data?.anchors && comparingComponent?.data?.anchors) {
          //   viewingComponent.data.anchors.forEach((viewingAnchor) => {
          //     comparingComponent.data.anchors.forEach((comparingAnchor) => {
          //       if (viewingAnchor.id === comparingAnchor.id) {
          //         for (let key in viewingAnchor.data) {
          //           if (viewingAnchor.data[key] !== comparingAnchor.data[key]) {
          //             changes.push({
          //               anchorId: viewingAnchor.id,
          //               key: key,
          //               viewingValue: viewingAnchor.data[key] || 'N/C',
          //               comparingValue: comparingAnchor.data[key] || 'N/C'
          //             });
          //           }
          //         }
          //       }
          //     });
          //   });

          //   // check for missing anchor in viewingAnchor array
          //   comparingComponent.data.anchors.forEach((comparingAnchor) => {
          //     let found = false;
          //     viewingComponent.data.anchors.forEach((viewingAnchor) => {
          //       if (viewingAnchor.id === comparingAnchor.id) {
          //         found = true;
          //       }
          //     });
          //     if (!found) {
          //       for (let key in comparingAnchor.data) {
          //         changes.push({
          //           anchorId: comparingAnchor.id,
          //           key: key,
          //           viewingValue: 'N/C',
          //           comparingValue: comparingAnchor.data[key] || 'N/C'
          //         });
          //       }
          //     }
          //   });
          // }
          // check for missing anchor in viewingAnchor array
          // comparingComponent.data.anchors.forEach((comparingAnchor) => {
          //   let found = false;
          //   viewingComponent.data.anchors.forEach((viewingAnchor) => {
          //     if (viewingAnchor.id === comparingAnchor.id) {
          //       found = true;
          //     }
          //   });
          //   if (!found) {
          //     for (let key in comparingAnchor.data) {
          //       changes.push({
          //         anchorId: comparingAnchor.id,
          //         key: key,
          //         viewingValue: 'N/C',
          //         comparingValue: comparingAnchor.data[key] || 'N/C'
          //       });
          //     }
          //   }
          // });

          if (changes.length) {
            result.push({
              id: viewingComponent.id,
              type: viewingComponent.data.type,
              changes: changes
            });
          }
        }
      });
    });
    if (result.length) {
      setDifferences(result);
      setHasDifferences(true);
      dispatch(assemblyEditorSliceActions.setListComparisonVersions(result));
    } else {
      dispatch(assemblyEditorSliceActions.setListComparisonVersions([]));
    }
  };

  useEffect(() => {
    compareLists(viewingComponents, comparingComponents);
  }, [viewingComponents, comparingComponents]);

  if (!isEqual(viewingComponents, comparingComponents) && _differences.length) {
    return (
      <div className="p-2">
        <div className="font-regular">{title}</div>
        {_differences.map((difference) => {
          return <ComponentAttributs difference={difference} />;
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default ComponentsChangeArray;
