import { ButtonFooter, Icon } from 'hakobio-react-ui';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ListTab } from '../../../constants/ListTab';
import { useAppDispatch, useAppSelector } from '../../../store';
import { DirectoryFolder } from '../../models/DirectoryFolder';
import AllAssembliesLine from './AllAssembliesLine';
import FolderLine from './FolderLine';
import {
  deleteDirectoryFolder,
  getAllDirectories,
  getUserIsAdmin
} from '../../../services/editor/editorService';
import _, { orderBy, set } from 'lodash';
import { Uuidv4 } from '../../../utilities';
import { DirectoryType } from '../../../app/web-api-client';
import { toast } from 'react-toastify';
import DeleteFolderModal from '../../../views/DeleteFolderModal';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import DefaultFolderLine from './DefaultFolderLine';
import { useDragDropManager } from 'react-dnd';

const Navigation = () => {
  const dispatch = useAppDispatch();
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const allHeadersLibrary = useAppSelector((state) => state.assemblyEditorSlice.allHeadersLibrary);
  const showDeleteFolderModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showDeleteFolderModal
  );

  const [_directoriesList, setDirectoriesList] = useState([]);
  const [_isSaving, setIsSaving] = useState(false);
  const [_isAdmin, setIsAdmin] = useState(false);
  const user = useAppSelector((state) => state.assemblyEditorSlice.user);

  const directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const dragDropManager = useDragDropManager();
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const unsubscribeDrag = dragDropManager.getMonitor().subscribeToStateChange(() => {
      const isDragInProgress = dragDropManager.getMonitor().isDragging();
      setIsDragging(isDragInProgress);
    });

    return () => {
      unsubscribeDrag();
    };
  }, [dragDropManager]);

  useEffect(() => {
    const allDirectories = async () => {
      const result = await getAllDirectories();
      dispatch(
        assemblyEditorSliceActions.setAllDirectories(
          orderBy(result, (r) => r.name.toLowerCase(), ['asc'])
        )
      );
    };
    allDirectories();
  }, []);

  useEffect(() => {
    const getRole = async () => {
      const role = await getUserIsAdmin();
      setIsAdmin(role);
    };
    getRole();
  }, []);

  // Default folders

  const assListFunc = [];
  const assListRef = [];
  for (let index = 0; index < allHeadersLibrary.length; index++) {
    const sua = allHeadersLibrary[index];
    if (sua.type === 'SingleUseAssembly') {
      assListFunc.push(sua.id);
    } else if (sua.type === 'SingleUseAssemblyReference') {
      assListRef.push(sua.id);
    }
  }

  const functionalAssembliesFolder = {
    id: 'functionalAssembliesFolder',
    name: 'Functional assemblies',
    children: assListFunc,
    shortCuts: [],
    directoryType: DirectoryType.SingleUseAssembly,
    organizationId: 1
  };

  const referenceAssembliesFolder = {
    id: 'referenceAssembliesFolder',
    name: 'Reference assemblies',
    children: assListRef,
    shortCuts: [],
    directoryType: DirectoryType.SingleUseAssembly,
    organizationId: 1
  };

  // --- end --- Default folders

  const addFolder = async () => {
    dispatch(assemblyEditorSliceActions.setEditingFolder(true));
    dispatch(assemblyEditorSliceActions.selectFolder(null));
  };

  function removeFolderFromDirectoriesList(folderId: string) {
    const newFoldersList = directoriesList.filter((f: DirectoryFolder) => f.id !== folderId);
    dispatch(assemblyEditorSliceActions.setAllDirectories(newFoldersList));
    //setDirectoriesList(newFoldersList);
  }

  const tabFolderList =
    listTab === ListTab.SUA
      ? directoriesList.filter((f: DirectoryFolder) => f.directoryType === (ListTab.SUA as number))
      : directoriesList.filter(
          (f: DirectoryFolder) => f.directoryType === (ListTab.UnitOperation as number)
        );

  const confirmFolderRemoved = async (folder: DirectoryFolder) => {
    const result = await deleteDirectoryFolder(folder.id);

    if (result) {
      removeFolderFromDirectoriesList(folder.id);
      dispatch(assemblyEditorSliceActions.setShowDeleteFolderModal(null));
      dispatch(assemblyEditorSliceActions.selectFolder(null));
      dispatch(
        assemblyEditorSliceActions.setAllDirectories(
          directoriesList.filter((f: DirectoryFolder) => f.id !== folder.id)
        )
      );

      toast.success(
        <FormattedMessage
          id="SUConfig.StartupPanel.Toast.FolderDeleted"
          defaultMessage="Folder deleted"
        />
      );
    }
  };

  function openDeleteFolderModal() {
    return <DeleteFolderModal confirmFolderRemoved={confirmFolderRemoved} />;
  }

  return (
    <div>
      <AllAssembliesLine />
      {listTab === ListTab.SUA && (
        <>
          <DefaultFolderLine folder={functionalAssembliesFolder} />
          <DefaultFolderLine folder={referenceAssembliesFolder} />
          <div className="ml-2 border-bottom w-10 mb-1" />
        </>
      )}

      {showDeleteFolderModal && openDeleteFolderModal()}
      <div
        style={{
          overflow: 'auto',
          height: `${
            listTab === ListTab.UnitOperation ? 'calc(100vh - 310px)' : 'calc(100vh - 400px)'
          }`
        }}>
        {tabFolderList.map((folder) => {
          return (
            // @ts-ignore
            <FolderLine
              key={folder.id}
              folder={folder}
              removeFolderFromDirectoriesList={removeFolderFromDirectoriesList}
              setDirectoriesList={setDirectoriesList}
              directoriesList={directoriesList}
              isAdmin={_isAdmin}
              isDragging={isDragging}
            />
          );
        })}
      </div>
      {_isAdmin && (
        <ButtonFooter arrow inverse onClick={() => addFolder()}>
          <div className="f-row gap-2 f2-center">
            <Icon name="folder" />
            <FormattedMessage id="Navigation.Button.Name" defaultMessage="New folder" />
          </div>
        </ButtonFooter>
      )}
    </div>
  );
};

export default Navigation;
