import { set, uniq } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  AdvancedSearch,
  Autocomplete,
  Chip,
  DoubleRange,
  TextInput,
} from "hakobio-react-ui";

import { useAppDispatch, useAppSelector } from "../../../store";

import { hasFiltersSet } from "./utilities/search";

import {
  availableConnectors,
  availableContainers,
  availableFunctions,
} from "./utilities/search-select-values";

import {
  configMessages,
  generalMessages,
  statusMessages,
} from "../../../lang/messages";

import { CUSTOM_FUNCTIONS } from "../../../constants/CUSTOM_FUNCTIONS";
import { CUSTOM_NODES } from "../../../constants/CUSTOM_NODES";

import { Component } from "../../models/Component";
import { Filters } from "../../models/Filters";
import { Function } from "../../models/Function";

import { EntityDisplayed } from "../../../constants/EntityDisplayed";
import { UnitOperationLayer } from "../../../constants/PFD_EquipmentTabs";
import { SUPPLIERS } from "../../../constants/SUPPLIERS";
import { assemblyEditorSliceActions } from "../../../store/features/assemblyEditor/assemblyEditorSlice";
import { GenericStatus } from "../../../utilities/statuses/genericStatus";
import { ReferenceStatus } from "../../../utilities/statuses/referenceStatus";
import AdvancedSearchFilters from "./AdvancedSearchFilters";
import { SearchTypes } from "./utilities/SearchTypes";
import { EditorMode } from "../../../constants/EditorMode";

interface AdvancedSearchComponentProps {
  setSearch: (v: string | Filters | null) => void;
  selectedComponent?: Component;
}

const AdvancedSearchComponent: React.FC<AdvancedSearchComponentProps> = (
  props,
) => {
  const dispatch = useAppDispatch();

  const assemblyTypes = ["Functional assembly", "Reference assembly"];

  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary,
  );
  const directoriesList = useAppSelector(
    (state) => state.assemblyEditorSlice.allDirectories,
  );
  const relevantDirectories = directoriesList.filter(
    (dir) => dir.directoryType === 1,
  );

  const layerMode = useAppSelector(
    (state) => state.assemblyEditorSlice.layerMode,
  );

  const entitiesDisplayed = useAppSelector(
    (state) => state.assemblyEditorSlice.entitiesDisplayed,
  );
  const editorMode = useAppSelector(
    (state) => state.assemblyEditorSlice.editorMode,
  );

  const selectedFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedFolder,
  );

  const [_search, setSearch] = useState("");
  const [_assemblyType, setAssemblyType] = useState(null);
  const [_tubingSchema, setTubingSchema] = useState(null);
  const [_sensorSchema, setSensorSchema] = useState(null);
  const [_hydrophilicFilterSchema, setHydrophilicFilterSchema] = useState(null);
  const [_suPumpHeadSchema, setSuPumpHeadSchema] = useState(null);
  const [_filters, setFilters] = useState<Filters | null>(null);
  const [_name, setName] = useState("");
  const [_createdBy, setCreatedBy] = useState("");
  const [_modifiedBy, setModifiedBy] = useState("");
  const [_parentSUA, setParentSUA] = useState<string[]>([]);
  const [_functions, setFunctions] = useState<string[]>([]);
  const [_ancillaryFunctions, setAncillaryFunctions] = useState<string[]>([]);
  const [_containers, setContainers] = useState<string[]>([]);
  const [_connectors, setConnectors] = useState<string[]>([]);
  const [_volumes, setVolumes] = useState<string[]>([]);
  const [_availableVolumes, setAvailableVolumes] = useState([]);
  const [_availableSuppliers, setAvailableSuppliers] = useState([]);
  const [_availableSuaStatus, setAvailableSuaStatus] = useState([]);
  const [_availableReferenceStatus, setAvailableReferenceStatus] = useState([]);
  const [_suaStatus, setSuaStatus] = useState([]);
  const [_referenceStatus, setReferenceStatus] = useState([]);
  const [_lines, setLines] = useState([0, 0]);
  const [_bags2D, setBags2D] = useState([0, 0]);
  const [_tubingMaterials, setTubingMaterials] = useState<string[]>([]);
  const [_tubingInnerDiameters, setTubingInnerDiameters] = useState<string[]>(
    [],
  );
  const [_tubingLength, setTubingLength] = useState<number>();
  const [_poreSizeMum, setPoreSizeMum] = useState<number>();
  const [_poreSizeMwco, setPoreSizeMwco] = useState<number>();
  const [_combinedPorosity, setCombinedPorosity] = useState<string[]>([]);
  const [_filtrationSurface, setFiltrationSurface] = useState<number>(null);
  const [_sensorTypes, setSensorTypes] = useState<string[]>([]);
  const [_suppliers, setSuppliers] = useState<string[]>([]);
  const [_pumpTypes, setPumpTypes] = useState<string[]>([]);
  const [_renderFilters, setRenderFilters] = useState(false);
  const [_folders, setFolders] = useState([]);

  const intl = useIntl();

  const availableGenericSuas = editorAssemblyLibrary
    .filter((sua: any) => sua.general.type === "SingleUseAssembly")
    .filter(
      (sua: any) =>
        sua.general.status === GenericStatus.Detailed ||
        sua.general.status === GenericStatus.Detailed ||
        sua.general.status === undefined,
    );

  const getSchemas = async () => {
    const tubingSchema = await CUSTOM_NODES["tubing"].schema();
    const sensorSchema = await CUSTOM_NODES["sensor"].schema();
    const suPumpHeadSchema = await CUSTOM_NODES["suPumpHead"].schema();
    const hydrophilicFilterSchema =
      await CUSTOM_NODES["hydrophilicFilter"].schema();
    setTubingSchema(tubingSchema);
    setSensorSchema(sensorSchema);
    setSuPumpHeadSchema(suPumpHeadSchema);
    setHydrophilicFilterSchema(hydrophilicFilterSchema);
  };

  useEffect(() => {
    if (_filters) {
      setRenderFilters(hasFiltersSet(_filters));
    }
  }, [_filters]);

  useEffect(() => {
    getSchemas();
  }, []);

  useEffect(() => {
    if (layerMode === UnitOperationLayer.PnID) {
      dispatch(
        assemblyEditorSliceActions.setEntitiesDisplayed(EntityDisplayed.SUA),
      );
    } else if (layerMode === UnitOperationLayer.Reference) {
      dispatch(
        assemblyEditorSliceActions.setEntitiesDisplayed(
          EntityDisplayed.Reference,
        ),
      );
    } else {
      dispatch(assemblyEditorSliceActions.setEntitiesDisplayed(null));
    }
  }, [layerMode]);

  useEffect(() => {
    switch (entitiesDisplayed) {
      case EntityDisplayed.SuaReference:
        break;
      case EntityDisplayed.SUA:
        const suaStatusValues: string[] = Object.keys(GenericStatus)
          .map((key) => GenericStatus[key])
          .filter((k) => !(parseInt(k) >= 0));
        suaStatusValues.forEach((v, index) => {
          suaStatusValues[index] =
            suaStatusValues[index].charAt(0).toUpperCase() +
            suaStatusValues[index].substring(1);
        });
        suaStatusValues.push("No status");
        setAvailableSuaStatus(suaStatusValues);
        break;
      case EntityDisplayed.Reference:
        const referenceStatusValues: string[] = Object.keys(ReferenceStatus)
          .map((key) => ReferenceStatus[key])
          .filter((k) => !(parseInt(k) >= 0));
        referenceStatusValues.forEach((v, index) => {
          referenceStatusValues[index] =
            referenceStatusValues[index].charAt(0).toUpperCase() +
            referenceStatusValues[index].substring(1);
        });
        setAvailableReferenceStatus(referenceStatusValues);
        break;
    }
  }, [entitiesDisplayed]);

  useEffect(() => {
    if (props.selectedComponent) {
      resetSearch();
      resetFilters();
      if (layerMode === UnitOperationLayer.PnID) {
        const functions = props.selectedComponent?.data.type
          ? [props.selectedComponent.data.type]
          : [];
        setFunctions(functions);
        const ancillaryFunctions = props.selectedComponent?.data
          ?.ancillaryFunctions?.[0]?.complexParameters
          ? props.selectedComponent?.data?.ancillaryFunctions?.[0]
              ?.complexParameters
          : [];
        setAncillaryFunctions(ancillaryFunctions);
        const filtrationSurface = props.selectedComponent?.data.componentSurface
          ? props.selectedComponent.data.componentSurface
          : 0;
        setFiltrationSurface(filtrationSurface);
        const volumes = props.selectedComponent?.data.componentVolume
          ? [props.selectedComponent.data.componentVolume]
          : [];
        setVolumes(volumes);
        const sensorTypes =
          props.selectedComponent?.data.componentType &&
          (props.selectedComponent?.data.type === "culture" ||
            props.selectedComponent?.data.type === "filtering" ||
            props.selectedComponent?.data.type === "measuring" ||
            props.selectedComponent?.data.type === "mixing" ||
            props.selectedComponent?.data.type === "pumping")
            ? [props.selectedComponent.data.componentType]
            : [];
        setSensorTypes(sensorTypes);
        setFilters({
          name: "",
          folders: [],
          createdBy: "",
          modifiedBy: "",
          assemblyType: "",
          parentSUA: [props.selectedComponent.data.assembly],
          suaStatus: [],
          referenceStatus: [],
          functions: functions,
          ancillaryFunctions: ancillaryFunctions,
          containers: [],
          connectors: [],
          tubingMaterials: [],
          tubingInnerDiameters: [],
          tubingLength: null,
          poreSizeMum: null,
          poreSizeMwco: null,
          combinedPorosity: [],
          filtrationSurface: filtrationSurface,
          volumes: volumes,
          lines: [0, 0],
          bags2D: [0, 0],
          sensorTypes: [],
          suppliers: [],
          pumpingTypes: [],
        });
      } else if (layerMode === UnitOperationLayer.Reference) {
        const parentSua = props.selectedComponent?.data.assembly
          ? [props.selectedComponent.data.assembly]
          : [];
        setParentSUA(parentSua);
        const functions = props.selectedComponent?.data.type
          ? [props.selectedComponent.data.type]
          : [];
        setFunctions(functions);

        const ancillaryFunctions = props.selectedComponent?.data
          ?.ancillaryFunctions?.[0]?.complexParameters
          ? props.selectedComponent?.data?.ancillaryFunctions?.[0]
              ?.complexParameters
          : [];
        setAncillaryFunctions(ancillaryFunctions);
        const filtrationSurface = props.selectedComponent?.data.componentSurface
          ? props.selectedComponent.data.componentSurface
          : 0;
        setFiltrationSurface(filtrationSurface);
        const volumes = props.selectedComponent?.data.componentVolume
          ? [props.selectedComponent.data.componentVolume]
          : [];
        setVolumes(volumes);
        const sensorTypes =
          props.selectedComponent?.data.componentType &&
          (props.selectedComponent?.data.type === "culture" ||
            props.selectedComponent?.data.type === "filtering" ||
            props.selectedComponent?.data.type === "measuring" ||
            props.selectedComponent?.data.type === "mixing" ||
            props.selectedComponent?.data.type === "pumping")
            ? [props.selectedComponent.data.componentType]
            : [];
        setSensorTypes(sensorTypes);
        setFilters({
          name: "",
          folders: [],
          createdBy: "",
          modifiedBy: "",
          assemblyType: "",
          parentSUA: [props.selectedComponent.data.assembly],
          suaStatus: [],
          referenceStatus: [],
          functions: functions,
          ancillaryFunctions: ancillaryFunctions,
          containers: [],
          connectors: [],
          tubingMaterials: [],
          tubingInnerDiameters: [],
          tubingLength: null,
          poreSizeMum: null,
          poreSizeMwco: null,
          combinedPorosity: [],
          filtrationSurface: filtrationSurface,
          volumes: volumes,
          lines: [0, 0],
          bags2D: [0, 0],
          sensorTypes: [],
          suppliers: [],
          pumpingTypes: [],
        });
      }
    }
  }, [props.selectedComponent]);

  useEffect(() => {
    if (editorAssemblyLibrary) {
      const volumes: string[] = [];
      editorAssemblyLibrary?.forEach((a) => {
        Object.values(a.components).forEach((comp: Component) => {
          if (comp.data.componentVolume)
            volumes.push(comp.data.componentVolume);
        });
      });
      const volumesTypeNumber = volumes.map((v) => Number(v));
      const uniqueVolumes = uniq(volumesTypeNumber);
      const sortedVolumes = uniqueVolumes.sort((a, b) => {
        return a - b;
      });
      const volumesToString = sortedVolumes.map((v) => v.toString());
      setAvailableVolumes(volumesToString);
      const suppliers = SUPPLIERS.map((s) => s.name);
      setAvailableSuppliers(suppliers);

      const suaStatusValues: string[] = Object.keys(GenericStatus)
        .map((key) => GenericStatus[key])
        .filter((k) => !(parseInt(k) >= 0));
      suaStatusValues.forEach((v, index) => {
        suaStatusValues[index] =
          suaStatusValues[index].charAt(0).toUpperCase() +
          suaStatusValues[index].substring(1);
      });
      suaStatusValues.push("No status");
      setAvailableSuaStatus(suaStatusValues);

      const referenceStatusValues: string[] = Object.keys(ReferenceStatus)
        .map((key) => ReferenceStatus[key])
        .filter((k) => !(parseInt(k) >= 0));
      referenceStatusValues.forEach((v, index) => {
        referenceStatusValues[index] =
          referenceStatusValues[index].charAt(0).toUpperCase() +
          referenceStatusValues[index].substring(1);
      });
      setAvailableReferenceStatus(referenceStatusValues);
    }
  }, [editorAssemblyLibrary]);

  useEffect(() => {
    if (
      ((selectedFolder?.id !== "functionalAssembliesFolder" &&
        selectedFolder?.id !== "referenceAssembliesFolder") ||
        !selectedFolder) &&
      _filters !== null
    ) {
      const newFilters = { ..._filters, folders: [] };
      setFilters(newFilters);
    }
  }, [selectedFolder]);

  const handleBasicSearch = (value: string) => {
    setSearch(value);
    setName(value);
    props.setSearch(value);
  };

  const resetFilters = () => {
    setFilters({
      name: "",
      createdBy: "",
      modifiedBy: "",
      assemblyType: "",
      parentSUA:
        editorMode === EditorMode.UnitOperation
          ? [props.selectedComponent?.data.assembly]
          : [],
      suaStatus: [],
      referenceStatus: [],
      functions: [],
      ancillaryFunctions: [],
      containers: [],
      connectors: [],
      tubingMaterials: [],
      tubingInnerDiameters: [],
      tubingLength: null,
      poreSizeMum: null,
      poreSizeMwco: null,
      combinedPorosity: [],
      filtrationSurface: null,
      volumes: [],
      lines: [0, 0],
      bags2D: [0, 0],
      sensorTypes: [],
      suppliers: [],
      pumpingTypes: [],
      folders: [],
    });
  };

  const resetIrrelevantFilters = (value) => {
    if (
      (_poreSizeMum || _poreSizeMwco || _combinedPorosity.length) &&
      !value.includes("filtering")
    ) {
      setPoreSizeMum(null);
      setPoreSizeMwco(null);
      setCombinedPorosity([]);
    }
    if (_filtrationSurface && !value.includes("filtering")) {
      setFiltrationSurface(null);
    }
    if (_bags2D && !value.includes("sampling")) {
      setBags2D([0, 0]);
    }
    if (
      _volumes.length &&
      !value.includes("culture") &&
      !value.includes("mixing") &&
      !value.includes("sampling") &&
      !value.includes("storage")
    ) {
      setVolumes([]);
    }
    if (
      _containers.length &&
      !value.includes("culture") &&
      !value.includes("mixing") &&
      !value.includes("storage")
    ) {
      setContainers([]);
    }
    if (
      _lines.length &&
      !value.includes("culture") &&
      !value.includes("mixing") &&
      !value.includes("storage")
    ) {
      setLines([0, 0]);
    }
    if (
      _sensorTypes.length &&
      !value.includes("culture") &&
      !value.includes("filtering") &&
      !value.includes("measuring") &&
      !value.includes("mixing") &&
      !value.includes("pumping")
    ) {
      setSensorTypes([]);
    }
  };

  const handleChangeSUAParent = (value: any) => {
    if (Array.isArray(value)) {
      setParentSUA(value);
    } else if (value === null) {
      setParentSUA([]);
    }
  };

  const handleChangeFunction = (value: string) => {
    if (Array.isArray(value)) {
      resetIrrelevantFilters(value);
      setFunctions(value);
    } else if (value === null) {
      setFunctions([]);
    }
  };

  const handleChangeAncillaryFunctions = (value: string) => {
    if (Array.isArray(value)) {
      resetIrrelevantFilters(value);
      setAncillaryFunctions(value);
    } else if (value === null) {
      setAncillaryFunctions([]);
    }
  };

  const handleChangeFolder = (newValue: any) => {
    if (Array.isArray(newValue)) {
      setFolders(newValue);
    } else if (newValue === null) {
      setFolders([]);
    }
  };

  const handleChangeSuppliers = (value: string) => {
    if (Array.isArray(value)) {
      setSuppliers(value);
    } else if (value === null) {
      setSuppliers([]);
    }
  };

  const handleChangeContainers = (value: string) => {
    if (Array.isArray(value)) {
      setContainers(value);
    } else if (value === null) {
      setContainers([]);
    }
  };
  const handleChangePumpTypes = (value: string) => {
    if (Array.isArray(value)) {
      setPumpTypes(value);
    } else if (value === null) {
      setPumpTypes([]);
    }
  };

  const handleChangeConnectors = (value: string) => {
    if (Array.isArray(value)) {
      setConnectors(value);
    } else if (value === null) {
      setConnectors([]);
    }
  };

  const handleChangeCombinedPorosity = (value: string) => {
    if (Array.isArray(value)) {
      setCombinedPorosity(value);
    } else if (value === null) {
      setCombinedPorosity([]);
    }
  };

  const handleChangeVolumes = (value: string) => {
    if (Array.isArray(value)) {
      setVolumes(value);
    } else if (value === null) {
      setVolumes([]);
    }
  };

  const handleChangeSensorTypes = (value: string) => {
    if (Array.isArray(value)) {
      setSensorTypes(value);
    } else if (value === null) {
      setSensorTypes([]);
    }
  };

  const handleChangeTubingMaterials = (value: string) => {
    if (Array.isArray(value)) {
      setTubingMaterials(value);
    } else if (value === null) {
      setTubingMaterials([]);
    }
  };

  const handleChangeTubingLengths = (value: string) => {
    const numberValue = Number(value);
    if (numberValue >= 1 && numberValue <= 10000) {
      setTubingLength(numberValue);
    }
    if (numberValue === 0) {
      setTubingLength(null);
    }
  };

  const handleChangeLines = (value: number[]) => {
    setLines(value);
  };

  const handleChangeBags2D = (value: number[]) => {
    setBags2D(value);
  };

  const handlePoreSizeMumChange = (value: string) => {
    const numberValue = Number(value);
    if (numberValue >= 1 && numberValue <= 30) {
      setPoreSizeMum(numberValue);
    }
    if (numberValue === 0) {
      setPoreSizeMum(null);
    }
  };

  const handlePoreSizeMwcoChange = (value: string) => {
    const numberValue = Number(value);
    if (numberValue >= 1 && numberValue <= 1000) {
      setPoreSizeMwco(numberValue);
    }
    if (numberValue === 0) {
      setPoreSizeMwco(null);
    }
  };

  const handleFiltrationSurfaceChange = (value: string) => {
    const numberValue = Number(value);
    if (numberValue >= 1 && numberValue <= 30) {
      setFiltrationSurface(numberValue);
    }
    if (numberValue === 0) {
      setFiltrationSurface(null);
    }
  };

  const handleChangeTubingInnerDiameters = (value: string) => {
    if (Array.isArray(value)) {
      setTubingInnerDiameters(value);
    } else if (value === null) {
      setTubingInnerDiameters([]);
    }
  };

  const onDeleteChip = (
    value: string[] | string | number | any,
    target: string,
  ) => {
    switch (target) {
      case SearchTypes.Function: {
        const newArray = _functions.filter((el) => el !== value);
        resetIrrelevantFilters(newArray);
        setFunctions(newArray);
        break;
      }
      case SearchTypes.AncillaryFunction: {
        const newArray = _ancillaryFunctions.filter((el) => el !== value);
        resetIrrelevantFilters(newArray);
        setAncillaryFunctions(newArray);
        break;
      }
      case SearchTypes.ParentSUA: {
        const newArray = _parentSUA.filter(
          (el: any) => el.general.id !== value.general.id,
        );
        setParentSUA(newArray);
        break;
      }
      case SearchTypes.Status: {
        const isSUAStatus = _suaStatus.find((el) => el === value);
        if (isSUAStatus) {
          const newArray = _suaStatus.filter((el) => el !== value);
          setSuaStatus(newArray);
        } else {
          const newArray = _referenceStatus.filter((el) => el !== value);
          setReferenceStatus(newArray);
        }
        break;
      }
      case SearchTypes.Connection: {
        const newArray = _connectors.filter((el) => el !== value);
        setConnectors(newArray);
        break;
      }
      case SearchTypes.TubingMaterial: {
        const newArray = _tubingMaterials.filter((el) => el !== value);
        setTubingMaterials(newArray);
        break;
      }
      case SearchTypes.TubingDiameter: {
        const newArray = _tubingInnerDiameters.filter((el) => el !== value);
        setTubingInnerDiameters(newArray);
        break;
      }
      case SearchTypes.Pumping: {
        const newArray = _pumpTypes.filter((el) => el !== value);
        setPumpTypes(newArray);
        break;
      }
      case SearchTypes.Container: {
        const newArray = _containers.filter((el) => el !== value);
        setContainers(newArray);
        break;
      }
      case SearchTypes.Volume: {
        const newArray = _volumes.filter((el) => el !== value);
        setVolumes(newArray);
        break;
      }
      case SearchTypes.Sensor: {
        const newArray = _sensorTypes.filter((el) => el !== value);
        setSensorTypes(newArray);
        break;
      }
      case SearchTypes.Supplier: {
        const newArray = _suppliers.filter((el) => el !== value);
        setSuppliers(newArray);
        break;
      }
      case SearchTypes.CombinedPorosity: {
        const newArray = _combinedPorosity.filter((el) => el !== value);
        setCombinedPorosity(newArray);
        break;
      }
      case SearchTypes.Folders: {
        const newArray = _folders.filter(
          (el: { id: string }) => el.id !== value,
        );
        setFolders(newArray);
        break;
      }
    }
  };

  const onSearch = () => {
    const searchFilters = {
      name: _name,
      folders: _folders,
      createdBy: _createdBy,
      modifiedBy: _modifiedBy,
      assemblyType: _assemblyType,
      parentSUA: _parentSUA,
      suaStatus: _suaStatus,
      referenceStatus: _referenceStatus,
      functions: _functions,
      ancillaryFunctions: _ancillaryFunctions,
      containers: _containers,
      connectors: _connectors,
      tubingMaterials: _tubingMaterials,
      tubingInnerDiameters: _tubingInnerDiameters,
      tubingLength: _tubingLength,
      poreSizeMum: _poreSizeMum,
      poreSizeMwco: _poreSizeMwco,
      combinedPorosity: _combinedPorosity,
      filtrationSurface: _filtrationSurface,
      volumes: _volumes,
      lines: _lines,
      bags2D: _bags2D,
      sensorTypes: _sensorTypes,
      suppliers: _suppliers,
      pumpingTypes: _pumpTypes,
    };
    setFilters(searchFilters);
    props.setSearch(searchFilters);
  };

  const resetSearch = () => {
    setName("");
    setFolders([]);
    setCreatedBy("");
    setModifiedBy("");
    setParentSUA([]);
    setFilters(null);
    setAssemblyType("");
    setFunctions([]);
    setAncillaryFunctions([]);
    setPoreSizeMum(null);
    setPoreSizeMwco(null);
    setCombinedPorosity([]);
    setFiltrationSurface(null);
    setContainers([]);
    setConnectors([]);
    setTubingMaterials([]);
    setTubingInnerDiameters([]);
    setTubingLength(null);
    setVolumes([]);
    setLines([0, 0]);
    setBags2D([0, 0]);
    setSensorTypes([]);
    setPumpTypes([]);
    setSuppliers([]);
    setSuaStatus([]);
    setReferenceStatus([]);
    setSearch("");
  };

  const onClear = () => {
    resetSearch();
    resetFilters();
    props.setSearch(null);
  };

  const onOpen = () => {
    if (_search) {
      setName(_search);
      setSearch("");
    } else if (_name) {
      setSearch(_name);
    }
  };

  //#region Advanced Fields region
  const advancedFields = (
    <div
      className="p-2 overflow-y-auto mb-1"
      style={{ maxHeight: "calc(100vh - 200px)" }}
    >
      {/* NAME */}
      <div className="f-row gap-1 f2-end">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(generalMessages.name)}
        </div>
        <div className="w-70">
          <TextInput
            variant="standard"
            value={_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
      {/* Folders */}
      {(!selectedFolder ||
        (selectedFolder &&
          (selectedFolder.id === "functionalAssembliesFolder" ||
            selectedFolder.id === "referenceAssembliesFolder"))) && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(generalMessages.folders)}
          </div>
          <div className="w-70">
            <Autocomplete
              id="autcomplete-folders"
              className="mt-2 mb-1"
              variant="standard"
              multiple
              options={relevantDirectories}
              value={_folders}
              getOptionLabel={(option: any) => option.name}
              renderOption={(props: any, option: any, { selected }) => (
                <li
                  {...props}
                  className="f-row f2-center gap-2 px-2 py-1 li-autocomplete"
                  style={{
                    backgroundColor: selected ? "var(--grey)" : "transparent",
                  }}
                >
                  <div>{option.name}</div>
                </li>
              )}
              onChange={(event, newValue) => {
                handleChangeFolder(newValue);
              }}
              renderTags={(_directories: any) =>
                _directories.map((d, index) => {
                  if (d && d.id && d.name) {
                    return (
                      <Chip
                        onDelete={() => onDeleteChip(d.id, SearchTypes.Folders)}
                        label={<span className="capitalize">{d.name}</span>}
                        key={`${d.id}-${index}`}
                      />
                    );
                  } else {
                    return null;
                  }
                })
              }
            />
          </div>
        </div>
      )}
      {/* Created By */}
      <div className="f-row gap-1 f2-end">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(generalMessages.createdBy)}
        </div>
        <div className="w-70">
          <TextInput
            variant="standard"
            value={_createdBy}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCreatedBy(e.target.value)
            }
          />
        </div>
      </div>
      {/* Modified By */}
      <div className="f-row gap-1 f2-end">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(generalMessages.modifiedBy)}
        </div>
        <div className="w-70">
          <TextInput
            variant="standard"
            value={_modifiedBy}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setModifiedBy(e.target.value)
            }
          />
        </div>
      </div>
      {/* Assembly Type */}
      {entitiesDisplayed === EntityDisplayed.SuaReference && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.assemblyType)}
          </div>
          <div className="w-70">
            <Autocomplete
              className="mt-2 mb-1"
              variant="standard"
              options={assemblyTypes}
              value={_assemblyType}
              onChange={(event, newValue) => {
                if (newValue?.length) {
                  setAssemblyType(newValue);
                  setSuaStatus([]);
                  setReferenceStatus([]);
                } else {
                  setAssemblyType(null);
                }
              }}
            />
          </div>
        </div>
      )}
      {/* Status */}
      {(_assemblyType === "Reference assembly" ||
        _assemblyType === "Functional assembly" ||
        entitiesDisplayed === EntityDisplayed.SUA ||
        entitiesDisplayed === EntityDisplayed.Reference) && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(statusMessages.status)}
          </div>
          <div className="w-70">
            <Autocomplete
              className="mt-2 mb-1"
              variant="standard"
              multiple
              options={
                _assemblyType === "Functional assembly" ||
                entitiesDisplayed === EntityDisplayed.SUA
                  ? _availableSuaStatus
                  : _availableReferenceStatus
              }
              value={
                _assemblyType === "Functional assembly" ||
                entitiesDisplayed === EntityDisplayed.SUA
                  ? _suaStatus
                  : _referenceStatus
              }
              onChange={(event, newValue) => {
                if (Array.isArray(newValue)) {
                  _assemblyType === "Functional assembly" ||
                  entitiesDisplayed === EntityDisplayed.SUA
                    ? setSuaStatus(newValue)
                    : setReferenceStatus(newValue);
                } else if (newValue === null) {
                  _assemblyType === "Functional assembly" ||
                  entitiesDisplayed === EntityDisplayed.SUA
                    ? setSuaStatus([])
                    : setReferenceStatus([]);
                }
              }}
              renderTags={(statusTorender: string[]) =>
                statusTorender.map((s, index) => {
                  return (
                    <Chip
                      onDelete={() => onDeleteChip(s, SearchTypes.Status)}
                      label={
                        <div className="f-row f2-center gap-1">
                          <span className="capitalize">{s}</span>
                        </div>
                      }
                      key={`${s}-${index}`}
                    />
                  );
                })
              }
            />
          </div>
        </div>
      )}
      {/* Parent SUA */}
      {(_assemblyType === "Reference assembly" ||
        entitiesDisplayed === EntityDisplayed.Reference ||
        layerMode === UnitOperationLayer.Reference) && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.functionalSUA)}
          </div>
          <div className="w-70">
            <Autocomplete
              className="mt-2 mb-1"
              variant="standard"
              multiple
              options={availableGenericSuas}
              value={_parentSUA}
              getOptionLabel={(option: any) => option.general.name}
              renderOption={(props: any, option: any, { selected }) => {
                return (
                  <li
                    {...props}
                    key={`option-${option.general.id}`}
                    className="f-row f2-center gap-2 px-2 py-1 li-autocomplete"
                    style={{
                      backgroundColor: selected ? "var(--grey)" : "transparent",
                    }}
                  >
                    <div className="max-lines-1">{option.general.name}</div>
                  </li>
                );
              }}
              onChange={(event, newValue) => {
                handleChangeSUAParent(newValue);
              }}
              renderTags={(_parentSUA: any[]) =>
                _parentSUA.map((p: any, index: number) => {
                  return (
                    <Chip
                      onDelete={() => onDeleteChip(p, SearchTypes.ParentSUA)}
                      label={
                        <div className="max-lines-1">{p?.general.name}</div>
                      }
                      key={`parent-tag-${index}`}
                    />
                  );
                })
              }
            />
          </div>
        </div>
      )}

      {/* Functions */}
      <div className="f-row gap-1 f2-end">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(configMessages.function)}
        </div>
        <div className="w-70">
          <Autocomplete
            className="mt-2 mb-1"
            variant="standard"
            multiple
            options={availableFunctions}
            value={_functions}
            getOptionLabel={(option) => CUSTOM_FUNCTIONS[option].name}
            renderOption={(props: any, option: string, { selected }) => (
              <li
                {...props}
                className="f-row f2-center gap-2 px-2 py-1 li-autocomplete"
                style={{
                  backgroundColor: selected ? "var(--grey)" : "transparent",
                }}
              >
                <div className="pt-1">{CUSTOM_FUNCTIONS[option]?.form(20)}</div>
                <div>{CUSTOM_FUNCTIONS[option]?.name}</div>
              </li>
            )}
            onChange={(event, newValue) => {
              handleChangeFunction(newValue);
            }}
            renderTags={(_functions: string[]) =>
              _functions.map((f, index) => {
                const fct: any = Object.values(CUSTOM_FUNCTIONS).find(
                  (value: Function) =>
                    CUSTOM_FUNCTIONS[f].name.toLowerCase() ===
                    value.name.toLowerCase(),
                );
                return (
                  <Chip
                    onDelete={() => onDeleteChip(f, SearchTypes.Function)}
                    label={
                      <div className="f-row f2-center gap-1">
                        <div style={{ marginTop: 1 }}>{fct.form(14)}</div>
                        <span>{fct.name}</span>
                      </div>
                    }
                    key={`${f}-${index}`}
                  />
                );
              })
            }
          />
        </div>
      </div>

      {/* Ancillary Functions */}
      <div className="f-row gap-1 f2-end">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(configMessages.ancillaryFunctions)}
        </div>
        <div className="w-70">
          <Autocomplete
            className="mt-2 mb-1"
            variant="standard"
            multiple
            options={availableFunctions}
            value={_ancillaryFunctions}
            getOptionLabel={(option) => CUSTOM_FUNCTIONS[option].name}
            renderOption={(props: any, option: string, { selected }) => (
              <li
                {...props}
                className="f-row f2-center gap-2 px-2 py-1 li-autocomplete"
                style={{
                  backgroundColor: selected ? "var(--grey)" : "transparent",
                }}
              >
                <div className="pt-1">{CUSTOM_FUNCTIONS[option]?.form(20)}</div>
                <div>{CUSTOM_FUNCTIONS[option]?.name}</div>
              </li>
            )}
            onChange={(event, newValue) => {
              handleChangeAncillaryFunctions(newValue);
            }}
            renderTags={(_ancillaryFunctions: string[]) =>
              _ancillaryFunctions.map((f, index) => {
                const fct: any = Object.values(CUSTOM_FUNCTIONS).find(
                  (value: Function) =>
                    CUSTOM_FUNCTIONS[f]?.name.toLowerCase() ===
                    value.name.toLowerCase(),
                );

                return (
                  <Chip
                    onDelete={() =>
                      onDeleteChip(f, SearchTypes.AncillaryFunction)
                    }
                    label={
                      <div className="f-row f2-center gap-1">
                        <div style={{ marginTop: 1 }}>{fct.form(14)}</div>
                        <span>{fct.name}</span>
                      </div>
                    }
                    key={`${f}-${index}`}
                  />
                );
              })
            }
          />
        </div>
      </div>

      {/* SUPPLIERS */}
      {(entitiesDisplayed === EntityDisplayed.Reference ||
        _assemblyType === "Reference assembly" ||
        layerMode === UnitOperationLayer.Reference) && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.suppliers)}
          </div>
          <div className="w-70">
            <Autocomplete
              className="mt-2 mb-1"
              variant="standard"
              multiple
              options={_availableSuppliers}
              value={_suppliers}
              onChange={(event, newValue) => {
                //@ts-ignore
                handleChangeSuppliers(newValue);
              }}
              renderTags={(_suppliers: string[]) =>
                _suppliers.map((comp, index) => {
                  return (
                    <Chip
                      onDelete={() => onDeleteChip(comp, SearchTypes.Supplier)}
                      label={comp}
                      key={`${comp}-${index}`}
                    />
                  );
                })
              }
            />
          </div>
        </div>
      )}

      {/* CONTAINER */}
      {(_functions.includes("culture") ||
        _functions.includes("mixing") ||
        _functions.includes("storage")) && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.container)}
          </div>
          <div className="w-70">
            <Autocomplete
              className="mt-2"
              variant="standard"
              multiple
              options={availableContainers}
              value={_containers}
              getOptionLabel={(option) => CUSTOM_NODES[option].name}
              renderOption={(props: any, option: string, { selected }) => (
                <li
                  {...props}
                  className="f-row f2-center gap-2 px-2 py-1 li-autocomplete"
                  style={{
                    backgroundColor: selected ? "var(--grey)" : "transparent",
                  }}
                >
                  <div className="pt-1">{CUSTOM_NODES[option]?.form(20)}</div>
                  <div>{CUSTOM_NODES[option]?.name}</div>
                </li>
              )}
              onChange={(event, newValue) => {
                //@ts-ignore
                handleChangeContainers(newValue);
              }}
              renderTags={(_containers: string[]) =>
                _containers.map((comp, index) => {
                  return (
                    <Chip
                      onDelete={() => onDeleteChip(comp, SearchTypes.Container)}
                      label={
                        <div className="f-row f2-center gap-1">
                          <div style={{ marginTop: 1 }}>
                            {CUSTOM_NODES[comp].form(14)}
                          </div>
                          <span>{CUSTOM_NODES[comp].name}</span>
                        </div>
                      }
                      key={`${comp}-${index}`}
                    />
                  );
                })
              }
            />
          </div>
        </div>
      )}

      {/* PUMP TYPE */}
      {(_functions.includes("pumping") ||
        _ancillaryFunctions.includes("pumping")) && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.pumpType)}
          </div>
          <div className="w-70">
            <Autocomplete
              className="mt-2"
              variant="standard"
              multiple
              options={_suPumpHeadSchema?.properties.componentType.enum}
              value={_pumpTypes}
              onChange={(event, newValue) => {
                //@ts-ignore
                handleChangePumpTypes(newValue);
              }}
              renderTags={(_pumpTypes: string[]) =>
                _pumpTypes.map((comp, index) => {
                  return (
                    <Chip
                      onDelete={() => onDeleteChip(comp, SearchTypes.Pumping)}
                      label={comp}
                      key={`${comp}-${index}`}
                    />
                  );
                })
              }
            />
          </div>
        </div>
      )}

      {(_functions.includes("sampling") ||
        _ancillaryFunctions.includes("sampling")) && (
        <div className="f-row gap-1 f2-center mt-3">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.bags2D)}
          </div>
          <div className="w-70">
            <DoubleRange
              step={1}
              min={0}
              max={30}
              defaultValue={_bags2D}
              onChange={handleChangeBags2D}
            />
          </div>
        </div>
      )}

      {/* CONNECTION TYPE */}
      <div className="f-row gap-1 f2-end">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(configMessages.connectionType)}
        </div>
        <div className="w-70">
          <Autocomplete
            className="mt-2 mb-1"
            variant="standard"
            multiple
            options={availableConnectors}
            value={_connectors}
            getOptionLabel={(option) => CUSTOM_NODES[option].name}
            renderOption={(props: any, option: string, { selected }) => (
              <li
                {...props}
                className="f-row f2-center gap-2 px-2 py-1 li-autocomplete"
                style={{
                  backgroundColor: selected ? "var(--grey)" : "transparent",
                }}
              >
                <div className="pt-1">{CUSTOM_NODES[option]?.form(20)}</div>
                <div>{CUSTOM_NODES[option]?.name}</div>
              </li>
            )}
            onChange={(event, newValue) => {
              handleChangeConnectors(newValue);
            }}
            renderTags={(_connectors: string[]) =>
              _connectors.map((conn: string, index: number) => {
                return (
                  <Chip
                    onDelete={() => onDeleteChip(conn, SearchTypes.Connection)}
                    label={
                      <div className="f-row f2-center gap-1">
                        <div style={{ marginTop: 1 }}>
                          {CUSTOM_NODES[conn].form(14)}
                        </div>
                        <span className="max-lines-1">
                          {CUSTOM_NODES[conn].name}
                        </span>
                      </div>
                    }
                    key={`${conn}-${index}`}
                  />
                );
              })
            }
          />
        </div>
      </div>

      {/* PORE SIZE M*/}
      {(_functions.includes("filtering") ||
        _ancillaryFunctions.includes("filtering")) && (
        <div className="f-row gap-1 f2-center">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.poreSizeMum)}
          </div>
          <div className="w-70">
            <TextInput
              variant="standard"
              className="mt-1 hide-input-arrow"
              inputSize="small"
              value={_poreSizeMum}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handlePoreSizeMumChange(e.target.value)
              }
              helperText={intl.formatMessage({
                id: "AdvancedSearchComponent.ThisValueNeedsToBeBetween0and30",
                defaultMessage: `Value must be between 0 and 30`,
              })}
              endAdornment={<span className="lowercase font-light">&Mu;m</span>}
              error={
                _poreSizeMum !== null && (_poreSizeMum < 1 || _poreSizeMum > 30)
              }
              min={0}
              max={30}
              step={1}
              type={"number"}
            />
          </div>
        </div>
      )}

      {/* PORE SIZE MWCO*/}
      {(_functions.includes("filtering") ||
        _ancillaryFunctions.includes("filtering")) && (
        <div className="f-row gap-1 f2-center">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.poreSizeMwco)}
          </div>
          <div className="w-70">
            <TextInput
              variant="standard"
              className="mt-1 hide-input-arrow"
              inputSize="small"
              value={_poreSizeMwco}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handlePoreSizeMwcoChange(e.target.value)
              }
              helperText={intl.formatMessage({
                id: "AdvancedSearchComponent.ThisValueNeedsToBeBetween0and1000",
                defaultMessage: `Value must be between 0 and 1000`,
              })}
              endAdornment={<span>kD</span>}
              error={
                _poreSizeMwco !== null &&
                (_poreSizeMwco < 1 || _poreSizeMwco > 1000)
              }
              min={0}
              max={1000}
              step={1}
              type={"number"}
            />
          </div>
        </div>
      )}

      {/* COMBINED POROSITY*/}
      {(_functions.includes("filtering") ||
        _ancillaryFunctions.includes("filtering")) && (
        <div className="f-row gap-1 f2-center">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.combinedPorosity)}
          </div>
          <div className="w-70">
            <Autocomplete
              multiple
              className="mt-2"
              variant="standard"
              options={
                _hydrophilicFilterSchema?.properties.componentCombinedPorosity
                  .enum
              }
              value={_combinedPorosity}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                handleChangeCombinedPorosity(newValue);
              }}
              renderTags={(_combinedPorosity: string[]) =>
                _combinedPorosity.map((cp: string, index: number) => {
                  return (
                    <Chip
                      onDelete={() =>
                        onDeleteChip(cp, SearchTypes.CombinedPorosity)
                      }
                      label={
                        <span>
                          {cp}{" "}
                          <span className="lowercase font-light">&Mu;m</span>
                        </span>
                      }
                      key={`${cp}-${index}`}
                    />
                  );
                })
              }
            />
          </div>
        </div>
      )}

      {/* FILTRATION SURFACE */}
      {(_functions.includes("filtering") ||
        _ancillaryFunctions.includes("filtering")) && (
        <div className="f-row gap-1 f2-center">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.filtrationSurface)}
          </div>
          <div className="w-70">
            <TextInput
              variant="standard"
              className="mt-1 hide-input-arrow"
              inputSize="small"
              value={_filtrationSurface}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFiltrationSurfaceChange(e.target.value)
              }
              helperText={intl.formatMessage({
                id: "AdvancedSearchComponent.ValueBetween1and30",
                defaultMessage: `Value must be between 1 and 30`,
              })}
              endAdornment={
                <span>
                  m<sup>2</sup>
                </span>
              }
              error={
                _filtrationSurface !== null &&
                (_filtrationSurface < 1 || _filtrationSurface > 30)
              }
              min={0}
              max={30}
              step={0.1}
              type={"number"}
            />
          </div>
        </div>
      )}
      {/* VOLUME */}
      {(_functions.includes("culture") ||
        _functions.includes("mixing") ||
        _functions.includes("sampling") ||
        _functions.includes("storage")) && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.volume)}
          </div>
          <div className="w-70">
            <Autocomplete
              className="mt-2"
              variant="standard"
              multiple
              options={_availableVolumes}
              value={_volumes}
              getOptionLabel={(option) => option + " L"}
              onChange={(event, newValue) => {
                //@ts-ignore
                handleChangeVolumes(newValue);
              }}
              renderTags={(_volumes: string[]) =>
                _volumes.map((v: string, index: number) => {
                  return (
                    <Chip
                      onDelete={() => onDeleteChip(v, SearchTypes.Volume)}
                      label={v + " L"}
                      key={`${v}-${index}`}
                    />
                  );
                })
              }
            />
          </div>
        </div>
      )}
      {/* SENSOR TYPE */}
      {(_functions.includes("culture") ||
        _functions.includes("filtering") ||
        _functions.includes("measuring") ||
        _functions.includes("mixing") ||
        _functions.includes("pumping")) && (
        <div className="f-row gap-1 f2-end">
          <div className="frozen-grey f-shrink-0 w-40">
            {intl.formatMessage(configMessages.sensorType)}
          </div>
          <div className="w-70">
            <Autocomplete
              className="mt-2"
              variant="standard"
              multiple
              options={_sensorSchema?.properties.componentType.enum}
              value={_sensorTypes}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                //@ts-ignore
                handleChangeSensorTypes(newValue);
              }}
              renderTags={(_sensorTypes: string[]) =>
                _sensorTypes.map((t: string, index: number) => {
                  return (
                    <Chip
                      onDelete={() => onDeleteChip(t, SearchTypes.Sensor)}
                      label={t}
                      key={`${t}-${index}`}
                    />
                  );
                })
              }
            />
          </div>
        </div>
      )}
      {/* LINES */}
      {(_functions.includes("culture") ||
        _functions.includes("mixing") ||
        _functions.includes("storage")) && (
        <div className="f-row gap-1 f2-center mt-3">
          <div className="frozen-grey f-shrink-0 w-40">
            <FormattedMessage
              id="AdvancedSearch.LinesAmount"
              defaultMessage={"Lines (amount)"}
            />
          </div>
          <div className="w-70">
            <DoubleRange
              step={1}
              min={0}
              max={30}
              defaultValue={[..._lines]}
              onChange={handleChangeLines}
            />
          </div>
        </div>
      )}
      {/* TUBING MATERIAL */}
      <div className="f-row gap-1 f2-end">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(configMessages.tubingMaterial)}
        </div>
        <div className="w-70">
          <Autocomplete
            className="mt-2 mb-1"
            variant="standard"
            multiple
            options={_tubingSchema?.properties.componentMaterial.enum}
            value={_tubingMaterials}
            onChange={(event, newValue) => {
              handleChangeTubingMaterials(newValue);
            }}
            renderTags={(_tubingMaterials: string[]) =>
              _tubingMaterials.map((comp, index) => {
                return (
                  <Chip
                    onDelete={() =>
                      onDeleteChip(comp, SearchTypes.TubingMaterial)
                    }
                    label={comp}
                    key={`${comp}-${index}`}
                  />
                );
              })
            }
          />
        </div>
      </div>

      {/* TUBING INNER DIAMETER */}
      <div className="f-row gap-1 f2-end">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(configMessages.tubingInner)} &#8960;
        </div>
        <div className="w-70">
          <Autocomplete
            className="mt-2 mb-1"
            variant="standard"
            multiple
            options={_tubingSchema?.properties.componentInnerDiameter.enum}
            value={_tubingInnerDiameters}
            onChange={(event, newValue) => {
              handleChangeTubingInnerDiameters(newValue);
            }}
            getOptionLabel={(option) => option + " inch"}
            renderTags={(_tubingInnerDiameters: string[]) =>
              _tubingInnerDiameters.map((comp, index) => {
                return (
                  <Chip
                    onDelete={() =>
                      onDeleteChip(comp, SearchTypes.TubingDiameter)
                    }
                    label={comp + " inch"}
                    key={`${comp}-${index}`}
                  />
                );
              })
            }
          />
        </div>
      </div>
      {/* TUBING LENGTH */}
      <div className="f-row gap-1 f2-center">
        <div className="frozen-grey f-shrink-0 w-40">
          {intl.formatMessage(configMessages.tubingLength)}
        </div>
        <div className="w-70">
          <TextInput
            variant="standard"
            className="mt-1 hide-input-arrow"
            inputSize="small"
            value={_tubingLength || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeTubingLengths(e.target.value)
            }
            helperText={intl.formatMessage({
              id: "AdvancedSearchComponent.ThisValueNeedsToBeBetween1and10000",
              defaultMessage: `This value needs to be between 1 and 10000`,
            })}
            endAdornment={<span>mm</span>}
            error={
              _tubingLength !== null &&
              (_tubingLength < 1 || _tubingLength > 10000)
            }
            min={1}
            max={100}
            step={1}
            type={"number"}
          />
        </div>
      </div>
    </div>
  );

  //#endregion

  return (
    <AdvancedSearch
      value={_search}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        handleBasicSearch(e.target.value)
      }
      filtersTitle={intl.formatMessage(generalMessages.advancedSearch)}
      children={advancedFields}
      filters={
        _renderFilters ? <AdvancedSearchFilters filters={_filters} /> : null
      }
      onSearch={onSearch}
      onClear={onClear}
      onOpen={onOpen}
      placeholder={intl.formatMessage({
        id: "SUConfig.SearchAssembly",
        defaultMessage: "Search Assembly",
      })}
    />
  );
};

export default AdvancedSearchComponent;
