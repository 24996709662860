import { ThinChip } from 'hakobio-react-ui';
import { includes, isEqual, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generalMessages, mediaMessages, versioningMessages } from '../../lang/messages';
import { useAppSelector } from '../../store';
import ItemChange from './ItemChange';

const MediaChangeCollection = ({ setHasDifferences }) => {
  const viewingAssembly = useAppSelector((state) => state.historySlice.viewingAssembly);
  const comparingAssembly = useAppSelector((state) => state.historySlice.comparingAssembly);

  const [_uniqueItemsList, setUniqueItemsList] = useState(null);

  const viewingCollection = viewingAssembly.general.medias;
  const comparingCollection = comparingAssembly.general.medias;

  const uniqueItemsIds = _uniqueItemsList?.map((item) => item.id);
  const viewingCollectionIds = viewingCollection?.map((item) => item.id);
  const comparingCollectionIds = comparingCollection?.map((item) => item.id);

  const intl = useIntl();

  useEffect(() => {
    if (!isEqual(viewingCollection, comparingCollection)) {
      setHasDifferences(true);
    } else {
      setHasDifferences(false);
    }
  }, [viewingCollection, comparingCollection]);

  useEffect(() => {
    if (!isEqual(viewingCollection, comparingCollection)) {
      const combinedCollection = [...viewingCollection, ...comparingCollection];
      const uniqueItems = uniqBy(combinedCollection, 'id');
      setUniqueItemsList(uniqueItems);
    }
  }, [viewingCollection, comparingCollection]);

  if (!isEqual(viewingCollection, comparingCollection)) {
    return (
      <>
        {(!isEqual(uniqueItemsIds, viewingCollectionIds) ||
          !isEqual(uniqueItemsIds, comparingCollectionIds)) && (
          <div className="p-2">
            <div className="font-regular">{intl.formatMessage(mediaMessages.media)}</div>
            <div className="py-1 f-col gap-2 hover-item">
              <div className="f-row f2-start gap-2 pl-3 py-1">
                <ThinChip
                  style={{ minWidth: 56 }}
                  className="f-shrink-0"
                  center
                  color={'var(--primaryColor)'}
                  backgroundColor={'var(--primaryColorBcg)'}
                  label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
                    viewingAssembly?.general?.version + 1
                  }`}
                />
                <div className=" f-row f-wrap gap-2">
                  {_uniqueItemsList?.map((item) => {
                    const isInViewingVersion = includes(
                      viewingCollection?.map((el) => el.id),
                      item.id
                    );

                    const isInComparingVersion = includes(
                      comparingCollection?.map((el) => el.id),
                      item.id
                    );
                    const label =
                      isInViewingVersion && isInComparingVersion ? (
                        item.name
                      ) : isInViewingVersion ? (
                        <span className="font-regular">&#43; {item.name}</span>
                      ) : comparingAssembly?.general?.version >
                        viewingAssembly?.general?.version ? (
                        <span>{intl.formatMessage(generalMessages.none)}</span>
                      ) : (
                        <s>{item.name}</s>
                      );
                    return (
                      <ThinChip
                        color={'var(--primaryColor)'}
                        backgroundColor={'var(--primaryColorBcg)'}
                        label={label || 'N/C'}
                      />
                    );
                  })}
                </div>
              </div>

              <div className="f-row f2-start gap-2 pl-3 py-1">
                <ThinChip
                  style={{ minWidth: 56 }}
                  className="f-shrink-0"
                  center
                  label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
                    comparingAssembly?.general?.version + 1
                  }`}
                />
                <div className=" f-row f-wrap gap-2">
                  {_uniqueItemsList?.map((item) => {
                    const isInViewingVersion = includes(
                      viewingCollection?.map((el) => el.id),
                      item.id
                    );

                    const isInComparingVersion = includes(
                      comparingCollection?.map((el) => el.id),
                      item.id
                    );

                    const label =
                      isInViewingVersion && isInComparingVersion ? (
                        item.name
                      ) : isInComparingVersion ? (
                        <span className="font-regular">&#43; {item.name}</span>
                      ) : comparingAssembly?.general?.version <
                        viewingAssembly?.general?.version ? (
                        <span className="frozen-grey">
                          {intl.formatMessage(generalMessages.none)}
                        </span>
                      ) : (
                        <s>{item.name}</s>
                      );
                    return <ThinChip label={label || 'N/C'} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="p-2">
          <div className="font-regular">{intl.formatMessage(mediaMessages.mediaDetails)}</div>
          <div className="py-1 f-col gap-2 hover-item">
            {_uniqueItemsList?.map((item) => {
              const viewingItemName = viewingCollection?.find((va) => va.id === item.id)?.name;
              const comparingItemName = comparingCollection?.find((ca) => ca.id === item.id)?.name;

              const viewingItemUrl = viewingCollection?.find((va) => va.id === item.id)?.url;
              const comparingItemUrl = comparingCollection?.find((ca) => ca.id === item.id)?.url;

              return (
                <div className="py-1 pl-3" key={item.id}>
                  {(!isEqual(viewingItemName, comparingItemName) ||
                    !isEqual(viewingItemUrl, comparingItemUrl)) && (
                    <div className="font-regular">{item.name}</div>
                  )}
                  {!isEqual(viewingItemName, comparingItemName) && (
                    <ItemChange
                      name={intl.formatMessage(generalMessages.name)}
                      viewingValue={viewingItemName}
                      comparingValue={comparingItemName}
                    />
                  )}
                  {!isEqual(viewingItemUrl, comparingItemUrl) && (
                    <ItemChange
                      name={intl.formatMessage(mediaMessages.link)}
                      viewingValue={viewingItemUrl}
                      comparingValue={comparingItemUrl}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default MediaChangeCollection;
