import Coordinate from './Coordinate.js';

export default class Element {
  constructor(arg) {
    var data = {
      projectId: null,
      rotation: 0,
      point: { coordinates: {} },
      id: null,
      createdOn: null,
      modifiedOn: null,
      behaviors: 0,
      destinationId: null,
      scaleWidth: 1,
      scaleLength: 1,
      scaleHeight: 1,
      stockType: 0,
      medias: [],
    };
    try {
      Object.assign(data, JSON.parse(arg));
    } catch (err) {
      try {
        Object.assign(data, arg);
      } catch (err) {
        console.log('Warning: ' + err);
      }
    }
    this.projectId = data.projectId;
    this.rotation = data.rotation;
    this.point = {};
    this.point.coordinates = new Coordinate(data.point.coordinates);
    this.id = data.id;
    this.createdOn = data.createdOn;
    this.modifiedOn = data.modifiedOn;
    this.productId = data.productId;
    this.product = data.product;
    this.name = data.name;
    if (!this.productId && this.product) {
      this.productId = this.product.id;
    }
    this.destinationId = data.destinationId;
    this.scaleHeight = data.scaleHeight;
    this.scaleLength = data.scaleLength;
    this.scaleWidth = data.scaleWidth;
    this.medias = data.medias;
    this.stockType = data.stockType;

    // computed props
    this.displayName = () => {
      return this.name || (this.product && this.product.name);
    };
  }
}
