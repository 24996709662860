import {
  SubTitleWithIcon,
  Switch,
  ThinChip,
  TitleWithIcon,
} from "hakobio-react-ui";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { assemblyEditorSliceActions } from "../../../../../store/features/assemblyEditor/assemblyEditorSlice";
import { useLinkEditorAction } from "../../../../../store/features/assemblyEditor/useLinkEditorAction";
import { getColors, Uuidv4 } from "../../../../../utilities";

const FunctionEdgeDetails = (props: any) => {
  const components = useAppSelector(
    (state) => state.assemblyEditorSlice.components
  );
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const { secondaryColor, secondaryColorAlpha } = getColors();

  const dispatch = useAppDispatch();
  const {joinComponents} = useLinkEditorAction();

  const component = components.find(
    (component) => component.id === props.component.id
  );
  const componentSource = components.find(
    (component) => component.id === props.component.source
  );
  const componentTarget = components.find(
    (component) => component.id === props.component.target
  );


  if (
    componentSource.type !== "generic" ||
    componentTarget.type !== "generic"
  ) {
    return null;
  }
 
  return (
    <div style={{ padding: "0 16px 4px" }}>
      <TitleWithIcon name="manifold">
        {"Transfer function"}
      </TitleWithIcon>
      <div className="mx-1 my-3">
        <SubTitleWithIcon className="mb-1" name="h-equipment">
          Functions
        </SubTitleWithIcon>
        <div className="f-row gap-2 mb-2 px-2">
          <ThinChip
            color={secondaryColor}
            backgroundColor={secondaryColorAlpha}
            label={"Transfer"}
          />
        </div>
      </div>
      <div className="mx-1 my-3 px-2 f-row f2-center gap-2">
        <div>Join connectors</div>
        <Switch
          checked={!component.data.implementable}
          onChange={() => joinComponents(!component.data.implementable,component,componentSource,componentTarget)}
        />
      </div>
    </div>
  );
};

export default FunctionEdgeDetails;
