import { cloneDeep } from 'lodash';
import { isNode } from 'react-flow-renderer';
import { angle } from '../../../utilities';
import { ViewMode } from '../../../constants/ViewMode';

export const getElementsReference = (components, viewMode) => {
  const elementList = [];
  const functionComponents = components.filter(
    (c) => c.type === 'function' || c.type === 'functionedge'
  );
  const filteredComponents = components.filter(
    (c) =>
      c.type === 'ghost' || c.type === 'assembly' || c.type === 'update' || c.type === 'selection'
  );
  functionComponents.forEach((functionComponent) => {
    if (functionComponent.type === 'functionedge') {
      filteredComponents.push(functionComponent);
      return;
    }
    if (
      functionComponent.type === 'function' &&
      !functionComponent.data.assembly &&
      !functionComponent.data.assemblyReference
    ) {
      filteredComponents.push(functionComponent);
      return;
    }
    if (
      functionComponent.type === 'function' &&
      functionComponent.data.assembly &&
      functionComponent.data.ghost &&
      !functionComponent.data.assemblyReference
    ) {
      filteredComponents.push(functionComponent);
      return;
    }
    if (
      functionComponent.type === 'function' &&
      functionComponent.data.assembly &&
      !functionComponent.data.ghost &&
      !functionComponent.data.assemblyReference
    ) {
      filteredComponents.push(functionComponent);
      filteredComponents.push(
        ...components.filter(
          (c) =>
            c.data.assembly?.component === functionComponent.id &&
            (c.type === 'generic' || c.type === 'genericedge' || c.type === 'genericonedge')
        )
      );
      return;
    }
    if (
      functionComponent.type === 'function' &&
      functionComponent.data.assemblyReference &&
      functionComponent.data.ghostReference
    ) {
      filteredComponents.push(
        ...components.filter(
          (c) =>
            c.data.assemblyReference?.component === functionComponent.id &&
            (c.type === 'generic' || c.type === 'genericedge' || c.type === 'genericonedge')
        )
      );
      return;
    }
    if (
      functionComponent.type === 'function' &&
      functionComponent.data.assemblyReference &&
      !functionComponent.data.ghostReference
    ) {
      filteredComponents.push(functionComponent);
      filteredComponents.push(
        ...components.filter(
          (c) =>
            c.data.assemblyReference?.component === functionComponent.id &&
            (c.type === 'generic' || c.type === 'genericedge' || c.type === 'genericonedge')
        )
      );
      return;
    }
  });
  filteredComponents.forEach((c) => {
    if (c.type === 'function' && c.data.ghostReference) {
      return;
    }
    if (isNode(c)) {
      if (
        c.type === 'genericonedge' ||
        /*  c.data.type === 'sensor' || */
        c.data.type === 'mechanicDisconnector' ||
        c.data.type === 'assembly' ||
        c.data.type === 'update'
      ) {
        // @ts-ignore
        const edgeRef = document.getElementById(c.viewer2D.source);
        if (edgeRef !== undefined && edgeRef !== null) {
          // @ts-ignore
          const edgeLength = edgeRef.getTotalLength();
          // @ts-ignore
          let edgeLoc = edgeRef.getPointAtLength(
            // @ts-ignore
            c.viewer2D.position * edgeLength
          );
          // @ts-ignore
          let edgeLoc2 = edgeRef.getPointAtLength(
            // @ts-ignore
            (c.viewer2D.position + 0.02) * edgeLength
          );
          let rotation = angle(edgeLoc.x, edgeLoc.y, edgeLoc2.x, edgeLoc2.y);
          let node = cloneDeep(c) as any;
          // @ts-ignore
          node.position = { x: edgeLoc.x - 15, y: edgeLoc.y - 15 };
          node.viewer2D.rotate = rotation;
          node.selectable = !node.data.ghostReference;
          node.draggable = viewMode===ViewMode.HistoryUO ? false : true;
          elementList.push(node);
          return;
        } else {
          let node = cloneDeep(c) as any;
          node.position = { x: node.viewer2D.reference.x, y: node.viewer2D.reference.y };
          node.selectable = !node.data.ghostReference;
          node.draggable = viewMode===ViewMode.HistoryUO ? false : true;
          elementList.push(node);
          return;
        }
      } else {
        let node = cloneDeep(c) as any;
        node.position = { x: node.viewer2D.reference.x, y: node.viewer2D.reference.y };
        node.selectable = !node.data.ghostReference;
        node.draggable = viewMode===ViewMode.HistoryUO ? false : !node.data.ghostReference;
        node.isHidden = !!(c.type === 'function' && c.data.assemblyReference);
        elementList.push(node);
        return;
      }
    } else {
      let edge = cloneDeep(c) as any;
      edge.animated = false;
      edge.style = {};
      const target = components.find((co: any) => co.id === c.target);
      let targetAnchorFunction;
      const source = components.find((co: any) => co.id === c.source);
      let sourceAnchorFunction;
      if (
        source &&
        source.type === 'function' &&
        !source.data.assembly &&
        !source.data.assemblyReference
      ) {
        //Nothing to do
      } else if (
        source &&
        source.type === 'function' &&
        source.data.assemblyReference &&
        source.data.ghostReference
      ) {
        let ghostEdge;
        if (edge.sourceGhostReference) {
          ghostEdge = components.find((e: any) => {
            return (
              e.type === 'functionedge' &&
              e.data.ghostReference &&
              ((e.source === edge.sourceGhostReference && e.sourceHandle === edge.sourceHandle) ||
                (e.target === edge.sourceGhostReference && e.targetHandle === edge.sourceHandle))
            );
          });
        }
        edge.source = edge.sourceGhostReference;
        if (!edge.animated && !ghostEdge) {
          edge.animated = true;
          edge.style = { stroke: '#f6ab6c' };
        }
      } else if (source && source.type !== 'function') {
        //Nothing to do
      } else if (
        source &&
        source.type === 'function' &&
        source.data.assemblyReference &&
        !source.data.ghostReference
      ) {
        let anchorSource;
        anchorSource = source.data.anchors.find((a: any) => a.id === c.sourceHandle);
        edge.source = anchorSource.data.componentLinkReference?.component;
        edge.sourceHandle = anchorSource.data.componentLinkReference?.anchor;
      } else if (
        source &&
        source.type === 'function' &&
        source.data.assembly &&
        !source.data.ghost
      ) {
        sourceAnchorFunction = source.data.anchors.find((anchor: any) => {
          return anchor.id === c.sourceHandle;
        });
        edge.source = sourceAnchorFunction.data.componentLink?.component;
        edge.sourceHandle = sourceAnchorFunction.data.componentLink?.anchor;
      } else if (
        source &&
        source.type === 'function' &&
        source.data.assembly &&
        source.data.ghost
      ) {
        //Nothing to do
      }
      if (
        target &&
        target.type === 'function' &&
        !target.data.assembly &&
        !target.data.assemblyReference
      ) {
        //Nothing to do
      } else if (
        target &&
        target.type === 'function' &&
        target.data.assemblyReference &&
        target.data.ghostReference
      ) {
        let ghostEdge;
        if (edge.targetGhostReference) {
          ghostEdge = components.find((e: any) => {
            return (
              e.type === 'functionedge' &&
              e.data.ghostReference &&
              ((e.source === edge.targetGhostReference && e.sourceHandle === edge.targetHandle) ||
                (e.target === edge.targetGhostReference && e.targetHandle === edge.targetHandle))
            );
          });
        }
        edge.target = edge.targetGhostReference;
        if (!edge.animated && !ghostEdge) {
          edge.animated = true;
          edge.style = { stroke: '#f6ab6c' };
        }
      } else if (target && target.type !== 'function') {
        //Nothing to do
      } else if (
        target &&
        target.type === 'function' &&
        target.data.assemblyReference &&
        !target.data.ghostReference
      ) {
        let anchorTarget;
        anchorTarget = target.data.anchors.find((a: any) => a.id === c.targetHandle);
        edge.target = anchorTarget.data.componentLinkReference?.component;
        edge.targetHandle = anchorTarget.data.componentLinkReference?.anchor;
      } else if (
        target &&
        target.type === 'function' &&
        target.data.assembly &&
        !target.data.ghost
      ) {
        targetAnchorFunction = target.data.anchors.find((anchor: any) => {
          return anchor.id === c.targetHandle;
        });
        edge.target = targetAnchorFunction.data.componentLink?.component;
        edge.targetHandle = targetAnchorFunction.data.componentLink?.anchor;
      } else if (
        target &&
        target.type === 'function' &&
        target.data.assembly &&
        target.data.ghost
      ) {
        //Nothing to do
      }
      elementList.push(edge);
      return;
    }
  });
  return elementList;
};
