import { useEffect, useRef } from 'react';
import { motion, useMotionValue } from 'framer-motion';
import { CUSTOM_NODES } from '../../../../../constants/CUSTOM_NODES';
import { CUSTOM_FUNCTIONS } from '../../../../../constants/CUSTOM_FUNCTIONS';
import { EditorMode } from '../../../../../constants/EditorMode';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useAssemblyEditorAction } from '../../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './DraggableSocketBadge';
import { assemblyEditorSliceActions } from '../../../../../store/features/assemblyEditor/assemblyEditorSlice';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { AnchorTypes } from '../../../../../constants/AnchorTypes';
import { portColors } from '../../../../../utilities/portsColors';
import { CustomIcon } from 'hakobio-react-ui';
import { getTubeColor } from '../../../../../services/tubing/getTubeColor';

let SIZE = 360;

export function AnchorDragEditor(props: any) {
  const constraintsRef = useRef(null);
  const canvasPosition = useRef(null);
  const dispatch = useAppDispatch();

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const isSavingScreenshotComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotComponent
  );

  const { onChange, schema, selectedNode } = props;

  const constants = editorMode === EditorMode.SUA ? CUSTOM_NODES : CUSTOM_FUNCTIONS;
  const customNode = Object.entries(constants).find(([key, cN]) => {
    return key.toLowerCase() === schema.default.title.toLowerCase();
  });
  const customNodeForm =
    editorMode === EditorMode.Component || editorMode === EditorMode.Reference
      ? CUSTOM_NODES[selectedNode.data.type].form(SIZE)
      : customNode && (customNode as any)[1].form(SIZE);

  useEffect(() => {
    setTimeout(() => {
      if (selectedNode.data.type === 'tubing') {
        const tubingElements = document.getElementsByClassName('tubing');
        const tubing = tubingElements.length ? tubingElements[0] : null;
        const tubeColor = getTubeColor(selectedNode.data.componentType);
        if (tubing) tubing.setAttribute('fill', tubeColor);
      }
    }, 50);
  }, [selectedNode]);

  const dispatchUpdate = async (item: any, style: any) => {
    const nodeCopy = cloneDeep(selectedNode);
    switch (item.anchorType) {
      case 'FluidTransfert':
        nodeCopy.data.anchors.push({
          id: uuidv4(),
          type: item.type,
          data: {
            position: 'left'
          },
          viewer2D: style
        });
        break;
      case 'InstrumentationPort':
        if (!nodeCopy.data.instrumentationPorts) {
          nodeCopy.data.instrumentationPorts = [];
        }
        nodeCopy.data.instrumentationPorts.push({
          id: uuidv4(),
          type: item.type,
          data: {
            position: 'left'
          },
          viewer2D: style
        });
        break;
      case 'SamplingPort':
        if (!nodeCopy.data.samplingPorts) {
          nodeCopy.data.samplingPorts = [];
        }
        nodeCopy.data.samplingPorts.push({
          id: uuidv4(),
          type: item.type,
          data: {
            position: 'left'
          },
          viewer2D: style
        });
        break;
    }
    if (editorMode === EditorMode.Component) {
      dispatch(assemblyEditorSliceActions.setCustomComponentEdition(nodeCopy));
    } else {
      dispatch(assemblyEditorSliceActions.updateComponent(nodeCopy));
      props.updateComponent();
    }
  };

  const [collectedProps, drop] = useDrop(
    () => ({
      accept: ItemTypes.DRAGGING_BADGE,
      drop(item: any, monitor: any) {
        const clientOffset = monitor.getClientOffset();
        //@ts-ignore
        const canvasLeft = canvasPosition.current.getBoundingClientRect().left;
        //@ts-ignore
        const canvasSizeX =
          //@ts-ignore
          canvasPosition.current.getBoundingClientRect().right -
          //@ts-ignore
          canvasPosition.current.getBoundingClientRect().left;
        //@ts-ignore
        const canvasTop = canvasPosition.current.getBoundingClientRect().top;
        //@ts-ignore
        const canvasSizeY =
          //@ts-ignore
          canvasPosition.current.getBoundingClientRect().bottom -
          //@ts-ignore
          canvasPosition.current.getBoundingClientRect().top;

        const style = {
          left: (clientOffset.x - canvasLeft + 4) / canvasSizeX,
          top: (clientOffset.y - canvasTop + 4) / canvasSizeY
        };
        dispatchUpdate(item, style);
        dispatch(assemblyEditorSliceActions.setEditingAssembly(true));
      }
    }),
    [selectedNode]
  );

  return (
    <div>
      <div ref={canvasPosition}>
        <div ref={drop}>
          {/* @ts-ignore */}
          <motion.div
            ref={constraintsRef}
            style={{
              height: SIZE,
              width: SIZE,
              position: 'relative',
              border:
                editorMode === EditorMode.Component || isSavingScreenshotComponent
                  ? undefined
                  : '8px solid var(--grey)',
              boxSizing: 'content-box'
            }}>
            <div style={{ position: 'absolute', pointerEvents: 'none' }}>{customNodeForm}</div>
            {selectedNode.data?.anchors?.map((item: any) => {
              return (
                <AnchorDragEditorItem
                  key={item.id}
                  item={item}
                  selectedNode={selectedNode}
                  onChange={onChange}
                  color={selectedNode.type === 'function' ? '#565656' : portColors.Transfer}
                  anchorType={AnchorTypes.Transfer}
                />
              );
            })}
            {selectedNode.data?.instrumentationPorts?.map((item: any) => {
              return (
                <AnchorDragEditorItem
                  key={item.id}
                  item={item}
                  selectedNode={selectedNode}
                  onChange={onChange}
                  color={portColors.Instrumentation}
                  anchorType={AnchorTypes.InstrumentationPort}
                />
              );
            })}
            {selectedNode.data?.samplingPorts?.map((item: any) => {
              return (
                <AnchorDragEditorItem
                  key={item.id}
                  item={item}
                  selectedNode={selectedNode}
                  onChange={onChange}
                  color={portColors.Sampling}
                  anchorType={AnchorTypes.SamplingPort}
                />
              );
            })}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

function AnchorDragEditorItem({ item, selectedNode, constraintsRef, anchorType, color }: any) {
  const top = useMotionValue(item.viewer2D.top || 0);
  const left = useMotionValue(item.viewer2D.left || 0);
  const { onAnchorChanged } = useAssemblyEditorAction();
  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );
  const selectedComponentId = selectedComponents && selectedComponents[0];
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const customComponentEdition = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentEdition
  );
  const customComponentViewOnly = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentViewOnly
  );
  const hoveredAnchor = useAppSelector((state) => state.assemblyEditorSlice.hoveredAnchor);
  const selectedComponent = components.find((c: any) => c.id === selectedComponentId);

  const isSavingScreenshotComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotComponent
  );

  const component =
    editorMode === EditorMode.Component ? customComponentEdition : selectedComponent;
  const dispatch = useAppDispatch();

  useEffect(() => {
    top.set(item.viewer2D.top || 0);
    left.set(item.viewer2D.left || 0);
  }, [item.viewer2D.left, item.viewer2D.top, left, selectedNode.Id, top]);

  const iconName = () => {
    switch (anchorType) {
      case AnchorTypes.Transfer:
        return 'blood';
      case AnchorTypes.InstrumentationPort:
        return 'instrumentation';
      case AnchorTypes.SamplingPort:
        return 'sampling';
    }
  };

  return (
    // @ts-ignore
    <motion.div
      className={`f-center`}
      style={{
        position: 'absolute',
        x: left.get() * SIZE - 8,
        y: top.get() * SIZE - 8,
        height:
          editorMode === EditorMode.Component ||
          (editorMode === EditorMode.SUA && isSavingScreenshotComponent)
            ? 30
            : 16,
        width:
          editorMode === EditorMode.Component ||
          (editorMode === EditorMode.SUA && isSavingScreenshotComponent)
            ? 30
            : 16,
        backgroundColor: color,
        borderRadius: '50%'
      }}
      drag={!customComponentViewOnly}
      dragMomentum={false}
      dragConstraints={constraintsRef}
      dragElastic={0}
      onDragEnd={(event, info) => {
        dispatch(assemblyEditorSliceActions.setEditingAssembly(true));
        if (event.target) {
          // @ts-ignore
          const transform = event.target.style.transform;
          const re =
            /translateX\((?<x>.*?)px\) translateY\((?<y>.*?)px\) translateZ\((?<z>.*?)px\)/;
          const results = re.exec(transform);
          if (results?.groups.x && results?.groups.y) {
            const { x, y } = results?.groups;

            const value = {
              ...item.viewer2D,
              top: (Number.parseFloat(y) + 8) / SIZE,
              left: (Number.parseFloat(x) + 8) / SIZE
            };
            onAnchorChanged(item, 'viewer2D', anchorType, value, component);
          }
        }
      }}>
      {editorMode === EditorMode.Component && !isSavingScreenshotComponent && (
        <div className="relative">
          <CustomIcon
            color="white"
            style={{ lineHeight: 0.5 }}
            name={iconName()}
            hako3={anchorType !== AnchorTypes.Transfer}
          />
          <div
            className={`${hoveredAnchor === item.id ? 'radarCustomComponent' : ''} absolute`}
            style={{
              border: hoveredAnchor === item.id ? '2px solid ' + color : 'none',
              top: -8,
              left: -8
            }}
          />
        </div>
      )}
    </motion.div>
  );
}
