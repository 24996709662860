import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { getOneAssemblyHistory } from "../../services/editor/editorService";
import { convertToRead } from "../../services/editor/editorServiceUtilities";

import FrontPage from "./SchemaPDF/FrontPage";

import SchemaSUA from "./SchemaPDF/SchemaSUA";
import { useAppSelector } from "../../store";
import { useReactToPrint } from "react-to-print";
import { Button, ButtonFooter, Icon } from "hakobio-react-ui";
import { cloneDeep, isEqual, orderBy, times, uniqWith } from "lodash";
import "./print.css";

import { useIntl } from "react-intl";
import { generalMessages } from "../../lang/messages";

const ReportPanelSUA = (props) => {
  const [_assemblyGeneral, setAssemblyGeneral] = useState(null);
  const [_assemblyComponents, setAssemblyComponents] = useState(null);
  const [_assemblyFunction, setAssemblyFunction] = useState(null);
  const [_assembliesLoaded, setAssembliesLoaded] = useState(0);
  const [_totalPage, setTotalPage] = useState(1);
  const [_isDeletedAssemblies, setDeletedAssemblies] = useState(false);
  const _assembliesLoadedRef = useRef(0);
  const _totalPageRef = useRef(1);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const reportData = useAppSelector(
    (state) => state.assemblyEditorSlice.reportData,
  );

  const exportDate = new Date().toLocaleDateString("en-EN", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const intl = useIntl();

  useEffect(() => {
    getAssemblyAsync(props.general.id, props.general.hashPartitionKey);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTotalPage(_totalPageRef.current);
    }, 20);
  });

  const getAssemblyAsync = async (id: string, hashPartitionKey: string) => {
    const assembly = await getOneAssemblyHistory(id, hashPartitionKey);
    const assemblyToRead = convertToRead(assembly);
    setAssemblyGeneral(assemblyToRead.general);
    setAssemblyComponents(assemblyToRead.components);
    setAssemblyFunction({ ...assemblyToRead, isArchived: assembly.isArchived });
  };

  const backToSUA = () => {
    props.history.push("/editor/UnitOperation/" + props.general.id);
  };

  const suaLoaded = () => {
    _assembliesLoadedRef.current = _assembliesLoadedRef.current + 1;
    setAssembliesLoaded(_assembliesLoadedRef.current);
  };

  const suaUnloaded = (id) => {
    _assembliesLoadedRef.current = _assembliesLoadedRef.current - 1;
    setAssembliesLoaded(_assembliesLoadedRef.current);
  };

  const renderSchemaSUA = () => {
    const assemblyId = props.general.id;
    const hashPartitionKey = props.general.hashPartitionKey;
    const sortComponents = () => {
      let componentsCopy = cloneDeep(props.components);
      let tubesComponentsLinkedIds = [];
      for (let index = 0; index < componentsCopy.length; index++) {
        const element = componentsCopy[index];
        if (element.data.idDrag) {
          tubesComponentsLinkedIds.push(element.data.idDrag.edge);
        }
      }
      componentsCopy = componentsCopy.filter(
        (component: any) => !tubesComponentsLinkedIds.includes(component.id),
      );

      componentsCopy.sort((c1, c2) => {
        let c1x;
        let c2x;
        if (c1.type === "generic" || c1.type === "genericonedge") {
          c1x = c1.viewer2D.x;
        } else {
          const c1Src = props.components.find((c) => c.id === c1.source);
          const c1Trg = props.components.find((c) => c.id === c1.target);
          c1x =
            c1Src.viewer2D.x < c1Trg.viewer2D.x
              ? c1Src.viewer2D.x
              : c1Trg.viewer2D.x;
        }
        if (c2.type === "generic" || c2.type === "genericonedge") {
          c2x = c2.viewer2D.x;
        } else {
          const c2Src = props.components.find((c) => c.id === c2.source);
          const c2Trg = props.components.find((c) => c.id === c2.target);
          c2x =
            c2Src.viewer2D.x < c2Trg.viewer2D.x
              ? c2Src.viewer2D.x
              : c2Trg.viewer2D.x;
        }
        if (c1x > c2x) {
          return 1;
        }
        if (c1x < c2x) {
          return -1;
        }
        if (c1.type === c2.type) {
          return 0;
        }
        return c1.type > c2.type ? 1 : c1.type < c2.type ? -1 : 0;
      });

      //delete non essential attributes for the comparison
      const newComponentsCopy = cloneDeep(componentsCopy);
      const componentsData = newComponentsCopy.map((component) => {
        delete component.data.customName;
        delete component.data.assembly;
        if (component.data.type === "plug") {
          component.data.anchors = component.data.anchors.map((anchor) => {
            return anchor.data || null;
          });
        } else if (component.data.type !== "tubing") {
          if (component?.data?.anchors) {
            component.data.anchors = component.data.anchors.map((anchor) => {
              if (anchor?.data !== null && anchor?.data !== undefined) {
                return anchor.data;
              }
            });
          }
          if (component?.data?.instrumentationPorts) {
            component.data.instrumentationPorts =
              component.data.instrumentationPorts.map((anchor) => {
                return anchor.data;
              });
          }
          if (component?.data?.samplingPorts) {
            component.data.samplingPorts = component.data.samplingPorts.map(
              (anchor) => {
                return anchor.data;
              },
            );
          }
        } else {
          delete component.data.anchors;
          delete component.data.instrumentationPorts;
          delete component.data.samplingPorts;
        }
        const newComponent = { ...component.data, ...{ id: component.id } };

        return newComponent;
      });

      function customizer(objValue, othValue) {
        const newObjValue = { ...objValue, ...{ id: "" } };
        const newOthValue = { ...othValue, ...{ id: "" } };

        if (isEqual(newObjValue, newOthValue)) {
          return true;
        } else {
          return false;
        }
      }

      const groups = cloneDeep(uniqWith(componentsData, customizer));

      function getOccurrence(componentsData, value) {
        let count = 0;
        const idLessValue = { ...value, ...{ id: "" } };
        componentsData.forEach((c) => {
          const idlessComponent = { ...c, ...{ id: "" } };
          isEqual(idlessComponent, idLessValue) && count++;
        });
        return count;
      }

      groups.map((g) => (g.count = getOccurrence(componentsData, g)));
      const sortedList = orderBy(groups, ["type"], ["asc"]);

      return sortedList;
    };

    const legend = props.general ? sortComponents() : [];

    const getEntriesAmount = () => {
      let count = 0;
      legend.length &&
        legend.forEach((leg, index) => {
          let legCount = 0;
          let anchorType = "";
          Object.entries(leg).forEach(([key1, value1]) => {
            if (
              key1 === "anchors" ||
              key1 === "instrumentationPorts" ||
              key1 === "samplingPorts"
            ) {
              value1 &&
                Object.values(value1).forEach((anchor) => {
                  if (anchor) {
                    Object.entries(anchor).forEach(([key2, value2]) => {
                      // we only want to add a count if there are actual properties inside the anchor
                      if (
                        key2 !== "position" &&
                        key2 !== "componentLink" &&
                        key2 !== "name"
                      ) {
                        if (anchorType !== key1) {
                          anchorType = key1;
                          count = count + 2;
                          legCount = legCount + 2;
                        }
                        count++;
                        legCount++;
                      }
                    });
                  }
                });
            } else if (key1 === "type") {
              count = count + 2;
              legCount = legCount + 2;
            } else if (
              key1 !== "id" &&
              key1 !== "ghost" &&
              key1 !== "count" &&
              key1 !== "length of tube" &&
              key1 !== "wall" &&
              value1
            ) {
              count++;
              legCount++;
            }
          });
          leg.elementCount = legCount;
          leg.legendNumber = index + 1;
        });
      return count;
    };

    const entriesAmount = getEntriesAmount();

    if (legend) {
      if (entriesAmount < 34) {
        _totalPageRef.current = _totalPageRef.current + 1;
        return (
          <SchemaSUA
            key={assemblyId}
            hashPartitionKey={hashPartitionKey}
            exportDate={exportDate}
            assemblyId={assemblyId}
            assemblyFunction={_assemblyFunction}
            pageNumber={_totalPageRef.current}
            totalPageNumber={_totalPage}
            suaLoaded={suaLoaded}
            suaUnloaded={suaUnloaded}
            legend={legend}
            entriesAmount={entriesAmount}
            setDeletedAssemblies={(bool) => setDeletedAssemblies(bool)}
          />
        );
      } else {
        let amount = 0;
        let legendDivided = [];
        legend.forEach((leg, index) => {
          amount = amount + leg.elementCount;
          const divided = legendDivided.flat(2);

          if (amount >= 33) {
            const part = legend.slice(divided?.length, index);
            legendDivided.push([part]);
            amount = leg.elementCount;
            const newDevided = [...legendDivided].flat(2);
            if (
              index === legend.length - 1 &&
              legend.length > newDevided.length
            ) {
              const part = legend.slice(newDevided.length);
              legendDivided.push([part]);
              amount = leg.elementCount;
            }
          } else {
            if (index === legend.length - 1 && legend.length > divided.length) {
              const part = legend.slice(divided.length);
              legendDivided.push([part]);
              amount = leg.elementCount;
            }
          }
        });
        return (
          <>
            {times(legendDivided.length, (i) => {
              _totalPageRef.current = _totalPageRef.current + 1;
              return (
                <SchemaSUA
                  key={assemblyId + i}
                  hashPartitionKey={hashPartitionKey}
                  time={i}
                  exportDate={exportDate}
                  assemblyId={assemblyId}
                  assemblyFunction={_assemblyFunction}
                  pageNumber={_totalPageRef.current}
                  totalPageNumber={_totalPage}
                  suaLoaded={suaLoaded}
                  suaUnloaded={suaUnloaded}
                  legend={legend}
                  entriesAmount={entriesAmount}
                />
              );
            })}
          </>
        );
      }
    }
  };

  const renderFrontPage = () => {
    _totalPageRef.current = _totalPageRef.current + 1;
    return (
      <FrontPage
        assemblyGeneral={_assemblyGeneral}
        exportDate={exportDate}
        pageNumber={_totalPageRef.current}
        totalPageNumber={_totalPage}
      />
    );
  };

  const isButtonDisabled =
    (!reportData.frontPage && reportData.allSUA.length === 0) ||
    _assembliesLoaded !== 1;

  const renderActiveButton = () => {
    _totalPageRef.current = 0;
    return (
      <div style={{ overflowY: "auto", counterReset: "page" }}>
        <ButtonFooter disabled={isButtonDisabled} onClick={handlePrint}>
          {intl.formatMessage(generalMessages.exportReport)}
        </ButtonFooter>
        <div style={{ height: 0, visibility: "hidden", overflow: "hidden" }}>
          <div
            className="border-top f-row f1-between f2-center px-3 py-2 border-bottom"
            style={{ backgroundColor: "var(--grey)" }}
          >
            <div className="f-row f2-center gap-2">
              <Icon name="previous" onClick={backToSUA} />
              {intl.formatMessage(generalMessages.report)}
            </div>
            <Button onClick={handlePrint}>
              {intl.formatMessage(generalMessages.exportReport)}
            </Button>
          </div>

          <div ref={componentRef} className="pb-3" style={{}}>
            {reportData.frontPage && renderFrontPage()}
            {renderSchemaSUA()}
          </div>
        </div>
      </div>
    );
  };

  const renderLoadingButton = () => {
    return (
      <>
        <ButtonFooter disabled={true} onClick={handlePrint}>
          {intl.formatMessage(generalMessages.exportReport)}
        </ButtonFooter>
      </>
    );
  };

  const render = () => {
    if (!_assemblyFunction) {
      return renderLoadingButton();
    }
    return renderActiveButton();
  };

  return render();
};

export default withRouter(ReportPanelSUA);
