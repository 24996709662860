import { Chip, Empty, SplashScreen } from 'hakobio-react-ui';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useStoreActions } from 'react-flow-renderer';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { CUSTOM_FUNCTIONS } from '../../../../../constants/CUSTOM_FUNCTIONS';
import { useAppSelector } from '../../../../../store';
import { useImplementEditorAction } from '../../../../../store/features/assemblyEditor/useImplementEditorAction';
import { advancedSearch } from '../../../../Shared/AdvancedSearch/utilities/search';
import SingleReference from '../Library/SingleReference';

export default function ReferenceLibrary(props: any) {
  const intl = useIntl();
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const isLayerLocked = useAppSelector((state) => state.assemblyEditorSlice.isLayerLocked);
  const { implementComponent } = useImplementEditorAction();
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);
  const [_selectedComponents, setSelectedComponents] = useState([]);
  const [_isEmptyListForFuction, setIsEmptyListForFuction] = useState(false);
  const { selectedComponent, components } = props;
  const [SUAListToShow, setSUAListToShow] = useState('');
  const directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const user = useAppSelector((state) => state.assemblyEditorSlice.user);
  const relevantDirectories = directoriesList.filter((dir) => dir.directoryType === 1);

  const isSUALibraryCharged = useAppSelector(
    (state) => state.assemblyEditorSlice.isSUALibraryCharged
  );
  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary
  );

  const referenceLibrary = editorAssemblyLibrary.filter(
    (x) => x.general.type === 'SingleUseAssemblyReference'
  );

  const headers: string[] = Object.values(CUSTOM_FUNCTIONS)
    .filter((f: any) => f.name !== 'Purification' && f.name !== 'Transferring')
    .map((f: any) => f.name)
    .sort();
  headers.push('Other');

  useEffect(() => {
    if (selectedComponent) setSelectedComponents(selectedComponent);
  }, [selectedComponent]);

  useEffect(() => {
    if (selectedComponent) {
      const correctCaseType = selectedComponent.data.type.charAt(0).toUpperCase() + selectedComponent.data.type.slice(1);
      const list = getSuaListByHeader(correctCaseType);
  
      setIsEmptyListForFuction(list.length === 0);
      setSUAListToShow(list.length > 0 ? correctCaseType : '');
    } else {
      setIsEmptyListForFuction(false);
      setSUAListToShow('');
    }
  }, [selectedComponent]);

  const getSuaListByHeader = (header) => {
    var list = referenceLibrary.filter((key) => {
      if (header !== 'Other') {
        return key.general.functions.includes(header.toLowerCase());
      } else {
        return key.general.functions.length === 0;
      }
    });
    return list;
  };

  const hasUserAccessToReference = (sua) => {
    const associatedSuaDirectory = relevantDirectories.find(
      (dir) =>
        (dir.designOwners.includes(user.id) || dir.designEditors.includes(user.id)) &&
        dir.children.includes(sua.general.id)
    ); 
    return Boolean(associatedSuaDirectory);
  };

  const handleClickOnReference = (value: any) => {
    if (selectedComponent) {
      const realSelectedComponent = components.find((c: any) => c.id === selectedComponent.id);
      const newComponent = _.cloneDeep(realSelectedComponent);
      implementComponent(
        newComponent,
        value,
        components,
        isLayerLocked,
        layerMode,
        editorAssemblyLibrary
      );
      setSelectedElements([]);
    }
    if (components.length < 1) {
      toast.warn(
        <FormattedMessage
          id="PleasePlaceAPFDFirstAndThenAddTheP&amp;ID"
          defaultMessage="Please place a PFD first and then add the P&amp;ID"
        />
      );
    }
  };

  const filteredList = (list) => {
    let filteredLibrary;
  
    if (typeof props.search === 'string') {
      filteredLibrary = list.filter(sua => 
        sua.general.name.toLowerCase().includes(props.search.toLowerCase())
      );
    } else {
      const searchedAssemblyIds = advancedSearch(referenceLibrary, props.search)?.map(a => a.general.id);
      filteredLibrary = searchedAssemblyIds ? 
        referenceLibrary.filter(a => searchedAssemblyIds.includes(a.general.id)) : 
        [...referenceLibrary];
    }
  
    return filteredLibrary.map(sua => {
      const isReferenceAccessible = hasUserAccessToReference(sua);
      return (
        <SingleReference
          key={sua.general.id}
          onClickSua={handleClickOnReference}
          sua={sua}
          selectedComponent={_selectedComponents}
          isReferenceAccessible={isReferenceAccessible}
        />
      );
    });
  };
  

  const checkDataSelectedComponent = () => {
    let datas = [];
    const componentData = { ...selectedComponent?.data } || {};
    if (componentData['volume']) {
      datas.push({
        type: 'volume',
        value: componentData['volume'],
        units: 'L'
      });
    }
    if (componentData['measuring type']) {
      datas.push({
        type: 'measuring type',
        value: componentData['measuring type'],
        units: ''
      });
    }
    if (componentData['flow rate']) {
      datas.push({
        type: 'flow rate',
        value: componentData['flow rate'],
        units: 'L/h'
      });
    }
    if (componentData['pressure']) {
      datas.push({
        type: 'pressure',
        value: componentData['pressure'],
        units: 'bar'
      });
    }
    return datas;
  };

  const renderList = (header: any) => {
    let list = filteredList(referenceLibrary);
    let datas = checkDataSelectedComponent();
    if (referenceLibrary.length > 0 && list.length > 0) {
      return (
        <>
          <div className="pt-2 f-row gap-2" style={{ flexWrap: 'wrap' }}>
            {datas.map((info) => (
              <Chip
                key={info.type}
                style={{ width: '1rem' }}
                color="var(--primaryColor)"
                backgroundColor={'var(--primaryColor)'}
                label={info.value + ' ' + info.units}
              />
            ))}
          </div>
          <div
            className="py-2 gap-2"
            style={{
              display: 'grid'
            }}>
            {list}
          </div>
        </>
      );
    } else {
      return (
        <div className="f-full">
          <Empty
            size="md"
            text={intl.formatMessage({
              id: 'PIDLibrary.EmptyText.NoReferenceFound',
              defaultMessage: 'No reference matching your search parameters'
            })}
          />
        </div>
      );
    }
  };

  if (
    (selectedComponent && selectedComponent?.data?.type === 'waste') ||
    selectedComponent?.data?.type === 'utilities'
  ) {
    return (
      <div style={{ marginLeft: 10 }}>
        A function {selectedComponent?.data?.type} can't be replaced by a single use assembly
        reference
      </div>
    );
  } else {
    return (
      <>
        <div className={'px-2 pb-2 f-col'} style={{ overflow: 'auto', height: 1, flexGrow: 1 }}>
          {SUAListToShow || _isEmptyListForFuction ? (
            isSUALibraryCharged ? (
              renderList(SUAListToShow)
            ) : (
              <SplashScreen />
            )
          ) : (
            <div className="f-full">
              <Empty
                size="md"
                text={intl.formatMessage({
                  id: 'PIDLibrary.NoSearchParameters',
                  defaultMessage:
                    'Please select a function or a functional assembly to start the search'
                })}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}
