import { Icon } from "hakobio-react-ui";
import { CSSProperties } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { CUSTOM_NODES } from "../../../../../constants/CUSTOM_NODES";
import { assemblyEditorSliceActions } from "../../../../../store/features/assemblyEditor/assemblyEditorSlice";
import { NodeItem } from "../../../Shared/EquipmentPanel/NodeItem";

import { EntityToDeleteTypes } from "../../../../../views/enum/EntityToDeleteType";

const nodeListStyle: CSSProperties = {
  display: "grid",
  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  gridAutoRows: 191,
  columnGap: 16,
  rowGap: 16,
};

const SingleUseCustomComponentLibrary = ({
  searchedValue,
  resetSearch,
}: {
  searchedValue: string;
  resetSearch: () => void;
}) => {
  const dispatch = useAppDispatch();
  const libComponentName = useAppSelector(
    (state) => state.assemblyEditorSlice.libComponentName,
  );
  const editorCustomComponentLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorCustomComponentLibrary,
  );

  let constants = Object.entries(CUSTOM_NODES).map((nodeObject: any) => {
    const [key, node] = nodeObject;
    return {
      key,
      type: "generic",
      node,
    };
  });

  const constant = constants?.find((constant: any) => {
    return constant.key === libComponentName;
  });

  const handleBack = () => {
    dispatch(assemblyEditorSliceActions.setLibComponentName(null));
    resetSearch();
  };

  const onDeleteCustomComponent = (component) => {
    dispatch(
      assemblyEditorSliceActions.setShowDeleteConfirmModal({
        entityId: component.id,
        entityName: CUSTOM_NODES[component.data.type]?.name,
        type: EntityToDeleteTypes.CustomComponent,
      }),
    );
  };

  const libComponents = editorCustomComponentLibrary?.length
    ? editorCustomComponentLibrary
        .filter(
          (customComponent) => customComponent.data.type === libComponentName,
        )
        .sort((a, b) => {
          if (a.data.customName < b.data.customName) {
            return -1;
          } else if (a.data.customName === b.data.customName) {
            return 0;
          } else {
            return 1;
          }
        })
        .filter((c) => {
          const name =
            c.data.customName || c.data.name || CUSTOM_NODES[c.data.type].name;
          return name.toLowerCase().includes(searchedValue.toLowerCase());
        })
    : [];

  return (
    <div className="p-2 f-full overflow-y-auto" style={{ height: 1 }}>
      <div
        className="f-row f2-center gap-2 p-2"
        style={{ backgroundColor: "var(--light-grey)" }}
      >
        <Icon name="previous" onClick={handleBack} />
        <div>{CUSTOM_NODES[libComponentName].name}</div>
      </div>

      <div className="p-2 f-full overflow-y-auto" style={nodeListStyle}>
        <NodeItem
          keyName={constant.key}
          node={constant.node}
          constant={constant}
          component={null}
          isCustomComponent={false}
          isShowingCustomLibrary={true}
          onDeleteCustomComponent={null}
        />
        {libComponents.map((cc: any) => {
          return (
            <NodeItem
              key={cc.id}
              keyName={constant.key}
              node={constant.node}
              constant={constant}
              component={cc}
              isShowingCustomLibrary={true}
              isCustomComponent={true}
              onDeleteCustomComponent={onDeleteCustomComponent}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SingleUseCustomComponentLibrary;
