import { defineMessages } from "react-intl";

export const actionMessages = defineMessages({
  save: {
    id: "Global.Action.Save",
    defaultMessage: "Save",
  },
  share: {
    id: "Global.Action.Share",
    defaultMessage: "Share",
  },
  saveStatus: {
    id: "Global.Action.SaveStatus",
    defaultMessage: "Save Status",
  },
  selectFolderToSave: {
    id: "Global.Versioning.SelectFolderToSave",
    defaultMessage: "Select folder to save  ",
  },
  edit: {
    id: "Global.Action.Edit",
    defaultMessage: "Edit",
  },
  open: {
    id: "Global.Action.Open",
    defaultMessage: "Open",
  },
  seeComponent: {
    id: "Global.Action.SeeComponent",
    defaultMessage: "See Component",
  },
  delete: {
    id: "Global.Action.Delete",
    defaultMessage: "Delete",
  },
  close: {
    id: "Global.Action.Close",
    defaultMessage: "Close",
  },
  cancel: {
    id: "Global.Action.Cancel",
    defaultMessage: "Cancel",
  },
  remove: {
    id: "Global.Action.Remove",
    defaultMessage: "Remove",
  },
  done: {
    id: "Global.Action.Done",
    defaultMessage: "Done",
  },
  OK: {
    id: "Global.Action.OK",
    defaultMessage: "OK",
  },
  search: {
    id: "Global.Action.Search",
    defaultMessage: "Search",
  },
  select: {
    id: "Global.Action.Select",
    defaultMessage: "Select",
  },
  update: {
    id: "Global.Action.Update",
    defaultMessage: "Update",
  },
  rotate: {
    id: "Global.Action.Rotate",
    defaultMessage: "Rotate",
  },
  flipHorizontal: {
    id: "Global.Action.FlipHorizontal",
    defaultMessage: "Flip horizontal",
  },
  flipVertical: {
    id: "Global.Action.Flip vertical",
    defaultMessage: "Flip vertical",
  },
  access: {
    id: "Global.Action.Access",
    defaultMessage: "Access",
  },
  typeEmailOrGroupName: {
    id: "Global.Action.TypeEmailOrGroupName",
    defaultMessage:
      'Type an email adress or a group name (press "Enter" to add)',
  },
});

export const generalMessages = defineMessages({
  name: {
    id: "Global.General.Name",
    defaultMessage: "Name",
  },
  folder: {
    id: "Global.General.Folder",
    defaultMessage: "Folder",
  },
  folders: {
    id: "Global.General.Folders",
    defaultMessage: "Folders",
  },
  generalInformation: {
    id: "Global.General.GeneralInformation",
    defaultMessage: "General Information",
  },
  createdBy: {
    id: "Global.General.CreatedBy",
    defaultMessage: "Created by",
  },
  modifiedBy: {
    id: "Global.General.ModifiedBy",
    defaultMessage: "Modified by",
  },
  advancedSearch: {
    id: "Global.General.AdvancedSearch",
    defaultMessage: "Advanced Search",
  },
  addUrl: {
    id: "Global.General.AddUrl",
    defaultMessage: "Add URL",
  },
  library: {
    id: "Global.General.Library",
    defaultMessage: "Library",
  },
  metrics: {
    id: "Global.General.Metrics",
    defaultMessage: "Metrics",
  },
  report: {
    id: "Global.General.Report",
    defaultMessage: "Report",
  },
  references: {
    id: "Global.General.References",
    defaultMessage: "References",
  },
  createReference: {
    id: "Global.General.CreateReference",
    defaultMessage: "Create a reference from this design",
  },
  exportReport: {
    id: "Global.General.ExportReport",
    defaultMessage: "Export report",
  },
  compareVersions: {
    id: "Global.General.CompareVersions",
    defaultMessage: "Compare versions",
  },
  none: {
    id: "Global.General.None",
    defaultMessage: "None",
  },
  new: {
    id: "Global.General.New",
    defaultMessage: "New",
  },
  noValue: {
    id: "Global.General.NoValue",
    defaultMessage: "No value",
  },
  linkReferenceToThisFunction: {
    id: "Global.General.LinkReferenceToThisFunction",
    defaultMessage: "Link reference to this function",
  },
  linkReferenceToThisAssembly: {
    id: "Global.General.LinkReferenceToThisAssembly",
    defaultMessage: "Link reference to this assembly",
  },
  noReferenceLinked: {
    id: "Global.General.NoReferenceLinked",
    defaultMessage: "No reference linked",
  },
  noDescription: {
    id: "Global.General.NoDescription",
    defaultMessage: "No description",
  },
  noSupplier: {
    id: "Global.General.NoSupplier",
    defaultMessage: "No supplier assigned",
  },
  areYouSure: {
    id: "Global.general.AreYouSure",
    defaultMessage: "Are you sure ?",
  },
  warningSave: {
    id: "Global.General.WarningSave",
    defaultMessage: "Link the following assemblies to save this Unit Operation",
  },
});

export const mediaMessages = defineMessages({
  media: {
    id: "Global.Media.Media",
    defaultMessage: "Media",
  },
  mediaDetails: {
    id: "Global.Media.MediaDetails",
    defaultMessage: "Media Details",
  },
  link: {
    id: "Global.Media.Link",
    defaultMessage: "Link",
  },
  links: {
    id: "Global.Media.Links",
    defaultMessage: "Links",
  },
  noExtrenalLink: {
    id: "Global.Media.NoExternalLink",
    defaultMessage: "No external link set",
  },
});

export const configMessages = defineMessages({
  function: {
    id: "Global.Config.Function",
    defaultMessage: "Function",
  },
  functionPFD: {
    id: "Global.Config.FunctionPFD",
    defaultMessage: "Function (PFD)",
  },
  functionsPFD: {
    id: "Global.Config.FunctionsPFD",
    defaultMessage: "Functions (PFD)",
  },
  noFunction: {
    id: "Global.Config.NoFunction",
    defaultMessage: "No function assigned",
  },
  functionsComponents: {
    id: "Global.Config.FunctionsComponents",
    defaultMessage: "Functions components",
  },
  assemblyType: {
    id: "Global.Config.AssemblyType",
    defaultMessage: "Assembly Type",
  },
  ancillaryFunction: {
    id: "Global.Config.AncillaryFunction",
    defaultMessage: "Ancillary function",
  },
  ancillaryFunctions: {
    id: "Global.Config.AncillaryFunctions",
    defaultMessage: "Ancillary functions",
  },
  container: {
    id: "Global.Config.Container",
    defaultMessage: "Container",
  },
  pumpType: {
    id: "Global.Config.PumpType",
    defaultMessage: "Pump Type",
  },
  bags2D: {
    id: "Global.Config.2DBags",
    defaultMessage: "2DBags",
  },
  connections: {
    id: "Global.Config.Connections",
    defaultMessage: "Connections",
  },
  connectionType: {
    id: "Global.Config.ConnectionType",
    defaultMessage: "Connection Type",
  },
  poreSizeMum: {
    id: "Global.Config.PoreSizeMum",
    defaultMessage: "Pore Size (µm)",
  },
  poreSizeMwco: {
    id: "Global.Config.PoreSizeMwco",
    defaultMessage: "Pore Size (MWCO)",
  },
  combinedPorosity: {
    id: "Global.Config.CombinedPorosity",
    defaultMessage: "Combined Porosity",
  },
  volume: {
    id: "Global.Config.Volume",
    defaultMessage: "Volume",
  },
  sensorType: {
    id: "Global.Config.SensorType",
    defaultMessage: "Sensor Type",
  },
  functionalSUA: {
    id: "Global.Config.FunctionalSua",
    defaultMessage: "Functional SUA",
  },
  supplier: {
    id: "Global.Config.Supplier",
    defaultMessage: "Supplier",
  },
  suppliers: {
    id: "Global.Config.Suppliers",
    defaultMessage: "Suppliers",
  },
  tubings: {
    id: "Global.Config.Tubings",
    defaultMessage: "Tubings",
  },
  tubingMaterial: {
    id: "Global.Config.TubingMaterial",
    defaultMessage: "Tubing Material",
  },
  tubingInner: {
    id: "Global.Config.TubingInner",
    defaultMessage: "Tubing Inner",
  },
  tubingLength: {
    id: "Global.Config.TubingLength",
    defaultMessage: "Tubing Length",
  },
  filtrationSurface: {
    id: "Global.Config.FiltrationSurface",
    defaultMessage: "Filtration Surface",
  },
  lines: {
    id: "Global.Config.Lines",
    defaultMessage: "Lines",
  },
  assignedFunctions: {
    id: "Global.Config.AssignedFunctions",
    defaultMessage: "Assigned function(s)",
  },
  functionsInvolved: {
    id: "Global.Config.FunctionsInvolved",
    defaultMessage: "Function(s) involved",
  },
  referencesInvolved: {
    id: "Global.Config.ReferencesInvolved",
    defaultMessage: "References involved",
  },
  PFD: {
    id: "Global.Config.PFD",
    defaultMessage: "PFD",
  },
  PID: {
    id: "Global.Config.PID",
    defaultMessage: "P&ID",
  },
  references: {
    id: "Global.Config.References",
    defaultMessage: "References",
  },
  components: {
    id: "Global.Config.Components",
    defaultMessage: "Components",
  },
  componentEditor: {
    id: "Global.Config.ComponentEditor",
    defaultMessage: "Component Editor",
  },
  componentViewer: {
    id: "Global.Config.ComponentViewer",
    defaultMessage: "Component Viewer",
  },
  componentsUsed: {
    id: "Global.Config.ComponensUsed",
    defaultMessage: "Components Used",
  },
  componentsLibrary: {
    id: "Global.Config.ComponensLibrary",
    defaultMessage: "Components Library",
  },
  sua: {
    id: "Global.Config.SingleUseAssembly",
    defaultMessage: "Single-Use Assembly",
  },
  reference: {
    id: "Global.Config.SingleUseReference",
    defaultMessage: "Single-Use Reference",
  },
  suaDesigner: {
    id: "Global.Config.SingleUseAssemblyDesigner",
    defaultMessage: "Single-Use Assembly Designer",
  },
  referenceDesigner: {
    id: "Global.Config.ReferenceDesigner",
    defaultMessage: "Reference Designer",
  },
  suas: {
    id: "Global.Config.SingleUseAssemblies",
    defaultMessage: "Single-Use Assemblies",
  },
  uo: {
    id: "Global.Config.UnitOperation",
    defaultMessage: "Unit Operation",
  },
  uos: {
    id: "Global.Config.UnitOperations",
    defaultMessage: "Unit Operations",
  },
  fluidTransfer: {
    id: "Global.Config.FluidTransfer",
    defaultMessage: "Transfer Port",
  },
  transferPort: {
    id: "Global.Config.TransferPort",
    defaultMessage: "Transfer Port",
  },
  instrumentationPort: {
    id: "Global.Config.InstrumentationPort",
    defaultMessage: "Instrumentation Port",
  },
  samplingPort: {
    id: "Global.Config.SamplingPort",
    defaultMessage: "Sampling Port",
  },
  ports: {
    id: "Global.Config.Ports",
    defaultMessage: "Ports",
  },
  noPort: {
    id: "Global.Config.NoPort",
    defaultMessage: "No port set",
  },
  transferPorts: {
    id: "Global.Config.TransferPorts",
    defaultMessage: "Transfer Ports",
  },
  instrumentationPorts: {
    id: "Global.Config.InstrumentationPorts",
    defaultMessage: "Instrumentation ports",
  },
  samplingPorts: {
    id: "Global.Config.SamplingPorts",
    defaultMessage: "Sampling ports",
  },
  addTransferPort: {
    id: "Global.Config.AddTransferPort",
    defaultMessage: "Add a Transfer Port",
  },
  addInstrumentationPort: {
    id: "Global.Config.AddInstrumentationPort",
    defaultMessage: "Add a Instrumentation Port",
  },
  addSamplingPort: {
    id: "Global.Config.AddSamplingPort",
    defaultMessage: "Add a Sampling Port",
  },
  selectAncillaryFunctions: {
    id: "Global.Config.SelectAncillaryFunctions",
    defaultMessage: "Select ancillary function(s)",
  },
  referenceBasedOnThisDesign: {
    id: "Global.General.ReferenceBasedOnThisDesign",
    defaultMessage: "References based on this design",
  },
});

export const versioningMessages = defineMessages({
  history: {
    id: "Global.Versioning.History",
    defaultMessage: "History",
  },
  versionAbr: {
    id: "Global.Versioning.VersionAbr",
    defaultMessage: "v.",
  },
  version: {
    id: "Global.Versioning.Version",
    defaultMessage: "Version",
  },
  versionComment: {
    id: "Global.Versioning.VersionComment",
    defaultMessage: "Version Comment",
  },

  currentVersion: {
    id: "Global.Versioning.CurrentVersion",
    defaultMessage: "Current Version",
  },
  seeVersion: {
    id: "Global.Versioning.SeeVersion",
    defaultMessage: "See Version",
  },
  view: {
    id: "Global.Versioning.View",
    defaultMessage: "View",
  },
  viewing: {
    id: "Global.Versioning.Viewing",
    defaultMessage: "Viewing",
  },
  compareWith: {
    id: "Global.Versioning.CompareWith",
    defaultMessage: "Compare with",
  },
  comparingWith: {
    id: "Global.Versioning.ComparingWith",
    defaultMessage: "Comparing with",
  },
  lastParentheses: {
    id: "Global.Versioning.LastParentheses",
    defaultMessage: "(last)",
  },
  current: {
    id: "Global.Versioning.Current",
    defaultMessage: "Current",
  },
  comment: {
    id: "Global.Versioning.Comment",
    defaultMessage: "Comment",
  },
  archived: {
    id: "Global.Versioning.Archived",
    defaultMessage: "archived",
  },
  componentAdded: {
    id: "Global.Versioning.ComponentAdded",
    defaultMessage: `{name} has been added in {version} {number}`,
  },
  componentNotExisting: {
    id: "Global.Versioning.ComponentNotExisting",
    defaultMessage: `{name} didn't exist in {version} {number}`,
  },
  componentDeleted: {
    id: "Global.Versioning.ComponentDeleted",
    defaultMessage: `{name} has been deleted in {version} {number}`,
  },
  updateToLastVersion: {
    id: "Global.Action.UpdateToLastVersion",
    defaultMessage: "Update to last version",
  },
});

export const statusMessages = defineMessages({
  selectStatus: {
    id: "Global.Status.SelectStatus",
    defaultMessage: "Select status",
  },
  status: {
    id: "Global.Status.Status",
    defaultMessage: "Status",
  },
  noStatus: {
    id: "Global.Status.NoStatus",
    defaultMessage: "No status",
  },
  saveBeforeChange: {
    id: "Global.Status.SaveBeforeChange",
    defaultMessage:
      "Your assembly must be saved at least once before changing its status",
  },
  shareBeforeChange: {
    id: "Global.Status.ShareBeforeChange",
    defaultMessage:
      "Your assembly must be set as 'Public' before changing its status",
  },
  changeStatusNotAllowed: {
    id: "Global.Status.ChangeStatusNotAllowed",
    defaultMessage: "You can't change the status of this assembly.",
  },
  contactOwner: {
    id: "Global.Status.ContactOwner",
    defaultMessage:
      "Please contact the design owner to change the status of this assembly.",
  },
  statusChangedSUAS: {
    id: "Global.Status.StatusChanged",
    defaultMessage: "Status can only be changed by Design Owners",
  },
  statusChangedUO: {
    id: "Global.Status.StatusChangedUO",
    defaultMessage: "Status can only be changed by Design Owners",
  },
});
