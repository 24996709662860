import { MenuItem } from '@mui/material';
import {
  Chip,
  Icon,
  Label,
  Select,
  ThinChip,
  Title,
  Tooltip,
  UserBadge,
  UserBadgeSelect
} from 'hakobio-react-ui';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CUSTOM_FUNCTIONS } from '../../../../constants/CUSTOM_FUNCTIONS';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';

import LinksTab from '../ReferencePanel/LinksTab';
import SuaComponents from './SuaComponents';

import { ViewMode } from '../../../../constants/ViewMode';
import { cloneDeep } from 'lodash';
import { configMessages } from '../../../../lang/messages';
import { EditorMode } from '../../../../constants/EditorMode';
import { EntityToDeleteTypes } from '../../../../views/enum/EntityToDeleteType';
import ReferenceLabelDescription from '../../Shared/ReferenceCards/ReferenceLabelDescription';
import { useCustomBehavior } from '../../../../utils';
import { DirectoryFolder } from '../../../models/DirectoryFolder';

interface ReferenceGeneralPanelProps {
  editable: boolean;
  sua: any;
  component: any;
}

export const ReferenceGeneralPanel: FC<ReferenceGeneralPanelProps> = ({
  editable,
  sua,
  component
}: ReferenceGeneralPanelProps) => {
  const dispatch = useAppDispatch();

  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);

  const allHeadersLibrary = useAppSelector((state) => state.assemblyEditorSlice.allHeadersLibrary);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const folder = directoriesList.find((f: DirectoryFolder) => f.id === general.parentFolderId);
  const userList = useAppSelector((state) => state.assemblyEditorSlice.userList);

  const [_isGenericSuaNameHovered, setGenericSuaNameHovered] = useState(false);
  const [_designOwners, setDesignOwners] = useState([]);
  const dropdownValues = [
    // { name: 'Description', icon: 'about' }, // Battle Fabio vs. Emilie => if Emilie wins don't forget to change the state of _dropdownValue to be dropdownValues[1]
    { name: 'Design', icon: 'design-pencil-rule' },
    { name: 'Links', icon: 'web-link' }
  ];
  const [_dropdownValue, setDropdownValue] = useState(dropdownValues[0]);

  const { openReactAppHistoryInNewTab } = useCustomBehavior();

  const intl = useIntl();

  useEffect(() => {
    if (folder) {
      let designOwners = [];
      folder.designOwners.map((designOwner) => {
        designOwners.push(userList.find((user) => user.userId === designOwner));
      });
      setDesignOwners(designOwners);
    }
  }, [directoriesList, userList, folder]);

  const openParentAssembly = (assembly) => {
    let assemblyCopy = cloneDeep(assembly);
    assemblyCopy.hashPartitionKey = assembly.id + '-' + assembly.version;
    localStorage.setItem('action', 'assemblyVersion');
    localStorage.setItem('actionData', JSON.stringify(assemblyCopy));
    openReactAppHistoryInNewTab();
  };

  const unselectReferenceSua = () => {
    dispatch(assemblyEditorSliceActions.selectComponents([]));
  };

  const renderFolderInfo = () => {
    return (
      <>
        <div className="f-row py-2 ">
          <div className={`f-row f2-center gap-2`}>
            <Tooltip title={'Directory'}>
              <Icon name="folder" />
            </Tooltip>
            {folder?.name}
          </div>
        </div>

        <div className="w-10 border-bottom mb-2" />
      </>
    );
  };

  const designOwners = () => {
    return (
      <>
        <Title
          className="mb-0"
          children={intl.formatMessage({
            id: 'SUConfigView.EditFolderModal.DesignOwners',
            defaultMessage: 'Design Owners'
          })}></Title>
        <div className="pt-1 f-row f2-center gap-2 f-wrap">
          {_designOwners.map((designOwner) => {
            return (
              <div>
                <UserBadge
                  firstName={designOwner.firstname}
                  lastName={designOwner.lastname}
                  size={_designOwners.length > 1 ? 'small' : 'medium'}
                  title={
                    <div className="f-col">
                      <div className="font-regular">
                        {designOwner.firstname} {designOwner.lastname}
                      </div>
                      {designOwner.email}
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderGenericSUAInfos = () => {
    const tooltipContent = (
      <div className="f-col gap-2">
        {sua.general?.functions.map((fct: string, index: number) => (
          <div className="f-row gap-1 f2-center" key={CUSTOM_FUNCTIONS[fct].name}>
            {CUSTOM_FUNCTIONS[fct].form(16)}
            {CUSTOM_FUNCTIONS[fct].name}
          </div>
        ))}
      </div>
    );
    let genericSUAList = [];
    sua.general?.assemblyGenericList.forEach((sua) => {
      const genericSua = allHeadersLibrary?.find((a) => a.id === sua.parameters[0].value);
      if (genericSua) genericSUAList.push(genericSua);
    });

    return (
      <div className="py-2 f-col gap-2">
        <div className="f-row f2-center gap-2">
          {!editable && <Icon name="previous" onClick={unselectReferenceSua} />}

          {sua.general?.functions.length ? (
            sua.general?.functions.map((fct: string, index: number) => {
              const fctItem = `${CUSTOM_FUNCTIONS[fct].name}${
                sua.general.functions.length === index + 1 ? '' : ', '
              }`;
              return (
                <div className="w-100 f-row f2-center gap-2">
                  {CUSTOM_FUNCTIONS[fct].form(16)}
                  <div className="w-100">
                    <Tooltip title={tooltipContent}>
                      <ThinChip
                        label={
                          <div
                            className="f-row f2-center gap-2"
                            key={CUSTOM_FUNCTIONS[fct].name + index}>
                            {fctItem}
                            {/*  <span style={{ textTransform: 'lowercase' }}>
                              {intl.formatMessage(configMessages.function)}
                            </span> */}
                          </div>
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            })
          ) : (
            <ThinChip label={intl.formatMessage(configMessages.noFunction)} />
          )}
        </div>
        {genericSUAList.map((gSua) => (
          <div
            key={gSua.name}
            className="f-row f1-between f2-center container-action "
            onMouseEnter={() => setGenericSuaNameHovered(true)}
            onMouseLeave={() => setGenericSuaNameHovered(false)}>
            <Icon className="mr-2" name="manifold" color="var(--primaryColor)" />
            <ThinChip
              color={_isGenericSuaNameHovered ? 'white' : 'var(--primaryColor)'}
              backgroundColor={
                _isGenericSuaNameHovered ? 'var(--primaryColor)' : 'var(--primaryColorBcg)'
              }
              title={intl.formatMessage({
                id: 'ReferenceGeneralPanel.GenericName',
                defaultMessage: 'Generic SUA'
              })}
              label={gSua.name}
              className="w-100"
            />
            {editable && (
              <Icon
                className="items-action-none ml-2"
                name="eye"
                onClick={() => openParentAssembly(gSua)}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const refActionIcons = () => {
    if (viewMode === ViewMode.History || viewMode === ViewMode.HistoryUO) return null;
    return (
      <>
        {(sua.general?.permissions?.update || sua.general?.permissions?.changeStatus) &&
          editable && (
            <Icon
              className="items-action-none"
              name="pencil"
              onClick={() =>
                dispatch(assemblyEditorSliceActions.setShowReferenceEditionModal(true))
              }
            />
          )}
        {editorMode === EditorMode.UnitOperation && <Icon name="bin" onClick={onDelete} />}
      </>
    );
  };

  const onDelete = () => {
    dispatch(
      assemblyEditorSliceActions.setShowDeleteConfirmModal({
        entityId: component.id,
        entityName: component.name,
        type: EntityToDeleteTypes.Reference
      })
    );
  };

  const displayDropDown = () => {
    return (
      <>
        <Select
          renderValue={(value) => (
            <div className="f-row f2-center icon-parent gap-2 mb-2 pt-2">
              <Icon name={_dropdownValue.icon} />
              {value}
            </div>
          )}
          underlineDisabled
          value={_dropdownValue.name}
          onChange={(event: any) => {
            setDropdownValue(event.target.value);
          }}
          variant="standard">
          {dropdownValues
            .filter((aF) => !_dropdownValue.name.includes(aF.name))
            .map((e: any) => (
              <MenuItem
                className="f-row f2-center gap-2"
                style={{ backgroundColor: 'transparent' }}
                key={e}
                value={e}>
                <Icon name={e.icon} />
                {e.name}
              </MenuItem>
            ))}
        </Select>
        <div className="w-10 border-bottom" style={{ marginTop: -8 }} />
      </>
    );
  };

  const renderPanel = () => {
    let content = null;
    switch (_dropdownValue?.name) {
      case 'Description':
        content = <div className="p-2">{sua.general.description}</div>;
        break;
      case 'Design':
        content = <SuaComponents components={sua.components} />;
        break;
      case 'Links':
        content = <LinksTab />;
        break;
      default:
        break;
    }
    return <div className="pt-2 pb-1 f-col f-full">{content}</div>;
  };

  return (
    <div className="f-full f-col px-2 relative">
      {folder && renderFolderInfo()}
      {renderGenericSUAInfos()}
      <div className="border-bottom w-10 my-2" />
      {folder && designOwners()}
      <ReferenceLabelDescription
        name={sua.general?.name}
        actionIcon={refActionIcons()}
        status={sua.general?.status}
        supplier={sua.general?.supplier}
        media={sua.general?.medias}
      />
      {displayDropDown()}
      {renderPanel()}
    </div>
  );
};
