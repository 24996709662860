import { useDrop } from "react-dnd";
import { useAppDispatch, useAppSelector } from "../../../store";
import { assemblyEditorSliceActions } from "../../../store/features/assemblyEditor/assemblyEditorSlice";

import { Icon, ThinChip, Tooltip } from "hakobio-react-ui";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { EntityDisplayed } from "../../../constants/EntityDisplayed";
import { moveAssemblyToDirectory } from "../../../services/editor/editorService";
import { ItemTypes } from "../../StartupPanel/Assembly";

const FolderLine = (props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { folder, directoriesList, isDragging } = props;
  const selectedFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedFolder,
  );
  const userList = useAppSelector(
    (state) => state.assemblyEditorSlice.userList,
  );
  const [_isDesignOwner, setIsDesignOwner] = useState(false);
  const [_isDesignEditor, setIsDesignEditor] = useState(false);
  const user = useAppSelector((state) => state.assemblyEditorSlice.user);
  const [_folderLength, setFolderLength] = useState(0);

  useEffect(() => {
    const updateDesignRoles = () => {
      if (
        folder &&
        folder.designOwners &&
        folder.designOwners.includes(user.id)
      ) {
        const currentUser = userList.find((u) => u.userId === user.id);
        setIsDesignOwner(!!currentUser);
      }

      if (
        folder &&
        folder.designEditors &&
        folder.designEditors.includes(user.id)
      ) {
        const currentUser = userList.find((u) => u.userId === user.id);
        setIsDesignEditor(!!currentUser);
      }
      setFolderLength(folder.children.length + folder.shortCuts.length);
    };

    if (userList) {
      updateDesignRoles();
    }
  }, [folder, user, userList]);

  const selectFolder = () => {
    dispatch(
      assemblyEditorSliceActions.setEntitiesDisplayed(
        EntityDisplayed.SuaReference,
      ),
    );
    dispatch(assemblyEditorSliceActions.selectFolder(folder));
  };

  const deleteSelectedFolder = () => {
    dispatch(assemblyEditorSliceActions.setShowDeleteFolderModal(folder));
  };

  async function dropAssembly(assembly) {
    try {
      const directoriesCopy = cloneDeep(directoriesList);
      let previousDirectory = directoriesCopy.find((directory: any) =>
        directory.children.includes(assembly.id),
      );

      if (previousDirectory?.id === folder.id) {
        return;
      }
      let result = await moveAssemblyToDirectory(assembly.id, folder.id);
      if (result.status) {
        throw new Error(result.message);
      }

      if (!!previousDirectory) {
        previousDirectory.children = previousDirectory.children.filter(
          (child) => child !== assembly.id,
        );
      }
      const newDirectory = directoriesCopy.find(
        (directory: any) => directory.id === folder.id,
      );
      newDirectory.children.push(assembly.id);
      assembly.parentFolderId = folder.id;
      dispatch(assemblyEditorSliceActions.setAllDirectories(directoriesCopy));
      dispatch(assemblyEditorSliceActions.selectFolder(previousDirectory));
      toast.success(
        `Assembly ${assembly.name} moved to ${folder.name} folder with success`,
      );
    } catch (e) {}
  }

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.DRAGGING_BADGE, // canDrop: (item: any) => {
      //   if (folder.permissions.assemblyUpdate && item.permissions.update) {
      //     return true;
      //   }
      //   return false;
      // },
      canDrop: (item: any) => {
        if (folder && item) {
          return folder.permissions.assemblyMove;
        }
        return false;
      },
      drop: (item: any) => {
        dropAssembly(item);
        document.body.style.cursor = "default";
      },
      hover(item: any, monitor: any) {
        if (!monitor.canDrop() && monitor.isOver()) {
          document.body.style.cursor = "not-allowed";
        } else {
          document.body.style.cursor = "default";
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [selectFolder, folder],
  );

  const isActive = canDrop && isOver;

  const folderNameStyle = {
    color:
      (selectedFolder?.id === folder.id ? "var(--primaryColor)" : undefined) ||
      (folder.permissions.assemblyMove
        ? undefined
        : isDragging
          ? "var(--frozen-grey)"
          : undefined),
  };

  return (
    <div
      ref={drop}
      style={{
        backgroundColor:
          selectedFolder?.id === folder.id || isActive
            ? "var(--light-grey)"
            : undefined,
      }}
      key={folder.id}
      onClick={selectFolder}
      className={"hover-item f-row f2-start gap-2 p-2 container-action"}
    >
      {folder.permissions.assemblyMove ? (
        <Icon
          name={isActive ? "folder-black" : "folder"}
          style={{
            marginTop: ".2rem",
            color: isActive && "var(--primaryColor)",
          }}
        />
      ) : isDragging ? (
        <Icon
          name="padlock"
          style={{
            marginTop: ".2rem",
            color: "var(--frozen-grey)",
          }}
        />
      ) : (
        <Icon
          name={isActive ? "folder-black" : "folder"}
          style={{
            marginTop: ".2rem",
            color: isActive && "var(--primaryColor)",
          }}
        />
      )}
      <div className="w-100 f-col">
        <div className={"f-row gap-2 w-100 f1-between f2-center"}>
          <div
            className="f-row gap-2 f2-center w-100 font-regular"
            style={{ ...folderNameStyle }}
          >
            {folder.name}
          </div>
          <div className="f-row f2-center gap-2">
            {folder.permissions.update && (
              <Icon
                className="items-action-none"
                style={{ lineHeight: 0.5 }}
                onClick={() =>
                  dispatch(assemblyEditorSliceActions.setEditingFolder(true))
                }
                name="pencil"
              />
            )}
            {folder.permissions.delete && (
              <Tooltip
                title={
                  folder.children.length === 0
                    ? ""
                    : intl.formatMessage({
                        id: "SUD.FolderLine.EmptyFolder",
                        defaultMessage: "Empty folder in order to delete it",
                      })
                }
              >
                <Icon
                  className="items-action-none"
                  style={{ lineHeight: 0.5 }}
                  onClick={
                    folder.children.length === 0
                      ? () => deleteSelectedFolder()
                      : undefined
                  }
                  hoverColor={
                    folder.children.length === 0
                      ? "var(--red)"
                      : "var(--frozen-grey)"
                  }
                  name="bin"
                  disabled={folder.children.length !== 0}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="f-row f1-between f2-center">
          <div className="small-text" style={{ ...folderNameStyle }}>
            {_folderLength}
            {_folderLength === 1 || _folderLength === 0
              ? " element"
              : " elements"}
          </div>
          <div className="f-row f2-center gap-2">
            {_isDesignOwner && (
              <div>
                <ThinChip
                  color="var(--primaryColor)"
                  backgroundColor="var(--primaryColorBcg)"
                  fontSize="smallText"
                  label={
                    <div className="f-row gap-2 f2-center">
                      <Icon name="key" />
                      {intl.formatMessage({
                        id: "SUConfigView.EditFolderModal.Owner",
                        defaultMessage: "Owner",
                      })}
                    </div>
                  }
                />
              </div>
            )}
            {_isDesignEditor && !_isDesignOwner && (
              <div>
                <ThinChip
                  color="var(--primaryColor)"
                  backgroundColor="var(--primaryColorBcg)"
                  fontSize="smallText"
                  label={
                    <div className="f-row gap-2 f2-center">
                      <Icon name="pencil" />
                      {intl.formatMessage({
                        id: "SUConfigView.EditFolderModal.Editor",
                        defaultMessage: "Editor",
                      })}
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderLine;
