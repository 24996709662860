import {
  ButtonFooter,
  DoubleChip,
  SplashScreen,
  Select,
  Title,
  CheckBox,
  Tooltip,
  ThinChip,
  Icon
} from 'hakobio-react-ui';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { convertForSaveComponent } from '../../../../services/editor/editorServiceUtilities';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { NodePropertyArray } from './NodePropertyDisplay/NodePropertyArray';
import { NodePropertyBoolean } from './NodePropertyDisplay/NodePropertyBoolean';
import { NodePropertyString } from './NodePropertyDisplay/NodePropertyString';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_FUNCTIONS } from '../../../../constants/CUSTOM_FUNCTIONS';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';
import { EditorMode } from '../../../../constants/EditorMode';
import { ViewMode } from '../../../../constants/ViewMode';
import { saveCustomComponent } from '../../../../services/editor/editorService';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useEditorLibraryAction } from '../../../../store/features/assemblyEditor/useEditorLibraryAction';
import { NodeArrayComponent } from './NodePropertyDisplay/NodeArrayComponent';
import NodePropertyNumber from './NodePropertyDisplay/NodePropertyNumber';
import { EntityToDeleteTypes } from '../../../../views/enum/EntityToDeleteType';
import { useLinkEditorAction } from '../../../../store/features/assemblyEditor/useLinkEditorAction';
import { useIntl } from 'react-intl';
import PresetTubingsModal from './Tubings/PresetTubingsModal';
import { useAssemblyEditorAction } from '../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { MenuItem, OutlinedInput, SelectChangeEvent } from '@mui/material';
import { configMessages, generalMessages } from '../../../../lang/messages';
import { cloneDeep } from 'lodash';

interface Props {
  components: Array<any>;
  selectedComponent: any;
}

export function DetailPanel({ components, selectedComponent }: Props) {
  const dispatch = useAppDispatch();
  const { getEditorCustomComponentLibrary } = useEditorLibraryAction();
  const { joinSUAComponents } = useLinkEditorAction();
  const { onComponentPropertyChanged } = useAssemblyEditorAction();

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const customComponentViewOnly = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentViewOnly
  );

  const isSavingScreenshotComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotComponent
  );

  const [_schema, setSchema] = useState<any | null>(null);
  const [_name, setName] = useState<any | null>(null);
  const [_displayParameters, setDisplayParameters] = useState(false);
  const [_isValidValue, setIsValidValue] = useState(true);
  const [_isEditing, setIsEditing] = useState(false);
  const [_presetTubingsModal, setPresetTubingsModal] = useState(false);
  const [_customComponentId, setCustomComponentId] = useState<null | string>(null);
  const [_ancillaryFunctionsList, setAncillaryFunctionsList] = useState(
    selectedComponent?.data?.ancillaryFunctions?.[0].complexParameters || []
  );

  const intl = useIntl();

  const isTubing = selectedComponent.data.type === 'tubing';

  const availableFunctions: string[] = Object.keys(CUSTOM_FUNCTIONS).sort();

  const getSchemaAsync = async () => {
    if (selectedComponent) {
      setSchema(null);
      const type = selectedComponent.data.type || 'tubing';
      try {
        let importedValue = null;
        switch (selectedComponent.type) {
          case 'generic':
          case 'genericonedge':
          case 'genericedge':
            setName(CUSTOM_NODES[type].name);
            importedValue = await CUSTOM_NODES[type].schema();
            break;
          case 'function':
          case 'functionedge':
            setName(CUSTOM_FUNCTIONS[type].name);
            importedValue = await CUSTOM_FUNCTIONS[type].schema();
            break;
        }
        setSchema(importedValue);
      } catch (error) {
        setSchema(null);
      }
    }
  };

  useEffect(() => {
    getSchemaAsync();
  }, []);

  useEffect(() => {}, [_isEditing]);

  useEffect(() => {
    getSchemaAsync();
  }, [selectedComponent.id]);

  const showModal = () => {
    let componentName =
      selectedComponent.type === 'generic' ||
      selectedComponent.type === 'genericedge' ||
      selectedComponent.type === 'genericonedge'
        ? CUSTOM_NODES[selectedComponent.data.type].name
        : CUSTOM_FUNCTIONS[selectedComponent.data.type].name;
    dispatch(
      assemblyEditorSliceActions.setShowDeleteConfirmModal({
        entityId: selectedComponent.id,
        type: EntityToDeleteTypes.Component,
        entityName: componentName
      })
    );
  };

  const renderEditParameters = () => {
    if (_isValidValue) {
      setIsEditing(!_isEditing);
      setDisplayParameters(!_displayParameters);
    } else {
      setIsEditing(!_isEditing);
      return undefined;
    }
  };

  const saveAsTemplate = async () => {
    if (selectedComponent.data.type !== 'tubing') {
      dispatch(assemblyEditorSliceActions.setSavingScreenshotComponent(true));
    }
    const convertedComponent = convertForSaveComponent(selectedComponent);
    convertedComponent.id = uuidv4();
    setCustomComponentId(convertedComponent.id);
    try {
      await saveCustomComponent(convertedComponent);
      toast.success('Component saved');
      getEditorCustomComponentLibrary();
    } catch {
      toast.error('Component not saved');
    }
  };

  const selectPresetTubing = () => {
    dispatch(assemblyEditorSliceActions.setLibComponentName('tubing'));
    setPresetTubingsModal(true);
  };

  const joinComponents = () => {
    const sourceComponent = components.find((c) => c.id === selectedComponent.source);
    const targetComponent = components.find((c) => c.id === selectedComponent.target);
    joinSUAComponents(false, selectedComponent, sourceComponent, targetComponent);
  };

  const showRightInput = (key: string, property: any, index: number) => {
    let returnValue = null;

    switch (property.type) {
      case 'string':
        returnValue = (
          <NodePropertyString
            selectedComponent={selectedComponent}
            key={selectedComponent.id + index}
            displayEdition={_displayParameters}
            keyProperty={key}
            property={property}
          />
        );
        break;
      case 'number':
        returnValue = (
          <NodePropertyNumber
            selectedComponent={selectedComponent}
            key={selectedComponent.id + index}
            setIsValid={(s) => setIsValidValue(s)}
            displayEdition={_displayParameters}
            keyProperty={key}
            property={property}
          />
        );
        break;
      case 'array':
        switch (selectedComponent.type) {
          case 'functionedge':
          case 'genericedge':
            returnValue = null;
            break;
          default:
            returnValue =
              editorMode === EditorMode.Component ? (
                <NodeArrayComponent
                  property={property}
                  keyProperty={key}
                  schema={_schema}
                  selectedComponent={selectedComponent}
                />
              ) : (
                <NodePropertyArray
                  key={selectedComponent.id + index}
                  displayParameters={_displayParameters}
                  schema={_schema}
                  keyProperty={key}
                  property={property}
                  customComponentId={_customComponentId}
                />
              );
        }
        break;
      case 'boolean':
        returnValue = (
          <NodePropertyBoolean
            selectedComponent={selectedComponent}
            displayParameters={_displayParameters}
            keyProperty={key}
          />
        );
        break;
      default:
        returnValue = (
          <div key={selectedComponent.id + index}>Cas non géré encore : {property.type}</div>
        );
    }
    if (!returnValue) {
      return null;
    }
    return (
      <div
        className={`f-col `} //${property.type === 'array' ? ' f-full' : ''}
        key={selectedComponent.id + index}>
        {returnValue}
      </div>
    );
  };

  if (!selectedComponent && !_schema) {
    return <div></div>;
  }

  const updateAncillaryFunctions = (e) => {
    setAncillaryFunctionsList(e.target.value);
    let ancillaryFunctionObject = [
      {
        id: uuidv4(),
        name: selectedComponent.data?.type || null,
        type: 'array',
        value: '',
        complexParameters: e.target.value
      }
    ];

    let copySelectedComponent = cloneDeep(selectedComponent);
    copySelectedComponent.data = {
      ...copySelectedComponent.data,
      ancillaryFunctions: ancillaryFunctionObject
    };
    onComponentPropertyChanged(
      copySelectedComponent,
      'ancillaryFunctions',
      ancillaryFunctionObject
    );
    //dispatch(assemblyEditorSliceActions.updateComponent(copySelectedComponent));
  };

  const renderTooltipTitle = () => {
    return _ancillaryFunctionsList?.length ? (
      <div>
        {_ancillaryFunctionsList.map((fct) => {
          return (
            <div className="f-row gap-2 f2-center" key={fct.id}>
              <div>{CUSTOM_FUNCTIONS[fct]?.form(16)}</div>
              <div>{CUSTOM_FUNCTIONS[fct]?.name}</div>
            </div>
          );
        })}
      </div>
    ) : (
      ''
    );
  };

  return (
    <div
      className="f-full f-col p-0"
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: 1
      }}>
      {_presetTubingsModal && (
        <PresetTubingsModal closeModal={() => setPresetTubingsModal(false)} />
      )}
      <Title className="px-2">
        <div className="f-row f1-between f2-center">
          <div className="f-row f2-center gap-2">
            {editorMode === EditorMode.SUA &&
              selectedComponent.data.type !== 'tubing' &&
              CUSTOM_NODES[selectedComponent.data.type].form(20)}
            {_name}
          </div>
          {((general?.permissions?.update && viewMode === ViewMode.Editor) ||
            (editorMode === EditorMode.Component && !customComponentViewOnly)) && (
            <div className="f-row gap-2">
              {isTubing && editorMode !== EditorMode.Component && !_isEditing && (
                <Icon name={'link'} title="Join components" onClick={joinComponents} />
              )}
              {!_isEditing ? (
                <Icon pointer name={'pencil'} onClick={renderEditParameters} />
              ) : (
                <Icon pointer name={'chat'} onClick={renderEditParameters} />
              )}
              {editorMode !== EditorMode.Component && <Icon name={'bin'} onClick={showModal} />}
            </div>
          )}
        </div>
      </Title>
      <div
        className="f-full f-col gap-2 p-2"
        style={{
          overflowY: 'auto',
          height: 1,
          position: 'relative',
          marginBottom: editorMode === EditorMode.SUA && _isEditing && isTubing ? '3rem' : 0
        }}>
        {editorMode === EditorMode.UnitOperation &&
          selectedComponent &&
          (!_isEditing ? (
            <div className="f-row gap-2">
              {CUSTOM_FUNCTIONS[selectedComponent?.data?.type]?.form(20)}
              {_ancillaryFunctionsList.length ? (
                <DoubleChip
                  className="max-1-lines-visible w-100"
                  label1Ratio={'25%'}
                  key={selectedComponent?.data?.id}
                  label1={
                    <span className="capitalize">
                      {CUSTOM_FUNCTIONS[selectedComponent?.data?.type].name || 'No name'}
                    </span>
                  }
                  label2={
                    _ancillaryFunctionsList.length ? (
                      _ancillaryFunctionsList.map((name, index) => {
                        return (
                          <Tooltip key={name + index} title={renderTooltipTitle()}>
                            <span>
                              {index === _ancillaryFunctionsList.length - 1
                                ? CUSTOM_FUNCTIONS[name]?.name
                                : CUSTOM_FUNCTIONS[name]?.name + ', '}
                            </span>
                          </Tooltip>
                        );
                      })
                    ) : (
                      <span className="frozen-grey">
                        {intl.formatMessage(generalMessages.noValue)}
                      </span>
                    )
                  }
                />
              ) : (
                <ThinChip
                  className="w-100"
                  key={selectedComponent?.data?.id}
                  label={CUSTOM_FUNCTIONS[selectedComponent?.data?.type]?.name || 'No name'}
                />
              )}
            </div>
          ) : (
            <Select
              inputSize="small"
              multiple
              label={intl.formatMessage(configMessages.selectAncillaryFunctions)}
              value={_ancillaryFunctionsList}
              renderValue={(value) => {
                // @ts-ignore
                const functionList: string[] = value || [];
                return (
                  <div>
                    {functionList.map((name, index) => {
                      return (
                        <span key={name + index}>
                          {index === functionList.length - 1
                            ? CUSTOM_FUNCTIONS[name]?.name
                            : CUSTOM_FUNCTIONS[name]?.name + ', '}
                        </span>
                      );
                    })}
                  </div>
                );
              }}
              onChange={(e) => updateAncillaryFunctions(e)}>
              {availableFunctions
                .filter((aF) => !general.functions.includes(aF))
                .filter(
                  (fc) =>
                    fc !== 'waste' &&
                    fc !== 'utilities' &&
                    /* fc !== 'mural transferring' &&  // voir avec Emilie car le client voudrait remettre cette fonction */
                    fc !== 'purification' &&
                    fc !== 'transferring' &&
                    fc !== selectedComponent?.data?.type
                )
                .map((fct, index) => {
                  return (
                    <MenuItem value={fct} key={fct + index}>
                      <div className="f-row f2-center gap-2">
                        <CheckBox
                          onChange={undefined}
                          checked={_ancillaryFunctionsList.includes(fct)}
                        />
                        {CUSTOM_FUNCTIONS[fct].name}
                      </div>
                    </MenuItem>
                  );
                })}
            </Select>
          ))}
        {_schema ? (
          Object.entries(_schema.properties)
            .filter(([key, property]) => key !== 'name')
            .map(([key, property]: any, index: number) => {
              return showRightInput(key, property, index);
            })
        ) : (
          <SplashScreen />
        )}
      </div>
      {general?.permissions?.update &&
        viewMode === ViewMode.Editor &&
        editorMode === EditorMode.SUA && (
          <ButtonFooter
            inverse={isTubing && !_isEditing}
            loading={isSavingScreenshotComponent}
            onClick={isTubing && _isEditing ? selectPresetTubing : saveAsTemplate}
            arrow={isTubing && _isEditing}>
            <div className="gap-2 f-row f2-center">
              <Icon name="book" />
              <span>
                {isTubing && _isEditing
                  ? intl.formatMessage({
                      id: 'DetailPanel.SelectPresetTubing',
                      defaultMessage: 'Select from preset tubings'
                    })
                  : isTubing
                  ? intl.formatMessage({
                      id: 'DetailPanel.SavePresetTubing',
                      defaultMessage: 'Save as a preset tubing'
                    })
                  : intl.formatMessage({
                      id: 'DetailPanel.SaveCustomComponent',
                      defaultMessage: 'Save as a custom component'
                    })}
              </span>
            </div>
          </ButtonFooter>
        )}
    </div>
  );
}
