import { Chip, Icon, IconTooltip, ThinChip, Title, Tooltip, UserBadge } from 'hakobio-react-ui';
import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { CUSTOM_FUNCTIONS } from '../../../../../constants/CUSTOM_FUNCTIONS';
import { configMessages } from '../../../../../lang/messages';
import { config } from '../../../../../services/config';
import {
  GenericStatus,
  genericStatusBackgroundColors,
  genericStatusColors
} from '../../../../../utilities/statuses/genericStatus';
import SingleComponent from './SingleComponent';
import { useAppSelector } from '../../../../../store';
import { DirectoryFolder } from '../../../../models/DirectoryFolder';
import { useEffect, useState } from 'react';

const SingleSUA = ({ sua, selectedComponents, onClickSua, isSuaAccessible }) => {
  const directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const folder = directoriesList.find((f: DirectoryFolder) => f.id === sua.general.parentFolderId);
  const userList = useAppSelector((state) => state.assemblyEditorSlice.userList);
  const componentList = orderBy(sua.components, (comp) => comp.data.type, ['asc']);
  const intl = useIntl();

  const [_designOwners, setDesignOwners] = useState([]);

  useEffect(() => {
    if (folder) {
      let designOwners = [];
      folder.designOwners.map((designOwner) => {
        designOwners.push(userList.find((user) => user.userId === designOwner));
      });
      setDesignOwners(designOwners);
    }
  }, []);

  const designOwners = () => {
    return (
      <>
        <div className="pb-1 small-text font-regular">
          {intl.formatMessage({
            id: 'SUConfigView.EditFolderModal.DesignOwners',
            defaultMessage: 'Design Owners'
          })}
        </div>
        <div className="f-row f2-center gap-2 f-wrap">
          {_designOwners.map((designOwner) => {
            return (
              <div>
                <UserBadge
                  firstName={designOwner.firstname}
                  lastName={designOwner.lastname}
                  size={'small'}
                  title={
                    <div className="f-col">
                      <div className="font-regular">
                        {designOwner.firstname} {designOwner.lastname}
                      </div>
                      {designOwner.email}
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderFolderInfo = () => {
    return (
      <>
        <div className="f-row py-2 ">
          <div className={`f-row f2-center gap-2`}>
            <Tooltip title={'Directory'}>
              <Icon name="folder" />
            </Tooltip>
            {folder?.name}
          </div>
        </div>

        <div className="w-10 border-bottom mb-2" />
      </>
    );
  };

  const suaIconTitle = (sua) => {
    return (
      <div className="f-row f-full">
        <img
          alt="sua"
          style={{
            objectFit: 'contain',
            borderRadius: 4,
            width: 300,
            height: 300,
            backgroundColor: '#f7faff'
          }}
          src={
            config.BlobURL +
            '/single-use-configurator/assemblies/thumbnail/' +
            sua.general.id +
            '.png?cache=' +
            sua.general.modifiedOn
          }
        />
        <div
          className="f-col mt-2 f1-start f-full px-2"
          style={{
            width: 300,
            height: 300,
            overflowY: 'auto',
            alignItems: 'stretch'
          }}>
          <div className="f-row f1-between f2-center">
            <div title={sua.general.name} className="font-regular max-lines-1">
              {sua.general.name}
            </div>
            <ThinChip
              className="f-shrink-0"
              label={
                sua.general.status ? (
                  <div className="capitalize">{sua.general.status}</div>
                ) : (
                  'No status'
                )
              }
              color={genericStatusColors(sua.general.status)}
              backgroundColor={genericStatusBackgroundColors(sua.general.status)}
            />
          </div>
          <div>{folder && renderFolderInfo()}</div>
          <div className="mb-2">{folder && _designOwners && designOwners()}</div>

          <div className="font-regular py-1">{intl.formatMessage(configMessages.functionsPFD)}</div>
          {sua.general.functions.map((fun) => {
            return (
              <div key={fun} className="f-row f2-center gap-2 mb-2">
                {CUSTOM_FUNCTIONS[fun]?.form(16)}
                <ThinChip
                  fontSize="smallText"
                  className="capitalize f-full"
                  style={{ cursor: 'pointer' }}
                  label={fun}
                  color={'var(--secondaryColor)'}
                  backgroundColor={'var(--secondaryColorBcg)'}
                />
              </div>
            );
          })}
          <div className="font-regular py-1">SUA components</div>
          {componentList
            .filter((comp: any) => comp.type === 'generic')
            .map((comp: any) => {
              return <SingleComponent key={comp.id} component={comp} />;
            })}
          <div className="font-regular py-1">Tubings</div>
          {componentList
            .filter((comp: any) => comp.type === 'genericedge')
            .map((comp: any) => {
              return <SingleComponent component={comp} />;
            })}
        </div>
      </div>
    );
  };

  return (
    <div
      key={sua.general.id}
      title={sua.general.name}
      className={isSuaAccessible ? 'f-col' : 'f-col overlay'}
      onClick={
        sua.general.status === GenericStatus.Obsolete || !isSuaAccessible
          ? undefined
          : () => onClickSua(sua)
      }
      style={{
        position: 'relative',
        cursor:
          sua.general.status === GenericStatus.Obsolete || !isSuaAccessible ? 'default' : 'pointer'
      }}>
      <img
        alt="sua"
        style={{
          objectFit: 'contain',
          borderRadius: 4,
          width: 180,
          height: 180,
          backgroundColor: '#f7faff',
          filter:
            sua.general.status === GenericStatus.Obsolete || !isSuaAccessible
              ? 'grayscale(1)'
              : undefined
        }}
        src={
          config.BlobURL +
          '/single-use-configurator/assemblies/thumbnail/' +
          sua.general.id +
          '.png?cache=' +
          sua.general.modifiedOn
        }
      />
      <IconTooltip
        color="var(--primaryColor)"
        // @ts-ignore
        tooltipWidth={600}
        style={{ position: 'absolute', right: '.4rem', top: '.2rem' }}
        title={<>{suaIconTitle(sua)}</>}
      />

      <div
        className="max-2-lines-visible py-1 f-row gap-2 f2-center"
        style={{
          color:
            sua.general.status === GenericStatus.Obsolete || !isSuaAccessible
              ? 'var(--frozen-grey)'
              : undefined
        }}>
        {!isSuaAccessible && (
          <div
            className="f-row f1-center"
            style={{
              backgroundColor: 'var(--light-orange)',
              borderRadius: 50,
              width: 22,
              height: 22,
              border: '1px solid var(--orange)'
            }}>
            <IconTooltip
              name="alert"
              color="var(--orange)"
              title={'You do not have access to this SUA'}
            />
          </div>
        )}
        {sua.general.name}
      </div>
    </div>
  );
};

export default SingleSUA;
