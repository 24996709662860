import { Icon } from 'hakobio-react-ui';
import { cloneDeep } from 'lodash';
import React, { useRef } from 'react';
import { useStoreState } from 'react-flow-renderer';
import { useIntl } from 'react-intl';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { actionMessages } from '../../../lang/messages';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { Uuidv4 } from '../../../utilities';
import { ViewMode } from '../../../constants/ViewMode';

interface SelectionToolbarProps {
  onComponentRotated?: any;
  isHover?: boolean;
  hoverComponentGenericNode?: any;
  hoverOnEdge?: any;
  isSelected?: boolean;
  component?: any;
  areLinkable?: boolean;
  handleClick?: any;
  linkableEdge?: any;
  onComponentHorizontalFlip?: any;
  onComponentVerticalFlip?: any;
  rotate?: any;
  noButton?: boolean;
}

const SelectionToolbar: React.FC<SelectionToolbarProps> = ({
  onComponentRotated,
  isHover,
  hoverComponentGenericNode,
  hoverOnEdge,
  isSelected,
  component,
  areLinkable,
  handleClick,
  linkableEdge,
  onComponentHorizontalFlip,
  onComponentVerticalFlip,
  rotate
}) => {
  const intl = useIntl();
  const componentsRef = useRef(null) as any;

  const nodes = useStoreState((store) => store.selectedElements);
  const dispatch = useAppDispatch();

  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );
  const selectedElements = useStoreState((store) => store.selectedElements);

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const selectedComponentId = selectedComponents && selectedComponents[0];

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  componentsRef.current = components;
  const selectedComponent = components.find((c: any) => c.id === selectedComponentId);

  const onComponentDuplicated = () => {
    const selectedComponentCopy = cloneDeep(selectedComponent);
    switch (editorMode) {
      case EditorMode.SUA:
        selectedComponentCopy.id = Uuidv4();
        selectedComponentCopy.viewer2D.x += 80;
        selectedComponentCopy.viewer2D.y -= 80;
        if (selectedComponentCopy.data.anchors) {
          selectedComponentCopy.data.anchors.forEach((anchor) => {
            anchor.id = Uuidv4();
          });
        } else {
          selectedComponentCopy.data.anchors = [];
        }
        dispatch(assemblyEditorSliceActions.addComponent(selectedComponentCopy));
        break;
      case EditorMode.Reference:
        selectedComponentCopy.id = Uuidv4();
        selectedComponentCopy.viewer2D.x += 80;
        selectedComponentCopy.viewer2D.y -= 80;
        if (selectedComponentCopy.data.anchors) {
          selectedComponentCopy.data.anchors.forEach((anchor) => {
            anchor.id = Uuidv4();
          });
        } else {
          selectedComponentCopy.data.anchors = [];
        }
        dispatch(assemblyEditorSliceActions.addComponent(selectedComponentCopy));
        break;
      case EditorMode.UnitOperation:
        selectedComponentCopy.id = Uuidv4();
        selectedComponentCopy.viewer2D.pfd.x += 80;
        selectedComponentCopy.viewer2D.pfd.y -= 80;
        selectedComponentCopy.viewer2D.pid.x += 80;
        selectedComponentCopy.viewer2D.pid.y -= 80;
        if (selectedComponentCopy.data.anchors) {
          selectedComponentCopy.data.anchors.forEach((anchor) => {
            anchor.id = Uuidv4();
          });
        } else {
          selectedComponentCopy.data.anchors = [];
        }
        dispatch(assemblyEditorSliceActions.addComponent(selectedComponentCopy));
        break;
    }
  };

  const renderActionButtons = () => {
    const canFlip =
      editorMode === EditorMode.SUA ||
      editorMode === EditorMode.Reference ||
      (editorMode === EditorMode.UnitOperation &&
        (layerMode === UnitOperationLayer.PnID || layerMode === UnitOperationLayer.Reference));

    return (
      <div className="f-row f2-center p-1 gap-2">
        <Icon
          className="p-1"
          onClick={onComponentRotated}
          hoverColor={'var(--primaryColor)'}
          name="rotate"
          title={intl.formatMessage(actionMessages.rotate)}
          pointer
          style={{
            lineHeight: 0.5,
            transform: 'scaleX(-1)'
          }}
        />

        {canFlip && (
          <>
            <Icon
              className="p-1"
              pointer
              size={13}
              onClick={onComponentHorizontalFlip}
              name="flip-horizontal"
              title={intl.formatMessage(actionMessages.flipHorizontal)}
              style={{
                lineHeight: 0.5
              }}
            />
            <Icon
              className="p-1"
              pointer
              size={13}
              onClick={onComponentVerticalFlip}
              name="flip-vertical"
              title={intl.formatMessage(actionMessages.flipVertical)}
              style={{
                lineHeight: 0.5
              }}
            />
          </>
        )}

        {layerMode === UnitOperationLayer.PFD && (
          <Icon
            className="p-1"
            pointer
            onClick={() => onComponentDuplicated()}
            name="files"
            title={intl.formatMessage({
              id: 'SelectionToolbar.DuplicateComponent',
              defaultMessage: 'Duplicate Component'
            })}
            style={{
              lineHeight: 0.5
            }}
          />
        )}
      </div>
    );
  };

  const showLink = () => {
    let implementableKey: string;
    let idDragKey: string;
    let showLinkButton: boolean;
    let showUnlinkButton: boolean;
    switch (editorMode) {
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PnID:
            implementableKey = 'implementable';
            idDragKey = 'idDrag';
            break;
          case UnitOperationLayer.Reference:
            implementableKey = 'implementableReference';
            idDragKey = 'idDragReference';
            break;
        }
        if (areLinkable && linkableEdge?.data[implementableKey]) {
          showLinkButton = true;
        } else if (component.data[idDragKey]) {
          showUnlinkButton = true;
        }
        break;
      case EditorMode.SUA:
      case EditorMode.Reference:
        implementableKey = 'implementable';
        idDragKey = 'idDrag';
        if (areLinkable && !component.data[idDragKey]) {
          showLinkButton = true;
        } else if (component.data[idDragKey]) {
          showUnlinkButton = true;
        }
        break;
    }
    if (showLinkButton) {
      return (
        <div className="py-1 pr-1 ">
          <Icon
            style={{ lineHeight: 0.5 }}
            className="border-left p-2"
            name="link"
            pointer
            onClick={
              editorMode === EditorMode.SUA || editorMode === EditorMode.Reference
                ? () => handleClick(false)
                : () => handleClick(!linkableEdge?.data[implementableKey])
            }
          />
        </div>
      );
    } else if (showUnlinkButton) {
      return (
        <div className="py-1 pr-1 ">
          <Icon
            style={{ lineHeight: 0.5 }}
            className="border-left p-2"
            color="var(--red)"
            hoverColor="var(--red)"
            pointer
            name="unlink"
            onClick={
              editorMode === EditorMode.SUA || editorMode === EditorMode.Reference
                ? () => handleClick(true)
                : () => handleClick(!linkableEdge?.data[implementableKey])
            }
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderButtons = () => {
    if (viewMode === ViewMode.History || viewMode === ViewMode.HistoryUO) {
      return null;
    }

    if (editorMode === EditorMode.UnitOperation) {
      switch (layerMode) {
        case UnitOperationLayer.PFD:
          if (
            (isSelected && !linkableEdge) ||
            (isSelected && linkableEdge?.data.implementable) ||
            (isSelected && linkableEdge && component.id === selectedElements?.[0].id)
          ) {
          } else {
            return null;
          }
          break;
        case UnitOperationLayer.PnID:
          if (
            (isSelected && !linkableEdge) ||
            (isSelected && linkableEdge?.data.implementable) ||
            (isSelected && linkableEdge && component.id === selectedElements?.[0].id)
          ) {
          } else {
            return null;
          }
          break;
        case UnitOperationLayer.Reference:
          if (
            (isSelected && !linkableEdge) ||
            (isSelected && linkableEdge?.data.implementableReference) ||
            (isSelected && linkableEdge && component.id === selectedElements?.[0].id)
          ) {
          } else {
            return null;
          }
          break;
      }
    }

    if (editorMode === EditorMode.SUA || editorMode === EditorMode.Reference) {
      if (
        (isSelected && !component.data.idDrag) ||
        (isSelected && component.data.idDrag && component.id === selectedElements?.[0].id)
      ) {
      } else {
        return;
      }
    }

    return (
      <div
        className="f-row"
        style={{
          borderRadius: 2,
          boxShadow: '0px 1px 5px 1px rgba(192,192,192, .5)',
          position: 'absolute',
          backgroundColor: 'white',
          bottom: -40,
          left: '50%',
          transform: 'translateX(-50%)'
        }}>
        {((nodes && nodes.length === 1) || isSelected) &&
          component.type !== 'genericonedge' &&
          renderActionButtons()}
        {/* {nodes && nodes.length === 1 && renderAssemblySelection()} */}
        {((nodes && nodes.length === 1) || isSelected) && showLink()}
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <div
          style={
            component.type === 'generic' || component.type === 'genericonedge'
              ? {
                  border: isSelected
                    ? 'solid 1px var(--blue)'
                    : isHover || hoverComponentGenericNode || hoverOnEdge
                    ? 'solid 1px var(--primaryColor)'
                    : 'none',
                  position: 'absolute',
                  top: -8,
                  bottom: -8,
                  left: -8,
                  right: -8,
                  pointerEvents: 'none'
                }
              : {}
          }>
          {(component.data.idDrag || component.data.idDragReference) && (
            <div style={{ position: 'relative' }}>
              <span
                className="px-1 absolute"
                style={{
                  borderRadius: 16,
                  top: -6,
                  left: 6,
                  height: 12,
                  backgroundColor: '#f7faff',
                  paddingRight: 5
                }}>
                <Icon
                  name="link"
                  color="var(--frozen-grey)"
                  style={{ transform: 'rotate(45deg) translate(-4px,-3px)', lineHeight: 1 }}
                  size={20}
                />
              </span>
            </div>
          )}
        </div>
        {renderButtons()}
      </>
    );
  };

  return render();
};
export default SelectionToolbar;
