import { useEffect, useState } from "react";
import { Button, Header, Icon, TextInput } from "hakobio-react-ui";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../store";
import { assemblyEditorSliceActions } from "../../store/features/assemblyEditor/assemblyEditorSlice";
import "./style/Arrow.css";
import "./style/Projects.css";
import AssemblyContainer from "./AssemblyContainer";
import { Filters } from "../models/Filters";
import AdvancedSearchComponent from "../Shared/AdvancedSearch/AdvancedSearchComponent";
import ExportDataButton from "./ExportDataButton";
import { configMessages } from "./../../lang/messages";
import ComponentsContainer from "./ComponentsContainer";
import Uuidv4 from "../Shared/Uuidv4";
import { DocumentTitle } from "../../constants/DocumentTitle";
import { ViewMode } from "../../constants/ViewMode";
import { EntityDisplayed } from "../../constants/EntityDisplayed";

const StartupList = (props: {
  buildings: any[];
  sites: any[];
  setSelectedBuildingFloorAndSite: (arg0: {
    building: any;
    floorNumber: any;
    site: any;
  }) => void;
  setSelectedSite: (arg0: null) => void;
  history: string[];
  language: string;
}) => {
  const dispatch = useAppDispatch();

  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const libComponentName = useAppSelector(
    (state) => state.assemblyEditorSlice.libComponentName,
  );
  const _directories = useAppSelector(
    (state) => state.assemblyEditorSlice.allDirectories,
  );
  const selectedFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedFolder,
  );

  const [_activeTab, setActiveTab] = useState(listTab);
  const [_search, setSearch] = useState<string | Filters | null>("");
  const [_viewType, setViewType] = useState(2);
  const [_canCreateAssembly, setCanCreateAssembly] = useState(false);
  const user = useAppSelector((state) => state.assemblyEditorSlice.user);
  const intl = useIntl();

  const history = useHistory();

  //#region useEffect
  useEffect(() => {
    switch (_activeTab) {
      case 0:
        dispatch(
          assemblyEditorSliceActions.setEntitiesDisplayed(
            EntityDisplayed.Component,
          ),
        );
        break;
      case 1:
        dispatch(
          assemblyEditorSliceActions.setEntitiesDisplayed(
            EntityDisplayed.SuaReference,
          ),
        );
        break;
      case 2:
        dispatch(
          assemblyEditorSliceActions.setEntitiesDisplayed(
            EntityDisplayed.UnitOperation,
          ),
        );
        break;
    }
  }, [_activeTab]);

  useEffect(() => {
    setCanCreateAssembly(
      _directories.some(
        (directory) =>
          directory.designEditors.includes(user.id) ||
          directory.designOwners.includes(user.id),
      ),
    );
  }, [user, _directories]);

  useEffect(() => {
    if (_activeTab === 1) {
      if (
        ((selectedFolder?.id !== "functionalAssembliesFolder" &&
          selectedFolder?.id !== "referenceAssembliesFolder") ||
          !selectedFolder) &&
        typeof _search !== "string"
      ) {
        const searchValue = { ..._search, folders: [] };
        setSearch(searchValue);
      }
    }
  }, [selectedFolder]);

  useEffect(() => {
    window.parent.postMessage(
      { message: "message", value: DocumentTitle.SUD },
      "*",
    );
    window.parent.postMessage({ message: "deleteCross", value: "show" }, "*");
  }, []);

  useEffect(() => {
    let action = localStorage.getItem("action");
    let actionData = localStorage.getItem("actionData");
    if (action === "assemblyVersion") {
      let dataAssembly = JSON.parse(actionData);
      history.push("/viewer/history/" + dataAssembly?.hashPartitionKey);
    } else if (action === "assemblyReference") {
      let dataAssembly = JSON.parse(actionData);
      history.push("/editor/Reference/" + dataAssembly?.id);
    } else if (action === "uoVersion") {
      let dataAssembly = JSON.parse(actionData);
      history.push("/viewer/uohistory/" + dataAssembly?.id);
    }
    localStorage.removeItem("action");
    localStorage.removeItem("actionData");
  }, []);

  //#endregion

  //#region Functions
  const handleChangeView = (number: number) => {
    setViewType(number);
  };

  const handleChangeTab = (index: number) => {
    setSearch("");
    setActiveTab(index);
    dispatch(assemblyEditorSliceActions.setListTab(index));
    dispatch(assemblyEditorSliceActions.setLibComponentName(null));
    dispatch(assemblyEditorSliceActions.selectFolder(null));
  };

  const onSearchChange = (value: string | Filters | null) => {
    setSearch(value);
  };

  const routeChange = () => {
    if (_activeTab === 0) {
      dispatch(assemblyEditorSliceActions.setCustomComponentViewOnly(false));
      dispatch(
        assemblyEditorSliceActions.setCustomComponentEdition({
          id: Uuidv4(),
          name: "generic",
          type: "generic",
          viewer2D: {},
          data: {
            anchors: [],
            instrumentationPorts: [],
            samplingPorts: [],
            type: libComponentName,
          },
        }),
      );
    } else {
      const newId = uuidv4();
      dispatch(
        assemblyEditorSliceActions.setAssembly({
          general: {
            createdOn: new Date().toISOString(),
            modifiedOn: new Date().toISOString(),
            name: "New assembly",
            id: newId,
            type: _activeTab === 1 ? "SingleUseAssembly" : "UnitOperation",
            description: "",
            functions: [],
            version: 0,
            comment: "",
            previousVersionId: newId,
            hashPartitionKey: newId + "-0",
          },
          components: [],
        }),
      );
    }
    dispatch(assemblyEditorSliceActions.setViewMode(ViewMode.Editor));
    const path =
      _activeTab === 0
        ? "/editor/Component"
        : _activeTab === 1
          ? "/editor/SingleUseAssembly"
          : "/editor/UnitOperation";
    history.push(path);
  };
  //#endregion

  //#region Renderers
  const renderSecondLine = () => {
    return (
      <div className="f-row f1-end" style={{ height: 56 }}>
        {_activeTab === 0 ? (
          !libComponentName && (
            <div style={{ width: 300 }}>
              <TextInput
                label={
                  <FormattedMessage
                    id="SUConfig.SearchComponent"
                    defaultMessage="Search Component"
                  />
                }
                value={_search}
                onChange={(e: any) => onSearchChange(e.target.value)}
                endAdornment={<Icon name="search" />}
                inputSize="small"
              />
            </div>
          )
        ) : _activeTab === 1 ? (
          <div
            className="mb-1 mt-2"
            style={{ width: "auto", minWidth: 500, maxWidth: "75%" }}
          >
            <AdvancedSearchComponent
              setSearch={(s: string | Filters | null) => setSearch(s)}
            />
          </div>
        ) : (
          <div style={{ width: 300 }}>
            <TextInput
              label={
                <FormattedMessage
                  id="SUConfig.SearchOperation"
                  defaultMessage="Search Operation"
                />
              }
              value={_search}
              onChange={(e: any) => onSearchChange(e.target.value)}
              endAdornment={<Icon name="search" />}
              inputSize="small"
            />
          </div>
        )}
      </div>
    );
  };

  const renderSwitchView = () => {
    return (
      <>
        <Icon
          color={_viewType === 2 ? "var(--primaryColor)" : undefined}
          size="1.5rem"
          pointer
          name="thumbnails"
          onClick={() => handleChangeView(2)}
        />
        <Icon
          color={_viewType === 3 ? "var(--primaryColor)" : undefined}
          size="1.5rem"
          pointer
          name="list"
          onClick={() => handleChangeView(3)}
        />
      </>
    );
  };

  const renderHeader = () => {
    return (
      <Header
        heading={
          <FormattedMessage
            id="SUConfig.SingleUseConfigurator"
            defaultMessage="Single-Use Designer"
          />
        }
        topRight={
          <div
            className="gap-2"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {_activeTab === 0 &&
              user?.id === "786b8f96-5009-4296-9032-22e8a633c8ab" && (
                <ExportDataButton />
              )}
            {(_activeTab === 0 && !libComponentName) ||
            (_activeTab !== 0 && !_canCreateAssembly) ? null : (
              <Button
                width={props.language === "fr" ? "auto" : 200}
                title={intl.formatMessage({
                  id: "SUConfig.Button.NewAssembly.Title",
                  defaultMessage: "New assembly",
                })}
                onClick={routeChange}
              >
                {_activeTab === 0 ? (
                  <FormattedMessage
                    id="SUConfig.Button.NewCustomComponent"
                    defaultMessage="New custom component"
                  />
                ) : _activeTab === 1 ? (
                  <FormattedMessage
                    id="SUConfig.Button.NewAssembly"
                    defaultMessage="New assembly"
                  />
                ) : (
                  <FormattedMessage
                    id="SUConfig.Button.NewOperation"
                    defaultMessage="New operation"
                  />
                )}
              </Button>
            )}
          </div>
        }
        tabs={[
          intl.formatMessage(configMessages.components),
          intl.formatMessage(configMessages.suas),
          intl.formatMessage(configMessages.uos),
        ]}
        activeTab={_activeTab}
        changeActiveTab={handleChangeTab}
      >
        <div className="f-row f1-between f2-center w-100">
          <div className="f-row gap-3 px-1 py-2">{renderSwitchView()}</div>
          {renderSecondLine()}
        </div>
      </Header>
    );
  };

  const renderContainer = () => {
    if (_activeTab === 0) {
      return <ComponentsContainer search={_search} />;
    } else {
      return <AssemblyContainer search={_search} viewType={_viewType} />;
    }
  };

  return (
    <div
      className="noselect f-col h-100"
      style={{ paddingLeft: 65, paddingRight: 65, position: "relative" }}
    >
      {renderHeader()}
      {renderContainer()}
    </div>
  );
};

export default StartupList;
