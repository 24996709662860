import { Button, Icon, Modal, TextInput } from "hakobio-react-ui";
import CustomComponentList from "../../../../ComponentsLibrairy/CustomComponentList";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useIntl } from "react-intl";
import { actionMessages } from "../../../../../lang/messages";
import { useEffect, useState } from "react";
import { CUSTOM_NODES } from "../../../../../constants/CUSTOM_NODES";
import { assemblyEditorSliceActions } from "../../../../../store/features/assemblyEditor/assemblyEditorSlice";
import { cloneDeep } from "lodash";
interface PresetTubingsModalProps {
  closeModal: () => void;
}

const PresetTubingsModal: React.FC<PresetTubingsModalProps> = ({
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents,
  );
  const components = useAppSelector(
    (state) => state.assemblyEditorSlice.components,
  );
  const selectedCustomTubing = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedCustomTubing,
  );

  const [_schema, setSchema] = useState(null);
  const [_searchedValue, setSearchValue] = useState<string>("");
  const intl = useIntl();

  const selectedComponent = components.find(
    (c: any) => c.id === selectedComponents?.[0],
  );

  useEffect(() => {
    getSchema();
    return () => {
      dispatch(assemblyEditorSliceActions.setLibComponentName(null));
    };
  }, []);

  const getSchema = async () => {
    const schema = await CUSTOM_NODES["tubing"].schema();
    setSchema(schema);
  };

  const updateSelectedTubing = () => {
    const properties = Object.entries(selectedCustomTubing.data).filter(
      ([key]) =>
        key !== "type" &&
        key !== "anchors" &&
        key !== "instrumentationPorts" &&
        key !== "samplingPorts" &&
        key !== "customName",
    );

    let newData = selectedCustomTubing.data;
    Object.keys(_schema.properties).forEach((prop) => {
      const existingProp = properties.find((p) => p[0] === prop);
      if (existingProp) {
        newData = { ...newData, [existingProp[0]]: existingProp[1] };
      }
    });
    const tubingToUpdate = cloneDeep(selectedComponent);
    tubingToUpdate.data = newData;
    dispatch(assemblyEditorSliceActions.updateComponent(tubingToUpdate));

    closeModal();
  };

  return (
    <Modal
      className="f-row h-80"
      cancel={closeModal}
      footer={
        <Button onClick={updateSelectedTubing}>
          {intl.formatMessage(actionMessages.select)}
        </Button>
      }
      size="modal-xl"
      title={
        <div
          className="f-row gap-2 f2-center f1-between w-100"
          style={{ width: 780 }}
        >
          <div>
            {intl.formatMessage({
              id: "PresetTubingsModal",
              defaultMessage: "Preset Tubings",
            })}
          </div>
          <TextInput
            defaultValue={_searchedValue}
            onChange={(e) => setSearchValue(e.target.value)}
            endAdornment={<Icon name="search" />}
            label={intl.formatMessage(actionMessages.search)}
            style={{ maxWidth: 200 }}
          />
        </div>
      }
    >
      <CustomComponentList searchedValue={_searchedValue} />
    </Modal>
  );
};

export default PresetTubingsModal;
