import { useEffect, useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';

import { matchPath } from 'react-router-dom';
import LeftPanel from '../components/EditorPanel/LeftPanel';
import FlowHistorySUA from '../components/Flow/FlowHistorySUA';
import ControlButtons from '../components/Shared/ControlButtons';
import { EditorMode } from '../constants/EditorMode';
import { ViewMode } from '../constants/ViewMode';
import { getAllAssemblies, getAllDirectories, getAssemblyHistory } from '../services/editor/editorService';
import { convertToRead } from '../services/editor/editorServiceUtilities';
import { useAppDispatch, useAppSelector } from '../store';
import { useAssemblyEditorAction } from '../store/features/assemblyEditor/useAssemblyEditorAction';
import { historySliceActions } from '../store/features/history/historySlice';
import HistoryHeader from '../components/History/HistoryHeader';
import { assemblyEditorSliceActions } from '../store/features/assemblyEditor/assemblyEditorSlice';

interface HistoryViewProps {}

const HistoryView: React.FC<HistoryViewProps> = () => {
  const dispatch = useAppDispatch();
  const { setEditorMode, setAssembly } = useAssemblyEditorAction();
  const [isLoading, setIsLoading] = useState(true);
  const allHeadersLibrary = useAppSelector((state) => state.assemblyEditorSlice.allHeadersLibrary);

  const getAssemblyAsync = async (hashPartitionKey: string) => {
    let splitHash = hashPartitionKey.substring(0, 36);
    const allAssembliesHistory = await getAssemblyHistory(splitHash);
    let assemblyVersion = allAssembliesHistory.find((a: any) => {
      return a.hashPartitionKey === hashPartitionKey;
    });
    const assemblyToRead = convertToRead(assemblyVersion);
    const historyToRead = allAssembliesHistory.map((a) => convertToRead(a));
    dispatch(historySliceActions.setViewingAssembly(assemblyToRead));
    dispatch(historySliceActions.setHistoryList(historyToRead));
    setAssembly(assemblyToRead.general, assemblyToRead.components);
    setIsLoading(false);
  };

  useEffect(() => {
    window.parent.postMessage({ message: 'deleteCross', value: 'hide' }, '*');
    dispatch(assemblyEditorSliceActions.setViewMode(ViewMode.History));
  }, []);

  useEffect(() => {
    const allDirectories = async () => {
      const result = await getAllDirectories();
      dispatch(assemblyEditorSliceActions.setAllDirectories(result));  
    };
    allDirectories();
  }, []);

  useEffect(() => {
    if (!allHeadersLibrary.length) {
      const allAssemblies = async () => {
        const result = await getAllAssemblies();
        result
          .filter(
            (a: any) => a.type === 'SingleUseAssembly' || a.type === 'SingleUseAssemblyReference'
          )
          .sort(sortByName);

        dispatch(assemblyEditorSliceActions.setAllHeadersLibrary(result));
      };
      allAssemblies();
    }
  }, [allHeadersLibrary]);

  const sortByName = (a: any, b: any) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
      return 0;
    } else {
      return -1;
    }
  };

  useEffect(() => {
    const matchSUA = matchPath(window.location.pathname, {
      path: '/viewer/history/:assemblyId',
      exact: true
    });
    if (matchSUA) {
      // @ts-ignore
      const hashPartitionKey = matchSUA.params.assemblyId;
      setEditorMode(EditorMode.SUA);
      getAssemblyAsync(hashPartitionKey);
    }
  }, []);

  const renderFlow = () => {
    return <FlowHistorySUA />;
  };
  if (isLoading) return null;
  return (
    <>
      <ReactFlowProvider>
        <HistoryHeader />

        <div className="f-row f-full" style={{ height: 1 }}>
          <LeftPanel />
          <ControlButtons />
          {renderFlow()}
        </div>
      </ReactFlowProvider>
    </>
  );
};

export default HistoryView;
