import {
  Autocomplete,
  Icon,
  IconTooltip,
  Modal,
  SmallButton,
} from "hakobio-react-ui";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { buttonSaveStyle } from "../components/EditorPanel/style/styles";
import { DirectoryFolder } from "../components/models/DirectoryFolder";
import { ListTab } from "../constants/ListTab";
import { actionMessages } from "../lang/messages";

import { useAppDispatch, useAppSelector } from "../store";
import { assemblyEditorSliceActions } from "../store/features/assemblyEditor/assemblyEditorSlice";
interface Props {
  duplicateAssembly: Function;
  isLoading: boolean;
}

const DuplicateSuaModal = ({ duplicateAssembly, isLoading }: Props) => {
  const dispatch = useAppDispatch();
  const assemblyToDuplicate = useAppSelector(
    (state) => state.assemblyEditorSlice.assemblyToDuplicate,
  );

  const [_directory, setDirectory] = useState(null);
  const intl = useIntl();

  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const directoriesList = useAppSelector(
    (state) => state.assemblyEditorSlice.allDirectories,
  );

  const tabFolderList =
    listTab === ListTab.SUA
      ? directoriesList.filter(
          (f: DirectoryFolder) => f.directoryType === (ListTab.SUA as number),
        )
      : directoriesList.filter(
          (f: DirectoryFolder) =>
            f.directoryType === (ListTab.UnitOperation as number),
        );

  const onCloseModal = () => {
    dispatch(assemblyEditorSliceActions.setAssemblyDuplicate(null));
  };

  const onDuplicate = async () => {
    duplicateAssembly(_directory);
  };

  const renderFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "5px",
          position: "relative",
        }}
      >
        <SmallButton
          disabled={
            _directory === null || _directory === undefined || _directory === ""
          }
          width={"150px"}
          loading={isLoading}
          color={"var(--primaryColor)"}
          style={buttonSaveStyle}
          onClick={async () => {
            onDuplicate();
          }}
        >
          <FormattedMessage
            id="Project.Button.Settings"
            defaultMessage="Duplicate"
          />
        </SmallButton>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="w-100 h-100 f-col f1-center gap-2">
        <div className="f-col gap-2 f1-start f2-start f-full pb-2">
          <div className="f-row f2-center w-100 gap-2 ">
            <span>
              {intl.formatMessage(
                {
                  id: "SUConfigView.DuplicateModal",
                  defaultMessage: "Select a folder to duplicates <b>{name}</b>",
                },
                {
                  name: assemblyToDuplicate.name,
                  b: (str) => <span className="font-regular">{str}</span>,
                },
              )}
            </span>
          </div>

          <div className="f-col w-100 f2-center gap-2 pt-2">
            <div className="f-col w-100 gap-2">
              <Autocomplete
                variant="outlined"
                placeholder={intl.formatMessage(
                  actionMessages.selectFolderToSave,
                )}
                label={intl.formatMessage(actionMessages.selectFolderToSave)}
                // @ts-ignore
                getOptionLabel={(option) => option.name}
                options={tabFolderList.map((option) => {
                  return option;
                })}
                value={_directory}
                onChange={(event, newValue) => {
                  if (newValue && newValue.id) {
                    setDirectory(newValue);
                  } else {
                    setDirectory(null);
                  }
                }}
                renderOption={(props: any, option: any) => {
                  if (!option.permissions.assemblyCreate) {
                    return;
                  }
                  return (
                    <li
                      {...props}
                      key={`option-${option.id}`}
                      className={`f-row f2-center gap-2 px-2 py-1 li-autocomplete`}
                    >
                      <div className="max-lines-1">{option.name}</div>
                    </li>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    let type = null;
    switch (assemblyToDuplicate.type) {
      case "SingleUseAssembly":
        type = "SUA";
        break;
      case "UnitOperation":
        type = "UO";
        break;
      case "SingleUseAssemblyReference":
        type = "Reference";
        break;
      default:
        type = "SUA";
        break;
    }
    return (
      <div className="f-row f1-center f2-center gap-2">
        <Icon name={"files"} />

        {intl.formatMessage(
          {
            id: "SUConfigView.DuplicateModal.message",
            defaultMessage: "Duplicate {entity}",
          },
          {
            entity: type,
          },
        )}
      </div>
    );
  };

  const render = () => {
    return (
      <Modal
        size={"modal-md"}
        title={renderTitle()}
        cancel={onCloseModal}
        footer={renderFooter()}
      >
        {renderBody()}
      </Modal>
    );
  };

  return render();
};

export default DuplicateSuaModal;
