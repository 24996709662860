import { DoubleChip, Empty, Icon, ThinChip, Title, Tooltip } from 'hakobio-react-ui';
import { FC, useState } from 'react';
import { CUSTOM_FUNCTIONS } from '../../../../constants/CUSTOM_FUNCTIONS';
import { ViewMode } from '../../../../constants/ViewMode';
import { configMessages, generalMessages } from '../../../../lang/messages';
import ReferenceLabelDescription from '../../Shared/ReferenceCards/ReferenceLabelDescription';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useAssemblyEditorAction } from '../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import labelRope from '../../../../constants/ficelle-string-label-reference.svg';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useStoreActions } from 'react-flow-renderer';

interface ReferenceInvolvedProps {
  functions: any[];
}

const ReferenceInvolved: FC<ReferenceInvolvedProps> = ({ functions }) => {
  const dispatch = useAppDispatch();

  const { setHoveredComponent } = useAssemblyEditorAction();
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const [_hoveredFunction, setHoveredFunction] = useState(null);
  const intl = useIntl();

  const handleMouseEnterFunction = (id) => {
    setHoveredFunction(id);
    setHoveredComponent(id);
  };

  const handleMouseLeaveFunction = (id) => {
    setHoveredFunction(null);
    setHoveredComponent(null);
  };

  const handleSelectFunction = (id) => {
    dispatch(assemblyEditorSliceActions.selectComponents([id]));
    const component = components.find((c: any) => c.id === id);
    setSelectedElements([component]);
  };

  const renderAncillaryFunctionsTitle = (f: any) => {
    return f?.data?.ancillaryFunctions?.[0].complexParameters?.length ? (
      <div>
        {f?.data?.ancillaryFunctions?.[0].complexParameters?.map((fct, index) => {
          return (
            <div className="f-row gap-2 f2-center" key={`function-${fct}-${index}`}>
              <div>{CUSTOM_FUNCTIONS[fct]?.form(20)}</div>
              <div>{CUSTOM_FUNCTIONS[fct]?.name}</div>
            </div>
          );
        })}
      </div>
    ) : (
      ''
    );
  };

  const renderUoItem = ({ f, inverse, handleSelectFunction, index }) => {
    const genericSua = f.data.assembly;
    const referenceSua = f.data.assemblyReference;

    let content = null;
    if (!genericSua && !referenceSua) {
      content = renderFunction({ f, inverse });
    } else if (genericSua && !referenceSua) {
      content = renderGenericSua({ f, inverse });
    } else if (referenceSua) {
      content = renderReferenceSua({ f });
    }

    return (
      <div
        onMouseEnter={() => handleMouseEnterFunction(f.id)}
        onMouseLeave={() => handleMouseLeaveFunction(f.id)}
        onClick={() => (viewMode === ViewMode.HistoryUO ? null : handleSelectFunction(f.id))}>
        {content}
      </div>
    );
  };

  const renderReferenceSua = ({ f }) => {
    const assembly = f.data.assemblyReference.general;

    const labelRopeComponent = (
      <img
        src={labelRope}
        height={75}
        alt=""
        style={{
          position: 'absolute',
          top: -16,
          left: 12,
          transform: 'rotate(-10deg) scaleX(-1) translateX(-8px)',
          zIndex: 4
        }}
      />
    );

    const infoLabel = (
      <div className="relative">
        {labelRopeComponent}
        <ReferenceLabelDescription
          name={assembly.name}
          status={assembly.status}
          supplier={assembly.supplier}
          media={assembly.medias}
          isHover={_hoveredFunction === f.id}
        />
      </div>
    );

    return (
      <div className="f-col f2-center gap-2  w-100">
        <div className="f-col  w-100">
          <div className="f-row gap-2 w-100">
            <Icon name="manifold" className="mt-1 f1-start f2-start" />
            <ThinChip
              className="f-full"
              label={f.data.assembly.general.name}
              color={'var(--primaryColor)'}
              backgroundColor={'var(--primaryColorBcg)'}
            />
          </div>
          {infoLabel}
        </div>
      </div>
    );
  };

  const renderGenericSua = ({ f, inverse }) => {
    const isInversed = inverse && viewMode !== ViewMode.HistoryUO;
    return (
      <div className="f-col gap-2" key={f.id}>
        <div className="f-row gap-2 f2-center">
          <Icon name="manifold" />
          <ThinChip
            className="w-100"
            label={f.data.assembly.general.name}
            color={'var(--primaryColor)'}
            backgroundColor={'var(--primaryColorBcg)'}
          />
        </div>
        <ThinChip
          className="ml-4"
          center
          key={f?.data?.id}
          color={isInversed ? 'var(--primaryColor)' : 'var(--dark-grey)'}
          backgroundColor={'white'}
          label={
            isInversed ? (
              intl.formatMessage(generalMessages.linkReferenceToThisAssembly)
            ) : (
              <div className="f-row gap-2 f1-center f2-center">
                <Icon name="qr" className="pl-1" />
                {intl.formatMessage(generalMessages.noReferenceLinked)}
              </div>
            )
          }
          style={{
            border: isInversed ? '1px solid var(--primaryColor)' : '1px dashed var(--frozen-grey)'
          }}
        />
      </div>
    );
  };

  const renderFunction = ({ f, inverse }) => {
    return (
      <div key={f.id} className="f-col gap-2" onClick={() => handleSelectFunction(f.id)}>
        <div className="f-row f2-center gap-2 w-100">
          <div className="f-shrink-0">{CUSTOM_FUNCTIONS[f?.data?.type].form(16)}</div>
          {f?.data?.ancillaryFunctions?.[0] ? (
            <Tooltip
              className="w-100"
              key={f?.data?.id}
              title={renderAncillaryFunctionsTitle(f)}
              placement="top">
              <DoubleChip
                className="max-1-lines-visible"
                label1Ratio={'25%'}
                color={'var(--secondaryColor)'}
                backgroundColor={'var(--secondaryColorBcg)'}
                label1={CUSTOM_FUNCTIONS[f?.data?.type].name || 'No name'}
                label2={
                  f?.data?.ancillaryFunctions?.[0].complexParameters?.length ? (
                    f?.data?.ancillaryFunctions?.[0].complexParameters?.map((name, index) => {
                      return (
                        <span key={name + index}>
                          {index === f?.data?.ancillaryFunctions?.[0].complexParameters?.length - 1
                            ? CUSTOM_FUNCTIONS[name]?.name
                            : CUSTOM_FUNCTIONS[name]?.name + ', '}
                        </span>
                      );
                    })
                  ) : (
                    <span className="frozen-grey">
                      {intl.formatMessage(generalMessages.noValue)}
                    </span>
                  )
                }
              />
            </Tooltip>
          ) : (
            <ThinChip
              className="w-100"
              key={f?.data?.id}
              color={'var(--secondaryColor)'}
              backgroundColor={'var(--secondaryColorBcg)'}
              label={CUSTOM_FUNCTIONS[f?.data?.type].name || 'No name'}
            />
          )}
        </div>
        <ThinChip
          center
          className="ml-4"
          key={f?.data?.id}
          color={inverse ? 'var(--primaryColor)' : 'var(--dark-grey)'}
          backgroundColor={'white'}
          label={
            inverse ? (
              intl.formatMessage(generalMessages.linkReferenceToThisFunction)
            ) : (
              <div className="f-row gap-2 f1-center f2-center">
                <Icon name="qr" className="pl-1" />
                {intl.formatMessage(generalMessages.noReferenceLinked)}
              </div>
            )
          }
          style={{
            border: inverse ? '1px solid var(--primaryColor)' : '1px dashed var(--frozen-grey)'
          }}
        />
      </div>
    );
  };

  return (
    <div className="f-col f-full pb-2" style={{ height: 1 }}>
      <Title className="mb-0">{intl.formatMessage(configMessages.referencesInvolved)}</Title>
      <div className="f-col gap-4 f-full" style={{ overflowY: 'auto' }}>
        {functions.length ? (
          functions.map((f: any, index: number) => {
            const inverse = _hoveredFunction === f.id;
            return renderUoItem({ f, inverse, handleSelectFunction, index });
          })
        ) : (
          <Empty
            text={intl.formatMessage({
              id: 'ReferenceInvolved.NoFunctionAdded',
              defaultMessage: 'No function has been added yet'
            })}
          />
        )}
      </div>
    </div>
  );
};

export default ReferenceInvolved;
