import { Empty } from 'hakobio-react-ui';
import { useAppSelector } from '../../../../../store';
import { ReferenceGeneralPanel } from '../../../SingleUseEditor/LeftPanel/ReferenceGeneralPanel';

const ReferenceSuaDetails = ({ component }) => {
  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const selectedComponentId = selectedComponents && selectedComponents[0];
  const selectedComponent = components.find((c: any) => c.id === selectedComponentId);

  return (
    <div className="pb-1 f-col f-full" style={{ overflowX: 'hidden' }}>
      {selectedComponent ? (
        <ReferenceGeneralPanel editable={false} sua={selectedComponent.data.assemblyReference} component={selectedComponent} />
      ) : (
        <Empty />
      )}
    </div>
  );
};
export default ReferenceSuaDetails;
