import { RuleComponentEdgeComponent } from '../RuleComponentEdgeComponent';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';

export class RuleTubeDiameterPlugFunctionPlugTubeDiameter extends RuleComponentEdgeComponent {
  schemaComponentSourceList = ['plug'];
  schemaComponentTargetList = ['plug'];
  schemaTubeList = ['transferring'];
  editorList = ['UOE_PID'];

  checkPrerequisite = () => {
    const isFunction = this.schemaTubeList.includes(this.edge.data.type);
    const isSourcePlug = this.componentSource ? this.schemaComponentSourceList.includes(this.componentSource?.data?.type) : false;
    const isTargetPlug = this.componentTarget ? this.schemaComponentTargetList.includes(this.componentTarget?.data?.type) : false;

    if (!isFunction) {
      return false;
    }

    if (!isSourcePlug || !isTargetPlug) {
      return false;
    }

    if (!this.componentSource.data.assembly || !this.componentTarget.data.assembly) {
      return false;
    }

    return true;
  };

  checkRule = () => {
    let edgeSource;
    let edgeTarget;

    this.componentList
      .filter((component: any) => component.type === 'genericedge')
      .forEach((comp: any) => {
        if (comp.target === this.componentSource.id) {
          edgeSource = comp;
        } else if (comp.source === this.componentTarget.id) {
          edgeTarget = comp;
        } else if (comp.source === this.componentSource.id) {
          edgeSource = comp;
        } else if (comp.target === this.componentTarget.id) {
          edgeTarget = comp;
        }
      });

    if (edgeSource && edgeTarget &&
      edgeSource.data.componentType === 'Weldable' &&
      edgeTarget.data.componentType === 'Weldable' &&
      edgeSource.data["componentInnerDiameter"] != edgeTarget.data["componentInnerDiameter"]) {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible diameter',
        errors: 'The diameter of the tube ' +
          '(source) is ' +
          (edgeSource.data["componentInnerDiameter"] || 'unspecified') +
          ' and the diameter of the tube ' +
          '(target) is ' +
          (edgeTarget.data["componentInnerDiameter"] || 'unspecified'),
        componentAnchors: [
          //@ts-ignore
          { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id },
          //@ts-ignore
          { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
        ]
      });
    }

    if (edgeSource && edgeTarget &&
      edgeSource.data.componentType === 'Weldable' &&
      edgeTarget.data.componentType === 'Weldable' &&
      edgeSource.data["componentOuterDiameter"] != edgeTarget.data["componentOuterDiameter"]) {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible diameter',
        errors: 'The diameter of the tube ' +
          '(source) is ' +
          (edgeSource.data["componentOuterDiameter"] || 'unspecified') +
          ' and the diameter of the tube ' +
          '(target) is ' +
          (edgeTarget.data["componentOuterDiameter"] || 'unspecified'),
        componentAnchors: [
          //@ts-ignore
          { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id },
          //@ts-ignore
          { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
        ]
      });
    }
  };
}