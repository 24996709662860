import { Empty, Title } from 'hakobio-react-ui';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useIntl } from 'react-intl';
import { configMessages } from '../../../../lang/messages';
import { EditorMode } from '../../../../constants/EditorMode';

const SuaComponents = ({ components }) => {
  const dispatch = useAppDispatch();
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);

  const intl = useIntl();

  const onMouseEnter = (component: any) => {
    component.type === 'generic'
      ? dispatch(assemblyEditorSliceActions.setHoverComponentGenericNode(component.id))
      : dispatch(assemblyEditorSliceActions.setHoverTube(component.id));
  };

  const onMouseLeave = (component: any) => {
    component.type === 'generic'
      ? dispatch(assemblyEditorSliceActions.setHoverComponentGenericNode(null))
      : dispatch(assemblyEditorSliceActions.setHoverTube(null));
  };

  return (
    <>
      {editorMode === EditorMode.SUA && (
        <Title className="mb-0">{intl.formatMessage(configMessages.componentsUsed)}</Title>
      )}
      <div className="f-col f-full f2-center overflow-y-auto" style={{ height: 10 }}>
        {components?.length ? (
          components.map((c) => (
            <div
              key={c.id}
              className="f-row f2-center gap-2 p-1 w-100 hover-item"
              onClick={() => dispatch(assemblyEditorSliceActions.selectComponents([c.id]))}
              onMouseEnter={() => onMouseEnter(c)}
              onMouseLeave={() => onMouseLeave(c)}
              style={{
                cursor: 'pointer'
              }}>
              {CUSTOM_NODES[c.data.type].form(20)}
              {CUSTOM_NODES[c.data.type].name}
            </div>
          ))
        ) : (
          <Empty size="md" />
        )}
      </div>
    </>
  );
};

export default SuaComponents;
