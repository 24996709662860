export interface ErrorDetailAnchor {
  anchorId: string;
  componentId: string;
  orphan: boolean;
}

export interface ErrorDetail {
  id: string;
  name: string;
  errors: string;
  componentAnchors: Array<ErrorDetailAnchor>;
}

export abstract class RuleComponentEdgeComponent {
  public schemaComponentSourceList: Array<string>;
  public schemaComponentTargetList: Array<string>;
  public schemaTubeList: Array<string>;
  public editorList: Array<string>;

  protected componentSource: any;
  protected componentSourceAnchor: any;
  protected componentTarget: any;
  protected componentTargetAnchor: any;

  protected edge: any;
  protected componentList: Array<any>;

  protected errorList = [] as Array<ErrorDetail>;

  public constructor(edge: any, components: Array<any>) {
    this.getSourceTarget(edge, components);
  }

  private getSourceTarget(edge: any, components: Array<any>) {
    this.edge = edge;
    this.componentList = components;

    this.componentSource = this.componentList.find((component) => component.id === edge.source);
    if (this.componentSource !== undefined) {
      this.componentSourceAnchor = this.componentSource?.data?.anchors.find(
        (anchor) => anchor.id === edge.sourceHandle
      );
      if (!this.componentSourceAnchor)
        this.componentSourceAnchor = this.componentSource?.data?.instrumentationPorts.find(
          (anchor) => anchor.id === edge.sourceHandle
        );
      if (!this.componentSourceAnchor)
        this.componentSourceAnchor = this.componentSource?.data?.samplingPorts.find(
          (anchor) => anchor.id === edge.sourceHandle
        );
    }

    this.componentTarget = this.componentList.find((component) => component.id === edge.target);
    if (this.componentTarget) {
      this.componentTargetAnchor = this.componentTarget?.data?.anchors.find(
        (anchor) => anchor.id === edge.targetHandle
      );
      if (!this.componentTargetAnchor)
        this.componentTargetAnchor = this.componentTarget?.data?.instrumentationPorts.find(
          (anchor) => anchor.id === edge.targetHandle
        );
      if (!this.componentTargetAnchor)
        this.componentTargetAnchor = this.componentTarget?.data?.samplingPorts.find(
          (anchor) => anchor.id === edge.targetHandle
        );
    }
  }

  abstract checkPrerequisite: () => boolean;

  abstract checkRule: () => void;

  public handle() {
    const isRuleValid = this.checkPrerequisite();
    if (isRuleValid) {
      this.checkRule();
    }
    return this.errorList;
  }
}
