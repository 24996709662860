import { UnitOperationLayer } from '../../../../constants/PFD_EquipmentTabs';
import { UnitOperationEquipmentPFDPanel } from './UnitOperationEquipmentPFDPanel';
import UnitOperationEquipmentReferencePanel from './Reference/UnitOperationEquipmentReferencePanel';
import { PanelHeader } from 'hakobio-react-ui';
import UnitOperationEquipmentPIDPanel from './UnitOperationEquipmentPIDPanel';
import { useIntl } from 'react-intl';
import { configMessages } from '../../../../lang/messages';

interface Props {
  selectedComponent: any;
  layerMode: UnitOperationLayer;
}

const UnitOperationEditorLibraryPanel = ({ selectedComponent, layerMode }: Props) => {
 
  const intl = useIntl();

  const renderContentPFD = () => {
    return (
      <>
        <PanelHeader>{intl.formatMessage(configMessages.functionsComponents)}</PanelHeader>
        <UnitOperationEquipmentPFDPanel />
      </>
    );
  };

  const renderContentPID = () => {
    return <UnitOperationEquipmentPIDPanel selectedComponent={selectedComponent} />;
  };

  const renderContentReference = () => {
    return <UnitOperationEquipmentReferencePanel selectedComponent={selectedComponent} />;
  };

  const renderContent = () => {
    switch (layerMode) {
      case UnitOperationLayer.PFD:
        return renderContentPFD();
      case UnitOperationLayer.PnID:
        return renderContentPID();
      case UnitOperationLayer.Reference:
        return renderContentReference();
    }
  };

  const render = () => {
    return (
      <div
        className="f-full f-col"
        style={{
          width: '400px'
        }}>
        {renderContent()}
      </div>
    );
  };

  return render();
};

export default UnitOperationEditorLibraryPanel;
