import { useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../store';

import { CUSTOM_NODES } from '../../constants/CUSTOM_NODES';

import { AnchorDragEditor } from '../EditorPanel/Shared/DetailPanel/AnchorPropertyDisplay/AnchorDragEditor';
import { useScreenshot } from '../../utilities/useScreenshot';
import { savePNGComponent } from '../../services/editor/editorService';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import { DocumentTitle } from '../../constants/DocumentTitle';
import { ViewMode } from '../../constants/ViewMode';

const FlowEditorComponents = (props) => {
  const customComponentEdition = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentEdition
  );
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const [image, takeScreenshot, clearScreenshot] = useScreenshot(1);
  const dispatch = useAppDispatch();

  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  // const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
  const [_schema, setSchema] = useState<any>(null);

  const isSavingScreenshotComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotComponent
  );

  // const onLoad: OnLoadFunc = (_reactFlowInstance) => {
  //   setTimeout(() => {
  //     _reactFlowInstance?.fitView({ padding: 0.1, includeHiddenNodes: true });
  //   }, 250);
  //   setReactFlowInstance(_reactFlowInstance);
  // };

  useEffect(() => {
    window.parent.postMessage({ message: 'deleteCross', value: 'hide' }, '*');
  }, []);

  useEffect(() => {
    if (viewMode === ViewMode.History) {
      window.parent.postMessage({ message: 'message', value: DocumentTitle.ComponentViewer }, '*');
    } else {
      window.parent.postMessage({ message: 'message', value: DocumentTitle.ComponentEditor }, '*');
    }
  }, [viewMode]);

  const getSchemaAsync = async () => {
    if (customComponentEdition) {
      try {
        const schema = await CUSTOM_NODES[customComponentEdition.data.type].schema();
        setSchema(schema);
      } catch (error) {
        setSchema(null);
      }
    }
  };

  useEffect(() => {
    getSchemaAsync();
  }, []);

  useEffect(() => {
    const takeScreenshotAsync = () => {
      // reactFlowInstance.fitView({ padding: 0.1, includeHiddenNodes: true });
      setTimeout(() => {
        takeScreenshot(reactFlowWrapper.current);
      }, 1000);
    };
    if (
      isSavingScreenshotComponent &&
      // && reactFlowInstance
      reactFlowWrapper.current &&
      customComponentEdition.data.type !== 'tubing'
    ) {
      takeScreenshotAsync();
    }
  }, [isSavingScreenshotComponent]);

  useEffect(() => {
    if (image) {
      // Example call:
      //var file = dataURLtoFile(image, 'screenshot.png');
      savePNGComponent(customComponentEdition.id, image);

      clearScreenshot();
      dispatch(assemblyEditorSliceActions.setSavingScreenshotComponent(false));
    }
  }, [image]);

  const left = window.innerWidth / 2 + 100;
  // Lors de la sauvegarde du screenshot, on set a zéro les attribut de style left et transform sinon le screenshot n'est pas centré
  return (
    <div className="f-row f2-center">
      {/* <ReactFlow
          minZoom={3}
          maxZoom={3}
          elements={_elements}
          onLoad={onLoad}
          paneMoveable={false}
        /> */}

      {_schema && (
        <div
          style={{
            position: 'relative',
            left: isSavingScreenshotComponent ? 0 : left,
            transform: isSavingScreenshotComponent ? 'translateX(0%)' : 'translateX(-50%)'
          }}
          ref={reactFlowWrapper}>
          <AnchorDragEditor schema={_schema} selectedNode={customComponentEdition} />
        </div>
      )}
    </div>
  );
};

export default FlowEditorComponents;
