import { Card, CardContent } from "@mui/material";
import { useState, useRef, useLayoutEffect } from "react";
import { useStyles } from "../styles.startuppanel";
import { useIntl } from "react-intl";
import {
  DoubleButton,
  Icon,
  Label,
  SmallButton,
  ThinChip,
  Tooltip,
} from "hakobio-react-ui";
import AssemblyMenu from "../AssemblyMenu";
import {
  actionMessages,
  statusMessages,
  versioningMessages,
} from "../../../lang/messages";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ListTab } from "../../../constants/ListTab";
import SingleUseEditorReferencesPanel from "../../EditorPanel/SingleUseEditor/ReferencePanel/SingleUseEditorReferencesPanel";
import { assemblyEditorSliceActions } from "../../../store/features/assemblyEditor/assemblyEditorSlice";
import { cloneDeep } from "lodash";
import labelRope from "../../../constants/ficelle-string-label-reference.svg";

import Color from "color";
import { Supplier, SUPPLIERS } from "../../../constants/SUPPLIERS";
import {
  ReferenceStatus,
  referenceStatusBackgroundColors,
  referenceStatusColors,
} from "../../../utilities/statuses/referenceStatus";
import {
  genericStatusBackgroundColors,
  genericStatusColors,
} from "../../../utilities/statuses/genericStatus";
import ImageCard from "./ImageCard";
import StatusTooltip from "./StatusTooltip";
import { useCustomBehavior } from "../../../utils";

const SuaCard = ({
  assembly,
  isHover,
  openAssembly,
}: {
  assembly: any;
  isHover: boolean;
  openAssembly: Function;
}) => {
  const dispatch = useAppDispatch();
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary,
  );
  const theme = useAppSelector((state) => state.assemblyEditorSlice.theme);

  const primaryTranslucid = Color(theme.primaryColor).alpha(0.1).toString();

  const [_isHoverEye, setIsHoverEye] = useState<boolean>(false);
  const [_openTextWidth, setOpenTextWidth] = useState(0);
  const refTextOpen = useRef();
  const { classes } = useStyles();

  const { openReactAppHistoryInNewTab } = useCustomBehavior();

  const intl = useIntl();
  useLayoutEffect(() => {
    if (_isHoverEye && refTextOpen?.current) {
      // @ts-ignore
      setOpenTextWidth(refTextOpen.current.offsetWidth);
    }
  }, [refTextOpen, _isHoverEye]);

  const assemblyRefList = assembly.parameters.find(
    (param) => param.name === "assemblyReferenceList",
  );

  const status = assembly.parameters?.find((p) => p.name === "status")?.value;

  const isReferenceAssembly = assembly?.type === "SingleUseAssemblyReference";

  const openAssemblyVersion = () => {
    let assemblyCopy = cloneDeep(assembly);
    assemblyCopy.hashPartitionKey = assembly.id + "-" + assembly.version;
    localStorage.setItem("action", "assemblyVersion");
    localStorage.setItem("actionData", JSON.stringify(assemblyCopy));
    openReactAppHistoryInNewTab();
  };

  const showModalHistory = () => {
    dispatch(
      assemblyEditorSliceActions.setShowHistoryModal({
        assemblyId: assembly?.id,
      }),
    );
  };

  function nameParentSuaReference() {
    const assemblyIdParent = assembly?.parameters
      ?.find((param) => param.name === "assemblyGenericList")
      ?.complexParameters?.[0].parameters?.find(
        (param) => param.name === "id",
      ).value;
    if (!assemblyIdParent) {
      return null;
    } else {
      const assemblyParentName = editorAssemblyLibrary.find(
        (assembly) => assembly.general.id === assemblyIdParent,
      )?.general.name;
      return (
        <ThinChip
          fontSize="smallText"
          color="var(--primaryColor)"
          backgroundColor={
            isHover ? "var(--primaryColorBcg)" : primaryTranslucid
          }
          blurryBcg={!isHover}
          label={
            assemblyParentName || (
              <span
                title="The parent functional assembly has been deleted"
                className="frozen-grey"
              >
                No functional Assembly
              </span>
            )
          }
          title=""
        />
      );
    }
  }

  const renderButtons = () => {
    const countPermissionsTrue = Object.values(assembly?.permissions).reduce(
      (acc: number, value: boolean) => {
        if (value === true) {
          return (acc || 0) + 1;
        } else {
          return acc;
        }
      },
      0,
    );
    return (
      <div
        style={{
          position: "absolute",
          top: 16,
          right: -18,
          zIndex: 1,
        }}
      >
        {countPermissionsTrue === 1 && assembly?.permissions?.read ? (
          <SmallButton
            onClick={showModalHistory}
            color="var(--anthracite)"
            className="f-row gap-2 f2-center"
          >
            <Icon name="history" />
            {intl.formatMessage(versioningMessages.history)}
          </SmallButton>
        ) : (
          <DoubleButton
            color="var(--anthracite)"
            size="small"
            popContent={<AssemblyMenu assembly={assembly} />}
            onClick={
              assembly?.permissions?.update ||
              assembly?.permissions?.changeStatus
                ? openAssembly
                : showModalHistory
            }
          >
            {assembly?.permissions?.update ||
            assembly?.permissions?.changeStatus ? (
              <div className="f-row gap-2">
                <Icon name="pencil" />
                {intl.formatMessage(actionMessages.edit)}
              </div>
            ) : (
              <div className="f-row gap-2">
                <Icon name="history" />
                {intl.formatMessage(versioningMessages.history)}
              </div>
            )}
          </DoubleButton>
        )}
      </div>
    );
  };

  const renderEye = () => {
    return (
      <div
        className="absolute f-center"
        onMouseEnter={() => setIsHoverEye(true)}
        onMouseLeave={() => setIsHoverEye(false)}
        onClick={openAssemblyVersion}
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "var(--anthracite)",
          borderRadius: 50,
          width: _isHoverEye ? _openTextWidth + 40 : 30,
          maxWidth: 220,
          padding: "1rem",
          height: 30,
          opacity: _isHoverEye ? 1 : 0.8,
          transition: "all .3s ease-in-out",
          cursor: "pointer",
          textWrap: "nowrap",
        }}
      >
        <div className="f-row f2-center gap-2">
          <Icon color="white" size={"1rem"} pointer name="preview" />
          {_isHoverEye && (
            <div
              style={{
                opacity: _isHoverEye ? 1 : 0,
                transition: "all .3s",
              }}
            >
              <div
                ref={refTextOpen}
                className="small-text "
                style={{ color: "white" }}
              >
                {intl.formatMessage(versioningMessages.view)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  function genericCardInfos() {
    return (
      <CardContent
        className="f-col gap-3"
        style={{
          color: "white",
          backgroundColor: "var(--primaryColor)",
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
          padding: "8px 16px",
        }}
      >
        <div className={"f-row f1-end f2-center gap-2"}>
          <div className="f-shrink-0">{`v. ${assembly?.version + 1}`}</div>
          <div className="f-row gap-2 f2-center">
            <Tooltip
              title={<StatusTooltip status={status} assemblyType="generic" />}
            >
              <ThinChip
                fontSize="smallText"
                color={
                  status
                    ? genericStatusColors(status)
                    : genericStatusColors("No status")
                }
                backgroundColor={
                  status
                    ? genericStatusBackgroundColors(status)
                    : genericStatusBackgroundColors("No status")
                }
                label={
                  status ? (
                    <span className="capitalize">{status}</span>
                  ) : (
                    <span className="capitalize">{"No status"}</span>
                  )
                }
                title=""
              />
            </Tooltip>
          </div>
        </div>

        <div className="f-col gap-1">
          <div className=" max-1-lines-visible">{assembly?.name}</div>

          <div className="f-row f1-start mb-1">
            <Tooltip
              className="tooltip-p-0"
              placement="top-start"
              title={
                assemblyRefList?.complexParameters?.length
                  ? showRefsInfos()
                  : ""
              }
            >
              <div className="small-text">
                {assemblyRefList?.complexParameters?.length !== undefined ? (
                  <div>
                    <span className="regular">
                      {assemblyRefList?.complexParameters?.length}{" "}
                    </span>
                    {assemblyRefList?.complexParameters?.length === 1 ||
                    assemblyRefList?.complexParameters?.length === 0
                      ? "reference"
                      : "references"}
                  </div>
                ) : (
                  <div>
                    <span className="regular">0</span> reference
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </CardContent>
    );
  }

  function showRefsInfos() {
    const assemblyReferenceList = assembly.parameters.find(
      (param) => param.name === "assemblyReferenceList",
    );

    return (
      <div style={{ maxHeight: 400, overflowY: "auto", minWidth: 250 }}>
        <SingleUseEditorReferencesPanel
          startUpPanel={true}
          referenceList={assemblyReferenceList?.complexParameters}
        />
      </div>
    );
  }

  function refCardInfos() {
    const supplierId = assembly.parameters?.find(
      (p) => p.name === "supplier",
    )?.value;
    const supplier = SUPPLIERS.find(
      (supplier: Supplier) => supplier.id === supplierId,
    )?.name;

    return (
      <div className="relative mt-2" style={{ paddingTop: 2, paddingLeft: 2 }}>
        {labelRopeComponent}
        <Label
          backgroundColor={"var(--primaryColor)"}
          borderColor={"var(--primaryColor)"}
          shadow={false}
        >
          <div className="f-col gap-3" style={{ color: "white" }}>
            <div className={"f-row f1-between f2-center gap-2"}>
              <div className="f-shrink-0">{`v. ${assembly?.version + 1}`}</div>
              <div className={"f-row f1-between f2-center gap-2"}>
                <Tooltip
                  title={
                    <StatusTooltip status={status} assemblyType="reference" />
                  }
                >
                  <ThinChip
                    fontSize="smallText"
                    color={
                      status
                        ? referenceStatusColors(status)
                        : referenceStatusColors(ReferenceStatus.PreOnboarding)
                    }
                    backgroundColor={
                      status
                        ? referenceStatusBackgroundColors(status)
                        : referenceStatusBackgroundColors(
                            ReferenceStatus.PreOnboarding,
                          )
                    }
                    label={
                      status ? (
                        <span className="capitalize">{status}</span>
                      ) : (
                        <span className="capitalize">
                          {intl.formatMessage(statusMessages.noStatus)}
                        </span>
                      )
                    }
                    title=""
                  />
                </Tooltip>
              </div>
            </div>

            <div>
              <div
                className="font-regular max-1-lines-visible"
                title={assembly?.name}
                style={{ lineHeight: "21px" }}
              >
                {assembly?.name}
              </div>
              <div
                className="max-1-lines-visible"
                style={{ lineHeight: "21px" }}
              >
                {supplier ? (
                  supplier
                ) : (
                  <span style={{ color: "var(--grey)" }}>
                    {intl.formatMessage({
                      id: "Assembly.NoSupplier",
                      defaultMessage: "No supplier assigned",
                    })}
                  </span>
                )}
              </div>
            </div>
          </div>
        </Label>
      </div>
    );
  }

  const punchHole = (
    <div
      style={{
        width: 14,
        height: 14,
        borderRadius: 50,
        border: "1px solid var(--dark-grey)",
        backgroundColor: "white",
        position: "absolute",
        bottom: 12,
        left: 12,
        boxShadow: "inset 0 0  10px var(--grey)",
      }}
    />
  );

  const labelRopeComponent = (
    <img
      src={labelRope}
      height={75}
      alt=""
      style={{
        position: "absolute",
        top: -28,
        left: 12,
        transform: "rotate(10deg)",
        zIndex: 4,
      }}
    />
  );

  return (
    <>
      <Card
        style={{
          borderRadius: isReferenceAssembly ? "0 0 0 0" : "0 0 16px 16px",
          overflow: "visible",
          border: "none",
        }}
        className={`${classes.Card} relative`}
      >
        <div
          className="absolute f-row f2-center gap-2"
          style={{ top: ".75rem", left: ".5rem", cursor: "grab", zIndex: 1 }}
        >
          {isHover && assembly.permissions.move && (
            <Icon
              color="var(--primaryColor)"
              name="grab-handle"
              style={{ cursor: "grab" }}
            />
          )}
          {isReferenceAssembly && nameParentSuaReference()}
        </div>
        {isHover && renderButtons()}

        <div className={`${classes.img} f-center relative`}>
          {assembly?.permissions?.read &&
            isHover &&
            ListTab.SUA === listTab &&
            renderEye()}
          <ImageCard assembly={assembly} />
          {isReferenceAssembly && punchHole}
          {assembly.isShortCut && (
            <div
              style={{
                position: "absolute",
                bottom: 6,
                right: 4,
                fontSize: "20px",
                color: "var(--primaryColor)",
                backgroundColor: "white",
                borderRadius: "4px",
                padding: ".4rem .4rem .5rem .5rem",
                border: "1px solid var(--grey)",
              }}
            >
              <Tooltip title={"Shortcut"}>
                <Icon name="external-link" />
              </Tooltip>
            </div>
          )}
        </div>
        {!isReferenceAssembly && genericCardInfos()}
      </Card>
      {isReferenceAssembly && refCardInfos()}
    </>
  );
};
export default SuaCard;
