import { v4 as uuidv4 } from 'uuid';
export class Media {
  id = uuidv4();
  name = '';
  text = '';
  url = '';
  mediaType = 10;
  organizationId = 1;
  isFavorite = false;
}

