import {useAppSelector} from "./store";

export function useCustomBehavior() {
    const theme = useAppSelector((state) => state.assemblyEditorSlice.theme);


    const openReactAppHistoryInNewTab = () => {
        const currentOrganization = theme.team;
        const urlToOpen =  process.env.REACT_APP_HISTORY_REDIRECTION.replace('{orgaSubDomain}', currentOrganization);

        if(urlToOpen === undefined){
            console.error('No url to open');
            return;
        }
        window.open(urlToOpen, '_blank');
    }


    return {
        openReactAppHistoryInNewTab
    };
}