import {
  ActionPermission,
  Assembly,
  AssemblyComplexParameter,
  AssemblyComponent,
  AssemblyDto,
  AssemblyParameter,
  CustomComponentComplexParameter,
  CustomComponentParameter
} from '../../app/web-api-client';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { Media } from '../../utilities/Media';
import { cloneDeep } from 'lodash';

export const convertToReadAssemblyGeneral = (assembly: AssemblyDto) => {
  const descriptionValue = assembly.parameters?.find((p) => p.name === 'description')?.value;

  const functionValues = assembly.parameters
    ?.find((p) => p.name === 'functions')
    ?.complexParameters.map((p) => {
      return p.name.toLowerCase();
    });

  const functionAnnexeValues = assembly.parameters
    ?.find((fctAnnexe) => fctAnnexe.name === 'ancillaryFunctions')
    ?.complexParameters?.map((fctA) => {
      return {
        name: fctA.name,
        complexParameters: fctA.parameters.map((fctAnnexeparam) => {
          return {
            name: fctAnnexeparam.name
          };
        })
      };
    });
  const medias = assembly.parameters
    ?.find((m) => m.name === 'medias')
    ?.complexParameters?.map((media) => {
      let mediaId = media.parameters.find((p) => p.name === 'id')?.value;
      let mediaName = media.parameters.find((p) => p.name === 'name')?.value;
      let mediaText = media.parameters.find((p) => p.name === 'text')?.value;
      let mediaUrl = media.parameters.find((p) => p.name === 'url')?.value;
      let mediaType = media.parameters.find((p) => p.name === 'mediaType')?.value;
      let mediaOrganizationId = media.parameters.find((p) => p.name === 'organizationId')?.value;
      let mediaIsFavorite = media.parameters.find((p) => p.name === 'isFavorite')?.value;
      return {
        id: mediaId,
        name: mediaName,
        text: mediaText,
        url: mediaUrl,
        mediaType: Number(mediaType),
        organizationId: Number(mediaOrganizationId),
        isFavorite: mediaIsFavorite === 'true'
      } as Media;
    });

  const supplierValue = assembly.parameters?.find((p) => p.name === 'supplier')?.value;

  const assemblyGenericList = assembly?.parameters
    ?.find((p) => p.name === 'assemblyGenericList')
    ?.complexParameters?.map((cp) => {
      return {
        id: cp.id,
        name: cp.name, //asemblyGeneric
        parameters: cp.parameters?.map((cpp) => {
          return {
            id: cpp.id,
            name: cpp.name,
            type: cpp.type,
            value: cpp.value
          };
        })
      };
    });

  const assemblyReferenceList = assembly?.parameters
    ?.find((p) => p.name === 'assemblyReferenceList')
    ?.complexParameters?.map((cp) => {
      return {
        id: cp.id,
        name: cp.name, //asemblyGeneric
        parameters: cp.parameters?.map((cpp) => {
          return {
            id: cpp.id,
            name: cpp.name,
            type: cpp.type,
            value: cpp.value
          };
        })
      };
    });

  const tagValues = assembly.parameters
    ?.find((p) => p.name === 'tags')
    ?.complexParameters.map((cp) => {
      return {
        id: cp.id,
        name: cp.parameters.find((cpp) => cpp.name === 'name')?.value,
        function: cp.parameters.find((cpp) => cpp.name === 'function')?.value,
        value: cp.parameters.find((cpp) => cpp.name === 'value')?.value
      };
    });

  const status = assembly.parameters?.find((p) => p.name === 'status')?.value || null;
  return {
    id: assembly.id,
    organizationId: assembly.organizationId,
    hashPartitionKey: assembly.hashPartitionKey || null,
    name: assembly.name,
    description: descriptionValue,
    functions: functionValues || [],
    ancillaryFunctions: functionAnnexeValues || [],
    medias: medias || [],
    assemblyGenericList: assemblyGenericList || [],
    assemblyReferenceList: assemblyReferenceList || [],
    type: assembly.type,
    createdOn: assembly.createdOn,
    createdByName: assembly.createdByName,
    modifiedOn: assembly.modifiedOn,
    modifiedByName: assembly.modifiedByName,
    supplier: supplierValue,
    tags: tagValues || [],
    version: assembly?.version,
    versionMessage: assembly?.versionMessage || '', // @ts-ignore
    isPrivate: assembly.isPrivate,
    status: status,
    permissions: assembly.permissions,
    parentFolderId: assembly.parentFolderId || null
  };
};

function dataAnchorsList(anchors: any, parameter: any) {
  parameter.complexParameters?.forEach((cp) => {
    const newAnchor = {
      id: null,
      type: cp.type,
      data: { componentLink: null, componentLinkReference: null },
      viewer2D: { top: null, left: null }
    };
    cp.parameters.forEach((cpp) => {
      if (cpp.name === 'id') {
        newAnchor.id = cpp.value;
      } else if (cpp.name.includes('componentLinkReference')) {
        const keys = cpp.name.split('.');
        if (!newAnchor.data.componentLinkReference) {
          newAnchor.data.componentLinkReference = { anchor: null, component: null };
        }
        newAnchor.data.componentLinkReference[keys[1]] = cpp.value;
      } else if (cpp.name.includes('componentLink')) {
        const keys = cpp.name.split('.');
        if (!newAnchor.data.componentLink) {
          newAnchor.data.componentLink = { anchor: null, component: null };
        }
        newAnchor.data.componentLink[keys[1]] = cpp.value;
      } else if (cpp.name === 'viewer2D.top') {
        newAnchor.viewer2D.top = Number(cpp.value);
      } else if (cpp.name === 'viewer2D.left') {
        newAnchor.viewer2D.left = Number(cpp.value);
      } else {
        newAnchor.data[cpp.name] = cpp.value;
      }
    });
    anchors.push(newAnchor);
  });
  return anchors;
}

function convertToReadAssemblyComponents(
  assembly: Assembly,
  general: {
    createdByName: string;
    functions: string[];
    description: string;
    type: string;
    hashPartitionKey: string;
    createdOn: Date;
    version: number;
    tags: { function: string; name: string; id: string; value: string }[];
    organizationId: number;
    medias: Media[];
    modifiedOn: Date;
    modifiedByName: string;
    ancillaryFunctions: { complexParameters: { name: string }[]; name: string }[];
    assemblyReferenceList: {
      name: string;
      id: string;
      parameters: { name: string; id: string; type: string; value: string }[];
    }[];
    permissions: ActionPermission;
    supplier: string;
    name: string;
    isPrivate: any;
    id: string;
    assemblyGenericList: {
      name: string;
      id: string;
      parameters: { name: string; id: string; type: string; value: string }[];
    }[];
    versionMessage: string;
    status: string;
  }
) {
  const components = [] as any;

  if (assembly.components) {
    assembly.components.forEach((component) => {
      const componentToRead = {} as any;
      componentToRead.id = component.parameters?.find((p) => p.name === 'id')?.value;
      componentToRead.type = component.type;
      componentToRead.name = component.name;
      componentToRead.data = {};
      component.parameters?.forEach((parameter) => {
        if (parameter.name === 'id') {
          //NOTHING
        } else if (parameter.name?.includes('viewer2D')) {
          //NOTHING
        } else if (parameter.name?.includes('anchors')) {
          const anchors = [] as any;
          componentToRead.data.anchors = anchors;
          dataAnchorsList(anchors, parameter);
        } else if (parameter.name?.includes('samplingPorts')) {
          const samplingPorts = [] as any;
          componentToRead.data.samplingPorts = samplingPorts;
          dataAnchorsList(samplingPorts, parameter);
        } else if (parameter.name?.includes('instrumentationPorts')) {
          const instrumentationPorts = [] as any;
          componentToRead.data.instrumentationPorts = instrumentationPorts;
          dataAnchorsList(instrumentationPorts, parameter);
        } else if (parameter.name?.includes('assemblyReference')) {
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          const keys = parameter.name.split('.');
          if (!componentToRead.data.assemblyReference) {
            componentToRead.data.assemblyReference = {};
          }
          if (parameter.type === 'string') {
            componentToRead.data[keys[0]][keys[1]] = parameter.value;
          } else {
            componentToRead.data[keys[0]][keys[1]] = JSON.parse(parameter.value);
          }
        } else if (parameter.name?.includes('assembly')) {
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          const keys = parameter.name.split('.');
          if (!componentToRead.data.assembly) {
            componentToRead.data.assembly = {};
          }
          if (parameter.type === 'string') {
            componentToRead.data[keys[0]][keys[1]] = parameter.value;
          } else {
            componentToRead.data[keys[0]][keys[1]] = JSON.parse(parameter.value);
          }
        } else if (parameter.name?.includes('idDragReference')) {
          const keys = parameter.name.split('.');
          if (!componentToRead.data.idDragReference) {
            componentToRead.data.idDragReference = {};
          }
          componentToRead.data[keys[0]][keys[1]] = parameter.value;
        } else if (parameter.name?.includes('idDrag')) {
          const keys = parameter.name.split('.');
          if (!componentToRead.data.idDrag) {
            componentToRead.data.idDrag = {};
          }
          componentToRead.data[keys[0]][keys[1]] = parameter.value;
        } else if (parameter.name?.includes('source') || parameter.name?.includes('target')) {
          componentToRead[parameter.name] = parameter.value;
        } else if (parameter.type === 'string') {
          // @ts-ignore
          componentToRead.data[parameter.name] = parameter.value;
        } else if (parameter.type === 'number') {
          // @ts-ignore
          componentToRead.data[parameter.name] = Number(parameter.value);
        } else if (parameter.type === 'boolean') {
          // @ts-ignore
          componentToRead.data[parameter.name] = parameter.value === 'true';
        } else if (parameter.type === 'array') {
          // @ts-ignore
          componentToRead.data[parameter.name] = JSON.parse(parameter.value);
        }
      });
      if (general.type === 'SingleUseAssembly' || general.type === 'SingleUseAssemblyReference') {
        const viewerX = component.parameters?.find((p) => p.name === 'viewer2D.x')?.value;
        const viewerY = component.parameters?.find((p) => p.name === 'viewer2D.y')?.value;
        const viewerPosition = component.parameters?.find(
          (p) => p.name === 'viewer2D.position'
        )?.value;
        const viewerSize = component.parameters?.find((p) => p.name === 'viewer2D.size')?.value;
        const viewerRotate = component.parameters?.find((p) => p.name === 'viewer2D.rotate')?.value;
        const viewerSource = component.parameters?.find((p) => p.name === 'viewer2D.source')?.value;
        const viewerFlipX = component.parameters?.find((p) => p.name === 'viewer2D.flipX')?.value;
        const viewerFlipY = component.parameters?.find((p) => p.name === 'viewer2D.flipY')?.value;
        componentToRead.viewer2D = {
          x: viewerX ? Number(viewerX) : null,
          y: viewerY ? Number(viewerY) : null,
          position: viewerPosition ? Number(viewerPosition) : null,
          size: viewerSize ? Number(viewerSize) : null,
          rotate: viewerRotate ? Number(viewerRotate) : null,
          source: viewerSource ? viewerSource : null,
          flipX: viewerFlipX ? Boolean(viewerFlipX) : null,
          flipY: viewerFlipY ? Boolean(viewerFlipY) : null
        };
      } else {
        const viewerPFDX = component.parameters?.find((p) => p.name === 'viewer2D.pfd.x')?.value;
        const viewerPFDY = component.parameters?.find((p) => p.name === 'viewer2D.pfd.y')?.value;
        const viewerPIDX = component.parameters?.find((p) => p.name === 'viewer2D.pid.x')?.value;
        const viewerPIDY = component.parameters?.find((p) => p.name === 'viewer2D.pid.y')?.value;
        const viewerPosition = component.parameters?.find(
          (p) => p.name === 'viewer2D.position'
        )?.value;
        const viewerSize = component.parameters?.find((p) => p.name === 'viewer2D.size')?.value;
        const viewerRotate = component.parameters?.find((p) => p.name === 'viewer2D.rotate')?.value;
        const viewerSource = component.parameters?.find((p) => p.name === 'viewer2D.source')?.value;
        const viewerFlipX = component.parameters?.find((p) => p.name === 'viewer2D.flipX')?.value;
        const viewerFlipY = component.parameters?.find((p) => p.name === 'viewer2D.flipY')?.value;
        const viewerDraggable = component.parameters?.find(
          (p) => p.name === 'viewer2D.draggable'
        )?.value;
        if (viewerDraggable) {
          componentToRead.draggable = viewerDraggable === 'true';
        }
        if (viewerPFDX && viewerPFDY && viewerPIDX && viewerPIDY) {
          componentToRead.viewer2D = {
            pfd: {
              x: viewerPFDX ? Number(viewerPFDX) : null,
              y: viewerPFDY ? Number(viewerPFDY) : null
            },
            pid: {
              x: viewerPIDX ? Number(viewerPIDX) : null,
              y: viewerPIDY ? Number(viewerPIDY) : null
            },
            position: viewerPosition ? Number(viewerPosition) : null,
            size: viewerSize ? Number(viewerSize) : null,
            rotate: viewerRotate ? Number(viewerRotate) : null,
            source: viewerSource ? viewerSource : null,
            flipX: viewerFlipX ? Boolean(viewerFlipX) : null,
            flipY: viewerFlipY ? Boolean(viewerFlipY) : null
          };
        } else {
          componentToRead.viewer2D = {
            x: null,
            y: null,
            position: viewerPosition ? Number(viewerPosition) : null,
            size: viewerSize ? Number(viewerSize) : null,
            rotate: viewerRotate ? Number(viewerRotate) : null,
            source: viewerSource ? viewerSource : null,
            flipX: viewerFlipX ? Boolean(viewerFlipX) : null,
            flipY: viewerFlipY ? Boolean(viewerFlipY) : null
          };
        }
      }
      components.push(componentToRead);
    });
  }
  return components;
}

export const convertToRead = (assembly: Assembly) => {
  const general = convertToReadAssemblyGeneral(assembly);
  const components = convertToReadAssemblyComponents(assembly, general);
  if (general.type === 'UnitOperation') {
    components.forEach((component) => {
      if (component.type === 'functionedge') {
        const source = components.find((c) => c.id === component.source);
        const sourceAnchor = source?.data.anchors.find((h) => h.id === component.sourceHandle);
        const target = components.find((c) => c.id === component.target);
        const targetAnchor = target?.data.anchors.find((h) => h.id === component.targetHandle);
        if (source && source.type !== 'function') {
          component.source = sourceAnchor.data.componentLink?.component;
          component.sourceHandle = sourceAnchor.data.componentLink?.anchor;
        }
        if (target && target.type !== 'function') {
          component.target = targetAnchor.data.componentLink?.component;
          component.targetHandle = targetAnchor.data.componentLink?.anchor;
        }
        if (component.data.implementableReference === undefined) {
          component.data.implementableReference = true;
        }
      }
      if (
        component.type !== 'functionedge' &&
        component.type !== 'genericedge' &&
        !component.viewer2D.reference
      ) {
        component.viewer2D.reference = cloneDeep(component.viewer2D.pid);
      }
    });
  }

  return { general, components };
};

function convertForSaveAssemblyGeneral(general: any) {
  // si sua généric ajout de la liste références ou si sua réference ajout généricList
  let dynamicParameters = [
    {
      id: uuidv4(),
      name: 'functions',
      type: 'array',
      value: '',
      complexParameters: general.functions.map((f: any) => {
        return {
          id: uuidv4(),
          name: f,
          parameters: [],
          type: 'string'
        } as AssemblyComplexParameter;
      })
    },
    {
      id: uuidv4(),
      name: 'medias',
      type: 'array',
      value: '',
      complexParameters: general.medias?.map((media: Media) => {
        return {
          id: media.id,
          name: media.name,
          parameters: Object.keys(media).map((key) => {
            return {
              id: uuidv4(),
              name: key,
              type:
                key === 'mediaType' || key === 'organizationId'
                  ? 'number'
                  : key === 'isFavorite'
                  ? 'boolean'
                  : 'string',
              value: media[key].toString(),
              complexParameters: []
            };
          }),
          type: 'media'
        } as AssemblyComplexParameter;
      })
    },
    {
      id: uuidv4(),
      name: 'ancillaryFunctions',
      type: 'array',
      value: '',
      complexParameters: general.ancillaryFunctions?.map((fctAnnexe: any) => {
        return {
          id: uuidv4(),
          name: fctAnnexe.name,
          parameters: fctAnnexe.complexParameters.map((annexe) => {
            return {
              id: uuidv4(),
              name: annexe.name,
              type: 'array',
              value: '',
              complexParameters: []
            };
          }),
          type: 'array'
        } as AssemblyComplexParameter;
      })
    },
    {
      id: uuidv4(),
      name: 'description',
      type: 'string',
      value: general.description,
      complexParameters: []
    },
    {
      id: uuidv4(),
      name: 'tags',
      type: 'array',
      value: '',
      complexParameters: general.tags.map((tag: any) => {
        return {
          id: tag.id,
          name: tag.name,
          parameters: [
            {
              id: uuidv4(),
              name: 'name',
              complexParameters: [],
              type: 'string',
              value: tag.name
            },
            {
              id: uuidv4(),
              name: 'function',
              complexParameters: [],
              type: 'string',
              value: tag.function
            },
            {
              id: uuidv4(),
              name: 'value',
              complexParameters: [],
              type: 'string',
              value: tag.value
            }
          ],
          type: 'tag'
        } as AssemblyComplexParameter;
      })
    },
    {
      id: uuidv4(),
      name: 'supplier',
      type: 'string',
      value: general.supplier,
      complexParameters: []
    }
  ];

  if (general.type === 'SingleUseAssemblyReference') {
    dynamicParameters.push({
      id: uuidv4(),
      name: 'assemblyGenericList',
      type: 'array',
      value: '',
      complexParameters: general?.assemblyGenericList?.map((f: any) => {
        return {
          id: uuidv4(),
          name: f.name,
          parameters: f?.parameters?.map((assGen) => {
            return {
              id: uuidv4(),
              name: assGen?.name,
              type: 'array',
              value: assGen?.value,
              complexParameters: []
            };
          })
        } as AssemblyComplexParameter;
      })
    });
  } else if (general.type === 'SingleUseAssembly') {
    dynamicParameters.push({
      id: uuidv4(),
      name: 'assemblyReferenceList',
      type: 'array',
      value: '',
      complexParameters: general?.assemblyReferenceList?.map((f: any) => {
        return {
          id: uuidv4(),
          name: f.name,
          parameters: f?.parameters?.map((assGen) => {
            return {
              id: uuidv4(),
              name: assGen?.name,
              type: 'array',
              value: assGen.value,
              complexParameters: []
            };
          })
        } as AssemblyComplexParameter;
      })
    });
  }
  dynamicParameters.push({
    id: uuidv4(),
    name: 'status',
    type: 'string',
    value: general.status,
    complexParameters: []
  });
  return dynamicParameters;
}

function convertToSaveAssemblyComponents(components: any) {
  const componentsToSave = [] as Array<AssemblyComponent>;
  components
    .filter((component: any) => component.type !== 'selection')
    .forEach((component: any) => {
      const parametersToSave = [] as Array<AssemblyParameter>;
      const componentToSave = {
        id: uuidv4(),
        name: component.type,
        type: component.type,
        parameters: parametersToSave
      } as AssemblyComponent;
      componentsToSave.push(componentToSave);
      // @ts-ignore
      parametersToSave.push({
        id: uuidv4(),
        name: 'id',
        type: 'string',
        value: component.id,
        complexParameters: []
      });
      if (component.viewer2D) {
        if (component.viewer2D.x) {
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.x',
            type: 'number',
            value: component.viewer2D.x.toString(),
            complexParameters: []
          });
        }
        if (component.viewer2D.y) {
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.y',
            type: 'number',
            value: component.viewer2D.y.toString(),
            complexParameters: []
          });
        }
        if (component.viewer2D.pfd) {
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.pfd.x',
            type: 'number',
            value: component.viewer2D.pfd.x.toString(),
            complexParameters: []
          });
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.pfd.y',
            type: 'number',
            value: component.viewer2D.pfd.y.toString(),
            complexParameters: []
          });
        }
        if (component.viewer2D.pid) {
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.pid.x',
            type: 'number',
            value: component.viewer2D.pid.x.toString(),
            complexParameters: []
          });
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.pid.y',
            type: 'number',
            value: component.viewer2D.pid.y.toString(),
            complexParameters: []
          });
        }
        if (component.viewer2D.position) {
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.position',
            type: 'number',
            value: component.viewer2D.position.toString(),
            complexParameters: []
          });
        }
        if (component.viewer2D.size) {
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.size',
            type: 'number',
            value: component.viewer2D.size.toString(),
            complexParameters: []
          });
        }
        if (component.viewer2D.rotate) {
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.rotate',
            type: 'number',
            value: component.viewer2D.rotate.toString(),
            complexParameters: []
          });
        }
        if (component.viewer2D.source) {
          //@ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.source',
            type: 'number',
            value: component.viewer2D.source,
            complexParameters: []
          });
        }
        if (component.viewer2D.flipX) {
          //@ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.flipX',
            type: 'boolean',
            value: component.viewer2D.flipX.toString(),
            complexParameters: []
          });
        }
        if (component.viewer2D.flipY) {
          //@ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.flipY',
            type: 'boolean',
            value: component.viewer2D.flipY.toString(),
            complexParameters: []
          });
        }
        if (component.draggable) {
          //@ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: 'viewer2D.draggable',
            type: 'boolean',
            value: component.draggable.toString(),
            complexParameters: []
          });
        }
      }
      if (component.type === 'genericedge' || component.type === 'functionedge') {
        // @ts-ignore
        parametersToSave.push({
          id: uuidv4(),
          name: 'source',
          type: 'string',
          value: component.source,
          complexParameters: []
        });
        // @ts-ignore
        parametersToSave.push({
          id: uuidv4(),
          name: 'target',
          type: 'string',
          value: component.target,
          complexParameters: []
        });
        // @ts-ignore
        parametersToSave.push({
          id: uuidv4(),
          name: 'sourceHandle',
          type: 'string',
          value: component.sourceHandle,
          complexParameters: []
        });
        // @ts-ignore
        parametersToSave.push({
          id: uuidv4(),
          name: 'targetHandle',
          type: 'string',
          value: component.targetHandle,
          complexParameters: []
        });
      }
      const entries = Object.entries(component.data);
      entries.forEach(([key, value]: [key: string, value: any]) => {
        if (typeof value === 'object') {
          if (key === 'anchors' || key === 'samplingPorts' || key === 'instrumentationPorts') {
            const complexParameters = [] as Array<AssemblyComplexParameter>;
            // @ts-ignore
            parametersToSave.push({
              id: uuidv4(),
              name: key,
              type: 'array',
              value: '',
              complexParameters: complexParameters
            });
            value.forEach((anchor: any) => {
              const anchorParameters = [
                {
                  id: uuidv4(),
                  name: 'id',
                  type: 'string',
                  value: anchor.id
                },
                {
                  id: uuidv4(),
                  name: 'viewer2D.top',
                  type: 'number',
                  value: anchor.viewer2D.top.toString()
                },
                {
                  id: uuidv4(),
                  name: 'viewer2D.left',
                  type: 'number',
                  value: anchor.viewer2D.left.toString()
                }
              ];
              const anchorEntries = Object.entries(anchor.data);
              anchorEntries.forEach(
                ([anchorKey, anchorValue]: [anchorKey: string, anchorValue: any]) => {
                  if (typeof anchorValue === 'object') {
                    if (anchorValue === null) {
                    } else if (anchorKey === 'componentLink') {
                      anchorParameters.push({
                        id: uuidv4(),
                        name: 'componentLink.anchor',
                        type: 'string',
                        value: anchorValue.anchor
                      });
                      // @ts-ignore
                      anchorParameters.push({
                        id: uuidv4(),
                        name: 'componentLink.component',
                        type: 'string',
                        value: anchorValue.component
                      });
                    } else if (anchorKey === 'componentLinkReference') {
                      anchorParameters.push({
                        id: uuidv4(),
                        name: 'componentLinkReference.anchor',
                        type: 'string',
                        value: anchorValue.anchor
                      });
                      // @ts-ignore
                      anchorParameters.push({
                        id: uuidv4(),
                        name: 'componentLinkReference.component',
                        type: 'string',
                        value: anchorValue.component
                      });
                    }
                  } else {
                    anchorParameters.push({
                      id: uuidv4(),
                      name: anchorKey,
                      type: typeof anchorValue,
                      value: anchorValue
                    });
                  }
                }
              );
              const complexParam = {
                id: uuidv4(),
                name: anchor.data.name,
                type: anchor.type,
                parameters: anchorParameters as Array<AssemblyParameter>
              } as AssemblyComplexParameter;
              complexParameters.push(complexParam);
            });
          } else if (key === 'assembly') {
            if (value.id) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'assembly.id',
                type: 'string',
                value: value.id,
                complexParameters: []
              });
            }
            if (value.component) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'assembly.component',
                type: 'string',
                value: value.component,
                complexParameters: []
              });
            }
            if (value.general) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'assembly.general',
                type: 'object',
                value: JSON.stringify(value.general),
                complexParameters: []
              });
            }
            if (value.components) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'assembly.components',
                type: 'array',
                value: JSON.stringify(value.components),
                complexParameters: []
              });
            }
          } else if (key === 'assemblyReference') {
            if (value.id) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'assemblyReference.id',
                type: 'string',
                value: value.id,
                complexParameters: []
              });
            }
            if (value.component) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'assemblyReference.component',
                type: 'string',
                value: value.component,
                complexParameters: []
              });
            }
            if (value.general) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'assemblyReference.general',
                type: 'object',
                value: JSON.stringify(value.general),
                complexParameters: []
              });
            }
            if (value.components) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'assemblyReference.components',
                type: 'array',
                value: JSON.stringify(value.components),
                complexParameters: []
              });
            }
          } else if (key === 'idDragReference') {
            if (value.edge) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'idDragReference.edge',
                type: 'string',
                value: value.edge,
                complexParameters: []
              });
            }
            if (value.id) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'idDragReference.id',
                type: 'string',
                value: value.id,
                complexParameters: []
              });
            }
          } else if (key === 'idDrag') {
            if (value.edge) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'idDrag.edge',
                type: 'string',
                value: value.edge,
                complexParameters: []
              });
            }
            if (value.id) {
              // @ts-ignore
              parametersToSave.push({
                id: uuidv4(),
                name: 'idDrag.id',
                type: 'string',
                value: value.id,
                complexParameters: []
              });
            }
          } else if (Array.isArray(value)) {
            // @ts-ignore
            parametersToSave.push({
              id: uuidv4(),
              name: key,
              type: 'array',
              value: JSON.stringify(value),
              complexParameters: []
            });
          }
        } else {
          // @ts-ignore
          parametersToSave.push({
            id: uuidv4(),
            name: key,
            type: typeof value,
            value: value.toString(),
            complexParameters: []
          });
        }
      });
    });
  return componentsToSave;
}

export const convertForSave = (general: any, components: any) => {
  if (!general.type) {
    throw 'convertForSave - Assembly has no types';
  }
  if (components.find((c) => c.type === 'ghost')) {
    throw 'convertForSave - Ghost in progress';
  }
  const componentsToSave = convertToSaveAssemblyComponents(components);
  const parametersToSave = convertForSaveAssemblyGeneral(general);

  return {
    id: general.id,
    name: general.name,
    type: general.type,
    organizationId: 1,
    createdOn: general.createdOn,
    createdByName: general.createdByName,
    hashPartitionKey: general.hashPartitionKey || null,
    modifiedOn: general.modifiedOn,
    modifiedByName: general.modifiedByName,
    components: componentsToSave,
    isPrivate: general.isPrivate,
    version: general.version,
    versionMessage: general.versionMessage,
    parameters: parametersToSave,
    parentFolderId: general.parentFolderId || null
  } as any as Assembly;
};

export const convertToReadComponent = (component: any) => {
  const componentToRead = {} as any;
  componentToRead.id = component.id;
  componentToRead.type = component.type;
  componentToRead.name = component.name;
  componentToRead.data = {};
  component.parameters?.forEach((parameter) => {
    if (parameter.name?.includes('viewer2D')) {
      return;
    } else if (parameter.name?.includes('anchors')) {
      const anchors = [] as any;
      componentToRead.data.anchors = anchors;
      dataAnchorsList(anchors, parameter);
    } else if (parameter.name?.includes('samplingPorts')) {
      const samplingPorts = [] as any;
      componentToRead.data.samplingPorts = samplingPorts;
      dataAnchorsList(samplingPorts, parameter);
    } else if (parameter.name?.includes('instrumentationPorts')) {
      const instrumentationPorts = [] as any;
      componentToRead.data.instrumentationPorts = instrumentationPorts;
      dataAnchorsList(instrumentationPorts, parameter);
    } else if (parameter.name?.includes('assembly')) {
      const keys = parameter.name.split('.');
      if (!componentToRead.data.assembly) {
        componentToRead.data.assembly = {};
      }
      if (parameter.type === 'string') {
        componentToRead.data[keys[0]][keys[1]] = parameter.value;
      } else {
        componentToRead.data[keys[0]][keys[1]] = JSON.parse(parameter.value);
      }
    } else if (parameter.name?.includes('source') || parameter.name?.includes('target')) {
      componentToRead[parameter.name] = parameter.value;
    } else if (parameter.type === 'string') {
      // @ts-ignore
      componentToRead.data[parameter.name] = parameter.value;
    } else if (parameter.type === 'number') {
      // @ts-ignore
      componentToRead.data[parameter.name] = Number(parameter.value);
    } else if (parameter.type === 'boolean') {
      // @ts-ignore
      componentToRead.data[parameter.name] = parameter.value === 'true';
    }
  });
  const viewerX = component.parameters?.find((p) => p.name === 'viewer2D.x')?.value;
  const viewerY = component.parameters?.find((p) => p.name === 'viewer2D.y')?.value;
  const viewerPosition = component.parameters?.find((p) => p.name === 'viewer2D.position')?.value;
  const viewerSize = component.parameters?.find((p) => p.name === 'viewer2D.size')?.value;
  const viewerRotate = component.parameters?.find((p) => p.name === 'viewer2D.rotate')?.value;
  const viewerSource = component.parameters?.find((p) => p.name === 'viewer2D.source')?.value;
  const viewerDraggable = component.parameters?.find((p) => p.name === 'viewer2D.draggable')?.value;
  const viewerFlipX = component.parameters?.find((p) => p.name === 'viewer2D.flipX')?.value;
  const viewerFlipY = component.parameters?.find((p) => p.name === 'viewer2D.flipY')?.value;
  if (viewerDraggable) {
    componentToRead.draggable = viewerDraggable === 'true';
  }
  componentToRead.viewer2D = {
    x: viewerX ? Number(viewerX) : null,
    y: viewerY ? Number(viewerY) : null,
    position: viewerPosition ? Number(viewerPosition) : null,
    size: viewerSize ? Number(viewerSize) : null,
    rotate: viewerRotate ? Number(viewerRotate) : null,
    source: viewerSource ? viewerSource : null,
    flipX: viewerFlipX ? Boolean(viewerFlipX) : null,
    flipY: viewerFlipY ? Boolean(viewerFlipY) : null
  };
  return componentToRead;
};
export const convertForSaveComponent = (component: any) => {
  const parametersToSave = [] as Array<CustomComponentParameter>;
  if (component.viewer2D) {
    if (component.viewer2D.position) {
      // @ts-ignore
      parametersToSave.push({
        id: uuidv4(),
        name: 'viewer2D.position',
        type: 'number',
        value: component.viewer2D.position.toString(),
        complexParameters: []
      });
    }
    if (component.viewer2D.size) {
      // @ts-ignore
      parametersToSave.push({
        id: uuidv4(),
        name: 'viewer2D.size',
        type: 'number',
        value: component.viewer2D.size.toString(),
        complexParameters: []
      });
    }
    if (component.viewer2D.rotate) {
      // @ts-ignore
      parametersToSave.push({
        id: uuidv4(),
        name: 'viewer2D.rotate',
        type: 'number',
        value: component.viewer2D.rotate.toString(),
        complexParameters: []
      });
    }
    if (component.viewer2D.flipX) {
      // @ts-ignore
      parametersToSave.push({
        id: uuidv4(),
        name: 'viewer2D.flipX',
        type: 'boolean',
        value: component.viewer2D.flipX.toString(),
        complexParameters: []
      });
    }
    if (component.viewer2D.flipY) {
      // @ts-ignore
      parametersToSave.push({
        id: uuidv4(),
        name: 'viewer2D.flipY',
        type: 'boolean',
        value: component.viewer2D.flipY.toString(),
        complexParameters: []
      });
    }
  }
  const entries = Object.entries(component.data);
  entries.forEach(([key, value]: [key: string, value: any]) => {
    if (typeof value === 'object') {
      if (key === 'anchors' || key === 'samplingPorts' || key === 'instrumentationPorts') {
        const complexParameters = [] as Array<CustomComponentComplexParameter>;
        // @ts-ignore
        parametersToSave.push({
          id: uuidv4(),
          name: key,
          type: 'array',
          value: '',
          complexParameters: complexParameters
        });
        value.forEach((anchor: any) => {
          const anchorParameters = [
            {
              id: uuidv4(),
              name: 'id',
              type: 'string',
              value: anchor.id
            },
            {
              id: uuidv4(),
              name: 'viewer2D.top',
              type: 'number',
              value: anchor.viewer2D.top.toString()
            },
            {
              id: uuidv4(),
              name: 'viewer2D.left',
              type: 'number',
              value: anchor.viewer2D.left.toString()
            }
          ];
          const anchorEntries = Object.entries(anchor.data);
          anchorEntries.forEach(
            ([anchorKey, anchorValue]: [anchorKey: string, anchorValue: any]) => {
              if (typeof anchorValue === 'object') {
                if (anchorValue === null) {
                } else if (anchorKey === 'componentLink') {
                  anchorParameters.push({
                    id: uuidv4(),
                    name: 'componentLink.anchor',
                    type: 'string',
                    value: anchorValue.anchor
                  });
                  // @ts-ignore
                  anchorParameters.push({
                    id: uuidv4(),
                    name: 'componentLink.component',
                    type: 'string',
                    value: anchorValue.component
                  });
                } else if (anchorKey === 'componentLinkReference') {
                  anchorParameters.push({
                    id: uuidv4(),
                    name: 'componentLinkReference.anchor',
                    type: 'string',
                    value: anchorValue.anchor
                  });
                  // @ts-ignore
                  anchorParameters.push({
                    id: uuidv4(),
                    name: 'componentLinkReference.component',
                    type: 'string',
                    value: anchorValue.component
                  });
                }
              } else {
                anchorParameters.push({
                  id: uuidv4(),
                  name: anchorKey,
                  type: typeof anchorValue,
                  value: anchorValue
                });
              }
            }
          );
          const complexParam = {
            id: uuidv4(),
            name: anchor.data.name,
            type: anchor.type,
            parameters: anchorParameters as Array<AssemblyParameter>
          } as AssemblyComplexParameter;
          complexParameters.push(complexParam);
        });
      }
    } else if (key === 'assembly') {
      if (value.general) {
        // @ts-ignore
        parametersToSave.push({
          id: uuidv4(),
          name: 'assembly.general',
          type: 'object',
          value: JSON.stringify(value.general),
          complexParameters: []
        });
      }
    } else {
      // @ts-ignore
      parametersToSave.push({
        id: uuidv4(),
        name: key,
        type: typeof value,
        value: value.toString(),
        complexParameters: []
      });
    }
  });
  return {
    id: component.id,
    name: component.data.type,
    type: component.type,
    parameters: parametersToSave
  } as any;
};

export function sum(a, b) {
  return a + b;
}
