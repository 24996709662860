import { MenuItem } from "@mui/material";
import {
  ButtonFooter,
  Chip,
  Icon,
  IconTooltip,
  MetroChip,
  Select,
  TextArea,
  TextInput,
  ThinChip,
  Title,
  TitleWithIcon,
  Tooltip,
  UserBadge,
} from "hakobio-react-ui";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { CUSTOM_FUNCTIONS } from "../../../../constants/CUSTOM_FUNCTIONS";
import { ViewMode } from "../../../../constants/ViewMode";
import {
  configMessages,
  generalMessages,
  statusMessages,
} from "../../../../lang/messages";
import { validateAssemblyName } from "../../../../services/editor/editorService";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { assemblyEditorSliceActions } from "../../../../store/features/assemblyEditor/assemblyEditorSlice";
import { useAssemblyEditorAction } from "../../../../store/features/assemblyEditor/useAssemblyEditorAction";
import { getColors } from "../../../../utilities";
import SuaComponents from "./SuaComponents";
import {
  GenericStatus,
  genericStatusBackgroundColors,
  genericStatusColors,
} from "../../../../utilities/statuses/genericStatus";
import PrivacyModal from "./PrivacyModal";
import { GENERIC_STATUS_EXPLANATION } from "../../../../constants/statusExplanations/GENERIC_STATUS_EXPLANATION";
import { useStatusAction } from "../../../../store/features/assemblyEditor/useStatusAction";
import { useCustomBehavior } from "../../../../utils";
import { DirectoryFolder } from "../../../models/DirectoryFolder";

const availableFunctions: string[] = Object.values(CUSTOM_FUNCTIONS)
  .map((f: any) => f.name)
  .sort();

export const SingleUseGeneralPanel = (props: any) => {
  const { onGeneralEdited } = useAssemblyEditorAction();
  const { changeStatus } = useStatusAction();
  const dispatch = useAppDispatch();
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const components = useAppSelector(
    (state) => state.assemblyEditorSlice.components,
  );
  const viewMode = useAppSelector(
    (state) => state.assemblyEditorSlice.viewMode,
  );

  const [_infoEditing, setInfoEditing] = useState(false);
  const [_functionEditing, setFunctionEditing] = useState(false);
  const [_suaName, setSuaName] = useState(general.name);
  const [_suaDescription, setSuaDescription] = useState(general.description);
  const [_suaStatus, setStatus] = useState(general.status);
  const userList = useAppSelector(
    (state) => state.assemblyEditorSlice.userList,
  );
  const directoriesList = useAppSelector(
    (state) => state.assemblyEditorSlice.allDirectories,
  );
  const folder = directoriesList.find(
    (f: DirectoryFolder) => f.id === general.parentFolderId,
  );
  const [_nameNotValid, setNameNotValid] = useState(false);
  const [_privacyModal, setPrivacyModal] = useState(false);
  const [_statusToChange, setStatusToChange] = useState(null);
  const [_designOwners, setDesignOwners] = useState([]);

  const { openReactAppHistoryInNewTab } = useCustomBehavior();

  const intl = useIntl();

  const { secondaryColor, secondaryColorAlpha } = getColors();


  useEffect(() => {
    if (folder) {
      let designOwners = [];
      folder.designOwners?.forEach((designOwner) => {
        designOwners.push(userList.find((user) => user.userId === designOwner));
      });
      setDesignOwners(designOwners);
    }
  }, [directoriesList, userList, folder]);

  const handleEditName = (event) => {
    setSuaName(event.target.value);
    setNameNotValid(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") closeNameEdition();
  };

  const closeNameEdition = async () => {
    const isValid = await validateAssemblyName(
      general.id,
      general.type,
      _suaName,
    );
    if (isValid) {
      setNameNotValid(false);
      dispatch(assemblyEditorSliceActions.setIsSaveDisabled(false));
      setInfoEditing(false);
      onGeneralEdited("name", _suaName);
      onGeneralEdited("description", _suaDescription);
      onGeneralEdited("status", _suaStatus);
    } else {
      setNameNotValid(true);
      dispatch(assemblyEditorSliceActions.setIsSaveDisabled(true));
      toast.error(`'${_suaName}' already exists. Please enter another name.`);
    }
  };

  const redirectionReferenceEditor = async () => {
    localStorage.setItem("action", "assemblyReference");
    localStorage.setItem("actionData", JSON.stringify(general));
    localStorage.setItem(
      "duplicateGenericToReference",
      "duplicateAssemblyGenericToReference",
    );

    openReactAppHistoryInNewTab();
  };

  const renderName = (
    <div className="capitalize max-lines-1 font-regular">{general.name}</div>
  );

  const renderNameEdition = (
    <>
      {_infoEditing && general?.permissions?.update ? (
        <div className="f-row gap-3 f1-between f2-start py-2 w-100">
          <TextInput
            id="name"
            label={"Name"}
            inputSize={"small"}
            value={_suaName}
            error={_nameNotValid}
            helperText={
              _nameNotValid
                ? `'${_suaName}' already exists. Please enter another name.`
                : null
            }
            onChange={(event: any) => handleEditName(event)}
            onKeyDown={(e: any) => handleKeyPress(e)}
          />
        </div>
      ) : (
        <div className="capitalize py-2 max-lines-1">{general.name}</div>
      )}
    </>
  );

  const renderStatusesExplanation = () => {
    return (
      <div className="px-2" style={{ backgroundColor: "rgba(255,255,255,.6)" }}>
        <Title style={{ marginTop: -4 }}>Functional SUA Statuses</Title>
        <div style={{ marginLeft: "20px", paddingBottom: "5px" }}>
          {intl.formatMessage(statusMessages.statusChangedSUAS)}
        </div>
        {Object.entries(GENERIC_STATUS_EXPLANATION)?.map(
          (
            [key, value]: [
              key: string,
              value: {
                name: string;
                explanations: string[];
              },
            ],
            index,
          ) => {
            return (
              <div key={`${key}-${value}-${index}`}>
                <MetroChip
                  key={value.name}
                  label={value.name}
                  color={genericStatusColors(key)}
                  backgroundColor={genericStatusBackgroundColors(key)}
                />
                <ul
                  className="pl-4 py-2"
                  style={{
                    borderLeft:
                      key === GenericStatus.Freeze ||
                      key === GenericStatus.Obsolete
                        ? "none"
                        : "1px solid var(--grey)",
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 12,
                  }}
                >
                  {value.explanations.map((exp, index) => (
                    <li key={`${exp}-${index}`}>{exp}</li>
                  ))}
                </ul>
              </div>
            );
          },
        )}
      </div>
    );
  };

  const renderStatus = (
    <ThinChip
      className="f-shrink-0"
      fontSize="smallText"
      label={
        <span className="capitalize">{general.status || "No status"}</span>
      }
      color={
        general.status
          ? genericStatusColors(general.status)
          : genericStatusColors("No status")
      }
      backgroundColor={
        general.status
          ? genericStatusBackgroundColors(general.status)
          : genericStatusBackgroundColors("No status")
      }
    />
  );

  const renderStatusEdition = () => {
    let message = null;
    if (
      !general.permissions.changeStatus &&
      general.permissions.update &&
      general.status === GenericStatus.Draft &&
      general.newAssembly
    ) {
      message = intl.formatMessage(statusMessages.saveBeforeChange);
    } else if (
      !general.permissions.changeStatus &&
      general.permissions.update &&
      general.status === GenericStatus.Draft
    ) {
      message = intl.formatMessage(statusMessages.contactOwner);
    } else if (
      !general.permissions.changeStatus &&
      !general.permissions.update &&
      general.status === GenericStatus.Obsolete
    ) {
      message = intl.formatMessage(statusMessages.changeStatusNotAllowed);
    } else if (
      !general.permissions.changeStatus &&
      !general.permissions.update
    ) {
      message = intl.formatMessage(statusMessages.contactOwner);
    } else {
      //message if assembly is in an invalid state
      message = intl.formatMessage(statusMessages.contactOwner);
    }

    let tooltip = null;
    if (!general.permissions.changeStatus) {
      tooltip = (
        <div className="ml-2 f-shrink-0">
          <Tooltip title={message}>
            <Icon name={"about"} />
          </Tooltip>
        </div>
      );
    }

    const handleChangeStatus = (e) => {
      if (
        (!general.permissions.changeStatus &&
          general.permissions.update &&
          general.status === GenericStatus.Draft) ||
        (general.isPrivate &&
          general.permissions.changeStatus &&
          !general.status) ||
        (general.isPrivate &&
          general.permissions.changeStatus &&
          general.status === null) ||
        (general.isPrivate &&
          general.permissions.changeStatus &&
          general.status === GenericStatus.Draft)
      ) {
        setPrivacyModal(true);
        setStatusToChange(e.target.value);
      } else {
        setStatus(e.target.value);
      }
    };

    return (
      <div className="f-row f2-center f-shrink-0">
        <Select
          defaultValue={_suaStatus || "No Status"}
          className="mb-1"
          inputSize="small"
          label={intl.formatMessage(statusMessages.selectStatus)}
          value={_suaStatus || "No Status"}
          disabled={
            (!general.permissions.changeStatus &&
              general.status !== GenericStatus.Draft) ||
            general.newAssembly ||
            (!general.permissions.changeStatus && !general.isPrivate)
          }
          renderValue={(value: string) => (
            <ThinChip
              className="f-shrink-0"
              center
              fontSize="smallText"
              label={<span className="capitalize">{value}</span>}
              color={genericStatusColors(value)}
              backgroundColor={genericStatusBackgroundColors(value)}
            />
          )}
          onChange={handleChangeStatus}
        >
          <MenuItem
            key={"no-status"}
            className="capitalize frozen-grey"
            value={"No Status"}
          >
            No Status
          </MenuItem>
          {Object.entries(GenericStatus).map(
            (status: [string, string], index) => {
              return (
                <MenuItem
                  key={status[0] + index}
                  className="capitalize"
                  value={status[1]}
                  style={{ color: genericStatusColors(status[1]) }}
                >
                  {status[1]}
                </MenuItem>
              );
            },
          )}
        </Select>
        {tooltip}
        <IconTooltip
          name="question-mark"
          tooltipWidth={500}
          className="ml-2"
          title={renderStatusesExplanation()}
          placement="right-start"
        />
      </div>
    );
  };

  const editDescription = (
    <>
      {_infoEditing && general?.permissions?.update ? (
        <TextArea
          //@ts-ignore
          id="description"
          inputSize="medium"
          label="Description"
          rows={3}
          value={_suaDescription}
          onChange={(event: any) => setSuaDescription(event.target.value)}
        />
      ) : (
        <div className="mt-2">
          {general.description === "" ? (
            <span className="frozen-grey">
              {intl.formatMessage(generalMessages.noDescription)}
            </span>
          ) : (
            <div style={{ maxHeight: 144, overflow: "auto" }}>
              {general.description}
            </div>
          )}
        </div>
      )}
    </>
  );

  const designOwners = () => {
    return (
      <>
        <Title
          className="mb-0"
          children={intl.formatMessage({
            id: "SUConfigView.EditFolderModal.DesignOwners",
            defaultMessage: "Design Owners",
          })}
        ></Title>
        <div className="pt-1 f-row f2-center gap-2 f-wrap">
          {_designOwners.map((designOwner, index) => {
            return (
              <div key={`${designOwner}-${index}`}>
                <UserBadge
                  firstName={designOwner.firstname}
                  lastName={designOwner.lastname}
                  size={_designOwners.length > 1 ? "small" : "medium"}
                  title={
                    <div className="f-col">
                      <div className="font-regular">
                        {designOwner.firstname} {designOwner.lastname}
                      </div>
                      {designOwner.email}
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const editFunction = () => {
    const assignedFunctions = [...general.functions];
    assignedFunctions.sort();
    return (
      <>
        {general?.permissions?.update && viewMode === ViewMode.Editor ? (
          <TitleWithIcon
            className="mb-0"
            name="add-3"
            onClick={() => setFunctionEditing(!_functionEditing)}
          >
            {intl.formatMessage(configMessages.assignedFunctions)}
          </TitleWithIcon>
        ) : (
          <Title className="mb-0">
            {intl.formatMessage(configMessages.assignedFunctions)}
          </Title>
        )}
        {_functionEditing && (
          <Select
            className="mb-3"
            inputSize="small"
            label={"Select function"}
            value={""}
            onChange={(e) => {
              const newFunction = e.target.value;
              onGeneralEdited("functions", [
                ...general.functions,
                newFunction.toLowerCase(),
              ]);
            }}
          >
            {availableFunctions
              .filter((aF) => !general.functions.includes(aF.toLowerCase()))
              .filter(
                (fc) =>
                  fc.toLowerCase() !== "waste" &&
                  fc.toLowerCase() !==
                    "utilities" /* fc.toLowerCase() !== 'mural transferring' &&  // voir avec Emilie car le client voudrait remettre cette fonction */ &&
                  fc.toLowerCase() !== "purification" &&
                  fc.toLocaleLowerCase() !== "transferring",
              )
              .map((e: any, index: number) => (
                <MenuItem key={e + index} value={e.toLowerCase()}>
                  {e}
                </MenuItem>
              ))}
          </Select>
        )}

        <div
          className=""
          style={{
            overflow: "auto",
            maxHeight: _functionEditing ? "none" : 96,
          }}
        >
          {assignedFunctions.length ? (
            assignedFunctions.map((f) => {
              const customFunction: any = Object.values(CUSTOM_FUNCTIONS).find(
                (cf: any) => cf.name.toLowerCase() === f,
              );
              return (
                <div
                  className="f-row f2-center icon-parent gap-2 mb-2 w-100"
                  key={f}
                >
                  <div style={{ height: 20 }}>{customFunction?.form(20)}</div>
                  <ThinChip
                    className="w-100"
                    label={customFunction?.name}
                    color={secondaryColor}
                    backgroundColor={secondaryColorAlpha}
                  />
                  {_functionEditing && (
                    <Icon
                      className="icon-hover p-1 pr-2"
                      onClick={() =>
                        onGeneralEdited(
                          "functions",
                          general.functions.filter((e) => e !== f),
                        )
                      }
                      name="bin"
                    />
                  )}
                </div>
              );
            })
          ) : (
            <span className="frozen-grey">
              No function has been assigned for this assembly
            </span>
          )}
        </div>
      </>
    );
  };

  const handleConfirm = async () => {
    await changeStatus(general.id, _statusToChange);
    setStatus(_statusToChange);
    setPrivacyModal(false);
  };

  const closeModal = () => {
    setPrivacyModal(false);
  };

  return (
    <div className="f-full f-col">
      {_privacyModal && (
        <PrivacyModal
          handleConfirm={handleConfirm}
          closeModal={closeModal}
          statusToChange={_statusToChange}
        />
      )}
      <div className="mb-2 f-full f-col px-2">
        <div className="f-col mb-3">
          {_infoEditing ? (
            <div className="f-col f-full">
              <div className="f-row f1-between f2-center w-100">
                {renderNameEdition}
                <Icon
                  name={"chat"}
                  onClick={closeNameEdition}
                  className="ml-2"
                  color="var(--dark-green)"
                />
              </div>
              {renderStatusEdition()}
            </div>
          ) : (
            <div className="w-100">
              <div
                className={`f-row f1-between f2-center gap-2 container-action mt-2 `}
              >
                {renderName}
                <div className="f-row f1-between f2-center f-shrink-0">
                  {renderStatus}
                  {(general?.permissions?.update ||
                    general?.permissions?.changeStatus) && (
                    <Icon
                      className="items-action-none ml-2"
                      name="pencil"
                      onClick={() => setInfoEditing(true)}
                    />
                  )}
                </div>
              </div>
              {folder && (
                <div className="f-row py-2 ">
                  <div className={`f-row f2-center gap-2`}>
                    <Tooltip title={"Directory"}>
                      <Icon name="folder" />
                    </Tooltip>
                    {folder?.name}
                  </div>
                </div>
              )}
              <div className="w-10 border-bottom mb-2" />
            </div>
          )}

          {editDescription}
        </div>
        {/* {!_infoEditing && <div className="w-10 border-bottom" />} */}
        {folder && designOwners()}
        {editFunction()}
        <SuaComponents components={components} />
      </div>
      {general?.permissions?.createReference && (
        <ButtonFooter onClick={redirectionReferenceEditor}>
          <div className="f-row  f2-center gap-2">
            <Icon name="label" />
            {intl.formatMessage(generalMessages.createReference)}
          </div>
        </ButtonFooter>
      )}
    </div>
  );
};
