import { useState } from 'react';
import { useIntl } from 'react-intl';
import { EditorMode } from '../../../../constants/EditorMode';
import { VerticalTabsTitle } from '../../../../constants/LeftPanel_VerticalTabs';
import { ViewMode } from '../../../../constants/ViewMode';
import { generalMessages } from '../../../../lang/messages';
import { useAppSelector } from '../../../../store';
import VerticalTabsItem from './VerticalTabsItem';

interface Props {
  isLeftPanelOpen: boolean;
  libraryPanelState: string;
  setLibraryPanelState: Function;
}

const VerticalTabs: React.FC<Props> = ({
  isLeftPanelOpen,
  libraryPanelState,
  setLibraryPanelState
}) => {
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);

  const [_isButtonExtended, setButtonExtended] = useState(false);

  const intl = useIntl();

  const renderLibraryButton = () => {
    return (
      <VerticalTabsItem
        libraryPanelState={libraryPanelState}
        buttonPanelState={VerticalTabsTitle.Library}
        isButtonExtended={_isButtonExtended}
        setLibraryPanelState={setLibraryPanelState}
        text={intl.formatMessage(generalMessages.library)}
        icon={'lego-blocks'}
      />
    );
  };

  const renderCompareVersionButton = () => {
    return (
      <VerticalTabsItem
        libraryPanelState={libraryPanelState}
        buttonPanelState={VerticalTabsTitle.Versions}
        isButtonExtended={_isButtonExtended}
        setLibraryPanelState={setLibraryPanelState}
        text={intl.formatMessage(generalMessages.compareVersions)}
        icon={'transfer'}
      />
    );
  };

  const renderMetricsButton = () => {
    return (
      <VerticalTabsItem
        libraryPanelState={libraryPanelState}
        buttonPanelState={VerticalTabsTitle.Metrics}
        isButtonExtended={_isButtonExtended}
        setLibraryPanelState={setLibraryPanelState}
        text={intl.formatMessage(generalMessages.metrics)}
        icon={'statistics'}
      />
    );
  };

  const renderReportButton = () => {
    return (
      <VerticalTabsItem
        libraryPanelState={libraryPanelState}
        buttonPanelState={VerticalTabsTitle.Report}
        isButtonExtended={_isButtonExtended}
        setLibraryPanelState={setLibraryPanelState}
        text={intl.formatMessage(generalMessages.report)}
        icon={'file'}
      />
    );
  };

  const renderReferenceButton = () => {
    return (
      <VerticalTabsItem
        libraryPanelState={libraryPanelState}
        buttonPanelState={VerticalTabsTitle.References}
        isButtonExtended={_isButtonExtended}
        setLibraryPanelState={setLibraryPanelState}
        text={intl.formatMessage(generalMessages.references)}
        icon={'label'}
      />
    );
  };


  return (
    <div
      onMouseEnter={() => setButtonExtended(true)}
      onMouseLeave={() => setButtonExtended(false)}
      style={{
        position: 'absolute',
        top: 39,
        zIndex: 100,
        left: isLeftPanelOpen
          ? libraryPanelState
            ? '799px'
            : '399px'
          : libraryPanelState
          ? '420px'
          : '20px'
      }}>
      {general?.permissions?.update && viewMode === ViewMode.Editor && renderLibraryButton()}
      {viewMode === ViewMode.History && renderCompareVersionButton()}
      {editorMode === EditorMode.SUA && renderReferenceButton()}
      {editorMode === EditorMode.UnitOperation && renderMetricsButton()}
      {editorMode !== EditorMode.Reference && renderReportButton()}
    </div>
  );
};

export default VerticalTabs;
