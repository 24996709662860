import { CSSProperties } from "react";

export const buttonSaveStyle: CSSProperties = {
  zIndex: 1,
  position: "relative",
  minWidth: 120
};
export const selectedTabStyle: CSSProperties = {
  backgroundColor: "white",
  color: "var(--primaryColor)",
};
export const selectedMenuStyle: CSSProperties = {
  color: "var(--primaryColor)",
  borderBottom: "solid 2px var(--primaryColor)",
};
export const unSelectedMenuStyle: CSSProperties = {
  color: "black",
  borderBottom: "solid 2px var(--frozen-grey)",
};

export const disabledMenuStyle: CSSProperties = {
  color: "var(--frozen-grey)",
  borderBottom: "solid 2px var(--frozen-grey)",
  pointerEvents: "none",
  cursor: "none",
};
export const leftPanelStyle: CSSProperties = {
  width: 400,
  maxWidth: 400,

  height: "100%",
  overflow: "hidden",
};
