import { Empty, SplashScreen } from "hakobio-react-ui";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { ListTab } from "../../constants/ListTab";
import {
  deleteAssembly,
  duplicateAssembly,
  duplicateReferenceAssembly,
  getAllAssemblies,
  getAllDirectories,
} from "../../services/editor/editorService";
import { shareAssembly } from "../../services/sharing/shareService";
import { useAppDispatch, useAppSelector } from "../../store";
import { assemblyEditorSliceActions } from "../../store/features/assemblyEditor/assemblyEditorSlice";
import { useEditorLibraryAction } from "../../store/features/assemblyEditor/useEditorLibraryAction";
import DeleteModal from "../../views/DeleteModal";
import ShareModal from "../../views/ShareModal";
import HistoryModal from "../History/HistoryModal";
import { advancedSearch } from "../Shared/AdvancedSearch/utilities/search";
import Navigation from "../Shared/Navigation/Navigation";
import GalleryView from "./GalleryView";
import ListView from "./ListView";
import AddEditFolderModal from "../../views/AddEditFolderModal";
import DuplicateSuaModal from "../../views/DuplicateSuaModal";
import { orderBy } from "lodash";
import CreateShortcutModal from "../../views/CreateShortcutModal";
import DeleteShortcutModal from "../../views/DeleteShortcutModal";

interface AssembyContainerProps {
  viewType: number;
  search?: string | any;
}

const AssemblyContainer: FC<AssembyContainerProps> = ({ viewType, search }) => {
  const dispatch = useAppDispatch();
  const { getEditorAssemblyLibrary } = useEditorLibraryAction();
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const assemblyListModified = useAppSelector(
    (state) => state.assemblyEditorSlice.assemblyListModified,
  );

  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary,
  );
  const selectedFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedFolder,
  );
  const directoriesList = useAppSelector(
    (state) => state.assemblyEditorSlice.allDirectories,
  );

  const showDeleteModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showDeleteModal,
  );
  const showShareModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showShareModal,
  );
  const showHistoryModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showHistoryModal,
  );
  const isEditingFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.isEditingFolder,
  );
  const assemblyToDuplicate = useAppSelector(
    (state) => state.assemblyEditorSlice.assemblyToDuplicate,
  );

  const createShortcut = useAppSelector(
    (state) => state.assemblyEditorSlice.showShortcutModal,
  );

  const deleteShortcut = useAppSelector(
    (state) => state.assemblyEditorSlice.showConfirmDeleteShortcutModal
  );
  const [_assemblyList, setAssemblyList] = useState(null) as any;
  const [_moduleList, setModuleList] = useState(null) as any;
  const [_listBeingModified, setListBeingModified] = useState(false);
  const [_isLoading, setIsLoading] = useState(false);
  const [_isDuplicate, setIsDuplicate] = useState(false);
  const [_directoryIdToDuplicate, setDirectoryIdToDuplicate] = useState(null);
  const intl = useIntl();

  const sortByName = (a: any, b: any) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
      return 0;
    } else {
      return -1;
    }
  };

  useEffect(() => {
    const allAssemblies = async () => {
      const assemblies = await getAllAssemblies();
      setAssemblyList(
        assemblies
          .filter(
            (a: any) =>
              a.type === "SingleUseAssembly" ||
              a.type === "SingleUseAssemblyReference",
          )
          .sort(sortByName),
      );
      dispatch(assemblyEditorSliceActions.setAllHeadersLibrary(assemblies));
      setModuleList(
        assemblies
          .filter((a: any) => a.type === "UnitOperation")
          .sort(sortByName),
      );
      void getEditorAssemblyLibrary();
    };
    void allAssemblies();
  }, []);

  useEffect(() => {
    if (assemblyListModified) {
      setListBeingModified(true);
      const allAssemblies = async () => {
        const assemblies = await getAllAssemblies();
        setAssemblyList(
          assemblies
            .filter(
              (a: any) =>
                a.type === "SingleUseAssembly" ||
                a.type === "SingleUseAssemblyReference",
            )
            .sort(sortByName),
        );
        dispatch(assemblyEditorSliceActions.setAllHeadersLibrary(assemblies));
        setModuleList(
          assemblies
            .filter((a: any) => a.type === "UnitOperation")
            .sort(sortByName),
        );
        void getEditorAssemblyLibrary();
        dispatch(assemblyEditorSliceActions.setAssemblyListModified(false));
      };
      void allAssemblies();
    }
  }, [assemblyListModified]);

  useEffect(() => {
    if (_listBeingModified && !assemblyListModified) {
      setIsLoading(false);
      dispatch(assemblyEditorSliceActions.setAssemblyDuplicate(null));
      dispatch(assemblyEditorSliceActions.setShowDeleteModal(null));
      dispatch(assemblyEditorSliceActions.setShowDeleteConfirmModal(null));
      _isDuplicate
        ? showDuplicateConfirmation(_directoryIdToDuplicate)
        : showDeleteConfirmation();
    }
  }, [assemblyListModified]);

  const renderDeleteModal = () => {
    return (
      <DeleteModal removeAssembly={removeAssembly} onRemoveComponents={null} />
    );
  };

  const renderEditFolderModal = () => {
    return <AddEditFolderModal />;
  };
  const renderCreateShortcutModal = () => {
    return <CreateShortcutModal />;
  };

  const renderDuplicateSuaModal = () => {
    return (
      <DuplicateSuaModal
        duplicateAssembly={onDuplicateAssembly}
        isLoading={_isLoading}
      />
    );
  };

  const renderDeleteShortcutModal = () => {
    return <DeleteShortcutModal />;
  };

  const renderShareModal = () => {
    let assemblySelected;
    switch (listTab) {
      case ListTab.SUA:
        assemblySelected = _assemblyList?.find(
          (a) => a.id === showShareModal?.assemblyId,
        );
        break;
      case ListTab.UnitOperation:
        assemblySelected = _moduleList?.find(
          (m) => m.id === showShareModal?.assemblyId,
        );
        break;
    }

    return (
      <ShareModal
        shareAssembly={shareAssemblyFunct}
        assembly={assemblySelected}
      />
    );
  };

  const renderHistoryModal = () => {
    return <HistoryModal />;
  };

  const onDuplicateAssembly = async (directory) => {
    if (directory.id !== null) {
      setIsLoading(true);
      setIsDuplicate(true);
      setDirectoryIdToDuplicate(directory.id);
      if (assemblyToDuplicate.type === "SingleUseAssemblyReference") {
        await duplicateReferenceAssembly(assemblyToDuplicate.id, directory.id);
      } else {
        await duplicateAssembly(assemblyToDuplicate.id, directory.id);
      }
      const allDirectories = async () => {
        const directories = await getAllDirectories();
        if (directories != null) {
          dispatch(
            assemblyEditorSliceActions.setAllDirectories(
              orderBy(directories, (r) => r.name.toLowerCase(), ["asc"]),
            ),
          );
          dispatch(
            assemblyEditorSliceActions.selectFolder(
              directories.find((d) => d.id === directory.id),
            ),
          );
          dispatch(assemblyEditorSliceActions.setAssemblyListModified(true));
        }
      };
      void allDirectories();
    }
  };

  const showDuplicateConfirmation = (directoryId) => {
    if (directoryId !== null) {
      switch (listTab) {
        case ListTab.SUA:
          toast.success(
            <FormattedMessage
              id="SUConfig.StartupPanel.Toast.AssemblyDuplicated"
              defaultMessage="Assembly duplicated"
            />,
          );
          break;
        case ListTab.UnitOperation:
          toast.success(
            <FormattedMessage
              id="SUConfig.StartupPanel.Toast.UnitOperationDuplicated"
              defaultMessage="Unit Operation duplicated"
            />,
          );
          break;
      }
    } else {
      toast.error(
        <FormattedMessage
          id="SUConfig.StartupPanel.Toast.NoDirectory"
          defaultMessage="No directory selected! Please select a directory to duplicate your assembly."
        />,
      );
    }
  };

  const showDeleteConfirmation = () => {
    switch (listTab) {
      case ListTab.SUA:
        toast.success(
          <FormattedMessage
            id="SUConfig.StartupPanel.Toast.AssemblyDeleted"
            defaultMessage="Assembly deleted"
          />,
        );
        break;
      case ListTab.UnitOperation:
        toast.success(
          <FormattedMessage
            id="SUConfig.StartupPanel.Toast.UnitOperationDeleted"
            defaultMessage="Unit Operation deleted"
          />,
        );
        break;
    }
  };

  const removeAssembly = async () => {
    try {
      setIsDuplicate(false);
      await deleteAssembly(showDeleteModal?.assemblyId);

      const deleteDirectory = directoriesList.find(
        (d) => d.id === selectedFolder?.id,
      );
      if (deleteDirectory) {
        const newChildren = deleteDirectory.children.filter(
          (ch) => ch !== showDeleteModal?.assemblyId,
        );
        const updatedSelectedFolder = {
          ...deleteDirectory,
          children: newChildren,
        };

        dispatch(
          assemblyEditorSliceActions.selectFolder(updatedSelectedFolder),
        );

        const updatedDirectoriesList = directoriesList.map((dir) =>
          dir.id === deleteDirectory.id ? updatedSelectedFolder : dir,
        );
        dispatch(
          assemblyEditorSliceActions.setAllDirectories(updatedDirectoriesList),
        );
      }

      dispatch(assemblyEditorSliceActions.setAssemblyListModified(true));
    } catch (e) {
      toast.error(
        <FormattedMessage
          id="SUConfig.StartupPanel.Toast.AssemblyDeleted.Error"
          defaultMessage="Error deleting assembly"
        />,
      );
    }
  };

  const shareAssemblyFunct = async (shareable: any) => {
    const result = await shareAssembly(shareable);
    dispatch(assemblyEditorSliceActions.setAssemblyListModified(true));
    if (result) {
      toast.success(
        <FormattedMessage
          id="SUConfig.StartupPanel.Toast.AccessRightsSuccessfullyChanged"
          defaultMessage="Access rights successfully changed"
        />,
      );
    } else {
      toast.error(
        <FormattedMessage
          id="SUConfig.StartupPanel.Toast.AProblemOccuredWhileChangingAccessRights"
          defaultMessage="A problem occured while changing access rights"
        />,
      );
    }
  };

  const searchList = () => {
    let assemblies = _assemblyList;
    let modules = _moduleList;

    if (selectedFolder) {
      const suas = _assemblyList
        .filter((a: any) => selectedFolder?.children.includes(a.id))
        .map((a: any) => ({
          ...a,
          isShortCut: false,
        }));

      const shortcuts = _assemblyList
        .filter((a: any) => selectedFolder?.shortCuts.includes(a.id))
        .map((a: any) => ({
          ...a,
          isShortCut: true,
        }));
      assemblies = [...suas, ...shortcuts];

      const uos = _moduleList
        .filter((a: any) => selectedFolder?.children.includes(a.id))
        .map((a: any) => ({
          ...a,
          isShortCut: false,
        }));
      const shortcutsUo = _moduleList
        .filter((a: any) => selectedFolder?.shortCuts.includes(a.id))
        .map((a: any) => ({
          ...a,
          isShortCut: true,
        }));
      modules = [...uos, ...shortcutsUo];
    }

    if (search) {
      if (typeof search === "string") {
        if (assemblies && listTab === ListTab.SUA) {
          return assemblies.filter((a: any) =>
            a.name.toLowerCase().includes(search.toLowerCase()),
          );
        }
        if (modules && listTab === ListTab.UnitOperation) {
          return modules.filter((m: any) =>
            m.name.toLowerCase().includes(search.toLowerCase()),
          );
        }
      } else {
        const advancedSearchIds = advancedSearch(
          editorAssemblyLibrary,
          search,
        ).map((a) => a.general.id);
        return assemblies.filter((a) => advancedSearchIds.includes(a.id));
      }
    } else if (listTab === ListTab.SUA) {
      return assemblies;
    } else if (listTab === ListTab.UnitOperation) {
      return modules;
    } else {
      return null;
    }
  };

  if (!_assemblyList || !_moduleList) {
    return (
      <SplashScreen
        text={intl.formatMessage({
          id: "Builder.Common.LoadingAssemblies",
          defaultMessage: "Loading Assemblies",
        })}
      />
      // <MovingCubesLoader text="Loading Assemblies" fontSize="small-text" />
    );
  }

  return (
    <div className="f-row f-full pb-4">
      <div className="f-col relative left-panel-sm border-right pr-2 py-2">
        <Navigation />
      </div>
      <div className="f-full">
        {viewType === 2 &&
          (_assemblyList ? (
            <>
              <div>{showDeleteModal && renderDeleteModal()}</div>
              <div>{showShareModal && renderShareModal()}</div>
              <div>{showHistoryModal && renderHistoryModal()}</div>
              <div>{isEditingFolder && renderEditFolderModal()}</div>
              <div>{assemblyToDuplicate && renderDuplicateSuaModal()}</div>
              <div>{createShortcut && renderCreateShortcutModal()}</div>
              <div>{deleteShortcut && renderDeleteShortcutModal()}</div>
              <GalleryView list={searchList()} />
            </>
          ) : (
            <Empty />
          ))}

        {viewType === 3 &&
          (_assemblyList ? (
            <div>
              <div>{showDeleteModal && renderDeleteModal()}</div>
              <div>{showShareModal && renderShareModal()}</div>
              <div>{showHistoryModal && renderHistoryModal()}</div>
              <div>{isEditingFolder && renderEditFolderModal()}</div>
              <div>{assemblyToDuplicate && renderDuplicateSuaModal()}</div>
              <div>{createShortcut && renderCreateShortcutModal()}</div>
              <div>{deleteShortcut && renderDeleteShortcutModal()}</div>
              <ListView list={searchList()} />
            </div>
          ) : (
            <Empty />
          ))}
      </div>
    </div>
  );
};

export default AssemblyContainer;
