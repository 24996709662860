import React, { useEffect, useState } from 'react';

import sanofiLogo from '../../../constants/logos/sanofi.png';
import { useAppSelector } from '../../../store';

interface Props {
  assemblyGeneral: any;
  exportDate: string;
  pageNumber: number;
  totalPageNumber: number;
}

const FrontPage = ({ assemblyGeneral, exportDate, pageNumber, totalPageNumber }: Props) => {
  const theme = useAppSelector((state) => state.assemblyEditorSlice.theme);

  return (
    <div
      className="py-3 mt-3"
      style={{
        breakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always',
        pageBreakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always',
        width: '100%',
        height: '100%',
        padding: '0 2rem',
        display: 'block',
        position: 'relative'
      }}>
      <div className="f-center" style={{ height: 700 }}>
        <div className="f-col f2-center">
          <img
            alt="PFD view"
            style={{
              objectFit: 'contain',
              height: 100,
              width: 'auto'
            }}
            src={theme.logo}
          />

          <div className="mt-5 mb-1">{exportDate}</div>
          <div className="font-regular" style={{ fontSize: 30 }}>
            {assemblyGeneral.name}
          </div>
          <div
            className="mt-3 mb-4"
            style={{ borderBottom: `1px solid var(--dark-grey)`, width: 100 }}
          />
          <div className="">{assemblyGeneral.description}</div>
        </div>
      </div>
      <div className="f-row f1-end gap-2 mt-2" style={{ fontSize: 13 }}>
        <div>{`Page ${pageNumber}/${totalPageNumber}`}</div>
      </div>
    </div>
  );
};

export default FrontPage;
