import _ from 'lodash';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { RuleTubingComponentOnEdgeDiameter } from './definition/RuleEdgeComponentOnEdge/RuleTubingComponentOnEdgeDiameter';
import { RulePlugTubingWeldable } from './definition/RuleComponentEdgeComponent/RulePlugTubingWeldable';
import { RuleComponentDiameterTubingDiameter } from './definition/RuleComponentEdgeComponent/RuleComponentDiameterTubingDiameter';
import { RuleAsepticConnectorTubingWeldable } from './definition/RuleComponentEdgeComponent/RuleAsepticConnectorTubingWeldable';
import { RuleComponentOrphanAnchors } from './definition/RuleComponent/RuleComponentOrphanAnchors';
import { RuleTubeDiameterPlugFunctionPlugTubeDiameter } from './definition/RuleComponentEdgeComponent/RuleTubeDiameterPlugFunctionPlugTubeDiameter';
import { RuleComponentDiameterFunctionComponentDiameter } from './definition/RuleComponentEdgeComponent/RuleComponentDiameterFunctionComponentDiameter';
import { RuleComponentModelFunctionEdgeComponentModel } from './definition/RuleComponentEdgeComponent/RuleComponentModelFunctionEdgeComponentModel';
import { RuleComponentInterfaceSizeFunctionEdgeComponentInterfaceSize } from './definition/RuleComponentEdgeComponent/RuleComponentInterfaceSizeFunctionEdgeComponentInterfaceSize';
import { RuleComponentInterfaceGenderFunctionEdgeComponentInterfaceGender } from './definition/RuleComponentEdgeComponent/RuleComponentInterfaceGenderFunctionEdgeComponentInterfaceGender';
import { RuleConnectorFunctionEdgeUtilityWaste } from './definition/RuleComponentEdgeComponent/RuleConnectorFunctionEdgeUtilityWaste';
import { RulePlugFunctionPlug } from './definition/RuleComponentEdgeComponent/RulePlugFunctionPlug';
import { RuleAsepticConnectorFunctionAsepticConnector } from './definition/RuleComponentEdgeComponent/RuleAsepticConnectorFunctionAsepticConnector';
import { CUSTOM_NODES } from '../../constants/CUSTOM_NODES';
import { Button, SmallButton } from 'hakobio-react-ui';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

export const checkPIDEditor = (components: any, assemblyLibrary: any, functionList: any = {}) => {
  const componentsCopy = _.cloneDeep(components);
  const failedRules: any[] = [];
  checkUpdatedSUA(componentsCopy, assemblyLibrary, failedRules, functionList);
  componentsCopy.forEach((component: any) => {
    switch (component.type) {
      case 'genericedge':
        checkGenericEdge(component, componentsCopy, failedRules);
        break;
      case 'functionedge':
        checkFunctionEdge(component, componentsCopy, failedRules);
        break;
      // case 'generic':
      //   checkGeneric(component, componentsCopy, failedRules);
      //   break;
      default:
        break;
    }
  });

  return failedRules;
};

const checkGeneric = (component, components, failedRules) => {
  Array.prototype.push.apply(
    failedRules,
    new RuleComponentOrphanAnchors(component, components).handle()
  );
};

const checkGenericEdge = (component, components, failedRules) => {
  Array.prototype.push.apply(
    failedRules,
    new RuleTubingComponentOnEdgeDiameter(component, components).handle()
  );
  Array.prototype.push.apply(
    failedRules,
    new RulePlugTubingWeldable(component, components).handle()
  );
  Array.prototype.push.apply(
    failedRules,
    new RuleComponentDiameterTubingDiameter(component, components).handle()
  );
  Array.prototype.push.apply(
    failedRules,
    new RuleAsepticConnectorTubingWeldable(component, components).handle()
  );
};

const checkFunctionEdge = (component, components, failedRules) => {
  Array.prototype.push.apply(
    failedRules,
    new RuleTubeDiameterPlugFunctionPlugTubeDiameter(component, components).handle()
  );
  Array.prototype.push.apply(
    failedRules,
    new RuleComponentDiameterFunctionComponentDiameter(component, components).handle()
  );
  Array.prototype.push.apply(
    failedRules,
    new RuleComponentModelFunctionEdgeComponentModel(component, components).handle()
  );
  Array.prototype.push.apply(
    failedRules,
    new RuleComponentInterfaceSizeFunctionEdgeComponentInterfaceSize(component, components).handle()
  );
  Array.prototype.push.apply(
    failedRules,
    new RuleComponentInterfaceGenderFunctionEdgeComponentInterfaceGender(
      component,
      components
    ).handle()
  );
  Array.prototype.push.apply(
    failedRules,
    new RuleConnectorFunctionEdgeUtilityWaste(component, components).handle()
  );
  Array.prototype.push.apply(failedRules, new RulePlugFunctionPlug(component, components).handle());
  Array.prototype.push.apply(
    failedRules,
    new RuleAsepticConnectorFunctionAsepticConnector(component, components).handle()
  );
};

const checkUpdatedSUA = (components, assemblyLibrary, failedRules, functionList: any) => {
  // get the assemblies in the UO editor
  const functionAssemblyList = components.filter((c) => c.type === 'function' && c.data.assembly);

  // check in the library if there are SUAs with the modifiedOn date more recent than the Unit Operation one
  const updatedSUAList = [];

  functionAssemblyList.forEach((functionAssembly) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };
    // @ts-ignore
    const functionDate = new Date(
      functionAssembly.data.assembly.general.modifiedOn
      // @ts-ignore
    ).toLocaleDateString(undefined, options);
    const assembly = assemblyLibrary.find(
      (a) => functionAssembly.data.assembly.general.id === a.general.id
    );
    if (!assembly) {
      return;
    }
    // @ts-ignore
    const assemblyDate = new Date(assembly.general.modifiedOn).toLocaleDateString(
      undefined,
      // @ts-ignore
      options
    );
    const functionVersion = functionAssembly.data.assembly.general.version;
    const assemblyVersion = assembly.general.version;
    if (functionDate !== assemblyDate || functionVersion !== assemblyVersion) {
      updatedSUAList.push({ assembly, component: functionAssembly });
    }
  });

  if (updatedSUAList.length > 0) {
    failedRules.push({
      id: uuidv4(),
      name: 'Outdated SUA',
      errors: (
        <div>
          {/* <div>{updatedSUAList.length} {updatedSUAList.length === 1 ? "assembly" : "assemblies"} can be updated</div> */}
          <div>
            {' '}
            <FormattedMessage
              id="UOEditorReportPanel.OneOrSeveralUpdate"
              defaultMessage="One or several SUA's used in this unit operation have been updated"
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row-reverse',
              marginTop: '5px'
            }}>
            <SmallButton inverse onClick={functionList.setIsSUAUpdating} width="auto">
              {`Update`}
            </SmallButton>
          </div>
        </div>
      ),
      componentAnchors: []
    });
  }
};
