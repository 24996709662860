import { useState } from 'react';
import { leftPanelStyle } from './style/styles';
import { useAppSelector } from '../../store';
import { EditorMode } from '../../constants/EditorMode';
import { withRouter } from 'react-router-dom';
import './style/LeftPanel.css';
import SingleUseEditorLibraryPanel from './SingleUseEditor/LibraryPanel/SingleUseEditorLibraryPanel';
import SingleUseEditorLeftPanel from './SingleUseEditor/LeftPanel/SingleUseEditorLeftPanel';
import UnitOperationEditorLeftPanel from './UnitOperationEditor/LeftPanel/UnitOperationEditorLeftPanel';
import UnitOperationEditorLibraryPanel from './UnitOperationEditor/LibraryPanel/UnitOperationEditorLibraryPanel';
import MinimizedLeftPanel from './Shared/MinimizedLeftPanel/MinimizedLeftPanel';
import VerticalTabs from './Shared/VerticalTabs/VerticalTabs';
import UnitOperationEditorMetricsPanel from './UnitOperationEditor/MetricsPanel/UnitOperationEditorMetricsPanel';
import HistoryComparisonPanel from '../History/HistoryComparisonPanel';
import UnitOperationEditorReportPanel from './UnitOperationEditor/ReportPanel/UnitOperationEditorReportPanel';
import { VerticalTabsTitle } from '../../constants/LeftPanel_VerticalTabs';
import SingleUseEditorReportPanel from './SingleUseEditor/ReportPanel/SingleuseEditorReportPanel';
import ComponentEditorLeftPanel from './ComponentEditor/LeftPanel/ComponentEditorLeftPanel';
import SingleUseEditorReferencesPanel from './SingleUseEditor/ReferencePanel/SingleUseEditorReferencesPanel';
import { Empty } from 'hakobio-react-ui';
import { UnitOperationLayer } from '../../constants/PFD_EquipmentTabs';

const LeftPanel = () => {
  const [_isLeftPanelOpen, setIsLeftPanelOpen] = useState(true);
  const [_extendablePanelState, setExtendablePanelState] = useState('');

  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );

  const selectedComponentId = selectedComponents && selectedComponents[0];

  const general = useAppSelector((state) => state.assemblyEditorSlice.general);

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);

  let selectedComponent = components.find(
    (c: any) => c.id === selectedComponentId && c.type !== 'ghost'
  );

  if (selectedComponent && selectedComponent.type === 'update') {
    selectedComponent = components.find((c: any) => {
      return selectedComponent?.data.component === c.id;
    });
  }

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);

  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);

  const customComponentEdition = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentEdition
  );

  const renderVerticalTabs = () => {
    return (
      <VerticalTabs
        isLeftPanelOpen={_isLeftPanelOpen}
        libraryPanelState={_extendablePanelState}
        setLibraryPanelState={(value) => setExtendablePanelState(value)}
      />
    );
  };

  const renderSmallInfoPanel = () => {
    return <MinimizedLeftPanel onOpenLeftPanel={() => setIsLeftPanelOpen(true)} />;
  };

  const renderSUAEditorLeftPanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <SingleUseEditorLeftPanel
          onCloseLeftPanel={() => setIsLeftPanelOpen(false)}
          selectedComponent={selectedComponent}
        />
      </aside>
    );
  };

  const renderSUAEditorLibraryPanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <SingleUseEditorLibraryPanel />
      </aside>
    );
  };

  const renderSUAEditorReportPanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <SingleUseEditorReportPanel />
      </aside>
    );
  };

  const renderSUAHistoryPanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <HistoryComparisonPanel />
      </aside>
    );
  };

  const renderComponentEditorLeftPanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <ComponentEditorLeftPanel
          components={components}
          onCloseLeftPanel={() => setIsLeftPanelOpen(false)}
          selectedComponent={customComponentEdition}
        />
      </aside>
    );
  };

  const renderReferencePanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <SingleUseEditorReferencesPanel
          components={components}
          onCloseLeftPanel={() => setIsLeftPanelOpen(true)}
          selectedComponent={selectedComponent}
          referenceList={general?.assemblyReferenceList}
        />
      </aside>
    );
  };

  const renderComponentEditor = () => {
    const leftPanel = _isLeftPanelOpen ? renderComponentEditorLeftPanel() : renderSmallInfoPanel();
    return (
      <div
        className="f-row"
        style={{
          position: 'absolute',
          top: 45,
          left: 0,
          bottom: 0,
          zIndex: 100,
          backgroundColor: 'rgba(255,255,255, 0.9)'
        }}>
        {leftPanel}
      </div>
    );
  };

  const renderSUAEditor = () => {
    const leftPanel = _isLeftPanelOpen ? renderSUAEditorLeftPanel() : renderSmallInfoPanel();
    let extendablePanel;
    switch (_extendablePanelState) {
      case VerticalTabsTitle.Library:
        extendablePanel = renderSUAEditorLibraryPanel();
        break;
      case VerticalTabsTitle.Versions:
        extendablePanel = renderSUAHistoryPanel();
        break;
      case VerticalTabsTitle.Report:
        extendablePanel = renderSUAEditorReportPanel();
        break;
      case VerticalTabsTitle.References:
        extendablePanel = renderReferencePanel();
        break;
      default:
        extendablePanel = null;
    }

    return (
      <div
        className="f-row"
        style={{
          position: 'absolute',
          top: 45,
          left: 0,
          bottom: 0,
          zIndex: 100,
          backgroundColor: 'rgba(255,255,255, 0.9)'
        }}>
        {leftPanel}
        {_extendablePanelState && extendablePanel}
        {renderVerticalTabs()}
      </div>
    );
  };

  const renderUnitOperationEditorLeftPanel = () => {
    // TODO: add reference left panel
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <UnitOperationEditorLeftPanel
          onCloseLeftPanel={() => setIsLeftPanelOpen(false)}
          components={components}
          selectedComponent={selectedComponent}
          layerMode={layerMode}
        /> 
      </aside>
    );
  };

  const renderUnitOperationEditorLibraryPanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <UnitOperationEditorLibraryPanel
          selectedComponent={selectedComponent}
          layerMode={layerMode}
        />
      </aside>
    );
  };

  const renderUnitOperationEditorMetricsPanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <UnitOperationEditorMetricsPanel />
      </aside>
    );
  };

  const renderUnitOperationEditorReportPanel = () => {
    return (
      <aside className="f-col border-right" style={leftPanelStyle}>
        <UnitOperationEditorReportPanel />
      </aside>
    );
  };

  const renderUnitOperationEditor = () => {
    const leftPanel = _isLeftPanelOpen
      ? renderUnitOperationEditorLeftPanel()
      : renderSmallInfoPanel();
    let extendablePanel;
    switch (_extendablePanelState) {
      case VerticalTabsTitle.Library:
        extendablePanel = renderUnitOperationEditorLibraryPanel();
        break;
      case VerticalTabsTitle.Metrics:
        extendablePanel = renderUnitOperationEditorMetricsPanel();
        break;
      case VerticalTabsTitle.Report:
        extendablePanel = renderUnitOperationEditorReportPanel();
        break;
      default:
        extendablePanel = null;
    }
    return (
      <div
        className="f-row"
        style={{
          position: 'absolute',
          top: 45,
          left: 0,
          bottom: 0,
          zIndex: 100,
          backgroundColor: 'rgba(255,255,255, 0.95)'
        }}>
        {leftPanel}
        {extendablePanel}
        {renderVerticalTabs()}
      </div>
    );
  };

  switch (editorMode) {
    case EditorMode.Component:
      return renderComponentEditor();
    case EditorMode.SUA:
      return renderSUAEditor();
    case EditorMode.Reference:
      return renderSUAEditor();
    case EditorMode.UnitOperation:
      return renderUnitOperationEditor();
    default:
      return null;
  }
};

export default withRouter(LeftPanel);
