import { Icon, TextInput } from "hakobio-react-ui";
import { NodeList } from "../../Shared/EquipmentPanel/NodeList";
import { useIntl } from "react-intl";
import { actionMessages } from "../../../../lang/messages";
import { useState } from "react";

export const UnitOperationEquipmentPFDPanel = () => {
  const [_searchedValue, setSearchedValue] = useState("");
  const intl = useIntl();

  const renderSearch = () => {
    return (
      <div className="mx-2 my-1">
        <TextInput
          inputSize={"small"}
          value={_searchedValue}
          onChange={(e: any) => setSearchedValue(e.target.value)}
          placeholder={intl.formatMessage(actionMessages.search)}
          endAdornment={<Icon name="search" />}
        />
      </div>
    );
  };

  const render = () => {
    return (
      <div className="f-full f-col">
        {renderSearch()}
        <NodeList searchedValue={_searchedValue} />
      </div>
    );
  };

  return render();
};
