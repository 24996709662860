import __ from 'lodash';
import React, { DragEventHandler, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  ConnectionMode,
  Elements,
  isNode,
  OnLoadFunc,
  ReactFlowProps,
  OnLoadParams,
  useStoreActions
} from 'react-flow-renderer';
import { useAppDispatch, useAppSelector } from '../../store';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../store/features/assemblyEditor/useAssemblyEditorAction';
import { angle } from '../../utilities';
import { edgeTypes } from './enum/EdgeTypes';
import { nodeTypes } from './enum/NodeTypes';
import { DocumentTitle } from '../../constants/DocumentTitle';

const FlowHistorySUA = () => {
  const [elements, setElements] = useState<any>([]);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const componentsRef = useRef<any>(null);
  const dragComponentRef = useRef<any | null>(null);
  const dragPositionRef = useRef<any | null>(null);
  componentsRef.current = components;
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);
  const [isDragging, setDragging] = useState<boolean>(false);
  const [_isMoving, setisMoving] = useState<boolean>(false);
  const { changeSelectionSUA, setHoverOnEdge } = useAssemblyEditorAction();
  const [_selectedComponent, setSelectedComponent] = useState(null);
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const promiseUpdateComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.promiseUpdateComponent
  );
  const promiseUpdateComponentRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();

  useEffect(() => {
    if (general.type === 'SingleUseAssembly') {
      window.parent.postMessage({ message: 'message', value: DocumentTitle.SUAHistory }, '*');
    } else if (general.type === 'SingleUseAssemblyReference') {
      window.parent.postMessage({ message: 'message', value: DocumentTitle.ReferenceHistory }, '*');
    }
  }, [general]);

  useEffect(() => {
    const newElements = getElements();
    setElements(__.cloneDeep(newElements));
  }, [components]);

  useEffect(() => {
    if (promiseUpdateComponent.length !== 0 && !isDragging) {
      promiseUpdateComponentRef.current.forEach((promise: any) => {
        dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
      });
      dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
    }
  }, [promiseUpdateComponent]);

  promiseUpdateComponentRef.current = promiseUpdateComponent;
  const getElements = () => {
    const elements = components.map((c) => {
      if (isNode(c)) {
        /* if (c.data.type === 'pinchClamp' || c.data.type === 'sensor') { */ // ceci empêche les éléments du SUA d'être déplacé
        // debugger;
        // @ts-ignore
        const edgeRef = document.getElementById(c.viewer2D.source);
        if (edgeRef !== undefined && edgeRef !== null) {
          // @ts-ignore
          const edgeLength = edgeRef.getTotalLength();
          // @ts-ignore
          let edgeLoc = edgeRef.getPointAtLength(
            // @ts-ignore
            c.viewer2D.position * edgeLength
          );
          // @ts-ignore
          let edgeLoc2 = edgeRef.getPointAtLength(
            // @ts-ignore
            (c.viewer2D.position + 0.02) * edgeLength
          );
          let rotation = angle(edgeLoc.x, edgeLoc.y, edgeLoc2.x, edgeLoc2.y);
          let node = __.cloneDeep(c) as any;
          node.position = { x: edgeLoc.x - 15, y: edgeLoc.y - 15 };
          node.viewer2D.rotate = rotation;
          node.draggable = false;
          return node;
        } else {
          let node = __.cloneDeep(c) as any;
          node.position = { x: node.viewer2D.x, y: node.viewer2D.y };
          node.draggable = false;
          return node;
        }
        /* } else {
          let node = __.cloneDeep(c) as any;
          node.position = { x: node.viewer2D.x, y: node.viewer2D.y };
          return node;
        } */
      } else {
        let edge = __.cloneDeep(c) as any;
        edge.animated = false;
        edge.style = {};
        edge.source = edge.source;
        edge.target = edge.target;
        return edge;
      }
    });

    return elements.filter((e) => e);
  };
  const onLoad: OnLoadFunc = (_reactFlowInstance) => {
    setTimeout(() => {
      _reactFlowInstance?.fitView({ padding: 0.5, includeHiddenNodes: true });
    }, 1050);
  };
  const onSelectionChange = (selectedElements: Elements<any> | null) => {
    changeSelectionSUA(selectedElements, componentsRef.current, setSelectedElements);
  };
  const onSelectionDragStart: ReactFlowProps['onSelectionDragStart'] = () => {};
  const onSelectionDrag: ReactFlowProps['onSelectionDragStart'] = (event, node) => {
    setDragging(true);
  };

  const onSelectionDragStop: ReactFlowProps['onSelectionDragStop'] = () => {
    promiseUpdateComponentRef.current.forEach((promise: any) => {
      dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
    });
    dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
    setDragging(false);
  };

  const multiSelect = () => {
    //@ts-ignore
    if (navigator.userAgentData?.platform.toLowerCase() === 'macOS') {
      return 224;
    } else {
      return 17;
    }
  };

  const changeDeleteKeyCode = () => {
    return 46;
  };

  const onNodeDragStart: ReactFlowProps['onNodeDragStart'] = (event, node) => {
    setDragging(true);
    let selectedComponent = components.find((c: any) => node.id === c.id);
    setSelectedComponent(selectedComponent);
    let componentListDrag = components.filter(
      (component) =>
        component.data.idDrag &&
        node.data.idDrag &&
        component.data.idDrag.id === node.data.idDrag.id &&
        component.id !== node.id
    );
    dragPositionRef.current = { x: selectedComponent.viewer2D.x, y: selectedComponent.viewer2D.y };
    let position = __.cloneDeep(componentListDrag);
    dragComponentRef.current = position;
  };

  const onNodeDragStop: ReactFlowProps['onNodeDragStop'] = (_, node) => {
    promiseUpdateComponentRef.current.forEach((promise: any) => {
      const clonePromise = __.cloneDeep(promise);
      clonePromise.component.viewer2D.x = _selectedComponent.viewer2D.x;
      clonePromise.component.viewer2D.y = _selectedComponent.viewer2D.y;
      node.position = {
        x: clonePromise.component.viewer2D.x,
        y: clonePromise.component.viewer2D.y
      };
      clonePromise.component.viewer2D.position = 0.5;
      dispatch(assemblyEditorSliceActions.updateComponent(clonePromise.component));
    });
    dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
    setDragging(false);
  };

  const onNodeDrag: ReactFlowProps['onNodeDrag'] = (event, node) => {
    setDragging(true);
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (event.dataTransfer && reactFlowInstance && reactFlowWrapper.current) {
      const constant = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      if (constant.node.snappable) {
        //|| constant.key === 'sensor'
        return;
      }
    }
  };

  const onMoveStart = () => {
    setisMoving(true);
  };

  const onMoveEnd = () => {
    setisMoving(false);
  };

  const onNodeMouseEnter = (event: React.MouseEvent, node: any) => {
    if (node.type === 'genericonedge') {
      setHoverOnEdge(node.id);
    }
  };

  const onNodeMouseLeave = (event: React.MouseEvent, node: any) => {
    if (node.type === 'genericonedge') {
      setHoverOnEdge(null);
    }
  };

  return (
    <>
      <div className="f-full" ref={reactFlowWrapper}>
        {/* @ts-ignore */}
        <ReactFlow
          minZoom={0.05}
          maxZoom={5}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          elements={elements}
          onLoad={onLoad}
          onSelectionChange={onSelectionChange}
          onSelectionDragStart={onSelectionDragStart}
          onSelectionDragStop={onSelectionDragStop}
          onSelectionDrag={onSelectionDrag}
          connectionMode={ConnectionMode.Loose}
          multiSelectionKeyCode={multiSelect()}
          deleteKeyCode={changeDeleteKeyCode()}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={onNodeMouseLeave}
          onNodeDragStop={onNodeDragStop}
          onNodeDragStart={onNodeDragStart}
          onNodeDrag={onNodeDrag}
          onDrop={onDrop}
          onMoveStart={onMoveStart}
          onMoveEnd={onMoveEnd}
          style={{
            backgroundColor: '#f7faff',
            cursor: _isMoving ? 'grabbing' : 'grab'
          }}
        />
      </div>
    </>
  );
};

export default FlowHistorySUA;
