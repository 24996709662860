import { RuleComponentEdgeComponent } from '../RuleComponentEdgeComponent';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';

export class RuleComponentDiameterFunctionComponentDiameter extends RuleComponentEdgeComponent {
  schemaComponentSourceList = allComponents;
  schemaComponentTargetList = allComponents;
  schemaTubeList = ['transferring'];
  editorList = ['UOE_PID'];

  checkPrerequisite = () => {
    const isFunction = this.schemaTubeList.includes(this.edge.data.type);
    const isSource = this.componentSource ? this.schemaComponentSourceList.includes(this.componentSource.data.type) : false;
    const isTarget = this.componentTarget ? this.schemaComponentTargetList.includes(this.componentTarget.data.type) : false;

    if (!isFunction) {
      return false;
    }

    if (!isSource || !isTarget) {
      return false;
    }

    return true;
  };

  checkRule = () => {
    if (this.componentSourceAnchor.data['anchorOuterDiameter'] !== this.componentTargetAnchor.data['anchorOuterDiameter']) {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible diameter',
        errors: 'The diameter on the' +
          ` ${CUSTOM_NODES[this.componentSource.data.type].name} ` +
          '(source) is' +
          (` ${this.componentSourceAnchor.data['anchorOuterDiameter']} ` || ' unspecified ') +
          'and the diameter on the' +
          ` ${CUSTOM_NODES[this.componentTarget.data.type].name} ` +
          '(target) is'
          + (` ${this.componentTargetAnchor.data['anchorOuterDiameter']} ` || ' unspecified '),
        componentAnchors: [
          // @ts-ignore
          { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id },
          // @ts-ignore
          { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
        ]
      });
    }
  };
}

const allComponents = [
  'suPumpHead',
  'asepticDisconnector',
  'mechanicDisconnector',
  'quickCoupler',
  'triclampConnector',
  'bioreactorBag',
  'twoDimensionalBag',
  'threeDimensionalBag',
  'mixerBag',
  'bottle',
  'plug',
  'couplerReducer',
  'lConnector',
  'tConnector',
  'yConnector',
  'xConnector',
  'asepticConnector',
  'sipConnector',
  'hydrophobicFilter',
  'hydrophilicFilter'
];