import { Empty } from "hakobio-react-ui";
import { useEffect, useState } from "react";
import InfiniteScrollWindow from "../Shared/UI/InfiniteScrollWindow";

import Assembly from "./Assembly";

import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../store";

const GalleryView = (props: any) => {
  const [_windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const height = window.innerHeight;
      setWindowHeight(height);
    });
  }, []);

  const renderProject = ({ columnIndex, data, rowIndex, style }: any) => {
    const assembly = data[rowIndex][columnIndex];
    if (!assembly) return null;
    return (
      <>
        <div key={assembly.id} style={style}>
          {/* @ts-ignore */}
          <Assembly assembly={assembly} />
        </div>
      </>
    );
  };

  const innnerElement = ({ style, ...rest }: any) => {
    return (
      <div id="testid" style={{ ...style }}>
        {rest.children}
      </div>
    );
  };
  const selectedFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedFolder,
  );
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);

  const folderName =
    listTab === 1 ? selectedFolder?.name : selectedFolder?.name;

  const defaultName = listTab === 1 ? "All assemblies" : "All unit operation";

  if (props.list?.length) {
    return (
      <>
        <div className="f-full f-col " style={{ flexWrap: "wrap" }}>
          <div className="px-3 pt-3">
            {folderName || defaultName}
            <div
              className="mt-1"
              style={{ width: 50, borderBottom: "1px solid var(--grey)" }}
            ></div>
          </div>

          {/* @ts-ignore */}
          <InfiniteScrollWindow
            rowHeight={400}
            children={renderProject}
            itemData={props.list}
            // @ts-ignore
            innerElementType={innnerElement}
            type={"grid"}
            adjustColumnWidth={25}
            itemCount={10}
          />
          <div style={{ height: _windowHeight - 290 }} />
        </div>
      </>
    );
  } else {
    return (
      <div className="f-col h-100 f1-center">
        <div className="px-3 pt-3">
          {folderName || defaultName}
          <div
            className="mt-1"
            style={{ width: 50, borderBottom: "1px solid var(--grey)" }}
          ></div>
        </div>
        <Empty
          text={
            <FormattedMessage
              id="SUConfig.StartupPanel.Empty.ThisListIsEmpty"
              defaultMessage="This list is empty"
            />
          }
        />
      </div>
    );
  }
};

export default GalleryView;
