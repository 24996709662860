// @ts-ignore
import {v4 as uuidv4} from 'uuid';

export const getConnectionCount = (components: Array<any>) => {
    const edges = components.filter((c) => {
        const source = components.find((cc) => cc.id === c.source);  
        const target = components.find((cc) => cc.id === c.target);       
        return c.type === "functionedge" && source.type === "function" && target.type === "function"
    });

    return edges.length;
}

export const getConnectionCountByType = (components: Array<any>) => {
    const edges = components.filter((c) => c.type === "functionedge");
    const edgeCountList = [] as Array<any>
    edges.forEach((edge) => {
        const source = components.find((c) => c.id === edge.source);
        const target = components.find((c) => c.id === edge.target);
        if(source.type !== "generic" || target.type !== "generic"){
            return;
        }
        const result1 = edgeCountList.find((ec) => ec.end1 === source.data.type && ec.end2 === target.data.type);
        const result2 = edgeCountList.find((ec) => ec.end2 === source.data.type && ec.end1 === target.data.type);
        if(result1){
            result1.count += 1;
        }else if(result2){
            result2.count += 1;
        }else{
            edgeCountList.push({
                end1: source.data.type,
                end2: target.data.type,
                count: 1
            })
        }
    })
    return edgeCountList;
}

export const getAssembliesCount = (components: Array<any>) => {
    const edges = components.filter((c) => c.type === "function" && c.data.assembly);
    return edges.length;
}

export const getAssembliesCountByType = (components: Array<any>) => {
    const functions = components.filter((c) => c.type === "function" && c.data.assembly);
    const functionCountList = [] as Array<any>
    functions.forEach((f) => {
        const component = functionCountList.find((fc) => fc.id === f.data.assembly.general.id);
        if(component){
            component.count += 1;
        }else{
            functionCountList.push({
                id: f.data.assembly.general.id,
                name: f.data.assembly.general.name,
                count: 1
            })
        }
    })
    return functionCountList;
}

export const getAssemblies = (components: Array<any>) => {
    const functions = components.filter((c) => c.type === "function" && c.data.assembly);
    const functionCountList = [] as Array<any>
    functions.forEach((f) => {
        const component = functionCountList.find((fc) => fc.id === f.data.assembly.general.id);
        if(component){
            component.count += 1;
        }else{
            functionCountList.push({
                id: f.data.assembly.general.id,
                general: f.data.assembly.general,
                components: f.data.assembly.components
            })
        }
    })
    return functionCountList;
}