import treeDBagSVG from './schemas/components/threeDimensionalBag.svg';
import suPumpHeadSVG from './schemas/components/suPumpHead.svg';
import mechanicDisconnectorSVG from './schemas/components/mechanicDisconnector.svg';
import adapterRmToMtc from './schemas/components/adapterRmToMtc.svg';
import airTrap from './schemas/components/airTrap.svg';
import asepticDisconnector from './schemas/components/asepticDisconnector.svg';
import quickCouplerSVG from './schemas/components/quickCoupler.svg';
import triclampConnectorSVG from './schemas/components/triclampConnector.svg';
import bioreactorBagSVG from './schemas/components/bioreactorBag.svg';
import mixerBagSVG from './schemas/components/mixerBag.svg';
import needlessClaveSamplePort from './schemas/components/needlessClaveSamplePort.svg';
import bottleSVG from './schemas/components/bottle.svg';
import bottleCapSVG from './schemas/components/bottleCap.svg';
import chromatoColumnSVG from './schemas/components/chromatoColumn.svg';
import xConnectorSVG from './schemas/components/xConnector.svg';
import hydrophobicFilterSVG from './schemas/components/hydrophobicFilter.svg';
import hydrophilicFilterSVG from './schemas/components/hydrophilicFilter.svg';
import tConnectorSVG from './schemas/components/tConnector.svg';
import lConnectorSVG from './schemas/components/lConnector.svg';
import twoDBagSVG from './schemas/components/twoDimensionalBag.svg';
import plugSVG from './schemas/components/plug.svg';
import sensorSVG from './schemas/components/sensor.svg';
import syringeSVG from './schemas/components/syringe.svg';
import yConnectorSVG from './schemas/components/yConnector.svg';
import asepticConnectorSVG from './schemas/components/asepticConnector.svg';
import sipConnectorSVG from './schemas/components/sipConnector.svg';
import pinchClampSVG from './schemas/components/pinchClamp.svg';
import pinchValveSVG from './schemas/components/pinchValve.svg';
import shutOffValveSVG from './schemas/components/shutOffValve.svg';
import couplerReducerSVG from './schemas/components/couplerReducer.svg';
import cellFactoryChamberSVG from './schemas/components/cellFactoryChamber.svg';
import powderTransferBagSVG from './schemas/components/powderTransferBag.svg';
import samplingTubeSVG from './schemas/components/samplingTube.svg';
import tubing from './schemas/components/tubing.svg';

import SVGInject from '@iconfu/svg-inject/dist/svg-inject.min.js';

export const CUSTOM_NODES: any = {
  suPumpHead: {
    name: 'SU Pump Head',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/suPumpHead.json'),
    form: (number = 80) => {
      return (
        <img
          src={suPumpHeadSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'SU Pump Head'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  adapterRmToMtc: {
    name: 'Adapter RM to mTC',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/adapterRmToMtc.json'),
    form: (number = 40) => {
      return (
        <img
          src={adapterRmToMtc}
          draggable={false}
          height={number}
          width={number}
          alt={'Adapter RM to mTC'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  airTrap: {
    name: 'Air Trap',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/airTrap.json'),
    form: (number = 80) => {
      return (
        <img
          src={airTrap}
          draggable={false}
          height={number}
          width={number}
          alt={'Air Trap'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  asepticDisconnector: {
    name: 'Aseptic Disconnector',
    anchors: [
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.037263583333333336,
          top: 0.4873111111111111
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.9571805555555555,
          top: 0.4829361111111111
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/asepticDisconnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={asepticDisconnector}
          draggable={false}
          height={number}
          width={number}
          alt={'Aseptic Disconnector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  mechanicDisconnector: {
    name: 'Mechanic Disconnector',
    anchors: [
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.042819138888888886,
          top: 0.48039444444444446
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.9517444444444444,
          top: 0.4718833333333333
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/mechanicDisconnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={mechanicDisconnectorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Mechanic Disconnector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  quickCoupler: {
    name: 'Quick Coupler',
    anchors: [
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.04861111111111111,
          top: 0.47968611111111115
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.9687638888888889,
          top: 0.48559444444444444
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/quickCoupler.json'),
    form: (number = 40) => {
      return (
        <img
          src={quickCouplerSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Quick Coupler'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  triclampConnector: {
    name: 'Triclamp Connector',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/triclampConnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={triclampConnectorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'triclamp connector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  bioreactorBag: {
    name: 'Bioreactor Bag',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 120,
    schema: async () => await import('./schemas/components/bioreactorBag.json'),
    form: (number = 120) => {
      return (
        <img
          src={bioreactorBagSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'bioreactor bag'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  twoDimensionalBag: {
    name: '2D Bag',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.2388888888888889,
          left: 0.9527777777777777
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.6416666666666667,
          left: 0.9527777777777777
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/twoDimensionalBag.json'),
    form: (number = 80) => {
      return (
        <img
          src={twoDBagSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'2D bag'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  threeDimensionalBag: {
    name: '3D Bag',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 120,
    schema: async () => await import('./schemas/components/threeDimensionalBag.json'),
    form: (number = 120) => {
      return (
        <img
          src={treeDBagSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'3D bag'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  mixerBag: {
    name: 'Mixer Bag',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 120,
    schema: async () => await import('./schemas/components/mixerBag.json'),
    form: (number = 120) => {
      return (
        <img
          src={mixerBagSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Mixer Bag'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  needlessClaveSamplePort: {
    name: 'Needless Clave Sample Port',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/needlessClaveSamplePort.json'),
    form: (number = 40) => {
      return (
        <img
          src={needlessClaveSamplePort}
          draggable={false}
          height={number}
          width={number}
          alt={'Needless Clave Sample Port'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  bottle: {
    name: 'Bottle',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/bottle.json'),
    form: (number = 80) => {
      return (
        <img
          src={bottleSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'bottle'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  bottleCap: {
    name: 'Bottle Cap',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 60,
    schema: async () => await import('./schemas/components/bottleCap.json'),
    form: (number = 80) => {
      return (
        <img
          src={bottleCapSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'bottleCap'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  chromatoColumn: {
    name: 'Chromatography column',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/chromatoColumn.json'),
    form: (number = 80) => {
      return (
        <img
          src={chromatoColumnSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'chromatoColumn'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  plug: {
    name: 'Plug',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.4638888888888889,
          left: 0.32222222222222224
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.4638888888888889,
          left: 0.6527777777777778
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/plug.json'),
    form: (number = 40) => {
      return (
        <img
          src={plugSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Plug'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  sensor: {
    name: 'Sensor',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.4861111111111111,
          left: 0.15555555555555556
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.475,
          left: 0.8
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/sensor.json'),
    form: (number = 40) => {
      return (
        <img
          src={sensorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Sensor'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  couplerReducer: {
    name: 'Coupler/Reducer',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.4638888888888889,
          left: 0.013888888888888888
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.44722222222222224,
          left: 0.9361111111111111
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/couplerReducer.json'),
    form: (number = 40) => {
      return (
        <img
          src={couplerReducerSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'couplerReducer'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  lConnector: {
    name: 'L Connector',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.45,
          left: 0.9305555555555556
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.9,
          left: 0.4638888888888889
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/lConnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={lConnectorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'l connector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  tConnector: {
    name: 'T Connector',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.46111111111111114,
          left: 0.013888888888888888
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.45,
          left: 0.9305555555555556
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.9,
          left: 0.4638888888888889
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/tConnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={tConnectorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'T Connector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  yConnector: {
    name: 'Y Connector',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.4583333333333333,
          left: 0.016666666666666666
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.125,
          left: 0.7944444444444444
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.7777777777777778,
          left: 0.775
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/yConnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={yConnectorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Y Connector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  xConnector: {
    name: 'X Connector',
    anchors: [
      {
        type: 'neutral',
        data: {
          position: 'top'
        },
        viewer2D: {
          left: 0.47922499999999996,
          top: 0.03506391666666667
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'right'
        },
        viewer2D: {
          left: 0.9507972222222222,
          top: 0.4818722222222222
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'bottom'
        },
        viewer2D: {
          left: 0.481175,
          top: 0.9628416666666666
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.034722222222222224,
          top: 0.47702500000000003
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/xConnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={xConnectorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'X Connector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  asepticConnector: {
    name: 'SU/SU aseptic connector',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.4666666666666667,
          left: 0.25833333333333336
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.4666666666666667,
          left: 0.5194444444444445
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/asepticConnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={asepticConnectorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'SU/SU aseptic connector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  sipConnector: {
    name: 'SIP Connector',
    anchors: [
      {
        type: 'neutral',
        viewer2D: {
          top: 0.46944444444444444,
          left: 0.005555555555555556
        },
        data: {
          name: '',
          position: 'left'
        }
      },
      {
        type: 'neutral',
        viewer2D: {
          top: 0.46111111111111114,
          left: 0.95
        },
        data: {
          name: '',
          position: 'left'
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/sipConnector.json'),
    form: (number = 40) => {
      return (
        <img
          src={sipConnectorSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'SIP connector'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  pinchClamp: {
    name: 'Pinch Clamp',
    size: 40,
    snappable: true,
    schema: async () => await import('./schemas/components/pinchClamp.json'),
    form: (number = 40) => {
      return (
        <img
          src={pinchClampSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Pinch clamp'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  pinchValve: {
    name: 'Pinch Valve',
    size: 40,
    snappable: true,
    schema: async () => await import('./schemas/components/pinchValve.json'),
    form: (number = 40) => {
      return (
        <img
          src={pinchValveSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Pinch valve'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  shutOffValve: {
    name: 'Shut-Off Valve',
    size: 40,
    schema: async () => await import('./schemas/components/shutOffValve.json'),
    form: (number = 40) => {
      return (
        <img
          src={shutOffValveSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Shut-off valve'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  hydrophobicFilter: {
    name: 'Hydrophobic Filter',
    anchors: [
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.09890666666666667,
          top: 0.4798027777777778
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: {
          left: 0.8573583333333333,
          top: 0.4805138888888889
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 40,
    schema: async () => await import('./schemas/components/hydrophobicFilter.json'),
    form: (number = 40) => {
      return (
        <img
          src={hydrophobicFilterSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Hydrophobic Filter'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  hydrophilicFilter: {
    name: 'Hydrophilic Filter',
    anchors: [
      {
        type: 'neutral',
        data: {
          position: 'top'
        },
        viewer2D: {
          left: 0.4902194444444445,
          top: 0.020465794444444445
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'bottom'
        },
        viewer2D: {
          left: 0.48501666666666665,
          top: 0.9707027777777777
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/hydrophilicFilter.json'),
    form: (number = 80) => {
      return (
        <img
          src={hydrophilicFilterSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Hydrophilic Filter'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  cellFactoryChamber: {
    name: 'Cell Factory Chamber',
    anchors: [
      {
        type: 'neutral',
        data: {
          position: 'top'
        },
        viewer2D: {
          left: 0.11,
          top: 0.115
        }
      },
      {
        type: 'neutral',
        data: {
          position: 'top'
        },
        viewer2D: {
          left: 0.32,
          top: 0.115
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/cellFactoryChamber.json'),
    form: (number = 80) => {
      return (
        <img
          src={cellFactoryChamberSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Cell Factory Chamber'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  powderTransferBag: {
    name: 'Powder Transfer Bag',
    anchors: [
      {
        type: 'neutral',
        data: {
          position: 'top'
        },
        viewer2D: {
          left: 0.4910472222222222,
          top: 0.9679249999999999
        }
      }
    ],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/powderTransferBag.json'),
    form: (number = 80) => {
      return (
        <img
          src={powderTransferBagSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'Powder Transfer Bag'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  samplingTube: {
    name: 'Sampling Tube',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 80,
    schema: async () => await import('./schemas/components/samplingTube.json'),
    form: (number = 80) => {
      return (
        <img
          src={samplingTubeSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'sampling-tube'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  syringe: {
    name: 'Syringe',
    anchors: [],
    instrumentationPorts: [],
    samplingPorts: [],
    size: 60,
    schema: async () => await import('./schemas/components/syringe.json'),
    form: (number = 60) => {
      return (
        <img
          src={syringeSVG}
          draggable={false}
          height={number}
          width={number}
          alt={'syringe'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  },
  tubing: {
    name: 'Tubing',
    hideInLibrary: true,
    schema: async () => await import('./schemas/components/tubing.json'),
    form: (number = 80) => {
      return (
        <img
          src={tubing}
          draggable={false}
          height={number}
          width={number}
          alt={'Tubing'}
          onLoad={(e: any) => SVGInject(e.target)}
        />
      );
    }
  }
};
