import {store} from "../store";
import {assemblyEditorSliceActions} from "../store/features/assemblyEditor/assemblyEditorSlice";
import {HakoBioPluginSDK} from "hakobio-plugin-sdk";
import * as Sentry from '@sentry/react';

function setupUserSentry (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let parsedJwt = JSON.parse(jsonPayload);
    Sentry.setUser({
        email: parsedJwt.email,
        id: parsedJwt.id,
    });
}

function waitFor(conditionFunction) {
    const poll = (resolve) => {
        if (conditionFunction()) resolve();
        else setTimeout((_) => poll(resolve), 400);
    };

    return new Promise(poll);
}

async function checkToken() {
    let localStore = store.getState();
    const jwt = localStore.assemblyEditorSlice.jwt;
    const lastTime = parseInt(localStore.assemblyEditorSlice.jwtTime, 10);
    const isAfter50Min = lastTime + 50 * 60 * 1000 <= Date.now();
    if (!jwt || isAfter50Min) {
        store.dispatch(
          assemblyEditorSliceActions.setJWT({jwt: null, jwtTime: null})
        )
        HakoBioPluginSDK.getJWT();
        let receivedJwt;
        await waitFor((_) => {
            localStore = store.getState();
            receivedJwt = localStore.assemblyEditorSlice.jwt;
            return receivedJwt !== null;
        });
        setupUserSentry(receivedJwt);
        return receivedJwt;
    } else {
        setupUserSentry(jwt);
        return jwt;
    }
}

/**
 *
 * @param {string} method
 * @param {any} body
 * @returns
 */
export const createAuthRequest = async (method = 'GET', body = null) => {
    const token = await checkToken();
    const mode = 'cors';
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    if (!body) {
        return { method, mode, headers };
    } else {
        return { method, mode, headers, body: JSON.stringify(body) };
    }
};

export const createAuthRequestFile = async (method = 'GET', body = null) => {
    const token = await checkToken();
    const mode = 'cors';
    var headers = new Headers();
    //headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    if (!body) {
        return { method, mode, headers };
    } else {
        var data = new FormData();
        data.append('file', body);
        return { method, mode, headers, body: data };
    }
};
