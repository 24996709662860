const PluginApiActions = {
  SetProject: "unity_action_SetProject",
  SendMousePosition: "unity_action_SendMousePosition",
  DragStart: "unity_action_DragStart",
  DragEnd: "unity_action_DragEnd",
  DragCancel: "unity_action_DragCancel",
  AddElements: "unity_action_AddElements",
  SetBackground: "unity_action_SetBackground",
  CreateZonePoints: "unity_action_CreateZonePoints",
  StopCreateZonePoints: "unity_action_StopCreateZonePoints",
  Undo: "unity_action_Undo",
  Redo: "unity_action_Redo",
  Isolate: "unity_action_Isolate",
  SetElementScale: "unity_action_SetElementScale",
  SetWallDisplayMode: "unity_action_SetWallDisplayMode",
  Split: "unity_action_Split",
  EnableWall: "unity_action_EnableWall",
  Delete: "unity_action_Delete",
  Keypress: "unity_action_Keypress",
  UpdateGhosts: "unity_action_UpdateGhosts",
  UpdateArrows: "unity_action_UpdateArrows",
  TakeScreenshot: "unity_action_TakeScreenshot",
  SetCamera: "unity_action_SetCamera",
  SetCameraPosition: "unity_action_SetCameraPosition",
  DrawLines: "unity_action_DrawLines",
  FpvClick: "unity_action_FpvClick",
  NextStep: "unity_action_NextStep",
  SetFpvFocus: "unity_action_SetFpvFocus",
  UpdateLabels: "unity_action_UpdateLabels",
  Outline: "unity_action_Outline",
  Copy: "unity_action_Copy",
  Paste: "unity_action_Paste",
  ConfigureEquipment: "unity_action_ConfigureEquipment",
  ConfigureEquipmentOnly: "unity_action_ConfigureEquipmentOnly",
  Zoom: "unity_action_Zoom",
  ChangeScene: "unity_action_ChangeScene",
  BuilderExit: "unity_action_BuilderExit",
  BuilderFocus: "unity_action_BuilderFocus",
  SetSelection: "unity_action_SetSelection",
  Locate: "unity_action_Locate",
  GetZoneElements: "unity_action_GetZoneElements",

  //Tubes
  AddTubes: "unity_action_AddTubes",
  UpdateTubes: "unity_action_UpdateTubes",
  RemoveTubes: "unity_action_RemoveTubes",
  ClearTubes: "unity_action_ClearTubes",

  //Sockets
  CreateSocket: "unity_action_CreateSocket",
  RemoveSockets: "unity_action_RemoveSockets",
  UpdateSockets: "unity_action_UpdateSockets",
  AddSockets: "unity_action_AddSocket",
  EndCreateSocket: "unity_action_EndCreateSocket",

  //Flows
  UpdateFlows: "unity_action_UpdateFlows",
  StartFlowFreeDraw: "unity_action_StartFlowFreeDraw",
  EndFlowFreeDraw: "unity_action_EndFlowFreeDraw",
  FrameScene: "unity_action_FrameScene",
  FrameAndSnap: "unity_action_FrameAndSnap",

  //Guided tour
  StartGuidedTour: "unity_action_StartGuidedTour",

  //Assets
  SetAssetsResolution: "unity_action_SetAssetsResolution",
  UseAssetImages: "unity_action_UseAssetImages",

  RequestOnReady: "unity_action_RequestOnReady",

  SetAngle: "unity_action_SetAngle",
  SetGlobalConfig: "unity_action_SetGlobalConfig",

  //ProjectImage
  SetProjectImages: "unity_action_SetProjectImages",
  updateUITags: "unity_action_updateUITags",
  getCameraInfo: "unity_action_getCameraInfo",

  LockElements: "unity_action_LockElements",
  UnlockElements: "unity_action_UnlockElements",

  //training
  GetPossibleActions: "unity_action_GetPossibleActions",
  SetEquipmentState: "unity_action_SetEquipmentState",
  GetCurrentStates: "unity_action_getCurrentStates",
  SetupSetPosition: "unity_action_SetupSetPosition",
  MovePosition: "unity_action_SetupMovePosition",
  ResetTransforms: "unity_action_resetTransforms",
  SaveTransforms: "unity_action_saveTransforms",
  isPathExist: "unity_action_isPathExist",
};

export default PluginApiActions;
