import React, { useEffect } from 'react';
import { useAppDispatch } from '../../store';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';

const style = {
  height: '300px',
  width: '300px',
  color: 'white',
  backgroundColor: '#564fae',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
};

export const StartupPanel = (props: any) => {
  const dispatch = useAppDispatch();


  useEffect(() => {
    dispatch(assemblyEditorSliceActions.resetEditor(null));
  }, []);

  const redirectSingleUse = () => {
    return props.history.push('/editor/SingleUseAssembly');
  };

  const redirectSingleUseReference = () => {
    return props.history.push('/editor/Reference');
  };

  const redirectUnitOperation = () => {
    return props.history.push('/editor/UnitOperation');
  };

  const renderSingleUse = () => {
    return (
      //@ts-ignore
      <div style={style} onClick={redirectSingleUse}>
        Single Use Assembly Designer
      </div>
    );
  };

  const renderSingleUseReference = () => {
    return (
      //@ts-ignore
      <div style={style} onClick={redirectSingleUseReference}>
        Single Use Assembly Reference
      </div>
    );
  };

  const renderUnitOperation = () => {
    return (
      //@ts-ignore
      <div style={style} onClick={redirectUnitOperation}>
        Unit Operation Designer
      </div>
    );
  };

  const render = () => {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        {renderSingleUse()}
        {renderSingleUseReference()}
        <div style={{ width: '40px' }} />
        {renderUnitOperation()}
      </div>
    );
  };

  return render();
};
