import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { shareAssembly } from '../../../services/sharing/shareService';
import { useAssemblyEditorAction } from './useAssemblyEditorAction';
import { Shareable } from '../../../utilities/Shareable';

export const useSharingAction = () => {
  const { onGeneralEdited } = useAssemblyEditorAction();

  const shareAssemblyAsync = async (shareable: Shareable) => {
    const result = await shareAssembly(shareable);
    const resLoaded = await result;
    if (resLoaded) {
      toast.success(
        <FormattedMessage
          id="SUConfig.StartupPanel.Toast.AccessRightsSuccessfullyChanged"
          defaultMessage="Access rights successfully changed"
        />
      );
    } else {
      toast.error(
        <FormattedMessage
          id="SUConfig.StartupPanel.Toast.AProblemOccuredWhileChangingAccessRights"
          defaultMessage="A problem occured while changing access rights"
        />
      );
    }
  };

  const setPrivacy = (shareable: Shareable, isPrivate: boolean) => {
    onGeneralEdited('isPrivate', !isPrivate);
    shareAssemblyAsync(shareable);
  };

  return {
    setPrivacy
  };
};
