import { CUSTOM_FUNCTIONS } from '../../../../constants/CUSTOM_FUNCTIONS';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';

export const availableFunctions: string[] = Object.keys(CUSTOM_FUNCTIONS)
  .filter(
    (fc) =>
      fc.toLowerCase() !== 'waste' &&
      fc.toLowerCase() !== 'utilities' &&
      fc.toLowerCase() !== 'transferring' &&
      fc.toLowerCase() !== 'purification'
  )
  .sort();

export const availableContainers: string[] = Object.keys(CUSTOM_NODES)
  .filter(
    (comp) =>
      comp.toLowerCase().includes('bag') ||
      comp.toLowerCase().includes('bottle') ||
      comp.toLowerCase().includes('chamber')
  )
  .sort();

export const availableConnectors: string[] = Object.keys(CUSTOM_NODES)
  .filter((comp) => comp.toLowerCase().includes('connector') || comp.toLowerCase().includes('plug'))
  .sort();
