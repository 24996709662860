import { ThinChip } from 'hakobio-react-ui';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { versioningMessages } from '../../lang/messages';
import { useAppSelector } from '../../store';

const GeneralChangeArray = ({ title, viewingArray, comparingArray, setHasDifferences }) => {
  const viewingAssembly = useAppSelector((state) => state.historySlice.viewingAssembly);
  const comparingAssembly = useAppSelector((state) => state.historySlice.comparingAssembly);

  const intl = useIntl();

  useEffect(() => {
    if (!isEqual(viewingArray, comparingArray)) {
      setHasDifferences(true);
    } else {
      setHasDifferences(false);
    }
  }, [viewingArray, comparingArray]);

  if (!isEqual(viewingArray, comparingArray)) {
    const combinedArray = [...viewingArray, ...comparingArray];
    // @ts-ignore
    const uniqueItems = [...new Set(combinedArray)];

    return (
      <div className="p-2">
        <div className="font-regular">{title}</div>
        <div className="py-1 f-col gap-2 hover-item">
          <div className="f-row f2-start gap-2 pl-3 py-1">
            <ThinChip
              style={{ minWidth: 56 }}
              className="f-shrink-0"
              center
              color={'var(--primaryColor)'}
              backgroundColor={'var(--primaryColorBcg)'}
              label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
                viewingAssembly?.general?.version + 1
              }`}
            />
            <div className=" f-row f-wrap gap-2">
              {uniqueItems.map((v) => {
                const isInViewingVersion = viewingArray.includes(v);
                const isInComparingVersion = comparingArray.includes(v);
                const label =
                  isInViewingVersion && isInComparingVersion ? (
                    v
                  ) : isInComparingVersion ? (
                    <span className="font-regular">&#43; {v}</span>
                  ) : (
                    <s>{v}</s>
                  );
                return (
                  <ThinChip
                    color={'var(--primaryColor)'}
                    backgroundColor={'var(--primaryColorBcg)'}
                    label={label || 'N/C'}
                  />
                );
              })}
            </div>
          </div>
          <div className="f-row f2-start gap-2 pl-3 py-1">
            <ThinChip
              style={{ minWidth: 56 }}
              className="f-shrink-0"
              center
              label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
                comparingAssembly?.general?.version + 1
              }`}
            />
            <div className=" f-row f-wrap gap-2">
              {uniqueItems.map((v) => {
                const isInViewingVersion = viewingArray.includes(v);
                const isInComparingVersion = comparingArray.includes(v);
                const label =
                  isInViewingVersion && isInComparingVersion ? (
                    v
                  ) : isInViewingVersion ? (
                    <span className="font-regular">&#43; {v}</span>
                  ) : (
                    <span style={{ textDecoration: 'line-through' }}>{v}</span>
                  );
                return <ThinChip label={label || 'N/C'} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default GeneralChangeArray;
