import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import CameraManager from './CameraManager';

import { Icon } from 'hakobio-react-ui';
import FabButton from './UI/UIBar/FabButton';

import { useStoreActions } from 'react-flow-renderer';

import {
  cameraModes,
  setCameraRotateOrTranslation,
  setMode
} from '../../store/features/modeView/modeViewSlice.ts';

import { useZoomPanHelper } from 'react-flow-renderer';
import { useAppSelector } from '../../store';
import { EditorMode } from '../../constants/EditorMode';
import { UnitOperationLayer } from '../../constants/PFD_EquipmentTabs';
import { useAssemblyEditorAction } from '../../store/features/assemblyEditor/useAssemblyEditorAction';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';

const ControlButtons = (props) => {
  const dispatch = useDispatch();
  const mode = useAppSelector((state) => state.modeViewSlice.mode);
  const isCameraInRotate = useAppSelector((state) => state.modeViewSlice.isCameraInRotate);
  const { zoomIn: zoomInFlow, zoomOut: zoomOutFlow } = useZoomPanHelper();
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);

  const isLayerLocked = useAppSelector((state) => state.assemblyEditorSlice.isLayerLocked);
  const areSUAFramesDisplayed = useAppSelector(
    (state) => state.assemblyEditorSlice.areSUAFramesDisplayed
  );

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);

  const { setIsLayerLocked, setSUAFramesDisplayed } = useAssemblyEditorAction();
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  const intl = useIntl();

  const toggleRotate3D = () => {
    dispatch(setCameraRotateOrTranslation());
  };

  //const makeScreenshot = () => screenshotAndDownload(props.project.name);
  // useEffect(() => {
  //   if(components.length){
  //  const generic =  components.filter((el)=>el.type!=='function' && el.type!=='functionedge').map((el)=>el.data.assembly.id)
  //  const suaIds = uniq(generic)
  //  let sua = [];
  //  suaIds.forEach(element => {
  //   const getUOAssembly = async () => {
  //   let assembly = await getAssembly(element);
  //   sua.push(assembly)
  //   }
  //   getUOAssembly()
  //  });

  //  console.log("SUA ", suaIds)
  //  setAllSUA(sua);
  //   }
  // }, [components]);
  /**
   * RENDER
   */
  const renderCameraManipulationButton = () => {
    let icon;
    if (isCameraInRotate) {
      icon = <Icon center name="rotation" theme={props.theme} size={14} />;
    } else {
      icon = <Icon center name="move-translation" theme={props.theme} size={14} />;
    }

    return <div data-id="item-move-camera">{<div>{icon}</div>}</div>;
  };

  const renderMultiActionButton = () => {
    let functions = [
      {
        onClickFn: mode === cameraModes.TWO ? zoomInFlow : zoomIn,
        title: intl.formatMessage({
          id: 'Common.ZoomIn',
          defaultMessage: 'Zoom in'
        }),
        content: <span className="zoom plus">&#43;</span>
      },
      {
        onClickFn: mode === cameraModes.TWO ? zoomOutFlow : zoomOut,
        title: intl.formatMessage({
          id: 'Common.ZoomOut',
          defaultMessage: 'Zoom out'
        }),
        content: <span className="zoom">&minus;</span>
      }
      // {
      //   onClickFn: mode === cameraModes.THREE ? setCenter : reset,
      //   title: intl.formatMessage({
      //     id: "Common.CenterCamera",
      //     defaultMessage: "Center camera",
      //   }),
      //   content: <HakoBio2Icon name="full_screen_retour" center size={14} />,
      // },
    ];

    if (mode === cameraModes.THREE) {
      functions.splice(3, 0, {
        onClickFn: toggleRotate3D,
        title: intl.formatMessage({
          id: 'Builder.MoveCamera',
          defaultMessage: 'Move camera'
        }),
        content: renderCameraManipulationButton()
      });
    }
    if (EditorMode.UnitOperation === editorMode && layerMode === UnitOperationLayer.PFD) {
      functions.splice(2, 0, {
        onClickFn: () => {
          switchPerspective();
        },
        title: intl.formatMessage({
          id: 'Builder.SwitchPerspective',
          defaultMessage: 'Switch perspective'
        }),
        content: renderPerspectiveButton()
      });
    }

    const onClickFn = functions.map((i) => i.onClickFn);
    const titles = functions.map((i) => i.onClickFn);
    const content = functions.map((i) => i.content);

    return <FabButton multi onClick={onClickFn} title={titles} content={content} />;
  };

  const switchPosition = () => {
    setIsLayerLocked(!isLayerLocked);
  };

  const switchPerspective = async () => {
    dispatch(setMode());
  };

  const zoomIn = () => {
    CameraManager.zoomIn();
  };
  const zoomOut = () => {
    CameraManager.zoomOut();
  };

  const handleEyeButton = () => {
    setSUAFramesDisplayed(!areSUAFramesDisplayed);
    const selectionNodes = components.filter((c) => c.type === 'selection');
    if (selectionNodes.length)
      dispatch(assemblyEditorSliceActions.removeComponents([...selectionNodes]));
    setSelectedElements([]);
    dispatch(assemblyEditorSliceActions.selectComponents([]));
  };

  const renderPerspectiveButton = () => (
    <div data-id="item-switch-perspective">
      <div>
        <span className="switch-perspective">
          {mode === cameraModes.TWO ? (
            <FormattedMessage id="Builder.2D" defaultMessage="2D" />
          ) : (
            <FormattedMessage id="Builder.3D" defaultMessage="3D" />
          )}
        </span>
      </div>
    </div>
  );

  const renderEyeButton = () => {
    if (editorMode !== EditorMode.UnitOperation || layerMode !== UnitOperationLayer.PnID && layerMode !== UnitOperationLayer.Reference) {
      return null;
    }
    return (
      <FabButton
        backgroundColor={areSUAFramesDisplayed ? '#7A00E6' : '#FFFFFF'}
        style={{ border: areSUAFramesDisplayed ? 0 : '1px solid lightgrey' }}
        onClick={handleEyeButton}
        content={
          <Icon
            name={areSUAFramesDisplayed ? 'eye' : 'eye-disabled'}
            size={16}
            color={areSUAFramesDisplayed ? '#FFFFFF' : '#7A00E6'}
          />
        }
        title={
          areSUAFramesDisplayed
            ? intl.formatMessage({ id: 'Builder.FramesDisplay', defaultMessage: 'Highlighted SUA' })
            : intl.formatMessage({ id: 'Builder.FramesNoDisplay', defaultMessage: 'No highlights' })
        }
      />
    );
  };

  const renderLockButton = () => {
    if (editorMode !== EditorMode.UnitOperation) {
      return null;
    }
    return (
      <FabButton
        backgroundColor={isLayerLocked ? '#7A00E6' : '#FFFFFF'}
        style={{ border: isLayerLocked ? 0 : '1px solid lightgrey' }}
        onClick={() => switchPosition()}
        content={
          <Icon
            name={isLayerLocked ? 'padlock' : 'padlock-unlock'}
            size={16}
            color={isLayerLocked ? '#FFFFFF' : '#7A00E6'}
          />
        }
        title={
          isLayerLocked
            ? intl.formatMessage({ id: 'Builder.LayerLocked', defaultMessage: 'Locked layer' })
            : intl.formatMessage({ id: 'Builder.LayerUnlocked', defaultMessage: 'Unlocked layer' })
        }
      />
    );
  };

  const render = () => {
    return (
      <React.Fragment>
        <div className="controlButtons" style={{ cursor: 'pointer' }}>
          {/* {renderScreenshotButton()} */}
          {renderEyeButton()}
          {renderMultiActionButton()}
          {renderLockButton()}
        </div>
      </React.Fragment>
    );
  };

  return render();
};

export default ControlButtons;
