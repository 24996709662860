import { ThinChip } from 'hakobio-react-ui';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { versioningMessages } from '../../lang/messages';
import { useAppSelector } from '../../store';

const GeneralChangeString = ({ title, viewingVariable, comparingVariable, setHasDifferences }) => {
  const viewingAssembly = useAppSelector((state) => state.historySlice.viewingAssembly);
  const comparingAssembly = useAppSelector((state) => state.historySlice.comparingAssembly);

  const intl = useIntl();

  useEffect(() => {
    setHasDifferences(viewingVariable !== comparingVariable);
  }, [viewingVariable, comparingVariable]);

  if (viewingVariable !== comparingVariable) {
    return (
      <div className="p-2 ">
        <div className="font-regular">{title}</div>
        <div className="py-1 f-col gap-2 hover-item">
          <div className="f-row gap-2 pl-3 ">
            <ThinChip
              style={{ minWidth: 56 }}
              className="f-shrink-0"
              center
              color={'var(--primaryColor)'}
              backgroundColor={'var(--primaryColorBcg)'}
              label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
                viewingAssembly?.general?.version + 1
              }`}
            />
            <ThinChip
              color={'var(--primaryColor)'}
              backgroundColor={'var(--primaryColorBcg)'}
              label={<span className="capitalize">{viewingVariable}</span> || 'N/C'}
            />
          </div>
          <div className="f-row gap-2 pl-3 py-1">
            <ThinChip
              style={{ minWidth: 56 }}
              className="f-shrink-0"
              center
              label={`${intl.formatMessage(versioningMessages.versionAbr)} ${
                comparingAssembly?.general?.version + 1
              }`}
            />
            <ThinChip label={<span className="capitalize">{comparingVariable}</span> || 'N/C'} />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default GeneralChangeString;
