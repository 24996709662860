import { CSSProperties } from 'react';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';
import { CUSTOM_FUNCTIONS } from '../../../../constants/CUSTOM_FUNCTIONS';
import { NodeItem } from './NodeItem';
import { useAppSelector } from '../../../../store';
import { EditorMode } from '../../../../constants/EditorMode';

const nodeListStyle: CSSProperties = {
  height: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gridAutoRows: 191,
  columnGap: 16,
  rowGap: 16
};

export function NodeList({
  searchedValue,
  resetSearch
}: {
  searchedValue: string;
  resetSearch?: () => void;
}) {
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const theme = useAppSelector((state) => state.assemblyEditorSlice.theme);

  let constants = [] as any;
  switch (editorMode) {
    case EditorMode.SUA:
    case EditorMode.Reference:
      constants = Object.entries(CUSTOM_NODES);
      constants = constants
        .filter(([key, node]: [any, any]) => {
          if(!!theme && (theme.team === 'marketing' || theme.team === 'cytiva')){
            return true;
          }else{
            const exceptedKeys = ['adapterRmToMtc', 'airTrap', 'needlessClaveSamplePort']
            return !exceptedKeys.includes(key);
          }
        })
        .filter(([key, node]) => !node.hideInLibrary)
        .sort((a, b) => {
          if (a[1].name > b[1].name) {
            return 1;
          } else if (a[1].name === b[1].name) {
            return 0;
          } else {
            return -1;
          }
        })
        .map((nodeObject: any) => {
          const [key, node] = nodeObject;
          return {
            key,
            type: 'generic',
            node
          };
        });
      break;
    case EditorMode.UnitOperation:
      constants = Object.entries(CUSTOM_FUNCTIONS);
      constants = constants
        .sort((a, b) => {
          if (a[1].name > b[1].name) {
            return 1;
          } else if (a[1].name === b[1].name) {
            return 0;
          } else {
            return -1;
          }
        })
        .map((nodeObject: any) => {
          const [key, node] = nodeObject;
          return {
            key,
            type: 'function',
            node
          };
        });
      break;
  }

  return (
    <div className="p-2 f-full overflow-y-auto" style={nodeListStyle}>
      {constants
        .filter((constant: any) => {
          if (constant.node.name === 'Transferring' || constant.node.name === 'Purification') {
            return false;
          } else {
            return constant.node.name.toLowerCase().includes(searchedValue.toLowerCase());
          }
        })
        .map((constant: any) => {
          const { key, node } = constant;
          return (
            <NodeItem
              key={key}
              keyName={key}
              node={node}
              constant={constant}
              component={null}
              isCustomComponent={false}
              isShowingCustomLibrary={false}
              onDeleteCustomComponent={null}
              resetSearch={resetSearch}
            />
          );
        })}
    </div>
  );
}
