import { useAppSelector } from '../../store';

import ComponentsList from '../ComponentsLibrairy/ComponentsList';
import CustomComponentList from '../ComponentsLibrairy/CustomComponentList';

const ComponentsContainer = (props) => {
  const libComponentName = useAppSelector((state) => state.assemblyEditorSlice.libComponentName);

  const renderContent = () => {
    if (libComponentName) {
      return <CustomComponentList />;
    } else {
      return <ComponentsList searchedValue={props.search} />;
    }
  };

  return <div className="f-col f-full">{renderContent()}</div>;
};

export default ComponentsContainer;
