import { CustomIcon, ThinChip } from 'hakobio-react-ui';
import { cloneDeep, isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generalMessages, versioningMessages } from '../../lang/messages';
import { useAppSelector } from '../../store';
import * as _ from 'lodash';
import { CUSTOM_NODES } from '../../constants/CUSTOM_NODES';

let differences = [];

const GeneralChangeComponent = ({
  title,
  viewingComponents,
  comparingComponents,
  setHasDifferences
}) => {
  const viewingAssembly = useAppSelector((state) => state.historySlice.viewingAssembly);
  const comparingAssembly = useAppSelector((state) => state.historySlice.comparingAssembly);
  const [_differences, setDifferences] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    differences = [];
    getDifferences(viewingComponents, comparingComponents);
  }, [viewingComponents, comparingComponents]);

  function getDifferences(viewingAComponents, comparingAComponents) {
    let deletedElements = cloneDeep(viewingAssembly.components);
    for (const component of comparingAComponents) {
      if (!viewingAComponents.find((c) => c.id === component.id)) {
        if (
          viewingAssembly.general.version + 1 < comparingAssembly.general.version + 1 &&
          component.data.type !== 'tubing'
        ) {
          const added = { addedName: component.data.type };
          differences.push(added);
        } else if (
          viewingAssembly.general.version + 1 > comparingAssembly.general.version + 1 &&
          component.data.type !== 'tubing'
        ) {
          const notExisted = { notExistInPreviousVersion: component.data.type };
          differences.push(notExisted);
        }
      }

      const elem = deletedElements.find((c) => c.id === component.id);
      if (elem) {
        deletedElements.splice(deletedElements.indexOf(elem), 1);
      }
    }
    deletedElements.forEach((element) => {
      if (
        element.data.type !== 'tubing' &&
        viewingAssembly?.general?.version + 1 < comparingAssembly?.general?.version + 1
      ) {
        const deleted = { deletedName: element.data.type };
        differences.push(deleted);
      } else if (
        element.data.type !== 'tubing' &&
        viewingAssembly?.general?.version + 1 > comparingAssembly?.general?.version + 1
      ) {
        const addedInLatterVesion = { addedInLatterVesion: element.data.type };
        differences.push(addedInLatterVesion);
      }
    });

    if (differences.length !== 0) {
      setDifferences(differences);
      setHasDifferences(true);
    }
    return differences;
  }

  function renderVersion(isLatestVersion: boolean) {
    return (
      <ThinChip
        style={{ minWidth: 56 }}
        className="f-shrink-0"
        center
        color={isLatestVersion ? 'black' : 'var(--primaryColor)'}
        backgroundColor={isLatestVersion ? 'var(--light-grey)' : 'var(--primaryColorBcg)'}
        label={
          isLatestVersion
            ? `${intl.formatMessage(versioningMessages.versionAbr)} ${
                comparingAssembly?.general?.version + 1
              }`
            : `${intl.formatMessage(versioningMessages.versionAbr)} ${
                viewingAssembly?.general?.version + 1
              }`
        }
      />
    );
  }

  if (!isEqual(viewingComponents, comparingComponents) && _differences?.length) {
    return (
      <div className="p-2">
        <div className="font-regular">{title}</div>
        {_differences?.length &&
          _differences.map((difference, index) => {
            return (
              <div className="hover-item" key={`differnec-${index}`}>
                <div className="f-col f2-start gap-2 pl-3 py-1">
                  {difference.addedName ? (
                    <div className="py-1 f-col gap-2">
                      <div className="f-row f2-start gap-2">
                        {renderVersion(false)}
                        <ThinChip
                          color={'var(--primaryColor)'}
                          backgroundColor={'var(--primaryColorBcg)'}
                          label={<span>{intl.formatMessage(generalMessages.none)}</span> || 'N/C'}
                          title={intl.formatMessage(versioningMessages.componentNotExisting, {
                            name: CUSTOM_NODES[difference.addedName].name,
                            version: intl.formatMessage(versioningMessages.versionAbr),
                            number: viewingAssembly?.general?.version + 1
                          })}
                        />
                      </div>
                      <div className="f-row f2-start gap-2">
                        {renderVersion(true)}
                        <ThinChip
                          label={
                            (
                              <span className="font-regular">
                                &#43; {CUSTOM_NODES[difference.addedName].name}
                              </span>
                            ) || 'N/C'
                          }
                          title={intl.formatMessage(versioningMessages.componentAdded, {
                            name: CUSTOM_NODES[difference.addedName].name,
                            version: intl.formatMessage(versioningMessages.versionAbr),
                            number: comparingAssembly?.general?.version + 1
                          })}
                        />
                      </div>
                    </div>
                  ) : difference.deletedName ? (
                    <div className="py-1 f-col gap-2">
                      <div className="f-row f2-start gap-2">
                        {renderVersion(false)}
                        <ThinChip
                          color={'var(--primaryColor)'}
                          backgroundColor={'var(--primaryColorBcg)'}
                          label={<span>{CUSTOM_NODES[difference.deletedName].name}</span> || 'N/C'}
                        />
                      </div>
                      <div className="f-row f2-start gap-2">
                        {renderVersion(true)}
                        <ThinChip
                          label={<s>{CUSTOM_NODES[difference.deletedName].name}</s> || 'N/C'}
                          title={intl.formatMessage(versioningMessages.componentDeleted, {
                            name: CUSTOM_NODES[difference.deletedName].name,
                            version: intl.formatMessage(versioningMessages.versionAbr),
                            number: comparingAssembly?.general?.version + 1
                          })}
                        />
                      </div>
                    </div>
                  ) : difference.modifiedNewName ? (
                    <div className="py-1 f-col gap-2">
                      <div className="f-row f2-start gap-2">
                        {renderVersion(false)}
                        <ThinChip
                          color={'var(--primaryColor)'}
                          backgroundColor={'var(--primaryColorBcg)'}
                          label={
                            <span>{CUSTOM_NODES[difference.modifiedOldName].name}</span> || 'N/C'
                          }
                        />
                      </div>
                      <div className="f-row f2-start gap-2">
                        {renderVersion(true)}
                        <ThinChip
                          label={
                            <span>{CUSTOM_NODES[difference.modifiedNewName].name}</span> || 'N/C'
                          }
                        />
                      </div>
                    </div>
                  ) : difference.notExistInPreviousVersion ? (
                    <div className="py-1 f-col gap-2">
                      <div className="f-row f2-start gap-2">
                        {renderVersion(false)}
                        <ThinChip
                          color={'var(--primaryColor)'}
                          backgroundColor={'var(--primaryColorBcg)'}
                          label={
                            <s>{CUSTOM_NODES[difference.notExistInPreviousVersion].name}</s> ||
                            'N/C'
                          }
                          title={intl.formatMessage(versioningMessages.componentDeleted, {
                            name: CUSTOM_NODES[difference.notExistInPreviousVersion].name,
                            version: intl.formatMessage(versioningMessages.versionAbr),
                            number: viewingAssembly?.general?.version + 1
                          })}
                        />
                      </div>
                      <div className="f-row f2-start gap-2">
                        {renderVersion(true)}
                        <ThinChip
                          label={
                            (
                              <span>{CUSTOM_NODES[difference.notExistInPreviousVersion].name}</span>
                            ) || 'N/C'
                          }
                        />
                      </div>
                    </div>
                  ) : difference.addedInLatterVesion ? (
                    <div className="py-1 f-col gap-2">
                      <div className="f-row f2-start gap-2">
                        {renderVersion(false)}
                        <ThinChip
                          color={'var(--primaryColor)'}
                          backgroundColor={'var(--primaryColorBcg)'}
                          label={
                            (
                              <span className="font-regular">
                                &#43; {CUSTOM_NODES[difference.addedInLatterVesion].name}
                              </span>
                            ) || 'N/C'
                          }
                          title={intl.formatMessage(versioningMessages.componentAdded, {
                            name: CUSTOM_NODES[difference.addedInLatterVesion].name,
                            version: intl.formatMessage(versioningMessages.versionAbr),
                            number: viewingAssembly?.general?.version + 1
                          })}
                        />
                      </div>
                      <div className="f-row f2-start gap-2">
                        {renderVersion(true)}
                        <ThinChip
                          label={
                            (
                              <span className="frozen-grey">
                                {intl.formatMessage(generalMessages.none)}
                              </span>
                            ) || 'N/C'
                          }
                          title={intl.formatMessage(versioningMessages.componentNotExisting, {
                            name: CUSTOM_NODES[difference.addedInLatterVesion].name,
                            version: intl.formatMessage(versioningMessages.versionAbr),
                            number: comparingAssembly?.general?.version + 1
                          })}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  } else {
    return null;
  }
};

export default GeneralChangeComponent;
