const CameraPresets = {
  initial: {
    position: {
      x: 0,
      y: 0,
      z: 0
    },
    rotation: {
      x: 30,
      y: 45
    },
    showMap: false,
    rotate: false,
    zoom: 10,
    transition: 1
  },
  twoD: {
    rotation: {
      x: 90,
      y: 0
    },
    showMap: true,
    rotate: false,
    transition: 1
  },
  threeD: {
    rotation: {
      x: 30,
      y: 45
    },
    showMap: false,
    rotate: false,
    transition: 1
  },
  admin3D: {
    perspective: true,
    transition: 1
  }
};

export default CameraPresets;
