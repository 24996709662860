import { Button } from 'hakobio-react-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import { CUSTOM_NODES } from '../../constants/CUSTOM_NODES';
import { useEffect, useState } from 'react';
import { CUSTOM_FUNCTIONS } from '../../constants/CUSTOM_FUNCTIONS';

const ExportDataButton = () => {
  const ExcelJS = require('exceljs');
  const intl = useIntl();
  const nodes = CUSTOM_NODES;
  const nodesfunctions = CUSTOM_FUNCTIONS;
  const [_rowsComponent, setRowsComonent] = useState([]);
  const [_rowsAnchor, setRowsAnchor] = useState([]);
  const [_rowsAnchorDipTubes, setRowsAnchorDipTubes] = useState([]);
  const [_rowsFunction, setRowsFunction] = useState([]);
  // const subSchema = schema.$defs?.[typeItem];

  useEffect(() => {
    getFunctions();
    getComponents();
    getAnchors();
    getAnchorsWithDipTubes();
  }, []);

  const onExport = () => {
    let rowNumberComonent = 1;
    let rowNumberAnchor = 1;
    let rowNumberFunction = 1;
    const adjustColumnWidth = (worksheet: any) => {
      worksheet.columns.forEach((column: any) => {
        const lengths = column.values.map((v: any) => v.toString().length);
        const maxLength = Math.max(...lengths.filter((v: any) => typeof v === 'number'));
        column.width = maxLength * 1.2;
      });
    };
    const workbook = new ExcelJS.Workbook();
    const components = workbook.addWorksheet('Components');
    const anchors = workbook.addWorksheet('Anchors');
    const functions = workbook.addWorksheet('Functions');
    components.insertRow(rowNumberComonent++, [
      'Composants',
      'Taille dans editeur',
      'Attributs',
      'Type de valeurs',
      'Valeurs',
      'Unitée'
    ]);
    anchors.insertRow(rowNumberAnchor++, [
      'Composants',
      'Attributs',
      'Type de valeurs',
      'Valeurs',
      'Unitée'
    ]);
    functions.insertRow(rowNumberFunction++, [
      'Composants',
      'Attributs',
      'Type de valeurs',
      'Valeurs',
      'Unitée'
    ]);

    components.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ADD8E6' }
    };

    anchors.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ADD8E6' }
    };

    functions.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ADD8E6' }
    };

    _rowsFunction.sort().forEach((el, index) => {
      functions.insertRow(rowNumberFunction++, _rowsFunction[index]);
    });

    _rowsAnchor.forEach((el, index) => {
      anchors.insertRow(rowNumberAnchor++, _rowsAnchor[index]);
    });

    _rowsAnchorDipTubes.forEach((el, index) => {
      anchors.insertRow(rowNumberAnchor++, _rowsAnchorDipTubes[index]);
    });

    _rowsComponent.sort().forEach((el, index) => {
      components.insertRow(rowNumberComonent++, _rowsComponent[index]);
    });

    adjustColumnWidth(components);
    adjustColumnWidth(anchors);
    adjustColumnWidth(functions);
    workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, 'Components and Functions data' + '.xlsx');
    });
  };
  const getAnchors = () => {
    let dataComonents = [];
    const schemaHeader = CUSTOM_NODES['suPumpHead'];
    const getSchema = async () => {
      const schema = await schemaHeader.schema();
      Object.entries(schema.$defs).forEach(([key, value]) => {
        //@ts-ignore
        Object.entries(value.properties).forEach((value, k) => {
          //@ts-ignore
          let row = [];
          Object.entries(value[1]).forEach((v) => {
            //@ts-ignore
            if (v[0] === 'enum') {
              if (key === 'anchor') key = 'FluidTransfert';
              row.push(
                key.charAt(0).toLocaleUpperCase() +
                  key
                    .slice(1)
                    .replace(/([A-Z])/g, ' $1')
                    .trim() +
                  ' (All components)'
              );
              row.push(
                value[0].charAt(0).toLocaleUpperCase() +
                  value[0]
                    .slice(1)
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
              );
              row.push('Liste');
              row.push(JSON.stringify(v[1]));
            }
            if (v[0] === 'units') {
              row.push(v[1]);
            }
          });
          dataComonents.push(row);
        });
      });
    };
    getSchema();
    setRowsAnchor(dataComonents);
  };

  const getAnchorsWithDipTubes = () => {
    let dataComonents = [];
    const schemaHeader = CUSTOM_NODES['bottle'];
    const getSchema = async () => {
      const schema = await schemaHeader.schema();
      Object.entries(schema.$defs).forEach(([key, value]) => {
        //@ts-ignore
        Object.entries(value.properties).forEach((value, k) => {
          let row = [];
          //@ts-ignore

          if (value[0].includes('Tubing')) {
            if (key === 'anchor') {
              key = 'Transfer Port';
            }
            row.push(
              key.charAt(0).toLocaleUpperCase() +
                key.slice(1).replace(/([A-Z])/g, ' $1') +
                ' (Bottle and Bioreactor Bag)'
            );
            row.push(
              value[0].charAt(0).toLocaleUpperCase() + value[0].slice(1).replace(/([A-Z])/g, ' $1')
            );

            Object.entries(value[1]).forEach((v) => {
              if (v[0] === 'enum') {
                row.push('Liste');
                row.push(JSON.stringify(v[1]));
              }
              if (v[0] === 'slider') {
                row.push('Valeur quantitative');
                row.push('Plage autorisée : ' + JSON.stringify(v[1]));
              }
              if (v[0] === 'units') {
                row.push(v[1]);
              }
            });
            dataComonents.push(row);
          }
        });
      });
    };
    getSchema();
    setRowsAnchorDipTubes(dataComonents);
  };

  const getComponents = () => {
    let dataComonents = [];
    Object.entries(nodes).forEach(([subSchemaKey, subSchemaValue]: any) => {
      const schemaHeader = CUSTOM_NODES[subSchemaKey];
      let schema = null;
      const getSchema = async () => {
        schema = await schemaHeader.schema();
        Object.entries(schema).forEach(([subSchemaK, subSchemaVal]: any) => {
          if (subSchemaVal === 'bottleCap') {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              '',
              '',
              '',
              ''
            ]);
          }
          if (subSchemaVal.resin) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.resin.name.charAt(0).toUpperCase() + subSchemaVal.resin.name.slice(1),
              'Text',
              '',
              ''
            ]);
          }
          if (subSchemaVal.componentHeigth) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentHeigth.name.charAt(0).toUpperCase() +
                subSchemaVal.componentHeigth.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentHeigth.slider[0] +
                '-' +
                subSchemaVal.componentHeigth.slider[1],
              subSchemaVal.componentHeigth.units
            ]);
          }
          if (subSchemaVal.componentDiameter) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentDiameter.name.charAt(0).toUpperCase() +
                subSchemaVal.componentDiameter.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentDiameter.slider[0] +
                '-' +
                subSchemaVal.componentDiameter.slider[1],
              subSchemaVal.componentDiameter.units
            ]);
          }
          if (subSchemaVal.componentVolume) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentVolume.name.charAt(0).toUpperCase() +
                subSchemaVal.componentVolume.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentVolume.slider[0] +
                '-' +
                subSchemaVal.componentVolume.slider[1],
              subSchemaVal.componentVolume.units
            ]);
          }
          if (subSchemaVal.componentStirring) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentStirring.name.charAt(0).toUpperCase() +
                subSchemaVal.componentStirring.name.slice(1),
              'Liste',
              subSchemaVal.componentStirring.enum,
              ''
            ]);
          }
          if (subSchemaVal.componentProductContactMaterial) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentProductContactMaterial.name.charAt(0).toUpperCase() +
                subSchemaVal.componentProductContactMaterial.name.slice(1),
              'Liste',
              subSchemaVal.componentProductContactMaterial.enum,
              ''
            ]);
          }
          if (subSchemaVal.componentMembraneMaterial) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentMembraneMaterial.name.charAt(0).toUpperCase() +
                subSchemaVal.componentMembraneMaterial.name.slice(1),
              'Liste',
              subSchemaVal.componentMembraneMaterial.enum,
              ''
            ]);
          }
          if (subSchemaVal.componentPortSize) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentPortSize.name.charAt(0).toUpperCase() +
                subSchemaVal.componentPortSize.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentPortSize.slider[0] +
                '-' +
                subSchemaVal.componentPortSize.slider[1],
              subSchemaVal.componentPortSize.units
            ]);
          }
          if (subSchemaVal.componentSurface) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentSurface.name.charAt(0).toUpperCase() +
                subSchemaVal.componentSurface.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentSurface.slider[0] +
                '-' +
                subSchemaVal.componentSurface.slider[1],
              subSchemaVal.componentSurface.units
            ]);
          }
          if (subSchemaVal.componentLength) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentLength.name.charAt(0).toUpperCase() +
                subSchemaVal.componentLength.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentLength.slider[0] +
                '-' +
                subSchemaVal.componentLength.slider[1],
              subSchemaVal.componentLength.units
            ]);
          }
          if (subSchemaVal.componentType) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentType.name.charAt(0).toUpperCase() +
                subSchemaVal.componentType.name.slice(1),
              'Liste',
              subSchemaVal.componentType.enum,
              ''
            ]);
          }
          if (subSchemaVal.componentModel) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentModel.name.charAt(0).toUpperCase() +
                subSchemaVal.componentModel.name.slice(1),
              'Liste',
              subSchemaVal.componentModel.enum,
              ''
            ]);
          }
          if (subSchemaVal.componentOuterDiameter) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentOuterDiameter.name.charAt(0).toUpperCase() +
                subSchemaVal.componentOuterDiameter.name.slice(1),
              'Valeur quantitative',
              subSchemaVal.componentOuterDiameter.enum,
              subSchemaVal.componentOuterDiameter.units
            ]);
          }
          if (subSchemaVal.componentInnerDiameter) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentInnerDiameter.name.charAt(0).toUpperCase() +
                subSchemaVal.componentInnerDiameter.name.slice(1),
              'Valeur quantitative',
              subSchemaVal.componentInnerDiameter.enum,
              subSchemaVal.componentInnerDiameter.units
            ]);
          }
          if (subSchemaVal.componentWall) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentWall.name.charAt(0).toUpperCase() +
                subSchemaVal.componentWall.name.slice(1),
              'Valeur quantitative',
              subSchemaVal.componentWall.enum,
              subSchemaVal.componentWall.units
            ]);
          }
          if (subSchemaVal.componentMaterial) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentMaterial.name.charAt(0).toUpperCase() +
                subSchemaVal.componentMaterial.name.slice(1),
              'Liste',
              subSchemaVal.componentMaterial.enum,
              ''
            ]);
          }
          if (subSchemaVal.componentMaxOuterDiameter) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentMaxOuterDiameter.name.charAt(0).toUpperCase() +
                subSchemaVal.componentMaxOuterDiameter.name.slice(1),
              'Valeur quantitative',
              subSchemaVal.componentMaxOuterDiameter.enum,
              subSchemaVal.componentMaxOuterDiameter.units
            ]);
          }
          if (subSchemaVal.componentMinOuterDiameter) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentMinOuterDiameter.name.charAt(0).toUpperCase() +
                subSchemaVal.componentMinOuterDiameter.name.slice(1),
              'Valeur quantitative',
              subSchemaVal.componentMinOuterDiameter.enum,
              subSchemaVal.componentMinOuterDiameter.units
            ]);
          }
          if (subSchemaVal.componentInterfaceGender) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentInterfaceGender.name.charAt(0).toUpperCase() +
                subSchemaVal.componentInterfaceGender.name.slice(1),
              'Liste',
              subSchemaVal.componentInterfaceGender.enum,
              ''
            ]);
          }
          if (subSchemaVal.componentInterfaceSize) {
            dataComonents.push([
              schemaHeader.name,
              calculateSize(schemaHeader.size),
              subSchemaVal.componentInterfaceSize.name.charAt(0).toUpperCase() +
                subSchemaVal.componentInterfaceSize.name.slice(1),
              'Liste',
              subSchemaVal.componentInterfaceSize.enum,
              ''
            ]);
          }
        });
      };
      getSchema();
    });
    setRowsComonent(dataComonents);
  };

  const getFunctions = () => {
    let dataFunctions = [];
    Object.entries(nodesfunctions).forEach(([schemaKey, subSchemaValue]: any, index) => {
      const schemaHeader = CUSTOM_FUNCTIONS[schemaKey];
      let schema = null;
      const getSchemaFunction = async () => {
        schema = await schemaHeader.schema();
        Object.entries(schema).forEach(([subSchemaK, subSchemaVal]: any) => {
          if (subSchemaVal.componentVolume) {
            dataFunctions.push([
              schemaHeader.name,
              subSchemaVal.componentVolume.name.charAt(0).toUpperCase() +
                subSchemaVal.componentVolume.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentVolume.slider[0] +
                '-' +
                subSchemaVal.componentVolume.slider[1],
              subSchemaVal.componentVolume.units
            ]);
          }
          if (subSchemaVal.name) {
            dataFunctions.push([schemaHeader.name, 'Name', 'Entrée texte', '', '']);
          }
          if (subSchemaVal.componentFlowRate) {
            dataFunctions.push([
              schemaHeader.name,
              subSchemaVal.componentFlowRate.name.charAt(0).toUpperCase() +
                subSchemaVal.componentFlowRate.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentFlowRate.range[0] +
                '-' +
                subSchemaVal.componentFlowRate.range[1],
              subSchemaVal.componentFlowRate.units
            ]);
          }
          if (subSchemaVal.componentPressure) {
            dataFunctions.push([
              schemaHeader.name,
              subSchemaVal.componentPressure.name.charAt(0).toUpperCase() +
                subSchemaVal.componentPressure.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentPressure.slider[0] +
                '-' +
                subSchemaVal.componentPressure.slider[1],
              subSchemaVal.componentPressure.units
            ]);
          }
          if (subSchemaVal.componentType) {
            dataFunctions.push([
              schemaHeader.name,
              subSchemaVal.componentType.name.charAt(0).toUpperCase() +
                subSchemaVal.componentType.name.slice(1),
              'Liste',
              subSchemaVal.componentType.enum,
              ''
            ]);
          }
          if (subSchemaVal.componentSurface) {
            dataFunctions.push([
              schemaHeader.name,
              subSchemaVal.componentSurface.name.charAt(0).toUpperCase() +
                subSchemaVal.componentSurface.name.slice(1),
              'Valeur quantitative',
              'Plage autorisée : ' +
                subSchemaVal.componentSurface.slider[0] +
                '-' +
                subSchemaVal.componentSurface.slider[1],
              subSchemaVal.componentSurface.units
            ]);
          }
        });
      };
      getSchemaFunction();
    });
    setRowsFunction(dataFunctions);
  };

  const calculateSize = (size: number) => {
    if (size) {
      switch (size) {
        case 40:
          return 'S';
        case 60:
          return 'S';
        case 80:
          return 'M';
        case 120:
          return 'L';
      }
    } else {
      return '-';
    }
  };

  return (
    <Button
      width={'200px'}
      title={intl.formatMessage({
        id: 'SUConfig.StartupPanel.Button.Export',
        defaultMessage: 'Export'
      })}
      onClick={onExport}>
      <FormattedMessage id="SUConfig.StartupPanel.Button.Export" defaultMessage="Export" />
    </Button>
  );
};
export default ExportDataButton;
