import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import historySlice from "./features/history/historySlice";
import modeViewSlice from "./features/modeView/modeViewSlice";
import {assemblyEditorSliceReducer as assemblyEditorSlice} from "./features/assemblyEditor/assemblyEditorSlice";

export const store = configureStore({
  reducer: {
    modeViewSlice,
    assemblyEditorSlice,
    historySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
