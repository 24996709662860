import { Modal, SmallButton } from "hakobio-react-ui";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { buttonSaveStyle } from "../components/EditorPanel/style/styles";
import { DirectoryFolder } from "../components/models/DirectoryFolder";
import { useAppDispatch, useAppSelector } from "../store";
import { assemblyEditorSliceActions } from "../store/features/assemblyEditor/assemblyEditorSlice";
import { EntityToDeleteTypes } from "./enum/EntityToDeleteType";

interface Props {
  confirmFolderRemoved: Function;
}

const DeleteFolderModal = ({ confirmFolderRemoved }: Props) => {
  const [_isDelete, setIsDelete] = useState(false);
  const dispatch = useAppDispatch();
  const folder = useAppSelector(
    (state) => state.assemblyEditorSlice.showDeleteFolderModal,
  );

  const onCloseModal = () => {
    dispatch(assemblyEditorSliceActions.setShowDeleteFolderModal(null));
  };

  const onDelete = async () => {
    setIsDelete(true);

    confirmFolderRemoved(folder);
  };

  const renderFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "5px",
        }}
      >
        <SmallButton
          width={"150px"}
          color={"var(--red)"}
          style={buttonSaveStyle}
          onClick={onDelete}
          loading={_isDelete}
        >
          <FormattedMessage
            id="SUConfigView.DeleteModal.Delete"
            defaultMessage="Delete"
          />
        </SmallButton>
      </div>
    );
  };

  const renderModalBody = () => {
    let body: any = "";
    body = (
      <FormattedMessage
        id="SUConfigView.DeleteModal.AreYouSureYouWantToDeleteThisFolder"
        defaultMessage="Are you sure you want to delete this folder <strong>{name}</strong>?"
        values={{
          name: folder.name,
          strong: (str) => <span className="font-regular">{str}</span>,
        }}
      />
    );
    return body;
    // }
  };

  const title = (
    <FormattedMessage
      id="DeleteModal.ConfirmationDelete"
      defaultMessage="Delete Folder"
    />
  );

  return (
    <Modal title={title} cancel={onCloseModal} footer={renderFooter()}>
      {renderModalBody()}
    </Modal>
  );
};

export default DeleteFolderModal;
