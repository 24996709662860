import { RuleComponentEdgeComponent } from '../RuleComponentEdgeComponent';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';

export class RulePlugTubingWeldable extends RuleComponentEdgeComponent {
  schemaComponentSourceList = ['plug'];
  schemaComponentTargetList = ['plug'];
  schemaTubeList = ['tubing'];
  editorList = ['SUE', 'UOE_PID'];

  checkPrerequisite = () => {
    const isTube = this.schemaTubeList.includes(this.edge.data.type);
    const isSourcePlug = this.componentSource ? this.schemaComponentSourceList.includes(this.componentSource.data.type) : false;
    const isTargetPlug = this.componentTarget ? this.schemaComponentTargetList.includes(this.componentTarget.data.type) : false;
    if (isTube && isSourcePlug) {
      return true;
    }
    if (isTube && isTargetPlug) {
      return true;
    }
    return false;
  };

  checkRule = () => {
    if (
      this.edge.type === 'genericedge' &&
      this.edge.data.componentType !== 'Weldable' &&
      this.componentSource.data.componentType === 'plug'
    ) {
      this.errorList.push({
        id: uuidv4(),
        name: "Incompatible tube type",
        errors:
          'Impossible to connect a(n) ' +
          ` ${CUSTOM_NODES[this.componentSource.data.type].name} ` +
          ' with a(n) ' +
          ` ${this.edge.data.componentType} ` +
          ' tube. ',
        componentAnchors: [
          // @ts-ignore
          { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id }
        ]
      });
    }

    if (
      this.edge.type === 'genericedge' &&
      this.edge.data.componentType !== 'Weldable' &&
      this.componentTarget.data.componentType === 'plug'
    ) {
      this.errorList.push({
        id: uuidv4(),
        name: "Incompatible tube type",
        errors:
          'Impossible to connect a(n) ' +
          ` ${CUSTOM_NODES[this.componentTarget.data.type].name} ` +
          ' with a(n) ' +
          ` ${this.edge.data.componentType} ` +
          ' tube. ',
        componentAnchors: [
          // @ts-ignore
          { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
        ]
      });
    }
  };
}