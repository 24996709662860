import { EditorMode } from '../../constants/EditorMode';
import { UnitOperationLayer } from '../../constants/PFD_EquipmentTabs';
import { tubingColors } from '../../utilities/tubingColors';

export const getTubeColorByLayerAndEditor = (type, componentField, editorMode, layerMode) => {
    switch (editorMode) {
        case EditorMode.Component:
        case EditorMode.SUA:
        case EditorMode.Reference:
            switch (type) {
                case 'Standard':
                    return tubingColors.pink;
                case 'High pressure':
                    return tubingColors.blue;
                case 'Pump dedicated':
                    return tubingColors.green;
                case 'Weldable':
                    return tubingColors.purple;
                default:
                    return 'black';
            }
        case EditorMode.UnitOperation:
            switch (layerMode) {
                case UnitOperationLayer.PnID:
                    switch (type) {
                        case 'Standard':
                            return tubingColors.pink;
                        case 'High pressure':
                            return tubingColors.blue;
                        case 'Pump dedicated':
                            return tubingColors.green;
                        case 'Weldable':
                            return tubingColors.purple;
                        default:
                            return 'black';
                    }
                case UnitOperationLayer.Reference:
                    switch (componentField) {
                        case 'assemblyReference':
                            switch (type) {
                                case 'Standard':
                                    return tubingColors.pink;
                                case 'High pressure':
                                    return tubingColors.blue;
                                case 'Pump dedicated':
                                    return tubingColors.green;
                                case 'Weldable':
                                    return tubingColors.purple;
                                default:
                                    return 'black';
                            }
                        case 'assembly':
                            switch (type) {
                                case 'Standard':
                                    return tubingColors.lightPink;
                                case 'High pressure':
                                    return tubingColors.lightBlue;
                                case 'Pump dedicated':
                                    return tubingColors.lightGreen;
                                case 'Weldable':
                                    return tubingColors.lightPurple;
                                default:
                                    return 'black';
                            }
                    }
            }
    }
};