import { memo, useEffect, useState } from 'react';
import { ThinChip } from 'hakobio-react-ui';
import { getColors } from '../../../utilities';
import Fraction from 'fraction.js';
import { CUSTOM_NODES } from '../../../constants/CUSTOM_NODES';
import { CUSTOM_FUNCTIONS } from '../../../constants/CUSTOM_FUNCTIONS';
import { generalMessages } from '../../../lang/messages';
import { useIntl } from 'react-intl';

interface Props {
  edge: any;
}

const EdgeInfoTooltip = ({ edge }: Props) => {
  const [_schema, setSchema] = useState(null);
  const { primaryColor, primaryColorAlpha } = getColors();

  const intl = useIntl()

  const getSchemaAsync = async () => {
    setSchema(null);
    const type = edge.data.type || 'tubing';
    try {
      let importedValue = null;
      switch (edge.type) {
        case 'generic':
        case 'genericonedge':
        case 'genericedge':
          importedValue = await CUSTOM_NODES[type].schema();
          break;
        case 'function':
        case 'functionedge':
          importedValue = await CUSTOM_FUNCTIONS[type].schema();
          break;
      }
      setSchema(importedValue);
    } catch (error) {
      setSchema(null);
    }
  };

  useEffect(() => {
    getSchemaAsync();
  }, []);

  const getWall = () => {
    if (edge?.data?.componentOuterDiameter && edge?.data?.componentInnerDiameter) {
      const outer = new Fraction(edge?.data?.componentOuterDiameter);
      const inner = new Fraction(edge?.data?.componentInnerDiameter);
      // @ts-ignore
      return new Fraction((outer - inner) / 2).toFraction();
    } else {
      return intl.formatMessage(generalMessages.noValue);
    }
  };

  const render = () => {
    if (!_schema) {
      return null;
    }

    return (
      <div className="px-1">
        <div className="border-bottom pb-2 my-2">Tube information</div>
        {edge?.data &&
          Object.entries(_schema.properties)
            .filter(([key, parameter]) => key !== 'name')
            .map(([key, parameter]: Array<any>) => {
              if (key === 'componentWall') {
                const value = getWall();
                return (
                  <div key={key} className="f-row f2-start gap-2 mb-2">
                    <div
                      className="capitalize"
                      // style={{ color: "black"}}
                    >
                      {parameter.name}
                    </div>
                    <ThinChip
                      style={{ width: 'auto' }}
                      auto
                      fontSize="smallText"
                      color={primaryColor}
                      backgroundColor={primaryColorAlpha}
                      label={
                        parameter.units && value !== undefined && value!=='No value' ? (
                          <span className="capitalize">
                            {value + ' ' + parameter.units || 'N/C'}
                          </span>
                        ) : (
                          <span className="capitalize">{value || 'N/C'}</span>
                        )
                      }
                    />
                  </div>
                );
              }
              if (parameter.type === 'string' || parameter.type === 'number') {
                return (
                  <div key={key} className="f-row f2-start gap-2 mb-2">
                    <div
                      className="capitalize"
                      // style={{ color: "black", width: 100 }}
                    >
                      {parameter.name}
                    </div>
                    <ThinChip
                      style={{ width: 'auto' }}
                      auto
                      fontSize="smallText"
                      color={primaryColor}
                      backgroundColor={primaryColorAlpha}
                      label={
                        parameter.units && edge.data[key] !== undefined ? (
                          <span className="capitalize">
                            {edge.data[key] + ' ' + parameter.units || 'N/C'}
                          </span>
                        ) : (
                          <span className="capitalize">{edge.data[key] || 'N/C'}</span>
                        )
                      }
                    />
                  </div>
                );
              }
            })}
      </div>
    );
  };

  return render();
};

function AreEqual(prevTooltip: any, nextTooltip: any) {
  return (
    JSON.parse(JSON.stringify(prevTooltip.edge)) === JSON.parse(JSON.stringify(nextTooltip.edge))
  );
}

export default memo(EdgeInfoTooltip, AreEqual);
