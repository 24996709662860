import { cloneDeep } from 'lodash';
import { isNode } from 'react-flow-renderer';
import { angle } from '../../../utilities';

export const getElementsSUA = (components, selectedElements) => {
  const elements = components.map((c) => {
  
    if (isNode(c)) {
      if (c.type === 'genericonedge' ) {
        // debugger;
        // @ts-ignore
        const edgeRef = document.getElementById(c.viewer2D.source);
        if (edgeRef !== undefined && edgeRef !== null) {
          // @ts-ignore
          const edgeLength = edgeRef.getTotalLength();
          // @ts-ignore
          let edgeLoc = edgeRef.getPointAtLength(
            // @ts-ignore
            c.viewer2D.position * edgeLength
          );
          // @ts-ignore
          let edgeLoc2 = edgeRef.getPointAtLength(
            // @ts-ignore
            (c.viewer2D.position + 0.02) * edgeLength
          );
          let rotation = angle(edgeLoc.x, edgeLoc.y, edgeLoc2.x, edgeLoc2.y);
          let node = cloneDeep(c) as any;
          node.position = { x: edgeLoc.x - 15, y: edgeLoc.y - 15 };
          node.viewer2D.rotate = rotation;
          node.draggable = false;
          return node;
        } else {
          let node = cloneDeep(c) as any;
          node.position = { x: node.viewer2D.x, y: node.viewer2D.y };
          node.draggable = false;
          return node;
        }
      } else {
        let node = cloneDeep(c) as any;
        node.position = { x: node.viewer2D.x, y: node.viewer2D.y };
        node.selectable = true;
        node.draggable = true;
        return node;
      }
    } else {
      let edge = cloneDeep(c) as any;
      edge.animated = false;
      edge.style = {};
      edge.source = edge.source;
      edge.target = edge.target;
      return edge;
    }
  });

  return elements.filter((e) => e);
};
