import { useStoreActions } from 'react-flow-renderer';
import { store, useAppDispatch } from '../..';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { assemblyEditorSliceActions } from './assemblyEditorSlice';
import { cloneDeep } from 'lodash';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';

export const useLinkEditorAction = () => {
  const dispatch = useAppDispatch();

  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  const joinComponents = (
    value: boolean,
    linkableFunctionEdge: any,
    componentSource: any,
    componentTarget: any
  ) => {
    const state = store.getState();
    const layerMode = state.assemblyEditorSlice.layerMode;
    const components = state.assemblyEditorSlice.components;
    const componentCopy = cloneDeep(linkableFunctionEdge);// functionEdge
    const componentsCopy = cloneDeep(components);
    const componentSourceCopy = cloneDeep(componentSource);// generic component
    
    const functionSourceCopy = componentsCopy.find(c => c.id == componentCopy.source);// function component
    const functionAnchorSourceCopy = functionSourceCopy.data.anchors.find(a => a.id === componentCopy.sourceHandle);
    //id du sourceHandle de functionEdge correspont a l'id du anchor du function component 
    const componentTargetCopy = cloneDeep(componentTarget);// generic component   
    const functionTargetCopy = componentsCopy.find(c => c.id == componentCopy.target);// function component
    const functionAnchorTargetCopy = functionTargetCopy.data.anchors.find(a => a.id === componentCopy.targetHandle);
     //id du targetHandle de functionEdge correspont a l'id du anchor du function component 
    const id = uuidv4();
    let viewerKey = "";
    let idDragKey = "";
    let implementableKey = "";
    let componentLinkKey = "";
    switch (layerMode){
      case UnitOperationLayer.PnID:
        viewerKey = "pid";
        idDragKey = "idDrag";
        implementableKey = "implementable";
        componentLinkKey = "componentLink";
        break;
      case UnitOperationLayer.Reference:
        viewerKey = "reference";
        idDragKey = "idDragReference";
        implementableKey = "implementableReference";
        componentLinkKey = "componentLinkReference";
        break;
    }
    
    if (value) {
      delete componentSourceCopy.data[idDragKey];
      delete componentTargetCopy.data[idDragKey];
      componentCopy.data[implementableKey] = !componentCopy.data[implementableKey];
    } else {
      componentSourceCopy.data[idDragKey] = { id, edge: linkableFunctionEdge.id };
      componentSourceCopy.viewer2D.size = componentSourceCopy.viewer2D.size ?? 80;
      componentTargetCopy.data[idDragKey] = { id, edge: linkableFunctionEdge.id };
      componentTargetCopy.viewer2D.size = componentTargetCopy.viewer2D.size ?? 80;
      let anchorSource : any;     
      
      anchorSource = componentSourceCopy.data.anchors.find(
        (anchor: any) => anchor.id === functionAnchorSourceCopy.data[componentLinkKey].anchor
      );
      if (!anchorSource)
        anchorSource = componentSourceCopy.data.instrumentationPorts?.find(
          (anchor: any) => anchor.id === functionAnchorSourceCopy.data[componentLinkKey].anchor
        );
      if (!anchorSource)
        anchorSource = componentSourceCopy.data.samplingPorts?.find(
          (anchor: any) => anchor.id === functionAnchorSourceCopy.data[componentLinkKey].anchor
        );
      let anchorTarget : any;
      anchorTarget = componentTargetCopy.data.anchors.find(
        (anchor: any) => anchor.id === functionAnchorTargetCopy.data[componentLinkKey].anchor
      );
      if (!anchorTarget)
        anchorTarget = componentTargetCopy.data.instrumentationPorts?.find(
          (anchor: any) => anchor.id === functionAnchorTargetCopy.data[componentLinkKey].anchor
        );
      if (!anchorTarget)
        anchorTarget = componentTargetCopy.data.samplingPorts?.find(
          (anchor: any) => anchor.id === functionAnchorTargetCopy.data[componentLinkKey].anchor
        );
      let anchorSourceX : number;
      let anchorSourceY : number;
      let anchorTargetX : number;
      let anchorTargetY : number;
      switch (componentSourceCopy.viewer2D.rotate) {
        
        case 180:
          anchorSourceX = (1 - anchorSource.viewer2D.left) * componentSourceCopy.viewer2D.size;
          anchorSourceY = (1 - anchorSource.viewer2D.top) * componentSourceCopy.viewer2D.size;
          break;
        case 270:
          anchorSourceX = anchorSource.viewer2D.top * componentSourceCopy.viewer2D.size;
          anchorSourceY = (1 - anchorSource.viewer2D.left) * componentSourceCopy.viewer2D.size;
          break;
        case 90:
          anchorSourceX = (1 - anchorSource.viewer2D.top) * componentSourceCopy.viewer2D.size;
          anchorSourceY = anchorSource.viewer2D.left * componentSourceCopy.viewer2D.size;
          break;
        case 0:
        default:      
          anchorSourceX = anchorSource.viewer2D?.left * componentSourceCopy?.viewer2D?.size;
          anchorSourceY = anchorSource.viewer2D?.top * componentSourceCopy?.viewer2D?.size;
          break;
      }
      switch (componentTargetCopy.viewer2D.rotate) {
        case 180:
          anchorTargetX = (1 - anchorTarget.viewer2D.left) * componentTargetCopy.viewer2D.size;
          anchorTargetY = (1 - anchorTarget.viewer2D.top) * componentTargetCopy.viewer2D.size;
          break;
        case 270:
          anchorTargetX = anchorTarget.viewer2D.top * componentTargetCopy.viewer2D.size;
          anchorTargetY = (1 - anchorTarget.viewer2D.left) * componentTargetCopy.viewer2D.size;
          break;
        case 90:
          anchorTargetX = (1 - anchorTarget.viewer2D.top) * componentTargetCopy.viewer2D.size;
          anchorTargetY = anchorTarget.viewer2D.left * componentTargetCopy.viewer2D.size;
          break;
        case 0:
        default:      
          anchorTargetX = anchorTarget.viewer2D?.left * componentTargetCopy.viewer2D?.size;
          anchorTargetY = anchorTarget.viewer2D?.top * componentTargetCopy.viewer2D?.size;
          break;
      }
      componentTargetCopy.viewer2D[viewerKey].x = componentSourceCopy.viewer2D[viewerKey].x + (anchorSourceX + 4);
      componentTargetCopy.viewer2D[viewerKey].y = componentSourceCopy.viewer2D[viewerKey].y + (anchorSourceY + 4);
      componentTargetCopy.viewer2D[viewerKey].x = componentTargetCopy.viewer2D[viewerKey].x - (anchorTargetX + 4);
      componentTargetCopy.viewer2D[viewerKey].y = componentTargetCopy.viewer2D[viewerKey].y - (anchorTargetY + 4);
      componentCopy.data[implementableKey] = !componentCopy.data[implementableKey];
    }
    dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
    dispatch(assemblyEditorSliceActions.updateComponent(componentSourceCopy));
    dispatch(assemblyEditorSliceActions.updateComponent(componentTargetCopy));
    setSelectedElements([]);
  };

  const joinSUAComponents = (
    value: boolean,
    linkableGenericEdge: any,
    componentSource: any,
    componentTarget: any
  ) => {
    const componentCopy = cloneDeep(linkableGenericEdge);
    const componentSourceCopy = cloneDeep(componentSource);
    const componentTargetCopy = cloneDeep(componentTarget);
    const id = uuidv4();
    if (value) {
      delete componentSourceCopy.data.idDrag;
      delete componentTargetCopy.data.idDrag;
      componentCopy.data.implementable = !componentCopy.data.implementable;
    } else {
      componentSourceCopy.data.idDrag = { id, edge: linkableGenericEdge.id };
      componentSourceCopy.viewer2D.size = componentSourceCopy.viewer2D.size ?? 80;
      componentTargetCopy.data.idDrag = { id, edge: linkableGenericEdge.id };
      componentTargetCopy.viewer2D.size = componentTargetCopy.viewer2D.size ?? 80;
      let anchorSource;
      anchorSource = componentSourceCopy.data.anchors.find(
        (anchor: any) => anchor.id === linkableGenericEdge.sourceHandle
      );
      if (!anchorSource)
        anchorSource = componentSourceCopy.data.instrumentationPorts?.find(
          (anchor: any) => anchor.id === linkableGenericEdge.sourceHandle
        );
      if (!anchorSource)
        anchorSource = componentSourceCopy.data.samplingPorts?.find(
          (anchor: any) => anchor.id === linkableGenericEdge.sourceHandle
        );
      let anchorTarget;
      anchorTarget = componentTargetCopy.data.anchors.find(
        (anchor: any) => anchor.id === linkableGenericEdge.targetHandle
      );
      if (!anchorTarget)
        anchorTarget = componentTargetCopy.data.instrumentationPorts?.find(
          (anchor: any) => anchor.id === linkableGenericEdge.targetHandle
        );
      if (!anchorTarget)
        anchorTarget = componentTargetCopy.data.samplingPorts?.find(
          (anchor: any) => anchor.id === linkableGenericEdge.targetHandle
        );
      let anchorSourceX;
      let anchorSourceY;
      let anchorTargetX;
      let anchorTargetY;
      switch (componentSourceCopy.viewer2D.rotate) {
        case 180:
          anchorSourceX = (1 - anchorSource.viewer2D.left) * componentSourceCopy.viewer2D.size;
          anchorSourceY = (1 - anchorSource.viewer2D.top) * componentSourceCopy.viewer2D.size;
          break;

        case 270:
          anchorSourceX = anchorSource.viewer2D.top * componentSourceCopy.viewer2D.size;
          anchorSourceY = (1 - anchorSource.viewer2D.left) * componentSourceCopy.viewer2D.size;
          break;
        case 90:
          anchorSourceX = (1 - anchorSource.viewer2D.top) * componentSourceCopy.viewer2D.size;
          anchorSourceY = anchorSource.viewer2D.left * componentSourceCopy.viewer2D.size;
          break;
        case 0:
        default:
          anchorSourceX = anchorSource.viewer2D.left * componentSourceCopy.viewer2D.size;
          anchorSourceY = anchorSource.viewer2D.top * componentSourceCopy.viewer2D.size;
          break;
      }

      switch (componentTargetCopy.viewer2D.rotate) {
        case 180:
          anchorTargetX = (1 - anchorTarget.viewer2D.left) * componentTargetCopy.viewer2D.size;
          anchorTargetY = (1 - anchorTarget.viewer2D.top) * componentTargetCopy.viewer2D.size;
          break;

        case 270:
          anchorTargetX = anchorTarget.viewer2D.top * componentTargetCopy.viewer2D.size;
          anchorTargetY = (1 - anchorTarget.viewer2D.left) * componentTargetCopy.viewer2D.size;
          break;
        case 90:
          anchorTargetX = (1 - anchorTarget.viewer2D.top) * componentTargetCopy.viewer2D.size;
          anchorTargetY = anchorTarget.viewer2D.left * componentTargetCopy.viewer2D.size;
          break;
        case 0:
        default:
          anchorTargetX = anchorTarget.viewer2D.left * componentTargetCopy.viewer2D.size;
          anchorTargetY = anchorTarget.viewer2D.top * componentTargetCopy.viewer2D.size;
          break;
      }

      componentTargetCopy.viewer2D.x = componentSourceCopy.viewer2D.x + (anchorSourceX + 4);
      componentTargetCopy.viewer2D.y = componentSourceCopy.viewer2D.y + (anchorSourceY + 4);

      componentTargetCopy.viewer2D.x = componentTargetCopy.viewer2D.x - (anchorTargetX + 4);
      componentTargetCopy.viewer2D.y = componentTargetCopy.viewer2D.y - (anchorTargetY + 4);

      componentCopy.data.implementable = !componentCopy.data.implementable;
    }
    dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
    dispatch(assemblyEditorSliceActions.updateComponent(componentSourceCopy));
    dispatch(assemblyEditorSliceActions.updateComponent(componentTargetCopy));
    setSelectedElements([componentSourceCopy]);
  };

  return {
    joinComponents,
    joinSUAComponents
  };
};
