import { useEffect, useState } from 'react';
import { Icon, ThinChip } from 'hakobio-react-ui';
import { CUSTOM_NODES } from '../../../../../constants/CUSTOM_NODES';
import { getColors } from '../../../../../utilities';

const SingleComponent = ({ component }) => {
  const [_schema, setSchema] = useState(null);

  const { primaryColorAlpha } = getColors();

  const getSchemaAsync = async () => {
    if (component) {
      const type = component.data.type;
      try {
        const componentSchema = await CUSTOM_NODES[type].schema();
        setSchema(componentSchema.properties);
      } catch (error) {
        setSchema(null);
      }
    }
  };

  useEffect(() => {
    getSchemaAsync();
  }, []);

  return (
    <div key={component.id} className="f-col gap-1 mb-2 py-1 ">
      <div className="f-row f2-center gap-1">
        {component.type === 'genericedge' ? (
          <Icon name="tubing" style={{ width: 20 }} />
        ) : (
          <div style={{ height: 20 }}>{CUSTOM_NODES[component.data.type]?.form(20)}</div>
        )}
        <ThinChip
          fontSize="smallText"
          className="capitalize f-full"
          style={{ cursor: 'pointer' }}
          label={
            component.data.customName
              ? `${component.data.customName} | ${CUSTOM_NODES[component.data.type]?.name}`
              : CUSTOM_NODES[component.data.type]?.name
          }
          color={'var(--primaryColor)'}
          backgroundColor={primaryColorAlpha}
        />
      </div>
      <div className="f-wrap f-row gap-2" style={{ paddingLeft: 24 }}>
        {_schema &&
          Object.entries(_schema).map(([prop, propValue]) => {
            const componentHasProp = Object.entries(component.data).find(
              ([key, value]) => prop === key
            );
            if (
              componentHasProp &&
              (/* (component.type === 'generic' &&
                componentHasProp[0] !== 'anchors' &&
                componentHasProp[0] !== 'customName') || */
                (component.type === 'genericedge' &&
                  (componentHasProp[0] === 'componentType' ||
                    componentHasProp[0] === 'componentInnerDiameter' ||
                    componentHasProp[0] === 'componentOuterDiameter')))
            ) {
              return (
                <ThinChip
                  key={componentHasProp.join()}
                  auto
                  fontSize="smallText"
                  className="capitalize"
                  title={_schema[componentHasProp[0]]?.name}
                  label={
                    componentHasProp[1] +
                    (_schema[componentHasProp[0]]?.units
                      ? ` ${_schema[componentHasProp[0]]?.units}`
                      : '')
                  }
                />
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};

export default SingleComponent;
