import React from 'react';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckBox, PanelHeader, Title } from 'hakobio-react-ui';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { withRouter } from 'react-router-dom';
import { ReportData } from '../../../../constants/ReportData';
import ReportPanel from '../../../ReportPanel/ReportPanelUO';
import { generalMessages } from '../../../../lang/messages';
import { UnitOperationLayer } from '../../../../constants/PFD_EquipmentTabs';

const UnitOperationEditorReportPanel = (props) => {
  const dispatch = useAppDispatch();
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const reportData = useAppSelector((state) => state.assemblyEditorSlice.reportData);

  const intl = useIntl();

  let suaList = components
    .filter((c: any) => c.type === 'function')
    .filter((c: any) => c.data?.hasOwnProperty('assembly'));

  let suaListReference = components
    .filter((c: any) => c.type === 'function')
    .filter((c: any) => c.data?.hasOwnProperty('assemblyReference'));

  suaList.sort((c1: any, c2: any) => {
    if (c1.data.assembly.general.name > c2.data.assembly.general.name) {
      return 1;
    } else if (c1.data.assembly.general.name === c2.data.assembly.general.name) {
      return 0;
    } else {
      return -1;
    }
  });

  suaListReference.sort((c1: any, c2: any) => {
    if (c1.data.assemblyReference.general.name > c2.data.assemblyReference.general.name) {
      return 1;
    } else if (c1.data.assemblyReference.general.name === c2.data.assemblyReference.general.name) {
      return 0;
    } else {
      return -1;
    }
  });

  suaList = suaList.filter(function (component) {
    var key = `${component.data?.assembly.general.id}`;
    return !this.has(key) && this.add(key);
  }, new Set());

  suaListReference = suaListReference.filter(function (component) {
    var key = `${component.data?.assemblyReference.general.id}`;
    return !this.has(key) && this.add(key);
  }, new Set());

  const handleChange = (event, key) => {
    dispatch(
      assemblyEditorSliceActions.setReportData({ ...reportData, [key]: event.target.checked })
    );
  };

  const handleChangeAllSUA = (event) => {
    const suaIdList = suaList.map((sua: any) => sua.data.assembly.general.id);
    if (event.target.checked) {
      dispatch(
        assemblyEditorSliceActions.setReportData({
          ...reportData,
          [ReportData.AllSUA]: [...suaIdList]
        })
      );
    } else {
      dispatch(
        assemblyEditorSliceActions.setReportData({ ...reportData, [ReportData.AllSUA]: [] })
      );
    }
  };

  const handleChangeAllReference = (event) => {
    const suaIdListReference = suaListReference.map((sua: any) => sua.data.assemblyReference.general.id);
    if (event.target.checked) {
      dispatch(
        assemblyEditorSliceActions.setReportData({
          ...reportData,
          [ReportData.AllReference]: [...suaIdListReference]
        })
      );
    } else {
      dispatch(
        assemblyEditorSliceActions.setReportData({ ...reportData, [ReportData.AllReference]: [] })
      );
    }
  };

  const handleChangeSUA = (event, id) => {
    let selectedSuas = _.cloneDeep(reportData.allSUA);
    if (event.target.checked) {
      selectedSuas.push(id);
    } else {
      selectedSuas = _.remove(selectedSuas, function (n) {
        return n !== id;
      });
    }
    dispatch(
      assemblyEditorSliceActions.setReportData({
        ...reportData,
        [ReportData.AllSUA]: [...selectedSuas]
      })
    );
  };

  const handleChangeReference = (event, id) => {
    let selectedReferences = _.cloneDeep(reportData.allReference);
    if (event.target.checked) {
      selectedReferences.push(id);
    } else {
      selectedReferences = _.remove(selectedReferences, function (n) {
        return n !== id;
      });
    }
    dispatch(
      assemblyEditorSliceActions.setReportData({
        ...reportData,
        [ReportData.AllReference]: [...selectedReferences]
      })
    );
  }



  // const redirectToReport = () => {
  //   props.history.push('/report/' + general.id);
  // };

  return (
    <div className="f-col f-full h-100  relative h-100">
      <PanelHeader>{intl.formatMessage(generalMessages.report)}</PanelHeader>
      <div className="f-full" style={{ overflowY: 'auto', marginBottom: '4rem' }}>
        <div className="f-col f1-start gap-2 f-full px-2">
          <Title underline="short">
            <FormattedMessage
              id="UOEditorReportPanel.SeenInYourReport"
              defaultMessage="What would you like to be seen in your report?"
            />
          </Title>
          <CheckBox
            onChange={(e: any) => handleChange(e, ReportData.FrontPage)}
            label={'Front page'}
            checked={reportData.frontPage}
          />
          {/* <CheckBox
          onChange={(e: any) => handleChange(e, ReportData.HeaderInformation)}
          label="Header information"
          checked={reportData.headerInformation}
        />
        <CheckBox
          onChange={(e: any) => handleChange(e, ReportData.TableOfContent)}
          label="Table of content"
          checked={reportData.tableOfContent}
        /> */}
          <div className="border-bottom" style={{ width: 35, borderBottomStyle: 'dotted' }} />
          <CheckBox
            onChange={(e: any) => handleChange(e, ReportData.PFDView)}
            label="PFD view"
            checked={reportData.PFDView}
          />
          <CheckBox
            onChange={(e: any) => handleChange(e, ReportData.PIDView)}
            label="P&amp;ID view"
            checked={reportData.PIDView}
          />
          <CheckBox
            onChange={(e: any) => handleChange(e, ReportData.Metrics)}
            label="Metrics"
            checked={reportData.metrics}
          />
          <CheckBox
            onChange={(e: any) => handleChangeAllSUA(e)}
            label="All Single-Use Assemblies"
            checked={reportData.allSUA.length === suaList.length}
            // @ts-ignore
            indeterminate={
              reportData.allSUA.length > 0 && reportData.allSUA.length !== suaList.length
            }
          />
          {suaList.length ? (
            suaList.map((component, index) => {
              return (
                <CheckBox
                  key={component.id}
                  className="pl-4"
                  onChange={(event) => handleChangeSUA(event, component.data.assembly.general.id)}
                  label={component.data.assembly.general.name}
                  checked={reportData.allSUA.includes(component.data.assembly.general.id)}
                />
              );
            })
          ) : (
            <div className="frozen-grey">There is no SUA implemented yet.</div>
          )}

          {suaListReference.length !== 0 && <><CheckBox
            onChange={(e: any) => handleChangeAllReference(e)}
            label="All Single-Use References"
            checked={reportData.allReference.length === suaListReference.length}
            // @ts-ignore
            indeterminate={
              reportData.allReference.length > 0 && reportData.allReference.length !== suaListReference.length
            }
          />
            {suaListReference.length ? (
              suaListReference.map((component, index) => {
                return (
                  <CheckBox
                    key={component.id}
                    className="pl-4"
                    onChange={(event) => handleChangeReference(event, component.data.assemblyReference.general.id)}
                    label={component.data.assemblyReference.general.name}
                    checked={reportData.allReference.includes(component.data.assemblyReference.general.id)}
                  />
                );
              })
            ) : (
              <div className="frozen-grey">There is no reference implemented yet.</div>
            )}</>}
          {/* <div className="border-bottom" style={{ width: 35, borderBottomStyle: 'dotted' }} />
        <CheckBox
          onChange={(e: any) => handleChange(e, ReportData.Analytics)}
          label="Analytics"
          checked={reportData.analytics}
        /> */}
        </div>

        <div style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
          <ReportPanel key={general.id} id={general.id} />
        </div>
        {/* 
      <ButtonFooter onClick={() => redirectToReport()} arrow>
        <div className="f-row gap-2 f1-center f2-center">
          <CustomIcon name="file-1" title="Export PDF" pointer style={{ lineHeight: 0.5 }} />
          <span>{'Export report'}</span>
        </div>
      </ButtonFooter> */}
      </div>
    </div>
  );
};

export default withRouter(UnitOperationEditorReportPanel);
