import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  Card: {
    width: '100%',
    height: '100%',
    backgroundColor: 'var(--primaryColor)',

    marginLeft: 0,
    marginTop: 0,
    transition: 'margin-top 0.3s, margin-left 0.3s',
    border: '1px solid var(--primaryColor)'
  },
  img: {
    overflow: 'hidden',
    maxHeight: 250,
    minHeight: 250,
    cursor: 'pointer',
    objectFit: 'contain',
    backgroundColor: 'rgb(247, 250, 255)'
  },
  titleProject: {
    textTransform: 'capitalize',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'white'
  },
  cardContent: {
    minHeight: 72,
    backgroundColor: 'var(--primaryColor)'
  },
  List: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%'
  },
  headers: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'calc(100% - 187px)',
    marginLeft: '40px'
  },
  test: {
    flex: '1 0 0',
    fontWeight: 600
  },
  headerName: {
    flex: '2 0 0',
    fontWeight: 600
  },
  headerDate: {
    flex: '2 0 0',
    fontWeight: 600
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 2fr 3fr 2fr 2fr',
  },
  deleteBtn: {
    color: 'var(--red)',
    '&:hover': {
      color: 'var(--red) !important',
      backgroundColor: 'var(--red-light) !important'
    }
  },
  infoMenu: {
    right: -25,
    top: '50%',
    marginTop: -70,
    zIndex: 2,
    '& .btn': {
      width: 'auto',
      display: 'flex',
      textTransform: 'capitalize',
      alignItems: 'center',
      gap: '.5rem',
      fontSize: 14,
      padding: '.5rem 1rem',
      borderRadius: '.25rem',
      cursor: 'pointer',
      transition:
        'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out'
    },
    '& .btn-light': {
      backgroundColor: '#f8f9fa',
      borderColor: '#f8f9fa'
    },
    '& .btn-light.focus, .btn-light:focus, .btn-light:hover': {
      backgroundColor: 'var(--light-grey)',
      borderColor: 'var(--primaryColor)'
    },
    '& .btn-dark': {
      color: '#fff',
      backgroundColor: '#565656',
      borderColor: '#565656'
    },
    '& .btn-dark.focus, .btn-dark:focus, .btn-dark:hover': {
      backgroundColor: 'black'
    },
    '& .btn-danger': {
      color: '#fff',
      backgroundColor: 'var(--red)',
      borderColor: 'var(--red)'
    },
    '& .btn-danger.focus, .btn-danger:focus, .btn-danger:hover': {
      color: '#fff',
      backgroundColor: '#c82333',
      borderColor: '#bd2130'
    }
  }
});
