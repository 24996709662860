import { memo, useEffect, useRef, useState } from 'react';
import { getSmoothStepPath, useStoreActions } from 'react-flow-renderer';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { cloneDeep } from 'lodash';

const FunctionEdge = (props: any) => {
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const selectEdges = useAppSelector((state) => state.assemblyEditorSlice.selectedEdges);
  const isSavingScreenshotUOAnnotatedPFD = useAppSelector((state) => state.assemblyEditorSlice.isSavingScreenshotUOAnnotatedPFD);
  const selectedComponents = useAppSelector((state) => state.assemblyEditorSlice.selectedComponents);
  const dispatch = useAppDispatch();
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  const [_tubeIsHover, setTubeHover] = useState(false) as any;
  const [_showTooltip, setShowToolTip] = useState(false) as any;
  const [_edge, setEdge] = useState({}) as any;
  const [_onHover, setOnHover] = useState(false) as any;
  const [markendArrow, setMarkendArrow] = useState(true) as any;

  useEffect(() => {
    let componentSrc = null;
    let componentTrg = null;
    let a = null;
    let b = null;

    const edge = components.find((c) => c.id === id);
    setEdge(edge);
    if (!edge) {
      return;
    }
    componentSrc = components.find((c) => c.id === edge.source);
    // setComponentSource(componentSrc);

    componentTrg = components.find((c) => c.id === edge.target);
    // setComponentTarget(componentTrg);

    if (componentSrc) {
      a = componentSrc.data.anchors.find((a: any) => a.id === edge.sourceHandle);
      if (!a) a = componentSrc.data.instrumentationPorts.find((a: any) => a.id === edge.sourceHandle);
      if (!a) a = componentSrc.data.samplingPorts.find((a: any) => a.id === edge.sourceHandle);
      // setAnchorSource(a);
    }

    if (componentTrg) {
      b = componentTrg.data.anchors.find((a: any) => a.id === edge.targetHandle);
      if (!b) b = componentTrg.data.instrumentationPorts.find((a: any) => a.id === edge.targetHandle);
      if (!b) b = componentTrg.data.samplingPorts.find((a: any) => a.id === edge.targetHandle);
      // setAnchorTarget(b);
    }

    if (markendArrow && a.type === 'neutral' && b.type === 'neutral') {
      setMarkendArrow(false);
      const componentSrcCopy = cloneDeep(componentSrc);
      componentSrcCopy.viewer2D.pfd.x += 0.00000001;
      componentSrcCopy.viewer2D.pid.x += 0.00000001;
      const promiseSrc = { id: componentSrcCopy.id, component: componentSrcCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseSrc));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentSrcCopy));

      const componentTrgCopy = cloneDeep(componentTrg);
      componentTrgCopy.viewer2D.pfd.x += 0.00000001;
      componentTrgCopy.viewer2D.pid.x += 0.00000001;
      const promiseTrg = { id: componentTrgCopy.id, component: componentTrgCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseTrg));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentTrgCopy));
    } else if (!markendArrow && (a.type !== 'neutral' || b.type !== 'neutral')) {
      setMarkendArrow(true);
      const componentSrcCopy = cloneDeep(componentSrc);
      componentSrcCopy.viewer2D.pfd.x += 0.00000001;
      componentSrcCopy.viewer2D.pid.x += 0.00000001;
      const promiseSrc = { id: componentSrcCopy.id, component: componentSrcCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseSrc));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentSrcCopy));

      const componentTrgCopy = cloneDeep(componentTrg);
      componentTrgCopy.viewer2D.pfd.x += 0.00000001;
      componentTrgCopy.viewer2D.pid.x += 0.00000001;
      const promiseTrg = { id: componentTrgCopy.id, component: componentTrgCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseTrg));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentTrgCopy));
    }
  }, []);

  const {
    id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEndId
  } = props;

  const foreignObjectSize = 20;

  const edgeRef = useRef(null);

  const edgePath = getSmoothStepPath({
    sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition
  });
  // @ts-ignore
  const realEdgeLength = edgeRef.current?.getTotalLength();
  // @ts-ignore
  const realCenter = realEdgeLength ? edgeRef.current?.getPointAtLength(0.5 * realEdgeLength) : 0;

  const component = components.find((c) => c.id === id);

  if (!component) {
    return null;
  }

  const handleMouseEnter = () => {
    setTubeHover(true);
    setShowToolTip(true);
  };
  const handleMouseLeave = () => {
    setTubeHover(false);
    setShowToolTip(false);
  };

  const handleClick = () => {
    setSelectedElements([cloneDeep(_edge)]);
    dispatch(assemblyEditorSliceActions.selectComponents([_edge.id]));
  };

  const renderAnnotation = () => {
    switch (editorMode) {
      case EditorMode.SUA:
        return null;
      case EditorMode.Reference:
        return null;
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PFD:
            return null;
          case UnitOperationLayer.PnID:
            return null;
          case UnitOperationLayer.Reference:
            return null;
        }
    }
  };

  const renderPath = () => {
    const pathIsSelected = selectedComponents?.find((sc) => sc === _edge.id);

    switch (editorMode) {
      case EditorMode.SUA:
        return null;
      case EditorMode.Reference:
        return null;
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PFD:
            return (<>
              <path
                ref={edgeRef}
                id={id}
                style={{ ...style, cursor: 'pointer' }}
                d={edgePath}
                /* markerEnd={markerEnd} */
                stroke={component?.errors ? 'var(--red)' : '#909090'}
                strokeWidth={3}
                fill='none'
              />
            </>);
          case UnitOperationLayer.PnID:
            if (!component || component.data.assembly || !component.data.implementable) {
              return null;
            }
            if (selectEdges && selectEdges[0] === component.id) {
              return (<path
                ref={edgeRef}
                id={id}
                style={style}
                d={edgePath}
                /*  markerEnd={markerEnd} */
                /* stroke={component?.errors ? "var(--red)" : "black"} */
                strokeWidth={7}
                fill='none'
              />);
            }
            return (<path
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ref={edgeRef}
              id={id}
              style={{ ...style, cursor: 'pointer' }}
              d={edgePath}
              /*    markerEnd={markerEnd} */
              stroke={_onHover ? 'var(--green)' : component?.errors ? 'var(--red)' : '#909090'}
              strokeWidth={_tubeIsHover ? 8 : pathIsSelected ? 7 : 3}
              fill='none'
            />);
          case UnitOperationLayer.Reference:
            if (!component || component.data.assemblyReference || !component.data.implementableReference) {
              return null;
            }
            if (selectEdges && selectEdges[0] === component.id) {
              return (<path
                ref={edgeRef}
                id={id}
                style={style}
                d={edgePath}
                /*  markerEnd={markerEnd} */
                /* stroke={component?.errors ? "var(--red)" : "black"} */
                strokeWidth={7}
                fill='none'
              />);
            }
            return (<path
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ref={edgeRef}
              id={id}
              style={{ ...style, cursor: 'pointer' }}
              d={edgePath}
              /*    markerEnd={markerEnd} */
              stroke={_onHover ? 'var(--green)' : component?.errors ? 'var(--red)' : '#909090'}
              strokeWidth={_tubeIsHover ? 8 : pathIsSelected ? 7 : 3}
              fill='none'
            />);
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const render = () => {
    return <>{renderPath()}</>;
  };

  return render();
};

export default memo(FunctionEdge);
