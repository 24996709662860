import React, { DragEventHandler, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Connection,
  ConnectionMode,
  Edge,
  Elements,
  OnEdgeUpdateFunc,
  OnLoadFunc,
  OnLoadParams,
  ReactFlowProps,
  useStoreActions,
  useStoreState
} from 'react-flow-renderer';

import { Uuidv4 } from '../../utilities';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../store/features/assemblyEditor/useAssemblyEditorAction';
import { useAppDispatch, useAppSelector } from '../../store';
import { nodeTypes } from './enum/NodeTypes';
import { edgeTypes } from './enum/EdgeTypes';
import _, { cloneDeep } from 'lodash';
import './Flow.css';
import { savePNGAssembly } from '../../services/editor/editorService';
import { useScreenshot } from '../../utilities/useScreenshot';
import { useDeletionEditorAction } from '../../store/features/assemblyEditor/useDeletionEditorAction';
import { v4 as uuidv4 } from 'uuid';
import DeleteModal from '../../views/DeleteModal';
import { getElementsSUA } from './utils/getElementsSUA';
import { EntityToDeleteTypes } from '../../views/enum/EntityToDeleteType';
import { useEditorLibraryAction } from '../../store/features/assemblyEditor/useEditorLibraryAction';
import ReferenceEditionModal from '../EditorPanel/SingleUseEditor/ReferencePanel/ReferenceEditionModal';
import { DocumentTitle } from '../../constants/DocumentTitle';

const FlowEditorReference = (props) => {
  const componentsRef = useRef<any>(null);
  const promiseUpdateComponentRef = useRef<any>(null);
  const { changeSelectionSUA, setHoverOnEdge } = useAssemblyEditorAction();
  const { removeComponents } = useDeletionEditorAction();
  const dispatch = useAppDispatch();
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);

  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);

  const general = useAppSelector((state) => state.assemblyEditorSlice.general);

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);

  const tubingData = useAppSelector((state) => state.assemblyEditorSlice.lastTubingDetails);

  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );

  const promiseUpdateComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.promiseUpdateComponent
  );

  const isSavingScreenshot = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUA
  );

  const isSavingScreenshotAnnotated = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUAAnnotated
  );

  const showDeleteConfirmModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showConfirmDeleteModal
  );

  const showReferenceEditionlModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showReferenceEditionlModal
  );

  const selectedElements = useStoreState((store) => store.selectedElements);
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  componentsRef.current = components;

  promiseUpdateComponentRef.current = promiseUpdateComponent;

  const dragComponentRef = useRef<any | null>(null);

  const dragPositionRef = useRef<any | null>(null);

  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
  const [elements, setElements] = useState<any>([]);
  const [isDragging, setDragging] = useState<boolean>(false);
  const [_isMoving, setisMoving] = useState<boolean>(false);
  const [image, takeScreenshot, clearScreenshot] = useScreenshot(1);
  const [imageAnnotated, takeScreenshotAnnotated, clearScreenshotAnnotated] = useScreenshot(3);

  useEffect(() => {
    window.parent.postMessage({ message: 'message', value: DocumentTitle.ReferenceEditor }, '*');
  }, []);

  useEffect(() => {
    dispatch(assemblyEditorSliceActions.setEditingAssembly(false));
  }, []);

  useEffect(() => {
    const newElements = getElementsSUA(components, selectedElements);
    setElements(_.cloneDeep(newElements));
  }, [components, layerMode]);

  useEffect(() => {
    if (promiseUpdateComponent.length !== 0 && !isDragging) {
      promiseUpdateComponentRef.current.forEach((promise: any) => {
        dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
      });
      dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
    }
  }, [promiseUpdateComponent]);

  useEffect(() => {
    const takeScreenshotAsync = () => {
      reactFlowInstance.fitView({ padding: 0.1, includeHiddenNodes: true });
      setTimeout(() => {
        takeScreenshot(reactFlowWrapper.current);
      }, 1000);
    };
    if (isSavingScreenshot && reactFlowInstance && reactFlowWrapper.current) {
      takeScreenshotAsync();
    }
  }, [isSavingScreenshot]);

  useEffect(() => {
    const takeScreenshotAsync = () => {
      reactFlowInstance.fitView({ padding: 0.1, includeHiddenNodes: true });
      setTimeout(() => {
        takeScreenshotAnnotated(reactFlowWrapper.current);
      }, 1000);
    };
    if (isSavingScreenshotAnnotated && reactFlowInstance && reactFlowWrapper.current) {
      takeScreenshotAsync();
    }
  }, [isSavingScreenshotAnnotated]);

  useEffect(() => {
    if (image) {
      // Example call:
      //var file = dataURLtoFile(image, 'screenshot.png');
      savePNGAssembly(general.id, image);
      clearScreenshot();
      dispatch(assemblyEditorSliceActions.setSavingScreenshotSUA(false));
      dispatch(assemblyEditorSliceActions.setSavingScreenshotSUAAnnotated(true));
    }
  }, [image]);

  useEffect(() => {
    if (imageAnnotated) {
      savePNGAssembly(
        general.id + '/' + general.hashPartitionKey + '_SUA_Annotated',
        imageAnnotated
      );
      clearScreenshotAnnotated();
      dispatch(assemblyEditorSliceActions.setSavingScreenshotSUAAnnotated(false));
      setTimeout(() => {
        dispatch(assemblyEditorSliceActions.setEditingAssembly(false));
      }, 1000);
    }
  }, [imageAnnotated]);

  useEffect(() => {
    window.parent.postMessage({ message: 'deleteCross', value: 'hide' }, '*');
  }, []);

  const onLoad: OnLoadFunc = (_reactFlowInstance) => {
    setTimeout(() => {
      _reactFlowInstance?.fitView({ padding: 0.1, includeHiddenNodes: true });
    }, 250);
    setReactFlowInstance(_reactFlowInstance);
  };

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.key === 'Delete' && selectedComponents?.length > 0) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(
          assemblyEditorSliceActions.setShowDeleteConfirmModal({
            entityId: selectedComponents?.[0].id,
            entityName: selectedComponents?.[0].name,
            type: EntityToDeleteTypes.Function
          })
        );
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [selectedComponents]);

  const onDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    dispatch(assemblyEditorSliceActions.setEditingAssembly(true));
    if (event.dataTransfer && reactFlowInstance && reactFlowWrapper.current) {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const constant = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      if (constant.node.snappable) {
        return;
      }
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });

      let newNode;
      if (constant.customComponent) {
        newNode = {
          id: Uuidv4(),
          type: constant.type,
          viewer2D: { ...position, size: constant.node.size },
          data: constant.customComponent.data
        };
      } else {
        newNode = {
          id: Uuidv4(),
          type: constant.type,
          viewer2D: { ...position, size: constant.node.size },
          data: {
            type: constant.key,
            anchors: constant.node.anchors
              ? constant.node.anchors.map((a: any) => {
                  return { ...a, id: Uuidv4() };
                })
              : [],
            instrumentationPorts: constant.node.instrumentationPorts
              ? constant.node.instrumentationPorts.map((a: any) => {
                  return { ...a, id: Uuidv4() };
                })
              : [],
            samplingPorts: constant.node.samplingPorts
              ? constant.node.samplingPorts.map((a: any) => {
                  return { ...a, id: Uuidv4() };
                })
              : []
          }
        };
      }
      dispatch(assemblyEditorSliceActions.addComponent(newNode));
    }
  };

  const multiSelect = () => {
    //@ts-ignore
    if (navigator.userAgentData?.platform.toLowerCase() === 'macOS') {
      return 224;
    } else {
      return 17;
    }
  };

  const changeDeleteKeyCode = () => {
    return 46;
  };

  const onElementsRemove = (elementsToRemove: any) => {
    removeComponents(elementsToRemove, editorMode, layerMode, components);
  };

  const onSelectionChange = (selectedElements: Elements<any> | null) => {
    changeSelectionSUA(selectedElements, componentsRef.current, setSelectedElements);
  };

  const exchangeSourceTarget = (params: Connection | Edge) => {
    const realTarget = params.source;
    const realAnchorTarget = params.sourceHandle;
    params.source = params.target;
    params.sourceHandle = params.targetHandle;
    params.target = realTarget;
    params.targetHandle = realAnchorTarget;
    return params;
  };

  const onConnect = (params: any) => {
    if (params.sourceHandle.includes('MEGA-ANCHOR')) {
      const source = componentsRef.current.find((component) => component.id === params.source);
      const sourceCopy = _.cloneDeep(source);
      const newAnchor = {
        id: uuidv4(),
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: { left: 0.5, top: 0.5 }
      };
      params.sourceHandle = newAnchor.id;
      sourceCopy.data.anchors.push(newAnchor);
      dispatch(assemblyEditorSliceActions.updateComponent(sourceCopy));
    }
    if (params.targetHandle.includes('MEGA-ANCHOR')) {
      const target = componentsRef.current.find((component) => component.id === params.target);
      const targetCopy = _.cloneDeep(target);
      const newAnchor = {
        id: uuidv4(),
        type: 'neutral',
        data: {
          position: 'left'
        },
        viewer2D: { left: 0.5, top: 0.5 }
      };
      params.targetHandle = newAnchor.id;
      targetCopy.data.anchors.push(newAnchor);
      dispatch(assemblyEditorSliceActions.updateComponent(targetCopy));
    }

    let source = componentsRef.current.find((c: any) => c.id === params.source);
    let sourceAnchor = source.data.anchors.find((a: any) => a.id === params.sourceHandle);
    if (!sourceAnchor)
      sourceAnchor = source.data.instrumentationPorts.find(
        (a: any) => a.id === params.sourceHandle
      );
    if (!sourceAnchor)
      sourceAnchor = source.data.samplingPorts.find((a: any) => a.id === params.sourceHandle);
    let target = componentsRef.current.find((c: any) => c.id === params.target);

    let targetAnchor = target.data.anchors.find((a: any) => a.id === params.targetHandle);
    if (!targetAnchor)
      targetAnchor = target.data.instrumentationPorts.find(
        (a: any) => a.id === params.targetHandle
      );
    if (!targetAnchor)
      targetAnchor = target.data.samplingPorts.find((a: any) => a.id === params.targetHandle);

    //Cas source anchor === target(vert) => échanger source et target
    if (sourceAnchor.type === 'target') {
      exchangeSourceTarget(params);
    }

    //Cas source anchor  = Genderless(bleu)
    //Si target est genderless(bleu) => rien à faire
    //Si target est target(vert) => rien à faire
    //Si target est source(rouge) => échanger position
    if (sourceAnchor.type === 'genderless' && targetAnchor.type === 'source') {
      exchangeSourceTarget(params);
    }

    if (sourceAnchor.type === 'neutral' && targetAnchor.type === 'source') {
      exchangeSourceTarget(params);
    }

    dispatch(
      assemblyEditorSliceActions.addComponent({
        id: Uuidv4(),
        type: 'genericedge',
        data: { type: 'tubing', ...tubingData },
        ...params
      })
    );
  };

  const onEdgeUpdate: OnEdgeUpdateFunc = (oldEdge, newConnection) => {
    if (oldEdge.data.ghost) {
      return;
    }
    const source = componentsRef.current.find((c: any) => c.id === newConnection.source);
    const sourceAnchor = source.data.anchors.find((a: any) => a.id === newConnection.sourceHandle);
    const target = componentsRef.current.find((c: any) => c.id === newConnection.target);
    const targetAnchor = target.data.anchors.find((a: any) => a.id === newConnection.targetHandle);

    if (newConnection.source === newConnection.target) {
      return false;
    }
    //Cas source anchor === source(rouge) => target anchor === target(vert) || genderless(blue)
    if (sourceAnchor.type === 'source' && targetAnchor.type === 'source') {
      return false;
    }
    //Cas source anchor === target(vert) =>
    //il faut que target anchor === source(rouge) || genderless(blue)
    //si correct => échanger source et target
    if (sourceAnchor.type === 'target' && targetAnchor.type === 'target') {
      return false;
    }

    //Cas source anchor === target(vert) => échanger source et target
    if (sourceAnchor.type === 'target') {
      exchangeSourceTarget(newConnection);
    }

    //Cas source anchor  = Genderless(bleu)
    //Si target est genderless(bleu) => rien à faire
    //Si target est target(vert) => rien à faire
    //Si target est source(rouge) => échanger position
    if (sourceAnchor.type === 'genderless' && targetAnchor.type === 'source') {
      exchangeSourceTarget(newConnection);
    }

    if (sourceAnchor.type === 'neutral' && targetAnchor.type === 'source') {
      exchangeSourceTarget(newConnection);
    }

    const oldEdgeCopy = _.cloneDeep(
      componentsRef.current.find((component: any) => component.id === oldEdge.id)
    );

    dispatch(
      assemblyEditorSliceActions.updateComponent({
        ...oldEdgeCopy,
        ...newConnection
      })
    );
  };

  const onNodeDragStop: ReactFlowProps['onNodeDragStop'] = (_, node) => {
    dispatch(assemblyEditorSliceActions.setEditingAssembly(true));
    promiseUpdateComponentRef.current.forEach((promise: any) => {
      dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
    });
    dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
    setDragging(false);
  };

  const onSelectionDragStop: ReactFlowProps['onSelectionDragStop'] = () => {
    promiseUpdateComponentRef.current.forEach((promise: any) => {
      dispatch(assemblyEditorSliceActions.updateComponent(promise.component));
    });
    dispatch(assemblyEditorSliceActions.resetPromiseUpdateComponent([]));
    setDragging(false);
  };

  const onNodeDragStart: ReactFlowProps['onNodeDragStart'] = (event, node) => {
    let componentListDrag = components.filter(
      (component) =>
        component.data.idDrag &&
        node.data.idDrag &&
        component.data.idDrag.id === node.data.idDrag.id &&
        component.id !== node.id
    );

    dragPositionRef.current = node.position;

    let position = _.cloneDeep(componentListDrag);
    dragComponentRef.current = position;
  };

  const onSelectionDragStart: ReactFlowProps['onSelectionDragStart'] = () => {};

  const onNodeDrag: ReactFlowProps['onNodeDrag'] = (event, node) => {
    setDragging(true);
  };

  const onSelectionDrag: ReactFlowProps['onSelectionDragStart'] = (event, node) => {
    setDragging(true);
  };

  const onMoveStart = () => {
    setisMoving(true);
  };

  const onMoveEnd = () => {
    setisMoving(false);
  };

  const onRemoveComponents = async () => {
    const selectedComponent = components.find((c) => c.id === selectedComponents[0]);
    let componentsToRemove = [selectedComponent];

    if (selectedComponent.type === 'generic') {
      if (selectedComponent.data.idDrag) {
        const componentLinked = components.find(
          (c) => c.data.idDrag?.edge === selectedComponent.data.idDrag.edge
        );
        let copyComponentLinked = cloneDeep(componentLinked);
        delete copyComponentLinked.data.idDrag;
        dispatch(assemblyEditorSliceActions.updateComponent(copyComponentLinked));
      }
      const results = components
        .filter((c) => c.type === 'genericedge')
        .filter((c) => c.source === selectedComponent.id || c.target === selectedComponent.id);
      componentsToRemove = [...componentsToRemove, ...results];
    }
    removeComponents(componentsToRemove, editorMode, layerMode, components);
    setSelectedElements([]);
  };

  const renderDeleteModal = () => {
    return <DeleteModal removeAssembly={null} onRemoveComponents={onRemoveComponents} />;
  };

  const renderReferenceEditionModal = () => {
    return <ReferenceEditionModal />;
  };

  const onPaneClick = () => {
    setSelectedElements([]);
    dispatch(assemblyEditorSliceActions.selectComponents([]));
    dispatch(assemblyEditorSliceActions.selectedEdges(null));
  };

  const onNodeMouseEnter = (event: React.MouseEvent, node: any) => {
    if (node.type === 'genericonedge') {
      setHoverOnEdge(node.id);
    }
  };

  const onNodeMouseLeave = (event: React.MouseEvent, node: any) => {
    if (node.type === 'genericonedge') {
      setHoverOnEdge(null);
    }
  };

  const onEdgeMouseEnter = (event, edge) => {
    dispatch(assemblyEditorSliceActions.setHoverEdge(edge.id));
  };

  const onEdgeMouseLeave = () => {
    dispatch(assemblyEditorSliceActions.setHoverEdge(null));
  };
  return (
    <>
      <div className="f-full" ref={reactFlowWrapper}>
        {/* @ts-ignore */}
        <ReactFlow
          onPaneClick={onPaneClick}
          minZoom={0.05}
          maxZoom={5}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          elements={elements}
          onLoad={onLoad}
          onDragOver={onDragOver}
          onDrop={onDrop}
          //onElementsRemove={onElementsRemove}
          onSelectionChange={onSelectionChange}
          onConnect={onConnect}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeMouseEnter={onEdgeMouseEnter}
          onEdgeMouseLeave={onEdgeMouseLeave}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={onNodeMouseLeave}
          onNodeDragStop={onNodeDragStop}
          onNodeDragStart={onNodeDragStart}
          onNodeDrag={onNodeDrag}
          onSelectionDragStart={onSelectionDragStart}
          onSelectionDragStop={onSelectionDragStop}
          onSelectionDrag={onSelectionDrag}
          connectionMode={ConnectionMode.Loose}
          multiSelectionKeyCode={multiSelect()}
          //deleteKeyCode={changeDeleteKeyCode()}
          onMoveStart={onMoveStart}
          onMoveEnd={onMoveEnd}
          style={{
            backgroundColor: isSavingScreenshotAnnotated ? 'white' : '#f7faff',
            cursor: _isMoving ? 'grabbing' : 'grab'
          }}
        />
      </div>
      {showDeleteConfirmModal && renderDeleteModal()}
      {showReferenceEditionlModal && renderReferenceEditionModal()}
    </>
  );
};

export default FlowEditorReference;
