import { RuleComponentEdgeComponent } from '../RuleComponentEdgeComponent';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';

export class RuleComponentInterfaceGenderFunctionEdgeComponentInterfaceGender extends RuleComponentEdgeComponent {
  schemaComponentSourceList = allComponents;
  schemaComponentTargetList = allComponents;
  schemaTubeList = ['transferring'];
  editorList = ['UOE_PID'];

  checkPrerequisite = () => {
    const isFunction = this.schemaTubeList.includes(this.edge.data.type);
    const isSource = this.componentSource ? this.schemaComponentSourceList.includes(this.componentSource.data.type) : false;
    const isTarget = this.componentTarget ? this.schemaComponentTargetList.includes(this.componentTarget.data.type) : false;

    if (!isFunction) {
      return false;
    }

    if (!isSource || !isTarget) {
      return false;
    }

    return true;
  };

  checkRule = () => {
    const isSameType = this.componentSource.data['componentType'] === this.componentTarget.data['componentType'];

    const isInvalidGender1 = this.componentSource.data['componentInterfaceGender'] === "Male" && this.componentTarget.data['componentInterfaceGender'] === "Male";
    const isInvalidGender2 = this.componentSource.data['componentInterfaceGender'] === "Female" && this.componentTarget.data['componentInterfaceGender'] === "Female";
    const isInvalidGender3 = this.componentSource.data['componentInterfaceGender'] === "Genderless" && this.componentTarget.data['componentInterfaceGender'] !== "Genderless";
    const isInvalidGender4 = this.componentSource.data['componentInterfaceGender'] !== "Genderless" && this.componentTarget.data['componentInterfaceGender'] === "Genderless";

    if (isSameType && (isInvalidGender1 || isInvalidGender2 || isInvalidGender3 || isInvalidGender4)) {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible interface gender',
        errors:
          'The interface gender of the' +
          ` ${CUSTOM_NODES[this.componentSource.data.type].name} ` +
          '(source) is ' +
          (this.componentSource.data['componentInterfaceGender']
            ? this.componentSource.data['componentInterfaceGender']
            : 'unspecified') +
          ' and the interface gender of the' +
          ` ${CUSTOM_NODES[this.componentTarget.data.type].name} ` +
          '(target) is ' +
          (this.componentTarget.data['componentInterfaceGender']
            ? this.componentTarget.data['componentInterfaceGender']
            : 'unspecified'),
        componentAnchors: [
          //@ts-ignore
          { anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id },
          //@ts-ignore
          { anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }
        ]
      });
    }
  };
}

const allComponents = [
  'asepticDisconnector',
  'mechanicDisconnector',
  'quickCoupler',
  'triclampConnector',
  'couplerReducer',
  'lConnector',
  'tConnector',
  'yConnector',
  'xConnector',
  'asepticConnector',
  'sipConnector'
];