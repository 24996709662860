import { cloneDeep } from 'lodash';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { NodeProps, useUpdateNodeInternals } from 'react-flow-renderer';
import { CUSTOM_FUNCTIONS } from '../../../constants/CUSTOM_FUNCTIONS';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { GenericAnchor } from '../anchor/GenericAnchor';
import { NodeData } from '../utility/NodeData';
import SelectionToolbar from '../utility/SelectionToolbar';

let SIZE = 80;

const FunctionNode: FC<NodeProps<NodeData>> = (props) => {
  const { id, selected } = props;
  const componentsRef = useRef(null) as any;
  const [isHover, setIsHover] = useState(false);
  const dispatch = useAppDispatch();
  const updateNodeInternals = useUpdateNodeInternals();
  const { onComponentRotated } = useAssemblyEditorAction();

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  componentsRef.current = components;

  const componentRef = useRef(null);
  const layerModeRef = useRef<any>(null);
  const editorModeRef = useRef<any>(null);
  layerModeRef.current = layerMode;
  editorModeRef.current = editorMode;

  const component = components.find((c) => c.id === id && c.type !== 'ghost');
  componentRef.current = component;
  const rotate = component?.viewer2D?.rotate ?? 0;
  const hoveredComponent = useAppSelector((state) => state.assemblyEditorSlice.hoveredComponent);

  useEffect(() => {
    updateNodeInternals(id);
  }, [id, component]);

  useEffect(() => {
    if (!componentRef.current) {
      return;
    }
    const componentCopy = cloneDeep(componentRef.current);
    switch (editorModeRef.current) {
      case EditorMode.SUA:
        break;
      case EditorMode.UnitOperation:
        switch (layerModeRef.current) {
          case UnitOperationLayer.PFD:
            if (
              componentRef.current.viewer2D.pfd.x !== props.xPos ||
              componentRef.current.viewer2D.pfd.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pfd.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pfd.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pfd = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.PnID:
            if (
              componentRef.current.viewer2D.pid.x !== props.xPos ||
              componentRef.current.viewer2D.pid.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pid.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pid.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pid = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.Reference:
            if (
              componentRef.current.viewer2D.reference.x !== props.xPos ||
              componentRef.current.viewer2D.reference.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.reference.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.reference.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.reference = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
        }
        break;
    }
  }, [props.xPos, props.yPos]);

  const onMouseEnter = () => {
    setIsHover(true);
  };

  const onMouseLeave = () => {
    setIsHover(false);
  };

  const renderAnnotation = () => {
    let top = -12;
    let left = 0;
    if (component.viewer2D.size === 80 || component.viewer2D.size === 120) {
      top = component.viewer2D.size * 0.5 - 18;
      left = component.viewer2D.size * 0.5 - 18;
    }
    switch (editorMode) {
      case EditorMode.SUA:
        return null;
      case EditorMode.Reference:
        return null;
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PFD:
            return null;
          case UnitOperationLayer.PnID:
            return null;
          case UnitOperationLayer.Reference:
            return null;
        }
    }
  };

  const renderAncillaryFunctions = () => {
    return (
      <div
        className='f-col absolute'
        style={{
          left: -16,
          fontSize: 12,
          overflowY: 'visible'
        }}>
        {(selected || isHover || hoveredComponent === component.id) &&
          component?.data?.ancillaryFunctions?.[0].complexParameters &&
          component?.data?.ancillaryFunctions?.[0].complexParameters?.map(
            (fct: 'string', index: number) => {
              return (
                <div key={fct + index} title={CUSTOM_FUNCTIONS[fct]?.name}>
                  <div>{CUSTOM_FUNCTIONS[fct]?.form(12)}</div>
                </div>
              );
            }
          )}
      </div>
    );
  };

  function renderFunctionName() {
    return (
      <div
        className='f-col absolute f1-center'
        style={{
          left: 0,
          top: component.data.assembly || component.data.assemblyReference ? -20 : -34,
          fontSize: 14,
          overflowY: 'visible'
        }}>
        {(selected || isHover || hoveredComponent === component.id) &&
          <div className='f-col f1-center' title={component.data.customName ?? component.data.type}>
            <div style={{ fontSize: 14, marginBottom:-4 }}>{`${component.data.type[0].toUpperCase()}${component.data.type.slice(1)}`}</div>
            {component.data.assembly || component.data.assemblyReference ? null : <div style={{
              fontSize: 8,
            }} >(No SUA linked)</div>}
          </div>
        }
      </div>
    );
  }


  const renderNode = () => {
    switch (editorMode) {
      case EditorMode.SUA:
        return null;
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PFD:
            return (
              <div
                key={'PFD'}
                style={{
                  height: SIZE,
                  width: SIZE,
                  cursor: 'pointer',
                  color: component.data?.assembly || component.data?.assemblyReference ? 'black' : '#909090',
                  border: selected
                    ? '1px solid var(--blue)'
                    : isHover || hoveredComponent === component.id
                      ? '1px solid var(--primaryColor)'
                      : '1px solid transparent'
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
                {renderAncillaryFunctions()}
                {renderFunctionName()}
                {CUSTOM_FUNCTIONS[component.data.type]?.form(SIZE)}
              </div>
            );
          case UnitOperationLayer.PnID:
            if (component.data.ghost) {
              return null;
            }
            if (component.data.assembly) {
              return (
                <div
                  key={'PID-Small'}
                  style={{
                    height: 1,
                    width: 1,
                    opacity: 0,
                    border: selected
                      ? '1px solid var(--blue)'
                      : isHover
                        ? '1px solid var(--primaryColor)'
                        : '1px solid transparent'
                  }}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}>
                  {renderAncillaryFunctions()}
                  {renderFunctionName()}
                  {CUSTOM_FUNCTIONS[component.data.type]?.form(1)}
                </div>
              );
            }
            return (
              <div
                key={'PID'}
                style={{
                  height: SIZE,
                  width: SIZE,
                  cursor: 'pointer',
                  color: component?.data?.ancillaryFunctions?.[0].complexParameters
                    ? 'black'
                    : '#909090',
                  border: window.location.href.includes('history')
                    ? 'solid 1px var(--primaryColor)'
                    : selected
                      ? '1px solid var(--blue)'
                      : isHover || hoveredComponent === component.id
                        ? '1px solid var(--primaryColor)'
                        : '1px solid transparent'
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
                {renderAncillaryFunctions()}
                {renderFunctionName()}
                {CUSTOM_FUNCTIONS[component.data.type]?.form(SIZE)}
              </div>
            );
            break;
          case UnitOperationLayer.Reference:
            if (component.data.assembly && !component.data.assemblyReference && !component.data.ghost) {
              return (
                <div
                  key={'Reference-Small'}
                  style={{
                    height: 1,
                    width: 1,
                    opacity: 0,
                    border: selected
                      ? '1px solid var(--blue)'
                      : isHover
                        ? '1px solid var(--primaryColor)'
                        : '1px solid transparent'
                  }}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}>
                  {renderAncillaryFunctions()}
                  {renderFunctionName()}
                  {CUSTOM_FUNCTIONS[component.data.type]?.form(1)}
                </div>
              );
            }
            if (component.data.assemblyReference && component.data.ghostReference) {
              return null;
            }
            if (component.data.assemblyReference && !component.data.ghostReference) {
              return (
                <div
                  key={'Reference-Small'}
                  style={{
                    height: 1,
                    width: 1,
                    opacity: 0,
                    border: selected
                      ? '1px solid var(--blue)'
                      : isHover
                        ? '1px solid var(--primaryColor)'
                        : '1px solid transparent'
                  }}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}>
                  {renderAncillaryFunctions()}
                  {renderFunctionName()}
                  {CUSTOM_FUNCTIONS[component.data.type]?.form(1)}
                </div>
              );
            }
            return (
              <div
                key={'Reference'}
                style={{
                  height: SIZE,
                  width: SIZE,
                  cursor: 'pointer',
                  color: component?.data?.ancillaryFunctions?.[0].complexParameters
                    ? 'black'
                    : '#909090',
                  border: window.location.href.includes('history')
                    ? 'solid 1px var(--primaryColor)'
                    : selected
                      ? '1px solid var(--blue)'
                      : isHover || hoveredComponent === component.id
                        ? '1px solid var(--primaryColor)'
                        : '1px solid transparent'
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
                {renderAncillaryFunctions()}
                {renderFunctionName()}
                {CUSTOM_FUNCTIONS[component.data.type]?.form(SIZE)}
              </div>
            );
        }
    }
  };

  const renderAnchors = () => {
    switch (editorMode) {
      case EditorMode.SUA:
        return null;
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PFD:
            return component.data.anchors.map((anchor: any) => {
              return (
                <GenericAnchor
                  key={anchor.id}
                  component={component}
                  color={'#565656'}
                  anchor={anchor}
                  sizeX={SIZE}
                  sizeY={SIZE}
                />
              );
            });
          case UnitOperationLayer.PnID:
            if (component.data.ghost) {
              return null;
            }
            return component.data.anchors.map((anchor: any) => {
              const edge = components.find(
                (c) =>
                  c.type === 'functionedge' &&
                  ((c.source === component.id && c.sourceHandle === anchor.id) ||
                    (c.target === component.id && c.targetHandle === anchor.id))
              );
              if ((component.data.assembly && edge) || !component.data.assembly) {
                return (
                  <GenericAnchor
                    key={anchor.id}
                    component={component}
                    anchor={anchor}
                    sizeX={SIZE}
                    sizeY={SIZE}
                  />
                );
              }
            });
          case UnitOperationLayer.Reference:
            if (component.data.assembly && !component.data.assemblyReference && !component.data.ghost) {
              return null;
            }
            if (component.data.ghostReference) {
              return null;
            }
            return component.data.anchors.map((anchor: any) => {
              const edge = components.find(
                (c) =>
                  c.type === 'functionedge' &&
                  ((c.source === component.id && c.sourceHandle === anchor.id) ||
                    (c.target === component.id && c.targetHandle === anchor.id))
              );
              if ((component.data.assemblyReference && edge) || !component.data.assemblyReference) {
                return (
                  <GenericAnchor
                    key={anchor.id}
                    component={component}
                    anchor={anchor}
                    sizeX={SIZE}
                    sizeY={SIZE}
                  />
                );
              }
            });
        }
    }
  };

  const renderSelected = () => {
    switch (editorMode) {
      case EditorMode.SUA:
        return (
          <SelectionToolbar
            component={component}
            rotate={rotate}
            onComponentRotated={() => onComponentRotated(component)}
          />
        );
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PFD:
            return (
              <SelectionToolbar
                component={component}
                rotate={rotate}
                onComponentRotated={() => onComponentRotated(component)}
              />
            );
          case UnitOperationLayer.PnID:
            if (component.data.assembly) {
              return null;
            }
            return (
              <>
                <SelectionToolbar
                  component={component}
                  rotate={rotate}
                  onComponentRotated={() => onComponentRotated(component)}
                />
              </>
            );
          case UnitOperationLayer.Reference:
            if (component.data.assemblyReference) {
              return null;
            }
            return null;
        }
    }
  };

  if (!component) {
    return null;
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        {renderAnnotation()}
        <div
          style={{
            position: 'relative',
            transform: `rotate(${rotate}deg)`
          }}>
          {renderNode()}
          {renderAnchors()}
        </div>
        {(selected || isHover) && renderSelected()}
      </div>
    </>
  );
};

export default memo(FunctionNode);
