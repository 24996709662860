import { Icon } from 'hakobio-react-ui';
import { FC, useState } from 'react';
import { config } from '../../../services/config';

interface ImageCardProps {
  assembly: any;
}

const ImageCard: FC<ImageCardProps> = ({ assembly }) => {
  const [src, setSrc] = useState(
    config.BlobURL +
      '/single-use-configurator/assemblies/thumbnail/' +
      assembly.id +
      '.png?nocache=' +
      assembly?.modifiedOn
  );

  const onCopyAdded = (event) => {
    setSrc('');
  };

  return (
    <>
      {src.length ? (
        <img
          style={{ height: 'auto', width: '100%' }}
          src={src}
          onError={onCopyAdded}
          alt="assembly view"
        />
      ) : (
        <div className="f-col f-center frozen-grey">
          <Icon name="manifold" size="5rem" />
        </div>
      )}
    </>
  );
};

export default ImageCard;
