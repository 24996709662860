import { useAppDispatch } from '../../index';
import { assemblyEditorSliceActions } from './assemblyEditorSlice';
import {
  getAllAssembliesAndReferencesComplete,
  getAllCustomComponents,
} from '../../../services/editor/editorService';
import {
  convertToRead,
  convertToReadComponent
} from '../../../services/editor/editorServiceUtilities';
import _ from 'lodash';

export const useEditorLibraryAction = () => {
  const dispatch = useAppDispatch();

  const resetEditorCustomComponentLibrary = () => {
    dispatch(assemblyEditorSliceActions.resetEditorCustomComponentLibrary(null));
  };

  const getEditorCustomComponentLibrary = async () => {
    dispatch(assemblyEditorSliceActions.resetEditorCustomComponentLibrary(null));
    const customComponentList = await getAllCustomComponents();
    customComponentList.forEach((customComponent) => {
      const customComponentComplete = convertToReadComponent(customComponent);
      dispatch(
        assemblyEditorSliceActions.addInEditorCustomComponentLibrary(customComponentComplete)
      );
    });
  };

  const resetEditorAssemblyLibrary = () => {
    dispatch(assemblyEditorSliceActions.resetEditorAssemblyLibrary(null));
  };

  const getEditorAssemblyLibrary = async () => {
    dispatch(assemblyEditorSliceActions.resetEditorAssemblyLibrary(null));
    let assemblyList = await getAllAssembliesAndReferencesComplete(); // getAllAssembliesComplete();
    const readableAssemblyList = assemblyList.map((assembly) => {
      return convertToRead(assembly);
      //dispatch(assemblyEditorSliceActions.addInEditorAssemblyLibrary(assemblyComplete));
    });
    const assembliesOrderedByName = _.sortBy(readableAssemblyList, [
      function (assembly) {
        return assembly.general.name.toLowerCase();
      }
    ]);
    dispatch(assemblyEditorSliceActions.setEditorAssemblyLibrary(assembliesOrderedByName));
    dispatch(assemblyEditorSliceActions.setIsSUALibraryCharged(true));
  };

  return {
    resetEditorCustomComponentLibrary,
    resetEditorAssemblyLibrary,
    getEditorCustomComponentLibrary,
    getEditorAssemblyLibrary
  };
};
