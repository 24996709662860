import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { Icon, DoubleChip, SubTitleWithIcon, TextInput, Select } from 'hakobio-react-ui';
import { useAssemblyEditorAction } from '../../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { useAppSelector } from '../../../../../store';
import { useIntl } from 'react-intl';
import { ViewMode } from '../../../../../constants/ViewMode';
import { EditorMode } from '../../../../../constants/EditorMode';
import { portColors } from '../../../../../utilities/portsColors';

export function NodePropertyAnchor({
  subSchema,
  item,
  keyProperty,
  index,
  property
}: {
  subSchema: any;
  item: any;
  keyProperty: any;
  index: number;
  property: any;
}) {
  const {
    onAnchorPropertyChanged,
    onAnchorDeleted,
    onAnchorDeletedCustomComponent,
    changeAnchorsSelection,
    setHoveredAnchor,
    unsetHoveredAnchor
  } = useAssemblyEditorAction();

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const selectedComponentId = selectedComponents && selectedComponents[0];
  const selectedComponent = components.find((c: any) => c.id === selectedComponentId);

  const customComponentEdition = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentEdition
  );

  const general = useAppSelector((state) => state.assemblyEditorSlice.general);

  const component =
    editorMode === EditorMode.Component ? customComponentEdition : selectedComponent;

  const [_isEditing, setIsEditing] = useState(false);
  const [_isHover, setIsHover] = useState(false);

  const intl = useIntl();

  const mouseEnter = () => {
    setIsHover(true);
    setHoveredAnchor(item.id);
  };

  const mouseLeave = () => {
    setIsHover(false);
    unsetHoveredAnchor();
  };

  const anchorName = () => {
    if (
      (editorMode === EditorMode.SUA || editorMode === EditorMode.Component) &&
      keyProperty === 'anchors'
    ) {
      return 'Transfer Port';
    } else {
      return keyProperty
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .slice(0, -1);
    }
  };
  const colorAnchors = () => {
    if (editorMode === EditorMode.UnitOperation) {
      return '#565656';
    } else {
      switch (keyProperty) {
        case 'anchors':
          return portColors.Transfer;
        case 'instrumentationPorts':
          return portColors.Instrumentation;
        case 'samplingPorts':
          return portColors.Sampling;
        default:
          return '#37c6f2';
      }
    }
  };

  const colorAnchorsOnHover = () => {
    if (editorMode === EditorMode.UnitOperation) {
      return 'rgba(151, 151, 151, 0.1)';
    } else {
      switch (keyProperty) {
        case 'anchors':
          return 'rgba(55, 198, 242, 0.1)';
        case 'instrumentationPorts':
          return 'rgba(173, 95, 46, 0.1)';
        case 'samplingPorts':
          return 'rgba(127, 17, 224, 0.1)';
        default:
          return '#37c6f2';
      }
    }
  };
  const colorOfAnchor = colorAnchors();
  const colorOfAnchorOnHover = colorAnchorsOnHover();

  // const labelAnchors = () => {
  //   switch (item.type) {
  //     case 'genderless':
  //       return 'G';
  //     case 'neutral':
  //       return 'N';
  //     case 'source':
  //       return 'M';
  //     case 'target':
  //       return 'F';
  //   }
  // };
  // const labelOfAnchor = labelAnchors();

  // const backgroundColorAnchors = () => {
  //   switch (item.type) {
  //     case 'genderless':
  //       return '#e7feff';
  //     case 'neutral':
  //       return 'lightblue';
  //     case 'source':
  //       return 'var(--light-red)';
  //     case 'target':
  //       return 'var(--light-green)';
  //   }
  // };
  // const backgroundColorOfAnchors = backgroundColorAnchors();

  const deleteAnchor = () => {
    if (editorMode === EditorMode.Component) {
      onAnchorDeletedCustomComponent(keyProperty, item, component);
    } else {
      onAnchorDeleted(keyProperty, item, components, component);
    }
  };

  const renderEditing = () => {
    const propertyList = Object.entries(subSchema.properties);
    return (
      <div className="f-col px-2 pb-2">
        <div className=" pt-1">
          <SubTitleWithIcon name="pencil-2">
            {intl.formatMessage({
              id: 'NodePropertyAnchor.EditProperties',
              defaultMessage: 'Edit properties'
            })}
          </SubTitleWithIcon>
        </div>
        <TextInput
          label="Anchor name"
          inputSize="small"
          value={item.data?.name || ''}
          type="string"
          onChange={(event: any) => {
            onAnchorPropertyChanged(item, 'name', keyProperty, event.target.value, component); //property.name,
          }}

          // endAdornment={property.units}
        />

        {propertyList.map(([key, property]: any) => {
          if (property.slider) {
            return (
              <TextInput
                className="w-100"
                label={property.name}
                value={item.data[key]}
                onChange={
                  (event: any) =>
                    onAnchorPropertyChanged(item, key, keyProperty, event.target.value, component) //property.name,
                }
                helperText={intl.formatMessage({
                  id: '.ThisValueNeedsToBeBetween',
                  defaultMessage: `This value needs to be between ${Number(
                    property.slider[0]
                  )} and ${Number(property.slider[1])}`
                })}
                endAdornment={property.units}
                min={Number(property.slider[0])}
                max={Number(property.slider[1])}
                step={Number(property.step) || 1}
                type={'number'}
              />
            );
          } else if (property.enum) {
            return (
              <Select
                inputSize="small"
                key={key}
                label={<span className="capitalize">{property.name}</span>}
                value={item.data[key] || ''}
                onChange={(event: any) =>
                  onAnchorPropertyChanged(item, key, keyProperty, event.target.value, component)
                }>
                {property.enum.map((e: any) => (
                  <MenuItem key={e} value={e}>
                    {`${e} ${property.units || ''}`}
                  </MenuItem>
                ))}
              </Select>
            );
          } else {
            return <div key={key}>{key}</div>;
          }
        })}
      </div>
    );
  };

  const renderDisplaying = () => {
    return (
      <div className="f-col p-2 gap-2">
        {Object.entries(subSchema.properties).map(([subSchemaKey, subSchemaValue]: any) => {
          return (
            <DoubleChip
              key={subSchemaKey}
              className="capitalize"
              label1={subSchemaValue.name}
              label2={
                item.data[subSchemaKey] ? (
                  <span className="font-regular">{item.data[subSchemaKey]}</span>
                ) : (
                  <span className="frozen-grey">N/C</span>
                )
              }
            />
          );
        })}
      </div>
    );
  };

  const isInversed =
    ((general?.permissions?.update && viewMode === ViewMode.Editor) ||
      (editorMode === EditorMode.Component && viewMode === ViewMode.Editor)) &&
    (_isHover || _isEditing);

  return (
    <div
      className="mb-1"
      style={{ border: `1px solid var(--grey)` }}
      onMouseEnter={() => {
        changeAnchorsSelection([{ id: item.id, component: component.id }]);
      }}
      onMouseLeave={() => changeAnchorsSelection([])}>
      <div
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        className="f-row f1-between f2-center"
        style={{
          backgroundColor: isInversed ? colorOfAnchor : colorOfAnchorOnHover,
          color: isInversed ? 'white' : colorOfAnchor
        }}>
        <div
          className={`max-1-lines-visible py-1 px-2 pointer capitalize f-row f2-center gap-2`}
          // title={item.data.name || item.id}
        >
          <div
            style={{
              width: 12,
              height: 12,
              borderRadius: 50,
              backgroundColor: isInversed ? 'white' : colorOfAnchor
            }}
          />
          {item.data.name || anchorName() + `(${index + 1})`}
        </div>

        <div className="f-row f1-end f2-center gap-2 mr-2">
          {isInversed && (
            <div className="f-row gap-2">
              {_isEditing ? (
                <Icon
                  pointer
                  color={isInversed ? 'white' : colorOfAnchor}
                  hoverColor={'white'}
                  name={'chat'}
                  onClick={() => setIsEditing(!_isEditing)}
                />
              ) : (
                <Icon
                  pointer
                  color={isInversed ? 'white' : colorOfAnchor}
                  hoverColor={isInversed ? 'white' : _isEditing ? 'var(--blue)' : 'white'}
                  name="pencil"
                  onClick={() => setIsEditing(!_isEditing)}
                />
              )}

              <Icon
                pointer
                color={isInversed ? 'white' : colorOfAnchor}
                hoverColor={isInversed ? 'var(--red)' : _isEditing ? 'var(--blue)' : 'white'}
                name="bin"
                onClick={deleteAnchor}
              />
            </div>
          )}
        </div>
      </div>
      <>
        <div
          style={{
            height: 2,
            backgroundColor: 'var(--primaryColorBcg)'
          }}
        />
        {_isEditing ? renderEditing() : renderDisplaying()}
      </>
    </div>
  );
}
