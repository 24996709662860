import { EntityDisplayed } from "../../../constants/EntityDisplayed";
import { useAppDispatch, useAppSelector } from "../../../store";
import { assemblyEditorSliceActions } from "./../../../store/features/assemblyEditor/assemblyEditorSlice";

import { Icon } from "hakobio-react-ui";

const DefaultFolderLine = ({ folder }) => {
  const dispatch = useAppDispatch();

  const selectedFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedFolder,
  );

  const selectFolder = () => {
    dispatch(assemblyEditorSliceActions.selectFolder(folder));
    switch (folder.id) {
      case "functionalAssembliesFolder":
        dispatch(
          assemblyEditorSliceActions.setEntitiesDisplayed(EntityDisplayed.SUA),
        );
        break;
      case "referenceAssembliesFolder":
        dispatch(
          assemblyEditorSliceActions.setEntitiesDisplayed(
            EntityDisplayed.Reference,
          ),
        );
        break;
    }
  };

  return (
    <div
      className={
        "f-row gap-2 f1-between f2-center p-2 hover-item container-action"
      }
      style={{
        backgroundColor:
          selectedFolder?.id === folder.id ? "var(--light-grey)" : undefined,
      }}
      key={folder.id}
      onClick={selectFolder}
    >
      <div
        className="f-row gap-2 f2-center w-100"
        style={{
          color:
            selectedFolder?.id === folder.id
              ? "var(--primaryColor)"
              : undefined,
        }}
      >
        <Icon name={"folder"} />
        <div>{folder.name}</div>
      </div>
    </div>
  );
};

export default DefaultFolderLine;
