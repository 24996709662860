import { CustomIcon, ThinChip, Tooltip } from 'hakobio-react-ui';
import { cloneDeep, indexOf } from 'lodash';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { NodeProps, useStoreState, useUpdateNodeInternals } from 'react-flow-renderer';
import { CUSTOM_NODES } from '../../../constants/CUSTOM_NODES';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { useLinkEditorAction } from '../../../store/features/assemblyEditor/useLinkEditorAction';
import { portColors } from '../../../utilities/portsColors';
import { GenericAnchor } from '../anchor/GenericAnchor';
import GenericTooltip from '../utility/GenericTooltip';
import { NodeData } from '../utility/NodeData';
import SelectionToolbar from '../utility/SelectionToolbar';

const GenericNode: FC<NodeProps<NodeData>> = (props) => {
  const { id, selected } = props;
  const updateNodeInternals = useUpdateNodeInternals();
  const { joinComponents, joinSUAComponents } = useLinkEditorAction();
  const { onComponentRotated, onComponentVerticalFlip, onComponentHorizontalFlip } =
    useAssemblyEditorAction();
  const dispatch = useAppDispatch();

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const hoveredComponent = useAppSelector((state) => state.assemblyEditorSlice.hoveredComponent);
  const hoverComponentGenericNode = useAppSelector((state) => state.assemblyEditorSlice.hoverComponentGenericNode);

  const isSavingScreenshotSUAAnnotated = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUAAnnotated
  );
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );
  const selectedElements = useStoreState((store) => store.selectedElements);

  const [areLinkable, setAreLinkable] = useState([]) as any;
  const [_linkableEdge, setLinkableEdge] = useState() as any;
  const [isHover, setIsHover] = useState(false);
  const [_showTooltip, setShowToolTip] = useState(false) as any;
  const [_schema, setSchema] = useState<any | null>(null);
  const getSchemaAsync = async () => {
    try {
      const schema = await CUSTOM_NODES[component?.data.type].schema();
      setSchema(schema);
    } catch (error) {
      setSchema(null);
    }
  };

  useEffect(() => {
    getSchemaAsync();
  }, []);

  const componentRef = useRef(null);
  const layerModeRef = useRef<any>(null);
  const editorModeRef = useRef<any>(null);
  layerModeRef.current = layerMode;
  editorModeRef.current = editorMode;

  const component = components.find((c) => c.id === id);
  componentRef.current = component;
  const customComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentEdition
  );

  const functionComponentAssembly = components.find((c: any) => {
    return component?.data?.assembly?.component === c.id;
  });

  const functionComponentAssemblyReference = components.find((c: any) => {
    return component?.data?.assemblyReference?.component === c.id;
  });

  const listComparisonVersions = useAppSelector(
    (state) => state.assemblyEditorSlice.listComparionVersions
  );

  const [_componentDifference, setComponentDifference] = useState() as any;
  const componentDifference = listComparisonVersions.find((c: any) => c.id === component?.id);

  useEffect(() => {
    if (componentDifference) {
      setComponentDifference(componentDifference);
    }
  }, [componentDifference]);

  const SIZE = component?.viewer2D.size ? component?.viewer2D.size : 80;
  const SIZECUSTOM = customComponent?.viewer2D?.size ? customComponent?.viewer2D?.size : 80;
  const rotate = component?.viewer2D.rotate ?? 0;
  const flipX = component?.viewer2D.flipX ?? false;
  const flipY = component?.viewer2D.flipY ?? false;

  useEffect(() => {
    updateNodeInternals(id);
    if (componentDifference) {
      setShowToolTip(true);
    }
  }, [id, component]);

  useEffect(() => {
    const componentSelectedList = [] as any;
    selectedComponents?.forEach((sc: any) => {
      const value = components.find((component: any) => {
        return component?.id === sc;
      });
      if (value && value.type === "generic") {
        componentSelectedList.push(value);
      }
    });

    if (componentSelectedList?.length !== 2) {
      setAreLinkable(false);
      setLinkableEdge(null);
      return;
    }

    if (editorMode === EditorMode.UnitOperation) {
      let functionEdge : any;
      switch (layerMode) {
        case UnitOperationLayer.PnID:
          const anchor1 = componentSelectedList[0].data.anchors.find(a => a.data.componentLink);
          // son id correspond a l'id d'un point d'anrchor dans la componentSelectedList          
          const anchor2 = componentSelectedList[1].data.anchors.find(a => a.data.componentLink);
          // son id correspond a l'id d'un point d'anrchor dans la componentSelectedList
                  
          if(!anchor1 || !anchor2){
            break;
          }
          functionEdge = components.find(c => {
            if (c.type !== 'functionedge') {
              return false;
            }
            if (c.source === anchor1.data.componentLink?.component && c.sourceHandle === anchor1.data.componentLink?.anchor &&
              c.target === anchor2.data.componentLink?.component && c.targetHandle === anchor2.data.componentLink?.anchor) {
            // l'id du source de la _linkableFunctionEdge correspond a l'id du component d'un anrchor dans la componentSelectedList ou anchor1 ou anchor2
              return true;
            }
            if (c.source === anchor2.data.componentLink?.component && c.sourceHandle === anchor2.data.componentLink?.anchor &&
              c.target === anchor1.data.componentLink?.component && c.targetHandle === anchor1.data.componentLink?.anchor) {
                // l'id du source de la _linkableFunctionEdge correspond a l'id du component d'un anrchor dans la componentSelectedList ou anchor1 ou anchor2
              return true;
            }
            return false;
          })
          break;
        case UnitOperationLayer.Reference:
          const anchor3 = componentSelectedList[0].data.anchors.find(a => a.data.componentLinkReference);
          const anchor4 = componentSelectedList[1].data.anchors.find(a => a.data.componentLinkReference);
          if(!anchor3 || !anchor4){
            break;
          }
          functionEdge = components.find(c => {
            if (c.type !== 'functionedge') {
              return false;
            }
            if (c.source === anchor3.data.componentLinkReference?.component && c.sourceHandle === anchor3.data.componentLinkReference?.anchor &&
              c.target === anchor4.data.componentLinkReference?.component && c.targetHandle === anchor4.data.componentLinkReference?.anchor) {
              return true;
            }
            if (c.source === anchor4.data.componentLinkReference?.component && c.sourceHandle === anchor4.data.componentLinkReference?.anchor &&
              c.target === anchor3.data.componentLinkReference?.component && c.targetHandle === anchor3.data.componentLinkReference?.anchor) {
              return true;
            }
            return false;
          })
          break;
      }
      if (functionEdge) {
        setAreLinkable(true);
        setLinkableEdge(functionEdge);
      } else {
        setAreLinkable(false);
        setLinkableEdge(null);
      }
    } else if (editorMode === EditorMode.SUA) {
      if (
        componentSelectedList.length === 2 &&
        componentSelectedList.every((c) => c.type === 'generic')
      ) {
        let genericedge = null;

        components
          .forEach((ge: any) => {
            if (ge.type !== 'genericedge') {
              return;
            }
            const sourceComponent = componentSelectedList.find((sc: any) => {
              return sc.type === 'generic' && sc.id === ge.source;
            });
            const targetComponent = componentSelectedList.find((sc: any) => {
              return sc.type === 'generic' && sc.id === ge.target;
            });
            if (sourceComponent && targetComponent) {
              genericedge = ge;
            }
          });
        setAreLinkable(true);
        setLinkableEdge(genericedge);
      } else {
        setAreLinkable(false);
        setLinkableEdge(null);
      }
    }
  }, [selectedComponents]);

  useEffect(() => {
    if (!componentRef.current) {
      return;
    }
    const componentCopy = cloneDeep(componentRef.current);
    switch (editorModeRef.current) {
      case EditorMode.SUA:
        if (component?.viewer2D.x !== props.xPos || component?.viewer2D.y !== props.yPos) {
          // @ts-ignore
          const deltaX = Math.abs(component?.viewer2D.x - props.xPos);
          // @ts-ignore
          const deltaY = Math.abs(component?.viewer2D.y - props.yPos);
          if (deltaX > 0.01 || deltaY > 0.01) {
            const promise = { id: id, component: componentCopy };
            promise.component.viewer2D.x = props.xPos;
            promise.component.viewer2D.y = props.yPos;
            dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          }
        }
        break;
      case EditorMode.Reference:
        if (component?.viewer2D.x !== props.xPos || component?.viewer2D.y !== props.yPos) {
          // @ts-ignore
          const deltaX = Math.abs(component?.viewer2D.x - props.xPos);
          // @ts-ignore
          const deltaY = Math.abs(component?.viewer2D.y - props.yPos);
          if (deltaX > 0.01 || deltaY > 0.01) {
            const promise = { id: id, component: componentCopy };
            promise.component.viewer2D.x = props.xPos;
            promise.component.viewer2D.y = props.yPos;
            dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          }
        }
        break;
      case EditorMode.UnitOperation:
        switch (layerModeRef.current) {
          case UnitOperationLayer.PFD:
            if (
              componentRef.current.viewer2D.pfd.x !== props.xPos ||
              componentRef.current.viewer2D.pfd.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pfd.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pfd.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pfd = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.PnID:
            if (
              componentRef.current.viewer2D.pid.x !== props.xPos ||
              componentRef.current.viewer2D.pid.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pid.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pid.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pid = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.Reference:
            if (
              componentRef.current.viewer2D.reference.x !== props.xPos ||
              componentRef.current.viewer2D.reference.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.reference.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.reference.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.reference = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
        }
        break;
    }
  }, [props.xPos, props.yPos]);

  const onMouseEnter = () => {
    setIsHover(true);
    setShowToolTip(true);
  };

  let componentField;
  let viewField;

  switch (layerMode) {
    case UnitOperationLayer.PnID:
      componentField = 'assembly';
      viewField = 'pid';
      break;
    case UnitOperationLayer.Reference:
      if (functionComponentAssembly?.data.assembly && !functionComponentAssemblyReference?.data.assemblyReference) {
        componentField = 'assembly';
        viewField = 'pid';
      } else {
        componentField = 'assemblyReference';
        viewField = 'reference';
      }
  }

  const onMouseLeave = () => {
    setIsHover(false);
    setShowToolTip(false);
  };

  const join = (value: boolean) => {
    if (editorMode === EditorMode.SUA) {
      const componentSource = components.find((component) => component?.id === _linkableEdge?.source);
      const componentTarget = components.find((component) => component?.id === _linkableEdge?.target);
      joinSUAComponents(value, _linkableEdge, componentSource, componentTarget);
    } else if (editorMode === EditorMode.UnitOperation) {
      const findComponent = (components, id) => {
        return components.find((component) => component?.id === id);
      }
      const getAnchorAndComponent = (layerMode, componentFunctionSource, componentFunctionTarget, components) => {
        const linkType = layerMode === UnitOperationLayer.PnID ? "componentLink" : "componentLinkReference";  
        const anchor1 = componentFunctionSource.data.anchors.find(a => a.data[linkType] && _linkableEdge.sourceHandle === a.id);// compenentLInk avec l'id Anchor et component
        const anchor2 = componentFunctionTarget.data.anchors.find(a => a.data[linkType] && _linkableEdge.targetHandle === a.id);// compenentLInk avec l'id Anchor et component
        const componentSource = findComponent(components, anchor1.data[linkType].component);// retourne le composant generic d'un SUA
        const componentTarget = findComponent(components, anchor2.data[linkType].component);// retourne le composant generic d'un SUA
        return [componentSource, componentTarget];
      }
      const componentFunctionSource = findComponent(components, _linkableEdge?.source);// composant fonction d'un SUA 
      const componentFunctionTarget = findComponent(components, _linkableEdge?.target);// composant fonction d'un SUA 
      const [componentSource, componentTarget] = getAnchorAndComponent(layerMode, componentFunctionSource, componentFunctionTarget, components);
      // link des deux composant generic de deux SUA différents    
      joinComponents(value, _linkableEdge, componentSource, componentTarget);

    }
  };

  const renderAnnotation = () => {
    let top = -12;
    let left = 0;
    if (component?.viewer2D.size === 80 || component?.viewer2D.size === 120) {
      top = component?.viewer2D.size * 0.5 - 11;
      left = component?.viewer2D.size * 0.5 - 11;
    }
    switch (editorMode) {
      case EditorMode.SUA:
      case EditorMode.Reference:
        if (isSavingScreenshotSUAAnnotated) {
          const element = general.legend?.find((l) => l?.ids?.includes(id));
          let indexNumber = indexOf(general.legend, element);

          return (
            <div
              style={{
                position: 'absolute',
                top,
                left,
                borderRadius: '30px',
                border: '1px solid white',
                backgroundColor: 'black',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
                fontSize: 16,
                fontFamily: 'aileron-regular',
                height: 24,
                width: 24
              }}>
              {indexNumber + 1}
            </div>
          );
        } else {
          return null;
        }
      case EditorMode.UnitOperation:
        return null;
    }
  };

  const renderNode = () => {
    switch (editorMode) {
      case EditorMode.Component:
        return (
          <div
            key={'Component'}
            style={{
              height: SIZECUSTOM,
              width: SIZECUSTOM,
              cursor: 'pointer'
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {CUSTOM_NODES[customComponent.data.type]?.form(SIZECUSTOM)}
          </div>
        );

      case EditorMode.SUA:
        return (
          <Tooltip placement='right' title={contentTooltip()} enterDelay={1000}>
            <div
              key={'SUA'}
              style={{
                height: SIZE,
                width: SIZE,
                cursor: 'pointer'
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}>
              {CUSTOM_NODES[component?.data.type]?.form(SIZE)}
              {componentDifference && renderCustomIconVersion()}
            </div>
          </Tooltip>
        );
      case EditorMode.Reference:
        return (
          <Tooltip placement='right' title={contentTooltip()} enterDelay={1000}>
            <div
              key={'SUA'}
              style={{
                height: SIZE,
                width: SIZE,
                cursor: 'pointer'
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}>
              {CUSTOM_NODES[component?.data.type]?.form(SIZE)}
              {componentDifference && renderCustomIconVersion()}
            </div>
          </Tooltip>
        );
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PnID:
            return (
              <Tooltip placement='right' title={contentTooltip()} enterDelay={1000}>
                <div
                  key={'UnitOperation-PnID'}
                  style={{ height: SIZE, width: SIZE, cursor: 'pointer', position: 'relative', color: 'black' }}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}>
                  {CUSTOM_NODES[component?.data.type]?.form(SIZE)}
                </div>
              </Tooltip>
            );
          case UnitOperationLayer.Reference:
            switch (componentField) {
              case 'assembly':             
                return (
                  <Tooltip placement='right' title={contentTooltip()} enterDelay={1000}>
                    <div
                      key={'UnitOperation-Reference'}
                      style={{
                        height: SIZE, width: SIZE, cursor: 'pointer', position: 'relative',
                        color:  'var(--dark-grey)'
                      }}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}>
                      {CUSTOM_NODES[component?.data.type]?.form(SIZE)}
                    </div>
                  </Tooltip>
                );

              case 'assemblyReference':
                return (
                  <Tooltip placement='right' title={contentTooltip()} enterDelay={1000}>
                    <div
                      key={'UnitOperation-Reference'}
                      style={{
                        height: SIZE, width: SIZE, cursor: 'pointer', position: 'relative',
                        color: 'black'
                      }}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}>
                      {CUSTOM_NODES[component?.data.type]?.form(SIZE)}
                    </div>
                  </Tooltip>
                );
            }         
        }
    }
  };

  const renderAnchors = () => {
    let anchors;
    if (editorMode !== EditorMode.Component) anchors = component?.data.anchors;
    else anchors = customComponent.data.anchors;
    return anchors?.map((anchor: any) => {
      return (
        <GenericAnchor
          key={anchor.id}
          component={component}
          anchor={anchor}
          sizeX={SIZE}
          sizeY={SIZE}
          color={portColors.Transfer}
        />
      );
    });
  };

  const renderInstrumentationPorts = () => {
    let instrumentationPorts;
    if (editorMode !== EditorMode.Component)
      instrumentationPorts = component?.data.instrumentationPorts;
    else instrumentationPorts = customComponent.data.instrumentationPorts;
    return instrumentationPorts?.map((instrumentationPort: any) => {
      return (
        <GenericAnchor
          key={instrumentationPort.id}
          component={component}
          anchor={instrumentationPort}
          sizeX={SIZE}
          sizeY={SIZE}
          color={portColors.Instrumentation}
        />
      );
    });
  };

  const renderSamplingPorts = () => {
    let samplingPorts;
    if (editorMode !== EditorMode.Component) samplingPorts = component?.data.samplingPorts;
    else samplingPorts = customComponent.data.samplingPorts;
    return samplingPorts?.map((samplingPort: any) => {
      return (
        <GenericAnchor
          key={samplingPort.id}
          component={component}
          anchor={samplingPort}
          sizeX={SIZE}
          sizeY={SIZE}
          color={portColors.Sampling}
        />
      );
    });
  };

  const renderSelected = () => {
    return (
      <SelectionToolbar
        component={component}
        rotate={rotate}
        onComponentRotated={() => onComponentRotated(component)}
        onComponentHorizontalFlip={() => onComponentHorizontalFlip(component)}
        onComponentVerticalFlip={() => onComponentVerticalFlip(component)}
        isHover={isHover}
        hoverComponentGenericNode={hoverComponentGenericNode}
        isSelected={selected}
        areLinkable={areLinkable}
        handleClick={join}
        linkableEdge={_linkableEdge}
      />
    );
  };

  const contentTooltip = () => {
    if (component?.data && Object.entries(component?.data).length > 0) {
      // @ts-ignore
      return <GenericTooltip component={component} />;
    } else {
      return null;
    }
  };

  const contentVersionTooltip = () => {
    return (
      <div className='p-2 overflow-y-auto' style={{ maxHeight: 400 }}>
        <>
          <div key={_componentDifference?.id} className='font-regular mb-1'>
            {CUSTOM_NODES[_componentDifference?.type]?.name}
          </div>
          <div className='pl-2 f-col gap-2'>
            {_componentDifference?.changes.map((change) => {
              if (
                change.key !== 'anchors' &&
                change.key !== 'instrumentationPorts' &&
                change.key !== 'samplingPorts'
              ) {
                return (
                  <div key={change.key}>
                    <span className=' capitalize'>
                      {_schema?.properties[change.key]?.name || change.key}
                    </span>
                    <div className='f-row f2-center gap-2'>
                      <ThinChip
                        color={'var(--primaryColor)'}
                        backgroundColor={'var(--primaryColorBcg)'}
                        label={
                          (
                            <span>{`${change.viewingValue}${_schema?.properties[change.key]?.units &&
                              change.viewingValue !== 'N/C'
                              ? ' ' + _schema?.properties[change.key].units
                              : ''
                              }`}</span>
                          ) || 'N/C'
                        }
                      />
                      <CustomIcon name={'transfer-6'} />
                      <ThinChip
                        label={
                          (
                            <span>{`${change.comparingValue}${_schema?.properties[change.key]?.units &&
                              change.comparingValue !== 'N/C'
                              ? ' ' + _schema?.properties[change.key].units
                              : ''
                              }`}</span>
                          ) || 'N/C'
                        }
                      />
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      </div>
    );
  };

  function highlightComponent() {
    return (
      <div
        style={{
          border:
            hoveredComponent === id || isHover
              ? 'solid 1px var(--orange)'
              : 'solid 1px var(--dark-grey)',
          position: 'absolute',
          top: -8,
          bottom: -8,
          left: -8,
          right: -8,
          pointerEvents: 'none'
        }}
      />
    );
  }

  const renderCustomIconVersion = () => {
    return (
      <div
        style={{
          position: 'absolute',
          top: -30,
          right: -4
        }}>
        <Tooltip placement='top' title={contentVersionTooltip()} open={isHover}>
          <CustomIcon hako3 name='history_icon' color='var(--orange)' style={{ lineHeight: 0.5 }} />
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      {editorMode !== EditorMode.Component && component ? (
        <div
          style={{
            position: 'relative'
            //   pointerEvents: component?.data.ghost ? 'none' : 'auto'
          }}
          key={component?.id}>
          {renderAnnotation()}
          <div
            style={{
              position: 'relative',
              transform: `scaleX(${flipX ? -1 : 1}) scaleY(${flipY ? -1 : 1}) rotate(${rotate}deg)`
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {renderNode()}

            {renderAnchors()}
            {renderInstrumentationPorts()}
            {renderSamplingPorts()}
          </div>
          {((selected && selectedElements?.length <= 2) ||
            (selected && selectedElements?.length > 2 && id === selectedElements[0].id) ||
            isHover || hoverComponentGenericNode === id) &&
            renderSelected()}
          {componentDifference && highlightComponent()}
        </div>
      ) : (
        <>
          {renderNode()}
          {renderAnchors()}
          {renderInstrumentationPorts()}
          {renderSamplingPorts()}
        </>
      )}
    </>
  );
};

export default memo(GenericNode);
