import React from 'react';

import './FabButton.css';

const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.9)';
  }
  throw new Error('Bad Hex');
};

const MultiFabTypes = {
  FIRST: 'FIRST',
  LAST: 'LAST',
  OTHER: 'OTHER'
};

const MultiFabButton = (props) => {
  const [isHover, setHover] = React.useState(false);

  const backgroundColor =
    (props.backgroundColor && hexToRgbA(String(props.backgroundColor))) ||
    (props.black
      ? isHover
        ? `rgba(255,255,255,0.9)`
        : 'rgba(0,0,0,0.9)'
      : isHover
      ? `rgba(230,230,230,0.9)`
      : 'rgba(255,255,255,0.9)');

  const borderRadius =
    props.type === MultiFabTypes.FIRST
      ? '100px 100px 0px 0px'
      : props.type === MultiFabTypes.LAST
      ? '0px 0px 100px 100px'
      : '0px';

  const borderBottom = props.type !== MultiFabTypes.LAST ? '0px' : null;

  const mouseEnter = () => {
    setHover(true);
  };

  const mouseLeave = () => {
    setHover(false);
  };

  const render = () => {
    return (
      <div
        className="fab-button"
        title={props.title || ''}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        style={{
          borderRadius,
          backgroundColor,
          borderBottom
        }}
        onClick={() => props.onClick()}>
        {props.content}
      </div>
    );
  };

  return render();
};

const FabButton = (props) => {
  const [isHover, setHover] = React.useState(false);

  const mouseEnter = () => {
    setHover(true);
  };

  const mouseLeave = () => {
    setHover(false);
  };

  const onClick = (event) => {
    if (props.onClick && !props.disabled) {
      props.onClick(event);
    }
  };

  const renderMulti = () => {
    return (
      <div className="fab-button-multi">
        {props.content.map((button, index) => {
          return (
            <MultiFabButton
              key={index}
              backgroundColor={props.backgroundColor}
              content={button}
              type={
                index === 0
                  ? MultiFabTypes.FIRST
                  : index === props.content.length - 1
                  ? MultiFabTypes.LAST
                  : MultiFabTypes.OTHER
              }
              onClick={props.onClick[index]}
              title={props.title[index].toString()}
            />
          );
        })}
      </div>
    );
  };

  const renderUnique = () => {
    return (
      <div
        id={props.id}
        className="fab-button"
        title={props.title}
        style={{
          backgroundColor: props.disabled
            ? `rgba(255,255,255,0.9)`
            : (props.backgroundColor && hexToRgbA(String(props.backgroundColor))) ||
              (props.black
                ? isHover
                  ? `rgba(255,255,255,0.9)`
                  : 'rgba(75,75,75,0.9)'
                : isHover
                ? `rgba(230,230,230,0.9)`
                : 'rgba(255,255,255,0.9)'),
          color: props.disabled
            ? 'lightgrey'
            : (props.backgroundColor && 'white') ||
              (props.black ? (isHover ? 'black' : 'white') : 'black'),
          minHeight: props.multi ? undefined : props.size || '30px',
          minWidth: props.size || '30px',
          border: props.disabled
            ? 0
            : props.backgroundColor || (props.black && !isHover)
            ? 0
            : null,
          cursor: props.disabled ? 'not-allowed' : null,
          ...props.style
        }}
        onClick={onClick}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}>
        <div className="icon-wrap">{props.content}</div>
      </div>
    );
  };

  const render = () => {
    if (props.multi) {
      return renderMulti();
    } else {
      return renderUnique();
    }
  };

  return render();
};

export default FabButton;
