import { Switch } from 'hakobio-react-ui';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface PrivacyModalContentProps {
  setPrivacy: Function;
  isPrivate: boolean;
}

const PrivacyModalContent: FC<PrivacyModalContentProps> = ({ isPrivate, setPrivacy }) => {
  const isAssemblyPrivate = isPrivate === undefined ? true : isPrivate;
  const intl = useIntl();
  return (
    <div>
      <div className="py-2">
        {intl.formatMessage({
          id: 'PrivacyModal.Instruction',
          defaultMessage: 'You need to make this assembly public before changing its status'
        })}
      </div>
      <div className="mt-2 f-row gap-4">
        <Switch
          className="switch-fix"
          vertical
          checked={isAssemblyPrivate}
          onChange={() => setPrivacy(isAssemblyPrivate)}
          firstColor="var(--primaryColor)"
          secondColor="var(--secondaryColor)"
        />

        <div className="f-col gap-2 f-full">
          <div className="f-row gap-2 w-100">
            <div
              style={{
                minWidth: 80,
                color: !isAssemblyPrivate ? 'var(--primaryColor)' : 'var(--frozen-grey)'
              }}>
              {intl.formatMessage({
                id: 'SUConfigView.ShareModal.Public',
                defaultMessage: `Public`
              })}
            </div>
            {!isAssemblyPrivate && (
              <div className="f-col gap-2 ">
                {intl.formatMessage({
                  id: 'PrivacyModal.EveryOneCan',
                  defaultMessage: `Everyone can access this Single-Use Assembly`
                })}
              </div>
            )}
          </div>
          <div className="f-row gap-2 w-100">
            <div
              style={{
                minWidth: 80,
                color: isAssemblyPrivate ? 'var(--primaryColor)' : 'var(--frozen-grey)'
              }}>
              {intl.formatMessage({
                id: 'SUConfigView.ShareModal.OnlyMe',
                defaultMessage: `Only me`
              })}
            </div>
            {isAssemblyPrivate && (
              <div>
                {intl.formatMessage({
                  id: 'PrivacyModal.IamTheOnly',
                  defaultMessage: `I'm the only one who can access this Single-Use Assembly`
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyModalContent;
