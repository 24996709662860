import { Chip, Tooltip } from "hakobio-react-ui";
import { CUSTOM_FUNCTIONS } from "../../../constants/CUSTOM_FUNCTIONS";
import { CUSTOM_NODES } from "../../../constants/CUSTOM_NODES";
import { configMessages, generalMessages, statusMessages } from "../../../lang/messages";
import { useIntl } from "react-intl";
import { Filters } from "../../models/Filters";

interface AdvancedSearchFiltersProps {
  filters?: Filters;
}

const AdvancedSearchFilters: React.FC<AdvancedSearchFiltersProps> = ({
  filters,
}) => {
  const intl = useIntl();

  const renderFilters = () => {
    return (
      <>
        {filters?.name?.length ? (
          <Chip label={filters?.name} title={"Name"} />
        ) : null}
        {filters?.folders?.length
          ? filters?.folders.map((f) => {
              return (
                <Chip
                  label={f.name}
                  key={`${f.id}-folder`}
                  title={intl.formatMessage(generalMessages.folder)}
                />
              );
            })
          : null}
        {filters?.createdBy.length ? (
          <Chip label={filters?.createdBy} title={"Created by"} />
        ) : null}
        {filters?.modifiedBy.length ? (
          <Chip label={filters?.modifiedBy} title={"Modified by"} />
        ) : null}
        {filters?.assemblyType?.length ? (
          <Chip label={filters?.assemblyType} title={"Assembly type"} />
        ) : null}
        {filters?.parentSUA?.length
          ? filters.parentSUA?.map((p: any) => {
              return (
                <Chip
                  label={<div className="max-lines-1">{p?.general?.name}</div>}
                  key={`${p?.general?.id}-filter`}
                  title={"Function"}
                />
              );
            })
          : null}

        {filters?.referenceStatus?.length
          ? filters?.referenceStatus.map((r) => {
              return (
                <Chip
                  label={r}
                  key={`${r}`}
                  title={intl.formatMessage(statusMessages.status)}
                />
              );
            })
          : null}

        {filters?.suaStatus?.length
          ? filters?.suaStatus.map((s) => {
              return (
                <Chip
                  label={s}
                  key={`${s}-status`}
                  title={intl.formatMessage(statusMessages.status)}
                />
              );
            })
          : null}

        {filters?.functions?.length
          ? filters?.functions.map((f) => {
              return (
                <Chip
                  label={
                    <div className="f-row f2-center gap-1">
                      <div style={{ marginTop: 1 }}>
                        {CUSTOM_FUNCTIONS[f]?.form(14)}
                      </div>
                      <span>{CUSTOM_FUNCTIONS[f]?.name}</span>
                    </div>
                  }
                  key={`${f}-function`}
                  title={intl.formatMessage(configMessages.function)}
                />
              );
            })
          : null}
        {filters?.ancillaryFunctions?.length
          ? filters?.ancillaryFunctions.map((f) => {
              return (
                <Chip
                  label={
                    <div className="f-row f2-center gap-1">
                      <div style={{ marginTop: 1 }}>
                        {CUSTOM_FUNCTIONS[f]?.form(14)}
                      </div>
                      <span>{CUSTOM_FUNCTIONS[f]?.name}</span>
                    </div>
                  }
                  key={`${f}-ancillary-function`}
                  title={intl.formatMessage(configMessages.ancillaryFunctions)}
                />
              );
            })
          : null}
        {filters?.containers?.length
          ? filters?.containers.map((cont) => {
              return (
                <Chip
                  label={
                    <div className="f-row f2-center gap-1">
                      <div style={{ marginTop: 1 }}>
                        {CUSTOM_NODES[cont]?.form(13)}
                      </div>
                      <span>{CUSTOM_NODES[cont]?.name}</span>
                    </div>
                  }
                  key={`${cont}-container`}
                  title={intl.formatMessage(configMessages.container)}
                />
              );
            })
          : null}
        {filters?.suppliers?.length
          ? filters?.suppliers.map((s) => {
              return (
                <Chip
                  label={s}
                  key={`${s}`}
                  title={intl.formatMessage(configMessages.suppliers)} //------------------------------------------------------
                />
              );
            })
          : null}
        {filters?.connectors?.length
          ? filters?.connectors.map((conn) => {
              return (
                <Chip
                  label={
                    <div className="f-row f2-center gap-1">
                      <div style={{ marginTop: 1 }}>
                        {CUSTOM_NODES[conn]?.form(14)}
                      </div>
                      <span>{CUSTOM_NODES[conn]?.name}</span>
                    </div>
                  }
                  key={`${conn}-connector`}
                  title={intl.formatMessage(configMessages.connectionType)}
                />
              );
            })
          : null}
        {filters?.volumes?.length
          ? filters?.volumes.map((v) => {
              return (
                <Chip
                  label={<span>{v} L</span>}
                  key={`${v}-volume`}
                  title={intl.formatMessage(configMessages.volume)}
                />
              );
            })
          : null}
        {filters?.poreSizeMum > 0 ? (
          <Chip
            label={<span>{filters?.poreSizeMum} &#956;m</span>}
            title={intl.formatMessage(configMessages.poreSizeMum)}
          />
        ) : null}

        {filters?.poreSizeMwco > 0 ? (
          <Chip
            label={<span>{filters?.poreSizeMwco} kD</span>}
            title={intl.formatMessage(configMessages.poreSizeMwco)}
          />
        ) : null}

        {filters?.combinedPorosity?.length
          ? filters?.combinedPorosity.map((cp) => {
              return (
                <Chip
                  label={
                    <span>
                      {cp} <span className="lowercase font-light">&Mu;m</span>
                    </span>
                  }
                  key={`${cp}-combined-porosity`}
                  title={intl.formatMessage(configMessages.combinedPorosity)}
                />
              );
            })
          : null}

        {filters?.bags2D[1] > 0 ? (
          <Chip
            label={
              <>
                {`${filters?.bags2D[0]}-${filters.bags2D[1]}`}
                <span className="frozen-grey">
                  {" "}
                  {intl.formatMessage(configMessages.bags2D)}
                </span>
              </>
            }
            title={"2D bags"}
          />
        ) : null}
        {filters?.sensorTypes?.length
          ? filters?.sensorTypes.map((type) => {
              return (
                <Chip
                  label={type}
                  key={`${type}-sensor-type`}
                  title={intl.formatMessage(configMessages.sensorType)}
                />
              );
            })
          : null}
        {filters?.filtrationSurface > 0 ? (
          <Chip
            label={
              <>
                {filters?.filtrationSurface}
                <span>
                  m<sup>2</sup>
                </span>
              </>
            }
            title={intl.formatMessage(configMessages.filtrationSurface)}
          />
        ) : null}
        {filters?.lines[1] > 0 ? (
          <Chip
            label={
              <>
                {`${filters?.lines[0]}-${filters?.lines[1]}`}
                <span className="frozen-grey">
                  {" "}
                  {intl.formatMessage(configMessages.lines)}
                </span>
              </>
            }
            title={"Lines"}
          />
        ) : null}
        {filters?.tubingMaterials?.length
          ? filters?.tubingMaterials.map((type) => {
              return (
                <Chip
                  label={type}
                  key={`${type}-tubing-material`}
                  title={intl.formatMessage(configMessages.tubingMaterial)}
                />
              );
            })
          : null}
        {filters?.tubingInnerDiameters?.length
          ? filters?.tubingInnerDiameters.map((diam) => {
              return (
                <Chip
                  label={`${diam} inch`}
                  key={`${diam}-tubing-diameter`}
                  title={
                    <span>
                      {intl.formatMessage(configMessages.tubingInner)} &#8960;
                    </span>
                  }
                />
              );
            })
          : null}
        {filters?.tubingLength > 0 ? (
          <Chip
            label={`${filters?.tubingLength} mm`}
            title={intl.formatMessage(configMessages.tubingLength)}
          />
        ) : null}
      </>
    );
  };
  return (
    <Tooltip title={renderFilters()} placement="top-start">
      <div className="max-lines-1">{renderFilters()}</div>
    </Tooltip>
  );
};

export default AdvancedSearchFilters;
