import _ from 'lodash';

import Coordinate from './Coordinate.js';

import centroid from '@turf/centroid';

export default class Zone {
  constructor(arg) {
    var data = {
      projectId: null,
      name: null,
      shape: { coordinates: [] },
      id: null,
      createdOn: null,
      modifiedOn: null,
      walls: [],
      height: null,
      biosafetyLevel: null,
      totalLoad: null,
      zoneType: null,
      phoneNumber: null,
      capacity: 0,
      isTemplate: false
    };
    try {
      Object.assign(data, JSON.parse(arg));
    } catch (err) {
      try {
        Object.assign(data, arg);
      } catch (err) {
        console.log('Warning: ' + err);
      }
    }
    this.projectId = data.projectId;
    this.name = data.name;
    this.shape = { coordinates: [] };
    for (var i = 0; i < data.shape.coordinates.length; i++)
      this.shape.coordinates.push(new Coordinate(JSON.stringify(data.shape.coordinates[i])));
    this.id = data.id;
    this.createdOn = data.createdOn;
    this.modifiedOn = data.modifiedOn;
    this.walls = data.walls;
    this.medias = data.medias;
    this.height = data.height;
    this.biosafetyLevel = data.biosafetyLevel;
    this.totalLoad = data.totalLoad;
    this.zoneType = data.zoneType;
    this.phoneNumber = data.phoneNumber;
    this.capacity = data.capacity;
    this.isTemplate = data.isTemplate;
  }

  centroid() {
    const coordinates = _.map(this.shape.coordinates, (c) => {
      return { x: c.x, y: c.z };
    });
    const center = centroid(coordinates);
    return center;
  }
}
