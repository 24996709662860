import { Card, CardContent } from "@mui/material";
import { DoubleButton, Icon, ThinChip, Tooltip } from "hakobio-react-ui";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { actionMessages, versioningMessages } from "../../../lang/messages";
import {
  operationStatusBackgroundColors,
  operationStatusColors,
} from "../../../utilities/statuses/operationStatus";
import SingleUseEditorReferencesPanel from "../../EditorPanel/SingleUseEditor/ReferencePanel/SingleUseEditorReferencesPanel";
import AssemblyMenu from "../AssemblyMenu";
import { useStyles } from "../styles.startuppanel";
import ImageCard from "./ImageCard";
import StatusTooltip from "./StatusTooltip";
import { useCustomBehavior } from "../../../utils";

interface Props {
  assembly: any;
  isHover: boolean;
  openAssembly: () => void;
}

const UoCard = ({ assembly, isHover, openAssembly }: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { openReactAppHistoryInNewTab } = useCustomBehavior();

  const [referencesListUnitOperation, setReferencesListUnitOperation] =
    useState<any[]>([]);
  const [assemblyUOComplete, setAssemblyUOComplete] = useState<any[]>([]);

  // Sert a récupérer l'assemblage complet avec ses components pour aller chercher les references de chaque fonction de l'UO et les afficher dans une thinchip
  useEffect(() => {
    async function getAssemblyComplete(assembly: any) {
      //const assemblyComplete = await getAssembly(assembly.id);
      //return assemblyComplete;
      return null;
    }

    let assemblyUOCompleteAsync = getAssemblyComplete(assembly);
    assemblyUOCompleteAsync.then((assemblyComplete) =>
      setAssemblyUOComplete(assemblyComplete),
    );
  }, [assembly]);

  function showRefsUnitOperationsInfos() {
    let functionsUO = (assemblyUOComplete as any)?.components?.filter(
      (param) => param.type === "function",
    );
    functionsUO.forEach((func) => {
      let references = func?.parameters?.find(
        (param) => param.name === "references",
      );
      references?.complexParameters?.forEach((ref) => {
        let refUO = {
          name: ref.name,
          id: ref.id,
          type: ref.type,
          version: ref.version,
          status: ref.status,
          thumbnail: ref.thumbnail,
        };
        setReferencesListUnitOperation((referencesListUnitOperation) => [
          ...referencesListUnitOperation,
          refUO,
        ]);
      });
    });
    return (
      <SingleUseEditorReferencesPanel
        startUpPanel={true}
        referenceList={referencesListUnitOperation}
      />
    );
  }

  function getStatus(assembly: any) {
    const assemblyStatus =
      assembly?.parameters?.find((param) => param.name === "status")?.value ||
      null;
    if (assemblyStatus !== null) {
      return assemblyStatus;
    } else {
      return "No status";
    }
  }

  function viewAssembly() {
    localStorage.setItem("action", "uoVersion");
    localStorage.setItem("actionData", JSON.stringify(assembly));
    openReactAppHistoryInNewTab();
  }

  function unitOperationCardInfos() {
    const status = getStatus(assembly);
    return (
      <CardContent
        className="f-col gap-3"
        style={{
          color: "white",
          backgroundColor: "var(--primaryColor)",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          padding: "8px 16px",
        }}
      >
        <div className={"f-row f1-end f2-center gap-2 w-100"}>
          <div
            className="absolute f-row f2-center gap-2"
            style={{ top: "10px", left: ".5rem", cursor: "grab", zIndex: 1 }}
          >
            {isHover && assembly.permissions.move && (
              <Icon
                color="var(--white)"
                name="grab-handle"
                style={{ cursor: "grab" }}
              />
            )}
          </div>

          <div className="f-row gap-2 f1-end f2-center">
            <Tooltip
              title={
                <StatusTooltip status={status} assemblyType="unit-operation" />
              }
            >
              <ThinChip
                fontSize="smallText"
                color={operationStatusColors(status)}
                backgroundColor={operationStatusBackgroundColors(status)}
                label={<span className="capitalize">{status}</span>}
                title=""
              />
            </Tooltip>
          </div>
        </div>

        <div className="f-col gap-1">
          <div className="font-regular max-1-lines-visible">
            {assembly?.name}
          </div>
          {isHover && (
            <div
              style={{
                position: "absolute",
                top: 36,
                right: 14,
                zIndex: 1,
              }}
            >
              <DoubleButton
                style={{ border: "white 1px solid" }}
                inverse
                size="small"
                popContent={<AssemblyMenu assembly={assembly} />}
                onClick={
                  assembly?.permissions?.update ||
                  assembly?.permissions?.changeStatus
                    ? openAssembly
                    : viewAssembly
                }
              >
                {assembly?.permissions?.update ||
                assembly?.permissions?.changeStatus ? (
                  <div className="f-row gap-2">
                    <Icon name="pencil" />
                    {intl.formatMessage(actionMessages.edit)}
                  </div>
                ) : (
                  <div className="f-row gap-2">
                    <Icon name="eye" />
                    {intl.formatMessage(versioningMessages.view)}
                  </div>
                )}
              </DoubleButton>
            </div>
          )}
        </div>
      </CardContent>
    );
  }

  return (
    <Card
      style={{
        borderRadius: 15,
        borderColor: "var(--primaryColor)",
      }}
      className={`${classes.Card} relative`}
    >
      {unitOperationCardInfos()}

      <div
        className={`${classes.img} f-center relative`}
        style={{
          backgroundColor: "rgb(247, 250, 255)",
        }}
      >
        {assembly.isShortCut && (
          <div
            style={{
              position: "absolute",
              bottom: 6,
              right: 8,
              fontSize: "20px",
              color: "var(--primaryColor)",
              backgroundColor: "white",
              borderRadius: "4px",
              padding: ".4rem .4rem .5rem .5rem",
              border: "1px solid var(--grey)",
            }}
          >
            <Tooltip title={"Shortcut"}>
              <Icon name="external-link" color="var(--primaryColor)" />
            </Tooltip>
          </div>
        )}
        <ImageCard assembly={assembly} />
      </div>
    </Card>
  );
};

export default UoCard;
