import { CubeLoader, Icon, Empty, Modal } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { getAssemblyHistory } from '../../services/editor/editorService';
import { useAppDispatch, useAppSelector } from '../../store';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import _ from 'lodash';
import HistoryModalElement from './HistoryModalElement';
import { versioningMessages } from '../../lang/messages';

const HistoryModal = () => {
  const showHistoryModal = useAppSelector((state) => state.assemblyEditorSlice.showHistoryModal);
  const [_historyList, setHistoryList] = useState(null);
  const dispatch = useAppDispatch();

  const intl = useIntl();

  useEffect(() => {
    const getHistoryAsync = async () => {
      let historyList = await getAssemblyHistory(showHistoryModal.assemblyId);
      if (historyList.title === 'Unauthorized') {
        dispatch(assemblyEditorSliceActions.setShowHistoryModal(null));
        setHistoryList(null);
        return;
      } else {
        setHistoryList(_.orderBy(historyList, ['version'], ['desc']));
      }
    };
    getHistoryAsync();
  }, []);

  const onCloseModal = () => {
    dispatch(assemblyEditorSliceActions.setShowHistoryModal(null));
  };

  const renderList = () => {
    return _historyList.map((ah: any, index: number) => {
      return (
        <HistoryModalElement key={ah.id} assembly={ah} index={index} historyList={_historyList} />
      );
    });
  };

  const renderBody = () => {
    if (_historyList === null) {
      return (
        <div className="p-3">
          <CubeLoader />
        </div>
      );
    } else {
      return <div>{_historyList ? renderList() : <Empty />}</div>;
    }
  };

  const renderTitle = () => {
    return (
      <div className="f-row f2-center gap-2">
        <Icon name={'history'} size="1.4rem" />
        {intl.formatMessage(versioningMessages.history)}
      </div>
    );
  };

  const render = () => {
    return (
      <Modal size={'modal-xl'} title={renderTitle()} cancel={onCloseModal}>
        {renderBody()}
      </Modal>
    );
  };

  return render();
};

export default HistoryModal;
