const HookEvents = {
  //PluginAPI
  OnReady: "unity_event_OnReady",
  ActionDone: "unity_event_ActionDone",
  SetProjectDone: "unity_event_SetProjectDone",

  // Builder
  OnProjectChanged: "unity_event_OnProjectChanged",
  OnSelectionChanged: "unity_event_OnSelectionChanged",
  SetBuilderMode: "unity_event_SetBuilderMode",
  NewFreeZone: "unity_event_NewFreeZone",
  OnLocationsChanged: "unity_event_LocationsChanged",

  //Camera
  CameraReady: "unity_event_CameraReady",
  OnCameraChanged: "unity_event_OnCameraChanged",

  //Loading
  LoadingDone: "unity_event_LoadingDone",
  OnSetLoadingProgress: "unity_event_OnSetLoadingProgress",

  //FPV
  OnProductFocus: "unity_event_OnProductFocus",
  OnFpvReady: "unity_event_OnFpvReady",
  UnlockMouse: "unity_event_UnlockMouse",
  OnRoomChange: "unity_event_OnRoomChange",
  RequestProjectJson: "unity_event_RequestProjectJson",

  //FlowEditorUO
  FlowUpdated: "unity_event_FlowUpdated",
  FlowRefreshed: "unity_event_FlowRefreshed",

  //Sockets
  OnSocketCreated: "unity_event_OnSocketCreated",

  //Tubes
  TubeUpdated: "unity_event_TubeUpdated",
  TubeRefreshed: "unity_event_TubeRefreshed",
  TubeConnectionUpdated: "unity_event_TubeConnectionUpdated",

  //Arrows
  ArrowSelected: "unity_event_ArrowSelected",

  //Training
  rainingStepDone: "unity_event_TrainingStepDone",
  TrainingEquipmentMoved: "unity_event_TrainingEquipmentMoved",
  TrainingStepDone: "unity_event_TrainingStepDone",
  SetupMovePositionDone: "unity_event_SetupMovePositionDone",
  //Threejs viewer
  SetBboxScreenCoordinates: "unity_event_SetBboxScreenCoordinates",
};

export default HookEvents;
