import { saveAs } from 'file-saver';
import { ButtonFooter, CustomIcon, DoubleChip, PanelHeader } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import { CUSTOM_NODES } from '../../../../constants/CUSTOM_NODES';
import { generalMessages } from '../../../../lang/messages';
import {
  getAssemblies,
  getAssembliesCount,
  getAssembliesCountByType,
  getConnectionCount,
  getConnectionCountByType
} from '../../../../services/metrics/metricsServiceUtilities';
import { useAppSelector } from '../../../../store';
import { getColors } from '../../../../utilities';

const ExcelJS = require('exceljs');

function UnitOperationEditorMetricsPanel() {
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const { primaryColorAlpha } = getColors();

  const intl = useIntl();

  const onDownloadMetrics = async () => {
    const connectionCount = getConnectionCount(components);
    const connectionCountByType = getConnectionCountByType(components);
    const assembliesCount = getAssembliesCount(components);
    const assembliesCountByType = getAssembliesCountByType(components);
    const assemblies = getAssemblies(components);

    const toExport = {
      connectionCount,
      connectionCountByType,
      assembliesCount,
      assembliesCountByType,
      assemblies
    };
    await download(toExport);
  };

  const adjustColumnWidth = (worksheet: any) => {
    worksheet.columns.forEach((column) => {
      const lengths = column.values.map((v) => v.toString().length);
      const maxLength = Math.max(...lengths.filter((v) => typeof v === 'number'));
      column.width = maxLength;
    });
  };

  const download = async (metrics: any) => {
    const workbook = new ExcelJS.Workbook();
    const worksheetConnections = workbook.addWorksheet('Connections');
    worksheetConnections.addRow(['Number of connections', metrics.connectionCount]);
    worksheetConnections.addRow([]);
    worksheetConnections.addRow(['End 1', 'End 2', 'Count']);
    metrics.connectionCountByType.forEach((connection) => {
      worksheetConnections.addRow([connection.end1, connection.end2, connection.count]);
    });
    worksheetConnections.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'fbec5d' }
    };
    worksheetConnections.getRow(3).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bcd4e6' }
    };

    adjustColumnWidth(worksheetConnections);

    const worksheetAssemblies = workbook.addWorksheet('Assemblies');
    worksheetAssemblies.addRow(['Number of assemblies', metrics.assembliesCount]);
    worksheetAssemblies.addRow([]);
    worksheetAssemblies.addRow(['Name', 'Count']);
    metrics.assembliesCountByType.forEach((assembly) => {
      worksheetAssemblies.addRow([assembly.name, assembly.count]);
    });
    worksheetAssemblies.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ff5349' }
    };
    worksheetAssemblies.getRow(3).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bcd4e6' }
    };

    let worksheetList = workbook.addWorksheet('List');
    worksheetList.addRow(['Assemblies used']);
    worksheetList.addRow([]);
    for (let i = 0; i < metrics.assemblies.length; i++) {
      const assembly = metrics.assemblies[i];
      const row = worksheetList.addRow([assembly.general.name]);
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'bcd4e6' }
      };
      for (let j = 0; j < assembly.components.length; j++) {
        const component = assembly.components[j];
        const schemaHeader = CUSTOM_NODES[component.data.type];
        if (!schemaHeader) {
          continue;
        }
        const nameRow = worksheetList.addRow(['', schemaHeader.name, component.data.name]);
        if (nameRow) {
          nameRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'bce6c6' }
          };
        }
        await getComponentParameters(component, worksheetList);
      }
    }
    worksheetList.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '8fbc8f' }
    };

    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, general.name + '.xlsx');
    });
  };

  const getComponentParameters = async (component: any, worksheet: any) => {
    const schemaHeader = CUSTOM_NODES[component.data.type];
    if (!schemaHeader) {
      return null;
    }
    const schema = await schemaHeader.schema();

    Object.entries(schema.properties).forEach(([key, property]) => {
      if (key === 'name' || key === 'customName' || key === 'anchors') {
        return;
      }
      const value = component.data[key];
      if (value) {
        // @ts-ignore
        worksheet.addRow(['', property.name, value + (property.units ? ' ' + property.units : '')]);
      } else {
        // @ts-ignore
        // worksheet.addRow(['', property.name, "N/C"]);
      }
    });
  };

  const renderUniqueDesigns = () => {
    const assembliesCount = getAssembliesCount(components);
    const assembliesCountByType = getAssembliesCountByType(components);

    return (
      <DoubleChip
        className="max-1-lines-visible"
        key={assembliesCount}
        label1Ratio="80%"
        color="var(--primaryColor)"
        backgroundColor={primaryColorAlpha}
        label1={'Unique Designs'}
        label2={
          <div className="capitalize" style={{ textAlign: 'center' }}>
            {assembliesCountByType.length}
          </div>
        }
      />
    );
  };

  const renderAssemblies = () => {
    const assembliesCount = getAssembliesCount(components);
    const assembliesCountByType = getAssembliesCountByType(components);
    return (
      <div className="f-col gap-2">
        <DoubleChip
          className="max-1-lines-visible"
          key={assembliesCount}
          label1Ratio="80%"
          color="var(--primaryColor)"
          backgroundColor={primaryColorAlpha}
          label1={"SUA's"}
          label2={
            <div className="capitalize" style={{ textAlign: 'center' }}>
              {assembliesCount}
            </div>
          }
        />
        {assembliesCountByType.map((a) => (
          <DoubleChip
            className="max-1-lines-visible ml-3"
            key={a.id}
            label1Ratio="80%"
            label1={<span className="capitalize">{a.name}</span>}
            title1={a.name}
            label2={
              <div className="capitalize" style={{ textAlign: 'center' }}>
                {a.count}
              </div>
            }
          />
        ))}
      </div>
    );
  };

  const renderConnections = () => {
    const connectionCount = getConnectionCount(components);
    const connectionCountByType = getConnectionCountByType(components);
    return (
      <div className="f-col gap-2">
        <DoubleChip
          className="max-1-lines-visible"
          key={connectionCount}
          label1Ratio="80%"
          color="var(--primaryColor)"
          backgroundColor={primaryColorAlpha}
          label1={'Connections'}
          label2={
            <div className="capitalize" style={{ textAlign: 'center' }}>
              {connectionCount}
            </div>
          }
        />
        {connectionCountByType.map((c) => {
          return (
            <DoubleChip
              className="max-1-lines-visible ml-3"
              key={c.id}
              label1Ratio="80%"
              label1={
                <span className="capitalize">
                  {CUSTOM_NODES[c.end1].name} - {CUSTOM_NODES[c.end2].name}
                </span>
              }
              title1={`${CUSTOM_NODES[c.end1].name} - ${CUSTOM_NODES[c.end2].name}`}
              label2={
                <div className="capitalize" style={{ textAlign: 'center' }}>
                  {c.count}
                </div>
              }
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="f-col f-full h-100  relative h-100">
      <PanelHeader>{intl.formatMessage(generalMessages.metrics)}</PanelHeader>
      <div className="f-full" style={{ overflowY: 'auto', marginBottom: '4rem' }}>
        <div className="f-col f1-start gap-2 f-full p-2">
          {renderUniqueDesigns()}

          {renderAssemblies()}

          {renderConnections()}
        </div>

        <ButtonFooter onClick={onDownloadMetrics} arrow>
          <div className="f-row gap-2 f1-center f2-center">
            <CustomIcon
              style={{ lineHeight: 0.5 }}
              name="statistics_stats_picto_icon"
              hako3
              title="Download metrics"
              pointer
            />
            <span>{'Download metrics'}</span>
          </div>
        </ButtonFooter>
      </div>
    </div>
  );
}

export default UnitOperationEditorMetricsPanel;
