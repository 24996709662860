import { assemblyEditorSliceActions } from '../store/features/assemblyEditor/assemblyEditorSlice';
import {
  Modal,
  Button,
  TextInput,
  Icon,
  UserBadgeSelect,
  TextArea,
  Chip,
  Tooltip
} from 'hakobio-react-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { buttonSaveStyle } from '../components/EditorPanel/style/styles';
import { Autocomplete } from 'hakobio-react-ui';
import Uuidv4 from '../components/Shared/Uuidv4';
import _, { orderBy } from 'lodash';
import { saveDirectoryFolder } from '../services/editor/editorService';
import { toast } from 'react-toastify';
import { DirectoryType } from '../app/web-api-client';
import { DirectoryFolder } from '../components/models/DirectoryFolder';
import { ListTab } from '../constants/ListTab';

const AddEditFolderModal = () => {
  const dispatch = useAppDispatch();
  const selectedFolder1 = useAppSelector((state) => state.assemblyEditorSlice.selectedFolder);

  const user = useAppSelector((state) => state.assemblyEditorSlice.user);
  const userList = useAppSelector((state) => state.assemblyEditorSlice.userList);
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const _directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const selectedFolder = _directoriesList.find((dir) => dir.id === selectedFolder1?.id) || null;
  const [_designOwners, setDesignOwners] = useState([]);
  const [_designEditors, setDesignEditors] = useState([]);
  const [_folderName, setFolderName] = useState(selectedFolder?.name || 'New Folder');
  const intl = useIntl();

  useEffect(() => {
    let designOwners = [];
    let designEditors = [];
    if (selectedFolder) {
      selectedFolder.designEditors.map((designEditor) => {
        designEditors.push(userList.find((user) => user.userId === designEditor));
      });
      selectedFolder.designOwners.map((designOwner) => {
        designOwners.push(userList.find((user) => user.userId === designOwner));
      });
    } else {
      const userCreator = userList.find((u) => u.userId === user.id);
      designEditors.push(userCreator);
      designOwners.push(userCreator);
    }
    setDesignOwners(designOwners);
    setDesignEditors(designEditors);
  }, []);

  const editFolder = async () => {
    let newFolder: DirectoryFolder = null;
    if (selectedFolder) {
      newFolder = _.cloneDeep(selectedFolder);
      if (selectedFolder.name !== _folderName) {
        newFolder.name = _folderName;
      }
    } else {
      // @ts-ignore
      newFolder = {
        id: Uuidv4(),
        name: _folderName,
        children: [],
        shortCuts: [],
        createdBy: user.id.toString(),
        directoryType:
          listTab === ListTab.SUA ? DirectoryType.SingleUseAssembly : DirectoryType.UnitOperation,
        organizationId: 1,
        designOwners: [],
        designEditors: []
      };
    }
    let designEditors = [];
    let designOwners = [];
    _designOwners.map((designOwner) => {
      designOwners.push(designOwner.userId);
    });
    _designEditors.map((designEditor) => {
      designEditors.push(designEditor.userId);
    });
    const saveResult = await saveDirectoryFolder(designOwners, designEditors, newFolder);
    selectedFolder ? renderEditConfirmation(saveResult) : renderAddConfirmation(saveResult);
    let directoriesListCopy = _.cloneDeep(_directoriesList);
    let newDirList = [];
    if (selectedFolder) {
      newDirList = directoriesListCopy.map((directory) => {
        if (directory.id === saveResult.id) {
          return { ...saveResult, designEditors, designOwners };
        }
        return directory;
      });
      dispatch(assemblyEditorSliceActions.setAllDirectories(newDirList));
    } else {
      directoriesListCopy.push(saveResult);
      dispatch(
        assemblyEditorSliceActions.setAllDirectories(
          orderBy(directoriesListCopy, (r) => r.name.toLowerCase(), ['asc'])
        )
      );
    }
    dispatch(assemblyEditorSliceActions.selectFolder(null));
    dispatch(assemblyEditorSliceActions.setEditingFolder(false));
  };

  const renderEditConfirmation = (saveResult: any) => {
    saveResult
      ? toast.success(
          <FormattedMessage
            id="SUConfig.StartupPanel.Toast.FolderEdited"
            defaultMessage="Folder edited successfully"
          />
        )
      : toast.error(
          <FormattedMessage
            id="SUConfig.StartupPanel.Toast.FolderEditedError"
            defaultMessage="Folder has not been edited"
          />
        );
  };

  const renderAddConfirmation = (saveResult: any) => {
    saveResult
      ? toast.success(
          <FormattedMessage
            id="SUConfig.StartupPanel.Toast.FolderAdded"
            defaultMessage="Folder added successfully"
          />
        )
      : toast.error(
          <FormattedMessage
            id="SUConfig.StartupPanel.Toast.FolderAddedError"
            defaultMessage="Folder has not been added"
          />
        );
  };

  const renderFooter = () => {
    return (
      <div
        className="gap-2"
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Button
          width={'auto'}
          color={'var(--primaryColor)'}
          style={buttonSaveStyle}
          onClick={() => editFolder()}>
          <div className="f-center gap-2">
            <span style={{ fontSize: '1rem' }}>
              {' '}
              <FormattedMessage id="SUConfigView.EditFolderModal.Apply" defaultMessage="Apply" />
            </span>
          </div>
        </Button>
      </div>
    );
  };

  const renderModalodalInfo = (
    <div className="pt-2">
      {intl.formatMessage(
        {
          id: 'SUConfigView.EditFolderModal.AssignRoles',
          defaultMessage: 'Assign <b>Design Owners</b> and <b> Design Editors</b>  for this folder'
        },
        {
          b: (str) => <span className="font-regular">{str}</span>
        }
      )}
    </div>
  );

  const render = () => {
    return (
      <Modal
        size="modal-xl"
        title={selectedFolder ? intl.formatMessage({
          id: 'SUConfigView.EditFolderModal.EditFolder',
          defaultMessage: 'Edit Folder'
        }) : intl.formatMessage({
          id: 'SUConfigView.EditFolderModal.AddFolder',
          defaultMessage: 'Add Folder'
        }) }
        cancel={() => dispatch(assemblyEditorSliceActions.setEditingFolder(false))}
        footer={renderFooter()}>
        <div className="f-row gap-2 f2-center">
          <Icon name="folder" />
          <TextInput
            className="w-100 pb-2 pt-2"
            placeholder={intl.formatMessage({
              id: 'SUConfigView.EditFolderModal.FolderName',
              defaultMessage: 'Folder name'
            })}
            label={intl.formatMessage({
              id: 'SUConfigView.EditFolderModal.FolderName',
              defaultMessage: 'Folder name'
            })}
            value={_folderName}
            onChange={(event: any) => {
              setFolderName(event.target.value);
            }}
          />
        </div>
        <hr />
        {renderModalodalInfo}
        <div className="f-row gap-2 f2-center   w-100 pt-3">
          <Icon name={'key'} />
          <div className="f-row gap-1 f2-center">
            {intl.formatMessage({
              id: 'SUConfigView.EditFolderModal.DesignOwners',
              defaultMessage: 'Design Owners'
            })}
            <Tooltip
              placement="right"
              title={intl.formatMessage({
                id: 'SUConfigView.EditFolderModal.DesignOwnersTooltip',
                defaultMessage: 'Design Owners can manage folders and edit SUA status'
              })}>
              <Icon name={'about'} color="var(--frozen-grey)" />
            </Tooltip>
          </div>
        </div>
        <Autocomplete
          multiple
          className="mt-2 mb-1"
          variant="outlined"
          height={200}
          options={userList.map((user) => user.email)}
          value={_designOwners}
          placeholder={intl.formatMessage({
            id: 'Global.Action.TypeEmailOrGroupName',
            defaultMessage: 'Type an email address or a group name'
          })}
          onChange={(event, newValue) => {
            if (newValue?.length) {
              const usedAddedEmail = newValue[newValue.length - 1];
              const selectedUser = userList.find((u) => u.email === usedAddedEmail);
              if (selectedUser) {
                const newUserList = [
                  ..._designOwners,
                  {
                    userId: selectedUser.userId || Uuidv4(),
                    email: selectedUser.email,
                    firstname: selectedUser.firstname,
                    lastname: selectedUser.lastname
                  }
                ];
                setDesignOwners(newUserList);
              }
            } else if (event.target.value === 0 || !event.target.value) {
              setDesignOwners((prevDesignOwners) => {
                if (prevDesignOwners) {
                  return prevDesignOwners;
                }
                return [];
              });
            }
          }}
          renderTags={(_alreadyPermittedUsers: string[]) =>
            _alreadyPermittedUsers.map((u, index) => {
              {
                return (
                  <UserBadgeSelect
                    email={u['email']}
                    firstName={u['firstname']}
                    lastName={u['lastname']}
                    onDelete={() => {
                      setDesignOwners(_designOwners.filter((user) => user.userId !== u['userId']));
                    }}
                  />
                );
              }
            })
          }
        />
        <br />
        <div className="f-row gap-2 f2-center pl-1  w-100">
          <Icon name={'plan-2d'} />
          <div className="f-row gap-1 f2-center">
            {intl.formatMessage({
              id: 'SUConfigView.EditFolderModal.DesignEditors',
              defaultMessage: 'Design Editors'
            })}
            <Tooltip
              placement="right"
              title={intl.formatMessage({
                id: 'SUConfigView.EditFolderModal.DesignEditorsTooltip',
                defaultMessage: 'Design Editors can design SUAs and UOs '
              })}>
              <Icon name={'about'} color="var(--frozen-grey)" />
            </Tooltip>
          </div>
        </div>
        <Autocomplete
          multiple
          className="mt-2 mb-1"
          variant="outlined"
          height={200}
          options={userList.map((user) => user.email)}
          value={_designEditors}
          placeholder={intl.formatMessage({
            id: 'Global.Action.TypeEmailOrGroupName',
            defaultMessage: 'Type an email address or a group name'
          })}
          onChange={(event, newValue) => {
            if (newValue?.length) {
              const usedAddedEmail = newValue[newValue.length - 1];
              const selectedUser = userList.find((u) => u.email === usedAddedEmail);
              if (selectedUser) {
                const newUserList = [
                  ..._designEditors,
                  {
                    userId: selectedUser.userId ? selectedUser.userId : Uuidv4(),
                    email: selectedUser.email,
                    firstname: selectedUser.firstname,
                    lastname: selectedUser.lastname
                  }
                ];
                setDesignEditors(newUserList);
              }
            } else if (event.target.value === 0 || !event.target.value) {
              setDesignOwners((prevDesignOwners) => {
                if (prevDesignOwners) {
                  return prevDesignOwners;
                }
                return [];
              });
            }
          }}
          renderTags={(_alreadyPermittedUsers: string[]) =>
            _alreadyPermittedUsers.map((u, index) => {
              {
                return (
                  <UserBadgeSelect
                    email={u['email']}
                    firstName={u['firstname']}
                    lastName={u['lastname']}
                    onDelete={() => {
                      setDesignEditors(
                        _designEditors.filter((user) => user.userId !== u['userId'])
                      );
                    }}
                  />
                );
              }
            })
          }
        />
      </Modal>
    );
  };

  return render();
};

export default AddEditFolderModal;
