import { Icon, PanelHeader } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import { configMessages } from '../../../../lang/messages';
import { DetailPanel } from '../../Shared/DetailPanel/DetailPanel';
import { SingleUseGeneralPanel } from './SingleUseGeneralPanel';
import { EditorMode } from '../../../../constants/EditorMode';
import { useAppSelector } from '../../../../store';
import { ReferenceGeneralPanel } from './ReferenceGeneralPanel';
import { useEffect } from 'react';
import { useAssemblyEditorAction } from '../../../../store/features/assemblyEditor/useAssemblyEditorAction';

interface Props {
  onCloseLeftPanel: Function;
  selectedComponent: any;
}

const SingleUseEditorLeftPanel = ({ onCloseLeftPanel, selectedComponent }: Props) => {
  const { setEditorMode } = useAssemblyEditorAction();

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const intl = useIntl();
  const viewingAssembly = useAppSelector((state) => state.historySlice.viewingAssembly);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);

  useEffect(() => {
    if (viewingAssembly && viewingAssembly.general.type === 'SingleUseAssemblyReference') {
      setEditorMode(EditorMode.Reference);
    }
  }, [viewingAssembly]);

  const renderContent = () => {
    if (selectedComponent) {
      return (
        <DetailPanel
          key={selectedComponent.id}
          components={components}
          selectedComponent={selectedComponent}
        />
      );
    } else {
      const sua = {general, components}
      return editorMode === EditorMode.SUA ? <SingleUseGeneralPanel /> : <ReferenceGeneralPanel editable sua={sua} component={selectedComponent} />;
    }
  };

  return (
    <>
      <PanelHeader arrowBack onClose={onCloseLeftPanel}>
        {editorMode === EditorMode.SUA
          ? intl.formatMessage(configMessages.suaDesigner)
          : intl.formatMessage(configMessages.referenceDesigner)}
      </PanelHeader>
      {renderContent()}
    </>
  );
};

export default SingleUseEditorLeftPanel;
