export default class Coordinate {
  constructor(arg) {
    var data = { x: 0, y: 0, z: 0 };
    try {
      Object.assign(data, JSON.parse(arg));
    } catch (err) {
      try {
        Object.assign(data, arg);
      } catch (err) {
        console.log('Warning: ' + err);
      }
    }
    this.x = data.x;
    this.y = data.y;
    this.z = data.z;
  }

  isEqual(compare) {
    return (
      Math.round(this.x * 100) / 100 === Math.round(compare.x * 100) / 100 &&
      Math.round(this.y * 100) / 100 === Math.round(compare.y * 100) / 100 &&
      Math.round(this.z * 100) / 100 === Math.round(compare.z * 100) / 100
    );
  }
}
