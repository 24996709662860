import { createAuthRequest, createAuthRequestFile } from '../token';
import { config } from '../config';
import { Assembly, CustomComponent } from '../../app/web-api-client';

export async function shareAssembly(shareable: any) {
  const request = (await createAuthRequest('POST', { shareable: shareable })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Sharing/share', request);
  return await resp.json();
}

export async function GetShareable(assemblyId: any) {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Sharing/shareable?id=' + assemblyId, request);
  return await resp.json();
}

// TODO to adapt to communicate with the backend and then the sharing service
// export async function getSharedAssemblies(assembly: Assembly) {
//   const request = (await createAuthRequest('POST', { assembly })) as RequestInit;
//   const resp = await fetch(config.APIUrl + `/api/getSharedAssemblies/`, request);
//   return await resp.json();
// }

// Custom Components
