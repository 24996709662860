import { cloneDeep } from 'lodash';
import { Uuidv4 } from '../../../utilities';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';

const calculateComponentDimensions = (functionId, components, layerMode) => {
  const componentField = layerMode === UnitOperationLayer.PnID ? 'assembly' : 'assemblyReference';
  const viewField = layerMode === UnitOperationLayer.PnID ? 'pid' : 'reference';

  const assemblyComponents = cloneDeep(
    components.filter((component: any) => {
      return component.data[componentField]?.component === functionId;
    })
  );

  const componentGeneric = assemblyComponents.filter((c: any) => c.type === 'generic');

  let componentMaxX = componentGeneric[0].viewer2D[viewField].x;
  let componentMaxY = componentGeneric[0].viewer2D[viewField].y;
  let componentMinX = componentMaxX;
  let componentMinY = componentMaxY;

  componentGeneric.forEach((c: any) => {
    componentMaxX = Math.max(componentMaxX, c.viewer2D[viewField].x);
    componentMaxY = Math.max(componentMaxY, c.viewer2D[viewField].y);
    componentMinX = Math.min(componentMinX, c.viewer2D[viewField].x);
    componentMinY = Math.min(componentMinY, c.viewer2D[viewField].y);
  });

  return {
    viewField,
    componentMinX,
    componentMinY,
    componentMaxX,
    componentMaxY
  };
};

export const createUpdateNode = (functionId, components, layerMode) => {
  const {
    viewField,
    componentMinX,
    componentMinY,
    componentMaxX,
    componentMaxY
  } = calculateComponentDimensions(functionId, components, layerMode);

  const positionTopLeft = {
    pfd: { x: componentMinX - 10, y: componentMinY - 10 },
    pid: { x: componentMinX - 10, y: componentMinY - 10 },
    reference: { x: componentMinX - 10, y: componentMinY - 10 }
  };
  const positionBottomRight = { x: componentMaxX + 120, y: componentMaxY + 120 };

  const nodeSize = {
    x: positionBottomRight.x - positionTopLeft[viewField].x,
    y: positionBottomRight.y - positionTopLeft[viewField].y
  };

  return {
    id: Uuidv4(),
    type: 'update',
    viewer2D: { ...positionTopLeft, size: nodeSize },
    data: { type: 'update', component: functionId },
    style: { zIndex: 0 }
  };
};

export const updateUpdateNode = (node, functionId, components, layerMode) => {
  const {
    viewField,
    componentMinX,
    componentMinY,
    componentMaxX,
    componentMaxY
  } = calculateComponentDimensions(functionId, components, layerMode);

  const positionTopLeft = {
    pfd: { x: componentMinX - 10, y: componentMinY - 10 },
    pid: { x: componentMinX - 10, y: componentMinY - 10 },
    reference: { x: componentMinX - 10, y: componentMinY - 10 }
  };
  const positionBottomRight = { x: componentMaxX + 120, y: componentMaxY + 120 };

  const nodeSize = {
    x: positionBottomRight.x - positionTopLeft[viewField].x,
    y: positionBottomRight.y - positionTopLeft[viewField].y
  };

  return {
    id: node.id,
    type: 'update',
    viewer2D: { ...positionTopLeft, size: nodeSize },
    data: { type: 'update', component: functionId },
    style: { zIndex: 0 }
  };
};

