import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface historyViewState {
  viewingAssembly: any;
  comparingAssembly: any;
  historyList: any;
  differencesCount:number;
}

const initialState: historyViewState = {
  viewingAssembly: null,
  comparingAssembly: null,
  historyList: null,
  differencesCount: 0
};



export const historySlice = createSlice({
  name: 'historyView',
  initialState,
  reducers: {
    setViewingAssembly(state, action) {
      state.viewingAssembly = action.payload;
    },
    setComparingAssembly(state, action) {
      state.comparingAssembly = action.payload;
    },
    setHistoryList(state, action) {
      state.historyList = action.payload;
    },
    setDifferencesCount(state, action) {
      state.differencesCount = action.payload;
    },
  }
});

export const historySliceActions = historySlice.actions;

export const historySliceReducer = historySlice.reducer;

export default historySlice.reducer;
