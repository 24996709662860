import { PanelHeader } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import { DetailPanel } from '../../Shared/DetailPanel/DetailPanel';
import { configMessages } from '../../../../lang/messages';
import { useAppSelector } from '../../../../store';
import { ViewMode } from '../../../../constants/ViewMode';

interface Props {
  onCloseLeftPanel: Function;
  selectedComponent: any;
  components: Array<any>;
}

const ComponentEditorLeftPanel = ({ onCloseLeftPanel, selectedComponent, components }: Props) => {
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);

  const intl = useIntl();

  const renderContent = () => {
    if (selectedComponent) {
      return (
        <DetailPanel
          key={selectedComponent.id}
          components={components}
          selectedComponent={selectedComponent}
        />
      );
    }
  };

  const render = () => {
    return (
      <>
        <PanelHeader arrowBack onClose={onCloseLeftPanel}>
          {viewMode === ViewMode.History
            ? intl.formatMessage(configMessages.componentViewer)
            : intl.formatMessage(configMessages.componentEditor)}
        </PanelHeader>
        {renderContent()}
      </>
    );
  };

  return render();
};

export default ComponentEditorLeftPanel;
