import { createAuthRequest, createAuthRequestFile } from '../token';
import { config } from '../config';
import { Assembly, CustomComponent } from '../../app/web-api-client';
import { DirectoryFolder } from '../../components/models/DirectoryFolder';

export async function saveAssembly(assembly: Assembly) {
  const request = (await createAuthRequest('POST', { assembly })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/save', request);
  return await resp.json();
}

export async function duplicateAssembly(assemblyId: string ,folderId: any) {
  const request = (await createAuthRequest('POST', { assemblyId, folderId })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/duplicate', request);
  return await resp.json();
}

export async function getAssembly(id: string) {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/get?Id=' + id, request);
  return await resp.json();
}

export async function duplicateGenericAssemblyToReferenceAssembly(id: string) {
  const request = (await createAuthRequest('POST', { id })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/duplicate/generic-to-reference', request);
  return await resp.json();
}

export async function duplicateReferenceAssembly(assemblyId: string, folderId: string) {
  const request = (await createAuthRequest('POST', { assemblyId, folderId })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/duplicate/reference', request);
  return await resp.json();
}

export async function getAllAssemblies() {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/get/all', request);
  return await resp.json();
}

export async function getAllAssembliesComplete() {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/get/all/complete', request);
  return await resp.json();
}

export async function getAllAssembliesAndReferencesComplete() {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(
    config.APIUrl + '/api/Assembly/get/assembliesandreferences/complete',
    request
  );
  return await resp.json();
}

export async function deleteAssembly(id: string) {
  const request = (await createAuthRequest('DELETE', { assemblyId: id })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/delete', request);
  if (resp.status !== 200 && resp.status !== 204) {
    throw new Error('Error deleting assembly');
  }
  return true;
}

export async function getAssemblyHistory(id: string) {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Assembly/history?Id=' + id, request);
  return await resp.json();
}

export async function validateAssemblyName(id: string, type: string, name: string) {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(
    config.APIUrl + '/api/Assembly/validate/name?Id=' + id + '&Name=' + name + '&Type=' + type,
    request
  );
  return await resp.json();
}

export async function getOneAssemblyHistory(id: string, hashPartitionKey: string) {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(
    config.APIUrl + '/api/Assembly/get/one/history?Id=' + id + '&PartitionKey=' + hashPartitionKey,
    request
  );
  return await resp.json();
}

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export async function savePNGAssembly(id: string, image: any) {
  const file = await urltoFile(image, id + '.png', 'image/png');
  const request = (await createAuthRequestFile('POST', file)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Image/upload/png', request);
  return resp;
}

export async function saveSVGAssembly(id: string, image: any) {
  const file = await urltoFile(image, id + '.svg', 'image/svg+xml');
  const request = (await createAuthRequestFile('POST', file)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Image/upload/svg', request);
  return resp;
}

export async function savePNGComponent(id: string, image: any) {
  const file = await urltoFile(image, id + '.png', 'image/png');
  const request = (await createAuthRequestFile('POST', file)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Image/upload/component/png', request);
  return resp;
}

export async function saveSVGComponent(id: string, image: any) {
  const file = await urltoFile(image, id + '.svg', 'image/svg+xml');
  const request = (await createAuthRequestFile('POST', file)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Image/upload/component/svg', request);
  return resp;
}

// Custom Components

export async function saveCustomComponent(component: CustomComponent) {
  const request = (await createAuthRequest('POST', { component })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/CustomComponent/save', request);
  return await resp.json();
}

export async function getCustomComponent(id: string) {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/CustomComponent/get?Id=' + id, request);
  return await resp.json();
}

export async function getAllCustomComponents() {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/CustomComponent/get/all', request);
  return await resp.json();
}

export async function deleteCustomComponent(id: string) {
  const request = (await createAuthRequest('DELETE', { componentId: id })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/CustomComponent/delete', request);
  return true;
}

// Directories

export async function getAllDirectories() {
  const request = (await createAuthRequest('GET', null)) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Directory/get/all', request);
  return await resp.json();
}

export async function saveDirectoryFolder(
  designOwners: string[],
  designEditors: string[],
  directoryFolder: DirectoryFolder
) {
  const request = (await createAuthRequest('POST', {
    designOwners,
    designEditors,
    directoryFolder
  })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Directory/save', request);
  return await resp.json();
}


export async function saveShortcut(folderId: string, shortcutId: string ) {
  const request = (await createAuthRequest('POST', { folderId, shortcutId })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Directory/saveShortcut', request);
  return await resp.json();
}

export async function deleteShortcut(folderId: string, shortcutId: string) {
  const request = (await createAuthRequest('DELETE', { folderId, shortcutId })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Directory/deleteShortcut', request);
  return resp.json();
}

export async function deleteDirectoryFolder(id: string) {
  const request = (await createAuthRequest('DELETE', { id })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Directory/delete', request);
  return true;
}

// Permissions

export async function getAssemblyPermissions(id: string) {
  const request = (await createAuthRequest('GET')) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/permission/assembly?id=' + id, request);
  return await resp.json();
}

export async function moveAssemblyToDirectory(assemblyId: string, folderId: string) {
  const request = (await createAuthRequest('POST', { assemblyId, folderId })) as RequestInit;
  const resp = await fetch(config.APIUrl + '/api/Directory/move/assembly', request);
  return await resp.json();
}

export async function getUserIsAdmin() {
  try {
    const request = (await createAuthRequest('GET')) as RequestInit;
    const resp = await fetch(config.APIUrl + '/api/permission/get/user-role', request);  
    if (resp.ok && resp.status === 200) {
      const text = await resp.text();
      return text ? JSON.parse(text) : null;
    } else {
      console.error('Erreur de réponse de l\'API', resp.status);
      return null;
    }
  } catch (error) {
    console.error('Erreur lors de la récupération du rôle de l\'utilisateur', error);
    return null;
  }
}