import { Button, Modal } from 'hakobio-react-ui';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { actionMessages } from '../../../../lang/messages';
import Uuidv4 from '../../../Shared/Uuidv4';
import { useAppSelector } from '../../../../store';
import PrivacyModalContent from './PrivacyModalContent';
import { Shareable } from '../../../../utilities/Shareable';
import { useSharingAction } from '../../../../store/features/assemblyEditor/useSharingAction';
import { useStatusAction } from '../../../../store/features/assemblyEditor/useStatusAction';
import { GenericStatus } from '../../../../utilities/statuses/genericStatus';
import { ReferenceStatus } from '../../../../utilities/statuses/referenceStatus';

interface PrivacyModalProps {
  closeModal: Function;
  handleConfirm: Function;
  statusToChange: null | string;
}

const PrivacyModal: FC<PrivacyModalProps> = ({ closeModal, handleConfirm, statusToChange }) => {
  const { changeStatus } = useStatusAction();
  const { setPrivacy } = useSharingAction();

  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const [_isPrivate, setIsPrivate] = useState<boolean>(general.isPrivate || false);

  const intl = useIntl();

  const handleSetPrivacy = (isPrivate) => {
    setIsPrivate(!isPrivate);
    const shareable: Shareable = {
      id: Uuidv4(),
      shareableContentId: general.id,
      contentType: 'sud.assembly',
      isPrivate: !isPrivate,
      shareWith: [],
      except: []
    };
    setPrivacy(shareable, isPrivate);
  };

  
  const _isDisable = general.isPrivate && (
    statusToChange === GenericStatus.Proposal ||
    statusToChange === GenericStatus.Detailed ||
    statusToChange === GenericStatus.Freeze ||
    statusToChange === GenericStatus.Obsolete ||
    statusToChange === ReferenceStatus.OnboardingQualification ||
    statusToChange === ReferenceStatus.Effective ||
    statusToChange === ReferenceStatus.Obsolete
  );


  return (
    <Modal
      title={intl.formatMessage({
        id: 'PrivacyModal.Title',
        defaultMessage: 'Change your privacy settings'
      })}
      cancel={closeModal}
      footer={
        <div className="f-row f1-end">
          <Button disabled={_isDisable}
           onClick={handleConfirm}>{intl.formatMessage(actionMessages.OK)}</Button>
        </div>
      }>
      <PrivacyModalContent setPrivacy={handleSetPrivacy} isPrivate={_isPrivate} />
    </Modal>
  );
};

export default PrivacyModal;
