import { IntlProvider } from 'react-intl';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import SUConfigView from '../../views/SUConfigView';

import { HakoBioActionType, HakoBioEventType, HakoBioPluginSDK } from 'hakobio-plugin-sdk';
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import messagesInEnglish from '../../lang/en.json';
import messagesInFrench from '../../lang/fr.json';
import messagesInSpanish from '../../lang/es.json';
import messagesInGerman from '../../lang/de.json';
import { useAppDispatch } from '../../store';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import { setColors } from '../../utilities';
import ReportPanel from '../ReportPanel/ReportPanelUO';
import StartupList from '../StartupPanel/StartupList';
import { StartupPanel } from '../StartupPanel/StartupPanel';
import HistoryView from '../../views/HistoryView';
import HistoryViewUo from '../../views/HistoryViewUo';

const messages: any = {
  en: messagesInEnglish,
  es: messagesInSpanish,
  fr: messagesInFrench,
  de: messagesInGerman
};

function App() {
  const dispatch = useAppDispatch();
  const [_language, setLanguage] = useState<string>('en');
  const callbackJWT = (data) => {
    dispatch(assemblyEditorSliceActions.setJWT({ jwt: data.jwt, jwtTime: data.time }));
  };

  const callbackConfiguration = (data) => {
    setColors(data.theme);
    setLanguage(data.user.language);
    dispatch(assemblyEditorSliceActions.setUser({ user: data.user }));
    dispatch(assemblyEditorSliceActions.setTheme({ theme: data.theme }));
    dispatch(assemblyEditorSliceActions.setUserList({ userList: data.userList }));
  };

  React.useEffect(() => {
    HakoBioPluginSDK.postConfiguration(HakoBioActionType.Configuration, { name: 'SUD' });
  }, []);

  React.useEffect(() => {
    HakoBioPluginSDK.addEventListener(HakoBioEventType.JWT, callbackJWT);
    HakoBioPluginSDK.addEventListener(HakoBioEventType.Configuration, callbackConfiguration);
    return () => {
      HakoBioPluginSDK.removeEventListener(HakoBioEventType.JWT, callbackJWT);
      HakoBioPluginSDK.removeEventListener(HakoBioEventType.Configuration, callbackConfiguration);
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <IntlProvider locale={_language} messages={messages[_language]} defaultLocale="fr">
        <div className="App f-col h-100">
          <ToastContainer
            style={{ zIndex: 999999 }}
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
            theme="colored"
            icon={false}
          />

          <BrowserRouter>
            <Switch>
              <Route path="/editor/Component" component={SUConfigView} />
              <Route path="/editor/SingleUseAssembly" component={SUConfigView} />
              <Route path="/editor/Reference" component={SUConfigView} />
              <Route path="/editor/UnitOperation" component={SUConfigView} />
              <Route path="/viewer/history" component={HistoryView} />
              <Route path="/viewer/uohistory" component={HistoryViewUo} />
              <Route path="/list" component={StartupList} />
              <Route path="/editor" component={StartupPanel} />
              <Route path="/report" component={ReportPanel} />
              <Redirect from="/" to="/list" />
            </Switch>
          </BrowserRouter>
        </div>
      </IntlProvider>
    </DndProvider>
  );
}

export default App;
