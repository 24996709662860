import Color from "color";

export function Uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const setColors = (theme: any) => {
  const colors = ["primaryColor", "primaryBackgroundColor", "secondaryColor"];
  colors.forEach((c) => {
    document.documentElement.style.setProperty(`--${c}`, theme[c]);
  });
  document.documentElement.style.setProperty(
    `--primaryColorBcg`,
    // @ts-ignore
    Color(theme.primaryColor).mix(Color('white'), 0.9)
  );
  document.documentElement.style.setProperty(
    `--secondaryColorBcg`,
    // @ts-ignore
    Color(theme.secondaryColor).mix(Color('white'), 0.9)
  );
};

export const getColors = () => {
  const primaryColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--primaryColor")
    .trim();
  const primaryColorAlpha = Color(primaryColor)
    .mix(Color("white"), 0.9)
    .string();

  const secondaryColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--secondaryColor")
    .trim();
  const secondaryColorAlpha = Color(secondaryColor)
    .mix(Color("white"), 0.9)
    .string();
  return {
    primaryColor,
    primaryColorAlpha,
    secondaryColor,
    secondaryColorAlpha,
  };
};

export const angle = (cx: any, cy: any, ex: any, ey: any) => {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
};
