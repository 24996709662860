import Project from './Project.js';
export default class BuilderModel {
  static LogHook = (hookname, data) => {
    if (process.env.NODE_ENV !== 'production' || window.showLog) {
      // console.log('Hook : ' + hookname + ', data : ', data);
    }
  };

  static setupPluginApi = () => {
    window.solutionBuilder = {
      call: function (name, param) {
        let object;
        try {
          object = JSON.parse(param);
        } catch (e) {
          object = param;
        }

        if (window.solutionBuilder.hook.hooks[name]) {
          BuilderModel.LogHook(name, object);
          for (let i = 0; i < window.solutionBuilder.hook.hooks[name].length; ++i)
            window.solutionBuilder.hook.hooks[name][i]
              ? window.solutionBuilder.hook.hooks[name][i](object)
              : console.log('Hook with name ' + name + ' has no subscriber');
        }
      },
      hook: {
        hooks: [],

        register: function (name, func) {
          if (!window.solutionBuilder.hook.hooks[name])
            window.solutionBuilder.hook.hooks[name] = [];

          if (window.solutionBuilder.hook.hooks[name].some((c) => c === func)) {
            console.log(
              `Hook with name ${name} is trying to register the same function multiple times. Registration cancelled`
            );

            return;
          }
          window.solutionBuilder.hook.hooks[name].push(func);
        },

        unregister: function (name, func) {
          if (window.solutionBuilder.hook.hooks[name]) {
            const size = window.solutionBuilder.hook.hooks[name].length;
            window.solutionBuilder.hook.hooks[name].splice(
              window.solutionBuilder.hook.hooks[name].indexOf(func) >>> 0,
              1
            );
            if (size === window.solutionBuilder.hook.hooks[name].length)
              console.log(`hook with name ${name} but function to remove not found`);
          } else console.log(`hook with name ${name} does not exist`);
        }
      }
    };
  };
  constructor() {
    this.Project = Project;
  }
  /**
   * Dont pass an arrow function if you want to unregister later
   * @param {string} hookName
   * @param {Function} func
   */
  static register(hookName, func) {
    window.solutionBuilder.hook.register(hookName, func);
  }
  /**
   *
   * @param {string} hookName
   * @param {Function} func
   */
  static unregister(hookName, func) {
    window.solutionBuilder.hook.unregister(hookName, func);
  }
}
BuilderModel.setupPluginApi();
