import {
  DoubleButton,
  Empty,
  Icon,
  ThinChip,
  Tooltip,
  UserCard,
} from "hakobio-react-ui";
import { useEffect, useState } from "react";
import { FormattedDate, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { ListTab } from "../../constants/ListTab";
import { useAppDispatch, useAppSelector } from "../../store";
import { assemblyEditorSliceActions } from "../../store/features/assemblyEditor/assemblyEditorSlice";
import InfiniteScrollWindow from "../Shared/UI/InfiniteScrollWindow";
import { useStyles } from "./styles.startuppanel";
import { cloneDeep } from "lodash";
import AssemblyMenu from "./AssemblyMenu";
import { actionMessages } from "../../lang/messages";
import {
  genericStatusBackgroundColors,
  genericStatusColors,
} from "../../utilities/statuses/genericStatus";
import {
  referenceStatusBackgroundColors,
  referenceStatusColors,
} from "../../utilities/statuses/referenceStatus";
import { useCustomBehavior } from "../../utils";

const ListView = (props: any) => {
  const dispatch = useAppDispatch();
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);

  const { classes } = useStyles();

  const [_windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [_lineHovered, setLineHovered] = useState(null);
  const [_buttonHovered, setButtonHovered] = useState(null);
  const [_showDeleteModal, setShowDeleteModal] = useState(null);

  const intl = useIntl();
  const history = useHistory();

  const { openReactAppHistoryInNewTab } = useCustomBehavior();

  useEffect(() => {
    window.addEventListener("resize", () => {
      const height = window.innerHeight;
      setWindowHeight(height);
    });
  }, [_showDeleteModal]);

  const openAssembly = (assembly: any) => {
    dispatch(assemblyEditorSliceActions.setCustomComponentViewOnly(false));
    const path =
      listTab === ListTab.SUA
        ? assembly.type === "SingleUseAssembly"
          ? "/editor/SingleUseAssembly/" + assembly?.id
          : "/editor/Reference/" + assembly?.id
        : "/editor/UnitOperation/" + assembly?.id;
    history.push(path);
  };

  const openAssemblyVersion = (assembly: any) => {
    let assemblyCopy = cloneDeep(assembly);
    assemblyCopy.hashPartitionKey = assembly.id + "-" + assembly.version;
    localStorage.setItem("action", "assemblyVersion");
    localStorage.setItem("actionData", JSON.stringify(assemblyCopy));
    openReactAppHistoryInNewTab();
  };

  function getStatus(assembly: any) {
    const assemblyStatus =
      assembly?.parameters?.find((param) => param.name === "status")?.value ||
      null;
    if (assemblyStatus !== null) {
      return assemblyStatus;
    } else {
      return "No status";
    }
  }

  function getColorAssembly(assembly: any) {
    return assembly.type === "SingleUseAssembly"
      ? genericStatusColors(getStatus(assembly))
      : referenceStatusColors(getStatus(assembly));
  }

  function getBackgroundColors(assembly: any) {
    return assembly.type === "SingleUseAssembly"
      ? genericStatusBackgroundColors(getStatus(assembly))
      : referenceStatusBackgroundColors(getStatus(assembly));
  }

  const renderLine = ({ index, style }: any) => {
    const assembly = props.list[index];
    const showModalHistory = () => {
      dispatch(
        assemblyEditorSliceActions.setShowHistoryModal({
          assemblyId: assembly?.id,
        }),
      );
    };

    const date = assembly.modifiedOn;
    const newDate = new Date(date);
    return (
      <div
        id={assembly.id}
        style={style}
        className={`${classes.listItem} hover-item px-2 `}
        onMouseEnter={() => setLineHovered(index)}
        onMouseLeave={() => setLineHovered(null)}
        onClick={() => {
          if (
            !_buttonHovered &&
            assembly.type !== "SingleUseAssemblyReference" &&
            assembly?.permissions?.read
          )
            // a verifier avec Valentine pour l'history redirection d'un sua ref
            openAssemblyVersion(assembly);
        }}
      >
        <div className="f-row gap-2 f2-center">
          {assembly.isShortCut && (
            <div
              style={{
                color: "var(--primaryColor)",
                backgroundColor: "var(--primaryColorBcg)",
                borderRadius: "4px",
                padding: ".2rem .2rem .25rem .25rem",
                border: "1px solid var(--grey)",
              }}
            >
              <Tooltip title={"Shortcut"}>
                <Icon name="external-link" size={14} />
              </Tooltip>
            </div>
          )}
          <div
            title={assembly.name}
            className={`${assembly.isShortCut ? "" : "pl-2"} max-lines-1 small-text }`}
            style={{ justifySelf: "start", alignSelf: "center" }}
          >
            {assembly.name}
          </div>
        </div>
        <div
          className="small-text"
          style={{ justifySelf: "start", alignSelf: "center", paddingLeft: 8 }}
        >
          v.{assembly.version}
        </div>
        <div style={{ justifySelf: "start", alignSelf: "center" }}>
          <ThinChip
            fontSize="smallText"
            center
            color={getColorAssembly(assembly)}
            backgroundColor={getBackgroundColors(assembly)}
            label={getStatus(assembly)}
          />
        </div>
        <div style={{ justifySelf: "start", alignSelf: "center" }}>
          <UserCard small firstName={assembly?.createdByName} />
        </div>
        <div
          className="small-text"
          style={{ justifySelf: "start", alignSelf: "center" }}
        >
          <FormattedDate
            value={newDate}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </div>
        <div style={{ justifySelf: "center", alignSelf: "center" }}>
          {_lineHovered === index && (
            <div
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
            >
              <DoubleButton
                size="small"
                className="double-btn"
                onClick={() =>
                  assembly?.permissions?.update && openAssembly(assembly)
                }
                popContent={<AssemblyMenu assembly={assembly} />}
              >
                {assembly?.permissions?.update ? (
                  <div className="f-row gap-2 f2-center">
                    <Icon name="pencil" />
                    {intl.formatMessage(actionMessages.edit)}
                  </div>
                ) : (
                  <div
                    className="f-row gap-2 f2-center"
                    onClick={() => showModalHistory()}
                  >
                    <Icon name="history" />
                    {intl.formatMessage({
                      id: "ListView.History",
                      defaultMessage: "History",
                    })}
                  </div>
                )}
              </DoubleButton>
            </div>
          )}
        </div>
      </div>
    );
  };

  const innerElement = ({ style, ...rest }: any) => {
    return (
      <div id="testid" style={{ ...style }}>
        {rest.children}
      </div>
    );
  };

  return (
    <>
      <div className="f-full f-col">
        <div
          className="border-bottom p-2"
          style={{
            display: "grid",
            gridTemplateColumns: " 3fr 1fr 2fr 3fr 2fr 2fr",
          }}
        >
          <div className="capitalize font-regular pl-2">
            {intl.formatMessage({
              id: "ListView.Name",
              defaultMessage: "Name",
            })}
          </div>
          <div className="capitalize font-regular">
            {intl.formatMessage({
              id: "ListView.Version",
              defaultMessage: "Version",
            })}
          </div>
          <div className="capitalize font-regular">
            {intl.formatMessage({
              id: "ListView.Status",
              defaultMessage: "Status",
            })}
          </div>
          <div className="capitalize font-regular">
            {intl.formatMessage({
              id: "ListView.CreatedBy",
              defaultMessage: "Created by",
            })}
          </div>
          <div className="capitalize font-regular">
            {intl.formatMessage({
              id: "ListView.LastModified",
              defaultMessage: "Last modified",
            })}
          </div>
          <div className="capitalize font-regular"></div>
        </div>
        {props.list?.length > 0 ? (
          <div style={{ height: _windowHeight - 250 }}>
            {/* @ts-ignore */}
            <InfiniteScrollWindow
              // @ts-ignore
              innerElementType={innerElement}
              itemData={props.list}
              children={renderLine}
              type={"list"}
            />
          </div>
        ) : (
          <div
            style={{ height: _windowHeight - 250 }}
            className="f-col f-center"
          >
            <Empty
              text={intl.formatMessage({
                id: "ProjrctViewList.FolderEmty",
                defaultMessage: "This folder is empty",
              })}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ListView;
