import { NodePropertyAnchorAdd } from '../AnchorPropertyDisplay/NodePropertyAnchorAdd';
import { NodePropertyAnchor } from '../AnchorPropertyDisplay/NodePropertyAnchor';
import { AnchorDragEditor } from '../AnchorPropertyDisplay/AnchorDragEditor';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useEffect, useRef, useState } from 'react';
import { ViewMode } from '../../../../../constants/ViewMode';
import { EditorMode } from '../../../../../constants/EditorMode';
import { useScreenshot } from '../../../../../utilities/useScreenshot';
import { assemblyEditorSliceActions } from '../../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { savePNGComponent } from '../../../../../services/editor/editorService';
import { CubeLoader } from 'hakobio-react-ui';

export function NodePropertyArray({
  displayParameters,
  keyProperty,
  property,
  schema,
  customComponentId
}: {
  displayParameters: any;
  keyProperty: string;
  property: any;
  schema: any;
  customComponentId?: null | string;
}) {
  const dispatch = useAppDispatch();

  const typeItemFull = property.items.$ref.split('/');
  const typeItem = typeItemFull[typeItemFull.length - 1];
  const subSchema = schema.$defs?.[typeItem];

  const [_selectedComponent, setSelectedComponent] = useState(null) as any;
  const [_reloadSelected, setReloadSelected] = useState(false) as any;
  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);

  const [image, takeScreenshot, clearScreenshot] = useScreenshot(1);

  const isSavingScreenshotComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotComponent
  );

  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const selectedComponentId = selectedComponents && selectedComponents[0];
    const selectedComponent = components.find((c: any) => c.id === selectedComponentId);
    setSelectedComponent(JSON.parse(JSON.stringify(selectedComponent)));
  }, []);

  useEffect(() => {
    const selectedComponentId = selectedComponents && selectedComponents[0];
    const selectedComponent = components.find((c: any) => c.id === selectedComponentId);
    setSelectedComponent(JSON.parse(JSON.stringify(selectedComponent)));
  }, [selectedComponents, components]);

  useEffect(() => {
    if (_reloadSelected) {
      const selectedComponentId = selectedComponents && selectedComponents[0];
      const selectedComponent = components.find((c: any) => c.id === selectedComponentId);
      setSelectedComponent(JSON.parse(JSON.stringify(selectedComponent)));
      setReloadSelected(false);
    }
  }, [_reloadSelected]);

  useEffect(() => {
    const takeScreenshotAsync = () => {
      setTimeout(() => {
        takeScreenshot(reactFlowWrapper.current);
      }, 1000);
    };
    if (
      isSavingScreenshotComponent &&
      reactFlowWrapper.current &&
      _selectedComponent.data.type !== 'tubing'
    ) {
      takeScreenshotAsync();
    }
  }, [isSavingScreenshotComponent]);

  useEffect(() => {
    if (image) {
      savePNGComponent(customComponentId, image);
      clearScreenshot();
      dispatch(assemblyEditorSliceActions.setSavingScreenshotComponent(false));
    }
  }, [image]);

  const updateComponent = () => {
    setReloadSelected(true);
  };

  if (_selectedComponent === undefined) {
    return null;
  }

  if (subSchema && _selectedComponent) {
    return (
      <div className="f-full f-col" style={{ overflowY: 'auto', alignItems: 'stretch' }}>
        {typeItem === 'anchor' && (
          <>
            {general?.permissions?.update && viewMode === ViewMode.Editor && (
              <>
                <NodePropertyAnchorAdd
                  keyProperty={keyProperty}
                  onClick={null}
                  selectedComponent={_selectedComponent}
                />
                <div className="relative">
                  {isSavingScreenshotComponent && (
                    <div
                      className="absolute f-center border"
                      style={{
                        backgroundColor: 'rgba(255,255,255,.9)',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 4
                      }}>
                      <CubeLoader />
                    </div>
                  )}
                  {property.positionnable !== false && (
                    <div className="py-1 f-center relative" ref={reactFlowWrapper}>
                      <AnchorDragEditor
                        schema={schema}
                        selectedNode={_selectedComponent}
                        keyProperty={keyProperty}
                        updateComponent={updateComponent}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="py-1">
              {_selectedComponent.data?.anchors?.map((item: any, index: number) => {
                return (
                  <NodePropertyAnchor
                    key={item.id}
                    subSchema={subSchema}
                    item={item}
                    keyProperty={keyProperty}
                    index={index}
                    property={property}
                  />
                );
              })}
            </div>
          </>
        )}
        {typeItem === 'instrumentationPort' && (
          <div className="py-1">
            {_selectedComponent.data?.instrumentationPorts?.map((item: any, index: number) => {
              return (
                <NodePropertyAnchor
                  key={item.id}
                  subSchema={subSchema}
                  item={item}
                  keyProperty={keyProperty}
                  index={index}
                  property={property}
                />
              );
            })}
          </div>
        )}
        {typeItem === 'samplingPort' && (
          <div className="py-1">
            {_selectedComponent.data?.samplingPorts?.map((item: any, index: number) => {
              return (
                <NodePropertyAnchor
                  key={item.id}
                  subSchema={subSchema}
                  item={item}
                  keyProperty={keyProperty}
                  index={index}
                  property={property}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  } else {
    return <div>Schema incomplete</div>;
  }
}
