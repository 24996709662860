import { ExecuteAction, SimpleExecuteAction } from "../ExecuteAction.js";
import PluginApiActions from "./PluginApiActions.js";
//import SocketTypes from '../../App/Features/tubing/models/SocketTypes.js';

export default class UnityAPI {
  /**
   * @param {Project} project
   * @return null
   */
  static setProject(project) {
    return ExecuteAction(PluginApiActions.SetProject, project);
  }
  /**
   * @param {string} zoneId
   * @return {Promise<any>}
   */
  static getZoneElements(zoneId) {
    return ExecuteAction(PluginApiActions.GetZoneElements, zoneId);
  }
  /**
   * @param {string[]} keys
   * @return null
   */
  static KeyPress(keys) {
    return ExecuteAction(PluginApiActions.Keypress, { keypress: keys });
  }
  /**
   * @param {number} displayMode - enum 0: Translucent 1: Invisible 2: Opaque 3: NoCeiling
   * @return null
   */
  static setWallDisplayMode(displayMode) {
    return ExecuteAction(PluginApiActions.SetWallDisplayMode, displayMode);
  }
  /**
   * @param {string=} zoneId
   * @param {boolean=} showZoneOnly
   */
  static setEditMode(zoneId = "", showZoneOnly = false) {
    return ExecuteAction(PluginApiActions.Isolate, {
      zoneId: zoneId,
      showZoneOnly: showZoneOnly,
    });
  }

  static takeScreenshot() {
    return ExecuteAction(PluginApiActions.TakeScreenshot);
  }
  /**
   * @param {Payload=} payload
   */
  static FrameAndSnap(payload) {
    return ExecuteAction(PluginApiActions.FrameAndSnap, payload);
  }
  /**
   *
   * @deprecated  unused
   */
  static drawLines(lines) {
    return ExecuteAction(PluginApiActions.DrawLines, { lines });
  }
  /**
   *
   * @param {string} elementId
   * @param {number} width
   * @param {number} length
   * @param {number} height
   * @returns
   */
  static setElementScale(elementId, width, length, height) {
    return ExecuteAction(PluginApiActions.SetElementScale, {
      elementId,
      x: width,
      z: length,
      y: height,
    });
  }
  /**
   *
   * @param {string} elementId
   * @returns
   */
  static nextStep(elementId) {
    return ExecuteAction(PluginApiActions.NextStep, elementId);
  }
  /**
   *
   * @param {Payload} payload
   * @returns null
   */
  static split(payload) {
    return ExecuteAction(PluginApiActions.Split, payload);
  }
  /**
   *
   * @param {Payload} payload
   * @returns null
   */
  static enableWall(payload) {
    return ExecuteAction(PluginApiActions.EnableWall, payload);
  }
  /**
   *
   * @param {Payload} payload
   * @returns null
   */
  static delete(payload) {
    return ExecuteAction(PluginApiActions.Delete, payload);
  }
  /**
   *
   * @param {CamDef} camera
   * @returns null
   */
  static setCamera(camera) {
    return ExecuteAction(PluginApiActions.SetCamera, camera);
  }

  /**
   *
   * @param {CamDef} camera
   * @returns null
   */
  static SetCameraPosition(camera) {
    return ExecuteAction(PluginApiActions.SetCameraPosition, camera);
  }
  /**
   *
   * @param {string} projectId
   * @returns
   */
  static setProjectImages(projectId) {
    return ExecuteAction(PluginApiActions.SetProjectImages, projectId);
  }
  /**
   *
   * @param {string[]} excepIdList
   * @returns
   */
  static LockElements(excepIdList = []) {
    return ExecuteAction(PluginApiActions.LockElements, {
      except: excepIdList,
    });
  }
  /**
   *
   * @param {string[]} excepIdList
   * @returns
   */
  static UnlockElements(excepIdList = []) {
    return ExecuteAction(PluginApiActions.UnlockElements, {
      except: excepIdList,
    });
  }

  /////////////////////////
  /// BUILDER BACKROUND ///
  /////////////////////////

  /**
   *
   * @param uri the url to the hosted image
   * @param ratio pixels per meter
   * @param x posx
   * @param z posz
   */
  static setBackground(uri, ratio = 0, x = 0, z = 0, visible = true) {
    return ExecuteAction(PluginApiActions.SetBackground, {
      uri: uri,
      ratio: ratio,
      backgroundX: x,
      backgroundZ: z,
      isVisible: visible,
    });
  }

  static setFpvFocus(hasFocus) {
    return ExecuteAction(PluginApiActions.SetFpvFocus, hasFocus);
  }

  static fpvClick() {
    return ExecuteAction(PluginApiActions.FpvClick);
  }

  /**
   * @param {{"name" : string, "height" : number}} [data]
   */
  static createZonePoints(data = null) {
    // UnityAPI.cancelCreateZonePoints();
    if (data === null) {
      return ExecuteAction(PluginApiActions.CreateZonePoints);
    } else {
      return ExecuteAction(PluginApiActions.CreateZonePoints, data);
    }
  }

  static cancelCreateZonePoints() {
    return ExecuteAction(PluginApiActions.StopCreateZonePoints);
  }

  static stopCreateZonePoints() {
    return ExecuteAction(PluginApiActions.StopCreateZonePoints);
  }

  ///////////////////////////////////
  // Utils to draw arrows ===>===  //
  ///////////////////////////////////

  static UpdateArrows(arrows) {
    return ExecuteAction(PluginApiActions.UpdateArrows, arrows);
  }
  /**
   *
   * @param {{ id:string, point:Object, rotation: number,
   * elementId: string, arrowOnly: boolean, arrowType: boolean,
   * color: Object,index: number, hidden: boolean, cloneAll: boolean}[]} ghosts
   * @returns {Promise<boolean>}
   */
  static UpdateGhosts(ghosts) {
    return ExecuteAction(PluginApiActions.UpdateGhosts, ghosts);
  }

  static SetWallHeight(payload) {
    return ExecuteAction(PluginApiActions.SetWallHeight, payload);
  }

  static Undo() {
    return ExecuteAction(PluginApiActions.Undo);
  }

  static Redo() {
    return ExecuteAction(PluginApiActions.Redo);
  }

  static Copy() {
    return ExecuteAction(PluginApiActions.Copy);
  }

  static Paste() {
    return ExecuteAction(PluginApiActions.Paste);
  }

  static UpdateLabels(labels) {
    return ExecuteAction(PluginApiActions.UpdateLabels, labels);
  }

  /**
   *
   * @param {{type : number,color : string, ids : string[]}} OutlineParam
   */
  static Outline(OutlineParam) {
    return ExecuteAction(PluginApiActions.Outline, OutlineParam);
  }

  /**
   *
   * @param {{ elements: HakobioElement[] }} SetSelectionParam
   */
  static SetSelection(SetSelectionParam) {
    return ExecuteAction(PluginApiActions.SetSelection, SetSelectionParam);
  }

  /**
   *
   * @param {{ elements: HakobioElement[] }} LocateParam
   */
  static Locate(LocateParam) {
    return ExecuteAction(PluginApiActions.Locate, LocateParam);
  }

  /////////////////////////
  /// Configure Sockets ///
  /////////////////////////

  static configureEquipmentSocket(elementId) {
    return ExecuteAction(PluginApiActions.ConfigureEquipment, {
      elements: [{ id: elementId }],
    });
  }
  static configureEquipmentOnly(elementId) {
    return ExecuteAction(PluginApiActions.ConfigureEquipmentOnly, {
      elements: [{ id: elementId }],
    });
  }

  static exitConfigureEquipmentSocket() {
    return ExecuteAction(PluginApiActions.ConfigureEquipment, {});
  }

  static createNewSocket(input, output) {
    return ExecuteAction(PluginApiActions.CreateSocket, {
      input,
      output,
    });
  }

  // This function first deletes all the sockets and then adds all the sockets given
  /*  static updateSockets(data) {
    const sockets = data.map((d) => {
      return {
        id: d.id,
        input: d.socketType === SocketTypes.In || d.socketType === SocketTypes.Mixed,
        output: d.socketType === SocketTypes.Out || d.socketType === SocketTypes.Mixed,
        elementId: d.elementId,
        position: d.position,
        orientation: d.orientation,
        name: d.name
      };
    });

    return ExecuteAction(PluginApiActions.UpdateSockets, sockets);
  } */

  static removeSockets(sockets) {
    return ExecuteAction(PluginApiActions.RemoveSockets, sockets);
  }

  /**
   *
   * @param {TubeGroup[]} tubes
   */
  static addTubes(tubes) {
    return ExecuteAction(PluginApiActions.AddTubes, tubes);
  }

  /**
   *
   * @param {TubeGroup[]} tubes
   */
  static updateTubes(tubes) {
    return ExecuteAction(PluginApiActions.UpdateTubes, tubes);
  }

  static ChangeScene(sceneName) {
    return ExecuteAction(PluginApiActions.ChangeScene, sceneName);
  }

  // FlowEditorUO
  static updateFlows(flows) {
    return ExecuteAction(PluginApiActions.UpdateFlows, flows);
  }
  static startFlowFreeDraw(flow) {
    return ExecuteAction(PluginApiActions.StartFlowFreeDraw, flow);
  }
  static endFlowFreeDraw() {
    return ExecuteAction(PluginApiActions.EndFlowFreeDraw, {});
  }

  static BuilderExit() {
    return ExecuteAction(PluginApiActions.BuilderExit, {});
  }

  //state: bool
  static BuilderFocus(state) {
    return ExecuteAction(PluginApiActions.BuilderFocus, state);
  }

  //Drag and drop
  static startDrag(payload) {
    return ExecuteAction(PluginApiActions.DragStart, payload);
  }

  static sendMousePosition(mousePosition) {
    SimpleExecuteAction(PluginApiActions.SendMousePosition, mousePosition);
  }

  static dragCancel() {
    return ExecuteAction(PluginApiActions.DragCancel);
  }

  static drop() {
    return ExecuteAction(PluginApiActions.DragEnd);
  }
  /**
   * @param {Payload} payload
   * @return null
   */
  static addElements(payload) {
    return ExecuteAction(PluginApiActions.AddElements, payload);
  }

  static setAngle(angle) {
    return ExecuteAction(PluginApiActions.SetAngle, angle);
  }

  static setGlobalConfig(config) {
    return ExecuteAction(PluginApiActions.SetGlobalConfig, config);
  }

  static startTour() {
    return ExecuteAction(PluginApiActions.StartGuidedTour);
  }
  /**
   *
   * @param {HakobioElement[]} elements
   * @returns {Promise<ActionablesInfo[]>} possible actions for elements
   */
  static GetTrainingActions(elements) {
    return ExecuteAction(PluginApiActions.GetPossibleActions, elements);
  }
  /**
   *
   * @param {EquipmentStateRequest[]} data
   * @returns void
   */
  static SetEquipmentState(data) {
    return ExecuteAction(PluginApiActions.SetEquipmentState, data);
  }

  /**
   *
   * @param {string[]} data
   * @returns {Promise<Object.<string, Object.<string, Array.<string[]>>>>} states of asked objects
   */
  static GetCurrentStates(data = []) {
    return ExecuteAction(PluginApiActions.GetCurrentStates, data);
  }

  /**
   *
   * @param {{ id:string,target?:string,withParent? : boolean,position?: any, rotation?: any}} data
   * @returns void
   */
  static SetupSetPosition(data) {
    return ExecuteAction(PluginApiActions.SetupSetPosition, data);
  }
  /**
   *
   * @param {string} path
   * @returns null
   */
  static SetupMovePosition(path) {
    return ExecuteAction(PluginApiActions.MovePosition, path);
  }

  /**
   *
   * @param {string} actionId
   */
  static ResetTransforms(actionId = "start") {
    console.log("ResetTransforms", actionId);
    return ExecuteAction(PluginApiActions.ResetTransforms, actionId);
  }
  /**
   * @param {string} actionId
   * @returns null
   */
  static SaveTransforms(actionId) {
    console.log("SaveTransforms", actionId);
    return ExecuteAction(PluginApiActions.SaveTransforms, actionId);
  }
  /**
   * @param {string} path
   * @returns {Promise<boolean>}
   */
  static IsPathExist(path) {
    return ExecuteAction(PluginApiActions.isPathExist, path);
  }
}
