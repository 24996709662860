import { cloneDeep } from 'lodash';
import { isNode } from 'react-flow-renderer';
import { ViewMode } from '../../../constants/ViewMode';

export const getElementsPFD = (components, viewMode) => {
  const elementList = [];
  components.forEach((c) => {
    if (c.type === 'functionedge' && c.data.ghost) {
      return;
    }
    if (c.type !== 'function' && c.type !== 'functionedge') {
      return;
    }
    if (isNode(c)) {
      let node = cloneDeep(c) as any;
      node.position = { x: node.viewer2D.pfd.x, y: node.viewer2D.pfd.y };
      node.selectable = true;
      node.draggable = viewMode===ViewMode.HistoryUO ? false : true;
      node.isHidden = false;
      elementList.push(node);
      return;
    } else {
      let edge = cloneDeep(c);
      edge.style = {};
      edge.animated = false;
      elementList.push(edge);
      return;
    }
  });
  return elementList;
};


function useAppSelector(arg0: (state: any) => any) {
  throw new Error('Function not implemented.');
}
// export enum ViewMode {
//   Editor = 0,
//   History = 1,
// }


// const target = components.find((co: any) => co.id === c.target);
// const source = components.find((co: any) => co.id === c.source);
// if (target && target.type === 'generic') {
//   let anchorTarget;
//   anchorTarget = target.data.anchors.find((a: any) => a.id === c.targetHandle);
//   if(!anchorTarget)
//     anchorTarget = target.data.instrumentationPorts.find((a: any) => a.id === c.targetHandle);
//   if(!anchorTarget)
//     anchorTarget = target.data.samplingPorts.find((a: any) => a.id === c.targetHandle);
//   edge.target = anchorTarget.data.componentLink?.component;
//   edge.targetHandle = anchorTarget.data.componentLink?.anchor;
// }
// if (source && source.type === 'generic') {
//   let anchorSource;
//   anchorSource = source.data.anchors.find((a: any) => a.id === c.sourceHandle);
//   if(! anchorSource)
//     anchorSource = source.data.instrumentationPorts.find((a: any) => a.id === c.sourceHandle);
//   if(! anchorSource)
//     anchorSource = source.data.samplingPorts.find((a: any) => a.id === c.sourceHandle);
//   edge.source = anchorSource.data.componentLink?.component;
//   edge.sourceHandle = anchorSource.data.componentLink?.anchor;
//}

