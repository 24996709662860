import { CustomIcon, DoubleChip, IconChip, IconTooltip, ThinChip, Title } from 'hakobio-react-ui';
import { CSSProperties, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getColors } from '../../../../utilities';
import { deleteCustomComponent } from '../../../../services/editor/editorService';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import _ from 'lodash';
import { EditorMode } from '../../../../constants/EditorMode';
import { NodeAnchors } from './NodeAnchors';
import { configMessages } from '../../../../lang/messages';
import { useIntl } from 'react-intl';
import { useEditorLibraryAction } from '../../../../store/features/assemblyEditor/useEditorLibraryAction';
import { config } from '../../../../services/config';

const informationIconStyle: CSSProperties = {
  position: 'absolute',
  top: '.5rem',
  right: '.5rem'
};

interface Props {
  keyName: string;
  node: any;
  constant: any;
  component: any;
  isShowingCustomLibrary: boolean;
  isCustomComponent: boolean;
  onDeleteCustomComponent: Function;
  resetSearch?: () => void;
}

export const NodeItem = ({
  keyName,
  node,
  constant,
  component,
  isShowingCustomLibrary,
  isCustomComponent,
  onDeleteCustomComponent,
  resetSearch
}: Props) => {
  const dispatch = useAppDispatch();

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);

  const [_isComponentNameHover, setComponentNameHover] = useState(false);
  const [_isGrabbing, setGrabbing] = useState(false);

  const intl = useIntl();

  const [_isImage, setIsImage] = useState(null);

  useEffect(() => {
    hasImageBlob();
  }, [component]);

  useEffect(() => {}, [_isImage]);

  const onDragStartNode = (event: any) => {
    if (constant.node.snappable) {
      dispatch(assemblyEditorSliceActions.setIsComponentSnappable(true));
    } else {
      dispatch(assemblyEditorSliceActions.setIsComponentSnappable(false));
    }
    if (event.dataTransfer) {
      event.dataTransfer.setData('application/reactflow', JSON.stringify(constant));
      event.dataTransfer.effectAllowed = 'move';
      setGrabbing(true);
    }
  };

  const onDragStartCustomComponent = (event: any) => {
    const constantCopy = _.cloneDeep(constant);
    constantCopy.customComponent = component;
    if (event.dataTransfer) {
      event.dataTransfer.setData('application/reactflow', JSON.stringify(constantCopy));
      event.dataTransfer.effectAllowed = 'move';
    }
  };

  const hasImageBlob = () => {
    async function fetchBlob(url) {
      const response = await fetch(url);
      let res = response.status === 200;
      setIsImage(res);
    }
    if (component) {
      let urlFileComponent =
        config.BlobURL + '/single-use-configurator/components/thumbnail/' + component.id + '.png';
      fetchBlob(urlFileComponent);
    }
  };

  const tooltipContentNode = () => {
    return <div>{`Default ${node.name}`}</div>;
  };

  const tooltipContentCustomComponent = () => {
    return (
      <div style={{ overflowY: 'auto', maxHeight: 400 }}>
        <div className="p-1 font-regular">{component.data.customName}</div>
        <div>
          {Object.entries(component.data)
            .filter(
              ([key, value]: any) =>
                key !== 'name' &&
                key !== 'anchors' &&
                key !== 'instrumentationPorts' &&
                key !== 'samplingPorts'
            )
            .map(([key, value]: any, index: number) => (
              <DoubleChip
                className="capitalize mb-2"
                fontSize="smallText"
                center
                key={key + index}
                label1={key}
                label2={
                  <span className="font-regular">
                    {typeof value === 'boolean' ? (value ? 'On' : 'Off') : value}
                  </span>
                }
              />
            ))}
        </div>
        <Title className="mb-0 w-100">{intl.formatMessage(configMessages.ports)}</Title>
        {component.data.anchors && <NodeAnchors list={component.data.anchors} type="transfer" />}
        {component.data.instrumentationPorts && (
          <NodeAnchors list={component.data.instrumentationPorts} type="instrumentation" />
        )}
        {component.data.samplingPorts && (
          <NodeAnchors list={component.data.samplingPorts} type="sampling" />
        )}
        {component.data.anchors?.length === 0 &&
          component.data.instrumentationPorts?.length === 0 &&
          component.data.samplingPorts?.length === 0 && (
            <span className="frozen-grey">{intl.formatMessage(configMessages.noPort)}</span>
          )}
      </div>
    );
  };

  const renderTooltipContent = () => {
    if (isCustomComponent) {
      return tooltipContentCustomComponent();
    } else {
      return tooltipContentNode();
    }
  };

  const showCustomComponentLibrary = () => {
    dispatch(assemblyEditorSliceActions.setLibComponentName(keyName));
    resetSearch();
  };

  const onDeleteComponent = () => {
    onDeleteCustomComponent(component);
  };

  function handleImageError() {
    setIsImage(false);
  }

  const renderCustomComponentInLibrary = () => {
    return (
      <div
        key={node.name}
        className="f-col"
        style={{ position: 'relative', cursor: _isGrabbing ? 'grabbing' : 'grab' }}>
        <div
          onDragStart={onDragStartCustomComponent}
          onDragOver={() => setGrabbing(false)}
          draggable
          className="f-center mb-1 f-full"
          style={{
            borderRadius: 4,
            backgroundColor: 'var(--primaryColorBcg)'
          }}>
          {_isImage === false ? (
            node.form(80)
          ) : (
            <img
              alt="Component view"
              style={{
                objectFit: 'contain',
                height: 80
              }}
              src={
                config.BlobURL +
                '/single-use-configurator/components/thumbnail/' +
                component.id +
                '.png'
              }
              onError={handleImageError}
            />
          )}
        </div>
        <div
          className="text-center font-regular gap-2 f-row px-2 f2-center icon-parent"
          style={{
            borderRadius: 4,
            backgroundColor: 'var(--primaryColorBcg)',
            justifyContent: 'space-between',
            cursor: 'default'
          }}>
          <div
            className="max-1-lines-visible"
            title={component.data.customName || component.data.name || node.name}>
            {component.data.customName || component.data.name || node.name}
          </div>
          <CustomIcon
            pointer
            className="icon-hover"
            name="bin-2"
            onClick={onDeleteComponent}
            style={{ lineHeight: 0.5 }}
          />
        </div>
        <IconTooltip
          tooltipWidth={400}
          key={node.name}
          style={informationIconStyle}
          color="var(--primaryColor)"
          title={renderTooltipContent()}
        />
      </div>
    );
  };

  const renderNodeInLibrary = () => {
    return (
      <div
        key={node.name}
        className="f-col"
        style={{ position: 'relative', cursor: _isGrabbing ? 'grabbing' : 'grab' }}>
        <div
          onDragStart={onDragStartNode}
          onDragOver={() => setGrabbing(false)}
          draggable
          className="f-center mb-1 f-full"
          style={{
            borderRadius: 4,
            backgroundColor: 'var(--light-grey)'
          }}>
          {_isImage === true ? (
            <img
              alt="Component view"
              style={{
                objectFit: 'contain',
                height: 80
              }}
              src={
                config.BlobURL +
                '/single-use-configurator/components/thumbnail/' +
                component.id +
                '.png'
              }
            />
          ) : (
            node.form(80)
          )}
        </div>
        <div
          className="text-center font-regular gap-2 f-row px-2 f2-center"
          style={{
            borderRadius: 4,
            backgroundColor: 'var(--light-grey)',
            justifyContent: 'center'
          }}>
          <div className="max-1-lines-visible" title={node.name} style={{ textAlign: 'start' }}>
            {node.name}
          </div>
        </div>
        <IconTooltip
          tooltipWidth={400}
          key={node.name}
          style={informationIconStyle}
          color="var(--primaryColor)"
          title={renderTooltipContent()}
        />
      </div>
    );
  };

  const renderNode = () => {
    return (
      <div
        key={node.name}
        className="f-col"
        style={{ position: 'relative', cursor: _isGrabbing ? 'grabbing' : 'grab' }}>
        <div
          onDragStart={onDragStartNode}
          onDragOver={() => setGrabbing(false)}
          draggable
          className="f-center mb-1 f-full"
          style={{
            borderRadius: 4,
            backgroundColor: 'var(--light-grey)'
          }}>
          {node.form(80)}
        </div>
        {editorMode === EditorMode.SUA ? (
          <div
            className="text-center font-regular gap-2 f-row px-2 f1-center f2-center"
            onMouseEnter={() => setComponentNameHover(true)}
            onMouseLeave={() => setComponentNameHover(false)}
            style={{
              borderRadius: 4,
              backgroundColor: _isComponentNameHover
                ? 'var(--primaryColorBcg)'
                : 'var(--light-grey)'
            }}>
            {_isComponentNameHover ? (
              <div
                className="w-100 p-1 f-center"
                style={{ cursor: 'pointer' }}
                title={`See more ${node.name}`}
                onClick={showCustomComponentLibrary}>
                <CustomIcon
                  color={'var(--primaryColor)'}
                  name="add-3"
                  style={{ lineHeight: 0.5 }}
                />
              </div>
            ) : (
              <div className="max-1-lines-visible" title={node.name} style={{ textAlign: 'start' }}>
                {node.name}
              </div>
            )}
          </div>
        ) : (
          <div
            className="text-center font-regular px-2"
            style={{
              borderRadius: 4,
              backgroundColor: 'var(--light-grey)'
            }}>
            <div className="max-1-lines-visible" title={node.name}>
              {node.name}
            </div>
          </div>
        )}
      </div>
    );
  };

  const render = () => {
    if (isShowingCustomLibrary && !isCustomComponent) {
      return renderNodeInLibrary();
    } else if (isShowingCustomLibrary && isCustomComponent) {
      return renderCustomComponentInLibrary();
    } else {
      return renderNode();
    }
  };

  return render();
};
