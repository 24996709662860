import { Label, ThinChip } from 'hakobio-react-ui';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { mediaMessages, statusMessages } from '../../../../lang/messages';
import {
  referenceStatusBackgroundColors,
  referenceStatusColors
} from '../../../../utilities/statuses/referenceStatus';

import { SUPPLIERS, Supplier } from '../../../../constants/SUPPLIERS';

interface ReferenceLabelDescriptionProps {
  name: string;
  status: string;
  supplier: string;
  media: any[];
  actionIcon?: JSX.Element;
  isHover?: boolean;
}

const ReferenceLabelDescription: FC<ReferenceLabelDescriptionProps> = ({
  name,
  status,
  supplier,
  media,
  actionIcon,

  isHover
}) => {
  const intl = useIntl();



  const renderAssemblyInfosUrl = () => {
    if (media?.length) {
      const favoriteMedia = media.find((m) => m.isFavorite) || media[0];
      return (
        <ThinChip
          label={
            <a className="max-lines-1" href={favoriteMedia.url} rel="noreferrer" target="_blank">
              {favoriteMedia.name}
            </a>
          }
        />
      );
    } else {
      return (
        <span className="frozen-grey small-text">
          {intl.formatMessage(mediaMessages.noExtrenalLink)}
        </span>
      );
    }
  };

  return (
    <Label
      shadow
      className={`my-2 mr-2`}
      backgroundColor={isHover ? 'var(--light-grey)' : undefined}>
      <div className="f-col gap-2 container-action">
        <div className="f-row f1-between f2-center">
          <div className="f-row f1-between f2-center gap-2 f-full">
            <div
              title={name}
              className="font-regular max-lines-1"
              style={{
                cursor: 'default',
                color: isHover ? 'var(--primaryColor)' : undefined
              }}>
              {name}
            </div>
            <ThinChip
              style={{ flexShrink: 0 }}
              label={
                <span className="capitalize">
                  {status || intl.formatMessage(statusMessages.noStatus)}
                </span>
              }
              center
              fontSize="smallText"
              color={referenceStatusColors(status)}
              backgroundColor={referenceStatusBackgroundColors(status)}
            />
          </div>
          {actionIcon && <div className="ml-2">{actionIcon}</div>}
        </div>

        <ThinChip label={SUPPLIERS.find((s: Supplier) => s.id === supplier)?.name || 'N/C'} />
        {renderAssemblyInfosUrl()}
      </div>
    </Label>
  );
};

export default ReferenceLabelDescription;
