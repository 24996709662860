import { Chip, Empty, SplashScreen } from 'hakobio-react-ui';
import { useEffect, useState } from 'react';
import { useStoreActions } from 'react-flow-renderer';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { CUSTOM_FUNCTIONS } from '../../../../../constants/CUSTOM_FUNCTIONS';
import { useAppSelector } from '../../../../../store';
import { useImplementEditorAction } from '../../../../../store/features/assemblyEditor/useImplementEditorAction';
import { getColors } from '../../../../../utilities';
import _ from 'lodash';
import { advancedSearch } from '../../../../Shared/AdvancedSearch/utilities/search';
import SingleSUA from './SingleSUA';

export default function PIDLibrary(props: any) {
  const intl = useIntl();

  const { implementComponent } = useImplementEditorAction();
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const isLayerLocked = useAppSelector((state) => state.assemblyEditorSlice.isLayerLocked);
  const isSUALibraryCharged = useAppSelector(
    (state) => state.assemblyEditorSlice.isSUALibraryCharged
  );

  const directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const user = useAppSelector((state) => state.assemblyEditorSlice.user);
  const relevantDirectories = directoriesList.filter((dir) => dir.directoryType === 1);

  const editorAssemblyLibrary = useAppSelector(
    (state) => state.assemblyEditorSlice.editorAssemblyLibrary
  );
  const suaLibrary = editorAssemblyLibrary.filter((x) => x.general.type === 'SingleUseAssembly');

  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  const [SUAListToShow, setSUAListToShow] = useState('');
  const [_selectedComponents, setSelectedComponents] = useState([]);
  const [_isEmptyListForFuction, setIsEmptyListForFuction] = useState(false);

  const { selectedComponent } = props;

  const { primaryColorAlpha } = getColors();

  const headers: string[] = Object.values(CUSTOM_FUNCTIONS)
    .filter((f: any) => f.name !== 'Purification' && f.name !== 'Transferring')
    .map((f: any) => f.name)
    .sort();
  headers.push('Other');
  useEffect(() => {
    if (selectedComponent) setSelectedComponents(selectedComponent);
  }, [selectedComponent]);

  useEffect(() => {
    if (selectedComponent) {
      const correctCaseType = selectedComponent.data.type.charAt(0).toUpperCase() + selectedComponent.data.type.slice(1);
      const list = getSuaListByHeader(correctCaseType);
  
      setIsEmptyListForFuction(list.length === 0);
      setSUAListToShow(list.length > 0 ? correctCaseType : '');
    } else {
      setIsEmptyListForFuction(false);
      setSUAListToShow('');
    }
  }, [selectedComponent]);

  const handleClickOnSua = (value: any) => {
    if (selectedComponent) {
      const realSelectedComponent = components.find((c: any) => c.id === selectedComponent.id);
      const newComponent = _.cloneDeep(realSelectedComponent);
      implementComponent(
        newComponent,
        value,
        components,
        isLayerLocked,
        layerMode,
        editorAssemblyLibrary
      );
      setSelectedElements([]);
    }
    if (components.length < 1) {
      toast.warn(
        <FormattedMessage
          id="PleasePlaceAPFDFirstAndThenAddTheP&amp;ID"
          defaultMessage="Please place a PFD first and then add the P&amp;ID"
        />
      );
    }
  };

  const getSuaListByHeader = (header) => {
    var list = suaLibrary.filter((key) => {
      if (header !== 'Other') {
        return key.general.functions.includes(header.toLowerCase());
      } else {
        return key.general.functions.length === 0;
      }
    });
    return list;
  };

  const hasUserAccessToSUA = (sua) => {
    const associatedSuaDirectory = relevantDirectories.find(
      (dir) =>
        (dir.designOwners.includes(user.id) || dir.designEditors.includes(user.id)) &&
        dir.children.includes(sua.general.id)
    );
    return Boolean(associatedSuaDirectory);
  };

  const checkDataSelectedComponent = () => {
    let datas = [];
    const componentData = { ...selectedComponent?.data } || {};
    if (componentData['volume']) {
      datas.push({
        type: 'volume',
        value: componentData['volume'],
        units: 'L'
      });
    }
    if (componentData['measuring type']) {
      datas.push({
        type: 'measuring type',
        value: componentData['measuring type'],
        units: ''
      });
    }
    if (componentData['flow rate']) {
      datas.push({
        type: 'flow rate',
        value: componentData['flow rate'],
        units: 'L/h'
      });
    }
    if (componentData['pressure']) {
      datas.push({
        type: 'pressure',
        value: componentData['pressure'],
        units: 'bar'
      });
    }
    return datas;
  };

  const filteredList = (list) => {
    let filteredSuas;

    if (typeof props.search === 'string') {
      // Filtrage par le nom si la recherche est une chaîne de caractères
      filteredSuas = list.filter((sua) =>
        sua.general.name.toLowerCase().includes(props.search.toLowerCase())
      );
    } else {
      // Filtrage avancé si la recherche n'est pas une chaîne de caractères
      const searchedAssemblyIds = advancedSearch(suaLibrary, props.search)?.map(
        (a) => a.general.id
      );
      filteredSuas = searchedAssemblyIds
        ? suaLibrary.filter((a) => searchedAssemblyIds.includes(a.general.id))
        : suaLibrary;
    }
    
    return filteredSuas.map((sua) => {
      let isSuaAccessible = hasUserAccessToSUA(sua);
      return (
        <SingleSUA
          onClickSua={isSuaAccessible && handleClickOnSua}
          sua={sua}
          selectedComponents={_selectedComponents}
          isSuaAccessible={isSuaAccessible}
        />
      );
    });
  };

  const renderList = (header: any) => {
    let list = filteredList(suaLibrary);
    let datas = checkDataSelectedComponent();
    if (suaLibrary.length > 0 && list.length > 0) {
      return (
        <>
          <div className="pt-2 f-row gap-2" style={{ flexWrap: 'wrap' }}>
            {datas.map((info) => (
              <Chip
                key={info.type}
                style={{ width: '1rem' }}
                color="var(--primaryColor)"
                backgroundColor={primaryColorAlpha}
                label={info.value + ' ' + info.units}
              />
            ))}
          </div>
          <div
            className="py-2 gap-2"
            style={{
              display: 'grid',
              gridTemplateColumns: 'minmax(185px, 185px) repeat(auto-fill,185px)'
            }}>
            {list}
          </div>
        </>
      );
    } else {
      return (
        <div className="f-full">
          <Empty
            size="md"
            text={intl.formatMessage({
              id: 'PIDLibrary.EmptyText.NoSUAFound',
              defaultMessage: 'No SUA matching your search parameters'
            })}
          />
        </div>
      );
    }
  };

  if (
    (selectedComponent && selectedComponent?.data?.type === 'waste') ||
    selectedComponent?.data?.type === 'utilities'
  ) {
    return (
      <div style={{ marginLeft: 10 }}>
        A function {selectedComponent?.data?.type} can't be replaced by a single use assembly
      </div>
    );
  } else {
    return (
      <>
        <div className={'px-2 pb-2 f-col'} style={{ overflow: 'auto', height: 1, flexGrow: 1 }}>
          {SUAListToShow ? (
            isSUALibraryCharged ? (
              renderList(SUAListToShow)
            ) : (
              <SplashScreen />
            )
          ) : (
            <>
              <div className="f-full">
                <Empty
                  size="md"
                  text={intl.formatMessage({
                    id: 'PIDLibrary.NoSearchParameters',
                    defaultMessage:
                      'Please select a function or a functional assembly to start the search'
                  })}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
