import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useAssemblyEditorAction } from '../../../store/features/assemblyEditor/useAssemblyEditorAction';

export const GhostAnchor = (props: any) => {
  const { setHoveredAnchor, unsetHoveredAnchor } =
    useAssemblyEditorAction();

  const { anchor, sizeX, sizeY, component } = props;
  const [anchorPosition, setAnchorPosition] = useState(anchor.position);
  const [anchorType, setAnchorType] = useState(anchor.type);
  const dispatch = useAppDispatch();

  // const hoveredAnchor = useAppSelector((state) => state.assemblyEditorSlice.hoveredAnchor);

  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  function handleClick(event: any): void {
    setAnchorEl(event.currentTarget);
  }

  // const handleChangeType = (value: string, event: any) => {
  //   event.stopPropagation();
  //   //  onAnchorChanged(anchor, "type", value, props.component);
  //   handleClose();
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  // const colorAnchors = () => {
  //   switch (anchor.type) {
  //     case 'genderless':
  //       return 'var(--blue)';
  //     case 'neutral':
  //       return '#37c6f2';
  //     case 'source':
  //       return 'var(--red)';
  //     case 'target':
  //       return 'var(--green)';
  //   }
  // };

  // const anchorColor = colorAnchors();

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);

  // const selectedAnchors = useAppSelector((state) => state.assemblyEditorSlice.selectedAnchors);

  // const selectedAnchor = selectedAnchors && selectedAnchors[0];
  // const isSelected = anchor.id === selectedAnchor?.id && component.id === selectedAnchor?.component;

  // const errors = useAppSelector((state) => state.assemblyEditorSlice.errors);

  // const errorAnchor = errors.filter((error) =>
  //   error.componentAnchors.find((a: any) => a.id === anchor.id && a.component === component.id)
  // );

  const connectedEdges = components
    .filter((e: any) => e.type === 'functionedge' || e.type === 'genericedge')
    .filter((edge: any) => {
      return (
        (edge.source === component.data.function && edge.sourceHandle === anchor.id) ||
        (edge.target === component.data.function && edge.targetHandle === anchor.id)
      );
    });

  const isConnectable = () => {
    const functionEdges = components.filter((c: any) => c.type === 'functionedge');
    // Check how many time the anchor.id is connected. If it's connected to 2 handles, it's not connectable anymore.
    const connectedHandles = functionEdges.filter(
      (c: any) =>
        (c.sourceHandle === anchor.id && c.source == component.id) ||
        (c.targetHandle === anchor.id && c.target == component.id)
    );

    return connectedHandles.length === 0;
  };

  const isValidConnection = (connection: any) => {
    const source = components.find((c) => c.id === connection.source);
    const sourceAnchor = source.data.anchors.find((a: any) => a.id === connection.sourceHandle);
    const target = components.find((c) => c.id === connection.target);
    const targetAnchor = target.data.anchors.find((a: any) => a.id === connection.targetHandle);
    if (connection.source === connection.target) {
      return false;
    }
    //Cas source anchor === source(rouge) => target anchor === target(vert) || genderless(blue)
    if (sourceAnchor.type === 'source' && targetAnchor.type === 'source') {
      return false;
    }
    //Cas source anchor === target(vert) =>
    //il faut que target anchor === source(rouge) || genderless(blue)
    //si correct => échanger source et target
    if (sourceAnchor.type === 'target' && targetAnchor.type === 'target') {
      return false;
    }
    //Cas source anchor  = Genderless
    //Si target est genderless => ok
    //Si target est vert => ok
    //Si target est rouge => échanger position
    return true;
  };

  let relativeY = anchor.viewer2D.top;
  let relativeX = anchor.viewer2D.left;

  const deltaY0 = relativeY;
  const deltaY1 = 1 - relativeY;

  const deltaX0 = relativeX;
  const deltaX1 = 1 - relativeX;

  if (deltaY0 < deltaY1 && deltaX0 < deltaX1) {
    if (deltaY0 > deltaX0) {
      relativeX = 0;
    } else {
      relativeY = 0;
    }
  } else if (deltaY0 < deltaY1 && deltaX0 > deltaX1) {
    if (deltaY0 > deltaX1) {
      relativeX = 1;
    } else {
      relativeY = 0;
    }
  } else if (deltaY0 > deltaY1 && deltaX0 > deltaX1) {
    if (deltaY1 > deltaX1) {
      relativeX = 1;
    } else {
      relativeY = 1;
    }
  } else {
    if (deltaY1 > deltaX0) {
      relativeX = 0;
    } else {
      relativeY = 1;
    }
  }

  const renderAnchorRadarConnectable = () => {
    return (
      <div
        className="radar f-center"
        style={{
          backgroundColor: 'none',
          border: '1px solid var(--frozen-grey)',
          position: 'absolute',
          top: relativeY * sizeY - 3,
          left: relativeX * sizeX - 3
        }}
      />
    );
  };

  const renderAnchor = () => {
    const connectable = isConnectable();

    let radarConnectable = null as JSX.Element;
    let anchorStyle = {} as React.CSSProperties;
    if (connectable) {
      radarConnectable = renderAnchorRadarConnectable();
      anchorStyle.backgroundColor = 'var(--frozen-grey)';
    }

    return (
      <>
        {radarConnectable}
        {/* @ts-ignore */}
        <Handle
          onMouseEnter={() => setHoveredAnchor(anchor.id)}
          onMouseLeave={() => unsetHoveredAnchor()}
          onClick={handleClick}
          key={anchor.id}
          type={anchorType}
          position={anchorPosition}
          id={anchor.id}
          isConnectable={isConnectable()}
          isValidConnection={isValidConnection}
          style={{
            position: 'absolute',
            width: '8px',
            height: '8px',
            backgroundColor: 'var(--frozen-grey)',
            border: 'none',
            top: relativeY * sizeY - 3,
            left: relativeX * sizeX - 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...anchorStyle
          }}
        />
      </>
    );
  };

  if (connectedEdges.length === 0) {
    return null;
  } else {
    return renderAnchor();
  }
};
