import {
  Chip,
  DoubleChip,
  Icon,
  SmallButton,
  ThinChip,
  Title,
  Tooltip,
  UserBadge,
  UserBadgeSelect
} from 'hakobio-react-ui';
import { cloneDeep, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { CUSTOM_FUNCTIONS } from '../../../../../constants/CUSTOM_FUNCTIONS';
import { CUSTOM_NODES } from '../../../../../constants/CUSTOM_NODES';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { assemblyEditorSliceActions } from '../../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { useLinkEditorAction } from '../../../../../store/features/assemblyEditor/useLinkEditorAction';
import { EntityToDeleteTypes } from '../../../../../views/enum/EntityToDeleteType';
import ButtonTooltip from '../../../../Flow/utility/EdgeInfoTooltip';
import { EditorMode } from '../../../../../constants/EditorMode';
import { configMessages, generalMessages, statusMessages } from '../../../../../lang/messages';
import { useIntl } from 'react-intl';
import { ViewMode } from '../../../../../constants/ViewMode';
import {
  genericStatusBackgroundColors,
  genericStatusColors
} from '../../../../../utilities/statuses/genericStatus';
import { DirectoryFolder } from '../../../../models/DirectoryFolder';

const GenericSuaDetails = (props: any) => {
  const {
    changeAnchorsSelection,
    changeEdgesSelection,
    setHoverEdge,
    unsetHoverEdge,
    setHoverComponentGenericNode,
    unsetHoverComponentGenericNode,
    setHoverOnEdge
  } = useAssemblyEditorAction();
  const { joinComponents } = useLinkEditorAction();
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const selectedAnchors = useAppSelector((state) => state.assemblyEditorSlice.selectedAnchors);
  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );
  const hoveredAnchor = useAppSelector((state) => state.assemblyEditorSlice.hoveredAnchor);
  const dispatch = useAppDispatch();
  const { sua, component } = props;
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const viewMode = useAppSelector((state) => state.assemblyEditorSlice.viewMode);
  const directoriesList = useAppSelector((state) => state.assemblyEditorSlice.allDirectories);
  const folder = directoriesList.find(
    (f: DirectoryFolder) => f.id === props.sua.general.parentFolderId
  );

  const userList = useAppSelector((state) => state.assemblyEditorSlice.userList);
  const [_designOwners, setDesignOwners] = useState([]);

  const [_listSchemaEdge, setListSchemaEdge] = useState([]);

  const intl = useIntl();

  let anchorList = [] as any;
  let edgeList = sua.components.filter((e: any) => e.type === 'genericedge');
  sua.components
    .filter((e: any) => e.type !== 'genericedge')
    .forEach((element: any) => {
      element.data.anchors.forEach((anchor: any) => {
        let result = edgeList.find((edge: any) => {
          return (
            (edge.source === element.id && edge.sourceHandle === anchor.id) ||
            (edge.target === element.id && edge.targetHandle === anchor.id)
          );
        });
        if (!result) {
          anchorList.push({ ...anchor, component: element.id });
        }
      });
    });

  const dragComponentList = components.filter((c: any) => {
    return (
      c.type === 'generic' &&
      c.data.assembly?.id === sua.general.id &&
      c.data.assembly?.component === component.id &&
      c.data.idDrag
    );
  });

  const listFunctionsEdge = components.filter((e: any) => e.type === 'functionedge');

  useEffect(() => {
    if (folder) {
      let designOwners = [];
      folder.designOwners.map((designOwner) => {
        designOwners.push(userList.find((user) => user.userId === designOwner));
      });
      setDesignOwners(designOwners);
    }
  }, [folder]);

  const getSchemaAsync = async () => {
    let listSchemaEdge = [];
    sua.components
      .filter((comp: any) => comp.type === 'genericedge')
      .map(async (edge: any) => {
        const type = edge.data.type || 'tubing';
        try {
          let importedValue = null;
          switch (edge.type) {
            case 'generic':
            case 'genericonedge':
            case 'genericedge':
              importedValue = await CUSTOM_NODES[type].schema();
              break;
            case 'function':
            case 'functionedge':
              importedValue = await CUSTOM_FUNCTIONS[type].schema();
              break;
          }
          listSchemaEdge.push({ importedValue, id: edge.id });
        } catch (error) {
          console.log({ error });
        }
        setListSchemaEdge(listSchemaEdge);
      });
  };

  useEffect(() => {
    getSchemaAsync();
  }, []);

  const setSelectedEdge = (anchor: any) => {
    const functionEdge = components.find(
      (component) =>
        component.type === 'functionedge' &&
        (component.sourceHandle === anchor.id || component.targetHandle === anchor.id)
    );
    if (functionEdge) {
      changeEdgesSelection([functionEdge]);
    }
  };

  const clearSelectedEdge = () => {
    changeEdgesSelection([]);
  };

  const renderAnchor = (assemblyAnchor: any) => {
    const anchor = components
      .find((c: any) => c.id === assemblyAnchor.component)
      .data.anchors.find((a: any) => a.id === assemblyAnchor.id);

    const isComponentLinked = anchor.data?.componentLink?.anchor;

    const isHover =
      (selectedAnchors?.length && selectedAnchors[0].id === anchor.id) ||
      hoveredAnchor === anchor.id;

    return (
      <>
        <div style={{ marginTop: '-.5rem' }}>
          <div
            onMouseEnter={() =>
              changeAnchorsSelection([
                {
                  id: assemblyAnchor.id,
                  component: assemblyAnchor.component
                }
              ])
            }
            onMouseLeave={() => changeAnchorsSelection([])}
            className="f-col p-2"
            style={{
              backgroundColor: isHover ? 'var(--light-grey)' : ''
            }}></div>
        </div>
      </>
    );
  };

  const renderFolderInfo = () => {
    return (
      <>
        <div className="f-row py-2 ">
          <div className={`f-row f2-center gap-2`}>
            <Tooltip title={'Directory'}>
              <Icon name="folder" />
            </Tooltip>
            {folder?.name}
          </div>
        </div>

        <div className="w-10 border-bottom mb-2" />
      </>
    );
  };

  const unDragComponent = (dragComponent: any) => {
    const edge = components.find((c: any) => c.id === dragComponent.data.idDrag.edge);
    const otherComponentId = edge.source === dragComponent.id ? edge.target : edge.source;
    const otherComponent = components.find((c: any) => c.id === otherComponentId);

    let dragComponentCopy = cloneDeep(dragComponent);
    delete dragComponentCopy.data.idDrag;
    let otherComponentCopy = cloneDeep(otherComponent);
    delete otherComponentCopy.data.idDrag;
    let edgeCopy = cloneDeep(edge);
    edgeCopy.data.implementable = true;

    dispatch(assemblyEditorSliceActions.updateComponent(dragComponentCopy));
    dispatch(assemblyEditorSliceActions.updateComponent(otherComponentCopy));
    dispatch(assemblyEditorSliceActions.updateComponent(edgeCopy));
  };

  const renderDragComponent = (dragComponent: any) => {
    return (
      <>
        <div className="f-row gap-2 mb-2 px-2">
          <ThinChip
            color={'var(--primaryColor)'}
            backgroundColor={'var(--primaryColorBcg)'}
            label={CUSTOM_NODES[dragComponent.data.type]?.name}
          />
        </div>
        <div style={{ marginTop: -5, marginBottom: 10, marginLeft: 10 }}>
          <SmallButton onClick={() => unDragComponent(dragComponent)}>Unlink</SmallButton>
        </div>
      </>
    );
  };

  const hightlightElement = (id: string) => {
    const element = document.querySelector(`div[data-id='${id}']`);
    if (element) element.classList.add('highlight');
    setHoverComponentGenericNode(id);
    setHoverOnEdge(id);
  };

  const resetHighlight = (id: string) => {
    const element = document.querySelector(`div[data-id='${id}']`);
    if (element) element.classList.remove('highlight');
    unsetHoverComponentGenericNode(null);
    setHoverOnEdge(null);
  };

  // order list of components (alphabetical) : first components "connectable", and then the rest
  let anchorComponentList: any[] = [];
  let nonAnchorComponentList: any[] = [];
  sua.components
    .filter((c: any) => c.type !== 'genericedge')
    .forEach((comp: any) => {
      const hasAnchor = anchorList.find((anch: any) => anch.component === comp.id);
      // need "name" to sort collection
      const component = { ...comp, name: comp.data.type };
      if (hasAnchor) {
        anchorComponentList.push(component);
      } else {
        nonAnchorComponentList.push(component);
      }
    });

  const sortedAnchorComponentList = orderBy(anchorComponentList, ['name'], ['asc']);

  const sortedNonAnchorComponentList = orderBy(nonAnchorComponentList, ['name'], ['asc']);

  const componentList = [...sortedAnchorComponentList, ...sortedNonAnchorComponentList];

  const unlinkSUAs = () => {
    dragComponentList.map((c: any) => {
      return unDragComponent(c);
    });
  };

  const functions = components.filter((c: any) => c.type === 'function');

  const selectedFunction = functions.find((f: any) => {
    let isSelected: boolean;
    f?.data?.assembly?.components.forEach((c: any) => {
      if (selectedComponents.includes(c.id) || selectedComponents.includes(f.id)) {
        isSelected = true;
      }
    });
    return isSelected ? f : null;
  });

  const contentTooltip = (comp) => {
    if (comp?.data && Object.entries(comp?.data).length > 0) {
      //@ts-ignore
      return <ButtonTooltip edge={comp} />;
    } else {
      return null;
    }
  };
  const onDelete = () => {
    dispatch(
      assemblyEditorSliceActions.setShowDeleteConfirmModal({
        entityId: component.id,
        entityName: component.name,
        type: EntityToDeleteTypes.Sua
      })
    );
  };

  const renderTooltipTitle = () => {
    return selectedFunction?.data?.ancillaryFunctions?.[0].complexParameters?.length ? (
      <div>
        {selectedFunction?.data?.ancillaryFunctions?.[0].complexParameters?.map((fct) => {
          return (
            <div className="f-row gap-2 f2-center">
              <div>{CUSTOM_FUNCTIONS[fct]?.form(20)}</div>
              <div>{CUSTOM_FUNCTIONS[fct]?.name}</div>
            </div>
          );
        })}
      </div>
    ) : (
      ''
    );
  };
  const designOwners = () => {
    return (
      <>
        <div
          className="font-regular"
          children={intl.formatMessage({
            id: 'SUConfigView.EditFolderModal.DesignOwners',
            defaultMessage: 'Design Owners'
          })}></div>

        <div className="pt-1 f-row f2-center gap-2 f-wrap">
          {_designOwners.map((designOwner) => {
            return (
              <div>
                <UserBadge
                  firstName={designOwner.firstname}
                  lastName={designOwner.lastname}
                  size={_designOwners.length > 1 ? 'small' : 'medium'}
                  title={
                    <div className="f-col">
                      <div className="font-regular">
                        {designOwner.firstname} {designOwner.lastname}
                      </div>
                      {designOwner.email}
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="pb-1 f-col f-full" style={{ overflowX: 'hidden' }}>
      {folder && renderFolderInfo()}
      {viewMode !== ViewMode.HistoryUO && (
        <Title className="px-2">
          <div className="f-row gap-2 f1-between f2-center">
            <div className="f-row f1-between f2-center gap-2 w-100">
              {sua.general.name}
              <ThinChip
                label={
                  sua.general.status ? (
                    <span className="capitalize">{sua.general.status}</span>
                  ) : (
                    intl.formatMessage(statusMessages.noStatus)
                  )
                }
                color={genericStatusColors(sua.general.status)}
                backgroundColor={genericStatusBackgroundColors(sua.general.status)}
              />
            </div>
            <Icon
              name="bin"
              onClick={() => {
                onDelete();
              }}
            />
          </div>
        </Title>
      )}
      <div
        style={{
          overflowY: 'auto',
          height: 1,
          flexGrow: 1,
          overflowX: 'hidden'
        }}>
        <div className="px-2 py-1">
          {sua.general.description ? (
            sua.general.description
          ) : (
            <span className="frozen-grey">{intl.formatMessage(generalMessages.noDescription)}</span>
          )}
        </div>

        <div className="my-3">
          {folder && designOwners()}
          <div className="mb-2 px-2 font-regular">
            {intl.formatMessage(configMessages.functionsPFD)}
          </div>
          {editorMode === EditorMode.UnitOperation && selectedFunction && (
            <Tooltip title={renderTooltipTitle()}>
              {selectedFunction?.data?.ancillaryFunctions?.length ? (
                <div className="f-row gap-2 px-2">
                  {CUSTOM_FUNCTIONS[selectedFunction?.data?.type].form(20)}
                  <DoubleChip
                    className="max-1-lines-visible w-100"
                    label1Ratio={'25%'}
                    key={selectedFunction?.data?.id}
                    label1={CUSTOM_FUNCTIONS[selectedFunction?.data?.type].name || 'No name'}
                    label2={
                      selectedFunction?.data?.ancillaryFunctions?.[0].complexParameters?.length ? (
                        selectedFunction?.data?.ancillaryFunctions?.[0].complexParameters?.map(
                          (name, index) => {
                            return (
                              <span key={name + index}>
                                {index ===
                                selectedFunction?.data?.ancillaryFunctions?.[0].complexParameters
                                  ?.length -
                                  1
                                  ? CUSTOM_FUNCTIONS[name]?.name
                                  : CUSTOM_FUNCTIONS[name]?.name + ', '}
                              </span>
                            );
                          }
                        )
                      ) : (
                        <span className="frozen-grey">
                          {intl.formatMessage(generalMessages.noValue)}
                        </span>
                      )
                    }
                  />
                </div>
              ) : (
                <div className="f-row gap-2 f2-center px-2">
                  {CUSTOM_FUNCTIONS[selectedFunction?.data?.type].form(20)}
                  <ThinChip
                    className="w-100"
                    label={
                      CUSTOM_FUNCTIONS[selectedFunction?.data?.type].name || 'No function assigned'
                    }
                  />
                </div>
              )}
            </Tooltip>
          )}
        </div>
        <div className="my-3">
          <div className="px-2 font-regular">SUA components</div>

          {componentList.map((e: any, index: number) => {
            let functionEdge = null as any;
            let componentTarget = null as any;
            let componentSource = null as any;
            let linkableComponent = listFunctionsEdge.filter((funcEdge: any) => {
              if (e.type === 'generic' && funcEdge.source === e.id) {
                componentSource = components.find((c: any) => c.id === e.id);
                functionEdge = funcEdge;
                componentTarget = components.find((c: any) => c.id === functionEdge.target);
                return e;
              }
              if (e.type === 'generic' && funcEdge.target === e.id) {
                componentTarget = components.find((c: any) => c.id === e.id);
                functionEdge = funcEdge;
                componentSource = components.find((c: any) => c.id === functionEdge.source);
                return e;
              }
            });
            let dragComponent = dragComponentList.find((c: any) => {
              return e.id === c.id;
            });
            return (
              <div
                key={'SUA DETAILS CL' + component.id + sua.general.id + e.id + index}
                className="element-wrap"
                onMouseEnter={() => hightlightElement(e.id)}
                onMouseLeave={() => resetHighlight(e.id)}>
                <div className="f-row gap-2 pt-2 mb-2 px-2">
                  <div
                    style={{ height: 20 }}
                    key={'ICON SUA DETAILS CL' + component.id + sua.general.id + e.id + index}>
                    {CUSTOM_NODES[e.data.type]?.form(20)}
                  </div>
                  <ThinChip
                    className="f-full"
                    color={'var(--primaryColor)'}
                    backgroundColor={'var(--primaryColorBcg)'}
                    label={CUSTOM_NODES[e.data.type]?.name}
                  />

                  {componentSource?.type === 'generic' &&
                    componentTarget?.type === 'generic' &&
                    functionEdge?.data?.implementable && (
                      <Icon
                        title="Link SUA's"
                        className="mt-1"
                        style={{ lineHeight: 0.5 }}
                        name="link"
                        pointer
                        onClick={(value: boolean) =>
                          joinComponents(
                            !functionEdge?.data?.implementable,
                            functionEdge,
                            componentSource,
                            componentTarget
                          )
                        }
                      />
                    )}
                  {componentSource?.type === 'generic' &&
                    componentTarget?.type === 'generic' &&
                    !functionEdge?.data?.implementable && (
                      <Icon
                        title="Split SUA's"
                        className="mt-1"
                        name="unlink"
                        pointer
                        onClick={() => unDragComponent(dragComponent)}
                      />
                    )}
                </div>

                <div className="f-row gap-2" style={{ marginLeft: 36 }}>
                  {e.data['material'] && (
                    <ThinChip
                      auto
                      fontSize="smallText"
                      className="capitalize mb-2"
                      style={{ cursor: 'pointer' }}
                      label={e.data['material'] || 'N/C'}
                    />
                  )}
                  {e.data['product contact material'] && (
                    <ThinChip
                      auto
                      fontSize="smallText"
                      className="capitalize mb-2"
                      style={{ cursor: 'pointer' }}
                      label={e.data['product contact material'] || 'N/C'}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="my-3">
          <div className="px-2 font-regular">Tubings</div>
          {sua.components
            .filter((comp: any) => comp.type === 'genericedge')
            .map((comp: any) => {
              let schema = _listSchemaEdge
                .filter((e: any) => delete e.assembly && e.id === comp.id)
                .map((edge) => {
                  return edge;
                });
              return (
                <Tooltip key={comp.id} title={contentTooltip(comp)}>
                  <div
                    className="f-col hover-item py-1 "
                    onMouseEnter={() => setHoverEdge(comp.id)}
                    onMouseLeave={() => unsetHoverEdge(comp.id)}>
                    <div className="f-row gap-2 py-1 px-2">
                      <Icon name="tubing" />
                      <ThinChip
                        fontSize="smallText"
                        className="capitalize f-full"
                        title={(comp.data?.customName && 'Name ') || 'N/C'}
                        label={comp.data?.customName || comp.data?.type}
                        color={'var(--primaryColor)'}
                        backgroundColor={'var(--primaryColorBcg)'}
                      />
                    </div>
                    <div className="f-wrap f-row gap-2 pr-2" style={{ paddingLeft: 30 }}>
                      {schema.length > 0 && schema[0] !== undefined ? (
                        Object.entries(schema[0].importedValue.properties)
                          .filter(([key, parameter]) => key !== 'name')
                          .map(([key, parameter]: Array<any>, index) => {
                            return (
                              (key === 'componentType' ||
                                key === 'componentInnerDiameter' ||
                                key === 'componentOuterDiameter') &&
                              comp.data[key] && (
                                <ThinChip
                                  key={key + index}
                                  auto
                                  fontSize="smallText"
                                  className="capitalize"
                                  title={parameter.name || 'N/C'}
                                  label={
                                    (comp.data[key]
                                      ? `${comp.data[key]} ${
                                          parameter?.units ? parameter.units : ''
                                        }`
                                      : 'N/C') || 'N/C'
                                  }
                                />
                              )
                            );
                          })
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </Tooltip>
              );
            })}

          {/* {sua.components ///// !!!!!!!!!!!!!! Ne pas effacer le code svp
            .filter((comp: any) => comp.type === 'genericedge')
            .map((comp: any) => {
              return (
                <div key={comp.id} className="f-col">
                  <div className="f-row gap-2 pt-2 mb-2 px-2">
                    <Icon name="tubing" />
                    <ThinChip
                      fontSize="smallText"
                      className="capitalize f-full"
                      style={{ cursor: 'pointer' }}
                      label={comp.data?.name || 'N/C'}
                      color={'var(--primaryColor)'}
                      backgroundColor={'var(--primaryColorBcg)'}
                    />
                  </div>
                  <div className="f-wrap f-row gap-2">
                    {Object.entries(comp.data).map(([key, parameter]: Array<any>, index) => {

                      return (
                        key !== 'assembly' && (
                          <ThinChip
                            key={key + index}
                            auto
                            fontSize="smallText"
                            className="capitalize"
                            style={{ cursor: 'pointer' }}
                            title={key || 'N/C'}
                            label={
                              (typeof parameter !== 'object' && typeof parameter !== 'boolean'
                                ? parameter
                                : 'N/C') || 'N/C'
                            }
                          />
                        )
                      );
                    })}
                  </div>
                </div>
              );
            })} */}
        </div>

        {/* <div className="mx-1 my-3">
          <SubTitleWithIcon className="mb-1" name="picto_new_equipment" hako2>
            Linked connectors
          </SubTitleWithIcon>
          {dragComponentList.length === 0 && (
            <div className="px-3" style={{ color: "lightgrey" }}>
              No linked connector
            </div>
          )}

          {dragComponentList.map((c: any) => {
            return renderDragComponent(c);
          })}
        </div> */}
      </div>
    </div>
  );
};
export default GenericSuaDetails;
