import { useAssemblyEditorAction } from './useAssemblyEditorAction';
import { getAssemblyPermissions } from '../../../services/editor/editorService';

export const useStatusAction = () => {
  const { onGeneralEdited } = useAssemblyEditorAction();

  const changeStatus = async (id: string, status: string) => {
    const permissions = await getAssemblyPermissions(id);
    onGeneralEdited('permissions', permissions);
    if (permissions.changeStatus) onGeneralEdited('status', status);
  };

  return {
    changeStatus
  };
};
