import { Icon, IconTooltip, SmallButton, ThinChip } from 'hakobio-react-ui';
import { buttonSaveStyle } from '../../style/styles';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useIntl } from 'react-intl';
import { actionMessages, generalMessages } from '../../../../lang/messages';
import { EditorMode } from '../../../../constants/EditorMode';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';

export function Save(props) {
  const isSavingAssembly = useAppSelector((state) => state.assemblyEditorSlice.isSavingAssembly);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const [_haveGhost, setHaveGhost] = useState([]);

  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const onSave = async () => {
    if (editorMode === EditorMode.UnitOperation) {
      dispatch(assemblyEditorSliceActions.setEditingAssembly(false));
    }
    props.onSave();
  };

  const statusButtonLabel = general?.permissions?.changeStatus && !general?.permissions?.update;

  useEffect(() => {
    let ghostList = components.filter((c) => c.type === 'ghost' || c.type === 'ghostReference');
    if (ghostList.length > 0) {
      setHaveGhost(orderBy(ghostList, (ghost) => ghost.type, ['asc']));
    } else {
      setHaveGhost([]);
    }
  }, [components]);

  const tooltipContent = (
    <div>
      <div className="p-1 pb-2">{intl.formatMessage(generalMessages.warningSave)}</div>
      {_haveGhost.map((ghost) => (
        <div className="p-1" key={ghost.id}>
          <div className="f-row f2-center gap-2 ">
            <Icon name={ghost.type === 'ghost' ? 'manifold' : 'label'} className="pl-1" />
            {!!ghost.data.assembly && (
              <ThinChip
                className="f-full"
                label={ghost.data.assembly.name}
                color={'var(--primaryColor)'}
                backgroundColor={'var(--primaryColorBcg)'}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="f-row gap-2">
      <div className="relative" style={{ backgroundColor: 'white', borderRadius: 4 }}>
        <SmallButton
          color={'var(--primaryColor)'}
          style={buttonSaveStyle}
          width="auto"
          onClick={onSave}
          loading={isSavingAssembly}
          disabled={props.saveButtonsDisabled || _haveGhost.length}>
          <div className="f-center gap-2">
            <>
              <Icon name="save" title={intl.formatMessage(actionMessages.save)} pointer />
              <span>
                {intl.formatMessage(
                  statusButtonLabel ? actionMessages.saveStatus : actionMessages.save
                )}
              </span>
            </>
          </div>
        </SmallButton>
        {_haveGhost.length > 0 && (
          <div
            className="f-row f1-center"
            style={{
              position: 'absolute',
              top: -6,
              right: -6,
              zIndex: 100,
              backgroundColor: 'var(--light-orange)',
              borderRadius: 50,
              width: 22,
              height: 22,
              border: '1px solid var(--orange)'
            }}>
            <IconTooltip
              name="alert-2"
              title={tooltipContent}
              color="var(--orange)"
              hoverColor="var(--orange)"
            />
          </div>
        )}
      </div>
    </div>
  );
}
