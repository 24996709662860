import UnityAPI from './UnityAPI/UnityAPI';
import CameraPresets from './CameraPresets';
import CameraModes from './UnityAPI/CameraModes';

export default class CameraManager {
  static setCameraMode(cameraMode) {
    if (cameraMode === CameraModes.View2D) UnityAPI.setCamera(CameraPresets.twoD);
    else if (cameraMode === CameraModes.View3D) UnityAPI.setCamera(CameraPresets.threeD);
  }

  static reset() {
    UnityAPI.setCamera(CameraPresets.initial);
  }

  static setRotate3d(enabled) {
    UnityAPI.setCamera({ rotate: enabled });
  }

  static zoomIn() {
    UnityAPI.setCamera({ zoomDiff: -2, transition: 0.75 });
  }

  static zoomOut() {
    UnityAPI.setCamera({ zoomDiff: 2, transition: 0.75 });
  }

  /**
   * 
   * @param {number} x - 
   * @param {number} z - 
   * @param {number} speed - 0 is fast, 1 is slow
   */
  static focus(x, z, speed = 0.75) {
    UnityAPI.setCamera({
      position: {
        x: x,
        z: z
      },
      transition: speed,
    });
  }
}
