import builderModel from './UnityAPI/BuilderModel';
import HookEvents from './UnityAPI/HookEvents';
//import { SendEventToUnity, ZfEvents } from './ZfBrowserAPI';
//import { EventBus } from 'hakobio-three-viewer';

const Message = (payload) => {
  //For Unity Webgl
  const keys = Object.keys(window.UnityInstance);

  if(keys.length === 1){
    if (window.UnityInstance[keys[0]]) {
      if (!window.UnityInstance[keys[0]]?.isReady) return null;
  
      window.UnityInstance[keys[0]].SendMessage('PluginAPI', 'ExecuteAction', JSON.stringify(payload));
    } else {
      //For Embedded browser
      //SendEventToUnity(ZfEvents.ExecuteAction, JSON.stringify(payload));
    }
  } else if(keys.length > 1){
    if (window.UnityInstance[window.UnityInstanceName]) {
      if (!window.UnityInstance[window.UnityInstanceName]?.isReady) return null;
  
      window.UnityInstance[window.UnityInstanceName].SendMessage('PluginAPI', 'ExecuteAction', JSON.stringify(payload));
    } else {
      //For Embedded browser
      //SendEventToUnity(ZfEvents.ExecuteAction, JSON.stringify(payload));
    }   
  }
};

let promises = {};

const actionDone = (res) => {
  if (!res) return;
  if (process.env.NODE_ENV !== 'production' || window.showLog) {
    console.log(
      '%c <= Action Done ',
      'color: #030706; background-color: #FFF;padding:4px;border:solid 1px #030706;border-radius: 4px;',
      `action ${res.action} done, returned data: ${res.res}`
    );
  }

  if (!(res.action in promises)) return;

  if (res.err) promises[res.action].reject(res.err);
  else promises[res.action].resolve(res.res);

  delete promises[res.action];
};

builderModel.register(HookEvents.ActionDone, actionDone);

const LogAction = (action, data) => {
  if (process.env.NODE_ENV !== 'production' || window.showLog) {
    console.log(
      '%c => Execute Action ',
      'color: #030706; background-color: #FFF;padding:4px;border:solid 1px #030706;border-radius: 4px;',
      'action : ' + action + ', data : ',
      JSON.stringify(data)
    );
  }
};

//This creates a promise and expects an ActionDone event (ack=true)
const ExecuteAction = (action, data) => {
  LogAction(action, data);

  const keys = Object.keys(window.UnityInstance);

  if (window.UnityInstance[keys[0]] || window._zfb_event) {
    return new Promise((resolve, reject) => {
      promises[action] = {
        resolve: resolve,
        reject: reject
      };

      Message({
        action: action,
        ack: true,
        data: typeof data === 'string' ? data : JSON.stringify(data)
      });
    });
  } else {
    //For three viewer
    //return EventBus.executeAction(action, data);

  }
};

//This doesn't create a promise and doesn't expect an ActionDone event (ack=false)
const SimpleExecuteAction = (action, data) => {
  LogAction(action, data);

  const keys = Object.keys(window.UnityInstance);

  if (window.UnityInstance[keys[0]] || window._zfb_event) {
    Message({
      action: action,
      ack: false,
      data: typeof data === 'string' ? data : JSON.stringify(data)
    });
  } else {

    //return EventBus.executeAction(action, data);
  }
};

export { ExecuteAction, SimpleExecuteAction, Message };
