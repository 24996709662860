import _ from "lodash";
// @ts-ignore
import {v4 as uuidv4} from "uuid";

export const checkPFDEditor = (components: any) => {
  const componentsCopy = _.cloneDeep(components);
  const failedRules: any[] = [];
  componentsCopy
    .filter((component: any) => component.type === "functionedge" && !component.data.ghost)
    .forEach((component: any) => {
    });
  return failedRules;
};
