import { Empty, Icon, PanelHeader, TextInput, ThinChip, Title } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { configMessages, versioningMessages } from '../../../../lang/messages';

import { cloneDeep } from 'lodash';
import { useAppSelector } from '../../../../store';
import {
  referenceStatusBackgroundColors,
  referenceStatusColors
} from '../../../../utilities/statuses/referenceStatus';
import { useState } from 'react';
import { SUPPLIERS, Supplier } from '../../../../constants/SUPPLIERS';
import {useCustomBehavior} from "../../../../utils";

const SingleUseEditorReferencesPanel = (props) => {
  const allHeadersLibrary = useAppSelector((state) => state.assemblyEditorSlice.allHeadersLibrary);
  const intl = useIntl();
  const [_referenceHovered, setReferenceHovered] = useState(null);

  const { openReactAppHistoryInNewTab } = useCustomBehavior();


  let referenceList = props.referenceList || [];

  function getReferenceCopy(reference: any) {
    let idRef = reference.parameters.find((param) => param.name === 'id').value;

    let assemblyComplete = allHeadersLibrary.find((assembly) => assembly.id === idRef);

    return cloneDeep(assemblyComplete);
  }

  function getRefParams(reference: any, value: any) {
    const referenceCopy = getReferenceCopy(reference);
    const paramValue = referenceCopy?.parameters.find((param) => param.name === value);
    if (value === 'supplier') {
      const supplier = Object.values(SUPPLIERS).find(
        (supplier: Supplier) => supplier.id === paramValue?.value
      );
      return supplier?.name || 'N/C';
    } else {
      return paramValue?.value || 'N/C';
    }
  }

  const openAssemblyVersion = (reference: any) => {
    const referenceCopy = getReferenceCopy(reference);

    referenceCopy.hashPartitionKey = referenceCopy.id + '-' + referenceCopy.version;
    localStorage.setItem('action', 'assemblyVersion');
    localStorage.setItem('actionData', JSON.stringify(referenceCopy));
    openReactAppHistoryInNewTab();
  };

  const displayReferences = (
    <div className="overflow-y-auto">
      {referenceList.length > 0 && allHeadersLibrary ? (
        referenceList.map((ref) => {
          return (
            <div
              key={ref.id}
              className="f-col gap-2 p-2 hover-item"
              onClick={() => openAssemblyVersion(ref)}
              onMouseEnter={() => setReferenceHovered(ref.id)}
              onMouseLeave={() => setReferenceHovered(null)}>
              <div className="f-row f1-between f2-center w-100">
                <div className="f-row f2-center gap-2 font-regular">
                  <Icon name="label" />
                  <span className="font-regular max-lines-1">{ref.name}</span>
                </div>
                {_referenceHovered === ref.id && (
                  <Icon className="pl-2 items-action-none" name="eye" />
                )}
              </div>
              <>
                <div className="f-row f2-left gap-2 w-100">
                  <ThinChip
                    color={'var(--primaryColor)'}
                    backgroundColor={'var(--primaryColorBcg)'}
                    label={
                      <span>
                        {intl.formatMessage(versioningMessages.versionAbr) +
                          (ref.version + 1 || '1')}
                      </span>
                    }
                  />
                  <ThinChip
                    color={referenceStatusColors(getRefParams(ref, 'status'))}
                    backgroundColor={referenceStatusBackgroundColors(getRefParams(ref, 'status'))} // needs to be camelCase in order to work?
                    label={<span className="capitalize">{getRefParams(ref, 'status')}</span>}
                  />
                </div>
                <ThinChip
                  color={'var(--anthracite)'}
                  backgroundColor={'var(--light-grey)'}
                  className="font-regular max-lines-1"
                  label={<span className="font-regular">{getRefParams(ref, 'supplier')}</span>}
                />
              </>
            </div>
          );
        })
      ) : (
        <Empty size="md" text="No SUA reference has been added yet" />
      )}
    </div>
  );

  return (
    <div className="f-col f-full h-100 relative container-action">
      {/* @ts-ignore */}
      <>
        {!props.startUpPanel && (
          <PanelHeader>
            <div className="f-row  f2-center gap-2">
              <Icon name="lego-blocks" />
              {intl.formatMessage(configMessages.componentsLibrary)}
            </div>
          </PanelHeader>
        )}
        <div className="px-2">
          {!props.startUpPanel && (
            <TextInput
              className="pt-2"
              placeholder={intl.formatMessage({
                id: 'Global.Action.Search',
                defaultMessage: 'Search'
              })}
              value={''}
              endAdornment={<Icon name="search" />}
            />
          )}
          <Title style={{ paddingTop: 0, marginBottom: 4 }}>
            {intl.formatMessage(configMessages.referenceBasedOnThisDesign)}
          </Title>
        </div>
      </>
      <div className="px-2">{displayReferences}</div>
    </div>
  );
};

export default withRouter(SingleUseEditorReferencesPanel);
