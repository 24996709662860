export enum SearchTypes {
  Function = 'function',
  AncillaryFunction = 'ancillaryFunction',
  ParentSUA = 'parentSua',
  Status = 'status',
  Connection = 'connection',
  TubingMaterial = 'tubingMaterial',
  TubingDiameter = 'tubingDiameter',
  Pumping = 'pumpings',
  Container = 'container',
  Volume = 'volume',
  Sensor = 'sensor',
  Supplier = 'supplier',
  CombinedPorosity = 'combinedPorosity',
  Folders= 'folders',
}
