export enum ReportData {
  FrontPage = 'frontPage',
  HeaderInformation = 'headerInformation',
  TableOfContent = 'tableOfContent',
  PFDView = 'PFDView',
  PIDView = 'PIDView',
  AllSUA = 'allSUA',
  Metrics = 'metrics',
  AllReference = 'allReference'
}
