import React, { useRef } from 'react';

import { CustomIcon } from 'hakobio-react-ui';

import {
  getAssembliesCount,
  getAssembliesCountByType,
  getConnectionCount,
  getConnectionCountByType
} from '../../../services/metrics/metricsServiceUtilities';
import { CUSTOM_NODES } from '../../../constants/CUSTOM_NODES';

interface Props {
  assemblyGeneral: any;
  assemblyComponents: any;
  exportDate: string;
  pageNumber: number;
  totalPageNumber: number;
  time?: number;
}

const Metrics = ({
  assemblyGeneral,
  assemblyComponents,
  exportDate,
  pageNumber,
  totalPageNumber,
  time
}: Props) => {
  const renderUniqueDesigns = () => {
    const assembliesCountByType = getAssembliesCountByType(assemblyComponents);
    return (
      <div className="p-2 border-bottom f-row f1-between">
        <div className="bold">Unique Designs</div>
        <div className="bold">{assembliesCountByType.length}</div>
      </div>
    );
  };

  const renderAssemblies = () => {
    const assembliesCount = getAssembliesCount(assemblyComponents);
    const assembliesCountByType = getAssembliesCountByType(assemblyComponents);
    const elementsToReturn = [];
    elementsToReturn.push(
      <div className="p-2 f-row f1-between" key="suas">
        <div className="bold">SUA's</div>
        <div className="bold">{assembliesCount}</div>
      </div>
    );

    for (let i = 0; i < assembliesCountByType.length; i = i + 2) {
      elementsToReturn.push(
        <div className="col-count-2 px-3 pb-1" key={`sua-${i}`}>
          <div className="f-row">
            <div className="w-70">{assembliesCountByType[i].name}</div>
            <div>{assembliesCountByType[i].count}</div>
          </div>
          {assembliesCountByType.length > i + 1 && (
            <div className="f-row">
              <div className="w-70">{assembliesCountByType[i + 1].name}</div>
              <div>{assembliesCountByType[i + 1].count}</div>
            </div>
          )}
        </div>
      );
    }

    return elementsToReturn;
  };

  const renderConnections = () => {
    const connectionCount = getConnectionCount(assemblyComponents);
    const connectionCountByType = getConnectionCountByType(assemblyComponents);
    const elementsToReturn = [];
    elementsToReturn.push(
      <div className="p-2 f-row f1-between border-top" key="connections">
        <div className="bold">Connections</div>
        <div className="bold">{connectionCount}</div>
      </div>
    );

    for (let i = 0; i < connectionCountByType.length; i = i + 2) {
      elementsToReturn.push(
        <div className="col-count-2 px-3 pb-1" key={`connection-${i}`}>
          <div className="f-row">
            <div className="w-70">
              {`${CUSTOM_NODES[connectionCountByType[i].end1].name} - ${
                CUSTOM_NODES[connectionCountByType[i].end2].name
              }`}
            </div>
            <div>{connectionCountByType[i].count}</div>
          </div>
          {connectionCountByType.length > i + 1 && (
            <div className="f-row">
              <div className="w-70">
                {CUSTOM_NODES[connectionCountByType[i + 1].end1].name} -{' '}
                {CUSTOM_NODES[connectionCountByType[i + 1].end2].name}
              </div>
              <div>{connectionCountByType[i + 1].count}</div>
            </div>
          )}
        </div>
      );
    }

    return elementsToReturn;
  };

  const elementsToRender = [];
  elementsToRender.push(renderUniqueDesigns());
  renderAssemblies().forEach((element) => {
    elementsToRender.push(element);
  });
  renderConnections().forEach((element) => {
    elementsToRender.push(element);
  });

  const render = () => {
    const elements =
      elementsToRender.length < 19
        ? elementsToRender
        : time === 0
        ? elementsToRender.slice(0, 18)
        : elementsToRender.slice(time * 18, time * 18 + 18);

    return (
      <div
        className="pb-3 pt-1 px-4 f-col h-100 w-100 relative"
        style={{
          breakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always',
          pageBreakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always'
        }}>
        <div className="border-bottom py-2">
          <div className="f-row f1-between f2-center">
            <div>
              Metrics | <span className="font-regular">{assemblyGeneral.name}</span>
            </div>
            <div>{exportDate}</div>
          </div>
        </div>
        <div className="f-col f1-start gap-2 f-full p-2" style={{ height: 690 }}>
          {elements.map((elements) => elements)}
        </div>
        <div className="f-row f1-end gap-2 mt-2" style={{ fontSize: 13 }}>
          {time > 0 && (
            <div className="dark-orange f-row f2-center gap-1">
              <CustomIcon name="alert-2" />
              Continued from previous page
            </div>
          )}
          <div>{`Page ${pageNumber}/${totalPageNumber}`}</div>
        </div>
      </div>
    );
  };

  return render();
};

export default Metrics;
