import connectingSVG from "./schemas/functions/connecting.svg";
import disconnectingSVG from "./schemas/functions/disconnecting.svg";
import measuringSVG from './schemas/functions/measuring.svg';
import muralTransferringSVG from "./schemas/functions/muralTransferring.svg"
import separationSVG from "./schemas/functions/separation.svg";
import mixingSVG from "./schemas/functions/mixing.svg";
import storageSVG from "./schemas/functions/storage.svg";
import filteringSVG from "./schemas/functions/filtering.svg";
import transferringSVG from "./schemas/functions/transferring.svg";
import cultureSVG from "./schemas/functions/culture.svg";
import wasteSVG from "./schemas/functions/waste.svg";
import utilitiesSVG from "./schemas/functions/utilities.svg";
import ventingSVG from "./schemas/functions/venting.svg";
import pumpingSVG from "./schemas/functions/pumping.svg";
import samplingSVG from "./schemas/functions/sampling.svg";

import SVGInject from "@iconfu/svg-inject/dist/svg-inject.min.js";

export const CUSTOM_FUNCTIONS: any = {
    connecting: {
        name: "Connecting",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/connecting.json"),
        form: (number = 80) => {
            return <img alt="connecting" src={connectingSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    disconnecting: {
        name: "Disconnecting",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/disconnecting.json"),
        form: (number = 80) => {
            return <img alt="disconnecting" src={disconnectingSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    measuring: {
        name: "Measuring",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/measuring.json"),
        form: (number = 80) => {
            return <img alt="measuring" src={measuringSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    muralTransferring: {
        name: "Mural transferring",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/muralTransferring.json"),
        form: (number = 80) => {
            return <img alt="muralTransferring" src={muralTransferringSVG} draggable={false} height={number}
                        width={number} onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    separation: {
        name: "Separation",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/separation.json"),
        form: (number = 80) => {
            return <img alt="separation" src={separationSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    purification: {
        name: "Purification",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/separation.json"),
        form: (number = 80) => {
            return <img alt="separation" src={separationSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    mixing: {
        name: "Mixing",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/mixing.json"),
        form: (number = 80) => {
            return <img alt="mixing" src={mixingSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    storage: {
        name: "Storage",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/storage.json"),
        form: (number = 80) => {
            return <img alt="storage" src={storageSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    filtering: {
        name: "Filtering",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/filtering.json"),
        form: (number = 80) => {
            return <img alt="filtering" src={filteringSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    transferring: {
        name: "Transferring",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/transferring.json"),
        form: (number = 80) => {
            return <img alt="transferring" src={transferringSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    culture: {
        name: "Culture",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/culture.json"),
        form: (number = 80) => {
            return <img alt="culture" src={cultureSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    waste: {
        name: "Waste",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/waste.json"),
        form: (number = 80) => {
            return <img alt="waste" src={wasteSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    utilities: {
        name: "Utilities",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/utilities.json"),
        form: (number = 80) => {
            return <img alt="utilities" src={utilitiesSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    venting: {
        name: "Venting",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/venting.json"),
        form: (number = 80) => {
            return <img alt="venting" src={ventingSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        }
    },
    pumping: {
        name: "Pumping",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/pumping.json"),
        form: (number = 80) => {
            return <img alt="pumping" src={pumpingSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        },
    },
    sampling: {
        name: "Sampling",
        anchors: [],
        size: 80,
        schema: async () => await import("./schemas/functions/sampling.json"),
        form: (number = 80) => {
            return <img alt="sampling" src={samplingSVG} draggable={false} height={number} width={number}
                        onLoad={(e: any) => SVGInject(e.target)}/>
        },
    }
};
