import { RuleComponentEdgeComponent } from '../RuleComponentEdgeComponent';
import { v4 as uuidv4 } from 'uuid';

export class RuleAsepticConnectorTubingWeldable extends RuleComponentEdgeComponent {
  schemaComponentSourceList = ['asepticConnector'];
  schemaComponentTargetList = ['asepticConnector'];
  schemaTubeList = ['tubing'];
  editorList = ['SUE', 'UOE_PID'];

  checkPrerequisite = () => {
    const isTube = this.schemaTubeList.includes(this.edge.data.type);
    const isSourceAsepticConnector = this.componentSource ? this.schemaComponentSourceList.includes(this.componentSource.data.type) : false;
    const isTargetAsepticConnector = this.componentTarget ? this.schemaComponentTargetList.includes(this.componentTarget.data.type) : false;
    if (isTube && isSourceAsepticConnector) {
      return true;
    }
    if (isTube && isTargetAsepticConnector) {
      return true;
    }
    return false;
  };

  checkRule = () => {
    if (this.componentSource.data.type === 'asepticConnector' && this.edge['componentType'] === 'Weldable') {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible tube',
        errors:
          'Impossible to connect an aseptic connector with a weldable tube',
        // @ts-ignore
        componentAnchors: [{ anchorId: this.componentSourceAnchor.id, componentId: this.componentSource.id }]
      });
    }

    if (this.componentTarget.data.type === 'asepticConnector' && this.edge['componentType'] === 'Weldable') {
      this.errorList.push({
        id: uuidv4(),
        name: 'Incompatible tube',
        errors:
          'Impossible to connect an aseptic connector with a weldable tube',
        // @ts-ignore
        componentAnchors: [{ anchorId: this.componentTargetAnchor.id, componentId: this.componentTarget.id }]
      });
    }
  };
}