import { Button, Icon, SignPost, ThinChip, Tooltip } from 'hakobio-react-ui';
import _, { indexOf } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { getBezierPath, getEdgeCenter } from 'react-flow-renderer';
import { CUSTOM_NODES } from '../../../constants/CUSTOM_NODES';
import { EditorMode } from '../../../constants/EditorMode';
import { getTubeColor } from '../../../services/tubing/getTubeColor';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { angle, Uuidv4 } from '../../../utilities';
import EdgeInfoTooltip from '../utility/EdgeInfoTooltip';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { getTubeColorByLayerAndEditor } from '../../../services/tubing/getTubeColorByLayer';



const foreignObjectSize = 24;

const GenericEdge = (props: any) => {
  const selectedComponents = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedComponents
  );

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);
  const componentSnappable = useAppSelector(
    (state) => state.assemblyEditorSlice.isComponentSnappable
  );
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);
  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const hoverEdge = useAppSelector((state) => state.assemblyEditorSlice.hoverEdge);
  const hoverTube = useAppSelector((state) => state.assemblyEditorSlice.hoverTube);

  const isSavingScreenshotSUAAnnotated = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUAAnnotated
  );

  const [_edge, setEdge] = useState({}) as any;
  const [componentSource, setComponentSource] = useState({}) as any;
  const [componentTarget, setComponentTarget] = useState({}) as any;
  const [anchorSource, setAnchorSource] = useState({}) as any;
  const [anchorTarget, setAnchorTarget] = useState({}) as any;
  const [markendArrow, setMarkendArrow] = useState(true) as any;
  const [_onHover, setOnHover] = useState(false) as any;
  const [_showTooltip, setShowTooltip] = useState(false) as any;
  const [_schema, setSchema] = useState<any | null>(null);

  const getSchemaAsync = async () => {
    try {
      const schema = await CUSTOM_NODES[component.data.type].schema();
      setSchema(schema);
    } catch (error) {
      setSchema(null);
    }
  };

  useEffect(() => {
    getSchemaAsync();
  }, []);

  useEffect(() => {
    if (hoverEdge && hoverEdge === _edge.id && !_onHover) {
      const timer = setTimeout(() => {
        setShowTooltip(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setShowTooltip(false);
    }
  }, [hoverEdge, _edge, _onHover]);

  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEndId
  } = props;

  const { onEdgeCenterMoved } = useAssemblyEditorAction();
  const component = components.find((c) => c.id === id);
  const dispatch = useAppDispatch();
  const edgeRef = useRef(null);
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY
  });
  // @ts-ignore
  const realEdgeLength = edgeRef.current?.getTotalLength();
  // @ts-ignore
  const realCenter = realEdgeLength ? edgeRef.current?.getPointAtLength(0.5 * realEdgeLength) : 0;

  const annotationCenter = realEdgeLength
    ? edgeRef.current?.getPointAtLength(0.3 * realEdgeLength)
    : 0;

  const listComparisonVersions = useAppSelector(
    (state) => state.assemblyEditorSlice.listComparionVersions
  );
  const [_componentDifference, setComponentDifference] = useState() as any;
  const componentDifference = listComparisonVersions.find((c: any) => c.id === component?.id);

  useEffect(() => {
    if (componentDifference) {
      setComponentDifference(componentDifference);
    }
  }, [componentDifference]);

  useEffect(() => {
    let componentSrc = null;
    let componentTrg = null;
    let a = null;
    let b = null;

    const edge = components.find((c) => c.id === id);
    setEdge(edge);

    if (!edge) {
      return;
    }

    componentSrc = components.find((c) => c.id === edge.source);
    setComponentSource(componentSrc);

    componentTrg = components.find((c) => c.id === edge.target);
    setComponentTarget(componentTrg);

    if (componentSrc) {
      a = componentSrc.data.anchors.find((a: any) => a.id === edge.sourceHandle);
      if (!a)
        a = componentSrc.data.instrumentationPorts.find((a: any) => a.id === edge.sourceHandle);
      if (!a) a = componentSrc.data.samplingPorts.find((a: any) => a.id === edge.sourceHandle);
      setAnchorSource(a);
    }

    if (componentTrg) {
      b = componentTrg.data.anchors.find((a: any) => a.id === edge.targetHandle);
      if (!b)
        b = componentTrg.data.instrumentationPorts.find((a: any) => a.id === edge.targetHandle);
      if (!b) b = componentTrg.data.samplingPorts.find((a: any) => a.id === edge.targetHandle);
      setAnchorTarget(b);
    }
    if (markendArrow && a.type === 'neutral' && b.type === 'neutral') {
      setMarkendArrow(false);
      const componentSrcCopy = _.cloneDeep(componentSrc);
      if (componentSrcCopy.viewer2D.pfd && componentSrcCopy.viewer2D.pid) {
        componentSrcCopy.viewer2D.pfd.x += 0.00000001;
        componentSrcCopy.viewer2D.pid.x += 0.00000001;
      } else {
        componentSrcCopy.viewer2D.x += 0.00000001;
      }
      const promiseSrc = { id: componentSrcCopy.id, component: componentSrcCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseSrc));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentSrcCopy));

      const componentTrgCopy = _.cloneDeep(componentTrg);
      if (componentTrgCopy.viewer2D.pfd && componentTrgCopy.viewer2D.pid) {
        componentTrgCopy.viewer2D.pfd.x += 0.00000001;
        componentTrgCopy.viewer2D.pid.x += 0.00000001;
      } else {
        componentTrgCopy.viewer2D.x += 0.00000001;
      }
      const promiseTrg = { id: componentTrgCopy.id, component: componentTrgCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseTrg));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentTrgCopy));
    } else if (!markendArrow && (a.type !== 'neutral' || b.type !== 'neutral')) {
      setMarkendArrow(true);
      const componentSrcCopy = _.cloneDeep(componentSrc);
      if (componentSrcCopy.viewer2D.pfd && componentSrcCopy.viewer2D.pid) {
        componentSrcCopy.viewer2D.pfd.x += 0.00000001;
        componentSrcCopy.viewer2D.pid.x += 0.00000001;
      } else {
        componentSrcCopy.viewer2D.x += 0.00000001;
      }
      const promiseSrc = { id: componentSrcCopy.id, component: componentSrcCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseSrc));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentSrcCopy));

      const componentTrgCopy = _.cloneDeep(componentTrg);
      if (componentTrgCopy.viewer2D.pfd && componentTrgCopy.viewer2D.pid) {
        componentTrgCopy.viewer2D.pfd.x += 0.00000001;
        componentTrgCopy.viewer2D.pid.x += 0.00000001;
      } else {
        componentTrgCopy.viewer2D.x += 0.00000001;
      }
      const promiseTrg = { id: componentTrgCopy.id, component: componentTrgCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseTrg));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentTrgCopy));
    }
  }, [markerEndId, id, components]);

  useEffect(() => {
    let componentSrc = null;
    let componentTrg = null;
    let a = null;
    let b = null;

    const edge = components.find((c) => c.id === id);
    setEdge(edge);
    if (!edge) {
      return;
    }
    componentSrc = components.find((c) => c.id === edge.source);
    setComponentSource(componentSrc);

    componentTrg = components.find((c) => c.id === edge.target);
    setComponentTarget(componentTrg);

    if (componentSrc) {
      a = componentSrc.data.anchors.find((a: any) => a.id === edge.sourceHandle);
      if (!a)
        a = componentSrc.data.instrumentationPorts.find((a: any) => a.id === edge.sourceHandle);
      if (!a) a = componentSrc.data.samplingPorts.find((a: any) => a.id === edge.sourceHandle);
      setAnchorSource(a);
    }

    if (componentTrg) {
      b = componentTrg.data.anchors.find((a: any) => a.id === edge.targetHandle);
      if (!b)
        b = componentTrg.data.instrumentationPorts.find((a: any) => a.id === edge.targetHandle);
      if (!b) b = componentTrg.data.samplingPorts.find((a: any) => a.id === edge.targetHandle);
      setAnchorTarget(b);
    }

    if (markendArrow && a.type === 'neutral' && b.type === 'neutral') {
      setMarkendArrow(false);
      const componentSrcCopy = _.cloneDeep(componentSrc);
      if (componentSrcCopy.viewer2D.pfd && componentSrcCopy.viewer2D.pid) {
        componentSrcCopy.viewer2D.pfd.x += 0.00000001;
        componentSrcCopy.viewer2D.pid.x += 0.00000001;
      } else {
        componentSrcCopy.viewer2D.x += 0.00000001;
      }
      const promiseSrc = { id: componentSrcCopy.id, component: componentSrcCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseSrc));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentSrcCopy));

      const componentTrgCopy = _.cloneDeep(componentTrg);
      if (componentTrgCopy.viewer2D.pfd && componentTrgCopy.viewer2D.pid) {
        componentTrgCopy.viewer2D.pfd.x += 0.00000001;
        componentTrgCopy.viewer2D.pid.x += 0.00000001;
      } else {
        componentTrgCopy.viewer2D.x += 0.00000001;
      }
      const promiseTrg = { id: componentTrgCopy.id, component: componentTrgCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseTrg));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentTrgCopy));
    } else if (!markendArrow && (a.type !== 'neutral' || b.type !== 'neutral')) {
      setMarkendArrow(true);
      const componentSrcCopy = _.cloneDeep(componentSrc);
      if (componentSrcCopy.viewer2D.pfd && componentSrcCopy.viewer2D.pid) {
        componentSrcCopy.viewer2D.pfd.x += 0.00000001;
        componentSrcCopy.viewer2D.pid.x += 0.00000001;
      } else {
        componentSrcCopy.viewer2D.x += 0.00000001;
      }
      const promiseSrc = { id: componentSrcCopy.id, component: componentSrcCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseSrc));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentSrcCopy));

      const componentTrgCopy = _.cloneDeep(componentTrg);
      if (componentTrgCopy.viewer2D.pfd && componentTrgCopy.viewer2D.pid) {
        componentTrgCopy.viewer2D.pfd.x += 0.00000001;
        componentTrgCopy.viewer2D.pid.x += 0.00000001;
      } else {
        componentTrgCopy.viewer2D.x += 0.00000001;
      }
      const promiseTrg = { id: componentTrgCopy.id, component: componentTrgCopy };
      dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promiseTrg));
      //dispatch(assemblyEditorSliceActions.updateComponent(componentTrgCopy));
    }
  }, []);

  useEffect(() => {
    components.forEach((e) => {
      if (e.type === 'genericonedge' && (e as any).viewer2D.source === id) {
        if (edgeRef !== null && edgeRef.current !== null) {
          // @ts-ignore
          const edgeLength = edgeRef.current.getTotalLength();
          // @ts-ignore
          let edgeLoc = edgeRef.current.getPointAtLength(
            // @ts-ignore
            e.viewer2D.position * edgeLength
          );

          // @ts-ignore
          let edgeLoc2 = edgeRef.current.getPointAtLength(
            // @ts-ignore
            (e.viewer2D.position + 0.02) * edgeLength
          );

          let rotation = angle(edgeLoc.x, edgeLoc.y, edgeLoc2.x, edgeLoc2.y);

          onEdgeCenterMoved(e, {
            // @ts-ignore
            ...e.viewer2D,
            x: edgeLoc.x - 15,
            y: edgeLoc.y - 15,
            rotate: rotation - 90
          });
        }
      }
    });
  }, [edgeCenterX, edgeCenterY]);

  const onDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOnHover(false);
    const constant = JSON.parse(event.dataTransfer.getData('application/reactflow'));
    if (event.dataTransfer && edgeRef.current && constant.node.snappable) {
      // @ts-ignore
      const edgeLength = edgeRef.current.getTotalLength();
      // @ts-ignore
      let edgeLoc = edgeRef.current.getPointAtLength(0.5 * edgeLength);
      // @ts-ignore
      let edgeLoc2 = edgeRef.current.getPointAtLength(0.52 * edgeLength);
      let rotation = angle(edgeLoc.x, edgeLoc.y, edgeLoc2.x, edgeLoc2.y);

      const newNode = {
        id: Uuidv4(),
        type: 'genericonedge',
        draggable: false,
        viewer2D: {
          source: id,
          position: 0.5,
          rotate: rotation - 90,
          x: edgeLoc.x - 15,
          y: edgeLoc.y - 15,
          size: constant.node.size
        },
        data: {
          type: constant.key,
          anchors: []
        }
      };
      dispatch(assemblyEditorSliceActions.addComponent(newNode));
    }
  };

  const contentTooltip = () => {
    if (_edge?.data && Object.entries(_edge?.data).length > 0) {
      // @ts-ignore
      return <EdgeInfoTooltip edge={_edge} />;
    } else {
      return null;
    }
  };

  const contentVersionTooltip = () => {
    return (
      <div className="p-2 overflow-y-auto" style={{ maxHeight: 400 }}>
        <>
          <div key={_componentDifference?.id} className="font-regular">
            {CUSTOM_NODES[_componentDifference?.type]?.name}
          </div>
          <div className="pl-2 f-col gap-2">
            {_componentDifference?.changes.map((change) => {
              if (change.key !== 'anchors') {
                return (
                  <div key={change.key}>
                    <span className=" capitalize">
                      {_schema?.properties[change.key].name || change.key}
                    </span>
                    <div className="f-row f2-start gap-2">
                      <ThinChip
                        color={'var(--primaryColor)'}
                        backgroundColor={'var(--primaryColorBcg)'}
                        label={
                          (
                            <span>{`${change.viewingValue}${_schema?.properties[change.key]?.units &&
                                change.viewingValue !== 'N/C'
                                ? ' ' + _schema?.properties[change.key].units
                                : ''
                              }`}</span>
                          ) || 'N/C'
                        }
                      />
                      <Icon name={'transfer'} />
                      <ThinChip
                        label={
                          (
                            <span>{`${change.comparingValue}${_schema?.properties[change.key]?.units &&
                                change.comparingValue !== 'N/C'
                                ? ' ' + _schema?.properties[change.key].units
                                : ''
                              }`}</span>
                          ) || 'N/C'
                        }
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </>
      </div>
    );
  };

  const dragGenericOnEdge = (event: any) => {
    if (componentSnappable) setOnHover(true);
  };

  const leaveGenricOnEdge = () => {
    setOnHover(false);
  };

  const renderPath = (edge : any) => {   
    const pathIsSelected = selectedComponents?.find((sc) => sc === _edge.id);
    let componentField;
    let viewField;

    switch (layerMode) {
      case UnitOperationLayer.PnID:
        componentField = 'assembly';
        viewField = 'pid';
        break;
      case UnitOperationLayer.Reference:
        if (edge?.data.assembly && !edge?.data.assemblyReference) {     
          componentField = 'assembly';
          viewField = 'pid';
        } else {
          componentField = 'assemblyReference';
          viewField = 'reference';
        }
    }

    return (
      <path
        onDragEnter={dragGenericOnEdge}
        onDragLeave={leaveGenricOnEdge}
        ref={edgeRef}
        onDrop={onDrop}
        id={id}
        style={{ ...style, cursor: 'pointer' }}
        d={edgePath}
        stroke={
          _onHover
            ? 'var(--green)'
            : pathIsSelected || hoverEdge === _edge.id || hoverTube === _edge.id
              ? 'var(--blue)'
              : _edge?.data?.errors
                ? 'var(--red)'
                : getTubeColorByLayerAndEditor(_edge?.data?.['componentType'],componentField,editorMode,layerMode)
        }
        strokeWidth={hoverEdge === _edge.id ? 8 : pathIsSelected ? 7 : 6}
        fill="none"
      />
    );
  };

  const renderAnnotation = () => {
    switch (editorMode) {
      case EditorMode.SUA:
      case EditorMode.Reference:
        if (isSavingScreenshotSUAAnnotated) {
          const element = general.legend?.find((l) => l?.ids?.includes(id));
          let indexNumber = indexOf(general.legend, element);
          return (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: '30px',
                border: '1px solid white',
                backgroundColor: 'var(--frozen-grey)',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
                fontSize: 16,
                fontFamily: 'aileron-regular',
                height: '100%',
                width: '100%'
              }}>
              {indexNumber + 1}
            </div>
          );
        } else {
          return null;
        }
      case EditorMode.UnitOperation:
        return null;
    }
  };

  const renderCustomIconVersion = () => {
    return (
      <Tooltip placement="top" title={contentVersionTooltip()}>
        <Icon
          name="history"
          color="orange"
          size="12px"
          style={{ lineHeight: 0.5, padding: '2px' }}
        />
      </Tooltip>
    );
  };

  if (!component) {
    return null;
  }

  return (
    <>
      {renderPath(component)}

      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={annotationCenter?.x - 11}
        y={annotationCenter?.y - 11}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml">
        <body style={{ backgroundColor: 'transparent' }}>{renderAnnotation()}</body>
      </foreignObject>
      <foreignObject
        width={2}
        height={2}
        x={realCenter?.x - 11}
        y={realCenter?.y - 11}
        className="edgebutton-foreignobject"
        style={{ cursor: 'pointer' }}
        requiredExtensions="http://www.w3.org/1999/xhtml">
        <body style={{ backgroundColor: 'transparent' }}>
          {(_showTooltip || hoverEdge === _edge.id) && <Tooltip open title={contentTooltip()} />}
        </body>
      </foreignObject>
      {componentDifference && (
        <foreignObject
          width={'20'}
          height={'152'}
          x={realCenter?.x - 10}
          y={realCenter?.y - 76}
          className="edgebutton-foreignobject .max-1-lines-visible"
          requiredExtensions="http://www.w3.org/1999/xhtml">
          <body className="f-col f2-center h-100" style={{ backgroundColor: 'transparent' }}>
            <SignPost poleColor="var(--dark-grey)">
              <div>{renderCustomIconVersion()}</div>
            </SignPost>
          </body>
        </foreignObject>
      )}
    </>
  );
};

export default memo(GenericEdge);
