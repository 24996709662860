import React from 'react';
import { config } from '../../../services/config';
import { CUSTOM_FUNCTIONS } from '../../../constants/CUSTOM_FUNCTIONS';
// import { CustomIcon } from 'hakobio-react-ui';
import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { configMessages } from '../../../lang/messages';

interface Props {
  assemblyGeneral: any;
  assemblyComponents: any;
  exportDate: string;
  time?: number;
  pageNumber: number;
  totalPageNumber: number;
}

const SchemaPFD = ({
  assemblyGeneral,
  assemblyComponents,
  exportDate,
  time,
  pageNumber,
  totalPageNumber
}: Props) => {
  let components = assemblyComponents
    .filter((c) => c.type === 'function')
    .sort((c1, c2) => c1.viewer2D.x - c2.viewer2D.x);

  const intl = useIntl();

  const componentTypes = components.map((c) => c.data.type);
  const uniqueTypes = new Set([...componentTypes]);

  let fcts = [];
  uniqueTypes.forEach((type) => {
    fcts.push(CUSTOM_FUNCTIONS[type]);
  });

  const sortedFunctions = orderBy(fcts, ['name'], ['asc']);

  return (
    <div
      className="pb-3 pt-1 px-4 f-col h-100 w-100 relative"
      style={{
        breakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always',
        pageBreakAfter: totalPageNumber === pageNumber ? 'avoid' : 'always'
      }}>
      <div className="border-bottom py-2">
        <div className="f-row f1-between f2-center">
          <div>
            <span>{intl.formatMessage(configMessages.PFD)}</span>
            <span className="px-2">&#124;</span>
            <span className="font-regular">{assemblyGeneral.name}</span>
          </div>
          <div>{exportDate}</div>
        </div>
      </div>
      <div className="w-100 f-full f-center mt-2" style={{ maxHeight: 600 }}>
        <img
          alt="PFD view"
          className="w-100"
          style={{
            objectFit: 'contain',
            height: 600
          }}
          src={
            config.BlobURL +
            '/single-use-configurator/assemblies/thumbnail/' +
            assemblyGeneral.id +
            '_UO_Annotated_PFD' +
            '.png?cache=' +
            assemblyGeneral.modifiedOn
          }
        />
      </div>
      <div className="h-100 col-count-5 px-2" style={{ height: 90 }}>
        {sortedFunctions.map((fct) => {
          return (
            <div key={fct?.name} className="h-100 f-row f2-center gap-2" style={{ height: 30 }}>
              <div>{fct?.form(20)}</div>
              <div className="font-regular ">{fct?.name}</div>
            </div>
          );
        })}
      </div>
      <div className="f-row f1-end gap-2 mt-2" style={{ fontSize: 13 }}>
        {/* {time > 0 && (
          <div className="dark-orange f-row f2-center gap-1">
            <CustomIcon name="alert-2" />
            Continued from previous page
          </div>
        )} */}
        <div>{`Page ${pageNumber}/${totalPageNumber}`}</div>
      </div>
    </div>
  );
};

export default SchemaPFD;
