import { ErrorDetail } from './RuleComponentEdgeComponent';

export abstract class RuleEdgeComponentOnEdge {
  public schemaComponentOnEdgeList: Array<string>;
  public schemaTubeList: Array<string>;
  public editorList: Array<string>;

  protected componentOnEdgeList: any;
  protected componentSource: any;
  protected componentSourceAnchor: any;
  protected componentTarget: any;
  protected componentTargetAnchor: any;

  protected edge: any;
  protected componentList: Array<any>;

  protected errorList = [] as Array<ErrorDetail>;

  public constructor(edge: any, components: Array<any>) {
    this.getSourceTarget(edge, components);
  }

  private getSourceTarget(edge: any, components: Array<any>) {
    this.edge = edge;
    this.componentList = components;

    this.componentOnEdgeList = this.componentList.filter(
      (component: any) => component.type === 'genericonedge' &&
        component.viewer2D.source === this.edge.id
    );

    this.componentSource = this.componentList.find(component => component.id === edge.source);
    this.componentSourceAnchor = this.componentSource.data.anchors.find(anchor => anchor.id === edge.sourceHandle);
    this.componentTarget = this.componentList.find(component => component.id === edge.target);
    this.componentTargetAnchor = this.componentTarget.data.anchors.find(anchor => anchor.id === edge.targetHandle);
  };

  abstract checkPrerequisite: () => boolean;

  abstract checkRule: () => void;

  public handle(){
    const isRuleValid = this.checkPrerequisite();
    if(isRuleValid){
      this.checkRule();
    }
    return this.errorList;
  }
}