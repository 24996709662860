import {
  Autocomplete,
  Chip,
  Icon,
  Modal,
  SmallButton,
  SplashScreen,
  Switch,
  TextArea,
  Title,
} from "hakobio-react-ui";
import { orderBy, cloneDeep, isEqual, uniqWith } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useStoreActions } from "react-flow-renderer";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { DirectoryType } from "../app/web-api-client";
import { buttonSaveStyle } from "../components/EditorPanel/style/styles";
import { DirectoryFolder } from "../components/models/DirectoryFolder";
import { EditorMode } from "../constants/EditorMode";
import { ListTab } from "../constants/ListTab";
import { versioningMessages } from "../lang/messages";
import {
  getAllDirectories,
  getUserIsAdmin,
  saveAssembly,
  saveCustomComponent,
  saveDirectoryFolder,
} from "../services/editor/editorService";
import {
  convertForSave,
  convertForSaveComponent,
  convertToRead,
} from "../services/editor/editorServiceUtilities";
import { getTags } from "../services/editor/editorTagServiceUtilities";
import { useAppDispatch, useAppSelector } from "../store";
import { assemblyEditorSliceActions } from "../store/features/assemblyEditor/assemblyEditorSlice";
import { useAssemblyEditorAction } from "../store/features/assemblyEditor/useAssemblyEditorAction";
import { useEditorLibraryAction } from "../store/features/assemblyEditor/useEditorLibraryAction";
import { getColors, Uuidv4 } from "../utilities";

interface Props {
  closeModal: Function;
  closeEditor: Function;
  isExitingOnSave: boolean;
  saveButtonsDisabled: boolean;
}

const SaveModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { onGeneralEdited, setAssembly } = useAssemblyEditorAction();
  const { getEditorCustomComponentLibrary } = useEditorLibraryAction();
  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const components = useAppSelector(
    (state) => state.assemblyEditorSlice.components,
  );
  const editorMode = useAppSelector(
    (state) => state.assemblyEditorSlice.editorMode,
  );
  const layerMode = useAppSelector(
    (state) => state.assemblyEditorSlice.layerMode,
  );
  const customComponentEdition = useAppSelector(
    (state) => state.assemblyEditorSlice.customComponentEdition,
  );
  const [_directories, setDirectories] = useState<DirectoryFolder[] | null>(
    null,
  );

  const [_versionMessage, setVersionMessage] = useState(general.versionMessage);
  const [_saveStep, setSaveStep] = useState(null);
  const [_isNewVersion, setIsNewVersion] = useState(false);
  const [_tabFolderList, setTabFolderList] = useState([]);
  const [_directory, setDirectory] = useState(null);
  const [_isAdmin, setIsAdmin] = useState(false);
  const user = useAppSelector((state) => state.assemblyEditorSlice.user);
  const intl = useIntl();

  const _savingToast = useRef(null);

  const isSavingAssembly = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingAssembly,
  );
  const isSavingScreenshotSUA = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUA,
  );
  const isSavingScreenshotSUARef = useRef(isSavingScreenshotSUA);
  const isSavingScreenshotComponent = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUA,
  );
  const isSavingScreenshotComponentRef = useRef(isSavingScreenshotComponent);
  const isSavingScreenshotUO = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotUO,
  );
  const isSavingScreenshotUORef = useRef(isSavingScreenshotUO);
  const isSavingScreenshotSUAAnnotated = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotSUAAnnotated,
  );
  const isSavingScreenshotSUAAnnotatedRef = useRef(
    isSavingScreenshotSUAAnnotated,
  );
  const isSavingScreenshotUOAnnotatedPFD = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotUOAnnotatedPFD,
  );
  const isSavingScreenshotUOAnnotatedPFDRef = useRef(
    isSavingScreenshotUOAnnotatedPFD,
  );
  const isSavingScreenshotUOAnnotatedPID = useAppSelector(
    (state) => state.assemblyEditorSlice.isSavingScreenshotUOAnnotatedPID,
  );
  const isSavingScreenshotUOAnnotatedPIDRef = useRef(
    isSavingScreenshotUOAnnotatedPID,
  );

  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements,
  );
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);

  useEffect(() => {
    isSavingScreenshotSUARef.current = isSavingScreenshotSUA;
    isSavingScreenshotComponentRef.current = isSavingScreenshotComponent;
    isSavingScreenshotSUAAnnotatedRef.current = isSavingScreenshotSUAAnnotated;
  }, [
    isSavingScreenshotSUA,
    isSavingScreenshotSUAAnnotated,
    isSavingScreenshotComponentRef,
  ]);

  useEffect(() => {
    isSavingScreenshotUORef.current = isSavingScreenshotUO;
    isSavingScreenshotUOAnnotatedPFDRef.current =
      isSavingScreenshotUOAnnotatedPFD;
    isSavingScreenshotUOAnnotatedPIDRef.current =
      isSavingScreenshotUOAnnotatedPID;
  }, [
    isSavingScreenshotUO,
    isSavingScreenshotUOAnnotatedPFD,
    isSavingScreenshotUOAnnotatedPID,
  ]);

  useEffect(() => {
    setSelectedElements([]);
    // @ts-ignore
    if (
      editorMode === EditorMode.SUA ||
      editorMode === EditorMode.Reference ||
      editorMode === EditorMode.UnitOperation /* &&
      !general.newAssembly */
    ) {
      //Nothing to do
      //In "render()", show the modal in this case
      return null;
    } else {
      //Save is done by default only when saving UO or "new" assembly
      //In "render()", do not show the modal in this case
      onSave(false);
    }
  }, []);

  useEffect(() => {
    const getRole = async () => {
      const role = await getUserIsAdmin();
      setIsAdmin(role);
    };
    getRole();
  }, []);

  useEffect(() => {
    const allDirectories = async () => {
      const directories = await getAllDirectories();
      setDirectories(directories);
      let currentDirectory = directories.find((directory: DirectoryFolder) =>
        directory.children?.includes(general.id),
      );
      setDirectory(currentDirectory);
    };
    void allDirectories();
  }, []);

  useEffect(() => {
    if (_directories === null) return;
    const tabFolderList =
      listTab === ListTab.SUA || editorMode === EditorMode.Reference
        ? _directories.filter(
            (f: DirectoryFolder) => f.directoryType === DirectoryType.SingleUseAssembly,
          )
        : _directories.filter(
            (f: DirectoryFolder) =>
              f.directoryType === DirectoryType.UnitOperation,
          );
    setTabFolderList(tabFolderList);
  }, [listTab, _directories, editorMode]);

  const onCloseModal = () => {
    props.closeModal(false);
  };

  const setLegend = () => {
    let componentsCopy = cloneDeep(components);
    let tubesComponentsLinkedIds = [];
    for (let index = 0; index < componentsCopy.length; index++) {
      const element = componentsCopy[index];
      if (element.data.idDrag) {
        tubesComponentsLinkedIds.push(element.data.idDrag.edge);
      }
    }

    componentsCopy = componentsCopy.filter(
      (component: any) => !tubesComponentsLinkedIds.includes(component.id),
    );

    componentsCopy.sort((c1, c2) => {
      let c1x;
      let c2x;
      if (c1.type === "generic" || c1.type === "genericonedge") {
        c1x = c1.viewer2D.x;
      } else {
        const c1Src = components.find((c) => c.id === c1.source);
        const c1Trg = components.find((c) => c.id === c1.target);
        c1x =
          c1Src.viewer2D.x < c1Trg.viewer2D.x
            ? c1Src.viewer2D.x
            : c1Trg.viewer2D.x;
      }
      if (c2.type === "generic" || c2.type === "genericonedge") {
        c2x = c2.viewer2D.x;
      } else {
        const c2Src = components.find((c) => c.id === c2.source);
        const c2Trg = components.find((c) => c.id === c2.target);
        c2x =
          c2Src.viewer2D.x < c2Trg.viewer2D.x
            ? c2Src.viewer2D.x
            : c2Trg.viewer2D.x;
      }
      if (c1x > c2x) {
        return 1;
      }
      if (c1x < c2x) {
        return -1;
      }
      if (c1.type === c2.type) {
        return 0;
      }
      return c1.type > c2.type ? 1 : c1.type < c2.type ? -1 : 0;
    });

    const newComponentsCopy = cloneDeep(componentsCopy);
    const componentsData = newComponentsCopy.map((component) => {
      delete component.data.customName;
      if (component.data.type === "plug") {
        component.data.anchors = component.data.anchors.map((anchor) => {
          return anchor.data || null;
        });
      } else if (component.data.type !== "tubing") {
        if (component?.data?.anchors) {
          component.data.anchors = component.data.anchors.map((anchor) => {
            if (anchor?.data !== null && anchor?.data !== undefined) {
              return anchor.data;
            }
          });
        }
        if (component?.data?.instrumentationPorts) {
          component.data.instrumentationPorts =
            component.data.instrumentationPorts.map((anchor) => {
              return anchor.data;
            });
        }
        if (component?.data?.samplingPorts) {
          component.data.samplingPorts = component.data.samplingPorts.map(
            (anchor) => {
              return anchor.data;
            },
          );
        }
      } else {
        delete component.data.anchors;
        delete component.data.instrumentationPorts;
        delete component.data.samplingPorts;
      }
      const newComponent = { ...component.data, ...{ id: component.id } };
      return newComponent;
    });

    const customizer = (objValue, othValue) => {
      const newObjValue = { ...objValue, ...{ id: "" } };
      const newOthValue = { ...othValue, ...{ id: "" } };
      if (isEqual(newObjValue, newOthValue)) {
        return true;
      } else {
        return false;
      }
    };

    const groups = cloneDeep(uniqWith(componentsData, customizer));

    const getComponentsId = (componentsData, value) => {
      let ids = [];
      const idLessValue = { ...value, ...{ id: "" } };
      componentsData.forEach((c) => {
        const compId = c.id;
        const idlessComponent = { ...c, ...{ id: "" } };
        if (isEqual(idlessComponent, idLessValue)) {
          ids.push(compId);
        }
      });
      return ids;
    };

    groups.forEach((g) => {
      g.ids = getComponentsId(componentsData, g);
    });

    const sortedList = orderBy(groups, ["type"], ["asc"]);
    onGeneralEdited("legend", sortedList);
  };

  const stepPrepareLegend = () => {
    if (editorMode === EditorMode.SUA || editorMode === EditorMode.Reference) {
      setLegend();
    } else if (
      editorMode === EditorMode.UnitOperation ||
      editorMode === EditorMode.Component
    ) {
      //Nothing to do
    }
    setSaveStep({ step: 2, isNewVersion: _saveStep.isNewVersion });
  };

  const stepTakeScreenshot = () => {
    const frames = components.filter(
      (c) => c.type === "selection" || c.type === "update",
    );
    if (frames.length)
      dispatch(assemblyEditorSliceActions.removeComponents(frames));

    const waitSavingScreenshotAsync = () => {
      setTimeout(() => {
        switch (editorMode) {
          case EditorMode.Component:
            if (isSavingScreenshotComponentRef.current) {
              waitSavingScreenshotAsync();
            } else {
              //setSaveStep({ step: 3, isNewVersion: _saveStep.isNewVersion });
              dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
              props.isExitingOnSave ? props.closeEditor() : props.closeModal();
            }
            break;
          case EditorMode.SUA:
            if (
              isSavingScreenshotSUARef.current ||
              isSavingScreenshotSUAAnnotatedRef.current
            ) {
              waitSavingScreenshotAsync();
            } else {
              //setSaveStep({ step: 3, isNewVersion: _saveStep.isNewVersion });
              dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
              props.isExitingOnSave ? props.closeEditor() : props.closeModal();
            }
            break;
          case EditorMode.Reference:
            if (
              isSavingScreenshotSUARef.current ||
              isSavingScreenshotSUAAnnotatedRef.current
            ) {
              waitSavingScreenshotAsync();
            } else {
              dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
              props.isExitingOnSave ? props.closeEditor() : props.closeModal();
            }
            break;
          case EditorMode.UnitOperation:
            if (
              isSavingScreenshotUORef.current ||
              isSavingScreenshotUOAnnotatedPFDRef.current ||
              isSavingScreenshotUOAnnotatedPIDRef.current
            ) {
              waitSavingScreenshotAsync();
            } else {
              //setSaveStep({ step: 3, isNewVersion: _saveStep.isNewVersion });
              dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
              dispatch(assemblyEditorSliceActions.setSUAFramesDisplayed(false));
              props.isExitingOnSave ? props.closeEditor() : props.closeModal();
            }
            break;
          default:
            throw "ExitModal - EditorMode - Unknown";
        }
      }, 1000);
    };

    switch (editorMode) {
      case EditorMode.Component:
        if (customComponentEdition.data.type !== "tubing")
          dispatch(
            assemblyEditorSliceActions.setSavingScreenshotComponent(true),
          );
        break;
      case EditorMode.SUA:
        dispatch(assemblyEditorSliceActions.setSavingScreenshotSUA(true));
        break;
      case EditorMode.Reference:
        dispatch(assemblyEditorSliceActions.setSavingScreenshotSUA(true));
        break;
      case EditorMode.UnitOperation:
        dispatch(assemblyEditorSliceActions.setSavingScreenshotUO(true));
        dispatch(assemblyEditorSliceActions.setPreShotLayerMode(layerMode));
        break;
      default:
        break;
    }
    waitSavingScreenshotAsync();
  };

  const stepSaveAssembly = async () => {
    const showSavingConfirmationAsync = () => {
      setTimeout(() => {
        switch (editorMode) {
          case EditorMode.Component:
            if (isSavingScreenshotComponentRef.current) {
              showSavingConfirmationAsync();
            } else {
              toast.dismiss(_savingToast.current);
              toast.success("Component saved");
              setSaveStep({ step: 3, isNewVersion: _saveStep.isNewVersion });
              //dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
              //props.isExitingOnSave ? props.closeEditor() : props.closeModal();
            }
            break;
          case EditorMode.SUA:
            if (
              isSavingScreenshotSUARef.current ||
              isSavingScreenshotSUAAnnotatedRef.current
            ) {
              showSavingConfirmationAsync();
            } else {
              toast.dismiss(_savingToast.current);
              toast.success("Assembly saved");
              setSaveStep({ step: 3, isNewVersion: _saveStep.isNewVersion });
              //dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
              //props.isExitingOnSave ? props.closeEditor() : props.closeModal();
            }
            break;
          case EditorMode.Reference:
            if (
              isSavingScreenshotSUARef.current ||
              isSavingScreenshotSUAAnnotatedRef.current
            ) {
              showSavingConfirmationAsync();
            } else {
              toast.dismiss(_savingToast.current);
              toast.success("Reference Assembly saved");
              setSaveStep({ step: 3, isNewVersion: _saveStep.isNewVersion });
            }
            break;
          case EditorMode.UnitOperation:
            if (
              isSavingScreenshotUORef.current ||
              isSavingScreenshotUOAnnotatedPFDRef.current ||
              isSavingScreenshotUOAnnotatedPIDRef.current
            ) {
              showSavingConfirmationAsync();
            } else {
              toast.dismiss(_savingToast.current);
              toast.success("Unit Operation saved");
              setSaveStep({ step: 3, isNewVersion: _saveStep.isNewVersion });
              //dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
              //dispatch(assemblyEditorSliceActions.setSUAFramesDisplayed(false));
              //props.isExitingOnSave ? props.closeEditor() : props.closeModal();
            }
            break;
          default:
            throw "ExitModal - EditorMode - Unknown";
        }
      }, 1000);
    };

    const tmpAssembly = cloneDeep({
      general,
      components: components.filter((c) => c.type !== "assembly"),
    });

    if (tmpAssembly.general.type === "SingleUseAssembly") {
      const tags = getTags(tmpAssembly);
      tmpAssembly.general.tags = tags;
    }
    if (_saveStep.isNewVersion) {
      tmpAssembly.general.version = tmpAssembly.general.version + 1;
    }
    tmpAssembly.general.versionMessage = _versionMessage;
    tmpAssembly.general.parentFolderId = _directory.id;

    const assembly = convertForSave(
      tmpAssembly.general,
      tmpAssembly.components,
    );
    try {
      _savingToast.current = toast.warning("Saving", { autoClose: false });
      const newAssembly = await saveAssembly(assembly);
      if (_directories.find((folder) => folder.id === _directory.id)) {
        const updatedSelectedFolder = {
          ..._directory,
          children: [..._directory.children, newAssembly.id],
        };
        dispatch(
          assemblyEditorSliceActions.selectFolder(updatedSelectedFolder),
        );
      }
      const assemblyToRead = convertToRead(newAssembly);
      //@ts-ignore
      assemblyToRead.general.legend = tmpAssembly.general.legend;
      setAssembly(assemblyToRead.general, assemblyToRead.components);
      showSavingConfirmationAsync();
    } catch {
      switch (editorMode) {
        case EditorMode.SUA:
          toast.dismiss(_savingToast.current);
          toast.error("Assembly not saved");
          break;
        case EditorMode.Reference:
          toast.dismiss(_savingToast.current);
          toast.error("Assembly reference not saved");
          break;
        case EditorMode.UnitOperation:
          toast.dismiss(_savingToast.current);
          toast.error("Unit Operation not saved");
          break;
      }
      dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
      props.closeModal();
    }
    //setSaveStep(null);
  };

  const saveAsTemplate = async () => {
    dispatch(assemblyEditorSliceActions.setSavingAssembly(true));
    const convertedComponent = convertForSaveComponent(customComponentEdition);
    /*  convertedComponent.id = uuidv4(); */
    try {
      await saveCustomComponent(convertedComponent);
      toast.success("Component saved");
      getEditorCustomComponentLibrary();
    } catch {
      toast.error("Component not saved");
    }
    //dispatch(assemblyEditorSliceActions.setSavingAssembly(false));
    //props.closeModal();
    setSaveStep({ step: 3, isNewVersion: _saveStep.isNewVersion });
  };

  useEffect(() => {
    switch (_saveStep?.step) {
      case 1:
        stepPrepareLegend();
        break;
      case 2:
        if (editorMode === EditorMode.Component) {
          saveAsTemplate();
        } else {
          stepSaveAssembly();
        }
        break;
      case 3:
        stepTakeScreenshot();
        break;
    }
  }, [_saveStep]);

  const onSave = async (isNewVersion: boolean) => {
    if (_directory !== null) {
      let cloneGeneral = cloneDeep(general);
      cloneGeneral.parentFolderId = _directory.id;
      setAssembly(cloneGeneral, components);
      if (_directory.isAddNew) {
        const userId = user?.id?.toString();
        let userListOwners = [userId];
        let result = await saveDirectoryFolder(
          userListOwners,
          userListOwners,
          _directory,
        );
      }
    }
    dispatch(assemblyEditorSliceActions.setSavingAssembly(true));
    setSaveStep({ step: 1, isNewVersion });
  };

  const renderFooter = () => {
    if (_saveStep) {
      return null;
    }

    return (
      <div
        className={`f-row f1-between f2-center ${!_directory ? "w-100" : ""}`}
      >
        {!_directory && (
          <div
            className="f-row gap-2 anthracite f2-center"
            style={{ fontSize: 13 }}
          >
            <Icon name="logout" />
            {intl.formatMessage({
              id: "SaveModal.Footer.ChooseDirectory",
              defaultMessage: "Choose a directory to save",
            })}
          </div>
        )}
        <SmallButton
          disabled={!_directory}
          width={"150px"}
          color={"var(--primaryColor)"}
          style={buttonSaveStyle}
          onClick={() => {
            _isNewVersion ? onSave(true) : onSave(false);
          }}
          loading={isSavingAssembly}
        >
          {_isNewVersion ? (
            <FormattedMessage
              id="SaveModal.Footer.NewVersion"
              defaultMessage="Create new version"
            />
          ) : (
            <FormattedMessage
              id="SaveModal.Footer.CurrentVersion"
              defaultMessage="Save current"
            />
          )}
        </SmallButton>
      </div>
    );
  };

  const renderMessage = () => {
    let content = <div />;
    /*  if (_isMessageEdited) { */
    content = (
      <div className="w-100 mt-2">
        <TextArea
          placeholder={intl.formatMessage(versioningMessages.versionComment)}
          value={_versionMessage}
          rowsMax={2}
          onChange={(e) => setVersionMessage(e.target.value)}
        />
      </div>
    );
    /*  } else { */
    //content = <div style={{ width: '100%' }}>{_versionMessage}</div>;
    /* } */

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <FormattedMessage id="SaveModal.Body.Message.Title" defaultMessage="Version comment" /> */}
          {/* <div style={{ opacity: _isHoveringMessage ? 1 : 0.3, transition: '0.1s all' }}>
            <Icon name={'pencil'} onClick={() => setMessageEdited(!_isMessageEdited)} />
          </div> */}
        </div>
        <div className="border-bottom w-100" />
        {content}
      </div>
    );
  };

  const renderSavingLoading = () => {
    return (
      <div
        style={{
          width: "100%",
          height: 430 + 53,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SplashScreen text={"Saving"} color={"var(--primaryColor)"} />
      </div>
    );
  };

  const renderLoadingWithoutText = () => {
    return (
      <div
        style={{
          width: "100%",
          height: 430 + 53,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SplashScreen color={"var(--primaryColor)"} />
      </div>
    );
  };

  const renderBody = () => {
    if (_saveStep) {
      return renderSavingLoading();
    }

    if (_directories == null) {
      return renderLoadingWithoutText();
    }

    let currentParentFolder = _directories.find(
      (directory) => directory.id === general.parentFolderId,
    );
    let canMoveAssembly: boolean = true;
    if (currentParentFolder != null) {
      canMoveAssembly = currentParentFolder.permissions.assemblyMove;
    }

    console.log("general", general)

    console.log("_tabFolderList", _tabFolderList)

    return (
      <div className="w-100 f-col gap-2">
        <div className="f-col gap-2 f1-start f2-start f-full">
          <div
            className="f-row f2-center w-100 f1-between"
            style={{ marginTop: -16 }}
          >
            <Title underline="short">{general.name}</Title>
            {editorMode !== EditorMode.UnitOperation && (
              <Chip
                label={"Version" + " " + (general.version + 1)}
                color={getColors().primaryColor}
                backgroundColor={getColors().primaryColorAlpha}
              />
            )}
          </div>
          {editorMode !== EditorMode.UnitOperation && !general.newAssembly && (
            <div className="f-col w-100 gap-2">
              <div className="f-row f2-center f1-start gap-2">
                <Switch
                  firstColor={"var(--primaryColor)"}
                  onOff={_isNewVersion}
                  vertical={true}
                  size="medium"
                  disabled={false}
                  checked={_isNewVersion}
                  onChange={
                    editorMode === EditorMode.SUA ||
                    editorMode === EditorMode.Reference
                      ? () => setIsNewVersion(!_isNewVersion)
                      : () => {
                          setIsNewVersion(false);
                        }
                  }
                />
                <div className="f-col gap-2 ">
                  <div
                    style={{
                      minWidth: 80,
                      color: !_isNewVersion
                        ? "var(--primaryColor)"
                        : "var(--frozen-grey)",
                    }}
                  >
                    <FormattedMessage
                      id="SaveModal.Footer.CurrentVersion"
                      defaultMessage="Save current version"
                    />
                  </div>

                  <div className="f-row f2-center f1-start gap-2">
                    <div
                      style={{
                        minWidth: 80,
                        color: _isNewVersion
                          ? "var(--primaryColor)"
                          : "var(--frozen-grey)",
                      }}
                    >
                      <FormattedMessage
                        id="SaveModal.Footer.NewVersion"
                        defaultMessage="Create new version"
                      />
                    </div>
                    <Chip
                      label={"Version" + " " + (general.version + 2)}
                      color={
                        _isNewVersion
                          ? getColors().primaryColor
                          : "var(--frozen-grey)"
                      }
                      backgroundColor={
                        _isNewVersion
                          ? getColors().primaryColorAlpha
                          : "var(--light-grey)"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {canMoveAssembly && (
            <div
              className={`f-col w-100  f2-center gap-2 ${editorMode !== EditorMode.UnitOperation && !general.newAssembly ? "my-3" : "mb-3"}`}
            >
              <div className="f-row f2-center w-100 gap-2">
                <Icon name="folder" />
                <Autocomplete
                  className="w-100"
                  disabled={!canMoveAssembly}
                  freeSolo={_isAdmin}
                  filterOptions={(options, state) => {
                    // Filtrer les options basées sur les autorisations de l'utilisateur
                    let accessibleOptions : DirectoryFolder[] = [];
                    if(general.newAssembly) {
                      accessibleOptions = options.filter(
                        (option : DirectoryFolder) => option.permissions.assemblyCreate,
                      );
                    }else {
                      accessibleOptions = options.filter(
                        (option: DirectoryFolder) => option.permissions.assemblyMove,
                      );
                    }

                    const filtered = accessibleOptions.filter((option) =>
                      option.name
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()),
                    );

                    const isExisting = accessibleOptions.some(
                      (option) =>
                        state.inputValue.toLowerCase() ===
                        option.name.toLowerCase(),
                    );

                    if (state.inputValue !== "" && !isExisting && _isAdmin) {
                      filtered.push({
                        name: `Create "${state.inputValue}"`,
                        // @ts-ignore
                        isAddNew: true,
                      });
                    }
                    return filtered.sort((a, b) =>
                      a.name.localeCompare(b.name),
                    );
                  }}
                  variant="outlined"
                  placeholder={
                    _isAdmin
                      ? intl.formatMessage({
                          id: "Project.Button.SelectOrCreateFolder",
                          defaultMessage: "Select or create a folder to save",
                        })
                      : intl.formatMessage({
                          id: "Project.Button.SelectFolder",
                          defaultMessage: "Select a folder to save",
                        })
                  }
                  label={
                    _isAdmin
                      ? intl.formatMessage({
                          id: "Project.Button.SelectOrCreateFolder",
                          defaultMessage: "Select or create a folder to save",
                        })
                      : intl.formatMessage({
                          id: "Project.Button.SelectFolder",
                          defaultMessage: "Select a folder to save",
                        })
                  }
                  options={_tabFolderList}
                  getOptionLabel={(option: any) => option.name || ""}
                  value={_directory}
                  onChange={async (event, value, reason) => {
                    const userId = user?.id?.toString();
                    const organizationId = user?.organizationId?.toString();

                    if (value) {
                      const folderExists =
                        typeof value === "string"
                          ? false
                          : _directories.some(
                              (directory) => directory?.name === value.name,
                            );

                      if (typeof value === "string") {
                        setDirectory(null);
                      } else if (!folderExists && _isAdmin) {
                        const newName =
                          typeof value === "string"
                            ? value
                            : value?.name.includes("Create")
                              ? value.name
                                  .replace('Create "', "")
                                  .replace('"', "")
                              : value.name;

                        // @ts-ignore
                        const folderToSave: DirectoryFolder = {
                          id: Uuidv4(),
                          name: newName,
                          children: [],
                          shortCuts: [],
                          createdBy: userId,
                          directoryType:
                            listTab === ListTab.SUA ||
                            editorMode === EditorMode.Reference
                              ? DirectoryType.SingleUseAssembly
                              : DirectoryType.UnitOperation,
                          organizationId, // @ts-expect-error Temporary used value
                          isAddNew: true,
                          designOwners: [userId],
                          designEditors: [userId],
                          permissions: {
                            assemblyCreate: true,
                            assemblyDelete: true,
                            assemblyMove: true,
                            assemblyRead: true,
                            assemblyUpdate: true,
                            assemblyUpdateStatus: true,
                            delete: true,
                            read: true,
                            update: true,
                          },
                        };

                        let directoriesCopy = cloneDeep(_directories);
                        directoriesCopy.push(folderToSave);
                        setDirectories(directoriesCopy);
                        setDirectory(folderToSave);
                      } else {
                        // Empêcher la sélection si l'utilisateur n'est pas autorisé
                        if (
                          value &&
                          !value.isAddNew &&
                          !value.permissions.assemblyCreate
                        ) {
                          return;
                        }

                        if (!!value && !!value?.name) {
                          setDirectory(value);
                        }
                      }
                    }
                  }}
                  renderOption={(props, option) => {
                    // Si c'est l'option d'ajout, elle devrait toujours être sélectionnable
                    const isSelectable =
                      (_isAdmin && option.isAddNew) ||
                      option.permissions.assemblyCreate;

                    return (
                      <li
                        {...props}
                        key={`option-${option.id}`}
                        className={`f-row f2-center gap-2 px-2 py-1 li-autocomplete ${!isSelectable && !option.isAddNew ? "greyed-out-class" : ""}`}
                        style={{
                          backgroundColor: props.selected
                            ? "var(--grey)"
                            : "transparent",
                          color: isSelectable ? "inherit" : "#ccc",
                        }}
                      >
                        <Icon
                          name="folder"
                          title={isSelectable ? "Folder" : "Not Selectable"}
                          pointer={isSelectable}
                        />

                        <div className="max-lines-1">{option.name}</div>
                      </li>
                    );
                  }}
                />
              </div>
            </div>
          )}
          {editorMode !== EditorMode.UnitOperation && (
            <div style={{ width: "100%" }}>{renderMessage()}</div>
          )}
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    let type = null;
    if (editorMode === EditorMode.Component) {
      type = "Component";
    } else {
      switch (general.type) {
        case "SingleUseAssembly":
          type = "SUA";
          break;
        case "UnitOperation":
          type = "UO";
          break;
        case "SingleUseAssemblyReference":
          type = "Reference";
          break;
        default:
          type = "SUA";
          break;
      }
    }
    return (
      <div className="f-row f1-center f2-center gap-1">
        <Icon name={"save"} />
        <div style={{ width: 5 }} />
        {intl.formatMessage(
          {
            id: "SaveModal.Title",
            defaultMessage: "Save {entity}",
          },
          {
            entity: type,
          },
        )}
      </div>
    );
  };

  const render = () => {
    // @ts-ignore
    if (
      editorMode === EditorMode.SUA ||
      editorMode === EditorMode.Reference ||
      editorMode === EditorMode.UnitOperation
    ) {
      return (
        <Modal
          height={"auto"}
          size={"modal-lg"}
          title={renderTitle()}
          cancel={onCloseModal}
          footer={renderFooter()}
        >
          {renderBody()}
        </Modal>
      );
    } else {
      return (
        <Modal size={"modal-lg"} title={renderTitle()}>
          {renderSavingLoading()}
        </Modal>
      );
    }
  };

  return render();
};

export default SaveModal;
