import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { assemblyEditorSliceActions } from '../../store/features/assemblyEditor/assemblyEditorSlice';
import SuaCard from './Cards/SuaCard';
import UoCard from './Cards/UoCard';
import { useCustomBehavior } from '../../utils';

export const ItemTypes = { DRAGGING_BADGE: 'DRAGGING_BADGE' };

const Assembly = ({ assembly, ...props }: any) => {
  const dispatch = useAppDispatch();

  const [_isHover, setIsHover] = useState<boolean>(false);
  const history = useHistory();
  const { isDragging } = props;

  const { openReactAppHistoryInNewTab } = useCustomBehavior();

  const [{ opacity }, dragRef] = useDrag(() => ({
    type: ItemTypes.DRAGGING_BADGE, //@ts-ignore
    item: {
      isDragging, ...assembly
    }, collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1
    })
  }), []);

  const openAssembly = () => {
    dispatch(assemblyEditorSliceActions.setCustomComponentViewOnly(false));
    let path = '';
    switch (assembly.type) {
      case 'SingleUseAssembly':
        path = '/editor/SingleUseAssembly/' + assembly?.id;
        break;
      case 'SingleUseAssemblyReference':
        path = '/editor/Reference/' + assembly?.id;
        break;
      case 'UnitOperation':
        path = '/editor/UnitOperation/' + assembly?.id;
        break;
    }
    history.push(path);
  };

  const openAssemblyVersion = () => {
    let assemblyCopy = cloneDeep(assembly);
    assemblyCopy.hashPartitionKey = assembly.id + '-' + assembly.version;
    localStorage.setItem('action', 'assemblyVersion');
    localStorage.setItem('actionData', JSON.stringify(assemblyCopy));
    openReactAppHistoryInNewTab();
  };

  return (<div
    className='px-3 py-4 noselect '
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}>
    <div ref={assembly.permissions.move ? dragRef : null} style={{ opacity }}>
      <div
        className='relative'
        style={{
          filter: _isHover ? 'drop-shadow(2px 2px 10px var(--dark-grey))' : 'none',
          zIndex: _isHover ? 1000 : 'none',
          transform: _isHover ? 'translate(-4px, -8px)' : 'none',
          transition: _isHover ? 'all 0.2s ease-in-out' : 'none'
        }}>
        {(assembly?.type === 'SingleUseAssemblyReference' || assembly?.type === 'SingleUseAssembly') && (
          <SuaCard assembly={assembly} openAssembly={openAssembly} isHover={_isHover} />)}
        {assembly?.type === 'UnitOperation' && (
          <UoCard assembly={assembly} openAssembly={openAssembly} isHover={_isHover} />)}
      </div>
    </div>
  </div>);
};

export default Assembly;
