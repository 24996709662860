import { Icon, ThinChip } from 'hakobio-react-ui';
import { FC } from 'react';

interface ItemChangeProps {
  name: string;
  viewingValue: string;
  comparingValue: string;
}

const ItemChange: FC<ItemChangeProps> = ({ name, viewingValue, comparingValue }) => {
  return (
    <>
      <span className="gap-2 pl-3 py-1 capitalize">{name}</span>
      <div className="f-row f2-center gap-2 pl-3 py-1">
        <ThinChip
          style={{ minWidth: 52 }}
          color={'var(--primaryColor)'}
          backgroundColor={'var(--primaryColorBcg)'}
          label={viewingValue || 'N/C'}
        />
        <Icon name={'transfer'} />
        <ThinChip style={{ minWidth: 52 }} label={comparingValue || 'N/C'} />
      </div>
    </>
  );
};

export default ItemChange;
