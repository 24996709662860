import { cloneDeep } from 'lodash';
import { isNode } from 'react-flow-renderer';
import { angle } from '../../../utilities';
import { ViewMode } from '../../../constants/ViewMode';

export const getElementsPID = (components, viewMode) => {
  const elementList = [];

  components.forEach((c) => {
    if (c.type === 'function' && c.data.ghost) {
      return;
    }
    if((c.type === 'generic' || c.type === 'genericedge' || c.type === 'genericonedge') && c.data.assemblyReference){
      return;
    }
    if (isNode(c)) {
      if (
        c.type === 'genericonedge' ||
       /*  c.data.type === 'sensor' || */
        c.data.type === 'mechanicDisconnector' ||
        c.data.type === 'assembly' ||
        c.data.type === 'update'
      ) {
        // @ts-ignore
        const edgeRef = document.getElementById(c.viewer2D.source);
        if (edgeRef !== undefined && edgeRef !== null) {
          // @ts-ignore
          const edgeLength = edgeRef.getTotalLength();
          // @ts-ignore
          let edgeLoc = edgeRef.getPointAtLength(
            // @ts-ignore
            c.viewer2D.position * edgeLength
          );
          // @ts-ignore
          let edgeLoc2 = edgeRef.getPointAtLength(
            // @ts-ignore
            (c.viewer2D.position + 0.02) * edgeLength
          );
          let rotation = angle(edgeLoc.x, edgeLoc.y, edgeLoc2.x, edgeLoc2.y);
          let node = cloneDeep(c) as any;
          // @ts-ignore
          node.position = { x: edgeLoc.x - 15, y: edgeLoc.y - 15 };
          node.viewer2D.rotate = rotation;
          node.selectable = !node.data.ghost;
          node.draggable = viewMode===ViewMode.HistoryUO ? false : true;
          elementList.push(node);
          return;
        } else {
          let node = cloneDeep(c) as any;
          node.position = { x: node.viewer2D.pid.x, y: node.viewer2D.pid.y };
          node.selectable = !node.data.ghost;
          node.draggable = viewMode===ViewMode.HistoryUO ? false : true;
          elementList.push(node);
          return;
        }
      } else {
        let node = cloneDeep(c) as any;
        node.position = { x: node.viewer2D.pid.x, y: node.viewer2D.pid.y };
        node.selectable = !node.data.ghost;
        node.draggable = viewMode===ViewMode.HistoryUO ? false : !node.data.ghost;
        node.isHidden = !!(c.type === 'function' && c.data.assembly);
        elementList.push(node);
        return;
      }
    } else {
      let edge = cloneDeep(c) as any;
      edge.animated = false
      edge.style = {};
      const target = components.find((co: any) => co.id === c.target);
      const source = components.find((co: any) => co.id === c.source);
      if (source && source.type === 'function' && !source.data.assembly) {
        //Nothing to do
      } else if (source && source.type === 'function' && source.data.assembly && source.data.ghost) {
        let ghostEdge;
        if (edge.sourceGhost) {
          ghostEdge = components.find((e: any) => {
            return (
              e.type === 'functionedge' &&
              e.data.ghost &&
              ((e.source === edge.sourceGhost && e.sourceHandle === edge.sourceHandle) ||
                (e.target === edge.sourceGhost && e.targetHandle === edge.sourceHandle))
            );
          });
        }
        edge.source = edge.sourceGhost;
        if (!edge.animated && !ghostEdge) {
          edge.animated = true;
          edge.style = { stroke: '#f6ab6c' };
        }
      } else if (source && source.type !== 'function') {
        //Nothing to do
      } else if (source && source.type === 'function' && source.data.assembly && !source.data.ghost) {
        let anchorSource;
        anchorSource = source.data.anchors.find((a: any) => a.id === c.sourceHandle);
        edge.source = anchorSource.data.componentLink?.component;
        edge.sourceHandle = anchorSource.data.componentLink?.anchor;
      }
      if (target && target.type === 'function' && !target.data.assembly) {
        //Nothing to do
      } else if (target && target.type === 'function' && target.data.assembly && target.data.ghost) {
        let ghostEdge;
        if (edge.targetGhost) {
          ghostEdge = components.find((e: any) => {
            return (
              e.type === 'functionedge' &&
              e.data.ghost &&
              ((e.source === edge.targetGhost && e.sourceHandle === edge.targetHandle) ||
                (e.target === edge.targetGhost && e.targetHandle === edge.targetHandle))
            );
          });
        }
        edge.target = edge.targetGhost;
        if (!edge.animated && !ghostEdge) {
          edge.animated = true;
          edge.style = { stroke: '#f6ab6c' };
        }
      } else if (target && target.type !== 'function') {
        //Nothing to do
      } else if (target && target.type === 'function' && target.data.assembly && !target.data.ghost) {
        let anchorTarget;
        anchorTarget = target.data.anchors.find((a: any) => a.id === c.targetHandle);
        edge.target = anchorTarget.data.componentLink?.component;
        edge.targetHandle = anchorTarget.data.componentLink?.anchor;
      }
      elementList.push(edge);
      return;
    }
  });
  return elementList;
};
