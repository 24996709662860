import { TextInput, PanelHeader, Icon } from "hakobio-react-ui";
import { NodeList } from "../../Shared/EquipmentPanel/NodeList";
import { useIntl } from "react-intl";
import { actionMessages } from "../../../../lang/messages";
import { useState } from "react";
import { useAppSelector } from "../../../../store";
import SingleUseCustomComponentLibrary from "./CustomComponent/SingleUseCustomComponentLibrary";
import { configMessages } from "./../../../../lang/messages";

const SingleUseEquipmentPanel = () => {
  const libComponentName = useAppSelector(
    (state) => state.assemblyEditorSlice.libComponentName,
  );

  const [_searchedValue, setSearchedValue] = useState("");
  const intl = useIntl();

  const renderSearch = () => {
    return (
      <div className="mx-2 my-1">
        <TextInput
          inputSize={"small"}
          value={_searchedValue}
          onChange={(e: any) => setSearchedValue(e.target.value)}
          placeholder={intl.formatMessage(actionMessages.search)}
          endAdornment={<Icon name="search" />}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (libComponentName) {
      return (
        <SingleUseCustomComponentLibrary
          resetSearch={() => setSearchedValue("")}
          searchedValue={_searchedValue}
        />
      );
    } else {
      return (
        <NodeList
          resetSearch={() => setSearchedValue("")}
          searchedValue={_searchedValue}
        />
      );
    }
  };

  return (
    <>
      <PanelHeader>{intl.formatMessage(configMessages.components)}</PanelHeader>
      {renderSearch()}
      {renderContent()}
    </>
  );
};

export default SingleUseEquipmentPanel;
