export const UNIT_OPERATION_STATUS_EXPLANATION = {
  draft: {
    name: 'Draft',
    explanations: [
      "Only UOs with 'draft' status can be deleted",
      /* "Status can only be changed by Design Owner and UO's Creator", */
      "Design Owners and Design Editors can edit"
    ]
  },
  effective: {
    name: 'Effective',
    explanations: [
      /* "Editable by Design Owner and UO's Creator only", */
      "UO cannot be edited anymore"/* ,
      "Status can only be changed by Design Owner and UO's Creator" */
    ]
  },
  obsolete: {
    name: 'Obsolete',
    explanations: [
      'Viewing and duplicating only',
      'Once the status is obsolete, it can only be changed by Design Owners'
    ]
  }
};
