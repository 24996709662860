import { Button, Icon, Modal, SmallButton, TextInput, Title, Tooltip } from 'hakobio-react-ui';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { buttonSaveStyle } from '../../style/styles';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { assemblyEditorSliceActions } from '../../../../store/features/assemblyEditor/assemblyEditorSlice';

import { useAssemblyEditorAction } from '../../../../store/features/assemblyEditor/useAssemblyEditorAction';
import DeleteModal from '../../../../views/DeleteModal';
import { cloneDeep } from 'lodash';

import GeneralInformationEdition from './GeneralInformationEdition';
import { actionMessages, generalMessages } from '../../../../lang/messages';
import { Media } from '../../../../utilities/Media';
import PrivacyModalContent from '../LeftPanel/PrivacyModalContent';
import Uuidv4 from '../../../Shared/Uuidv4';
import { Assembly } from '../../../../app/web-api-client';
import { useSharingAction } from '../../../../store/features/assemblyEditor/useSharingAction';
import { Shareable } from '../../../../utilities/Shareable';
import { useStatusAction } from '../../../../store/features/assemblyEditor/useStatusAction';
import { validateAssemblyName } from '../../../../services/editor/editorService';
import { toast } from 'react-toastify';

const ReferenceEditionModal = () => {
  const dispatch = useAppDispatch();
  const { onGeneralEdited } = useAssemblyEditorAction();
  const { setPrivacy } = useSharingAction();
  const { changeStatus } = useStatusAction();

  const general = useAppSelector((state) => state.assemblyEditorSlice.general);
  const showDeleteConfirmModal = useAppSelector(
    (state) => state.assemblyEditorSlice.showConfirmDeleteModal
  );

  const allHeadersLibrary = useAppSelector((state) => state.assemblyEditorSlice.allHeadersLibrary);

  const assembly = allHeadersLibrary.find((assembly: Assembly) => assembly.id === general.id);

  const [_suaName, setSuaName] = useState(general.name);
  const [_suaDescription, setSuaDescription] = useState(general.description);
  const [_supplier, setSupplier] = useState(general.supplier);
  const [_status, setStatus] = useState(general.status);
  const [_highLightFirst, setHighLightFirst] = useState(false);
  const [_medias, setMedia] = useState(cloneDeep(general.medias));
  const [_confirmationOpen, setConfirmationOpen] = useState<number | null>(null);
  const [_privacyContent, setPrivacyContent] = useState<boolean>(false);
  const [_statusToChange, setStatusToChange] = useState(null);
  const [_nameNotValid, setNameNotValid] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (assembly) onGeneralEdited('isPrivate', assembly.isPrivate);
  }, [assembly]);

  const onCloseModal = () => {
    dispatch(assemblyEditorSliceActions.setShowReferenceEditionModal(null));
  };

  const editLinkName = (event: any, index: number) => {
    const mediaList = cloneDeep(_medias);
    mediaList[index].name = event.target.value;
    setMedia(mediaList);
  };

  const editLinkUrl = (event: any, index: number) => {
    const mediaList = cloneDeep(_medias);
    mediaList[index].url = event.target.value;
    setMedia(mediaList);
  };

  const editIsFavorite = (event: any, index: number) => {
    const mediaList = cloneDeep(_medias);
    let mediaSelected = mediaList[index];
    if (mediaSelected.isFavorite) {
      mediaSelected.isFavorite = false;
    } else {
      mediaList.forEach((media: any) => {
        media.isFavorite = false;
      });
      mediaSelected.isFavorite = true;
    }
    setMedia(mediaList);
  };

  const handleOKButton = async () => {
    const isValid = await validateAssemblyName(general.id, general.type, _suaName);
    if (!isValid) {
      setNameNotValid(true);
      toast.error(`'${_suaName}' already exists. Please enter another name.`);
      return;
    }

    if (_privacyContent) {
      setPrivacyContent(false);
      await changeStatus(general.id, _statusToChange);
      setStatus(_statusToChange);
    } else {
      dispatch(assemblyEditorSliceActions.setShowReferenceEditionModal(null));
      onGeneralEdited('medias', _medias);
      onGeneralEdited('name', _suaName);
      onGeneralEdited('description', _suaDescription);
      onGeneralEdited('supplier', _supplier);
      onGeneralEdited('status', _status);
    }
  };

  const addUrl = () => {
    let newMedia = new Media();
    let mediaList = [..._medias];
    mediaList.unshift(newMedia);
    setMedia(mediaList);
    setHighLightFirst(true);
  };

  const deleteLink = (media: any, index: number) => {
    if (media.name === '' && media.url === '') {
      const mediaList = cloneDeep(_medias);
      mediaList.splice(index, 1);
      setMedia(mediaList);
    } else {
      let linkToDelete = general.medias[index];
      let newArr = general.medias.filter((l) => !l.id.includes(linkToDelete.id));
      setMedia(newArr)
    }
    toggleDelete(null);
  };

  const toggleDelete = (index: number) => {
    if (_confirmationOpen === null) {
      setConfirmationOpen(index);
    } else {
      setConfirmationOpen(null);
    }
  };

  const handleSetPrivacy = (isPrivate) => {
    const shareable: Shareable = {
      id: Uuidv4(),
      shareableContentId: general.id,
      contentType: 'sud.assembly',
      isPrivate: !isPrivate,
      shareWith: [],
      except: []
    };
    setPrivacy(shareable, isPrivate);
  };

  const isButtonDisabled = () => {
    const linkWithoutName = _medias.find((media) => media.name === '');
    const linkWithoutUrl = _medias.find((media) => media.url === '');
    const refStatus = general.isPrivate && general.status ==="pre-onboarding" && _privacyContent;
    return linkWithoutName || linkWithoutUrl || _nameNotValid || refStatus;
  };

  const title = <FormattedMessage id='Reference.Edition' defaultMessage='Reference Edition' />;

  const renderGeneralInfos = () => {
    return (
      <GeneralInformationEdition
        setPrivacyContent={setPrivacyContent}
        setStatusToChange={setStatusToChange}
        name={_suaName}
        description={_suaDescription}
        supplier={_supplier}
        status={_status}
        nameNotValid={_nameNotValid}
        setName={(name) => {setSuaName(name); setNameNotValid(false)}}
        setDescription={(description) => setSuaDescription(description)}
        setSupplier={(supplier) => setSupplier(supplier)}
        setStatus={(status) => setStatus(status)}
      />
    );
  };

  const renderUrlList = () => {
    return _medias?.map((media, index) => (
      <>
        <div
          className='f-row f2-center gap-3 py-2 px-3 w-100'
          style={{
            backgroundColor: _highLightFirst && index === 0 ? 'var(--light-grey)' : undefined
          }}>
          <div
            className='f-shrink-0 f-center'
            style={{ width: 20 }}
            onClick={(e) => editIsFavorite(e, index)}>
            {media.isFavorite ? (
              <Icon name='star-full' color={'var(--primaryColor)'} size={18} />
            ) : (
              <Icon name='star' size={24} />
            )}
          </div>
          <div className='w-100'>
            <TextInput
              label='Link name'
              inputSize='small'
              value={media.name}
              type='string'
              onChange={(e) => editLinkName(e, index)}
            />
            <TextInput
              label='Url'
              inputSize='small'
              value={media.url}
              type='string'
              onChange={(e) => editLinkUrl(e, index)}
            />
          </div>
          <Tooltip
            open={_confirmationOpen === index && _confirmationOpen !== null}
            title={
              <div className='p-2 f-col f1-center f2-center gap-2'>
                <div>{intl.formatMessage(generalMessages.areYouSure)}</div>
                <SmallButton onClick={() => deleteLink(media, index)} red>
                  {intl.formatMessage(actionMessages.delete)}
                </SmallButton>
              </div>
            }>
            <Icon name='bin' onClick={() => toggleDelete(index)} />
          </Tooltip>
        </div>
        {index !== _medias.length - 1 && <div className='border-top w-10 my-2 ml-2' />}
      </>
    ));
  };

  const renderModalBody = () => {
    return (
      <div className='f-row f-full'>
        {(general?.permissions?.update || general?.permissions?.changeStatus) && renderGeneralInfos()}
        {
          general?.permissions?.update &&
          <div className='pl-2 f-full'>
            <Title>
              <div className='f-row f1-between gap-2'>
                <div className='f-row f2-center gap-2'>
                  <FormattedMessage
                    id='ReferenceEditionModal.RelatedUrls'
                    defaultMessage='Related URLs'
                  />
                  <Icon color='var(--frozen-grey)' />
                </div>
                <SmallButton width={'auto'} inverse style={buttonSaveStyle} onClick={() => addUrl()}>
                  <FormattedMessage id='Global.General.AddUrl' defaultMessage='Add URL' />
                </SmallButton>
              </div>
            </Title>
            {renderUrlList()}
          </div>
        }
      </div>
    );
  };

  const renderDeleteModal = () => {
    return <DeleteModal removeAssembly={null} onRemoveComponents={null} />;
  };

  const renderFooter = () => {
    return (
      <div className='f-row f1-end'>
        <Button disabled={isButtonDisabled()} style={buttonSaveStyle} onClick={handleOKButton}>
          <FormattedMessage id='Global.Action.OK' defaultMessage='OK' />
        </Button>
      </div>
    );
  };
  return (
    <Modal size='modal-lg' title={title} cancel={onCloseModal} footer={renderFooter()}>
      {_privacyContent ? (
        <PrivacyModalContent setPrivacy={handleSetPrivacy} isPrivate={general.isPrivate} />
      ) : (
        renderModalBody()
      )}
      {showDeleteConfirmModal && renderDeleteModal()}
    </Modal>
  );
};

export default ReferenceEditionModal;
