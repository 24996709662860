import { useAppDispatch, useAppSelector } from '../..';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { assemblyEditorSliceActions } from './assemblyEditorSlice';
import { cloneDeep } from 'lodash';
import { createUpdateNode } from './../../../components/Flow/utils/createUpdateNode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';

export const useImplementEditorAction = () => {
  const dispatch = useAppDispatch();
  const outdatedAssemblies = useAppSelector(
    (state) => state.assemblyEditorSlice.outdatedAssemblies
  );
  const components = useAppSelector((state) => state.assemblyEditorSlice.components);

  const clearAssembly = (component: any, componentList: any, layer: UnitOperationLayer) => {
    const componentCopy = cloneDeep(component);
    let componentListCopy = cloneDeep(componentList);
    let componentListCopyToModify = cloneDeep(componentListCopy);
    switch (layer) {
      case UnitOperationLayer.Reference:
        cleanAssemblyComponents(componentCopy, componentListCopy, componentListCopyToModify, layer);
        break;
      case UnitOperationLayer.PnID:
        cleanAssemblyComponents(componentCopy, componentListCopy, componentListCopyToModify, UnitOperationLayer.PnID);
        cleanAssemblyComponents(componentCopy, componentListCopy, componentListCopyToModify, UnitOperationLayer.Reference);
        break;
      case UnitOperationLayer.PFD:
        cleanAssemblyComponents(componentCopy, componentListCopy, componentListCopyToModify, UnitOperationLayer.PnID);
        cleanAssemblyComponents(componentCopy, componentListCopy, componentListCopyToModify, UnitOperationLayer.Reference);
        break;
    }
    dispatch(assemblyEditorSliceActions.setComponents(componentListCopyToModify));
  };

  const implementComponent = (
    component: any,
    assembly: any,
    componentList: any,
    isLayerLinked: any,
    layer: UnitOperationLayer,
    editorAssemblyLibrary: any
  ) => {
    let componentCopy = cloneDeep(component);
    let assemblyCopy = cloneDeep(assembly);
    let componentListCopy = cloneDeep(componentList);
    const componentListCopyToModify = cloneDeep(componentListCopy);

    if (layer === UnitOperationLayer.Reference && (!componentCopy.data.assembly || componentCopy.data.ghost)) {
      let parentId = assemblyCopy.general.assemblyGenericList[0].parameters[0].value;
      let componentGenericParent = editorAssemblyLibrary.find((element: any) => element.general.type === 'SingleUseAssembly' && element.general.id === parentId);
      let componentGenericParentCopy = cloneDeep(componentGenericParent);
      let deltaGeneric = getDelta(componentCopy, componentGenericParentCopy, componentListCopy);
      cleanAssemblyComponents(componentCopy, componentListCopy, componentListCopyToModify, UnitOperationLayer.PnID);
      addAssemblyComponents(
        componentCopy,
        componentGenericParentCopy,
        deltaGeneric,
        componentListCopy,
        componentListCopyToModify,
        isLayerLinked
      );
    }


    let delta = getDelta(componentCopy, assemblyCopy, componentListCopy);
    cleanAssemblyComponents(componentCopy, componentListCopy, componentListCopyToModify, layer);
    addAssemblyComponents(
      componentCopy,
      assemblyCopy,
      delta,
      componentListCopy,
      componentListCopyToModify,
      isLayerLinked
    );
    dispatch(assemblyEditorSliceActions.setComponents(componentListCopyToModify));
  };

  const average = (array: any) => array.reduce((a: any, b: any) => a + b) / array.length;

  const getDelta = (component: any, assembly: any, componentList: any) => {
    const positionXList = assembly.components
      .filter((element: any) => element.viewer2D && element.viewer2D.x)
      .map((element: any) => element.viewer2D.x);
    const positionYList = assembly.components
      .filter((element: any) => element.viewer2D && element.viewer2D.y)
      .map((element: any) => element.viewer2D.y);
    const externalCenter = {
      x: average(positionXList),
      y: average(positionYList)
    };

    let originalCenter;
    switch (component.type) {
      case 'function':
        originalCenter = getCenterFunction(component);
        break;
      case 'functionedge':
        originalCenter = getCenterFunctionEdge(component, componentList);
        break;
      default:
        originalCenter = { x: 0, y: 0 };
    }

    const delta = {
      x: externalCenter.x - originalCenter.x,
      y: externalCenter.y - originalCenter.y
    };

    return delta;
  };

  const getCenterFunction = (component: any) => {
    const originalCenter = {
      x: component.viewer2D.pid.x,
      y: component.viewer2D.pid.y
    };
    return originalCenter;
  };

  const getCenterFunctionEdge = (component: any, componentList: any) => {
    const originalPositionXList = componentList
      .filter((c: any) => c.id === component.source || c.id === component.target)
      .map((element: any) => element.viewer2D.x);

    const originalPositionYList = componentList
      .filter((c: any) => c.id === component.source || c.id === component.target)
      .map((element: any) => element.viewer2D.y);
    const originalCenter = {
      x: average(originalPositionXList),
      y: average(originalPositionYList)
    };

    return originalCenter;
  };

  const cleanFunctionEdge = (
    component: any,
    componentList: any,
    componentListToModify: any,
    anchor: any,
    toDelete: any,
    layer: UnitOperationLayer
  ) => {
    switch (layer) {
      case UnitOperationLayer.PnID:
        {
          const edge = componentList
            .find((edge: any) => {
              return (
                edge.type === 'functionedge' &&
                ((edge.source === component.id && edge.sourceHandle === anchor.id) ||
                  (edge.target === component.id && edge.targetHandle === anchor.id))
              );
            });
          edge.data.implementable = true;
          const edgeIndex = componentListToModify.findIndex((c) => c.id === edge.id);
          componentListToModify[edgeIndex] = edge;
          delete anchor.data.componentLink;
        }
        break;
      case UnitOperationLayer.Reference:
        {
          const edge = componentList
            .find((edge: any) => {
              return (
                edge.type === 'functionedge' &&
                ((edge.source === component.id && edge.sourceHandle === anchor.id) ||
                  (edge.target === component.id && edge.targetHandle === anchor.id))
              );
            });
          edge.data.implementableReference = true;
          const edgeIndex = componentListToModify.findIndex((c) => c.id === edge.id);
          componentListToModify[edgeIndex] = edge;
          delete anchor.data.componentLinkReference;
        }
        break;
    }
  };

  const cleanAssemblyComponents = (
    component: any,
    componentList: any[],
    componentListToModify: any[],
    layer: UnitOperationLayer
  ) => {
    let filteredComponents = [];
    let filteredComponentReferences = [];
    let ghostComponent = null;

    switch (layer) {
      case UnitOperationLayer.PnID:
        filteredComponents = componentList.filter(
          (c: any) =>
            (c.type === 'generic' || c.type === 'genericedge' || c.type === 'genericonedge') &&
            c.data.assembly &&
            c.data.assembly.component === component.id
        );
        ghostComponent = componentList.find((c: any) => c.type === 'ghost' && c.data.function === component.id && c.data.assembly);
        break;
      case UnitOperationLayer.Reference:
        filteredComponentReferences = componentList.filter(
          (c: any) =>
            (c.type === 'generic' || c.type === 'genericedge' || c.type === 'genericonedge') &&
            c.data.assemblyReference &&
            c.data.assemblyReference.component === component.id
        );
        ghostComponent = componentList.find((c: any) => c.type === 'ghost' && c.data.function === component.id && c.data.assemblyReference);
        break;
    }

    switch (component.type) {
      case 'function':
        component.data.anchors.forEach((anchor: any) => {
          if (anchor.data?.componentLink && layer === UnitOperationLayer.PnID) {
            const anchorComponentId = anchor.data.componentLink.component;
            const componentLinkedToDelete = filteredComponents.find((fc: any) => fc.id === anchorComponentId);
            if (componentLinkedToDelete) {
              cleanFunctionEdge(component, componentList, componentListToModify, anchor, componentLinkedToDelete, layer);
            }
          }
          if (anchor.data?.componentLinkReference && layer === UnitOperationLayer.Reference) {
            const anchorComponentReferenceId = anchor.data.componentLinkReference.component;
            const componentLinkedReferenceToDelete = filteredComponentReferences.find((fc: any) => fc.id === anchorComponentReferenceId);
            if (componentLinkedReferenceToDelete) {
              cleanFunctionEdge(component, componentList, componentListToModify, anchor, componentLinkedReferenceToDelete, layer);
            }
          }
        });
        break;
      case 'functionedge':
      default:
        break;
    }
    switch (layer) {
      case UnitOperationLayer.PnID:
        delete component.data.assembly;
        break;
      case UnitOperationLayer.Reference:
        delete component.data.assemblyReference;
        break;
    }

    const componentsToDelete = [...filteredComponents, ...filteredComponentReferences];
    if (ghostComponent) {
      componentsToDelete.push(ghostComponent);
    }
    const selectionComponent = componentList.find((co: any) => co.type === 'selection');
    if (selectionComponent) {
      componentsToDelete.push(selectionComponent);
    }
    componentsToDelete.forEach((componentToDelete) => {
      const index = componentListToModify.findIndex(
        (component) => component.id === componentToDelete.id
      );
      if (index !== -1) {
        componentListToModify.splice(index, 1);
      }
    });

    const functionComponentIndex = componentListToModify.findIndex(
      (c: any) => c.id === component.id
    );
    componentListToModify[functionComponentIndex] = component;
  };

  const addFunctionAssemblyComponents = (
    component: any,
    assembly: any,
    delta: any,
    componentList: any,
    componentListToModify: any,
    isLayerLinked: boolean
  ) => {
    if (component.type === 'function') {
      const assemblyComponents = [] as any;
      assembly.components
        .filter((e: any) => e.type === 'generic')
        .forEach((element: any) => {
          element.oldId = element.id;
          element.id = uuidv4();
          element.viewer2D.pfd = {
            x: element.viewer2D.x - delta.x,
            y: element.viewer2D.y - delta.y
          };
          element.viewer2D.pid = {
            x: element.viewer2D.x - delta.x,
            y: element.viewer2D.y - delta.y
          };
          element.viewer2D.reference = {
            x: element.viewer2D.x - delta.x,
            y: element.viewer2D.y - delta.y
          };
          switch (assembly.general.type) {
            case 'SingleUseAssembly':
              element.data.assembly = {
                ...assembly.general,
                component: component.id
              };
              element.data.ghost = true;
              break;
            case 'SingleUseAssemblyReference':
              element.data.assemblyReference = {
                ...assembly.general,
                component: component.id
              };
              element.data.ghostReference = true;
              break;
          }
          componentListToModify.push(element);
          assemblyComponents.push(element);
        });

      assembly.components
        .filter((e: any) => e.type === 'genericedge')
        .forEach((element: any) => {
          element.oldId = element.id;
          element.id = uuidv4();
          let target = assembly.components.find((c: any) => c.oldId === element.target);
          let source = assembly.components.find((c: any) => c.oldId === element.source);
          element.target = target.id;
          element.source = source.id;
          switch (assembly.general.type) {
            case 'SingleUseAssembly':
              element.data.assembly = {
                ...assembly.general,
                component: component.id
              };
              element.data.ghost = true;
              break;
            case 'SingleUseAssemblyReference':
              element.data.assemblyReference = {
                ...assembly.general,
                component: component.id
              };
              element.data.ghostReference = true;
              break;
          }
          componentListToModify.push(element);
        });

      assembly.components
        .filter((e: any) => e.type === 'genericonedge')
        .forEach((element: any) => {
          element.oldId = element.id;
          element.id = uuidv4();
          element.viewer2D.pfd = {
            x: element.viewer2D.x - delta.x,
            y: element.viewer2D.y - delta.y
          };
          element.viewer2D.pid = {
            x: element.viewer2D.x - delta.x,
            y: element.viewer2D.y - delta.y
          };
          element.viewer2D.reference = {
            x: element.viewer2D.x - delta.x,
            y: element.viewer2D.y - delta.y
          };
          let source = assembly.components.find((c: any) => c.oldId === element.viewer2D.source);
          element.viewer2D.source = source.id;
          switch (assembly.general.type) {
            case 'SingleUseAssembly':
              element.data.assembly = {
                ...assembly.general,
                component: component.id
              };
              element.data.ghost = true;
              break;
            case 'SingleUseAssemblyReference':
              element.data.assemblyReference = {
                ...assembly.general,
                component: component.id
              };
              element.data.ghostReference = true;
              break;
          }
          componentListToModify.push(element);
        });

      var componentMaxX = assemblyComponents[0].viewer2D.pid.x as number;
      var componentMaxY = assemblyComponents[0].viewer2D.pid.y as number;
      var componentMinX = assemblyComponents[0].viewer2D.pid.x as number;
      var componentMinY = assemblyComponents[0].viewer2D.pid.y as number;
      assemblyComponents.forEach((c: any) => {
        if (componentMaxX < c.viewer2D.pid.x) {
          componentMaxX = c.viewer2D.pid.x;
        }
        if (componentMaxY < c.viewer2D.pid.y) {
          componentMaxY = c.viewer2D.pid.y;
        }
        if (componentMinX > c.viewer2D.pid.x) {
          componentMinX = c.viewer2D.pid.x;
        }
        if (componentMinY > c.viewer2D.pid.y) {
          componentMinY = c.viewer2D.pid.y;
        }
      });

      const positionTopLeft = {
        x: componentMinX - 10,
        y: componentMinY - 10
      };
      const positionBottomRight = {
        x: componentMaxX + 90,
        y: componentMaxY + 90
      };
      const nodeSize = {
        x: positionBottomRight.x - positionTopLeft.x + 35,
        y: positionBottomRight.y - positionTopLeft.y + 35
      };

      const ghostId = uuidv4();
      switch (assembly.general.type) {
        case 'SingleUseAssembly':
          component.data.assembly = assembly;
          component.data.ghost = true;
          component.data.ghostId = ghostId;
          break;
        case 'SingleUseAssemblyReference':
          component.data.assemblyReference = assembly;
          component.data.ghostReference = true;
          component.data.ghostIdReference = ghostId;
          break;
      }

      const componentIndex = componentListToModify.findIndex((c) => c.id === component.id);
      componentListToModify[componentIndex] = component;

      let ghost = {
        id: ghostId,
        type: 'ghost',
        viewer2D: {
          pfd: { ...positionTopLeft },
          pid: { ...positionTopLeft },
          reference: { ...positionTopLeft },
          size: nodeSize
        },
        data: {
          function: component.id,
          type: 'ghost',
          anchors: [...component.data.anchors]
        }
      } as any;

      switch (assembly.general.type) {
        case 'SingleUseAssembly':
          ghost.data.ghost = true;
          ghost.data.assembly = { ...assembly.general };
          break;
        case 'SingleUseAssemblyReference':
          ghost.data.ghostReference = true;
          ghost.data.assemblyReference = { ...assembly.general };
          break;
      }

      componentListToModify.unshift(ghost);

      let moveLeft = false;
      let moveRight = false;
      const middleX = (positionBottomRight.x + positionTopLeft.x) / 2;
      const middleY = (positionBottomRight.y + positionTopLeft.y) / 2;

      const validTypes = isLayerLinked ? ['genericedge', 'functionedge'] : ['genericedge', 'functionedge', 'function'];

      const processComponents = (componentList: any[], prop: string, coord: string) => {
        componentList
          .filter((c: any) => !validTypes.includes(c.type) && c.id !== component.id && c.data[prop])
          .forEach((c: any) => {
            if (
              positionTopLeft.x - 80 <= c.viewer2D[coord].x &&
              c.viewer2D[coord].x <= positionBottomRight.x + 80 &&
              positionTopLeft.y - 80 <= c.viewer2D[coord].y &&
              c.viewer2D[coord].y <= positionBottomRight.y + 80
            ) {
              moveLeft = c.viewer2D[coord].x < middleX;
              moveRight = !moveLeft;

              if (moveLeft || moveRight) {
                const displacement = (moveLeft ? -1 : 1) * (positionBottomRight.x - positionTopLeft.x) / 2 + 20;
                c.viewer2D[coord].x += displacement;
                c.viewer2D[coord].y +=
                  c.viewer2D[coord].y < middleY
                    ? -(positionBottomRight.y - positionTopLeft.y) / 2 - 20
                    : (positionBottomRight.y - positionTopLeft.y) / 2 + 20;

                const cIndex = componentListToModify.findIndex((cc) => cc.id === c.id);
                componentListToModify[cIndex] = c;
              }
            }
          });
      }

      switch (assembly.general.type) {
        case "SingleUseAssembly":
          processComponents(componentList, 'assembly', 'pid');
          break;
        case "SingleUseAssemblyReference":
          processComponents(componentList, 'assemblyReference', 'reference');
          break;
      }

      const updateEdgeList = (fieldGhost: string, fieldSource: string, fieldTarget: string) => {
        let edgeList = componentList.filter(
          (c: any) =>
            c.type === 'functionedge' &&
            !c.data[fieldGhost] &&
            (c.source === component.id || c.target === component.id)
        );

        edgeList.forEach((edge: any) => {
          if (edge.source === component.id) {
            edge[fieldSource] = ghost.id;
          } else {
            edge[fieldTarget] = ghost.id;
          }
          const edgeIndex = componentListToModify.findIndex((c) => c.id === edge.id);
          componentListToModify[edgeIndex] = edge;
        });
      }

      switch (assembly.general.type) {
        case 'SingleUseAssembly':
          updateEdgeList('ghost', 'sourceGhost', 'targetGhost');
          break;
        case 'SingleUseAssemblyReference':
          updateEdgeList('ghostReference', 'sourceGhostReference', 'targetGhostReference');
          break;
      }
    }
  };

  const createTransfertFunction = (component: any, componentList: any) => {
    let newTransfert = {
      data: {
        type: 'transferring',
        anchors: [
          {
            data: { name: 'S-A' },
            id: uuidv4(),
            position: 'left',
            viewer2D: { top: 0.47348611111111116, left: 0.13156555555555555 },
            type: 'neutral'
          },
          {
            data: { name: 'T-B' },
            id: uuidv4(),
            position: 'left',
            viewer2D: {
              top: 0.48106111111111105,
              left: 0.8353527777777777
            },
            type: 'neutral'
          }
        ],
        assembly: null
      },
      id: uuidv4(),
      type: 'function',
      viewer2D: getCenterFunctionEdge(component, componentList)
    };
    return newTransfert;
  };

  const createTransferFunctionEdge = (component: any, newTransfert: any) => {
    let newEdge1 = {
      data: { type: 'transferring', implementable: true },
      id: uuidv4(),
      source: component.source,
      sourceHandle: component.sourceHandle,
      target: newTransfert.id,
      targetHandle: newTransfert.data.anchors[1].id,
      type: 'functionedge'
    };
    let newEdge2 = {
      data: { type: 'transferring', implementable: true },
      id: uuidv4(),
      source: newTransfert.id,
      sourceHandle: newTransfert.data.anchors[0].id,
      target: component.target,
      targetHandle: component.targetHandle,
      type: 'functionedge'
    };
    return { newEdge1, newEdge2 };
  };

  const addFunctionEdgeAssemblyComponents = (
    component: any,
    assembly: any,
    delta: any,
    componentList: any,
    componentListToModify: any
  ) => {
    let newTransfert = createTransfertFunction(component, componentList);
    let { newEdge1, newEdge2 } = createTransferFunctionEdge(component, newTransfert);

    const index = componentListToModify.findIndex((c) => c.id === component.id);
    if (index !== -1) {
      componentListToModify.splice(index, 1);
    }
    const assemblyComponents = [] as any;
    assembly.components
      .filter((e: any) => e.type === 'generic')
      .forEach((element: any) => {
        element.oldId = element.id;
        element.id = uuidv4();
        element.viewer2D.x = element.viewer2D.x - delta.x;
        element.viewer2D.y = element.viewer2D.y - delta.y;
        element.data.assembly = {
          ...assembly.general,
          component: newTransfert.id
        };
        element.data.ghost = true;
        assemblyComponents.push(element);
        componentListToModify.push(element);
      });

    assembly.components
      .filter((e: any) => e.type === 'genericedge')
      .forEach((element: any) => {
        element.oldId = element.id;
        element.id = uuidv4();
        let target = assembly.components.find((c: any) => c.oldId === element.target);
        let source = assembly.components.find((c: any) => c.oldId === element.source);
        element.target = target.id;
        element.source = source.id;
        element.data.assembly = {
          ...assembly.general,
          component: newTransfert.id
        };
        element.data.ghost = true;
        componentListToModify.push(element);
      });

    assembly.components
      .filter((e: any) => e.type === 'genericonedge')
      .forEach((element: any) => {
        element.oldId = element.id;
        element.id = uuidv4();
        element.viewer2D.x = element.viewer2D.x - delta.x;
        element.viewer2D.y = element.viewer2D.y - delta.y;
        let source = assembly.components.find((c: any) => c.oldId === element.viewer2D.source);
        element.viewer2D.source = source.id;
        element.data.assembly = {
          ...assembly.general,
          component: newTransfert.id
        };
        element.data.ghost = true;
        componentListToModify.push(element);
      });

    var componentMaxX = assemblyComponents[0].viewer2D.x as number;
    var componentMaxY = assemblyComponents[0].viewer2D.y as number;
    var componentMinX = assemblyComponents[0].viewer2D.x as number;
    var componentMinY = assemblyComponents[0].viewer2D.y as number;
    assemblyComponents.map((c: any) => {
      if (componentMaxX < c.viewer2D.x) {
        componentMaxX = c.viewer2D.x;
      }
      if (componentMaxY < c.viewer2D.y) {
        componentMaxY = c.viewer2D.y;
      }
      if (componentMinX > c.viewer2D.x) {
        componentMinX = c.viewer2D.x;
      }
      if (componentMinY > c.viewer2D.y) {
        componentMinY = c.viewer2D.y;
      }
    });

    const positionTopLeft = { x: componentMinX - 10, y: componentMinY - 10 };
    const positionBottomRight = {
      x: componentMaxX + 90,
      y: componentMaxY + 90
    };
    const nodeSize = {
      x: positionBottomRight.x - positionTopLeft.x + 35,
      y: positionBottomRight.y - positionTopLeft.y + 35
    };

    const ghostId = uuidv4();
    newTransfert.data.assembly = assembly;
    // @ts-ignore
    newTransfert.data.ghost = true;
    // @ts-ignore
    newTransfert.data.ghostId = ghostId;
    componentListToModify.push(newTransfert);
    const ghost = {
      id: ghostId,
      type: 'ghost',
      viewer2D: { ...positionTopLeft, size: nodeSize },
      data: {
        ghost: true,
        function: newTransfert.id,
        assembly: { ...assembly.general },
        type: 'ghost',
        anchors: cloneDeep(newTransfert.data.anchors)
      }
    };
    componentListToModify.unshift(ghost);
    // @ts-ignore
    newEdge1.targetGhost = ghostId;
    // @ts-ignore
    newEdge2.sourceGhost = ghostId;
    componentListToModify.push(newEdge1);
    componentListToModify.push(newEdge2);

    let moveLeft = false;
    let moveRight = false;
    const middleX = positionBottomRight.x - (positionBottomRight.x - positionTopLeft.x) / 2;
    const middleY = positionBottomRight.y - (positionBottomRight.y - positionTopLeft.y) / 2;

    componentList
      .filter(
        (c: any) => c.type !== 'genericedge' && c.type !== 'functionedge' && c.id !== component.id
      )
      .forEach((c: any) => {
        if (
          positionTopLeft.x - 80 <= c.viewer2D.x &&
          c.viewer2D.x <= positionBottomRight.x + 80 &&
          positionTopLeft.y - 80 <= c.viewer2D.y &&
          c.viewer2D.y <= positionBottomRight.y + 80
        ) {
          if (c.viewer2D.x < middleX) {
            moveLeft = true;
          } else {
            moveRight = true;
          }
        }
      });

    componentList
      .filter(
        (c: any) => c.type !== 'genericedge' && c.type !== 'functionedge' && c.id !== component.id
      )
      .forEach((c: any) => {
        if (moveLeft && c.viewer2D.x < middleX) {
          c.viewer2D.x = c.viewer2D.x - (positionBottomRight.x - positionTopLeft.x) / 2 - 50;
          c.viewer2D.y =
            c.viewer2D.y < middleY
              ? c.viewer2D.y - (positionBottomRight.y - positionTopLeft.y) / 2 - 20
              : c.viewer2D.y + (positionBottomRight.y - positionTopLeft.y) / 2 + 20;
          const cIndex = componentListToModify.findIndex((cc) => cc.id === c.id);
          componentListToModify[cIndex] = c;
        }
        if (moveRight && c.viewer2D.x >= middleX) {
          c.viewer2D.x = c.viewer2D.x + (positionBottomRight.x - positionTopLeft.x) / 2 + 50;
          c.viewer2D.y =
            c.viewer2D.y < middleY
              ? c.viewer2D.y - (positionBottomRight.y - positionTopLeft.y) / 2 - 20
              : c.viewer2D.y + (positionBottomRight.y - positionTopLeft.y) / 2 + 20;
          const cIndex = componentListToModify.findIndex((cc) => cc.id === c.id);
          componentListToModify[cIndex] = c;
        }
      });
  };

  const addAssemblyComponents = (
    component: any,
    assembly: any,
    delta: any,
    componentList: any,
    componentListToModify: any,
    isLayerLinked: boolean
  ) => {
    if (component.type === 'function') {
      addFunctionAssemblyComponents(
        component,
        assembly,
        delta,
        componentList,
        componentListToModify,
        isLayerLinked
      );
    } else if (component.type === 'functionedge') {
      addFunctionEdgeAssemblyComponents(
        component,
        assembly,
        delta,
        componentList,
        componentListToModify
      );
    }
  };

  const confirmGhost = (ghostId: string, layer: UnitOperationLayer, originalComponentList: any) => {
    const componentList = cloneDeep(originalComponentList);
    let ghostFunction: any;
    switch (layer) {
      case UnitOperationLayer.PnID:
        ghostFunction = componentList.find((c: any) => c.id === ghostId);
        break;
      case UnitOperationLayer.Reference:
        ghostFunction = componentList.find((c: any) => c.id === ghostId);
        break;
    }
    const edgeToDeleteList = [] as any;
    const originalFunction = componentList.find((c: any) => c.id === ghostFunction.data.function);

    let edgeGhostList = [];
    switch (layer) {
      case UnitOperationLayer.PnID:
        edgeGhostList = componentList.filter(
          (c: any) =>
            c.type === 'functionedge' &&
            c.data.ghost &&
            (c.source === ghostFunction.id || c.target === ghostFunction.id)
        );
        break;
      case UnitOperationLayer.Reference:
        edgeGhostList = componentList.filter(
          (c: any) =>
            c.type === 'functionedge' &&
            c.data.ghostReference &&
            (c.source === ghostFunction.id || c.target === ghostFunction.id)
        );
        break;
    }

    edgeGhostList.forEach((edgeGhost: any) => {
      let functionGhostNode: any;
      let anchorFunctionGhostNode: any;
      let genericGhostNode: any;
      let anchorGenericGhostNode: any;

      if (edgeGhost.source === ghostFunction.id) {
        functionGhostNode = { type: 'source', id: originalFunction.id };
        anchorFunctionGhostNode = {
          type: 'sourceHandle',
          id: edgeGhost.sourceHandle
        };
        genericGhostNode = { type: 'target', id: edgeGhost.target };
        anchorGenericGhostNode = {
          type: 'targetHandle',
          id: edgeGhost.targetHandle
        };
      } else {
        functionGhostNode = { type: 'target', id: originalFunction.id };
        anchorFunctionGhostNode = {
          type: 'targetHandle',
          id: edgeGhost.targetHandle
        };
        genericGhostNode = { type: 'source', id: edgeGhost.source };
        anchorGenericGhostNode = {
          type: 'sourceHandle',
          id: edgeGhost.sourceHandle
        };
      }

      let originalEdge: any;

      switch (layer) {
        case UnitOperationLayer.PnID:
          originalEdge = componentList.find((c: any) => {
            return (
              c.type === 'functionedge' &&
              !c.data.ghost &&
              ((c.source === functionGhostNode.id && c.sourceHandle === anchorFunctionGhostNode.id) ||
                (c.target === functionGhostNode.id && c.targetHandle === anchorFunctionGhostNode.id))
            );
          });
          break;
        case UnitOperationLayer.Reference:
          originalEdge = componentList.find((c: any) => {
            return (
              c.type === 'functionedge' &&
              !c.data.ghostReference &&
              ((c.source === functionGhostNode.id && c.sourceHandle === anchorFunctionGhostNode.id) ||
                (c.target === functionGhostNode.id && c.targetHandle === anchorFunctionGhostNode.id))
            );
          });
          break;
      }

      if (!originalEdge) {
        return;
      }

      const element = componentList.find((component: any) => component.id === genericGhostNode.id);
      const originalFunctionAnchor = originalFunction.data.anchors.find(
        (anchor: any) => anchor.id === anchorFunctionGhostNode.id
      );
      let anchor;
      anchor = element.data.anchors.find((anchor: any) => anchor.id === anchorGenericGhostNode.id);
      if (!anchor)
        anchor = element.data.instrumentationPorts.find(
          (anchor: any) => anchor.id === anchorGenericGhostNode.id
        );
      if (!anchor)
        anchor = element.data.samplingPorts.find(
          (anchor: any) => anchor.id === anchorGenericGhostNode.id
        );
      switch (layer) {
        case UnitOperationLayer.PnID:
          if (originalEdge.source === functionGhostNode.id) {
            delete originalEdge.sourceGhost;
          } else {
            delete originalEdge.targetGhost;
          }
          break;
        case UnitOperationLayer.Reference:
          if (originalEdge.source === functionGhostNode.id) {
            delete originalEdge.sourceGhostReference;
          } else {
            delete originalEdge.targetGhostReference;
          }
          break;
      }

      switch (layer) {
        case UnitOperationLayer.PnID:
          anchor.data.componentLink = {
            anchor: anchorFunctionGhostNode.id,
            component: functionGhostNode.id
          };
          originalFunctionAnchor.data.componentLink = {
            anchor: anchorGenericGhostNode.id,
            component: genericGhostNode.id
          };
          break;
        case UnitOperationLayer.Reference:
          anchor.data.componentLinkReference = {
            anchor: anchorFunctionGhostNode.id,
            component: functionGhostNode.id
          };
          originalFunctionAnchor.data.componentLinkReference = {
            anchor: anchorGenericGhostNode.id,
            component: genericGhostNode.id
          };
          break;
      }
      edgeToDeleteList.push(edgeGhost);
      dispatch(assemblyEditorSliceActions.updateComponent(originalEdge));
    });
    componentList.forEach((c: any) => {
      switch (layer) {
        case UnitOperationLayer.PnID:
          if (c.id === originalFunction.id || c.data.assembly?.component === originalFunction.id) {
            delete c.data.ghost;
            dispatch(assemblyEditorSliceActions.updateComponent(c));
          }
          break;
        case UnitOperationLayer.Reference:
          if (c.id === originalFunction.id || c.data.assemblyReference?.component === originalFunction.id) {
            delete c.data.ghostReference;
            dispatch(assemblyEditorSliceActions.updateComponent(c));
          }
          break;
      }
    });
    // @ts-ignore
    const isOutdatedAssembly = outdatedAssemblies.includes(ghostFunction.data.function);
    if (isOutdatedAssembly) {
      const updateNodeToAdd = createUpdateNode(ghostFunction.data.function, components, layer);
      dispatch(assemblyEditorSliceActions.addComponent(updateNodeToAdd));
    }
    dispatch(assemblyEditorSliceActions.removeComponents([...edgeToDeleteList, ghostFunction]));
  };
  const cancelGhost = (ghostId: any, originalComponentList: any, layer: string) => {
    const componentList = cloneDeep(originalComponentList);
    const ghostFunction = componentList.find((c: any) => c.id === ghostId);
    const originalFunction = componentList.find((c: any) => c.id === ghostFunction.data.function);

    switch (layer) {
      case UnitOperationLayer.PnID:
        delete originalFunction.data.ghost;
        delete originalFunction.data.ghostId;
        delete originalFunction.data.assembly;
        delete originalFunction.data.assemblyReference;

        break;
      case UnitOperationLayer.Reference:
        delete originalFunction.data.ghostReference;
        delete originalFunction.data.ghostIdReference;
        delete originalFunction.data.assemblyReference;
        break;
    }

    const originalEdgeList = componentList.filter((c: any) => {
      return (
        c.type === 'functionedge' &&
        (c.source === originalFunction.id || c.target === originalFunction.id)
      );
    });


    switch (layer) {
      case UnitOperationLayer.PnID:
        originalEdgeList.forEach((edge: any) => {
          if (edge.sourceGhost === ghostId) {
            delete edge.sourceGhost;
          }
          if (edge.targetGhost === ghostId) {
            delete edge.targetGhost;
          }
        });
        break;
      case UnitOperationLayer.Reference:
        originalEdgeList.forEach((edge: any) => {
          if (edge.sourceGhost === ghostId) {
            delete edge.sourceGhostReference;
          }
          if (edge.targetGhost === ghostId) {
            delete edge.targetGhostReference;
          }
        });
        break;
    }

    let assemblyComponents = [];
    switch (layer) {
      case UnitOperationLayer.PnID:
        assemblyComponents = componentList.filter((c: any) => {
          return (
            (c.type === 'generic' || c.type === 'genericonedge' || c.type === 'genericedge') &&
            c.data.assembly &&
            c.data.assembly.component === originalFunction.id
          );
        });
        break;
      case UnitOperationLayer.Reference:
        assemblyComponents = componentList.filter((c: any) => {
          return (
            (c.type === 'generic' || c.type === 'genericonedge' || c.type === 'genericedge') &&
            c.data.assemblyReference &&
            c.data.assemblyReference.component === originalFunction.id
          );
        });
        break;
    }

    let ghostEdgeList = [];
    switch (layer) {
      case UnitOperationLayer.PnID:
        ghostEdgeList = componentList.filter((c: any) => {
          return (
            c.type === 'functionedge' &&
            c.data.ghost &&
            (c.source === ghostFunction.id || c.target === ghostFunction.id)
          );
        });
        break;
      case UnitOperationLayer.Reference:
        ghostEdgeList = componentList.filter((c: any) => {
          return (
            c.type === 'functionedge' &&
            c.data.ghostReference &&
            (c.source === ghostFunction.id || c.target === ghostFunction.id)
          );
        });
    }

    dispatch(assemblyEditorSliceActions.updateComponent(originalFunction));

    originalEdgeList.forEach((e: any) => {
      dispatch(assemblyEditorSliceActions.updateComponent(e));
    });

    dispatch(
      assemblyEditorSliceActions.removeComponents([
        ...assemblyComponents,
        ...ghostEdgeList,
        ghostFunction
      ])
    );
  };

  const reactiveGhost = (component: any, componentList: any) => {
    if (component.type === 'function') {
      const componentCopy = cloneDeep(component);
      const componentListCopy = cloneDeep(componentList);

      let ghost: any;
      let ghostId: string;
      let ghostReference: any;
      let ghostIdReference: string;

      if (componentCopy.data.assembly) {
        let assemblyComponents = componentListCopy.filter(
          (c: any) => c.data.assembly && c.data.assembly.component === componentCopy.id
        );
        assemblyComponents.forEach((c: any) => {
          c.data.ghost = true;
          dispatch(assemblyEditorSliceActions.updateComponent(c));
        });
        assemblyComponents = assemblyComponents.filter(
          (component: any) => component.type === 'generic'
        );
        var componentMaxX = assemblyComponents[0].viewer2D.pid.x as number;
        var componentMaxY = assemblyComponents[0].viewer2D.pid.y as number;
        var componentMinX = assemblyComponents[0].viewer2D.pid.x as number;
        var componentMinY = assemblyComponents[0].viewer2D.pid.y as number;
        assemblyComponents.forEach((c: any) => {
          if (componentMaxX < c.viewer2D.pid.x) {
            componentMaxX = c.viewer2D.pid.x;
          }
          if (componentMaxY < c.viewer2D.pid.y) {
            componentMaxY = c.viewer2D.pid.y;
          }
          if (componentMinX > c.viewer2D.pid.x) {
            componentMinX = c.viewer2D.pid.x;
          }
          if (componentMinY > c.viewer2D.pid.y) {
            componentMinY = c.viewer2D.pid.y;
          }
        });
        const positionTopLeft = {
          pfd: {
            x: componentMinX - 10,
            y: componentMinY - 10
          },
          pid: {
            x: componentMinX - 10,
            y: componentMinY - 10
          },
          reference: {
            x: componentMinX - 10,
            y: componentMinY - 10
          }
        };
        const positionBottomRight = {
          x: componentMaxX + 90,
          y: componentMaxY + 90
        };
        const nodeSize = {
          x: positionBottomRight.x - positionTopLeft.pid.x + 35,
          y: positionBottomRight.y - positionTopLeft.pid.y + 35
        };
        const ghostAlreadyExists = components.find(
          (c) => c.type === 'ghost' && !!c.data.ghost && c.data.function === componentCopy.id
        );
        if (!ghostAlreadyExists) {
          ghostId = uuidv4();
          componentCopy.data.ghost = true;
          componentCopy.data.ghostId = ghostId;
          dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
          ghost = {
            id: ghostId,
            type: 'ghost',
            viewer2D: { ...positionTopLeft, size: nodeSize },
            data: {
              ghost: true,
              function: componentCopy.id,
              assembly: { ...componentCopy.data.assembly.general },
              type: 'ghost',
              anchors: cloneDeep([...componentCopy.data.anchors])
            }
          };
          dispatch(assemblyEditorSliceActions.unshiftComponent(ghost));
        } else {
          ghost = ghostAlreadyExists;
          ghostId = ghostAlreadyExists.id;
        }
      }

      if (componentCopy.data.assemblyReference) {
        let assemblyReferenceComponents = componentListCopy.filter(
          (c: any) => c.data.assemblyReference && c.data.assemblyReference.component === componentCopy.id
        );
        assemblyReferenceComponents.forEach((c: any) => {
          c.data.ghostReference = true;
          dispatch(assemblyEditorSliceActions.updateComponent(c));
        });
        assemblyReferenceComponents = assemblyReferenceComponents.filter(
          (component: any) => component.type === 'generic'
        );
        let componentReferenceMaxX = assemblyReferenceComponents[0].viewer2D.reference.x as number;
        let componentReferenceMaxY = assemblyReferenceComponents[0].viewer2D.reference.y as number;
        let componentReferenceMinX = assemblyReferenceComponents[0].viewer2D.reference.x as number;
        let componentReferenceMinY = assemblyReferenceComponents[0].viewer2D.reference.y as number;
        assemblyReferenceComponents.map((c: any) => {
          if (componentReferenceMaxX < c.viewer2D.reference.x) {
            componentReferenceMaxX = c.viewer2D.reference.x;
          }
          if (componentReferenceMaxY < c.viewer2D.reference.y) {
            componentReferenceMaxY = c.viewer2D.reference.y;
          }
          if (componentReferenceMinX > c.viewer2D.reference.x) {
            componentReferenceMinX = c.viewer2D.reference.x;
          }
          if (componentReferenceMinY > c.viewer2D.reference.y) {
            componentReferenceMinY = c.viewer2D.reference.y;
          }
        });
        const positionTopLeftReference = {
          pfd: {
            x: componentReferenceMinX - 10,
            y: componentReferenceMinY - 10
          },
          pid: {
            x: componentReferenceMinX - 10,
            y: componentReferenceMinY - 10
          },
          reference: {
            x: componentReferenceMinX - 10,
            y: componentReferenceMinY - 10
          }
        };
        const positionBottomRightReference = {
          x: componentReferenceMaxX + 90,
          y: componentReferenceMaxY + 90
        };
        const nodeSizeReference = {
          x: positionBottomRightReference.x - positionTopLeftReference.reference.x + 35,
          y: positionBottomRightReference.y - positionTopLeftReference.reference.y + 35
        };
        const ghostReferenceAlreadyExists = components.find(
          (c) => c.type === 'ghost' && !!c.data.ghostReference && c.data.function === componentCopy.id
        );
        if (!ghostReferenceAlreadyExists) {
          ghostIdReference = uuidv4();
          componentCopy.data.ghostReference = true;
          componentCopy.data.ghostIdReference = ghostIdReference;
          dispatch(assemblyEditorSliceActions.updateComponent(componentCopy));
          ghostReference = {
            id: ghostIdReference,
            type: 'ghost',
            viewer2D: { ...positionTopLeftReference, size: nodeSizeReference },
            data: {
              ghostReference: true,
              function: componentCopy.id,
              assemblyReference: { ...componentCopy.data.assemblyReference.general },
              type: 'ghost',
              anchors: cloneDeep([...componentCopy.data.anchors])
            }
          };
          dispatch(assemblyEditorSliceActions.unshiftComponent(ghostReference));
        } else {
          ghostReference = ghostReferenceAlreadyExists;
          ghostIdReference = ghostReferenceAlreadyExists.id;
        }
      }


      // let moveLeft = false;
      // let moveRight = false;
      // const middleX = positionBottomRight.x - (positionBottomRight.x - positionTopLeft.pid.x) / 2;
      // const middleY = positionBottomRight.y - (positionBottomRight.y - positionTopLeft.pid.y) / 2;

      // componentListCopy
      //   .filter(
      //     (c: any) =>
      //       c.type !== 'genericedge' && c.type !== 'functionedge' && c.id !== componentCopy.id
      //   )
      //   .forEach((c: any) => {
      //     if (
      //       positionTopLeft.pid.x - 80 <= c.viewer2D.pid.x &&
      //       c.viewer2D.pid.x <= positionBottomRight.x + 80 &&
      //       positionTopLeft.pid.y - 80 <= c.viewer2D.pid.y &&
      //       c.viewer2D.pid.y <= positionBottomRight.y + 80
      //     ) {
      //       if (c.viewer2D.pid.x < middleX) {
      //         moveLeft = true;
      //       } else {
      //         moveRight = true;
      //       }
      //     }
      //   });

      // componentListCopy
      //   .filter(
      //     (c: any) =>
      //       c.type !== 'genericedge' && c.type !== 'functionedge' && c.id !== componentCopy.id
      //   )
      //   .forEach((c: any) => {
      //     if (moveLeft && c.viewer2D.pid.x < middleX) {
      //       c.viewer2D.pid.x =
      //         c.viewer2D.pid.x - (positionBottomRight.x - positionTopLeft.pid.x) / 2 - 50;
      //       c.viewer2D.pid.y =
      //         c.viewer2D.pid.y < middleY
      //           ? c.viewer2D.pid.y - (positionBottomRight.y - positionTopLeft.pid.y) / 2 - 50
      //           : c.viewer2D.pid.y + (positionBottomRight.y - positionTopLeft.pid.y) / 2 + 50;
      //       //dispatch(assemblyEditorSliceActions.updateComponent(c));
      //     }
      //     if (moveRight && c.viewer2D.pid.x >= middleX) {
      //       c.viewer2D.pid.x =
      //         c.viewer2D.pid.x + (positionBottomRight.x - positionTopLeft.pid.x) / 2 + 50;
      //       c.viewer2D.pid.y =
      //         c.viewer2D.pid.y < middleY
      //           ? c.viewer2D.pid.y - (positionBottomRight.y - positionTopLeft.pid.y) / 2 - 50
      //           : c.viewer2D.pid.y + (positionBottomRight.y - positionTopLeft.pid.y) / 2 + 50;
      //       //dispatch(assemblyEditorSliceActions.updateComponent(c));
      //     }
      //   });

      if (!!ghost) {
        const edgeList = componentListCopy.filter(
          (c: any) =>
            c.type === 'functionedge' &&
            !c.data.ghost &&
            (c.source === componentCopy.id || c.target === componentCopy.id)
        );
        edgeList.forEach((edge: any) => {
          if (edge.source === componentCopy.id) {
            edge.sourceGhost = ghost.id;
          } else {
            edge.targetGhost = ghost.id;
          }
          const functionSource = componentListCopy.find(
            (c: any) => c.id === edge.source && c.type === 'function'
          )
          const anchorSource = functionSource.data.anchors.find(
            (a: any) => a.id === edge.sourceHandle
          );
          let componentLinkSource = anchorSource.data.componentLink
          if (!!componentLinkSource) {
            const componentSource = componentListCopy.find(
              (c: any) => c.id === componentLinkSource.component
            );
            if (!!componentSource) {
              if (componentSource.data.idDrag) {
                delete componentSource.data.idDrag;
                dispatch(assemblyEditorSliceActions.updateComponent(componentSource));
              }
            }
          }

          const functionTarget = componentListCopy.find(
            (c: any) => c.id === edge.target && c.type === 'function'
          );
          const anchorTarget = functionTarget.data.anchors.find(
            (a: any) => a.id === edge.targetHandle
          );
          let componentLinkTarget = anchorTarget.data.componentLink
          if (!!componentLinkTarget) {
            const componentTarget = componentListCopy.find(
              (c: any) => c.id === componentLinkTarget.component
            );
            if (!!componentTarget) {
              if (componentTarget.data.idDrag) {
                delete componentTarget.data.idDrag;
                dispatch(assemblyEditorSliceActions.updateComponent(componentTarget));
              }
            }
          }
          edge.data.implementable = true
          dispatch(assemblyEditorSliceActions.updateComponent(edge));
        });
      }

      if (!!ghostReference) {
        const edgeListReference = componentListCopy.filter(
          (c: any) =>
            c.type === 'functionedge' &&
            !c.data.ghostReference &&
            (c.source === componentCopy.id || c.target === componentCopy.id)
        );
        edgeListReference.forEach((edge: any) => {
          if (edge.source === componentCopy.id) {
            edge.sourceGhostReference = ghostReference.id;
          } else {
            edge.targetGhostReference = ghostReference.id;
          }
          const functionSource = componentListCopy.find(
            (c: any) => c.id === edge.source && c.type === 'function'
          )
          const anchorSource = functionSource.data.anchors.find(
            (a: any) => a.id === edge.sourceHandle
          );
          let componentLinkSource = anchorSource.data.componentLinkReference
          if (!!componentLinkSource) {
            const componentSource = componentListCopy.find(
              (c: any) => c.id === componentLinkSource.component
            );
            if (!!componentSource) {
              if (componentSource.data.idDragReference) {
                delete componentSource.data.idDragReference;
                dispatch(assemblyEditorSliceActions.updateComponent(componentSource));
              }
            }
          }
          const functionTarget = componentListCopy.find(
            (c: any) => c.id === edge.target && c.type === 'function'
          );
          const anchorTarget = functionTarget.data.anchors.find(
            (a: any) => a.id === edge.targetHandle
          );
          let componentLinkTarget = anchorTarget.data.componentLinkReference
          if (!!componentLinkTarget) {
            const componentTarget = componentListCopy.find(
              (c: any) => c.id === componentLinkTarget.component
            );
            if (!!componentTarget) {
              if (componentTarget.data.idDragReference) {
                delete componentTarget.data.idDragReference;
                dispatch(assemblyEditorSliceActions.updateComponent(componentTarget));
              }
            }
          }
          edge.data.implementableReference = true
          dispatch(assemblyEditorSliceActions.updateComponent(edge));
        });
      }

      return { ghost, ghostReference }
    }
    return null;
  };

  return { implementComponent, clearAssembly, confirmGhost, cancelGhost, reactiveGhost };
};
