import { Icon } from "hakobio-react-ui";
import { MouseEventHandler } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../store";
import { ListTab } from "../../constants/ListTab";
import { assemblyEditorSliceActions } from "../../store/features/assemblyEditor/assemblyEditorSlice";
import { EntityToDeleteTypes } from "../../views/enum/EntityToDeleteType";
import { versioningMessages } from "../../lang/messages";
import { cloneDeep } from "lodash";

const AssemblyMenu = ({ assembly }) => {
  const dispatch = useAppDispatch();
  const listTab = useAppSelector((state) => state.assemblyEditorSlice.listTab);
  const user = useAppSelector((state) => state.assemblyEditorSlice.user);
  const selectedFolder = useAppSelector(
    (state) => state.assemblyEditorSlice.selectedFolder,
  );

  const intl = useIntl();
  const showModal = () => {
    dispatch(
      assemblyEditorSliceActions.setShowDeleteModal({
        assemblyId: assembly?.id,
      }),
    );
    dispatch(
      assemblyEditorSliceActions.setShowDeleteConfirmModal({
        entityId: assembly.id,
        entityName: assembly.name,
        type: EntityToDeleteTypes.Assembly,
      }),
    );
  };

  const showDeleteShortcutModal = () => {
    dispatch(
      assemblyEditorSliceActions.setShowDeleteShortcutConfirmModal({
        shortcutId: assembly?.id,
        shortcutName: assembly.name,
      }),
    );
  };

  const showModalShare = () => {
    dispatch(
      assemblyEditorSliceActions.setShowShareModal({
        assemblyId: assembly?.id,
      }),
    );
  };

  const showModalShortcut = () => {
    dispatch(
      assemblyEditorSliceActions.setShowShortcutModal({
        assemblyId: assembly?.id,
        assemblyName: assembly?.name,
        assemblyType: assembly?.type,
      }),
    );
  };

  const showModalHistory = () => {
    dispatch(
      assemblyEditorSliceActions.setShowHistoryModal({
        assemblyId: assembly?.id,
      }),
    );
  };

  const copyAssembly = async () => {
    dispatch(
      assemblyEditorSliceActions.setAssemblyDuplicate(cloneDeep(assembly)),
    );
  };

  const renderMenuButton = (
    icon: string,
    label: string | JSX.Element,
    action: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => {
    return (
      <button
        onClick={action}
        className="f-row f2-center gap-2"
        type="button"
        style={{ color: icon === "bin" ? "var(--red)" : undefined }}
      >
        <Icon name={icon} />
        {label}
      </button>
    );
  };

  const countPermissionsTrue: any =
    Object.values(assembly?.permissions).reduce(
      (acc: number, value: boolean) => {
        if (value === true) {
          return (acc || 0) + 1;
        } else {
          return acc;
        }
      },
      0,
    ) || 0;
  const shouldRenderDuplicateButton =
    assembly?.permissions?.duplicate && !assembly?.isShortCut;
  const shouldRenderShareButton =
    assembly?.permissions?.changeVisibility && !assembly?.isShortCut;
  const shouldRenderShortcutButton = !assembly?.isShortCut;
  const shouldRenderDltButton =
    !assembly?.isShortCut &&
    (selectedFolder?.designEditors?.includes(user?.id) ||
      selectedFolder?.designOwners?.includes(user?.id)) &&
    assembly?.permissions?.delete;
  const shouldRenderRemoveButton =
    assembly?.isShortCut &&
    (selectedFolder?.designEditors?.includes(user?.id) ||
      selectedFolder?.designOwners?.includes(user?.id));

  return (
    <div style={{ width: "auto", minWidth: 150 }}>
      {shouldRenderShareButton &&
        renderMenuButton(
          "share",
          <FormattedMessage id="Project.Button.Share" defaultMessage="Share" />,
          showModalShare,
        )}

      {shouldRenderDuplicateButton &&
        renderMenuButton(
          "files",
          <FormattedMessage
            id="Project.Button.Settings"
            defaultMessage="Duplicate"
          />,
          copyAssembly,
        )}
      {shouldRenderShortcutButton &&
        renderMenuButton(
          "external-link",
          <FormattedMessage
            id="Project.Button.Shortcut"
            defaultMessage="Create a shortcut"
          />,
          showModalShortcut,
        )}
      {listTab === ListTab.SUA &&
        countPermissionsTrue > 1 &&
        assembly?.permissions?.update &&
        assembly?.permissions?.read &&
        renderMenuButton(
          "history",
          intl.formatMessage(versioningMessages.history),
          showModalHistory,
        )}

      {shouldRenderDltButton &&
        renderMenuButton(
          "bin",
          <FormattedMessage
            id="SUConfig.StartupPanel.Button.Delete"
            defaultMessage="Delete"
          />,
          showModal,
        )}

      {shouldRenderRemoveButton &&
        renderMenuButton(
          "bin",
          <FormattedMessage
            id="SUConfig.StartupPanel.Button.Remove"
            defaultMessage="Remove"
          />,
          showDeleteShortcutModal,
        )}
    </div>
  );
};

export default AssemblyMenu;
