import { useEffect, useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';

import { matchPath } from 'react-router-dom';
import LeftPanel from '../components/EditorPanel/LeftPanel';
import FlowHistorySUA from '../components/Flow/FlowHistorySUA';
import ControlButtons from '../components/Shared/ControlButtons';
import { EditorMode } from '../constants/EditorMode';
import { ViewMode } from '../constants/ViewMode';
import { getAllDirectories, getAssembly, getAssemblyHistory } from '../services/editor/editorService';
import { convertToRead } from '../services/editor/editorServiceUtilities';
import { useAppDispatch, useAppSelector } from '../store';
import { useAssemblyEditorAction } from '../store/features/assemblyEditor/useAssemblyEditorAction';
import { historySliceActions } from '../store/features/history/historySlice';
import HistoryHeader from '../components/History/HistoryHeader';
import { assemblyEditorSliceActions } from '../store/features/assemblyEditor/assemblyEditorSlice';
import FlowHistoryUO from '../components/Flow/FlowHistoryUo';
import { Icon, ShortHeader } from 'hakobio-react-ui';
import { configMessages } from '../lang/messages';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Save } from '../components/EditorPanel/Shared/Utility/Save';
import SaveModal from './SaveModal';

interface HistoryViewProps {}

const HistoryViewUo: React.FC<HistoryViewProps> = () => {
  const dispatch = useAppDispatch();
  const { setEditorMode, setAssembly } = useAssemblyEditorAction();
  const [isLoading, setIsLoading] = useState(true);
  const [_activeTab, setActiveTab] = useState(0);
  const [_name, setName] = useState(null);
  const [_showSaveModal, setShowSaveModal] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const getAssemblyAsync = async (id: string) => {
    const assembly = await getAssembly(id);

    const assemblyToRead = convertToRead(assembly);
    setAssembly(assemblyToRead.general, assemblyToRead.components);
    setName(assemblyToRead.general.name);
    setIsLoading(false);
  };

  useEffect(() => {
    window.parent.postMessage({ message: 'deleteCross', value: 'hide' }, '*');
    dispatch(assemblyEditorSliceActions.setViewMode(ViewMode.HistoryUO));
  }, []);

  useEffect(() => {
    const matchUO = matchPath(window.location.pathname, {
      path: '/viewer/uohistory/:assemblyId',
      exact: true
    });
    if (matchUO) {
      // @ts-ignore
      const hashPartitionKey = matchUO.params.assemblyId;
      setEditorMode(EditorMode.UnitOperation);
      getAssemblyAsync(hashPartitionKey);
    }
  }, []);

  useEffect(() => {
    const allDirectories = async () => {
      const result = await getAllDirectories();
      dispatch(assemblyEditorSliceActions.setAllDirectories(result));  
    };
    allDirectories();
  }, []);

  const handleChangeTab = (index: number) => {
    setActiveTab(index);
    const tabName = ['PFD', 'P&ID', 'Reference'];
    dispatch(assemblyEditorSliceActions.setLayerMode(tabName[index]));
  };

  const closeEditor = () => {
    window.parent.postMessage({ message: 'deleteCross', value: 'show' }, '*');
    dispatch(assemblyEditorSliceActions.resetEditor(null));
    history.push('/list');
  };
  const closeSaveModal = () => {
    setShowSaveModal(false);
  };

  const onSave = () => {
    setShowSaveModal(true);
  };

  const renderSaveModal = () => {
    return (
      <SaveModal
        saveButtonsDisabled={true}
        closeModal={closeSaveModal}
        closeEditor={closeEditor}
        isExitingOnSave={false}
      />
    );
  };

  const UOTabs = [
    <div className="f-row f2-center gap-2">
      <Icon name="layer-1" />
      {intl.formatMessage(configMessages.PFD)}
    </div>,
    <div className="f-row f2-center gap-2">
      <Icon name="layer-2" />
      {intl.formatMessage(configMessages.PID)}
    </div>,
    <div className="f-row f2-center gap-2">
      <Icon name="floors" />
      {intl.formatMessage(configMessages.references)}
    </div>
  ];
  const renderFlow = () => {
    return <FlowHistoryUO />;
  };
  if (isLoading) return null;
  return (
    <>
      <ReactFlowProvider>
      {_showSaveModal && renderSaveModal()}
        <ShortHeader
          style={{ zIndex: 9, position: 'relative' }}
          // @ts-ignore
          tabs={UOTabs}
          activeTab={_activeTab}
          changeActiveTab={handleChangeTab}
          onClose={closeEditor}>
          <div className="font-regular max-1-lines-visible capitalize">{_name}</div>
        </ShortHeader>
        <div className="f-row f-full" style={{ height: 1 }}>
          <LeftPanel />
          <ControlButtons />
          {renderFlow()}
        </div>
      </ReactFlowProvider>
    </>
  );
};

export default HistoryViewUo;
