import { Icon, IconTooltip } from 'hakobio-react-ui';
import { CSSProperties, FC, memo, useEffect, useRef, useState } from 'react';
import { NodeProps, useUpdateNodeInternals } from 'react-flow-renderer';
import { CUSTOM_FUNCTIONS } from '../../../constants/CUSTOM_FUNCTIONS';
import { EditorMode } from '../../../constants/EditorMode';
import { UnitOperationLayer } from '../../../constants/PFD_EquipmentTabs';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assemblyEditorSliceActions } from '../../../store/features/assemblyEditor/assemblyEditorSlice';
import { useAssemblyEditorAction } from '../../../store/features/assemblyEditor/useAssemblyEditorAction';
import { useImplementEditorAction } from '../../../store/features/assemblyEditor/useImplementEditorAction';
import { GhostAnchor } from '../anchor/GhostAnchor';
import { NodeData } from '../utility/NodeData';
import _ from 'lodash';
import { useIntl } from 'react-intl';

const GhostNode: FC<NodeProps<NodeData>> = (props: any) => {
  const { id, selected } = props;
  const editorMode = useAppSelector((state) => state.assemblyEditorSlice.editorMode);

  const layerMode = useAppSelector((state) => state.assemblyEditorSlice.layerMode);

  const components = useAppSelector((state) => state.assemblyEditorSlice.components);

  const updateNodeInternals = useUpdateNodeInternals();
  const { onComponentRotated } = useAssemblyEditorAction();
  const { confirmGhost, cancelGhost } = useImplementEditorAction();
  const dispatch = useAppDispatch();

  const componentRef = useRef(null);
  const layerModeRef = useRef<any>(null);
  const editorModeRef = useRef<any>(null);
  layerModeRef.current = layerMode;
  editorModeRef.current = editorMode;

  const [showButton, setShowButton] = useState(false);

  const intl = useIntl();

  const component = components.find((c: any) => c.id === id);
  componentRef.current = component;
  const rotate = component?.viewer2D?.rotate ?? 0;

  let functionComponent: any;

  switch (layerMode) {
    case UnitOperationLayer.PnID:
      functionComponent = components.find((c: any) => {
        return component?.id === c.data.ghostId;
      });
      break;
    case UnitOperationLayer.Reference:
      functionComponent = components.find((c: any) => {
        return component?.id === c.data.ghostIdReference;
      });
      break;
  }

  const cancelChange = (event: any) => {
    dispatch(assemblyEditorSliceActions.setIsShowingGhostNode(false));
    cancelGhost(component.id, components, layerMode);
  };

  useEffect(() => {
    dispatch(assemblyEditorSliceActions.setIsShowingGhostNode(true));
    if (!componentRef.current) {
      return;
    }
    const componentCopy = _.cloneDeep(componentRef.current);
    switch (editorModeRef.current) {
      case EditorMode.SUA:
        if (component.viewer2D.x !== props.xPos || component.viewer2D.y !== props.yPos) {
          // @ts-ignore
          const deltaX = Math.abs(component.viewer2D.x - props.xPos);
          // @ts-ignore
          const deltaY = Math.abs(component.viewer2D.y - props.yPos);
          if (deltaX > 0.01 || deltaY > 0.01) {
            const promise = { id: id, component: componentCopy };
            promise.component.viewer2D.x = props.xPos;
            promise.component.viewer2D.y = props.yPos;
            dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
          }
        }
        break;
      case EditorMode.UnitOperation:
        switch (layerModeRef.current) {
          case UnitOperationLayer.PFD:
            if (
              componentRef.current.viewer2D.pfd.x !== props.xPos ||
              componentRef.current.viewer2D.pfd.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pfd.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pfd.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pfd = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.PnID:
            if (
              componentRef.current.viewer2D.pid.x !== props.xPos ||
              componentRef.current.viewer2D.pid.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.pid.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.pid.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.pid = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
          case UnitOperationLayer.Reference:
            if (
              componentRef.current.viewer2D.reference.x !== props.xPos ||
              componentRef.current.viewer2D.reference.y !== props.yPos
            ) {
              // @ts-ignore
              const deltaX = Math.abs(componentRef.current.viewer2D.reference.x - props.xPos);
              // @ts-ignore
              const deltaY = Math.abs(componentRef.current.viewer2D.reference.y - props.yPos);
              if (deltaX > 0.01 || deltaY > 0.01) {
                const promise = { id: id, component: componentCopy };
                promise.component.viewer2D.reference = { x: props.xPos, y: props.yPos };
                dispatch(assemblyEditorSliceActions.addPromiseUpdateComponent(promise));
              }
            }
            break;
        }
        break;
    }
  }, [props.xPos, props.yPos]);

  useEffect(() => {
    let breakLoop = false;
    switch (layerMode) {
      case UnitOperationLayer.PnID:
        component?.data.anchors.forEach((anchor: any) => {
          if (!breakLoop) {
            let edgeList = components.filter((c: any) => {
              const hasTargetOrTargetGhostSameOfCurrentComponent =
                c.targetGhost === component.id || c.target === component.id;
              const hasTargetHandleSameOfCurrentComponent = c.targetHandle === anchor.id;

              const hasTargetAndTargetHandler =
                hasTargetOrTargetGhostSameOfCurrentComponent &&
                hasTargetHandleSameOfCurrentComponent;

              const hasSourceOrSourceGhostSameOfCurrentComponent =
                c.sourceGhost === component.id || c.source === component.id;
              const hasSourceHandleSameOfCurrentComponent = c.sourceHandle === anchor.id;

              const hasSourceAndSourceHandler =
                hasSourceOrSourceGhostSameOfCurrentComponent &&
                hasSourceHandleSameOfCurrentComponent;

              return (
                c.type === 'functionedge' &&
                (hasTargetAndTargetHandler || hasSourceAndSourceHandler)
              );
            });
            if (edgeList.length === 1) {
              breakLoop = true;
              setShowButton(false);
            }
          }
        });
        break;
      case UnitOperationLayer.Reference:
        component?.data.anchors.forEach((anchor: any) => {
          if (!breakLoop) {
            let edgeList = components.filter((c: any) => {
              const hasTargetAndTargetHandler =
                (c.targetGhostReference === component.id || c.target === component.id) &&
                c.targetHandle === anchor.id;

              const hasSourceAndSourceHandler =
                (c.sourceGhostReference === component.id || c.source === component.id) &&
                c.sourceHandle === anchor.id;

              return (
                c.type === 'functionedge' &&
                (hasTargetAndTargetHandler || hasSourceAndSourceHandler)
              );
            });
            if (edgeList?.length === 1) {
              breakLoop = true;
              setShowButton(false);
            }
            if (edgeList?.length) {
              const ghost = components.find(
                (c: any) => c.type === 'ghost' && c.id === edgeList?.[0].targetGhost
              );
              if (ghost) {
                breakLoop = true;
                setShowButton(false);
              }
            }
          }
        });
        break;
    }

    if (!breakLoop) {
      setShowButton(true);
    }
  }, [components]);

  const confirm = (event: any) => {
    if (showButton) {
      dispatch(assemblyEditorSliceActions.setIsShowingGhostNode(false));
      confirmGhost(component.id, layerMode, components);
    }
    if (editorMode === EditorMode.UnitOperation) {
      dispatch(assemblyEditorSliceActions.setEditingAssembly(true));
    }
  };

  const foreignObjectSize = 40;

  const infoTooltipStyle: CSSProperties = {
    lineHeight: 0.5,
    backgroundColor: 'rgba(255,147,30,0.1)',
    height: 14,
    width: 14,
    borderRadius: 50,
    cursor: 'pointer',
    pointerEvents: 'auto'
  };

  const contentTooltip = () => {
    return (
      <div className="f-col f1-center">
        <div>
          {intl.formatMessage({
            id: 'GhostNode.ConnectSUADraggingAnchors',
            defaultMessage: "Connect the Single-Use Assembly by dragging its components' anchors."
          })}
        </div>
        <div>
          {layerMode === UnitOperationLayer.Reference &&
            intl.formatMessage({
              id: 'GhostNode.ConnectSuaDraggingAnchorsReference',
              defaultMessage: "Please don't forget to connect the SUAs in the P&ID Layer."
            })}
        </div>
      </div>
    );
  };

  const renderNode = () => {
    return (
      <>
        <div>
          <div
            className="f-row f2-center absolute py-1 pl-1 pr-2 gap-2"
            style={{
              borderRadius: 4,
              bottom: -36,
              right: 0,
              boxShadow: '0px 1px 5px 1px rgba(192,192,192, .5)',
              backgroundColor: 'white',
              cursor: 'default'
            }}>
            <div className="border-right pr-1">
              <IconTooltip
                style={{ lineHeight: 0.5, paddingLeft: 1 }}
                hoverColor="var(--primaryColor)"
                color="var(--primaryColor)"
                title={contentTooltip()}
                size={20}
              />
            </div>
            <Icon
              name="delete"
              size="0.8rem"
              color={'var(--orange)'}
              hoverColor={'var(--orange)'}
              style={{
                pointerEvents: 'auto',
                lineHeight: 0.5
              }}
              onClick={cancelChange}
            />

            <Icon
              name="chat"
              color={showButton ? 'var(--green)' : 'var(--frozen-grey)'}
              hoverColor={showButton ? 'var(--green)' : 'var(--frozen-grey)'}
              title={
                layerMode === UnitOperationLayer.Reference &&
                intl.formatMessage({
                  id: 'GhostNode.ConnectSuaDraggingAnchorsReference',
                  defaultMessage: "Please don't forget to connect the SUAs in the P&ID Layer"
                })
              }
              style={{
                pointerEvents: 'auto',
                lineHeight: 0.5
              }}
              onClick={confirm}
            />
          </div>
        </div>
        <div
          style={{
            height: component?.viewer2D?.size?.y,
            width: component?.viewer2D?.size?.x,
            position: 'relative',
            backgroundColor: 'rgba(255,147,30,0.1)',
            border: '1px solid #ff931e',
            borderRadius: 4
          }}>
          <div
            className="f-center absolute gap-1"
            style={{
              top: '-30px',
              whiteSpace: 'nowrap'
            }}>
            <div
              className="f-row f2-center gap-1 w-100"
              style={{
                color: 'var(--frozen-grey)'
              }}>
              {functionComponent?.data?.type &&
                CUSTOM_FUNCTIONS[functionComponent?.data?.type].form(26)}
              <div className="max-1-lines-visible">
                {layerMode === UnitOperationLayer.PnID
                  ? functionComponent?.data?.assembly?.general?.name
                  : functionComponent?.data?.assemblyReference?.general?.name}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderAnchors = () => {
    switch (editorMode) {
      case EditorMode.SUA:
        return null;
      case EditorMode.Reference:
        return null;
      case EditorMode.UnitOperation:
        switch (layerMode) {
          case UnitOperationLayer.PFD:
            return null;
          case UnitOperationLayer.PnID:
            return component.data.anchors.map((anchor: any) => {
              return (
                <GhostAnchor
                  key={anchor.id}
                  component={component}
                  anchor={anchor}
                  sizeY={component.viewer2D?.size?.y}
                  sizeX={component.viewer2D?.size?.x}
                />
              );
            });
          case UnitOperationLayer.Reference:
            return component.data.anchors.map((anchor: any) => {
              return (
                <GhostAnchor
                  key={anchor.id}
                  component={component}
                  anchor={anchor}
                  sizeY={component.viewer2D?.size?.y}
                  sizeX={component.viewer2D?.size?.x}
                />
              );
            });
        }
    }
  };

  const render = () => {
    if (component === undefined) {
      return null;
    }

    if (!functionComponent) {
      return null;
    }

    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'relative',
            transform: `rotate(${rotate}deg)`
          }}>
          {renderNode()}
          {renderAnchors()}
        </div>
      </div>
    );
  };

  return render();
};

export default memo(GhostNode);
