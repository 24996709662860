import { Empty, IconTooltip, PanelHeader } from 'hakobio-react-ui';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../store';
import GeneralChangeArray from './GeneralChangeArray';
import GeneralChangeString from './GeneralChangeString';
import GeneralChangeComponent from './GeneralChangeComponent';
import ComponentsChangeArray from './ComponentsChangeArray';
// import GeneralChangeBoolean from './GeneralChangeBoolean';
import { SUPPLIERS, Supplier } from '../../constants/SUPPLIERS';
import { historySliceActions } from '../../store/features/history/historySlice';
import MediaChangeCollection from './MediaChangeCollection';

const HistoryComparisonPanel = () => {
  const dispatch = useAppDispatch();
  const viewingAssembly = useAppSelector((state) => state.historySlice.viewingAssembly);
  const comparingAssembly = useAppSelector((state) => state.historySlice.comparingAssembly);
  const differencesCount = useAppSelector((state) => state.historySlice.differencesCount);

  const intl = useIntl();

  const handleDifferencesCount = (hasDifference) => {
    if (hasDifference) {
      dispatch(historySliceActions.setDifferencesCount(differencesCount + 1));
    }
  };

  return (
    <div
      className="f-full f-col"
      style={{
        width: '400px'
      }}>
      <PanelHeader>
        <div className="f-row f2-center gap-2">
          <span>
            {intl.formatMessage({
              id: 'HistoryComparisonPanel.Title.ChangesSummary',
              defaultMessage: 'Changes Summary'
            })}
          </span>
          <IconTooltip
            color="var(--frozen-grey)"
            title={'See changes made between 2 diffferent versions'}
          />
        </div>
      </PanelHeader>
      <div className="f-full overflow-y-auto" style={{ height: 1 }}>
        <GeneralChangeString
          setHasDifferences={handleDifferencesCount}
          title={'Name'}
          viewingVariable={viewingAssembly.general.name}
          comparingVariable={comparingAssembly.general.name}
        />
        <GeneralChangeString
          setHasDifferences={handleDifferencesCount}
          title={'Description'}
          viewingVariable={viewingAssembly.general.description}
          comparingVariable={comparingAssembly.general.description}
        />

        <GeneralChangeString
          setHasDifferences={handleDifferencesCount}
          title={'Supplier'}
          viewingVariable={
            SUPPLIERS.find((supplier: Supplier) => supplier.id === viewingAssembly.general.supplier)
              ?.name
          }
          comparingVariable={
            SUPPLIERS.find(
              (supplier: Supplier) => supplier.id === comparingAssembly.general.supplier
            )?.name
          }
        />

        <GeneralChangeString
          setHasDifferences={handleDifferencesCount}
          title={'Status'}
          viewingVariable={viewingAssembly.general.status}
          comparingVariable={comparingAssembly.general.status}
        />

        <GeneralChangeArray
          setHasDifferences={handleDifferencesCount}
          title={'Functions'}
          viewingArray={viewingAssembly.general.functions}
          comparingArray={comparingAssembly.general.functions}
        />

        <MediaChangeCollection
          setHasDifferences={handleDifferencesCount}
  
        />

        <GeneralChangeComponent
          setHasDifferences={handleDifferencesCount}
          title={'Components'}
          viewingComponents={viewingAssembly.components}
          comparingComponents={comparingAssembly.components}
        />

        <ComponentsChangeArray
          setHasDifferences={handleDifferencesCount}
          title={'Components attributs'}
          viewingComponents={viewingAssembly.components}
          comparingComponents={comparingAssembly.components}
        />

        {differencesCount === 0 && (
          <Empty
            size="md"
            text={intl.formatMessage({
              id: 'HistoryComparisonPanel.NoDiffernces',
              defaultMessage: 'There are no difference between these two versions'
            })}
          />
        )}
      </div>
    </div>
  );
};

export default HistoryComparisonPanel;
