import SingleUseEquipmentPanel from './SingleUseEquipmentPanel';

const SingleUseEditorLibraryPanel = () => {
  const render = () => {
    return (
      <div
        className="f-full f-col"
        style={{
          width: '400px'
        }}>
        <SingleUseEquipmentPanel />
      </div>
    );
  };

  return render();
};

export default SingleUseEditorLibraryPanel;
