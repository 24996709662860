
export function groupBySuaNamePnid(components: any, assemblyType: any) {
    /* let allComponents = components
        .filter((c) => c.type === 'function' && (c.data[assemblyType]))  
        .sort((c1, c2) => c1.viewer2D.x - c2.viewer2D.x);

    const assemblyNames = allComponents.map((c) => c.data[assemblyType]?.general.name);
    const uniqueAssemblyNames = new Set(assemblyNames);
    let countByAssembly = [];
    uniqueAssemblyNames.forEach((assemblyName: any) => {
        const filteredComponents = allComponents.filter((c) => c.data[assemblyType]?.general.name === assemblyName);
        countByAssembly.push({ name: assemblyName, count: filteredComponents.length });
    }); */

    let allComponents = components
        .filter((c) => c.type === 'function' && (c.data[assemblyType]))
        .sort((c1, c2) => c1.viewer2D.x - c2.viewer2D.x);

    let groupedComponents = allComponents.reduce((acc, c) => {
        const assemblyName = c.data[assemblyType]?.general.name;
        if (!acc[assemblyName]) {
            acc[assemblyName] = [];
        }
        acc[assemblyName].push(c);
        return acc;
    }, {});  
     
    let countByAssembly = [];

    for (let assemblyName in groupedComponents) {
        const group = groupedComponents[assemblyName];
        let letters = [];  

        group.forEach((c, index) => {
            const letter = String.fromCharCode(65 + index);           
            // Si vous stockez ceci dans un état, assurez-vous d'utiliser la copie et non l'original
            letters.push({ letter, id: c.id }); 
        });

        countByAssembly.push({ name: assemblyName, count: group.length, letters });
    }
    return countByAssembly
}